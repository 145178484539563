import React, { useState, useEffect } from "react";
import validator from "validator";
import { uuid } from "uuidv4";

import { Row, Col, Button, Form } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { LoadingLoader, PriceInput } from "components";
import "./Suppliers.scss";
import RequiredText from "components/required-text/required-text";

export function MiscLabourPriceList({ addNew }) {
  const PRICELIST = new Array(4).fill({
    price: 10,
  });
  const [hide, setHide] = useState(false);
  const [preffered, setPreffered] = useState(false);
  const [data, setData] = useState([]);

  const handleChange = (e, callback) => {
    callback(e.target.checked);
  };

  useEffect(() => {
    if (hide) {
      setData(data.filter((priceData) => priceData.price));
    } else {
      setData([
        ...PRICELIST,
        {
          price: 0,
        },
      ]);
    }
  }, [hide]);

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <div className="_tab_msg">
              <p className="__msg">
                This is where you enter those various unforseen items not found
                in the labor categories. Click 'Add new Item' above.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="_tbl_wrapper">
              <table className="_lbr_price_list_tbl" style={{ width: "900px" }}>
                <thead>
                  <tr>
                    <th>Item Description</th>
                    <th>Price</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {data.map((priceData) => (
                    <MiscLabourListData price={priceData.price} />
                  ))}
                  {addNew && <MiscLabourListData price={0} newData />}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>

        <Row style={{ justifyContent: "flex-end", margin: "1em 0" }}>
          <div style={{ margin: "0 1em" }}>
            <Button variant="danger" style={{ marginRight: "1em" }}>
              Cancel
            </Button>

            <Button variant="success"> Save</Button>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export function ListDataLabor({
  priceData,
  price,
  uom,
  changePrice,
  id,
  _id,
  isCustom,
  setDataList,
  dataList,
  setAddItem,
  delData,
  roofPitch,
  workType,
  subType,
  style,
  color,
  size,
  accessory,
  deletePricing,
  setDisable,
  uomOptions,
}) {
  const [newPrice, setNewPrice] = useState(
    price ? parseFloat(price).toFixed(2) : parseFloat("0").toFixed(2)
  );
  const [newUom, setNewUom] = useState(uom);

  const [editing, setEditing] = useState(false);
  const [deleting, setDel] = useState(false);
  const [newAssessory, setNewAccessory] = useState(accessory);
  const dispatch = useDispatch();

  const showOptions = (list) =>
    list.map((data) => (
      <option style={{ textTransform: "capitalize" }} value={data} key={data}>
        {data}
      </option>
    ));
  const handleChange = (e, callback) => {
    setDisable(false);
    callback(e.target.value);
  };
  const handleEdit = async () => {
    setEditing((prev) => !prev);
  };

  const handleInputChange = (price) => {
    setDisable(false);
    setNewPrice(price);
  };

  useEffect(() => {
    let value = newPrice;
    if (value.trim() && validator.isNumeric(value)) {
      value = parseFloat(newPrice).toFixed(2);
    }
    changePrice(id, { uomPrice: value });
  }, [newPrice]);
  useEffect(() => {
    // validate? maybe
    changePrice(id, { accessory: newAssessory, uom: newUom });
  }, [newAssessory, newUom]);

  const handleDel = async () => {
    setDel(true);
    const toastId = toast.info("Deleting data. Please wait!", {
      autoClose: false,
    });
    try {
      const res = await dispatch(deletePricing(_id));
      if (res.data.success) {
        toast.dismiss(toastId);

        if (delData(_id)) {
          setAddItem(false);
          toast.success("Data deleted successfully.");
        }
      } else {
        setDel(false);
        toast.dismiss(toastId);
        toast.error("An Error occured deleting data.Please try again later!");
      }
    } catch (err) {
      toast.dismiss(toastId);
      toast.error(err.message);
    } finally {
      setDel(false);
    }
  };

  if (
    !roofPitch ||
    !workType ||
    !subType ||
    !style ||
    !color ||
    !size ||
    !accessory
  )
    return <LoadingLoader />;

  return (
    <Row style={{ paddingBottom: "15px" }}>
      {roofPitch !== "N/A" && roofPitch.length > 0 && (
        <Col>
          <Form.Group>
            <Form.Control
              className="subDataField"
              style={{ textAlign: "center" }}
              type="text"
              disabled
              value={roofPitch}
              onChange={(event) => handleChange(event, setNewPrice)}
            />
          </Form.Group>
        </Col>
      )}
      {workType !== "N/A" && workType.length > 0 && (
        <Col lg="3">
          <Form.Group>
            <Form.Control
              className="subDataField"
              style={{ textAlign: "center" }}
              type="text"
              disabled
              value={workType}
              onChange={(event) => handleChange(event, setNewPrice)}
            />
          </Form.Group>
        </Col>
      )}
      {subType !== "N/A" && subType.length > 0 && (
        <Col>
          <Form.Group>
            <Form.Control
              className="subDataField"
              style={{ textAlign: "center" }}
              type="text"
              disabled
              value={subType}
              onChange={(event) => handleChange(event, setNewPrice)}
            />
          </Form.Group>
        </Col>
      )}
      {style !== "N/A" && style.length > 0 && (
        <Col>
          <Form.Group>
            <Form.Control
              className="subDataField"
              style={{ textAlign: "center" }}
              type="text"
              disabled
              value={style}
              onChange={(event) => handleChange(event, setNewPrice)}
            />
          </Form.Group>
        </Col>
      )}
      {color !== "N/A" && color.length > 0 && (
        <Col>
          <Form.Group>
            <Form.Control
              className="subDataField"
              style={{ textAlign: "center" }}
              type="text"
              disabled
              value={color}
              onChange={(event) => handleChange(event, setNewPrice)}
            />
          </Form.Group>
        </Col>
      )}
      {size !== "N/A" && size.length > 0 && (
        <Col>
          <Form.Group>
            <Form.Control
              className="subDataField"
              style={{ textAlign: "center" }}
              type="text"
              disabled
              value={size}
              onChange={(event) => handleChange(event, setNewPrice)}
            />
          </Form.Group>
        </Col>
      )}
      {accessory !== "N/A" && accessory.length > 0 && (
        <Col>
          <Form.Group>
            <Form.Control
              className="subDataField"
              style={{ textAlign: "center" }}
              type="text"
              disabled={!editing}
              value={newAssessory}
              onChange={(event) => handleChange(event, setNewAccessory)}
            />
          </Form.Group>
        </Col>
      )}

      <Col>
        <Form.Group>
          <Form.Control
            as="select"
            value={newUom}
            name={newUom}
            disabled={!editing}
            onChange={(event) => handleChange(event, setNewUom)}
          >
            {/* <option value="">Choose...</option> */}
            <option value="">{newUom}</option>
            {showOptions(uomOptions)}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col lg="2">
        <PriceInput
          style={{ textAlign: "right" }}
          price={newPrice}
          onChange={handleInputChange}
        />
      </Col>
      <Col>
        {isCustom && (
          <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
            <Button
              variant={editing ? "success" : "warning"}
              onClick={() => handleEdit()}
            >
              {editing ? "Done" : "Edit"}
            </Button>
            <Button
              variant="danger"
              onClick={() => handleDel()}
              disabled={deleting}
            >
              {deleting ? "Deleting..." : "Delete"}
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
}

export function MiscListDataLabor({
  changePrice,
  customItemDefaultValue,
  setDataList,
  dataList,
  setAddItem,
  newData,
  uomOptions,
  idx,
  _id,
  priceData,
  delData,
  deletePricing,
  setDisable,
  uom,
}) {
  const [uomPrice, setUomPrice] = useState(
    priceData.uomPrice
      ? parseFloat(priceData.uomPrice).toFixed(2)
      : parseFloat("0").toFixed(2)
  );
  const [workType, setWorkType] = useState("" || priceData.workType);
  const [processing, setProcessing] = useState(false);
  const [newUom, setNewUom] = useState(uom);
  const [editing, setEditing] = useState(false);

  const dispatch = useDispatch();

  const handleInputChange = (e, callback) => {
    callback(e.target.value.replace("$", ""));
  };

  useEffect(() => {
    let value = uomPrice;
    if (value && validator.isNumeric(value.toString())) {
      value = parseFloat(uomPrice).toFixed(2);
    }
    changePrice(idx, { uomPrice: value });
  }, [uomPrice]);

  useEffect(() => {
    // validate? maybe
    changePrice(idx, { workType: workType, uom: newUom });
  }, [workType, newUom]);

  const handleChange = (e, callback) => {
    setDisable(false);
    callback(e.target.value);
  };

  const showOptions = (list) =>
    list.map((data) => (
      <option style={{ textTransform: "capitalize" }} value={data} key={data}>
        {data}
      </option>
    ));

  const handleDel = async () => {
    setProcessing(true);
    const toastId = toast.info("Deleting data. Please wait!", {
      autoClose: false,
    });
    try {
      const res = await dispatch(deletePricing(_id));
      if (res.data.success) {
        toast.dismiss(toastId);
        if (delData(_id)) {
          setAddItem(false);
          toast.success("Data deleted successfully.");
        }
      } else {
        toast.dismiss(toastId);
        toast.error("An Error occured deleting data.Please try again later!");
      }
    } catch (err) {
      toast.dismiss(toastId);
      toast.error(err.message);
    } finally {
      setProcessing(false);
    }
  };
  const handlePriceChange = (price) => {
    setDisable(false);

    setUomPrice(price);
  };

  const handleEdit = () => {
    setEditing((prev) => !prev);
  };

  return (
    <Row style={{ paddingBottom: "15px" }}>
      <Col>
        <Form.Group>
          <Form.Control
            style={{ textAlign: "center" }}
            type="text"
            disabled={!editing}
            value={workType}
            name={workType}
            onChange={(event) => handleChange(event, setWorkType)}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Control
            as="select"
            value={newUom}
            name={newUom}
            disabled={!editing}
            onChange={(event) => handleChange(event, setNewUom)}
          >
            {/* <option value="">Choose...</option> */}
            <option value="">{newUom}</option>
            {showOptions(uomOptions)}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col lg="2">
        <PriceInput
          style={{ textAlign: "right" }}
          price={uomPrice}
          onChange={handlePriceChange}
        />
      </Col>

      {priceData.isCustom ? (
        <Col>
          <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
            <Button
              variant={editing ? "success" : "warning"}
              onClick={() => handleEdit()}
            >
              {editing ? "Done" : "Edit"}
            </Button>
            <Button
              variant="danger"
              onClick={() => handleDel()}
              disabled={processing}
            >
              {processing ? "Deleting..." : "Delete"}
            </Button>
          </div>
        </Col>
      ) : (
        <Col />
      )}
    </Row>
  );
}

export function NewMiscListDataLabor({
  customItemDefaultValue,
  setDataList,
  dataList,
  setAddItem,
  newData,
  uomOptions,
  priceData,
  suppliersId,
  addPricing,
}) {
  const [uomPrice, setUomPrice] = useState(Number(0).toFixed(2));
  const [workType, setWorkType] = useState("");
  const [processing, setProcessing] = useState(false);
  const [newUom, setNewUom] = useState("");

  const dispatch = useDispatch();

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };
  const handlePriceChange = (e, callback) => {
    callback(e.target.value.replace("$", ""));
  };

  const showOptions = (list) => {
    return list.map((data) => (
      <option style={{ textTransform: "capitalize" }} value={data} key={data}>
        {data}
      </option>
    ));
  };

  const handleSave = async () => {
    if (!validator.isNumeric(uomPrice)) {
      return toast.error("Please provide a valid price.");
    }

    const toastId = toast.info("Adding Data. Please wait!", {
      autoClose: false,
    });
    try {
      setProcessing(true);
      const res = await dispatch(
        addPricing(
          {
            ...newData,
            categoryType: "Miscellaneous",
            uomPrice: parseFloat(uomPrice).toFixed(2),
            workType,
            uom: newUom,
          },
          suppliersId
        )
      );
      if (res.data.success) {
        toast.dismiss(toastId);
        dataList.push(res.data.result.custom);
        setDataList(dataList);
        setAddItem(false);
        toast.success("Data Added successfully.");
      } else {
        toast.dismiss(toastId);
        toast.error(res.data.error);
      }
    } catch (err) {
      toast.dismiss(toastId);
      toast.error(err.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Control
            type="text"
            value={workType}
            style={{ textAlign: "center" }}
            name={workType}
            onChange={(event) => handleChange(event, setWorkType)}
          />
        </Form.Group>
      </Col>

      <Col>
        <Form.Group>
          <Form.Control
            as="select"
            value={newUom}
            name={newUom}
            onChange={(event) => handleChange(event, setNewUom)}
          >
            {/* <option value="">Choose...</option> */}
            <option value="">{newUom}</option>
            {showOptions(uomOptions)}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col lg="2">
        <Form.Group>
          <Form.Control
            style={{ textAlign: "right" }}
            type="text"
            name="uomPrice"
            value={`$${uomPrice}`}
            onChange={(event) => handlePriceChange(event, setUomPrice)}
          />
        </Form.Group>
      </Col>
      <Col>
        <Button
          onClick={() => handleSave()}
          disabled={processing || !workType || !uomPrice}
          variant="success"
          style={{ marginRight: "1em" }}
        >
          {processing ? "Saving..." : "Save"}
        </Button>
        <Button variant="danger" onClick={() => setAddItem(false)}>
          Cancel
        </Button>
      </Col>
    </Row>
  );
}

export function MiscPriceListLabor({
  data,
  customItemDefaultValue,
  addItem,
  setAddItem,
  newData,
  uomOptions,
  suppliersId,
  patchPricing,
  addPricing,
  deletePricing,
  customItem,
  materialPointerEventViewOnly,
}) {
  const [hide, setHide] = useState(false);
  const [dataList, setDataList] = useState([...data]);
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [disable, setDisable] = useState(true);

  const [prevData, setPrevData] = useState([]);

  const handleChange = (e, callback) => {
    callback(e.target.checked);
  };
  useEffect(() => {
    if (hide) {
      setPrevData(dataList);
      setDataList(
        dataList.filter((priceData) => Number(priceData.uomPrice) !== 0)
      );
    } else if (prevData.length !== 0) {
      setDataList([...prevData]);
    }
  }, [hide]);

  useEffect(() => {
    setDataList([...data]);
  }, [data]);
  const changePrice = (id, value) => {
    const newList = [...dataList];
    newList.splice(id, 1, {
      ...dataList[id],
      ...value,
    });
    setDataList(newList);
  };

  const handleSave = () => {
    if (dataList.length === 0) return;
    setProcessing(true);
    setDisable(true);
    const toastId = toast.info("Updating data. Please Wait...!", {
      autoClose: false,
    });

    const invalidInputs = dataList.some(
      (priceData) => !validator.isNumeric(`${priceData.uomPrice}`)
    );
    if (invalidInputs) {
      toast.dismiss(toastId);
      toast.error("Please enter valid price.");
      setProcessing(false);
    } else {
      dataList.map((priceData, idx) => {
        dispatch(
          patchPricing(priceData._id, {
            uomPrice: Number(priceData.uomPrice),
            uom: priceData.uom,
            workType: priceData.workType,
          })
        )
          .then((res) => res.data)
          .then((res) => {
            if (res.success) {
              if (idx === dataList.length - 1) {
                toast.dismiss(toastId);
                toast.success("Data updated successfully.");
              }
            } else {
              toast.dismiss(toastId);
              toast.error(res.error);
            }
          })
          .catch((err) => {
            toast.dismiss(toastId);
            toast.error(err.message);
          })
          .finally(() => {
            setProcessing(false), setDisable(true);
          });
      });
    }
  };

  const delData = (id) => {
    const idx = dataList.findIndex((info) => info._id === id);
    const newList = [...dataList];
    newList.splice(idx, 1);
    setDataList(newList);
    return true;
  };

  const displayList = () =>
    dataList.length > 0
      ? dataList.map((priceData, idx) => (
          <MiscListDataLabor
            setAddItem={setAddItem}
            setDataList={setDataList}
            dataList={dataList}
            customItemDefaultValue={customItemDefaultValue}
            newData={newData}
            uomOptions={uomOptions}
            key={priceData._id}
            _id={priceData._id}
            priceData={priceData}
            idx={idx}
            isNewData={false}
            delData={delData}
            changePrice={changePrice}
            deletePricing={deletePricing}
            setDisable={setDisable}
            uom={priceData.uom}
          />
        ))
      : !addItem && (
          <div className="_tab_msg">
            <p className="__msg">No records are available.</p>
          </div>
        );

  return (
    <Row>
      <Col>
        <Row>
          <Col />

          {/* <Col style={{ textAlign: "right" }} lg="2">
            <Form.Group style={{ float: "right" }}>
              <Form.Check
                name="hide"
                style={{ marginBottom: "1em" }}
                checked={hide}
                onChange={(e) => handleChange(e, setHide)}
                label="Hide non-priced item"
              />
            </Form.Group>
          </Col> */}
          <Col />
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }}>
            <Form.Label>
              Item Description {customItem && <RequiredText>*</RequiredText>}
            </Form.Label>
          </Col>
          <Col style={{ textAlign: "center" }} lg="2">
            <Form.Label>Price Per UOM</Form.Label>
          </Col>
          <Col />
        </Row>
        {processing ? <LoadingLoader /> : displayList()}

        {addItem && (
          <NewMiscListDataLabor
            setAddItem={setAddItem}
            setDataList={setDataList}
            dataList={dataList}
            customItemDefaultValue={customItemDefaultValue}
            newData={newData}
            uomOptions={uomOptions}
            isNewData
            suppliersId={suppliersId}
            addPricing={addPricing}
          />
        )}

        <Row
          style={{
            // justifyContent: "flex-end",
            margin: "1em 0",
            float: "right",
          }}
        >
          <div style={{ margin: "0 1em" }}>
            <Button variant="danger" style={{ marginRight: "1em" }}>
              Cancel
            </Button>

            <Button disabled={disable} variant="success" onClick={handleSave}>
              Save
            </Button>
          </div>
        </Row>
      </Col>
    </Row>
  );
}
export function NewListDataLabor({
  setDataList,
  dataList,
  setAddItem,
  newData,
  uomOptions,
  suppliersId,
  addPricing,
}) {
  const [uomPrice, setUomPrice] = useState(parseFloat(0.0).toFixed(2));
  const [accessory, setAccessory] = useState("");
  const [uom, setUOM] = useState("");
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const handlePriceChange = (e, callback) => {
    callback(e.target.value.replace("$", ""));
  };

  const handleSave = async () => {
    if (!validator.isNumeric(uomPrice)) {
      return toast.error("Please provide a valid price.");
    }
    const toastId = toast.info("Adding Data. Please wait!", {
      autoClose: false,
    });
    try {
      setProcessing(true);
      const res = await dispatch(
        addPricing(
          {
            ...newData,
            uomPrice: parseFloat(uomPrice).toFixed(2),
            uom,
            accessory,
          },
          suppliersId
        )
      );
      if (res.data.success) {
        toast.dismiss(toastId);
        dataList.push(res.data.result.custom);
        setDataList(dataList);
        setAddItem(false);
        toast.success("Data Added successfully.");
      } else {
        toast.dismiss(toastId);
        toast.error(res.data.error);
      }
    } catch (err) {
      toast.dismiss(toastId);
      toast.error(err.message);
    } finally {
      setProcessing(false);
    }
  };
  const showOptions = (list) =>
    list.map((data) => (
      <option style={{ textTransform: "capitalize" }} value={data} key={data}>
        {data}
      </option>
    ));

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Control
            type="text"
            style={{ textAlign: "center" }}
            value={accessory}
            onChange={(event) => handleChange(event, setAccessory)}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Control
            as="select"
            value={uom}
            name={uom}
            onChange={(event) => handleChange(event, setUOM)}
          >
            {/* <option value="">Choose...</option> */}
            <option value="">{newUom}</option>
            {showOptions(uomOptions)}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col lg="2">
        <Form.Group>
          <Form.Control
            style={{ textAlign: "right" }}
            type="text"
            name="uomPrice"
            value={`$${uomPrice}`}
            onChange={(event) => handlePriceChange(event, setUomPrice)}
          />
        </Form.Group>
      </Col>
      <Col>
        <Button
          onClick={() => handleSave()}
          disabled={processing || !accessory || !uom || !uomPrice}
          variant="success"
          style={{ marginRight: "1em" }}
        >
          {processing ? "Saving..." : "Save"}
        </Button>
        <Button variant="danger" onClick={() => setAddItem(false)}>
          Cancel
        </Button>
      </Col>
    </Row>
  );
}

export function PriceListLabor({
  data,
  customItemDefaultValue,
  addItem,
  setAddItem,
  newData,
  subName,
  uomOptions,
  suppliersId,
  patchPricing,
  deletePricing,
  addPricing,
  customItem,
  materialPointerEventViewOnly,
}) {
  const [hide, setHide] = useState(false);
  const [dataList, setDataList] = useState([...data]);
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [disable, setDisable] = useState(true);

  const [prevData, setPrevData] = useState([]);

  const handleChange = (e, callback) => {
    callback(e.target.checked);
  };
  useEffect(() => {
    if (hide) {
      setPrevData(dataList);
      setDataList(
        dataList.filter((priceData) => Number(priceData.uomPrice) !== 0)
      );
    } else if (prevData.length !== 0) {
      setDataList([...prevData]);
    }
  }, [hide]);

  const changePrice = (id, value) => {
    const newList = [...dataList];
    newList.splice(id, 1, {
      ...dataList[id],
      ...value,
    });
    setDataList(newList);
  };

  const handleSave = () => {
    if (dataList.length === 0) return;
    setProcessing(true);
    setDisable(true);
    const toastId = toast.info("Updating data. Please Wait...!", {
      autoClose: false,
    });

    const invalidInputs = dataList.some(
      (priceData) => !validator.isNumeric(`${priceData.uomPrice}`)
    );
    if (invalidInputs) {
      toast.dismiss(toastId);
      toast.error("Please enter valid price.");
      setProcessing(false);
    } else {
      dataList.map((priceData, idx) => {
        dispatch(
          patchPricing(priceData._id, {
            uomPrice: Number(priceData.uomPrice),
            uom: priceData.uom,
            workType: priceData.workType,
          })
        )
          .then((res) => res.data)
          .then((res) => {
            if (res.success) {
              if (idx === dataList.length - 1) {
                toast.dismiss(toastId);
                toast.success("Data updated successfully.");
              }
            } else {
              toast.dismiss(toastId);
              toast.error(res.error);
            }
          })
          .catch((err) => {
            toast.dismiss(toastId);
            toast.error(err.message);
          })
          .finally(() => {
            setProcessing(false), setDisable(true);
          });
      });
    }
  };

  const delData = (id) => {
    const idx = dataList.findIndex((info) => info._id === id);
    const newList = [...dataList];
    newList.splice(idx, 1);
    setDataList(newList);
    return true;
  };

  const displayList = () =>
    dataList.length > 0
      ? dataList.map((priceData, idx) => (
          <ListDataLabor
            setDataList={setDataList}
            dataList={dataList}
            customItemDefaultValue={customItemDefaultValue}
            key={priceData._id}
            _id={priceData._id}
            price={priceData.uomPrice}
            uom={priceData.uom}
            changePrice={changePrice}
            id={idx}
            priceData={priceData}
            isCustom={priceData.isCustom}
            setAddItem={setAddItem}
            delData={delData}
            pricingType={priceData.pricingType}
            workType={priceData.workType || "N/A"}
            subType={priceData.subType}
            style={priceData.style || "N/A"}
            color={priceData.color || "N/A"}
            size={priceData.size || "N/A"}
            roofPitch={priceData.roofPitch || "N/A"}
            accessory={priceData.accessory}
            deletePricing={deletePricing}
            setDisable={setDisable}
            uomOptions={uomOptions}
          />
        ))
      : !addItem && (
          <div className="_tab_msg">
            <p className="__msg">No records availabe.</p>
          </div>
        );

  return (
    <Row>
      <Col>
        <Row>
          {data[0].roofPitch !== "N/A" && <Col />}
          {data[0].workType !== "N/A" && <Col lg="3" />}
          {data[0].subType !== "N/A" && <Col />}
          {data[0].style !== "N/A" && <Col />}
          {data[0].color !== "N/A" && <Col />}
          {data[0].size !== "N/A" && <Col />}
          {data[0].accessory !== "N/A" && <Col />}
          <Col />

          {/* <Col style={{ textAlign: "right" }} lg="2">
            <Form.Group style={{ float: "right", marginTop: "30px" }}>
              <Form.Check
                style={{ marginBottom: "1em" }}
                name="hide"
                checked={hide}
                onChange={(e) => handleChange(e, setHide)}
                label="Hide non-priced item"
              />
            </Form.Group>
          </Col> */}
          <Col />
        </Row>

        <Row>
          {data[0].roofPitch !== "N/A" && (
            <Col style={{ textAlign: "center" }}>
              <Form.Label>Roof Pitch</Form.Label>
            </Col>
          )}
          {data[0].workType !== "N/A" && (
            <Col lg="3" style={{ textAlign: "center" }}>
              <Form.Label>Work Type</Form.Label>
            </Col>
          )}
          {data[0].subType !== "N/A" && (
            <Col style={{ textAlign: "center" }}>
              <Form.Label>{subName}</Form.Label>
            </Col>
          )}
          {data[0].style !== "N/A" && (
            <Col style={{ textAlign: "center" }}>
              <Form.Label>Style</Form.Label>
            </Col>
          )}
          {data[0].color !== "N/A" && (
            <Col style={{ textAlign: "center" }}>
              <Form.Label>Color</Form.Label>
            </Col>
          )}
          {data[0].size !== "N/A" && (
            <Col style={{ textAlign: "center" }}>
              <Form.Label>Size</Form.Label>
            </Col>
          )}
          {data[0].accessory !== "N/A" && (
            <Col style={{ textAlign: "center" }}>
              <Form.Label>
                Accessory {customItem && <RequiredText>*</RequiredText>}
              </Form.Label>
            </Col>
          )}
          <Col style={{ textAlign: "center" }}>
            <Form.Label>
              UOM
              {customItem && <RequiredText>*</RequiredText>}
            </Form.Label>
          </Col>
          <Col style={{ textAlign: "center" }} lg="2">
            <Form.Label style={{ marginTop: "15%" }}>Price Per UOM</Form.Label>
          </Col>
          <Col />
        </Row>
        {processing ? <LoadingLoader /> : displayList()}

        {addItem && (
          <NewListDataLabor
            setAddItem={setAddItem}
            setDataList={setDataList}
            dataList={dataList}
            showUOM={data[0].uomType !== "N/A"}
            customItemDefaultValue={customItemDefaultValue}
            newData={newData}
            uomOptions={uomOptions}
            suppliersId={suppliersId}
            addPricing={addPricing}
          />
        )}

        <Row
          style={{
            // justifyContent: "flex-end",
            margin: "1em -15px",
            float: "right",
          }}
        >
          <div style={{ margin: "0 1em" }}>
            <Button variant="danger" style={{ marginRight: "1em" }}>
              Cancel
            </Button>

            <Button disabled={disable} variant="success" onClick={handleSave}>
              Save
            </Button>
          </div>
        </Row>
      </Col>
    </Row>
  );
}
export function MiscLabourListData({ price, newData }) {
  const [_id] = useState(uuid());
  const [newPrice, setNewPrice] = useState(price);
  const [edit, setEdit] = useState(false);

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  return (
    <tr>
      <td>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder={newData ? "Enter Description" : ""}
            disabled={newData ? !edit : true}
          />
        </Form.Group>
      </td>

      <td>
        <Form.Group>
          {edit ? (
            <Form.Control
              type="newPrice"
              name="newPrice"
              value={newPrice}
              onChange={(event) => handleChange(event, setNewPrice)}
            />
          ) : (
            <Form.Control
              type="text"
              disabled
              name="newPrice"
              value={`$${parseFloat(newPrice).toFixed(2)}`}
              onChange={(event) => handleChange(event, setNewPrice)}
            />
          )}
        </Form.Group>
      </td>
      <td>
        {edit ? (
          <Button onClick={() => setEdit(false)} variant="success">
            {" "}
            Save
          </Button>
        ) : (
          <Button onClick={() => setEdit(true)}>Edit</Button>
        )}{" "}
      </td>
    </tr>
  );
}
