import React, { useState } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import PopupModal from "components/popup-modal/popup-modal";
import ArrowPagination from "components/ArrowPagination";

const styles = {
  formInput: {
    marginBottom: "15px",
  },
};

export default function AddBuilding({
  onCoopModalHide,
  newBuildingName,
  coopUnits,
  onEditBuilding,
  saveEditBuilding,
  tempNewBuildingName,
  onDeleteBuilding,
  deleteBuilding,
  showCoopModal,
  addBuldings,
  onSelected,
  setNewBuildingName,
  setTempNewBuildingName,
}) {
  const [pageNum, setPageNum] = useState(1);
  const [maxItem] = useState(5);

  const prevPage = () => setPageNum(pageNum - 1);

  const nextPage = () => setPageNum(pageNum + 1);

  const lastI = pageNum * maxItem;
  const startI = lastI - maxItem;
  return (
    <>
      <PopupModal
        show={showCoopModal}
        heading="Condo/Coop Buildings"
        onHide={onCoopModalHide}
        bold={false}
      >
        <Row style={{ alignItems: "center" }}>
          <Col md="8" sm="9" xs="8" lg="10">
            <Form.Group style={styles.formInput}>
              <Form.Label> New Building Name</Form.Label>
              <Form.Control
                type="text"
                name="newBuildingName"
                value={newBuildingName}
                onChange={(e) => setNewBuildingName(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col style={{ marginTop: "1em" }}>
            <Button
              id="addNewBuilding"
              variant="outline-success"
              disabled={
                !newBuildingName.trim() ||
                Object.keys(coopUnits).includes(newBuildingName.trim())
              }
              onClick={() => addBuldings(newBuildingName)}
            >
              Add +
            </Button>
          </Col>
        </Row>

        {Object.keys(coopUnits).length > 0 && (
          <Row>
            <Col>
              <div>
                <h5 style={{ textAlign: "center", padding: "1em 0" }}>
                  <b>Condo/Coop Buildings</b>
                </h5>
                <ul className="buildings-list">
                  {Object.keys(coopUnits)
                    .slice(startI, lastI)
                    .map((unit) => (
                      <li
                        key={unit}
                        className="coop-units"
                        onClick={onSelected ? () => onSelected(unit) : () => {}}
                      >
                        <Row style={{ justifyContent: "space-between" }}>
                          <Col>
                            {coopUnits[unit].editBtn ? (
                              <>
                                <Button
                                  variant="danger"
                                  onClick={() => onEditBuilding(unit, false)}
                                  style={{ marginRight: "5px" }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="success"
                                  onClick={() => saveEditBuilding(unit)}
                                  disabled={
                                    !tempNewBuildingName.trim() ||
                                    Object.keys(coopUnits).includes(
                                      tempNewBuildingName.trim()
                                    )
                                  }
                                  style={{ marginLeft: "5px" }}
                                >
                                  Save
                                </Button>
                              </>
                            ) : (
                              <Button
                                onClick={() => onEditBuilding(unit, true)}
                              >
                                Edit
                              </Button>
                            )}
                          </Col>
                          <Col>
                            {coopUnits[unit].editBtn === true ? (
                              <Form.Group style={styles.formInput}>
                                <Form.Control
                                  type="text"
                                  name="tempNewBuildingName"
                                  value={tempNewBuildingName}
                                  autoFocus
                                  onChange={(e) =>
                                    setTempNewBuildingName(e.target.value)
                                  }
                                />
                              </Form.Group>
                            ) : (
                              <p
                                style={{
                                  textAlign: "center",
                                  cursor: "pointer",
                                  margin: " auto 0",
                                }}
                              >
                                {unit}
                              </p>
                            )}
                          </Col>
                          <Col>
                            <Button
                              variant="danger"
                              style={{ float: "right" }}
                              onClick={() => onDeleteBuilding(unit)}
                            >
                              Delete
                            </Button>
                          </Col>
                        </Row>
                      </li>
                    ))}
                </ul>
              </div>
            </Col>
          </Row>
        )}
        <Modal.Footer
          style={{
            justifyContent: "space-between",
            border: "none",
            padding: "0 .75rem",
          }}
        >
          <ArrowPagination
            totalItem={Object.keys(coopUnits).length}
            pageNum={pageNum}
            prevPage={prevPage}
            nextPage={nextPage}
            maxItem={maxItem}
          />
          <Button
            id="closeAddBuilding"
            variant="outline-danger"
            onClick={onCoopModalHide}
          >
            Close
          </Button>
        </Modal.Footer>
      </PopupModal>
      {Object.keys(coopUnits).length > 0 &&
        Object.keys(coopUnits).map((unit) => (
          <PopupModal
            show={coopUnits[unit].deleteModal}
            heading="Delete Building"
            onHide={() => onDeleteBuilding(unit, false)}
            bold={false}
            key={unit}
          >
            <h5 style={{ padding: "1em" }}>
              All collected metrics for "{unit}" will be lost and will be
              unrecoverable, proceed with delete?
            </h5>
            <Modal.Footer>
              <Button variant="danger" onClick={() => deleteBuilding(unit)}>
                Yes
              </Button>
              <Button onClick={() => onDeleteBuilding(unit, false)}>No</Button>
            </Modal.Footer>
          </PopupModal>
        ))}
    </>
  );
}
