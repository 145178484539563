import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { LoadingLoader, ModalTemplate } from "components";
import "./ProposalTab.scss";
import { ModalFooter } from "components/popup-modal/popup-modal";
import DatePickerNew from "components/DatePickerWithRemove";
import SignatureCanvas from "react-signature-canvas";
import { useDispatch, useSelector } from "react-redux";
import {
  changeProposalModalIndex,
  displayProposalTabModal,
  updateProposalStage,
} from "lib/redux/quote-proposals/quotes-proposals.actions";
import usProposalTab from "hooks/useProposalTab";
import useContactData from "hooks/useEmailContract";
import { AutoTextModalDetail } from "../AutoTextModal";
import { Sections } from "../Sections";
import {
  HeadingText,
  signCanavsHeight,
  signCanavsWidth,
} from "lib/constants/ui-const";
import { convertDate } from "utils/utils";

export default function ProposalTab(props) {
  const {
    sendEmail,
    SendEmailContract,
    AddDateToContract,
    contractDate,
    GetContractById,
    contractDateGet,
    isEmailSent,
    signatureSeller,
    signatureClient,
    pdflineItem,
    contractId,
    updateContractState,
    updateContractResponse,
    contractDetails,
    createContract,
    createContractDetails,
    edit_quote,
    firstTime,
    setFirstTime,
    MakeContract,
  } = useContactData();
  const { isAddSignature, addSignatureInvoice } = usProposalTab();

  const {
    changeProposalPercentage,
    changeProposalisSelected,
    changeProposalDisPlayText,
    isLockQuoteData,
    totalWithMarkUp,
    changeTotalValue,
    type,
    changeProposalStatus,
    metricId,
    tabKey,
  } = props;

  const [modalShow, show_modal] = useState();
  const [modalShowv2, show_modal_v2] = useState();

  const { proposalId, proposalIdx } = useSelector(
    (state) => state.activeProposal
  );

  const { gettingModalData, modalData, modalErr } = useSelector(
    (state) => state.quoteProposalModal
  );
  const { quoteProposalData } = useSelector((state) => state.quoteProposal);

  const { showProposalModal, proposalModalActiveIdx } = useSelector(
    (state) => state.quoteProposalTab
  );
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);

  const [modalDataDetail, setModalDataDetail] = useState(null);
  const [showModal, setShowModal] = useState(showProposalModal);

  console.log({ showProposalModal, showModal });

  const [singleData, setSingleData] = useState("");
  const [multipleData, setMultipleData] = useState([]);

  const [proposalData, setProposalData] = useState([]);
  const [btnsName, setBtnsName] = useState("");
  const [fixedStandardStatements, setFixedStandardStatements] = useState([]);
  const [leadRequestId, setLeadRequestId] = useState("");

  const [addClientSignature, setAddClientSignature] = useState();
  const [addSellerSignature, setAddSellerSignature] = useState();

  const [showProposalModalTab, setShowProposalModalTab] = useState(false);
  const [otherTexts, setOtherTexts] = useState([]);
  const [proposalModal, setProposalModal] = useState("");

  const [currentStageState, setCurrentStageState] = useState(0);

  // State to show email modal
  const [showEmailModal, setShowEmailModal] = useState(false);

  const BtnNames = type
    ? ["Start Over", "Finalize", "Email"]
    : ["Start Over", "Finalize", "Email", "Make Contract"];

  const [btnsState, setBtnsState] = type
    ? useState({
      [BtnNames[0]]: false,
      [BtnNames[1]]: false,
      [BtnNames[2]]: true,
    })
    : useState({
      [BtnNames[0]]: false,
      [BtnNames[1]]: false,
      [BtnNames[2]]: true,
      [BtnNames[3]]: true,
    });


  const [currentSection, setCurrentSection] = useState();
  // States to handle types of signature
  const [openHomeWonSignature, setOpenHomeWonSignature] = useState(false);
  const [openAuthorizedSignature, setOpenAuthorizedSignature] = useState(false);

  // Here are the buttons and it has all the informations like name an on click what to do
  const BtnsData = type
    ? [
      { name: BtnNames[0], onClick: () => onStartOver(true) },
      { onClick: displayFinalize, name: BtnNames[1] },
      { onClick: displayEmail, name: BtnNames[2] },
    ]
    : [
      { name: BtnNames[0], onClick: () => onStartOver(true) },
      { onClick: displayFinalize, name: BtnNames[1] },
      { onClick: displayEmail, name: BtnNames[2] },
      { onClick: () => onShowDateModal(), name: BtnNames[3] },
    ];


    console.log({ btnsState, BtnsData, type, contractId, quoteProposalData });

  const [dateSelected, setDateSelected] = useState("");
  const [dateSigned, setDateSigned] = useState(convertDate(new Date()));

  const [contractDates, setContractDates] = useState({
    dateSelected,
    dateSigned,
  });

  const [showDateModal, setShowDateModal] = useState(false);

  const signCanvasClient = useRef(null);
  const signCanvasSeller = useRef(null);

  useEffect(() => {
    if (multipleData.length > 0 || singleData.length > 0) {
      setShowModal(false);
    }
  }, [singleData, multipleData]);

  useEffect(() => {
    if (
      quoteProposalData &&
      quoteProposalData.length > 0 &&
      proposalIdx !== ""
    ) {
      console.log({ quoteProposalData, proposalIdx });
      const currentProposalData = quoteProposalData[proposalIdx];

      setFixedStandardStatements(currentProposalData.fixedStandardStatements);
      setOtherTexts([...currentProposalData.otherTexts]);
      setLeadRequestId(currentProposalData.requestId);
    }
  }, [quoteProposalData, proposalIdx]);

  console.log({ proposalData });

  useEffect(() => {
    console.log({isLockQuoteData, BtnNames});
    if (isLockQuoteData?.isLocked) {
      setBtnsState({ [BtnNames[2]]: false, [BtnNames[3]]: false });
    } 
    else {
      setBtnsState({ [BtnNames[2]]: false, [BtnNames[3]]: false });
    }
  }, [isLockQuoteData, proposalIdx]);

  useEffect(() => {
    setOtherTexts(quoteProposalData[0]?.otherTexts);
  }, []);

  const onChangeCurrentSection = (section) => setCurrentSection(section);

  const clearClientSign = () => {
    signCanvasClient.current.clear();
  };

  const clearSellerSign = () => {
    signCanvasSeller.current.clear();
  };

  let proposal_modal = (val) => {
    setProposalModal(val);
  };

  function displayFinalize() {
    const currentQuoteData = quoteProposalData[proposalIdx];
    console.log("currentStage displayfinalize");
    const updateData = {
      _id: currentQuoteData._id,
      quoteName: currentQuoteData.quoteName,
      markUp: currentQuoteData.markUp,
      isLocked: currentQuoteData.isLocked ? currentQuoteData.isLocked : false,
      isLockedTime: currentQuoteData.isLockedTime
        ? currentQuoteData.isLockedTime
        : "",
      isLockedTime: currentQuoteData.isLockedTime,
      total: currentQuoteData.total,
      currentStage: 1,
    };

    show_modal("not");
    setProposalModal("Not Start");
    handleUpdateContractState({
      isInSOverTab: false,
      isInFinalizeTab: true,
      isInEmailTab: false,
      isInMakeContractTab: false
    });
    edit_quote(updateData, metricId);
    const currentProposalData = quoteProposalData[proposalIdx];

    setOtherTexts(currentProposalData.otherTexts);
    setBtnsName(BtnNames[1]);
    onChangeCurrentSection(BtnsData[1].name);
    if (isLockQuoteData?.isLocked) {
      setBtnsState({ [BtnNames[2]]: false, [BtnNames[3]]: false });
    }
  }

  function displayEmail() {
    const currentQuoteData = quoteProposalData[proposalIdx];
    const updateData = {
      _id: currentQuoteData._id,
      quoteName: currentQuoteData.quoteName,
      markUp: currentQuoteData.markUp,
      isLocked: currentQuoteData.isLocked ? currentQuoteData.isLocked : false,
      isLockedTime: currentQuoteData.isLockedTime
        ? currentQuoteData.isLockedTime
        : "",
      isLockedTime: currentQuoteData.isLockedTime,
      total: currentQuoteData.total,
      currentStage: 2,
    };

    show_modal("not");
    setProposalModal("Not Start");
    handleUpdateContractState({
      isInSOverTab: false,
      isInFinalizeTab: false,
      isInEmailTab: true,
      isInMakeContractTab: false
    });
    edit_quote(updateData, metricId);
    setShowEmailModal(true);
    setOtherTexts(currentQuoteData.otherTexts);
    setBtnsName(BtnNames[2]);
    onChangeCurrentSection(BtnsData[2].name);
  }

  function displayStartOver() {
    show_modal_v2("Start Over");
    setProposalModal("Start");
    onChangeCurrentSection(BtnsData[0].name);
    handleUpdateContractState({
      isInSOverTab: true,
      isInFinalizeTab: false,
      isInEmailTab: false,
      isInMakeContractTab: false
    });
  }

  function onShowDateModal() {
    const currentQuoteData = quoteProposalData[proposalIdx];
    const updateData = {
      _id: currentQuoteData._id,
      quoteName: currentQuoteData.quoteName,
      markUp: currentQuoteData.markUp,
      isLocked: currentQuoteData.isLocked ? currentQuoteData.isLocked : false,
      isLockedTime: currentQuoteData.isLockedTime
        ? currentQuoteData.isLockedTime
        : "",
      isLockedTime: currentQuoteData.isLockedTime,
      total: currentQuoteData.total,
      currentStage: 3,
    };

    GetContractById({ leadRequestId: leadRequestId });
    MakeContract({
      leadRequestId: leadRequestId,
      isEmailSent: false,
    });
    show_modal("not");
    setProposalModal("Not Start");
    handleUpdateContractState({
      isInSOverTab: false,
      isInFinalizeTab: false,
      isInEmailTab: false,
      isInMakeContractTab: true
    });
    edit_quote(updateData, metricId);

    setOtherTexts(currentQuoteData.otherTexts);
    setBtnsName(BtnNames[3]);
    {
      if (contractDateGet === "") {
        setShowDateModal(true);
      } else setShowDateModal(false);
    }
    onChangeCurrentSection(BtnsData[3].name);
  }

  const onSelectSingleData = (dataId) => {
    if (singleData === dataId) {
      setSingleData("");
    } else {
      setSingleData(dataId);
    }
  };

  const changeModalIndex = (activeIdx) => {
    dispatch(changeProposalModalIndex(activeIdx));
  };

  const increaseCurrentIdx = () => {
    const newIdx = proposalModalActiveIdx + 1;
    onHideModal();
    changeModalIndex(newIdx);
    if (newIdx < modalData.length) {
      selectModalData(newIdx);
      setSingleData("");
      setMultipleData([]);
    } else {
      if (firstTime) {
        setFirstTime(false, proposalId); //false
      }
    }
  };
  const onCancelModal = () => {
    increaseCurrentIdx();
  };

  const onInsertSingleData = async () => {
    if (singleData) {
      const selectedData = modalDataDetail.data.filter(
        (data) => singleData === data._id
      );
      let {
        _id,
        _sid,
        excludedBuildings,
        excludedSections,
        fixedStandardStatements,
        isLocked,
        isLockedTime,
        isProposalCreated,
        otherTexts,
        leadRequestId,
        makeContract,
        markUp,
        outPut,
        pdf,
        proposalsNotSent,
        proposalsSent,
        quoteName,
        requestId,
        stateTax,
        total,
        inserted_data,
      } = quoteProposalData[proposalIdx];

      otherTexts = [...otherTexts, ...selectedData];
      inserted_data = selectedData;

      await edit_quote(
        {
          _id,
          _sid,
          excludedBuildings,
          excludedSections,
          fixedStandardStatements,
          isLocked,
          isLockedTime,
          isProposalCreated,
          leadRequestId,
          makeContract,
          markUp,
          otherTexts,
          outPut,
          pdf,
          proposalsNotSent,
          proposalsSent,
          quoteName,
          requestId,
          stateTax,
          total,
          inserted_data,
        },
        metricId
      );
      addOtherText(selectedData);
    }
    increaseCurrentIdx();
  };
  const onSelectMultipleData = (dataId) => {
    const temp = [...multipleData];
    if (multipleData.includes(dataId)) {
      temp.splice(temp.indexOf(dataId), 1);
    } else {
      temp.push(dataId);
    }
    setMultipleData(temp);
  };

  const addOtherText = async (textData) => {
    let allData = [...otherTexts, ...textData];
    let order_datas = allData
      .filter((val) => Object.keys(val).includes("order") === true)
      .sort((a, b) => a.order - b.order);
    let non_order_data = allData.filter(
      (val) => Object.keys(val).includes("order") != true
    );
    let [x, ...rest] = non_order_data;
    allData = [x, ...order_datas, ...rest];
    allData = allData
      .filter((elem) => elem.isSelected === true)
      .sort((a, b) => a.order - b.order);
    setOtherTexts(allData);
  };

  const onChangeOtherText = (data) => {
    const currentQuoteData = quoteProposalData[proposalIdx];
    setOtherTexts(data);
    const updateData = {
      _id: currentQuoteData._id,
      quoteName: currentQuoteData.quoteName,
      markUp: currentQuoteData.markUp,
      isLocked: currentQuoteData.isLocked ? currentQuoteData.isLocked : false,
      isLockedTime: currentQuoteData.isLockedTime
        ? currentQuoteData.isLockedTime
        : "",
      isLockedTime: currentQuoteData.isLockedTime,
      total: currentQuoteData.total,
      otherTexts: data,
    };
    edit_quote(updateData, metricId);
  };

  const onChangeFixedText = (data) => {
    const currentQuoteData = quoteProposalData[proposalIdx];

    setFixedStandardStatements(data);
    const updateData = {
      _id: currentQuoteData._id,
      quoteName: currentQuoteData.quoteName,
      markUp: currentQuoteData.markUp,
      isLocked: currentQuoteData.isLocked ? currentQuoteData.isLocked : false,
      isLockedTime: currentQuoteData.isLockedTime
        ? currentQuoteData.isLockedTime
        : "",
      isLockedTime: currentQuoteData.isLockedTime,
      total: currentQuoteData.total,
      fixedStandardStatements: data,
    };
    edit_quote(updateData, metricId);
  };

  const onInsertMultipleData = async () => {
    if (multipleData.length > 0) {
      const selectedData = modalDataDetail.data.filter((data) =>
        multipleData.includes(data._id)
      );

      let {
        _id,
        _sid,
        excludedBuildings,
        excludedSections,
        fixedStandardStatements,
        isLocked,
        isLockedTime,
        isProposalCreated,
        otherTexts,
        leadRequestId,
        makeContract,
        markUp,
        outPut,
        pdf,
        proposalsNotSent,
        proposalsSent,
        quoteName,
        requestId,
        stateTax,
        total,
        inserted_data,
      } = quoteProposalData[proposalIdx];

      const sortedSelectedData = selectedData.sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!b.order) return 1;
        if (!a.order) return -1;
        return a.order > b.order;
      });

      let i = 0;
      let j = 0;
      while (i < otherTexts.length) {
        if (j >= sortedSelectedData.length) {
          break;
        }
        if (otherTexts[i].order) {
          if (otherTexts[i].order >= sortedSelectedData[j].order) {
            otherTexts.splice(i - 1, 1, sortedSelectedData[j]);
            j++;
          }
        }
        i++;
      }

      if (j < sortedSelectedData.length) {
        otherTexts = [...otherTexts, ...sortedSelectedData.slice(j)];
      }

      inserted_data = selectedData;

      await edit_quote(
        {
          _id,
          _sid,
          excludedBuildings,
          excludedSections,
          fixedStandardStatements,
          isLocked,
          isLockedTime,
          isProposalCreated,
          leadRequestId,
          makeContract,
          markUp,
          otherTexts,
          outPut,
          pdf,
          proposalsNotSent,
          proposalsSent,
          quoteName,
          requestId,
          stateTax,
          total,
          inserted_data,
        },
        metricId
      );
    }

    increaseCurrentIdx();
  };

  const changeModalDisplay = (value) => {
    setShowProposalModalTab(value);
    dispatch(displayProposalTabModal(value));
  };

  const onHideModal = () => changeModalDisplay(false);
  const onShowModal = () => changeModalDisplay(true);

  const displayDataModal = (proposalModalData) => {
    setModalDataDetail(proposalModalData);
    onShowModal();
  };

  const selectModalData = (idx) => {
    const proposalModalData = modalData[idx];
    displayDataModal(proposalModalData);
  };

  /**
   * Function to add signature for both client and owner
   */
  const addSignature = () => {
    if (openHomeWonSignature) {
      setAddClientSignature(signCanvasClient.current?.toDataURL());

      addSignatureInvoice({
        client: signCanvasClient.current?.toDataURL(),
        id: leadRequestId,
        imageType: "client",
      });
      setOpenHomeWonSignature(false);

      clearClientSign();
    }

    if (openAuthorizedSignature) {
      setAddSellerSignature(signCanvasSeller.current?.toDataURL());

      addSignatureInvoice({
        seller: signCanvasSeller.current?.toDataURL(),
        id: leadRequestId,
        imageType: "seller",
      });

      setOpenAuthorizedSignature(false);
      clearSellerSign();
    }
  };
console.log({modalData});
  useEffect(() => {
    /**
     * i have implement the new condition for the proposal modal the previus are commented
     */
    if (modalData && modalData.length > 0 && modalData?.length !== null) {
      selectModalData(proposalModalActiveIdx);
    }
  }, [modalData, proposalModalActiveIdx]);

  const onReload = () => { };

  // Fetching contract details
  useEffect(() => {
    if (leadRequestId) {
      handleContractId();
      handleUpdateContractDetails();
    }
  }, [
    leadRequestId,
    sendEmail,
    contractDate,
    isAddSignature,
    updateContractResponse,
    createContract,
  ]);

  // Get contract by id
  const handleContractId = () => {
    GetContractById({
      leadRequestId: leadRequestId,
    });
  };

  const handleUpdateContractDetails = () => {
    createContractDetails({
      leadRequestId: leadRequestId,
    });
  };

  // Update the button status
  const handleUpdateContractState = (
    { isInSOverTab,
      isInFinalizeTab,
      isInEmailTab,
      isInMakeContractTab }
  ) => {
    if (leadRequestId)
      updateContractState({
        leadRequestId: leadRequestId,
        startOver: isInSOverTab,
        finalize: isInFinalizeTab,
        email: isInEmailTab,
        makeContract: isInMakeContractTab,
      });

  };

  const handleContractDate = () => {
    AddDateToContract({
      proposalId: proposalId,
      leadRequestId: leadRequestId,
      date: dateSelected,
    });

    setContractDates({ dateSelected, dateSigned });
    setShowDateModal(false);
  };

  // click on send for the while apply click on the email dialog
  const handleOnClickSendEmail = () => {
    SendEmailContract({
      leadRequestId: leadRequestId,
    });
  };

  const switchModalData = () => {

    console.log({modalDataDetail, currentSection, btnName: BtnNames[0], firstTime, showProposalModal});
    if (
      modalDataDetail &&
      !(currentSection === BtnNames[0] && firstTime === true)
    ) {
      return (
        <AutoTextModalDetail
          modalDataDetail={modalDataDetail}
          onCancelModal={onCancelModal}
          onInsertMultipleData={onInsertMultipleData}
          onInsertSingleData={onInsertSingleData}
          onSelectMultipleData={onSelectMultipleData}
          onSelectSingleData={onSelectSingleData}
          multipleData={multipleData}
          singleData={singleData}
          showModal={showModal}
          onHideModal={onHideModal}
          showProposalModalTab={showProposalModalTab}
          showProposalModal={showProposalModal}
        />
      );
    }
    return null;
  };

  const onHideDateModal = () => {
    setShowDateModal(false);
  };

  // Call this function if start over clicked
  function onStartOver(onClick = false) {

    const currentQuoteData = quoteProposalData[proposalIdx];

    const updateData = {
      _id: currentQuoteData._id,
      quoteName: currentQuoteData.quoteName,
      markUp: currentQuoteData.markUp,
      isLocked: currentQuoteData.isLocked ? currentQuoteData.isLocked : false,
      isLockedTime: currentQuoteData.isLockedTime
        ? currentQuoteData.isLockedTime
        : "",
      total: currentQuoteData.total,
      currentStage: 0,
    };

    if (onClick) {
      setFirstTime(true, proposalId);
      edit_quote(updateData, metricId);
    } else {
      dispatch(updateProposalStage(metricId));
    }

    displayStartOver();

    // handleUpdateContractState({
    //   isInSOverTab: false,
    //   isInFinalizeTab: false,
    //   isInEmailTab: false,
    //   isInMakeContractTab: false
    // });

    setProposalData([]);
    setData(null);
    setOtherTexts([]);
    setBtnsName(BtnNames[0]);
    dispatch(changeProposalModalIndex(0));
    dispatch(displayProposalTabModal(true));

    const currentProposalData = quoteProposalData[proposalIdx];

    setOtherTexts(currentProposalData.otherTexts);

    setSingleData("");
    setMultipleData([]);

  }

  useEffect(() => {
    onStartOver();
    if (!contractDetails) {
    }
  }, []);

  useEffect(() => {
    const { currentStage } = quoteProposalData[proposalIdx];
    console.log({ currentStage });
    if (currentStage !== currentStageState) {
      setCurrentStageState(currentStage);
    }
  }, [quoteProposalData, proposalIdx]);

  // useEffect(() => {
  //   const currentStage = currentStageState;
  //   if (currentStage !== quoteProposalData[proposalIdx].currentStage) {
  //     return;
  //   }

  //   console.log({currentStage});

  //   if (currentStage || currentStage === 0) {
  //     if (currentStage === 0) {
  //       dispatch(changeProposalModalIndex(0));
  //       onStartOver();
  //     }
  //     if (currentStage === 1) {
  //       console.log("currentStage finalize");
  //       displayFinalize();
  //     }
  //     if (currentStage === 2) {
  //       console.log("currentStage email");
  //       displayEmail();
  //       setBtnsState({ [BtnNames[2]]: false });
  //     }
  //     if (currentStage === 3) {
  //       console.log("currentStage dd");
  //       onShowDateModal();
  //       setBtnsState({ [BtnNames[3]]: false });
  //     }
  //   }
  // }, [currentStageState]);

  console.log({ currentStageState, quoteProposalData, type });

  const updateFixedStandardStatementsPercentage = (idx, updatedPercentage) => {
    fixedStandardStatements[idx].percentage = updatedPercentage;
    setFixedStandardStatements(fixedStandardStatements);
    changeProposalPercentage(fixedStandardStatements);
  };

  const updateFixedStandardStatementsisSelected = (idx, updatedisSelected) => {
    otherTexts[idx].isSelected = updatedisSelected;
    setOtherTexts(otherTexts);
    changeProposalisSelected(otherTexts);
  };

  const updateFixedStandardStatementdisplayText = (idx, updateddisplayText) => {
    otherTexts[idx].displayText = updateddisplayText;
    setOtherTexts(otherTexts);
    changeProposalDisPlayText(otherTexts);
  };

  if (loading) {
    return <LoadingLoader />;
  }

  if (error) {
    return (
      <div className="__reload_wrapper">
        <button className="__reload_btn" onClick={onReload}>
          Reload Again.
        </button>
      </div>
    );
  }

  return (
    <div>
      <Row>
        {BtnsData.map((btn) => (
          <Col key={btn.name}>
            <Button
              onClick={btn.onClick}
              style={
                btn.name === btnsName
                  ? { width: "100%", backgroundColor: "#0657ad" }
                  : { width: "100%" }
              }
              disabled={
                // Condition 1: Disable if the button state is true
                btnsState[btn.name] ||

                // Condition 2: Disable under specific contract and button conditions
                (
                  // Contract conditions:
                  contractId && // A contract ID exists
                  contractId.contract.paymentMethod !== "" && // Payment method is selected in the contract

                  // Quote proposal conditions:
                  !quoteProposalData[proposalIdx].makeContract && // Contract hasn't been made
                  btn.name === BtnsData[3]?.name // Button name matches a specific condition
                )
              }
            >
              {btn.name}
            </Button>
          </Col>
        ))}
      </Row>

      {/* Contents For the tabs */}
      <Sections
        showEmailModal={showEmailModal}
        setShowEmailModal={setShowEmailModal}
        signatureSeller={signatureSeller}
        signatureClient={signatureClient}
        HeadingText={HeadingText}
        proposalData={proposalData}
        setProposalData={setProposalData}
        otherTexts={otherTexts}
        fixedStandardStatements={fixedStandardStatements}

        setOtherTexts={onChangeOtherText}
        setFixedText={onChangeFixedText}

        showModal={showModal}
        onHideModal={onHideModal}
        switchModalData={switchModalData}
        currentSection={currentSection}
        BtnsData={BtnsData}

        updateFixedStandardStatementsPercentage={
          updateFixedStandardStatementsPercentage
        }
        updateFixedStandardStatementsisSelected={
          updateFixedStandardStatementsisSelected
        }
        updateFixedStandardStatementdisplayText={
          updateFixedStandardStatementdisplayText
        }

        isLockQuoteData={isLockQuoteData}
        totalWithMarkUp={totalWithMarkUp}
        setOpenAuthorizedSignature={setOpenAuthorizedSignature}
        setOpenHomeWonSignature={setOpenHomeWonSignature}
        handleOnClickSendEmail={handleOnClickSendEmail}
        SendEmailContract={SendEmailContract}
        AddDateToContract={AddDateToContract}
        leadRequestId={leadRequestId}

        isEmailSent={isEmailSent}
        contractDate={contractDate}
        sendEmail={sendEmail}

        pdflineItem={pdflineItem}
        addClientSignature={addClientSignature}
        addSellerSignature={addSellerSignature}
        contractId={contractId}
        modalShow={modalShow}
        modalShowv2={modalShowv2}
        quoteProposalData={quoteProposalData}
        proposalModal={proposalModal}
        proposal_modal={proposal_modal}
        type={type}
        changeProposalStatus={changeProposalStatus}
        firstTime={firstTime}
        signingDate={contractDates}
      />

      {/**
       * Modal For singature
       */}
      <ModalTemplate
        size="xl"
        onHide={() => {
          if (openHomeWonSignature) {
            setOpenHomeWonSignature(false);
          }
          if (openAuthorizedSignature) {
            setOpenAuthorizedSignature(false);
          }
        }}
        show={openHomeWonSignature || openAuthorizedSignature}
        heading={
          openHomeWonSignature
            ? "Home Owners Signature"
            : "Company Authorize Signature"
        }
      >
        <Row className="m-4 overflow-y-auto">
          <div
            className="border overflow-hidden"
            style={{ width: "100%", height: signCanavsHeight }}
          >
            <SignatureCanvas
              ref={(ref) => {
                if (openHomeWonSignature) {
                  signCanvasClient.current = ref;
                }
                if (openAuthorizedSignature) {
                  signCanvasSeller.current = ref;
                }
              }}
              // onEnd={(event) => {
              // }}
              penColor="black"
              canvasProps={{
                height: signCanavsHeight,
                width: signCanavsWidth,
                className: "__sigCanvas",
              }}
            />
          </div>
        </Row>

        <ModalFooter
          onCancel={() => {
            if (openAuthorizedSignature) {
              setOpenAuthorizedSignature(false);
            } else {
              setOpenHomeWonSignature(false);
            }
          }}
          onSubmit={() => {
            addSignature();
          }}
          submitText="Apply"
        />
      </ModalTemplate>

      {/**
       * Modal For Date
       */}
      {showDateModal && !contractDateGet && (
        <ModalTemplate
          onHide={onHideDateModal}
          show={showDateModal}
          heading="Consumer Right to Cancel Within Three (3) Business Days"
        >
          <Row className="m-4">
            <Col
              className="d-flex align-items-center"
              style={{ justifyContent: "space-around", marginBottom: "1rem" }}
            >
              <DatePickerNew
                label="Signing Date"
                value={dateSigned}
                setValue={setDateSigned}
                disabled={false}
              />
              <DatePickerNew
                label="Select Date"
                value={dateSelected}
                setValue={setDateSelected}
                disabled={false}
              />
            </Col>
          </Row>

          <ModalFooter
            onCancel={onHideDateModal}
            onSubmit={() => {
              handleContractDate();
            }}
            submitText="Apply"
          />
        </ModalTemplate>
      )}
    </div>
  );
}
