import React from 'react'

import { Form } from 'react-bootstrap'

export default function InputWithSymbol({
	changeValue,
	disabled,
	value,
	styles,
	onBlur,
	...rest
}) {


	return (
		<Form.Group>
			<Form.Control
				onChange={changeValue}
				disabled={disabled}
				type='text'
				value={value}
				style={styles}
				onBlur={onBlur}
				{...rest}

			/>
		</Form.Group>
	)
}
