import React, { Component } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faChevronLeft,
  faCameraRetro,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { uploadData } from "lib/redux/metrics/metrics.actions";
import { connect } from "react-redux";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import PopupModal from "components/popup-modal/popup-modal";
import "./Images.scss";
import RotateSVG from "./rotate.svg";
import ImageCarasoul from "components/ImageCarasoul/ImageCarasoul";
import { getImage } from "services/offlineComputing/models/offlineImageModel";

function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(",")[1]);
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

const DeleteModal = ({ deleteModal, hideDeleteModal, deleteImage }) => {
  if (!deleteModal) return null;
  return (
    <PopupModal
      show={deleteModal}
      heading="Delete Confirmation"
      onHide={hideDeleteModal}
      bold={true}
      centered={false}
      styles={{ border: "none" }}
    >
      <h5 style={{ color: "red", fontWeight: "bold" }}>
        This is a permanent delete, this image will be lost. Continue with
        delete?
      </h5>

      <Modal.Footer
        style={{
          justifyContent: "flex-end",
          border: "none",
        }}
      >
        <Button
          style={{ width: "130px", height: "50px" }}
          variant="danger modified-btn"
          onClick={deleteImage}
        >
          Yes, Delete
        </Button>
        <Button
          style={{ width: "130px", height: "50px" }}
          onClick={hideDeleteModal}
        >
          No
        </Button>
      </Modal.Footer>
    </PopupModal>
  );
};
class Images extends Component {
  constructor(props) {
    super(props);
    this.state = {
      camera: false,
      imgModal: false,
      video: null,
      loadingCamera: false,
      frontFace: false,
      displayBtns: true,
      err: "",
      selectedImgs: "",
      displaySlides: false,
      slidesImages: [],
      selectedSection: "",
      selectedCol: "",
      deleteModal: false,
      activeIdx: 0,
      activeSection: null,
      uploadedPercentage: 0,
      stream: null,
      sectionData: this.props.sectionData,
    };
    this.imagePicker = null;
    this.camera = null;
    this.canvas = null;
    this.picture = null;
    this.track = null;
  }
  async componentDidMount() {
    if (navigator.onLine) return;
    const { sectionData } = this.state;

    const newSectionData = sectionData.map(async (data, i) => {
      const imgList = data.imagesList;
      const newImagesList = imgList.map(async (img) => {
        if (img) {
          if ("offlineKey" in img) {
            const blobFile = await getImage(img.offlineKey);
            const newUri = URL.createObjectURL(blobFile);
            return { ...img, uri: newUri };
          }
        }
        return img;
      });

      const temp1 = await Promise.all(newImagesList);
      return { ...data, imagesList: temp1 };
    });

    const temp = await Promise.all(newSectionData);
    this.setState({ sectionData: temp });
  }
  async componentDidUpdate(prevProps) {
    const { sectionData } = this.props;
    if (prevProps.sectionData != this.props.sectionData) {
      this.setState({ ...this.state, sectionData: sectionData });
    }
  }

  getIcon = (name) => <FontAwesomeIcon icon={name} />;

  cameraStart = () => {
    const { frontFace } = this.state;

    this.setState({ camera: true, loadingCamera: true, err: "" }, async () => {
      if (!("mediaDevices" in navigator)) {
        navigator.mediaDevices = {};
      }

      if (!("getUserMedia" in navigator.mediaDevices)) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          var getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented!")
            );
          }

          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: frontFace ? "user" : "environment" },
          audio: false,
        });
        this.setState({ stream: stream });
        if (stream) {
          this.camera.srcObject = stream;
          this.track = stream.getTracks()[0];
          setTimeout(() => this.setState({ loadingCamera: false }), 500);
        }
      } catch (err) {
        this.setState({ err: err.message });
      }
    });
  };

  capture = () => {
    const context = this.canvas;
    context.width = this.camera.videoWidth;
    context.height = this.camera.videoHeight;
    context.getContext("2d").drawImage(this.camera, 0, 0);

    this.picture.src = context.toDataURL("image/webp");
    this.picture.classList.add("taken");
    setTimeout(() => this.setState({ displayBtns: false }), 150);
  };

  resetCamera = () => {
    if (this.picture) {
      this.picture.classList.remove("taken");
      this.picture.src = null;
    }

    this.setState({ displayBtns: true });
  };

  closeCamera = () => {
    if (this.track) {
      this.track.stop();
    }
    this.setState({ camera: false });
  };
  rotateCamera = () => {
    const { stream } = this.state;
    stream.getTracks().forEach(function (track) {
      track.stop();
    });
    this.setState({ frontFace: !this.state.frontFace }, () => {
      this.cameraStart();
    });
  };
  hideImageModal = () => {
    this.setState({ imgModal: false });
  };

  selectImage = () => {
    const { selectedSection, selectedCol } = this.state;
    const file = this.imagePicker.files[0];
    if (file.type.split("/")[0] !== "image") return;
    if (file) {
      this.addSelectedImg(selectedSection, selectedCol, { isLoading: true });
      this.handleFileUpload(selectedSection, selectedCol, file);
      this.hideImageModal();

      // const reader = new FileReader()
      // const url = reader.readAsDataURL(file)
      // reader.onloadend = function (e) {
      // 	this.addSelectedImg(reader.result)
      // }.bind(this)
    }
  };

  handleFileUpload = (selectedSection, selectedCol, file) => {
    const here = this;
    const data = new FormData();
    data.append("uploads", file);
    this.props
      .dispatch(
        uploadData(data, this.props.metricId, {
          onUploadProgress: function (event) {
            let progress = Math.round((event.loaded * 100) / event.total);
            here.setState({ uploadedPercentage: progress });
          },
        })
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.status) {
          this.addSelectedImg(selectedSection, selectedCol, {
            isLoading: false,
            ...data.data[0],
          });
        } else {
          this.addSelectedImg(selectedSection, selectedCol, {
            error: true,
            img: file,
          });
        }
      })
      .catch((err) => {
        this.addSelectedImg(selectedSection, selectedCol, {
          error: true,
          img: file,
        });
      })
      .finally(() => here.setState({ uploadedPercentage: 0 }));
  };

  savePhoto = () => {
    const { selectedSection, selectedCol } = this.state;

    const pic = dataURItoBlob(this.picture.src);

    this.addSelectedImg(selectedSection, selectedCol, { isLoading: true });
    this.closeCamera();
    this.resetCamera();
    this.hideImageModal();
    this.handleFileUpload(selectedSection, selectedCol, pic);
  };

  addSelectedImg = (selectedSection, selectedCol, url) => {
    this.props.addImageIntoSection(selectedSection, selectedCol, url);
  };

  checkRefImage = async (checked, image) => {
    this.props.addCheckToImage(checked, image);
  };

  seeImages = (imagesList, section, idx, data) => {
    let images = [];
    let id = 0;
    let count = 0;
    imagesList.map((img, i) => {
      if (img) {
        if (i === idx) {
          count = id;
        }
        images.push({
          ...img,
          section,
          idx: i,
        });
        id++;
      }
    });
    this.setState({
      slidesImages: images,
      displaySlides: true,
      activeIdx: count,
      activeSection: data,
    });
  };

  addRemoveImage = (section, selectedCol) => {
    this.setRowAndCol(section, selectedCol);
    this.openDeleteModal();
  };

  handleRetry = (selectedSection, selectedCol, file) => {
    const here = this;
    this.addSelectedImg(selectedSection, selectedCol, {
      isLoading: true,
    });
    const data = new FormData();
    data.append("uploads", file);
    this.props
      .dispatch(
        uploadData(data, {
          onUploadProgress: function (event) {
            let progress = Math.round((event.loaded * 100) / event.total);
            here.setState({ uploadedPercentage: progress });
          },
        })
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.status) {
          this.addSelectedImg(selectedSection, selectedCol, {
            isLoading: false,
            ...data.data[0],
          });
        } else {
          this.addSelectedImg(selectedSection, selectedCol, {
            error: true,
            img: file,
          });
        }
      })
      .catch((err) => {
        this.addSelectedImg(selectedSection, selectedCol, {
          error: true,
          img: file,
        });
      })
      .finally(() => here.setState({ uploadedPercentage: 0 }));
  };

  displayImageOrBtn = (imagesList, section, data) => {
    return imagesList.map((img, i) => {
      if (img) {
        if (img.error) {
          return (
            <div key={i} className="img-prev-wrap">
              <div className="loadingDot-sm-wrapper">
                <div className="warpper-error">
                  {this.getIcon(faExclamationTriangle)}
                </div>
                <button
                  className="_retry"
                  onClick={() => this.handleRetry(section, i, img.img)}
                >
                  RETRY
                </button>
              </div>
              <button
                onClick={() => this.addRemoveImage(section, i)}
                aria-label="delete"
                className="delete-img"
                disabled={this.props.isQuoteLock}
              >
                {this.getIcon(faTimes)}
              </button>
            </div>
          );
        }

        return (
          <div key={i} className="img-prev-wrap">
            {img.isLoading ? (
              <>
                <div className="loadingDot-sm-wrapper">
                  <div className="loadingDot-sm"></div>
                  <div className="upload-progress">
                    <div
                      style={{ width: `${this.state.uploadedPercentage}%` }}
                    ></div>
                  </div>
                </div>

                <button
                  onClick={() => this.addRemoveImage(section, i)}
                  aria-label="delete"
                  className="delete-img"
                  disabled={this.props.isQuoteLock}
                >
                  {this.getIcon(faTimes)}
                </button>
              </>
            ) : (
              <>
                <div
                  onClick={() => this.seeImages(imagesList, section, i, data)}
                  aria-label="See Image"
                  role="button"
                  className="img-preview"
                  style={{
                    background: `url('${img.uri}')`,
                  }}
                />
                {this.props.coverImage() === img.uri && (
                  <span aria-label="ref-image" className="check-img">
                    {this.getIcon(faCheck)}
                  </span>
                )}
                <button
                  onClick={() => this.addRemoveImage(section, i)}
                  aria-label="delete"
                  className="delete-img"
                  disabled={this.props.isQuoteLock}
                >
                  {this.getIcon(faTimes)}
                </button>
              </>
            )}
          </div>
        );
      }
      const disabledBtn =
        i > 0
          ? imagesList[i - 1] === null || imagesList[i - 1] === undefined
          : false;
      return (
        <button
          key={i}
          onClick={() => this.openAddImageModal(section, i)}
          aria-label="Add Image"
          className="addBtn"
          disabled={disabledBtn || this.props.isQuoteLock}
        >
          {this.getIcon(faCameraRetro)}
        </button>
      );
    });
  };
  openAddImageModal = (section, imgId) => {
    this.setState({
      selectedSection: section,
      selectedCol: imgId,
      imgModal: true,
    });
  };

  closeSlides = () =>
    this.setState({ displaySlides: false, activeSection: null });
  removeImageFromSlides = (id, imgId) => {
    this.removeImage(id, imgId);
    this.hideDeleteModal();
    this.closeSlides();
  };

  getTableData = () => {
    const { getLabel, getElevationLetters, getDataLabel } = this.props;
    const { sectionData } = this.state;
    let tableData = [];
    sectionData?.map((data, i) => {
      const id = i + 1,
        floor = getLabel(data[this.props.label.toLowerCase()]),
        elevation =
          `${getElevationLetters(data.elevation)}` +
          "\n" +
          `${getDataLabel(data.elevation_details)}`;
      return tableData.push({
        id,
        floor,
        elevation,
        images: data.imagesList && (
          <div className="selected-imgs-group">
            {this.displayImageOrBtn(data.imagesList, data.sectionId, {
              id,
              floor,
              elevation,
            })}
          </div>
        ),
      });
    });
    return tableData;
  };

  displayTable = () => {
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      totalSize: this.props.sectionData?.length,
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              hover
              keyField="id"
              bordered={false}
              headerWrapperClasses="images_tbl"
              bodyClasses="images_tbl_body"
              data={this.getTableData()}
              columns={[
                {
                  dataField: "id",
                  text: "Sec",
                },
                {
                  dataField: "floor",
                  text: this.props.label,
                },
                {
                  dataField: "elevation",
                  text: "Elevation",
                },
                {
                  dataField: "images",
                  text: " " + "Images " + " ",
                },
              ]}
              {...paginationTableProps}
            />
          </div>
        </div>

        {this.props.sectionData?.length > 10 && (
          <PaginationListStandalone {...paginationProps} />
        )}
      </div>
    );

    return (
      <>
        <div style={{ width: "100%", overflowX: "auto" }}>
          <PaginationProvider
            pagination={paginationFactory(options)}
            className="pagignationboostrap"
          >
            {contentTable}
          </PaginationProvider>
        </div>
      </>
    );
  };

  hideDeleteModal = () => {
    this.setState({ deleteModal: false });
  };

  deleteImage = () => {
    const { selectedSection, selectedCol } = this.state;
    this.props.deleteImageFromSection(selectedSection, selectedCol, undefined);
    this.hideDeleteModal();
    this.closeSlides();
  };

  setRowAndCol = (row, col) => {
    this.setState({ selectedSection: row, selectedCol: col });
  };

  deleteModal = () => (
    <PopupModal
      show={this.state.deleteModal}
      heading="Delete Confirmation 1"
      onHide={this.hideDeleteModal}
      bold={true}
      centered={false}
      styles={{ border: "none" }}
    >
      <h5 style={{ color: "red", fontWeight: "bold" }}>
        This is a permanent delete, this image will be lost. Continue with
        delete?
      </h5>

      <Modal.Footer
        style={{
          justifyContent: "flex-end",
          border: "none",
        }}
      >
        <Button
          style={{ width: "130px", height: "50px" }}
          variant="danger modified-btn"
          onClick={this.deleteImage}
        >
          Yes, Delete
        </Button>
        <Button
          style={{ width: "130px", height: "50px" }}
          onClick={this.hideDeleteModal}
        >
          No
        </Button>
      </Modal.Footer>
    </PopupModal>
  );

  openDeleteModal = () => this.setState({ deleteModal: true });

  render() {
    const Header = this.props.Header;
    const {
      camera,
      imgModal,
      loadingCamera,
      displayBtns,
      err,
      displaySlides,
      slidesImages,
      activeIdx,
      activeSection,
    } = this.state;

    if (displaySlides) {
      return (
        <>
          <div className="metric">
            <Row>
              <Col>
                <Header />
              </Col>
              <Row style={{ justifyContent: "flex-end" }}>
                <Button
                  style={{ float: "right", margin: "0 1em", width: "150px" }}
                  onClick={this.closeSlides}
                >
                  Close
                </Button>
              </Row>
            </Row>
            {activeSection && (
              <Row>
                <Col>
                  <label className="btnheading">Section : </label>{" "}
                  {activeSection.id}
                </Col>
                <Col>
                  <label className="btnheading">{this.props.label} : </label>{" "}
                  {activeSection.floor}
                </Col>
                <Col>
                  <label className="btnheading">Elevation : </label>{" "}
                  {activeSection.elevation}
                  {/* <Row>
                    <div>
                      <label className="btnheading">Elevation:</label>{" "}
                    </div>
                    <div style={{ whiteSpace: "pre", textAlign: "center" }}>
                      {activeSection.elevation}
                    </div>
                  </Row> */}
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <ImageCarasoul
                  activeIdx={activeIdx}
                  images={slidesImages}
                  handleChange={(checked, image) =>
                    this.checkRefImage(checked, image)
                  }
                  openDeleteModal={this.openDeleteModal}
                  setRowAndCol={this.setRowAndCol}
                  coverImage={this.props.coverImage}
                  isQuoteLock={this.props.isQuoteLock}
                />
              </Col>
            </Row>
            <DeleteModal
              deleteModal={this.state.deleteModal}
              hideDeleteModal={this.hideDeleteModal}
              deleteImage={this.deleteImage}
            />
            {
              //this.deleteModal()
            }
          </div>
        </>
      );
    }

    if (camera) {
      return (
        <>
          {loadingCamera && (
            <div className="loading-wrapper-camera">
              <button
                onClick={this.closeCamera}
                aria-label="Go Back"
                className="backBtn"
              >
                {this.getIcon(faChevronLeft)}
              </button>
              <div className="loadingDot"></div>
              {err && <div>{err}</div>}
            </div>
          )}
          <div
            className="camera"
            style={{ display: loadingCamera ? "none" : "block" }}
          >
            <canvas
              ref={(node) => {
                this.canvas = node;
              }}
              className="image-view"
            />
            <video
              autoPlay
              playsInline
              className="camera-view"
              ref={(node) => {
                this.camera = node;
              }}
            />
            {displayBtns ? (
              <>
                <button
                  onClick={this.closeCamera}
                  aria-label="Go Back"
                  className="backBtn"
                >
                  {this.getIcon(faChevronLeft)}
                </button>
                <button
                  onClick={this.capture}
                  aria-label="capture"
                  className="captureBtn"
                ></button>
                <button
                  aria-label="rotate-camera"
                  className="rotateBtn"
                  onClick={this.rotateCamera}
                >
                  <img src={RotateSVG} alt="Flip" />
                </button>
              </>
            ) : (
              <>
                <button
                  aria-label="Retry"
                  className="retry"
                  onClick={this.resetCamera}
                >
                  {this.getIcon(faTimes)}
                </button>
                <button
                  aria-label="Done"
                  className="submit"
                  onClick={this.savePhoto}
                >
                  {this.getIcon(faCheck)}
                </button>
              </>
            )}

            <img
              ref={(node) => {
                this.picture = node;
              }}
              alt=""
              className="picture"
            />
          </div>
        </>
      );
    }
    return (
      <div className="metric">
        <Row>
          <Col>
            <Header />
          </Col>
          <Row
            className="MeasurementsBtnImage"
            style={{
              marginRight: "5px",
              width: "auto",

              height: "3.6rem",
            }}
          >
            <Button
              style={{
                float: "right",
                margin: "0 1em",
                fontWeight: "bold",
                fontSize: "larger",
              }}
              onClick={this.props.closeImages}
            >
              Measurements
            </Button>
          </Row>
        </Row>
        <div style={{ marginTop: "2em" }}>{this.displayTable()}</div>
        {imgModal && (
          <PopupModal
            show={imgModal}
            heading="Collect Image Using?"
            onHide={this.hideImageModal}
            bold={true}
            centered={false}
            styles={{
              background: "rgb(0, 123, 255)",
              color: "#fff",
            }}
          >
            <Row style={{ justifyContent: "center", margin: "2.5em 0" }}>
              <input
                ref={(node) => {
                  this.imagePicker = node;
                }}
                type="file"
                accept="image/*"
                onChange={this.selectImage}
                style={{ display: "none" }}
              />
              <Button
                onClick={() => this.imagePicker.click()}
                className="modified_btn"
                style={{ marginRight: "1.5em", width: "18%" }}
              >
                Existing Image
              </Button>

              <Button
                onClick={() => this.cameraStart()}
                className="modified_btn"
                style={{ marginLeft: "1.5em", width: "18%" }}
              >
                Device Camera
              </Button>
            </Row>
          </PopupModal>
        )}
        <DeleteModal
          deleteModal={this.state.deleteModal}
          hideDeleteModal={this.hideDeleteModal}
          deleteImage={this.deleteImage}
        />
        {
          //this.deleteModal()
        }
      </div>
    );
  }
}

export default connect(null)(Images);
