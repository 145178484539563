import React from 'react'
import PopupModal from 'components/popup-modal/popup-modal'

const LoadingDark = () => (
	<div className='loadingDot-sm-wrapper'>
		<div className='loadingDot-sm' />
	</div>
)

export default function ({
	name = 'As-Collected',
	text = `Organized Roofer is generating the '${name}'${
		name === 'As-Collected' ? ' first time' : ''
	} quote.
    Once complete, you will be redirected to the Quotes & Proposals
    screen.`,
	show,
}) {
	return (
		<PopupModal
			show={show}
			heading={''}
			// onHide={() => hideTabModal()}
			bold
			styles={{ border: 'none' }}
			modalStyles={{
				width: '40%',
				left: '50%',
				transform: 'translateX(-50%)',
			}}
			scrollable={false}
			closeButton={false}
		>
			<div style={{ padding: '0 1.5em 2em 1.5em' }}>
				<div className='_tab_msg'>
					<p className='__msg' style={{ textAlign: 'center',fontSize:'22px' }}>
						{text}
					</p>
				</div>
				<LoadingDark />
			</div>
		</PopupModal>
	)
}
