import React, { useState } from "react";
import moment from "moment";
import {
  convert24hrsTo12hrs,
  calculateMin,
  doesPrevOverlap,
  overLappingItemBefore,
  getRowNumber,
  firstDayOfMonth,
  getWeekDate,
} from "./utils";
import { DropTarget } from "react-drag-drop-container";

import MonthView from "./MonthView";
import WeekView from "./WeekView";
import DayView from "./DayView";

import "./Calendar.scss";
import { convertDate } from "utils/utils";
import { useEffect } from "react";

const sortTimeWise = (a, b) => {
  let start = new Date(a.eventDetails.date);
  let end = new Date(a.eventDetails.date);

  const startHour = Number(a.eventDetails.startTime.split(":")[0]);
  const startMin = Number(a.eventDetails.startTime.split(":")[1]);
  const endHour = Number(a.eventDetails.endTime.split(":")[0]);
  const endMin = Number(a.eventDetails.endTime.split(":")[1]);
  start.setHours(startHour);
  start.setMinutes(startMin);
  end.setHours(endHour);
  end.setMinutes(endMin);
  let bstart = new Date(b.eventDetails.date);
  let bend = new Date(b.eventDetails.date);

  const bstartHour = Number(b.eventDetails.startTime.split(":")[0]);
  const bstartMin = Number(b.eventDetails.startTime.split(":")[1]);
  const bendHour = Number(b.eventDetails.endTime.split(":")[0]);
  const bendMin = Number(b.eventDetails.endTime.split(":")[1]);
  bstart.setHours(bstartHour);
  bstart.setMinutes(bstartMin);
  bend.setHours(bendHour);
  bend.setMinutes(bendMin);
  if (start < bstart || end < bend) return -1;
  if (start > bstart) return 1;
  return 0;
};

const Calendar = (props) => {
  const [weekdaysShort, setWeekdaysShort] = useState(moment.weekdaysShort());
  const [months, setMonths] = useState(moment.months());
  const [displayedEventsDate, setDisplayedEventsDate] = useState("");
  const [displayRow, setDisplayRow] = useState("");
  const [displayDay, setDisplayDay] = useState("");
  const [eventss, setEvents] = useState({});

  //   constructor(props) {
  //     super(props);
  //     state = {
  //       weekdaysShort: moment.weekdaysShort(),
  //       months: moment.months(),
  //       displayedEventsDate: "",
  //       displayRow: "",
  //       displayDay: "",
  //       events: {},
  //     };
  //   }

  const handleDrop = (props) => {
    if (props.dropFromOutside) {
      props.dropFromOutside(props);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const eventClicked = (data) => {
    const { eventClicked } = props;
    if (eventClicked) {
      eventClicked(data);
    }
  };

  const renderBlanks = () => {
    const { dateObject } = props;
    let blanks = [];
    let i;
    const prevMDays = moment(dateObject).subtract(1, "M").daysInMonth();
    let day = prevMDays;
    for (i = 0; i < firstDayOfMonth(dateObject); i++) {
      blanks.push(
        <td key={`empty${i}`} className="calendar-day empty">
          <span>{day}</span>
        </td>
      );
      day = day - 1;
    }
    return blanks.reverse();
  };

  const getEventsByDate = (date) => {
    // const { events } = state;
    const currentDateEvents = eventss[date];

    return currentDateEvents ?? [];
  };

  const daysInMonth = () => {
    // const { displayDay } = state;
    const { dateObject } = props;

    const year = moment(dateObject).year();
    const month = moment(dateObject).month() + 1;
    let daysinmonths = [];
    let d;
    for (d = 1; d <= moment(dateObject).daysInMonth(); d++) {
      const fullDate = `${month}/${d}/${year}`;
      const currentDay = d;
      const events = getEventsByDate(fullDate);
      const blanks = renderBlanks().length;
      const day =
        new Date(moment().subtract(1, "day")) <= new Date(fullDate) ? (
          <td
            key={d}
            onClick={() =>
              events.length > 0
                ? displayEvents(
                    fullDate,
                    `row-${getRowNumber(fullDate, blanks)}`,
                    currentDay
                  )
                : emptyFunction()
            }
            className={`calendar-day ${
              new Date(fullDate).toDateString() === new Date().toDateString()
                ? "today"
                : ""
            } ${displayDay === currentDay ? "selected" : ""} ${
              events.length > 0 ? "clickable" : ""
            }`}
          >
            <DropTarget
              targetKey="calendar"
              onDragOver={onDragOver}
              onDrop={(e) => handleDrop({ event: e })}
              dropData={{
                date: new Date(fullDate),
              }}
            ></DropTarget>
            {events.length > 0 && (
              <small className="_badge">{events.length}</small>
            )}
            <span className="_num_date">{d}</span>
            {false && (
              <>
                <div className="_dots">
                  {events.length > 0 &&
                    events.map((event, i) => (
                      <button
                        key={`b${i}`}
                        className="_dot"
                        style={{
                          background: event.badgeColor
                            ? event.badgeColor
                            : "#0051ff",
                        }}
                      ></button>
                    ))}
                </div>
                {events.length > 10 && (
                  <div className="more-text">+ more...</div>
                )}
              </>
            )}
          </td>
        ) : (
          <td
            key={d}
            className={`calendar-day ${
              fullDate === moment().format("l") ? "today" : ""
            } ${displayDay === currentDay ? "selected" : ""} ${
              events.length > 0 ? "clickable" : ""
            }`}
            onClick={() =>
              events.length > 0
                ? displayEvents(
                    fullDate,
                    `row-${getRowNumber(fullDate, blanks)}`,
                    currentDay
                  )
                : emptyFunction()
            }
          >
            {events.length > 0 && (
              <small className="_badge">{events.length}</small>
            )}
            <span>{d}</span>
            {false && (
              <>
                <div className="_dots">
                  {events.length > 0 &&
                    events.map((event, i) => (
                      <button
                        key={`b${i}`}
                        className="_dot"
                        style={{
                          background: event.badgeColor
                            ? event.badgeColor
                            : "#0051ff",
                        }}
                      ></button>
                    ))}
                </div>
                {events.length > 10 && (
                  <div className="more-text">+ more...</div>
                )}
              </>
            )}
          </td>
        );

      daysinmonths.push(day);
    }
    return daysinmonths;
  };

  const daysInWeekView = () => {
    // const { weekdaysShort } = state;
    const { dateObject } = props;

    return weekdaysShort.map((day, i) => {
      const dayDate = getWeekDate(i, dateObject);
      const events = getEventsByDate(dayDate);
      const dayBlock =
        new Date(moment().subtract(1, "day")) <= new Date(dayDate) ? (
          <td className="_week_view_td" key={`${day}-${i}`}>
            <DropTarget
              targetKey="calendar"
              onDragOver={onDragOver}
              onDrop={(e) => handleDrop({ event: e })}
              dropData={{
                date: new Date(dayDate),
              }}
            ></DropTarget>
            {events.length > 0 &&
              events.map((eventData) => (
                <div
                  key={eventData.requestObj.requestId}
                  className="week-event"
                  style={{
                    background: eventData.badgeColor
                      ? eventData.badgeColor
                      : "#0051ff",
                  }}
                  onClick={(event) => eventClicked({ event, data: eventData })}
                >
                  <p>{eventData.displayText1}</p>

                  <a
                    href={`https://www.google.com/maps/place?q=${eventData.displayText2}`}
                    style={{ textDecoration: "underline" }}
                    target="_blank"
                  >
                    {eventData.displayText2}
                  </a>
                </div>
              ))}
          </td>
        ) : (
          <td className="_week_view_td" key={`${day}-${i}`}>
            {events.length > 0 &&
              events.map((eventData) => (
                <div
                  key={eventData.requestObj.requestId}
                  className="week-event"
                  style={{
                    background: eventData.badgeColor
                      ? eventData.badgeColor
                      : "#0051ff",
                  }}
                  onClick={(event) => eventClicked({ event, data: eventData })}
                >
                  {eventData.displayText1}
                  {eventData.displayText2}
                </div>
              ))}
          </td>
        );
      return dayBlock;
    });
  };

  const displayEvents = (date, row, displayDay) => {
    setDisplayDay(displayDay === displayDay ? "" : displayDay);
    setDisplayedEventsDate(date);
    setDisplayRow(
      displayRow === row ? (displayDay === displayDay ? "" : displayRow) : row
    );
  };

  const emptyFunction = () => {
    return;
  };

  const clearEventsDisplayed = () => {
    setDisplayDay("");
    setDisplayedEventsDate("");
    setDisplayRow("");
  };

  const { view, dateObject, events } = props;

  useEffect(() => {
    if (events) {
      let eventsObj = {};

      events.sort(sortTimeWise).forEach((event) => {
        const date = event.eventDetails.date;
        const currentDayEvents = eventsObj[date];
        if (currentDayEvents) {
          eventsObj[date] = [...currentDayEvents, event];
        } else {
          eventsObj[date] = [event];
        }
      });
      setEvents(eventsObj);
      //   setState({ events: eventsObj });
    }
  }, [events]);

  useEffect(() => {
    if (view) {
      props.setToday();
      clearEventsDisplayed();
    }
  }, [view]);
  useEffect(() => {
    if (dateObject) {
      clearEventsDisplayed();
    }
  }, [dateObject]);

  // const  componentDidUpdate(prevProps, prevState) {

  //   }

  const getDayEvents = (date) => {
    const { dayviewstart } = props;
    let startN = 0;
    if (dayviewstart) {
      startN = Number(dayviewstart.split(":")[0]);
    }
    const events = getEventsByDate(convertDate(date));

    return (
      events.length > 0 &&
      events.map((event, i) => {
        let start = new Date(event.eventDetails.date);
        let end = new Date(event.eventDetails.date);
        let allDay = event.eventDetails.allDay;

        const startHour = Number(event.eventDetails.startTime.split(":")[0]);
        const startMin = Number(event.eventDetails.startTime.split(":")[1]);
        const endHour = Number(event.eventDetails.endTime.split(":")[0]);
        const endMin = Number(event.eventDetails.endTime.split(":")[1]);
        start.setHours(startHour);
        start.setMinutes(startMin);
        end.setHours(endHour);
        end.setMinutes(endMin);
        const paddingFromTop = startHour - startN;
        const height = calculateMin(startHour, startMin, endHour, endMin);
        let paddingFromLeft = "0%";
        const matchedNumberOfEvents = doesPrevOverlap(start, events);
        const width =
          matchedNumberOfEvents - 1 > 0
            ? `${100 / matchedNumberOfEvents}`
            : "100";
        const matchedBefore = overLappingItemBefore(i, start, events);
        if (matchedNumberOfEvents - 1 > 0) {
          paddingFromLeft =
            matchedBefore > 0 ? `${width * matchedBefore}%` : `0%`;
        }

        return (
          <div
            className="day-event"
            key={event.requestObj.requestId}
            style={
              allDay
                ? {
                    top: `${0 * 2 * 30 + 0.99}px`,
                    left: `calc(${paddingFromLeft})`,
                    height: `${120 - 0.99}px`,
                    width: `calc(${matchedBefore > 0 ? width : `${width}`}%)`,
                    // background: "rgba(255, 227, 168, 0.79)",
                    background: "#FFF293",
                    outline: "rgb(233, 169, 40) solid .1px",
                  }
                : {
                    top: `${paddingFromTop * 2 * 30 + 0.99 + startMin}px`,
                    left: `calc(${paddingFromLeft})`,
                    height: `${height - 0.99}px`,
                    width: `calc(${matchedBefore > 0 ? width : `${width}`}%)`,
                    // background: "rgba(255, 227, 168, 0.79)",
                    background: "#FFF293",

                    outline: "rgb(233, 169, 40) solid .1px",
                  }
            }
            onClick={(e) => eventClicked({ event: e, data: event })}
          >
            <span>{convert24hrsTo12hrs(startHour, startMin)}</span>
            <span className="text">
              {/* {event.displayText} */}
              {event.displayText1}

              <a
                href={`https://www.google.com/maps/place?q=${event.displayText2}`}
                style={{ textDecoration: "underline" }}
                target="_blank"
              >
                {event.displayText2}
              </a>
            </span>
            <span>{convert24hrsTo12hrs(endHour, endMin)}</span>
          </div>
        );
      })
    );
  };

  const renderView = () => {
    const totalSlots = [...renderBlanks(), ...daysInMonth()];
    //   state;
    const { dayviewstart, dayviewend, dateObject } = props;
    let displayedEvents = [];

    if (displayedEventsDate) {
      displayedEvents = getEventsByDate(displayedEventsDate);
    }
    switch (props.view) {
      case "month":
        return (
          <MonthView
            onPrev={props.onPrev}
            onNext={props.onNext}
            weekdaysShort={weekdaysShort}
            totalSlots={totalSlots}
            displayedEvents={displayedEvents}
            displayRow={displayRow}
            date={`${months[moment(dateObject).month()]}, ${moment(
              dateObject
            ).year()}`}
            eventClicked={eventClicked}
          />
        );
      case "week":
        return (
          <WeekView
            weekdaysShort={weekdaysShort}
            onPrevWeek={props.onPrevWeek}
            onNextWeek={props.onNextWeek}
            weekText={`Week ${moment(dateObject).week()} of ${moment(
              dateObject
            ).year()}`}
            dateObject={dateObject}
            daysInWeekView={daysInWeekView}
            eventClicked={eventClicked}
          />
        );

      case "day":
        return (
          <DayView
            date={` ${moment(dateObject).format("MMMM Do YYYY")}`}
            onPrevDay={props.onPrevDay}
            onNextDay={props.onNextDay}
            dayviewstart={dayviewstart}
            dayviewend={dayviewend}
            getDayEvents={getDayEvents(dateObject)}
            isSameDay={
              new Date(moment().subtract(1, "day")) <= new Date(dateObject)
            }
            onDragOver={onDragOver}
            onDrop={(e, date = new Date(dateObject)) =>
              handleDrop({ event: e, date })
            }
            dateObj={dateObject}
          />
        );

      default:
        return null;
    }
  };

  //   render() {
  return (
    <div className="_calendar_ui">
      <div
        className="btns-group"
        style={{ justifyContent: "flex-start", marginTop: ".5em" }}
      ></div>
      {renderView()}
    </div>
  );
};
// }

export default Calendar;
