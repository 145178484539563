import React from "react";
import { Row, Col, Form, Alert, InputGroup } from "react-bootstrap";
import validator from "validator";
import MaskedInput from "react-maskedinput";

import "react-phone-input-2/lib/style.css";
import RequiredText from "components/required-text/required-text";
import { checkPhone } from "utils/utils";
import SelectState from "./state";
import County from "./county";


const newStyle = {
  formInput: {
    marginBottom: "15px",
  },
  formStyle: {
    // marginTop: "5%"
  },
  phoneInput: {
    width: "100%",
  },
};

class CompanyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errMsg: "",
      errAt: "",
      errType: "warning", //danger, success, info, warning
      selectedState: "",
    };

    this.companyNameField = React.createRef();
  }

  componentDidMount() {
    this.companyNameField.current.focus();
  }


  isValidated() {
    const {
      cName,
      cAddress1,
      cCity,
      cState,
      cZip,
      primaryPhone,
      secondaryPhone,
      fax,
      cEmail,
      cWebsite,
      county,
    } = this.props;
    

    const regex = new RegExp(
      "(?:(?:https?|ftp|file)://|www.|ftp.)(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[A-Z0-9+&@#/%=~_|$])"
    );

    if (cName === "") {
      this.setState({
        errMsg: "Company Name is required.",
        errAt: "cName",
        errType: "danger",
      });
      return false;
    } else if (cAddress1 === "") {
      this.setState({
        errMsg: "Address 1 is required.",
        errAt: "cAddress1",
        errType: "danger",
      });
      return false;
    } else if (cCity === "") {
      this.setState({
        errMsg: "City is required.",
        errAt: "cCity",
        errType: "danger",
      });
      return false;
    } else if (cState === "") {
      this.setState({
        errMsg: "State is required.",
        errAt: "cState",
        errType: "danger",
      });
      return false;
    } else if (cZip === "") {
      this.setState({
        errMsg: "Zip is required.",
        errAt: "cZip",
        errType: "danger",
      });
      return false;
    } else if (county === "") {
      this.setState({
        errMsg: "County is required.",
        errAt: "county",
        errType: "danger",
      });
      return false;
    } else if (!validator.isNumeric(cZip)) {
      this.setState({
        errMsg: "Zip should have only numbers.",
        errAt: "cZip",
        errType: "warning",
      });
      return false;
    } else if (cZip !== "" && cZip.length < 5) {
      this.setState({
        errMsg: "Zip should have 5 digits.",
        errAt: "cZip",
        errType: "warning",
      });
      return false;
    } else if (primaryPhone === "") {
      this.setState({
        errMsg: "Phone 1 is required.",
        errAt: "primaryPhone",
        errType: "danger",
      });
      return false;
    } else if (primaryPhone !== "" && checkPhone(primaryPhone)) {
      this.setState({
        errMsg: "Phone 1 should have 10 digits.",
        errAt: "primaryPhone",
        errType: "warning",
      });
      return false;
    } else if (secondaryPhone !== "" && checkPhone(secondaryPhone)) {
      this.setState({
        errMsg: "Secondary Phone should have 10 digits.",
        errAt: "secondaryPhone",
        errType: "warning",
      });
      return false;
    } else if (fax !== "" && checkPhone(fax)) {
      this.setState({
        errMsg: "Fax should have 10 digits.",
        errAt: "fax",
        errType: "warning",
      });
      return false;
    } else if (cWebsite !== "" && !regex.test(cWebsite)) {
      this.setState({
        errMsg: "Invalid website URL.",
        errAt: "cWebsite",
        errType: "warning",
      });
      return false;
    } else if (cEmail !== "" && !validator.isEmail(cEmail)) {
      this.setState({
        errMsg: "Invalid email address.",
        errAt: "cEmail",
        errType: "warning",
      });
      return false;
    } else {
      this.setState({
        errMsg: "",
        errAt: "",
        errType: "",
      });
      return true;
    }
  }

  selected_county(e) {
    this.setState({

    })
  }

  onSubmit(e) {
    e.preventDefault();
  }


  // useEffect(() => {
  //   if (props.value) {
  //     const {id, name} = splitToGetNameAndId(props.value);
  //     get_county_tax_by_state_zip(id, name);
  //   }

  // }, [props.value])
  render() {
    const {
      cName,
      cAddress1,
      cAddress2,
      cCity,
      cState,
      cZip,
      primaryPhone,
      secondaryPhone,
      cWebsite,
      cEmail,
      fax,
      handleChange,
      selectRegion,
      county,
      loadingCounty
    } = this.props;

    const { errType, errMsg, errAt } = this.state;
    return (
      <Form noValidate onSubmit={this.onSubmit} style={newStyle.formStyle}>
        <Row>
          <div style={{ width: "100%", textAlign: "right" }}>
            <RequiredText>* Required</RequiredText>
          </div>
          <Col xs="12">
            {errMsg && <Alert variant={errType}>{errMsg}</Alert>}
          </Col>

          <Col xs="12" md="12">
            <Row>
              <Col xs="12" md="6">
                <Form.Group style={newStyle.formInput}>
                  <Form.Label>
                    Company Name <RequiredText>*</RequiredText>
                  </Form.Label>
                  <Form.Control
                    ref={this.companyNameField}
                    type="text"
                    name="cName"
                    value={cName}
                    placeholder="Enter company name"
                    onChange={(event) => handleChange(event)}
                    isInvalid={errAt === "cName"}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="6">
                <Form.Group style={newStyle.formInput}>
                  <Form.Label>
                    Address 1 <RequiredText>*</RequiredText>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="2"
                    name="cAddress1"
                    value={cAddress1}
                    placeholder="Enter additional address 1"
                    onChange={(event) => handleChange(event)}
                    isInvalid={errAt === "cAddress1"}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6">
                <Form.Group style={newStyle.formInput}>
                  <Form.Label>Address 2</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="2"
                    name="cAddress2"
                    value={cAddress2}
                    placeholder="Enter additional address 2"
                    onChange={(event) => handleChange(event)}
                    isInvalid={errAt === "cAddress2"}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md="6" sm="12">
                <Form.Group style={newStyle.formInput}>
                  <Form.Label>
                    City <RequiredText>*</RequiredText>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="cCity"
                    value={cCity}
                    placeholder="Enter city"
                    required="required"
                    onChange={(event) => handleChange(event)}
                    isInvalid={errAt === "cCity"}
                  />
                </Form.Group>
              </Col>

              <Col sm="6" md="2">
                <SelectState
                  label="State"
                  required={true}
                  name="cState"
                  value={cState
                    .toLowerCase()
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                  onChange={(value) => selectRegion("cState", value)}
                  errAt={this.state.errAt}
                />
              </Col>

              <Col sm="6" md="2">
                <Form.Group style={newStyle.formInput}>
                  <Form.Label>
                    Zip Code <RequiredText>*</RequiredText>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="cZip"
                    value={cZip}
                    maxLength={5}
                    placeholder="Enter zip"
                    required="required"
                    onChange={(event) => handleChange(event)}
                    isInvalid={errAt === "cZip"}
                  />
                </Form.Group>
              </Col>

              <Col sm="6" md="2">
                {/* <County
                  label="County"
                  name="county"
                  value={county}
                  // onChange={(event) => handleChange(event)}
                  disabled={true}
                  county={cState}
                  onChange={(value) => selectRegion("county", value)}
                /> */}

                  <Form.Label>
                    County {loadingCounty ? 'Loading county...': ''} <RequiredText>*</RequiredText>
                  </Form.Label>

              <Form.Control
                    label="County"
                    name="county"
                    value={county}
                    disabled={true}
                    required="required"
                    onChange={(event) => handleChange(event)}
                  />
              </Col>
            </Row>

            <Row>
              <Col md="4" sm="12">
                <Form.Group style={newStyle.formInput}>
                  <Form.Label>
                    Phone 1 <RequiredText>*</RequiredText>
                  </Form.Label>
                  <InputGroup>
                    <MaskedInput
                      className="form-control"
                      type="text"
                      name="primaryPhone"
                      value={primaryPhone}
                      mask="(111) 111-1111"
                      placeholder="Enter Phone"
                      required="required"
                      maxLength={10}
                      onChange={(event) => handleChange(event)}
                      isInvalid={errAt === "primaryPhone"}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col md="4" sm="12">
                <Form.Group style={newStyle.formInput}>
                  <Form.Label>Secondary Phone</Form.Label>
                  <InputGroup>
                    {/* <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        +1
                      </InputGroup.Text>
                    </InputGroup.Prepend> */}
                    <MaskedInput
                      className="form-control"
                      mask="(111) 111-1111"
                      type="text"
                      name="secondaryPhone"
                      value={secondaryPhone}
                      placeholder="Enter Phone"
                      required="required"
                      maxLength={10}
                      onChange={(event) => handleChange(event)}
                      isInvalid={errAt === "secondaryPhone"}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col md="4" sm="12">
                <Form.Group style={newStyle.formInput}>
                  <Form.Label>Fax</Form.Label>
                  <InputGroup>
                    <MaskedInput
                      className="form-control"
                      mask="(111) 111-1111"
                      type="text"
                      name="fax"
                      value={fax}
                      placeholder="Enter Fax"
                      required="required"
                      maxLength={10}
                      onChange={(event) => handleChange(event)}
                      isInvalid={errAt === "fax"}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6" sm="12">
                <Form.Group style={newStyle.formInput}>
                  <Form.Label>Company Website</Form.Label>
                  <Form.Control
                    type="text"
                    name="cWebsite"
                    value={cWebsite}
                    placeholder="Enter company website"
                    onChange={(event) => handleChange(event)}
                    isInvalid={errAt === "cWebsite"}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default CompanyDetails;
