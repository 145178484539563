import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import { PriceInput } from "components";
import validator from "validator";
import styles from "lib/config/baseStyles";
import { useSelector } from "react-redux";
import QTYInput from "../components/QTYInput";
import showOptions from "../components/showOptions";

export default function LaborMiscItem({
  data,
  deleteMiscItem,
  editMiscItem,
  isLockQuoteData,
}) {
  const staticData = useSelector((state) => state.staticAll);

  const handleChangeQty = (e) => {
    const { value } = e.target;

    const newTotal =
      validator.isNumeric(value) && validator.isNumeric(data.uomPrice)
        ? Number(value * data.uomPrice).toFixed(2)
        : data.total;

    const newData = changeData("qty", value);
    newData.total = newTotal;

    editMiscItem(newData);
  };

  const handlePriceChange = (value) => {
    const newTotal =
      validator.isNumeric(value) && validator.isNumeric(data.qty)
        ? Number(value * data.qty).toFixed(2)
        : data.total;
    const newData = changeData("uomPrice", value);
    newData.total = newTotal;

    editMiscItem(newData);
  };

  const onChangeField = (e) => {
    const newData = changeData("field", e.target.value);

    editMiscItem(newData);
  };

  const changeData = (key, value) => ({
    ...data,
    [key]: value,
  });

  return (
    <Row>
      <Col style={styles.reduceWidthCol} lg="2">
        <Button variant="danger" onClick={deleteMiscItem}>
          Delete
        </Button>
      </Col>
      <Col style={styles.reduceWidthCol} lg="4">
        <Form.Group>
          <Form.Control
            type="text"
            value={data.field}
            onChange={onChangeField}
          />
        </Form.Group>
      </Col>

      <Col style={styles.reduceWidthCol}>
        <Form.Group>
          <Form.Control as="select">
            {showOptions(
              staticData.data ? staticData.data.quoteLaborUom : [],
              staticData.isLoading
            )}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <QTYInput
          value={data.qty}
          onChange={handleChangeQty}
          styless={data?.original_list?.qty != data.qty}
          disabled={isLockQuoteData?.isLocked}
        />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput
          price={data.uomPrice}
          onChange={(value) => handlePriceChange(value)}
          style={{ textAlign: "right" }}
        />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput disabled price={data.total} />
      </Col>
    </Row>
  );
}
