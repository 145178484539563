const getEmailId = () => {
  return localStorage.getItem("user_email");
};

const getData = (route) => {
  const user_id = getEmailId();
  if (user_id === null) return;

  const data = localStorage.getItem(user_id);
  if (data === null) {
    return null;
  }

  const parsedData = JSON.parse(data);

  if (parsedData[route] === undefined) {
    return null;
  }
  return parsedData[route];
};

const saveData = async (route, data) => {
  const user_id = getEmailId();
  if (user_id === null) return;

  let storedData = localStorage.getItem(user_id);

  let parsedData = {};
  if (storedData !== null) {
    parsedData = JSON.parse(storedData);
  }
  const newData = { ...parsedData, [route]: data };
  localStorage.setItem(user_id, JSON.stringify(newData));
};

const removeData = (route) => {
  const user_id = getEmailId();
  if (user_id === null) return;

  const data = localStorage.getItem(user_id);
  if (data === null) {
    console.log("error remove data, no data stored");
    return;
  }
  const parsedData = JSON.parse(data);

  if (!(route in parsedData)) {
    console.log("warning remove data, no index found");
  }
  delete parsedData[route];

  localStorage.setItem(user_id, JSON.stringify(parsedData));
};

export { getEmailId, getData, saveData, removeData };

//const DataTypes =
