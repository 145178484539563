import { useEffect } from "react";
import { useState } from "react";
import { getEndPoints } from "api/api";
import { axiosInstance as axios } from "services";
// import {
//   GET_SCEDULING_TIME,
//   GET_SCEDULING_TIME_BY_ID,
//   UPDATE_SSCHEDULING_MODAL,
// } from "utils/utils";
import {
  changeSchedulingTimeProjectManagement,
  changeSchedulingTimeRepair,
  changeSchedulingTimeRepairGap,
  changeSchedulingTimeSales,
  changeSchedulingTimeSalesGap,
} from "utils/SchedulingModal/scheduling";
import { toast } from "react-toastify";
import { ApiEndpoints } from "lib/config/baseSettings";

const { GET_SCEDULING_TIME_BY_ID } = ApiEndpoints.SCHEDULE_MODAL;

const url = getEndPoints(process.env.REACT_APP_MODE);

const useSchedulingModal = (user) => {
  const [additionalRepairTime, setAdditionalRepairtime] = useState(0);
  const [additionalSalesTime, setAdditionalSalesTime] = useState(0);
  const [additionalRepairGapTime, setAdditionalRepairGapTime] = useState(0);
  const [additionalSalesGapTime, setAdditionalSalesGapTime] = useState(0);
  const [additionalProjectmanagementTime, setAdditionalProjectManagementTime] =
    useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [showSalesSave, setShowSalesSave] = useState(false);
  const [showRepairSave, setShowRepairSave] = useState(false);
  const [showProjectSave, setShowProjectSave] = useState(false);
  const [showSalesGapSave, setShowSalesGapSave] = useState(false);
  const [showRepairGapSave, setShowRepairGapSave] = useState(false);
  /**
   * Scheduling Modal
   * Start  apis of scheduling modal by id
   */

  useEffect(() => {
    axios
      .get(`${GET_SCEDULING_TIME_BY_ID}${user?.userData?.companyId}`)
      .then((response) => {
        setAdditionalRepairtime(response?.data?.result?.repair_time);
        setAdditionalSalesTime(response?.data?.result?.sales_time);
        setAdditionalProjectManagementTime(
          response?.data?.result?.project_management_time
        );
        setAdditionalRepairGapTime(response?.data?.result?.repair_gap_time);
        setAdditionalSalesGapTime(response?.data?.result?.sales_gap_time);
        if (response.data.success === false && response.data.error) {
          toast.error(response.data.error);
        }

        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, [isLoading]);

  /**
   * Scheduling Modal
   * Start all apis of scheduling modal
   */
  const increaseProjectManager = (increase = true) => {
    let user = additionalProjectmanagementTime;
    if (increase) {
      user += 1;
    } else if (user >= 1) {
      user -= 1;
    }
    setAdditionalProjectManagementTime(user);
  };
  const increaseSales = (increase = true) => {
    let user = additionalSalesTime;
    if (increase) {
      user += 1;
    } else if (user >= 1) {
      user -= 1;
    }
    setAdditionalSalesTime(user);
  };
  const increaseRepair = (increase = true) => {
    let user = additionalRepairTime;
    if (increase) {
      user += 1;
    } else if (user >= 1) {
      user -= 1;
    }
    setAdditionalRepairtime(user);
  };

  /**
   *
   * for gap time repairs and sales
   */
  const increaseSalesGap = (increase = true) => {
    let user = additionalSalesGapTime;
    if (increase) {
      user += 1;
    } else if (user >= 1) {
      user -= 1;
    }
    setAdditionalSalesGapTime(user);
  };
  const increaseRepairGap = (increase = true) => {
    let user = additionalRepairGapTime;
    if (increase) {
      user += 1;
    } else if (user >= 1) {
      user -= 1;
    }
    setAdditionalRepairGapTime(user);
  };
  /**
   *
   */
  const handleOnClickSalesSave = () => {
    changeSchedulingTimeSales(
      user?.userData?.companyId,
      additionalSalesTime,
      setShowSalesSave,
      setIsLoading
    );
  };
  const handleOnClickRepairSave = () => {
    changeSchedulingTimeRepair(
      user?.userData?.companyId,
      additionalRepairTime,
      setShowRepairSave,
      setIsLoading
    );
  };
  const handleOnClickProjectSave = () => {
    changeSchedulingTimeProjectManagement(
      user?.userData?.companyId,
      additionalProjectmanagementTime,
      setShowProjectSave,
      setIsLoading
    );
  };
  /**
   *Click on save of repair and sales gap
   */
  const handleOnClickRepairGapSave = () => {
    changeSchedulingTimeRepairGap(
      user?.userData?.companyId,
      additionalRepairGapTime,
      setShowRepairGapSave,
      setIsLoading
    );
  };
  const handleOnClickSalesGapSave = () => {
    changeSchedulingTimeSalesGap(
      user?.userData?.companyId,
      additionalSalesGapTime,
      setShowSalesGapSave,
      setIsLoading
    );
  };
  /**
   *
   */
  return {
    increaseProjectManager,
    increaseSales,
    increaseRepair,
    setAdditionalRepairtime,
    additionalRepairTime,
    setAdditionalSalesTime,
    additionalSalesTime,
    setAdditionalProjectManagementTime,
    additionalProjectmanagementTime,
    setIsLoading,
    isLoading,

    showRepairSave,
    setShowRepairSave,
    showProjectSave,
    setShowProjectSave,
    showSalesSave,
    setShowSalesSave,
    handleOnClickSalesSave,
    handleOnClickRepairSave,
    handleOnClickProjectSave,

    increaseSalesGap,
    increaseRepairGap,
    handleOnClickRepairGapSave,
    handleOnClickSalesGapSave,
    setShowSalesGapSave,
    setShowRepairGapSave,
    showSalesGapSave,
    showRepairGapSave,
    setAdditionalRepairGapTime,
    setAdditionalSalesGapTime,
    additionalRepairGapTime,
    additionalSalesGapTime,
  };
};
export default useSchedulingModal;
