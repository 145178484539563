const INITIAL_STATE_PROPOSAL_TAB = {
  contractDetail: false,
  firstTime: true,
};

export const UPDATE_CONTRACT_DETAIL = "UPDATE_CONTRACT_DETAIL";
export const FINISH_FIRST_TIME = "FINISH_FIRST_TIME";

export const ContractDetailReducer = (
  state = INITIAL_STATE_PROPOSAL_TAB,
  action
) => {
  switch (action.type) {
    case UPDATE_CONTRACT_DETAIL:
      return {
        ...state,
        contractDetail: action.payload,
      };

    case FINISH_FIRST_TIME:
      return {
        ...state,
        firstTime: action.payload,
      };
    default:
      return state;
  }
};
