import { axiosInstance as axios } from "services";
import { getEndPoints } from "api/api";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ApiEndpoints } from "lib/config/baseSettings";

const useWeatherZipCode = () => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState();
  const [zipCode, setZipCode] = useState();

  useEffect(() => {
    get_weather_zip_code();
  }, []);

  const get_weather_zip_code = () => {
    setLoading(true);
    return axios
      .get(ApiEndpoints.WEATHER.GET)
      .then((response) => {
        if (response.data.success) {
          setZipCode(response.data.result);
        }
      })
      .catch((err) => {
        setIsError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const update_weather_zip_code = (body) => {
    setLoading(true);
    return axios
      .post(ApiEndpoints.WEATHER.UPDATE, body)
      .then((response) => {
        if (response.data.success) {
          get_weather_zip_code();
          toast.success("Zip Code Updated");
        }
      })
      .catch((err) => {
        setIsError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    get_weather_zip_code,
    update_weather_zip_code,
    zipCode,
    isError,
    loading,
  };
};

export default useWeatherZipCode;
