export const metricsActionTypes = {
	GET_ROOF_START: 'GET_ROOF_START',
	GET_ROOF_SUCCESS: 'GET_ROOF_SUCCESS',
	GET_ROOF_FAILURE: 'GET_ROOF_FAILURE',
	GET_GUTTERS_START: 'GET_GUTTERS_START',
	GET_GUTTERS_SUCCESS: 'GET_GUTTERS_SUCCESS',
	GET_GUTTERS_FAILURE: 'GET_GUTTERS_FAILURE',
	ADD_BUILDING_START: 'ADD_BUILDING_START',
	ADD_BUILDING_SUCCESS: 'ADD_BUILDING_SUCCESS',
	ADD_BUILDING_FAILURE: 'ADD_BUILDING_FAILURE',
	ADD_METRICS_START: 'ADD_METRICS_START',
	ADD_METRICS_SUCCESS: 'ADD_METRICS_SUCCESS',
	ADD_METRICS_FAILURE: 'ADD_METRICS_FAILURE',
}
