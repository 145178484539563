import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

const newStyle = {
  formInput: {
    marginBottom: "15px",
  },
  formStyle: {
    // marginTop: "5%"
  },
};

class ReviewSubmit extends React.Component {
  isValidated() {
    return true;
  }

  onSubmit(e) {
    e.preventDefault();
  }

  componentDidMount() {
    console.log({ rProps: this.props });
  }

  getTotalTax = () => {
    let tax = {
      totalPriceWithTax: 0,
      stateTaxAmount: 0
    }
    const stateTaxPercentage = this.props.currentCounty?.county_tax;
    if (stateTaxPercentage) {
      const { monthlySubscription, oneTimeCharge, additionalUser, additionalPricePerUser } = this.props;
      // Calculate the total price before tax
      const totalPriceBeforeTax = Number(monthlySubscription) + Number(oneTimeCharge) + (additionalUser * additionalPricePerUser);

      // Calculate the state tax amount
      tax.stateTaxAmount = (stateTaxPercentage / 100) * totalPriceBeforeTax;

      // Calculate the total price including state tax
      tax.totalPriceWithTax = totalPriceBeforeTax + tax.stateTaxAmount;

    }

    return tax;
  }


  render() {
    const {
      fName,
      lName,
      aEmail,
      aPhone,
      cName,
      cAddress1,
      cAddress2,
      bAddressChecked,
      billingAddress,
      billingAddress2,
      additionalUser,
      monthlySubscription,
      cCity,
      cState,
      cZip,
      bCity,
      bState,
      bZip,
      primaryPhone,
      secondaryPhone,
      cWebsite,
      cEmail,
      fax,
      basePrice,
      additionalPricePerUser,
      updateStep,
      currency,
      baseUsers,
      total_tax,
      county,
      subscriptionInfo,
      oneTimeCharge,
    } = this.props;

    return (
      <Row>
        <Col xs="12">
          <div
            className=""
            style={{
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
              marginBottom: "10px",
            }}
          >
            <div className="card-body">
              <Row style={newStyle.submitView}>
                <Col xs="12" md="4">
                  <h4>Company Details</h4>
                  <p>{cName}</p>
                  <p>{cAddress1}</p>
                  {cAddress2 && <p>{cAddress2} </p>}
                  <p>
                    {cCity}, {cState} {cZip} {county}
                  </p>
                  <p>Primary: {primaryPhone}</p>
                  {secondaryPhone && <p>Secondary: {secondaryPhone}</p>}
                  {fax && <p>Fax: {fax}</p>}
                  {cWebsite && <p>Website: {cWebsite} </p>}
                  {cEmail && <p>Email: {cEmail}</p>}
                  <Button variant="outline-dark" onClick={() => updateStep(0)}>
                    Edit
                  </Button>
                </Col>

                <Col xs="12" md="4">
                  <h4>Subscription Admin</h4>
                  <p>{`${fName} ${lName}`}</p>
                  <p>Email: {aEmail}</p>
                  {aPhone && <p>Phone: {aPhone}</p>}
                  <Button variant="outline-dark" onClick={() => updateStep(1)}>
                    Edit
                  </Button>
                </Col>

                <Col xs="12" md="4">
                  <h4>Subscription Pricing</h4>
                  <p>
                    Base Plan includes ({baseUsers}) users at{" "}
                    {`${
                      currency ? (currency === "USD" ? "$" : currency) : ""
                    }${basePrice.toFixed(2)}`}{" "}
                    per month
                  </p>
                  <p>
                    Each additional user{" "}
                    {`${
                      currency ? (currency === "USD" ? "$" : currency) : ""
                    }${additionalPricePerUser.toFixed(2)}`}{" "}
                    per user/per month
                  </p>
                  <p>Additional User: {additionalUser}</p>
                  <p>
                    Monthly Subscription Total:{" "}
                    {`$${monthlySubscription.toFixed(2)}`}
                  </p>
                  <p>
                    One Time Setup:{" "}
                    {`$${oneTimeCharge && oneTimeCharge.toFixed(2)}`}
                  </p>
                  <p>
                    Tax:{" "}
                    {`$${this.getTotalTax().stateTaxAmount.toFixed(2)}`}
                  </p>
                  <p>
                    Total:{" "}
                    {`$${this.getTotalTax().totalPriceWithTax.toFixed(2)}`}
                  </p>

                  <Form.Group>
                    <Form.Check
                      name="bAddressCheckedValue"
                      checked={bAddressChecked}
                      disabled={true}
                      label="Billing address same as company address."
                    />
                  </Form.Group>
                  <p>{billingAddress}</p>
                  {billingAddress2 && <p>{billingAddress2}</p>}
                  <p>{`${bCity}, ${bState} ${bZip}`}</p>
                  <Button variant="outline-dark" onClick={() => updateStep(2)}>
                    Edit
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ReviewSubmit;
