import { CHECKOUT_TYPES } from "./checkout.action.type";

const INITIAL_STATE = {
  checkoutModalDisplay: false,
};

export const Checkout = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECKOUT_TYPES.SHOW_CHECKOUT_MODAL:
      return {
        checkoutModalDisplay: true,
      };

    case CHECKOUT_TYPES.HIDE_CHECKOUT_MODAL:
      return {
        checkoutModalDisplay: false,
      };
    default:
      return state;
  }
};
