/* eslint-disable import/no-unresolved */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import "./Suppliers.scss";
import RequiredText from "components/required-text/required-text";
import validator from "validator";
import { checkPhone, convertDate } from "utils/utils";
// eslint-disable-next-line import/no-extraneous-dependencies
import { uuid } from "uuidv4";
import Personnel from "components/Personnel/Personnel";
import CommonAddressFields from "components/CommonAddressFields/CommonAddressFields";
import PhoneDetails from "components/PhoneDetails/PhoneDetails";
import DatePickerNew from "components/DatePickerWithRemove";

import { Row, Col, Button, Form } from "react-bootstrap";

import {
  getMSuppliers,
  addMSuppliers,
  updateMSuppliers,
  deleteMSuppliers,
  toggleIsPreferred,
} from "lib/redux/suppliers/suppliers.action";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import DeleteModal from "../../components/DeleteModal";
import { TABS_SUPPLIERS } from "./const";
import userPeesonnelData from "hooks/usePersonelData";
import userPersonnelData from "hooks/usePersonelData";

export default function PrimarySuppliers({
  mateSuppliers,
  setMateSuppliers,
  setIsPrimary,
  isPrimary,
  selectedSupp,
  setSelectedSupp,
  addNew,
  permission,
}) {
  const [_id, setId] = useState(uuid());
  const [isActive, setIsActive] = useState(true);
  const [isPreferred, setIsPreferred] = useState(false);
  const [opened, setOpened] = useState("");
  const [closed, setClosed] = useState("");
  const [name, setName] = useState("");
  const [locOrBranch, setLocOrBranch] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, changeState] = useState("");
  const [zip, setZip] = useState("");
  const [note, setNote] = useState("");
  const [personnels, setPersonnels] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [POS, setPOS] = useState([]);
  const [edit, setEdit] = useState(false);
  const [prevData, setPrevData] = useState({});

  const [dltModal, setDltModal] = useState(false);

  const hideDeleteModal = () => setDltModal(false);

  const dispatch = useDispatch();

  const { materialPersonnels = [] } = userPersonnelData();

  const { data, errors, isLoading } = useSelector((state) => state.staticAll);

  useEffect(() => {
    if (data) {
      setPOS(data.personnelPos);
    }
  }, [data]);

  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);

  useEffect(() => {
    if (selectedSupp) {
      const data = mateSuppliers.find((supp) => supp._id === selectedSupp);
      if (data) {
        setId(data._id);
        setIsActive(data.isActive);
        setIsPreferred(data.isPreferred);
        setName(data.name);
        setLocOrBranch(data.locOrBranch);
        setPhone1(data.phone1.toString());
        setPhone2(data.phone2 ? data.phone2.toString() : "");
        setFax(data.fax ? data.fax.toString() : "");
        setEmail(data.email);
        setAddress1(data.address1);
        setAddress2(data.address2 ? data.address2 : "");
        setCity(data.city);
        changeState(data.state);
        setZip(`${data.zip}`);
        setNote(data.note);
        setPersonnels(data.personnels);
        setClosed(data.closed ? convertDate(data.closed) : "");
        setOpened(data.opened ? convertDate(data.opened) : "");

        setPrevData({
          _id: data._id,
          isActive: data.isActive,
          isPreferred: data.isPreferred,
          name: data.name,
          locOrBranch: data.locOrBranch,
          phone1: data.phone1.toString(),
          phone2: data.phone2 ? data.phone2.toString() : "",
          fax: data.fax ? data.fax.toString() : "",
          email: data.email,
          address1: data.address1,
          address2: data.address2 ? data.address2 : "",
          city: data.city,
          zip: `${data.zip}`,
          note: data.note,
          personnels: data.personnels,
          opened: data.opened ? convertDate(data.opened) : "",
          closed: data.closed ? convertDate(data.closed) : "",
        });
      }
    } else {
      resetFields();
    }
  }, [selectedSupp, mateSuppliers]);

  const hasNotChangedFields = () =>
    JSON.stringify(prevData) ===
    JSON.stringify({
      _id,
      isActive,
      isPreferred,
      name,
      locOrBranch,
      phone1: phone1.toString(),
      phone2: phone2 ? phone2.toString() : "",
      fax: fax ? fax.toString() : "",
      email,
      address1,
      address2: address2 || "",
      city,
      zip: `${zip}`,
      note,
      personnels,
      opened: opened ? convertDate(opened) : "",
      closed: closed ? convertDate(closed) : "",
    });

  const resetFields = () => {
    setId(uuid());
    setIsActive(true);
    setIsPreferred(false);
    setOpened("");
    setClosed("");
    setName("");
    setLocOrBranch("");
    setPhone1("");
    setPhone2("");
    setFax("");
    setEmail("");
    setAddress1("");
    setAddress2("");
    setCity("");
    changeState("");
    setZip("");
    setNote("");
    setPersonnels([]);
    setPrevData({});
  };

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const addSuppliers = () => {
    if (!selectedSupp) {
      setProcessing(true);
      return dispatch(
        addMSuppliers({
          isActive,
          opened,
          closed,
          name,
          locOrBranch,
          phone1,
          phone2,
          fax,
          email,
          address1,
          address2,
          city,
          state,
          zip,
          note,
          personnels,
          isPrimary,
        })
      )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            const newData = res.result;
            dispatch(
              toggleIsPreferred(0, {
                supplierId: newData._id,
                isPreferred,
              })
            )
              .then((resData) => resData.data)
              .then((resData) => {
                if (resData) {
                  setMateSuppliers([
                    ...mateSuppliers,
                    { ...newData, isPreferred },
                  ]);
                  setSelectedSupp(newData._id);
                  toast.success("Branch Supplier added successfully.");
                  dispatch(getMSuppliers());
                } else {
                  toast.error(res.error);
                }
              })
              .catch((err) => {
                toast.error(err);
              })
              .finally(() => setProcessing(false));
          } else {
            toast.error(res.error);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => setProcessing(false));
    }
    if (selectedSupp === _id) {
      const idx = mateSuppliers.findIndex((supp) => supp._id === _id);
      setProcessing(true);
      return dispatch(
        updateMSuppliers(_id, {
          isActive,
          opened,
          closed,
          name,
          locOrBranch,
          phone1,
          phone2,
          fax,
          email,
          address1,
          address2,
          city,
          state,
          zip,
          note,
          personnels,
          isPrimary,
        })
      )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            dispatch(
              toggleIsPreferred(0, {
                supplierId: _id,
                isPreferred,
              })
            )
              .then((resData) => resData.data)
              .then((resData) => {
                if (resData) {
                  mateSuppliers.splice(idx, 1, {
                    _id,
                    isActive,
                    isPreferred,
                    opened,
                    closed,
                    name,
                    locOrBranch,
                    phone1,
                    phone2,
                    fax,
                    email,
                    address1,
                    address2,
                    city,
                    state,
                    zip,
                    note,
                    personnels,
                    isPrimary,
                  });
                  setMateSuppliers([...mateSuppliers]);
                  toast.success("Branch Supplier updated successfully.");
                  dispatch(getMSuppliers());
                } else {
                  toast.error(res.error);
                }
              })
              .catch((err) => {
                toast.error(err);
              })
              .finally(() => setProcessing(false));
          } else {
            toast.error(res.error);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => setProcessing(false));
    }
  };

  const dltData = () => {
    const idx = mateSuppliers.findIndex((supp) => supp._id === _id);
    setProcessing(true);
    dispatch(deleteMSuppliers(_id))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          hideDeleteModal();
          mateSuppliers.splice(idx, 1);
          setMateSuppliers([...mateSuppliers]);
          toast.success("Supplier deleted successfully.");
          addNew(TABS_SUPPLIERS[0].id);
        } else {
          toast.error(res.error);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setProcessing(false));
  };
  return (
    <div
      style={
        permission
          ? { padding: "1em" }
          : { pointerEvents: "none", padding: "1em" }
      }
    >
      <Row className="_form_top" style={{ alignItems: "center" }}>
        <Col md="4" lg="2">
          <Form.Group>
            <Form.Check
              disabled={!permission}
              name="isActive"
              checked={isActive}
              onChange={(event) => setIsActive(event.target.checked)}
              label="Account Active"
            />
          </Form.Group>
        </Col>
        {[
          {
            label: "Opened",
            value: opened,
            setValue: setOpened,
            disabled: !isActive,
            key: "_1",
          },
          {
            label: "Closed",
            value: closed,
            setValue: setClosed,
            disabled: isActive,
            key: "_2",
          },
        ].map((datePick) => (
          <Col md="6" lg="3" key={datePick.label}>
            <DatePickerNew
              label={datePick.label}
              value={datePick.value}
              setValue={datePick.setValue}
              disabled={datePick.disabled}
              key={datePick.key}
            />
          </Col>
        ))}

        <Col md="6" lg="2">
          <RequiredText>*</RequiredText> <span>Required Fields</span>
        </Col>
        <Col>
          <Button
            onClick={() => setIsPrimary(false)}
            disabled={selectedSupp !== _id || !isActive || !permission}
          >
            Add Branch/Location
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Check
              disabled={!isActive}
              name="isPrefered"
              checked={isPreferred}
              onChange={(event) => setIsPreferred(event.target.checked)}
              label="Preferred Branch/Location"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="6" lg="4">
          <Form.Group>
            <Form.Label>
              Supplier Name: <RequiredText>*</RequiredText>
            </Form.Label>
            <Form.Control
              disabled={!isActive}
              type="text"
              name="name"
              value={name}
              onChange={(event) => handleChange(event, setName)}
            />
          </Form.Group>
        </Col>
        <Col md="6" lg="4">
          <Form.Group>
            <Form.Label>
              Branch/Location Name: <RequiredText>*</RequiredText>
            </Form.Label>

            <Form.Control
              disabled={!isActive}
              type="text"
              name="locOrBranch"
              value={locOrBranch}
              onChange={(event) => handleChange(event, setLocOrBranch)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <CommonAddressFields
          address1Name="address1"
          address2Name="address2"
          cityName="city"
          zipName="zip"
          stateName="state"
          address1={address1}
          address2={address2}
          city={city}
          zip={zip}
          state={state}
          setAddress1={setAddress1}
          setAddress2={setAddress2}
          setCity={setCity}
          setZip={setZip}
          setState={changeState}
          handleChange={handleChange}
          required
          allowDisabling={!isActive}
        />
      </Row>
      <Row>
        <PhoneDetails
          phone1Name="phone1"
          phone2Name="phone2"
          faxName="fax"
          emailName="email"
          phone1={phone1}
          phone2={phone2}
          fax={fax}
          email={email}
          state={state}
          setPhone1={setPhone1}
          setPhone2={setPhone2}
          setEmail={setEmail}
          setFax={setFax}
          handleChange={handleChange}
          required
          disabled={!isActive}
        />
      </Row>

      <Personnel
        personnel={personnels}
        setPersonnel={setPersonnels}
        posOptions={materialPersonnels}
        disabled={!isActive || !permission}
      />
      <Row>
        <Col sm="12" md="12">
          <Form.Group>
            <Form.Label>General Note About Supplier:</Form.Label>
            <Form.Control
              as="textarea"
              rows="1"
              name="note"
              value={note}
              disabled={!isActive}
              onChange={(event) => handleChange(event, setNote)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row
        style={{
          // justifyContent: "flex-end",
          margin: "1em 0",
          float: "right",
        }}
      >
        <div style={{ margin: "10px 1em", marginBottom: "2em" }}>
          {edit ? (
            <Button
              disabled={!isActive}
              onClick={() => setDltModal(true)}
              variant="danger"
              style={{ marginRight: "1em" }}
            >
              Delete
            </Button>
          ) : (
            <Button
              disabled={!isActive || hasNotChangedFields()}
              onClick={() => resetFields()}
              variant="danger"
              style={{ marginRight: "1em" }}
            >
              Cancel
            </Button>
          )}
          <Button
            disabled={
              !name ||
              !locOrBranch ||
              !phone1 ||
              !email ||
              !address1 ||
              !city ||
              !state ||
              !zip ||
              isNaN(zip) ||
              zip.length !== 5 ||
              !validator.isEmail(email) ||
              checkPhone(phone1) ||
              processing ||
              hasNotChangedFields()
            }
            onClick={() => addSuppliers()}
          >
            Save
          </Button>
        </div>
      </Row>
      <DeleteModal
        showDeleteModal={dltModal}
        deleteData={dltData}
        hideDeleteModal={hideDeleteModal}
        processing={processing}
      />
    </div>
  );
}
