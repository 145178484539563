const { saveData, getData } = require("services/storageManager");
const { find: findMongo, save: saveMongo } = require("./mongoDB");

const modelName = "allCrewDatabase";

const find = (query) => findMongo(modelName, query);
const save = (data) => saveMongo(modelName, data);
const getLength = () => {
  const documents = getData(modelName);
  if (documents === null) {
    return 0;
  }
  return documents.length;
};

const laborPricingCompany = { find, save, getLength };
export default laborPricingCompany;
