import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export const CheckMark = () => (
  <span>
    <FontAwesomeIcon color="#0979fa" className="ico" icon={faCheck} />
  </span>
);

export const displayPos = (types) => types.map((type) => (
  <option key={type.id} value={type.id}>
    {type.name}
  </option>
));

export const EmptyData = () => (
  <div className="_tab_msg">
    <p className="__msg">No data available.</p>
  </div>
);
