import { getLaborPricing } from "services/offlineComputing/controllers/laborPricingComapny";
import { getData } from "services/storageManager";
import { parseGuttersMetricData } from "./parseGutterMetricData";
import { getGuttersMetrics } from "services/offlineComputing/models/metricsModel";

const getParsedPricings = async (parsedMetricData, supplierId, companyId) => {
  if (parsedMetricData.length === 0) return [];
  const allData = await parsedMetricData.map(async (metricData) => {
    const { category, categoryType, style, color, size, length, subType } =
      metricData;
    let q = { category, categoryType };
    const hasSubType = subType && subType !== "N/A";
    const hasStyle = style && style !== "N/A";
    const hasColor = color && color !== "N/A";
    const hasSize = size && size !== "N/A";

    q = hasSubType ? { ...q, subType } : q;
    if (categoryType !== "Fascia") {
      q = hasStyle ? { ...q, style } : q;
      q = hasColor ? { ...q, color } : q;
      q = hasSize ? { ...q, size: size.replace(/[^a-zA-Z0-9 ]/g, "") } : q;
    }

    const pricing = await getLaborPricing(q, { supplierId, companyId });
    return {
      pricing,
      metricData,
    };
  });

  const res = await Promise.all(allData);
  const data = res.map((guttersData) => {
    const { pricing, metricData } = guttersData;
    let uomPrice = 0,
      uom = 0;

    if (pricing.length > 0) {
      uomPrice = pricing[0].uomPrice;
      uom = pricing[0].uom;
    }
    let original_list = {
      qty: metricData.qty,
    };

    const total = Number(uomPrice) * Number(metricData.qty || 0);

    return {
      ...metricData,
      uomPrice: Number(uomPrice).toFixed(2),
      uom,
      total: Number(total).toFixed(2),
      original_list: original_list,
    };
  });
  return data;
};
const getUnparsedGuttersMetricData = async ({ requestId }) => {
  const metricData = getGuttersMetrics(requestId); //getData(`Gmetrics${requestId}`);

  if (metricData === null) {
    return [];
  }

  return [metricData.data];
};

export const guttersSectionAllData = async (
  requestId,
  supplierId,
  companyId,
  excludedBuildings,
  excludedSections
) => {
  const parsedMetricData = await getGuttersSectionData({
    requestId,
    excludedBuildings,
    excludedSections,
  });

  const guttersData = await getParsedPricings(
    parsedMetricData,
    supplierId,
    companyId
  );

  return guttersData;
};

const getGuttersSectionData = async ({
  requestId,
  excludedBuildings,
  excludedSections,
}) => {
  const metricData = await getUnparsedGuttersMetricData({
    requestId,
  });

  return await parseGuttersMetricData(
    metricData[0],
    excludedBuildings,
    excludedSections
  );
};
