import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { PriceInput } from "components";

import { useDispatch, useSelector } from "react-redux";

import { uuid } from "uuidv4";
import validator from "validator";

import styles from "lib/config/baseStyles";
import displayMsg from "components/displayMsg";
import {
  editQuoteLaborSectionData,
  saveEditedQuoteLaborSectionData,
  updateTotalQuoteLaborSectionData,
} from "lib/redux/quote-proposals/quotes-proposals.actions";
import { debounce } from "utils/utils";
import LaborMiscItem from "./LaborMiscItem";
import SkylightLaborListData from "./SkylightLaborListData";
import { changeSupplier, supplierEmptyText } from "../utils";
import SelectSuppliers, {
  SupplierItemOnlyName,
  SelectSuppliersSection,
} from "../components/SelectSupliers";
import LaborIndividualData from "./LaborIndividualData";

import SectionDataList from "../components/SectionDataList";
import LoadingSkeleton from "../components/LoadingSkeleton";
import SmRetryBtn from "../components/SmRetryBtn";

export default function RoofingLabor({
  suppliers,
  selectedSupplier,
  setSelectedSupplier,
  metricId,
  isLockQuoteData,
}) {
  const dispatch = useDispatch();

  const [addMisc, setAddMisc] = useState(false);

  const [allTotalData, setAllTotalData] = useState({});
  const [allRoofingTotals, setAllRoofingTotals] = useState({});

  const {
    gettingLaborSectionData,
    quoteLaborSectionData,
    quoteLaborSectionDataErr,
  } = useSelector((state) => state.quoteLaborSectionData);

  const CATEGORYDATA = [
    {
      fields: ["accessory"],
      types: ["Replace", , "New Install", "Close Off"],
      series: "Skylight",
      totalKey: "skylightTotal",
      dataKey: "skylightData",
    },
  ];

  const onChangeSupplier = (e) => {
    changeSupplier(e.target.value, setSelectedSupplier);
  };

  const [error, setError] = useState(false);
  const getSubmissionData = (sectionData) => ({
    dataId: quoteLaborSectionData.dataId,
    sectionData,
  });

  const getTotalData = () => ({
    roofingLaborSubTotal: quoteLaborSectionData.roofingLaborSubTotal,
  });

  const updateTotals = (updatedData) => {
    dispatch(updateTotalQuoteLaborSectionData(updatedData));
  };

  const saveLaborSectionDataToDB = (data) => {
    dispatch(saveEditedQuoteLaborSectionData(data))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          setError(false);
          updateTotals(res.result);
        }
      })
      .catch((err) => setError(true));
  };

  const debounceSaveData = useRef(
    debounce((data) => saveLaborSectionDataToDB(data), 1000)
  );

  const miscDebounceSaveData = useRef(
    debounce((data) => saveLaborSectionDataToDB(data), 1000)
  );

  const saveData = (dataArr) => {
    const data = getSubmissionData(dataArr);
    const dataWithTotal = {
      ...getTotalData(),
      ...data,
    };
    dispatch(editQuoteLaborSectionData(dataWithTotal));
    debounceSaveData.current(data);
  };

  const addMiscItem = (item) => {
    const dataArr = quoteLaborSectionData.sectionData.slice();
    dataArr.push(item);
    saveData(dataArr);
  };

  const editMiscItem = (idx, dataItem) => {
    const dataArr = quoteLaborSectionData.sectionData.slice();
    dataArr.splice(idx, 1, dataItem);
    saveData(dataArr);
  };

  const deleteMiscItem = (idx) => {
    const dataArr = quoteLaborSectionData.sectionData.slice();
    dataArr.splice(idx, 1);
    saveData(dataArr);
  };

  const retry = () => {
    saveLaborSectionDataToDB(quoteLaborSectionData);
  };

  const onAddMisc = () => {
    const initialAmount = Number(0).toFixed(2);
    const newMiscItem = {
      _id: uuid(),
      field: "",
      uom: "",
      uomPrice: initialAmount,
      total: initialAmount,
      qty: "",
      isCustom: true,
    };
    addMiscItem(newMiscItem);
  };

  return (
    <div style={{ margin: "1em" }}>
      <section>
        <Row lg="12">
          <Col style={styles.reduceWidthCol}>
            <h4 style={{ marginBottom: "-.5rem" }}>Roofing Labor</h4>
          </Col>
          <Col className="d-flex justify-content-end">
            {error && <SmRetryBtn retry={retry} />}
          </Col>
        </Row>
        <Row>
          <SelectSuppliers
            selectedSupplier={selectedSupplier}
            onSelectSupplier={onChangeSupplier}
            loading={false}
            suppliers={suppliers}
            emptyText={supplierEmptyText(suppliers.length)}
            SupplierItem={SupplierItemOnlyName}
            isLockQuoteData={isLockQuoteData}
          />
        </Row>
        <SelectSuppliersSection>
          <Col
            style={styles.reduceWidthCol}
            style={{ textAlign: "center" }}
            lg="2"
          >
            <Form.Label style={{ marginTop: "6%" }}>Difficulty</Form.Label>
          </Col>
          <Col
            style={styles.reduceWidthCol}
            style={{ textAlign: "center" }}
            lg="2"
          >
            <Form.Label style={{ marginTop: "6%" }}>Work Type</Form.Label>
          </Col>
          <Col
            style={styles.reduceWidthCol}
            style={{ textAlign: "center" }}
            lg="2"
          >
            <Form.Label style={{ marginTop: "6%" }}>Install</Form.Label>
          </Col>
        </SelectSuppliersSection>

        <SectionDataList
          selectedSupplier={selectedSupplier}
          metricId={metricId}
          loadingData={gettingLaborSectionData}
          sectionData={
            quoteLaborSectionData
              ? quoteLaborSectionData.sectionData
              : quoteLaborSectionData
          }
          dataError={quoteLaborSectionDataErr}
          saveSectionDataToDB={saveLaborSectionDataToDB}
          deleteMiscItem={deleteMiscItem}
          editMiscItem={editMiscItem}
          MiscItemComponent={LaborMiscItem}
          LoadingSkeleton={LoadingSkeleton}
          IndividualDataItem={LaborIndividualDataItem}
          displayMsg={displayMsg}
          isLockQuoteData={isLockQuoteData}
        />

        <MiscItemComponent
          onAddMisc={onAddMisc}
          isLockQuoteData={isLockQuoteData}
        />
        {quoteLaborSectionData && (
          <LaborTotalSection
            total={quoteLaborSectionData.roofingLaborSubTotal}
          />
        )}
      </section>
    </div>
  );
}

const MiscItemComponent = ({ onAddMisc, isLockQuoteData }) => (
  <Row>
    <Col style={styles.reduceWidthCol} lg="2">
      <Button onClick={onAddMisc} disabled={isLockQuoteData?.isLocked}>
        Add Misc Item
      </Button>
    </Col>
  </Row>
);

function LaborTotalSection({ total }) {
  return (
    <Row>
      <Col style={styles.reduceWidthCol} lg="6" />
      <Col style={styles.reduceWidthCol} style={{ flex: "77%" }}>
        <Row>
          <Col style={styles.reduceWidthCol} style={{ textAlign: "right" }}>
            <Form.Label className="mt-3">Roofing Labor Sub Total:</Form.Label>
          </Col>
        </Row>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput
          price={total}
          disabled
          style={{ background: "#28a745", color: "#fff" }}
        />
      </Col>
    </Row>
  );
}

function LaborIndividualDataItem({
  pricing,
  selectedSupplier,
  idx,
  metricId,
  sectionData,
  saveSectionDataToDB,
  editItem,
  isLockQuoteData,
}) {
  const {
    _id,
    total,
    field,
    type,
    types,
    uomPrice,
    uom,
    subType,
    qty,
    series,
    additionalDetail,
    original_list,
  } = pricing;
  const handleEdit = (newData) => editItem(newData);

  const changeData = (key, value) => ({
    ...pricing,
    [key]: value,
  });

  const handleChangeQty = (e) => {
    const { value } = e.target;

    const newTotal =
      validator.isNumeric(`${value}`) && validator.isNumeric(`${uomPrice}`)
        ? Number(value * uomPrice).toFixed(2)
        : total;

    const newData = changeData("qty", value);
    newData.total = newTotal;

    handleEdit(newData);
  };

  const typeText = `${type}${additionalDetail ? ` - ${additionalDetail}` : ""}`;

  return series ? (
    <SkylightLaborListData
      selectedSupplier={selectedSupplier}
      data={pricing}
      onChangeQty={handleChangeQty}
      editItem={editItem}
      isLockQuoteData={isLockQuoteData}
    />
  ) : (
    <LaborIndividualData
      field={field}
      subType={subType}
      type={typeText}
      total={total}
      qty={qty}
      uom={uom}
      price={uomPrice}
      onChangeQty={handleChangeQty}
      original_list={original_list}
      isLockQuoteData={isLockQuoteData}
    />
  );
}
