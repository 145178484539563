import { PermissionActionTypes } from "./permission.type";

const INITIAL_STATE_pERMISSIONS = {
  gettingPermission: false,
  permission: null,
  permissionErr: null,
};

export const GetAllPermissionReducer = (
  state = INITIAL_STATE_pERMISSIONS,
  action
) => {
  switch (action.type) {
    case PermissionActionTypes.GET_PERMISSION_START:
      return {
        ...state,
        gettingPermission: true,
        permission: null,
        permissionErr: null,
      };

    case PermissionActionTypes.GET_PERMISSION_SUCCESS:
      return {
        ...state,
        gettingPermission: false,
        permission: action.payload,
        permissionErr: null,
      };

    case PermissionActionTypes.GET_PERMISSION_FAILURE:
      return {
        ...state,
        gettingPermission: false,
        permission: null,
        permissionErr: action.payload,
      };

    default:
      return state;
  }
};
