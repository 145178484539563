import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { PriceInput, PopupModal, LoadingLoader } from "components";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styles from "lib/config/baseStyles";
import { uuid } from "uuidv4";
import displayMsg from "components/displayMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  getSupplierPricing,
  editQuoteMaterialSectionData,
  saveEditedQuoteMaterialSectionData,
  updateTotalQuoteMaterialSectionData,
} from "lib/redux/quote-proposals/quotes-proposals.actions";
import validator from "validator";
import { debounce } from "utils/utils";
import { changeSupplier, supplierEmptyText } from "../utils";
import SelectSuppliers, {
  SelectOption,
  SupplierItem,
  SelectSuppliersSection,
} from "../components/SelectSupliers";
import LoadingSkeleton from "../components/LoadingSkeleton";
import MaterialIndividualData from "./MaterialIndividualData";

import SectionDataList from "../components/SectionDataList";
import SmRetryBtn from "../components/SmRetryBtn";
import MaterialMiscItem from "./MaterialMiscItem";
import * as quoteTypes from "../../../lib/redux/quote-proposals/quotes-proposals.types";
//new commit
export default function Material({
  mateSuppliers,
  setSelectedSupplier,
  selectedSupplier,
  loadingSuppliers,
  metricId,
  dataList,
  isLockQuoteData,
}) {
  const {
    gettingMaterialSectionData,
    quoteMaterialSectionData,
    quoteMaterialSectionDataErr,
  } = useSelector((state) => state.quoteMaterialSectionData);

  const { quoteProposalData } = useSelector((state) => state.quoteProposal);
  const { proposalIdx } = useSelector(
    (state) => state.activeProposal
  );

  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [materialSectionData, setMaterialSectionData] = useState(null);

  useEffect(() => {
    setMaterialSectionData({ ...quoteMaterialSectionData });
  }, [gettingMaterialSectionData, quoteMaterialSectionData]);

  const getSubmissionData = (sectionData) => {
    return {
      dataId: materialSectionData.dataId,
      proposalId: materialSectionData.proposalId, //._id,
      sectionData,
      stateTax: materialSectionData.stateTax,
      taxExemptId: materialSectionData.taxExemptId
        ? materialSectionData.taxExemptId
        : "",
    };
  };
  const getTotalData = () => ({
    materialSubTotal: quoteMaterialSectionData.materialSubTotal,
    total: quoteMaterialSectionData.total,
  });

  const updateTotals = (updatedData) => {
    dispatch(updateTotalQuoteMaterialSectionData(updatedData));
  };

  const saveMaterialSectionDataToDB = (data, isMisc) => {
    setLoading(true);

    dispatch(saveEditedQuoteMaterialSectionData(data))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          setError(false);
          setLoading(false); //loading false

          updateTotals(res.result);
          if (isMisc) return;
          let submitData = null;
          try {
            submitData = getSubmissionData(res.result.sectionData);
          } catch {
            submitData = data;
            submitData.sectionData = res.result.sectionData;
            delete submitData.updatedMaterial;
            dispatch(
              editQuoteMaterialSectionData({ ...res.result, ...submitData })
            );
            throw "solved";
          }

          dispatch(
            editQuoteMaterialSectionData({ ...res.result, ...submitData })
          );
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        if (err == "solved") {
          setError(false);
        } else setError(true);
      });
  };

  const editAllData2 = (newData, isMisc) => {
    saveMaterialSectionDataToDB(
      {
        ...newData[0],
        proposalId: newData[0].proposalId._id
          ? newData[0].proposalId._id
          : newData[0].proposalId,
      },
      isMisc
    );
  };
  const debounceSaveData = useCallback(
    debounce((newData) => editAllData2({ ...newData }, false), 1000),
    []
  );
  const debounceSaveDataMisc = useCallback(
    debounce((newData) => editAllData2({ ...newData }, true), 1000),
    []
  );

  const retry = () => {
    const dataArr = quoteMaterialSectionData.sectionData.slice();
    const data = getSubmissionData(dataArr);
    //data = { ...data, proposalId: data.proposalId._id };
    debounceSaveData(data);
  };

  const addMiscItem = (item, isMisc = false) => {
    const dataArr = quoteMaterialSectionData.sectionData.slice();
    dataArr.push(item);
    const data = getSubmissionData(dataArr);
    const dataWithTotal = {
      ...getTotalData(),
      ...data,
    };
    dispatch(editQuoteMaterialSectionData(dataWithTotal));

    debounceSaveData(data);
  };

  const editMiscItem = (idx, dataItem, isMisc = false) => {
    const dataArr = quoteMaterialSectionData.sectionData.slice();
    dataArr.splice(idx, 1, dataItem);

    let data = getSubmissionData(dataArr);
    ///check here
    data = { ...data, updatedMaterial: { ...dataItem, index: idx } };
    const dataWithTotal = {
      ...getTotalData(),
      ...data,
    };
    dispatch(editQuoteMaterialSectionData(dataWithTotal));

    if (isMisc) {
      debounceSaveDataMisc(data);
    } else {
      debounceSaveData(data);
    }
  };

  const deleteMiscItem = (idx) => {
    const dataArr = quoteMaterialSectionData.sectionData.slice();
    dataArr.splice(idx, 1);
    const data = getSubmissionData(dataArr);
    const dataWithTotal = {
      ...getTotalData(),
      ...data,
    };

    dispatch(editQuoteMaterialSectionData(dataWithTotal));

    debounceSaveData(data);
  };

  const onAddMisc = () => {
    const initialAmount = Number(0).toFixed(2);
    const newMiscItem = {
      _id: uuid(),
      series: "",
      uomPrice: initialAmount,
      total: initialAmount,
      qty: "",
      isCustom: true,
    };
    addMiscItem(newMiscItem);
  };

  const onChangeTax = (e) => {
    const dataArr = quoteMaterialSectionData.sectionData.slice();
    const data = getSubmissionData(dataArr);
    data.stateTax = e.target.value;
    const dataWithTotal = {
      ...getTotalData(),
      ...data,
    };
    dispatch(editQuoteMaterialSectionData(dataWithTotal));
    let tmp_proposal = [...quoteProposalData];
    tmp_proposal[proposalIdx].stateTax = dataWithTotal.stateTax;
    dispatch({
      type: quoteTypes.QUOTE_PROPOSAL_DATA_EDIT_ALL,
      payload: { updatedData: [...tmp_proposal] },
    });
    debounceSaveData(data);
  };

  const onChangeTaxExempt = (e) => {
    const dataArr = quoteMaterialSectionData.sectionData.slice();
    const data = getSubmissionData(dataArr);
    data.taxExemptId = e.target.value;
    const dataWithTotal = {
      ...getTotalData(),
      ...data,
    };

    dispatch(editQuoteMaterialSectionData(dataWithTotal));

    debounceSaveData(data);
  };

  const onSelectSupplier = (e) => {
    changeSupplier(e.target.value, setSelectedSupplier);
  };

  return (
    <div style={{ margin: "1em" }}>
      <section>
        <Row>
          <Col style={styles.reduceWidthCol}>
            <Row lg="12">
              <Col>
                <h4 style={{ marginBottom: "-.5rem" }}>Material </h4>
              </Col>
              <Col className="d-flex justify-content-end">
                {loading ? null : error ? <SmRetryBtn retry={retry} /> : null}
              </Col>
            </Row>
          </Col>
        </Row>
        <SelectSuppliers
          selectedSupplier={selectedSupplier}
          onSelectSupplier={onSelectSupplier}
          loading={loadingSuppliers}
          suppliers={mateSuppliers}
          emptyText={supplierEmptyText(mateSuppliers.length)}
          SupplierItem={SupplierItem}
          isLockQuoteData={isLockQuoteData}
        />
        <SelectSuppliersSection>
          <Col>
            <Form.Label style={{ marginTop: "15%" }}>Manufacturer</Form.Label>
          </Col>
          <Col>
            <Form.Label style={{ marginTop: "15%" }}>Material</Form.Label>
          </Col>
          <Col>
            <Form.Label style={{ marginLeft: "170%", marginTop: "15%" }}>
              Series
            </Form.Label>
          </Col>

          <Col></Col>
          <Col></Col>
        </SelectSuppliersSection>

        <SectionDataList
          isLoading={loading}
          metricId={metricId}
          loadingData={gettingMaterialSectionData}
          sectionData={
            quoteMaterialSectionData
              ? quoteMaterialSectionData.sectionData
              : quoteMaterialSectionData
          }
          dataError={quoteMaterialSectionDataErr}
          saveSectionDataToDB={saveMaterialSectionDataToDB}
          deleteMiscItem={deleteMiscItem}
          editMiscItem={editMiscItem}
          selectedSupplier={selectedSupplier}
          MiscItemComponent={MaterialMiscItem}
          LoadingSkeleton={LoadingSkeleton}
          IndividualDataItem={MaterialIndividualDataItem}
          displayMsg={displayMsg}
          quoteMaterialSectionData={quoteMaterialSectionData}
          isLockQuoteData={isLockQuoteData}
        />

        <MiscItemComponent
          onAddMisc={onAddMisc}
          quoteMaterialSectionData={quoteMaterialSectionData}
          isLockQuoteData={isLockQuoteData}
        />
        {selectedSupplier && quoteMaterialSectionData && (
          <MaterialDataSection
            tax={
              quoteMaterialSectionData.proposalId.stateTax
                ? quoteMaterialSectionData.proposalId.stateTax
                : quoteMaterialSectionData.stateTax
            }
            taxExemptId={quoteMaterialSectionData.taxExemptId}
            materialTotal={quoteMaterialSectionData.materialSubTotal}
            total={quoteMaterialSectionData.total}
            onChangeTax={onChangeTax}
            dataList={dataList}
            onChangeTaxExempt={onChangeTaxExempt}
            quoteMaterialSectionData={quoteMaterialSectionData}
            isLockQuoteData={isLockQuoteData}
          />
        )}
      </section>
      {loading && (
        <div
          style={{
            display: "flex",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#01010101",
          }}
        >
          <LoadingLoader />
        </div>
      )}
    </div>
  );
}

const PricingListData = (
  allData,
  selectedSeriesId,
  changeFromPricingListModal
) =>
  allData.map((pricing) => (
    <li key={pricing._id}>
      <button
        onClick={() => changeFromPricingListModal(pricing)}
        disabled={selectedSeriesId === pricing._id}
        style={{ border: "1px gray solid", marginTop: "15px" }}
        class="rounded-pill justify-content-center"
      >
        {pricing.series}
        {selectedSeriesId === pricing._id && (
          <span>
            <FontAwesomeIcon color="#0979fa" className="ico" icon={faCheck} />
          </span>
        )}
      </button>
    </li>
  ));

const onChangePricing = ({
  idx,
  manufacturers,
  order,
  fromMetric,
  key,
  qty,
  dispatch,
  changedPricingData,
  metricId,
  sectionData,
  selectedSupplier,
  handleEdit,
  original_list,
}) => {
  const { _id, category, series, uomPrice, uom, subType, categoryType } =
    changedPricingData;
  const manufacturer =
    changedPricingData.manufacturer === "Various"
      ? ""
      : changedPricingData.manufacturer;

  const editedData = {
    order,
    _id,
    manufacturer,
    series,
    uomPrice: Number(uomPrice).toFixed(2),
    subType,
    pricingCategory: category,
    categoryType,
    qty,
    uom,
    category: key,
    manufacturers,
    fromMetric,
    total: Number(uomPrice * qty).toFixed(2),
    original_list,
  };
  handleEdit(editedData);
};

function MaterialIndividualDataItem({
  pricing,
  selectedSupplier,
  idx,
  metricId,
  sectionData,
  saveSectionDataToDB,
  editItem,
  isLockQuoteData,
  isLoading,
}) {
  const {
    _id,
    category,
    series,
    uomPrice,
    uom,
    subType,
    categoryType,
    pricingCategory,
    manufacturer,
    fromMetric,
    manufacturers,
    qty,
    order,
    total,
    original_list,
  } = pricing;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showModal, setModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [allData, SetAllData] = useState([]);

  const handleEdit = (newData) => editItem(newData);

  const changeData = (key, value) => ({
    ...pricing,
    [key]: value,
  });
  const handleChangeQty = (e) => {
    const { value } = e.target;
    const newTotal =
      validator.isNumeric(value) && validator.isNumeric(pricing.uomPrice)
        ? Number(value * pricing.uomPrice).toFixed(2)
        : pricing.total;
    const newData = changeData("qty", value.toString());
    newData.total = newTotal;

    handleEdit(newData);
  };

  const handlePriceChange = (value) => {
    const newTotal =
      validator.isNumeric(value) && validator.isNumeric(pricing.qty.toString())
        ? Number(value * pricing.qty).toFixed(2)
        : pricing.total;
    const newData = changeData("uomPrice", value);
    newData.total = newTotal;

    handleEdit(newData);
  };

  const onHide = () => setModal(false);
  const openModal = () => {
    setModal(true);
  };

  useEffect(() => {
    if (selectedSupplier && !fromMetric && showModal) {
      let query = { category: pricingCategory };
      query = subType ? { ...query, subType } : { ...query };
      query = manufacturer ? { ...query, manufacturer } : { ...query };
      query = categoryType ? { ...query, categoryType } : { ...query };
      setDisabled(false);
      setLoading(true);

      dispatch(getSupplierPricing(selectedSupplier, query))
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            if (res.result.length > 0) {
              SetAllData(res.result);
            } else {
              SetAllData([]);

              setDisabled(true);
            }
          } else {
            toast.error(res.error);
          }
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoading(false));
    } else {
      SetAllData([]);

      setDisabled(true);
    }
  }, [selectedSupplier, fromMetric, showModal]);

  const changeFromPricingListModal = (changedPricingData) => {
    onChangePricing({
      idx,
      manufacturers,
      order,
      fromMetric,
      key: category,
      qty,
      dispatch,
      changedPricingData,
      metricId,
      sectionData,
      selectedSupplier,
      handleEdit,
      original_list,
    });
  };

  const changeManufacturer = (manufacturer) => {
    const newId = _id.replace("old:");
    const changedPricingData = {
      _id: `old:${newId}`,
      category: pricingCategory,
      series: "Tap to select",
      uomPrice: 0,
      uom: "",
      subType,
      categoryType,
      manufacturer,
    };

    onChangePricing({
      idx,
      manufacturers,
      order,
      fromMetric,
      key: category,
      qty,
      dispatch,
      changedPricingData,
      metricId,
      sectionData,
      selectedSupplier,
      handleEdit,
      original_list,
    });
  };

  const subTypeDetails = subType && subType !== "N/A" ? ` - ${subType}` : "";
  const priceChangeHandler =
    category === "Skylight" ? handlePriceChange : () => { };

  const PricingListModal = () => (
    <PopupModal
      show={showModal}
      heading=""
      onHide={onHide}
      bold
      centered={false}
      styles={{ border: "none" }}
    >
      {PricingList(loading, allData, _id, changeFromPricingListModal)}
    </PopupModal>
  );

  return (
    <>
      <MaterialIndividualData
        disablePriceChange={category !== "Skylight"}
        handlePriceChange={priceChangeHandler}
        manufacturerList={manufacturers}
        manufacturer={manufacturer}
        series={series}
        category={category + subTypeDetails}
        qty={qty}
        uom={uom}
        price={uomPrice}
        total={total}
        disabled={fromMetric}
        openModal={openModal}
        PricingListModal={PricingListModal}
        changeManufacturer={changeManufacturer}
        handleChangeQty={handleChangeQty}
        original_list={original_list}
        isLockQuoteData={isLockQuoteData?.isLocked}
        isLoading={isLoading}
      />
      {PricingListModal()}
    </>
  );
}

const PricingList = (
  loading,
  allData,
  selectedPricing,
  changeFromPricingListModal
) => {
  if (loading) {
    return (
      <div className="__modal_msg">
        <div className="_tab_msg" style={{ paddingTop: 0 }}>
          <LoadingLoader />
          <p className="__msg">Please wait while data is being loaded.</p>
        </div>
      </div>
    );
  }
  if (allData.length > 0) {
    return (
      <div>
        <h5 style={{ marginBottom: "revert" }}>
          Please select one of the following.
        </h5>
        <div className="__quote_pricing_list">
          <ul>
            {PricingListData(
              allData,
              selectedPricing,
              changeFromPricingListModal
            )}
          </ul>
        </div>
      </div>
    );
  }
  return (
    <div className="__modal_msg">
      <div className="_tab_msg" style={{ paddingTop: 0 }}>
        <p className="__msg">No records availabe.</p>
      </div>
    </div>
  );
};

const MiscItemComponent = ({ onAddMisc, isLockQuoteData }) => (
  <Row>
    <Col style={styles.reduceWidthCol} lg="2">
      <Button onClick={onAddMisc} disabled={isLockQuoteData?.isLocked}>
        Add Misc Item
      </Button>
    </Col>
  </Row>
);

function MaterialDataSection({
  tax,
  onChangeTaxExempt,
  taxExemptId,
  materialTotal,
  total,
  onChangeTax,
  dataList,
  isLockQuoteData,
}) {
  return (
    <>
      <Row>
        <Col
          style={{
            ...styles.reduceWidthCol,
            flex: "77%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Form.Label style={{ marginTop: "2%" }}>
            Material Sub Total:
          </Form.Label>
        </Col>
        <Col
          style={{
            ...styles.reduceWidthCol,
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <PriceInput input="Total" price={materialTotal} disabled />
        </Col>
      </Row>
      <Row>
        <Col style={styles.reduceWidthCol} lg="6" />
        <Col style={styles.reduceWidthCol} lg="3">
          {tax !== null && Number(tax) === 0 && (
            <Form.Control
              value={taxExemptId}
              onChange={onChangeTaxExempt}
              type="text"
              style={{ textAlign: "center" }}
              placeholder="Tax Exempt ID#"
            />
          )}
        </Col>
        <Col style={styles.reduceWidthCol} lg="3">
          <Row>
            <Col style={{ textAlign: "right" }}>
              <Form.Label>State Tax:</Form.Label>
            </Col>
            <Col>
              <Form.Group>
                <Form.Control
                  as="select"
                  style={
                    !tax
                      ? {
                        borderColor: "red",
                        color: "red",
                      }
                      : {}
                  }
                  value={tax}
                  onChange={onChangeTax}
                  disabled={isLockQuoteData?.isLocked}
                >
                  <option value="">Select</option>
                  {dataList &&
                    dataList.length > 0 &&
                    dataList.map((taxData) => (
                      <TaxOption taxData={taxData} key={taxData._id} />
                    ))}
                  <option value={0}>Exempt</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col style={styles.reduceWidthCol} lg="6" />
        <Col style={styles.reduceWidthCol} />
        <Col style={styles.reduceWidthCol} />
        <Col style={{ ...styles.reduceWidthCol, textAlign: "right" }}>
          <Form.Label style={{ marginTop: "10%" }}>Total:</Form.Label>
        </Col>
        <Col
          style={{
            ...styles.reduceWidthCol,
            display: "flex",
            alignItems: "center",
          }}
        >
          <PriceInput
            price={tax ? total : 0}
            disabled
            style={{ background: "#28a745", color: "#fff" }}
          />
        </Col>
      </Row>
    </>
  );
}

function TaxOption({ taxData }) {
  return (
    <SelectOption
      value={taxData.per}
      display={`${taxData.per}% (${taxData.country})`}
    />
  );
}
