import React, { useState, useEffect } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import Components from "components";

import staticTexts from "./staticTexts";

function BuildingListModal({
  text,
  show,
  onHide,
  children,
  heading,
  metrics,
  setSelectedBuilding,
  toggleModals,
  setSelectedBuildingName,
}) {
  const { PopupModal } = Components;
  const [pageNum, setPageNum] = useState(1);
  const [maxItem] = useState(5);
  const [list, setList] = useState([]);
  const [address, setAddress] = useState(1);

  useEffect(() => {
    if (metrics && metrics.roof) {
      setAddress(metrics.roof.address);
      if (metrics.roof.buildings || metrics.roof.buildings?.length > 0) {
        const buildingList = [];
        metrics.roof.buildings.map((building) => buildingList.push(building));
        setList(buildingList);
      }
    }
  }, [metrics]);

  const prevPage = () => setPageNum(pageNum - 1);

  const nextPage = () => setPageNum(pageNum + 1);

  const lastI = pageNum * maxItem;
  const startI = lastI - maxItem;

  return (
    <PopupModal
      show={show}
      heading={`${staticTexts.buildingHeading}
      - ${address}
      `}
      onHide={() => onHide()}
      bold
      centered={false}
      styles={{ border: "none" }}
      size="xl"
    >
      <p style={{ fontWeight: "400", marginTop: "-2em" }}>
        {staticTexts.buildingListSubHead}
      </p>
      {list.length > 0 && (
        <Row>
          <Col>
            <div>
              <h5 style={{ textAlign: "center" }}>
                <b>Condo/Coop Buildings </b>
              </h5>
              <ul className="buildings-list">
                {list.slice(startI, lastI).map((unit) => (
                  <li key={unit.bid} className="coop-units">
                    <Row
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <Col>
                        <h5
                          style={{
                            textAlign: "center",
                            cursor: "pointer",
                            margin: " auto 0",
                            fontWeight: "bold",
                          }}
                        >
                          {unit.bname}
                        </h5>
                      </Col>
                      <Col lg={1.3}>
                        <Button
                          style={{ float: "right" }}
                          onClick={() => {
                            setSelectedBuilding(unit.bid);
                            setSelectedBuildingName(unit.bname);
                            toggleModals(true, false);
                          }}
                        >
                          View Sections
                        </Button>
                      </Col>
                    </Row>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      )}
      <Modal.Footer
        style={{
          justifyContent: "space-between",
          border: "none",
          padding: "0 .75rem",
        }}
      >
        <Components.ArrowPagination
          totalItem={list.length}
          pageNum={pageNum}
          prevPage={prevPage}
          nextPage={nextPage}
          maxItem={maxItem}
        />
        <Button
          style={{ width: "130px", height: "50px" }}
          variant="danger modified-btn"
          onClick={() => onHide()}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </PopupModal>
  );
}

export default BuildingListModal;
