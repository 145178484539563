import { useState } from "react";
import { axiosInstance as axios } from "services";
import { getEndPoints } from "api/api";
import { editLock } from "services/offlineComputing/routes/generateQuote";
import { getUserLocal } from "lib/constants/localbase";
import { ApiEndpoints } from "lib/config/baseSettings";
const url = getEndPoints(process.env.REACT_APP_MODE);

const useLockQuote = () => {
  const [quotelockData, setQuotelockData] = useState("");
  const [gettingLockquoteData, setGettingLockquoteData] = useState("");

  const lockeQuoteData = async (supplierId, metricId, proposalId, data) => {
    setGettingLockquoteData(true);
    if (!navigator.onLine) {
      let userDb = await getUserLocal();
      const companyId = userDb.userData.companyId;

      if (!companyId) {
        throw "companyId not found";
      }
      const resulte = await editLock({
        supplierId,
        metricId,
        proposalId,
        companyId,
        postData: data,
      });
      if (resulte === true) {
        setQuotelockData(true);
        setGettingLockquoteData(false);
        return { error: null, resulte };
      } else {
        setGettingLockquoteData(false);
        return { error: resulte };
      }
    }
    return axios
      .patch(
        `${ApiEndpoints.QUOTE.LOCK_QUOTE_DATA}?supplierId=${supplierId}&metricId=${metricId}&proposalId=${proposalId}`,
        data
      )

      .then((response) => {
        setQuotelockData(response.data.success);
        setGettingLockquoteData(false);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          setGettingLockquoteData(false);
          return { error: error.response };
        }
      });
  };

  return { quotelockData, gettingLockquoteData, lockeQuoteData };
};
export default useLockQuote;
