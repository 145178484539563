import React from "react";
import Next from "assets/right.svg";
import Prev from "assets/left.svg";
import { getAllHours } from "./utils";
import { DropTarget } from "react-drag-drop-container";

export default function DayView({
  date,
  onPrevDay,
  onNextDay,
  dayviewstart,
  dayviewend,
  getDayEvents,
  isSameDay,
  dateObj,
  ...rest
}) {
  const hoursInBetween = () => {
    const hours = getAllHours(dayviewstart, dayviewend);
    return hours.map((hour) => (
      <div className="hour" key={hour}>
        <div className="hour-space">
          <span id="hour-txt">{hour}</span>
        </div>
        <div className="hour-space"></div>
      </div>
    ));
  };

  return (
    <div>
      <div className="_heading">
        <div>
          <button onClick={() => onPrevDay()}>
            <img src={Prev} height="25" width="25" alt="prev" />
          </button>
        </div>
        <div className="month-name" style={{ fontSize: "1.8em" }}>
          {date}
        </div>
        <div>
          <button onClick={() => onNextDay()}>
            <img src={Next} height="25" width="25" alt="next" />
          </button>
        </div>
      </div>
      {isSameDay ? (
        <div className="day-view">
          <DropTarget
            targetKey="calendar"
            {...rest}
            dropData={{
              date: dateObj,
            }}
          >
            {hoursInBetween()}
            <div className="_day_events">{getDayEvents}</div>
          </DropTarget>
        </div>
      ) : (
        <div className="day-view">
          {hoursInBetween()}
          <div className="_day_events">{getDayEvents}</div>
        </div>
      )}
    </div>
  );
}
