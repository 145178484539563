import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import styles from "lib/config/baseStyles";

import PriceInput from "components/PriceIput/PriceInput";
import QTYInput from "../components/QTYInput";

export default function LaborIndividualData({
  field,
  type,
  subType,
  uom,
  qty,
  price,
  total,
  onChangeQty,
  original_list,
  isLockQuoteData,
}) {
  return (
    <Row>
      <Col style={styles.reduceWidthCol} lg="2">
        <Form.Control
          disabled
          value={field}
          type="text"
          style={{ textAlign: "center" }}
        />
      </Col>
      <Col style={styles.reduceWidthCol} lg="2">
        {type && (
          <Form.Control
            disabled
            value={type}
            type="text"
            style={{ textAlign: "center" }}
          />
        )}
      </Col>
      <Col style={styles.reduceWidthCol} lg="2">
        {subType && (
          <Form.Control
            disabled
            value={subType}
            type="text"
            style={{ textAlign: "center" }}
          />
        )}
      </Col>
      <Col style={styles.reduceWidthCol}>
        <Form.Group>
          <Form.Control
            disabled
            type="text"
            style={{ textAlign: "center" }}
            value={uom}
          />
        </Form.Group>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <QTYInput
          value={qty}
          onChange={onChangeQty}
          styless={original_list?.qty != qty}
          disabled={isLockQuoteData?.isLocked}
        />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput disabled price={price} />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput disabled price={total} input="Total" />
      </Col>
    </Row>
  );
}
