import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AdminRoutes(props) {
  const { component: Component, ...rest } = props;
  const { user } = useSelector((state) => state.login);
  const location = useLocation();
  console.log("user 111", user);
  return (
    <Route
      {...rest}
      render={(props) =>

        user ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { referrer: location.pathname },
            }}
          />
        )
      }
    />
  );
}


        // (user &&
        //   user?.userData?.role[0]?.roleName?.toLowerCase().includes("admin")) ||
        // (user &&
        //   user.userData?.role[0]?.roleName
        //     ?.toLowerCase()
        //     ?.includes("project_manager")) ||
        // (user &&
        //   user.userData?.role[0]?.roleName
        //     ?.toLowerCase()
        //     ?.includes("sales_manager")) ||
        // (user &&
        //   user?.userData?.role[0]?.roleName
        //     ?.toLowerCase()
        //     ?.includes("sales")) ||
        // (user &&
        //   user.userData?.role[0]?.roleName
        //     ?.toLowerCase()
        //     ?.includes("repair")) ||
        // (user &&
        //   user.userData?.role[0]?.roleName
        //     ?.toLowerCase()
        //     ?.includes("receptionist")) ||
        // (user &&
        //   user.userData?.role[0]?.roleName
        //     ?.toLowerCase()
        //     ?.includes("office_manager")) ||
        // (user &&
        //   user.userData?.role[0]?.roleName
        //     ?.toLowerCase()
        //     ?.includes("foreman")) ||
        // (user &&
        //   user.userData?.role[0]?.roleName
        //     ?.toLowerCase()
        //     ?.includes("executive"))