/* eslint-disable no-underscore-dangle */
import React from 'react';
import './Suppliers.scss';

import DatePickerNew from 'components/DatePickerWithRemove';

import { Col, Form } from 'react-bootstrap';

export default function InsuranceFields({
  company,
  setCompany,
  underwriter,
  setUnderwriter,
  policy,
  setPolicy,
  effective,
  setEffective,
  expiration,
  setExpiration,
  handleChange,
  disabled = false,
}) {
  return (
    <>
      <Col xs="6" md="3">
        <Form.Group>
          <Form.Label>Insurance Company:</Form.Label>
          <Form.Control
            type="text"
            name="company"
            value={company}
            disabled={disabled}
            onChange={(event) => handleChange(event, setCompany)}
          />
        </Form.Group>
      </Col>
      <Col xs="6" md="3">
        <Form.Group>
          <Form.Label>Underwriter:</Form.Label>
          <Form.Control
            type="text"
            name="underwriter"
            value={underwriter}
            disabled={disabled}
            onChange={(event) => handleChange(event, setUnderwriter)}
          />
        </Form.Group>
      </Col>
      <Col xs="6" md="2">
        <Form.Group>
          <Form.Label>Policy #:</Form.Label>
          <Form.Control
            type="text"
            name="policy"
            value={policy}
            disabled={disabled}
            onChange={(event) => handleChange(event, setPolicy)}
          />
        </Form.Group>
      </Col>

      {[
        {
          label: '',
          value: effective,
          setValue: setEffective,
          disabled,
          key: 'Effective:',
        },
        {
          label: '',
          value: expiration,
          setValue: setExpiration,
          disabled,
          key: 'Expiration:',
        },
      ].map((datePick) => (
        <Col xs="6" md="2" key={datePick.key}>
          <Form.Group>
            <Form.Label>{datePick.key}</Form.Label>
            <DatePickerNew
              value={datePick.value}
              setValue={datePick.setValue}
              disabled={datePick.disabled}
              extraStyles
            />
          </Form.Group>
        </Col>
      ))}
    </>
  );
}
