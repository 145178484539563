const dbName = "offlineImagesDB";
const dbVersion = 1;

let db;
const request = indexedDB.open(dbName, dbVersion);

request.onerror = function (event) {
  console.log("Database error: " + event.target.errorCode);
};

request.onsuccess = function (event) {
  db = event.target.result;
  // Code to retrieve and display images goes here
};

request.onupgradeneeded = function (event) {
  const db = event.target.result;
  const objectStore = db.createObjectStore("images", {
    keyPath: "id",
    autoIncrement: true,
  });
  // Additional configuration for object store goes here
};

// Function to store image in IndexedDB
function storeImage(imageBlob, key, additionalInfo) {
  const transaction = db.transaction(["images"], "readwrite");
  const objectStore = transaction.objectStore("images");

  const newImage = { image: imageBlob };
  const request = objectStore.add({ ...newImage, id: key, ...additionalInfo });

  request.onsuccess = function () {
    console.log("Image stored successfully");
  };

  request.onerror = function (event) {
    console.log("Error storing image: " + event.target.errorCode);
  };
}

function updateImage(imageBlob, key, additionalInfo) {
  const transaction = db.transaction(["images"], "readwrite");
  const objectStore = transaction.objectStore("images");

  const newImage = { image: imageBlob };
  const request = objectStore.put({ ...newImage, id: key, ...additionalInfo });

  request.onsuccess = function () {
    console.log("Image updated successfully");
  };

  request.onerror = function (event) {
    console.log("Error updating image: " + event.target.errorCode);
  };
}

// Function to retrieve and display image from IndexedDB
async function getImage(imageId) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["images"], "readonly");
    const objectStore = transaction.objectStore("images");
    const getRequest = objectStore.get(imageId);

    getRequest.onsuccess = function (event) {
      const image = event.target.result;
      if (image) {
        const imageBlob = image.image;
        resolve(imageBlob); // Resolve with the image Blob
      } else {
        reject("Image not found");
      }
    };

    getRequest.onerror = function (event) {
      reject("Error retrieving image: " + event.target.errorCode);
    };
  });
}

const deleteImage = (imageId) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["images"], "readwrite");
    const objectStore = transaction.objectStore("images");
    const getRequest = objectStore.delete(imageId);

    getRequest.onsuccess = function (event) {
      resolve("successfull");
    };

    getRequest.onerror = function (event) {
      reject("Error deleting image: " + event.target.errorCode);
    };
  });
};

const init = () => {
  return new Promise((resolve, reject) => {
    const dbName = "offlineImagesDB";
    const dbVersion = 1;

    let db;
    const request = indexedDB.open(dbName, dbVersion);

    request.onerror = function (event) {
      console.log("Database error: " + event.target.errorCode);
      reject(event.target.errorCode);
    };

    request.onsuccess = function (event) {
      db = event.target.result;
      resolve(db);
      // Code to retrieve and display images goes here
    };
  });
};

const getAllImages = () => {
  return new Promise(async (resolve, reject) => {
    let db = await init();
    const transaction = db.transaction(["images"], "readonly");
    const objectStore = transaction.objectStore("images");
    const getRequest = objectStore.getAll();

    getRequest.onsuccess = function (event) {
      const images = event.target.result;
      if (images) {
        resolve(images); // Resolve with the image Blob
      } else {
        reject("No Image found");
      }
    };

    getRequest.onerror = function (event) {
      reject("Error retrieving image: " + event.target.errorCode);
    };
  });
};

export { storeImage, getImage, deleteImage, getAllImages, updateImage };
