import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactSortable } from "react-sortablejs";
import LoaderModal from "./LoaderModal";
import PopupModal from "./popup-modal/popup-modal";
import ArrowPagination from "./ArrowPagination";
import LoadingLoader from "./LoadingLoader";
import PriceInput from "./PriceIput/PriceInput";

const Icon = ({ name, color, ...rest }) => (
  <FontAwesomeIcon {...rest} color={color} icon={name} />
);

function ModalTemplate({
  text,
  show,
  onHide,
  children,
  heading,
  textStyle = {},
  size = "lg",
  styleBody,
  fromProposal = false,
}) {
  return (
    <PopupModal
      styleBody={styleBody}
      show={show}
      heading={heading || ""}
      onHide={onHide}
      bold
      centered={false}
      size={size}
      styles={{ border: "none" }}
      fromProposal={fromProposal}
    >
      {text && <h5 style={{ fontWeight: "bold", ...textStyle }}>{text}</h5>}
      {children}
    </PopupModal>
  );
}

const AllComponents = {
  PopupModal,
  LoaderModal,
  LoadingLoader,
  ArrowPagination,
};

export {
  PriceInput,
  FontAwesomeIcon,
  Icon,
  ReactSortable,
  PopupModal,
  LoaderModal,
  LoadingLoader,
  ArrowPagination,
  ModalTemplate,
};

export default AllComponents;
