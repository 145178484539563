import { createSelector } from 'reselect';

const selectStaticData = ({staticAll}) => staticAll.data

export const newRepairs = createSelector(
    [selectStaticData],
    (data) => data ? data['repairType'] : null
)

export const taxRates = createSelector(
    [selectStaticData],
    (data) => data ? data['taxRate'] : null
)

export const salesStages = createSelector(
    [selectStaticData],
    (data) => data ? data['salesStage'] : null
)

export const salesTabs = createSelector(
    [salesStages],
    (tabs) => tabs ? tabs.filter(tab => tab.showInTable) : null
)

export const salesStagesChange = createSelector(
    [salesStages],
    (tabs) => tabs ? tabs.filter(tab => tab.showInChange) : null
)

export const repairsStages = createSelector(
    [selectStaticData],
    (data) => data ? data['repairStage'] : null
)

export const repairsTabs = createSelector(
    [repairsStages],
    (tabs) => tabs ? tabs.filter(tab => tab.showInTable) : null
)

export const repairsStagesChange = createSelector(
    [repairsStages],
    (tabs) => tabs ? tabs.filter(tab => tab.showInChange) : null
)