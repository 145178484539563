import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";

import moment from "moment";
import PickerDate from "react-datepicker";

import { Link } from "react-router-dom";
import CancelledModal from "./CancelledModal";
import { axiosInstance } from "services";
import { ApiEndpoints } from "lib/config/baseSettings";

export default function SalesCardExtended({
  id,
  name,
  fullAdd,
  tel,
  time,
  visit,
  created,
  project,
  sales,
  roof,
  gutters,
  changeStage,
  allowStageChange,
  isChanging,
  proposalSent,
  followUp,
  updatedVisible,
  updatedOn,
  updatedText,
  showMetric,
  displayExtraButton,
  handleDateChange,
  followUpDate,
  RedirectTo,
  data,
  handleOnUpdateOndata,
  secondCol,
  firstCol,
  addModal,
  setAddModal,
  cancelModalId,
  setCancelModalId,
  setActive,
  selectedStage,
  setHide,
  paymentStatus,
}) {

  console.log("SalesCardExtended");

  const [cancellationData, setCancellationData] = useState({
    type: "",
    detail: "",
  });

  const [prevCancelDetail, setPrevCancelDetail] = useState("");

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (updatedText !== "Canceled" || addModal) return;
    axiosInstance
      .get(ApiEndpoints.QUOTE.cancellationReason, {
        params: {
          requestId: id,
        },
      })
      .then((response) => {
        if ((response.status === 200) & (response.data.success === true)) {
          const cancData = response.data.result;
          setCancellationData({
            type: cancData.cancellationType,
            detail: cancData.cancellationReason,
          });
        } else {
          setCancellationData(null);
          console.error("get cancellation data failed");
        }
      })
      .catch((err) => {
        setCancellationData(null);
        console.error("get cancellation data failed", err);
      });
  }, [id, addModal]);

  const saveEditCancellation = (data) => {
    axiosInstance.patch(ApiEndpoints.QUOTE.cancellationReason, {
      params: {
        requestId: id,
      },
      body: {
        ...data,
      },
    });
  };

  return (
    <div className="_sales-card">
      <div className="p-details" style={{ maxWidth: "300px" }}>
        {data?.companyName && <div className="names"> {data?.companyName}</div>}
        <div className="names">
          {name.length > 0 &&
            name.map((data, i) => (
              <h5 key={i}>
                {data.name}
                {/* <span className="sub">({data.type})</span> */}
              </h5>
            ))}
        </div>
        {tel.length > 0 && (
          <div className="tels">
            {tel.map((data, i) => (
              <div key={i} className="tel">
                {data.phone}
                {/* <span className="sub">({data.type})</span> */}
              </div>
            ))}
          </div>
        )}
        <a
          // href=  `` "https://www.google.com/maps/place/"
          href={`https://www.google.com/maps/place?q=${fullAdd}`}
          className="address"
          style={{ textDecoration: "underline" }}
          target="_blank"
        >
          {fullAdd}
        </a>
      </div>
      <div className="o-details" style={{ margin: "0 1em" }}>
        <div className="bold">
          Visited: <span className="bold">{moment(visit).format("LL")}</span>
        </div>
        {proposalSent && (
          <div>
            <label>Proposal Sent:</label> <span>{proposalSent}</span>
          </div>
        )}
        {followUp && (
          <div>
            <Row>
              <Col md="4">
                <label className="_label">Follow Up:</label>
              </Col>
              <Col md="8">
                {/* {followUp === "--/--/----" && (
                  <>
                    &nbsp;&nbsp;
                    <FontAwesomeIcon
                      style={{ color: "#007bff" }}
                      icon={faCalendarAlt}
                    />
                  </>
                )} */}
                <PickerDate
                  value={followUp}
                  minDate={new Date()}
                  onChange={(date) => handleDateChange(id, date)}
                  className="_date_picker2"
                />
              </Col>
            </Row>
          </div>
        )}
        {updatedVisible && (
          <div>
            <Row>
              <Col md={firstCol}>
                <label>{updatedText} :</label>
              </Col>
              <Col md={secondCol}>
                <PickerDate
                  value={updatedOn}
                  minDate={new Date()}
                  onChange={(date) => handleOnUpdateOndata(id, date)}
                  className="_date_picker2"
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
          </div>
        )}

        <div>
          <label>Project:</label> <span>{project}</span>
        </div>
        {sales && (
          <div>
            <label>Sales:</label> <span>{sales}</span>
          </div>
        )}
        <div>
          <label>Created:</label> <span>{moment(created).format("LL")}</span>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <div className="_btns">
            <Row style={{ flexDirection: "column" }}>
              <Col style={{ marginBottom: "1em" }}>
                <Button
                  className="_btn_modified"
                  onClick={changeStage}
                  disabled={
                    updatedText === "Canceled"
                      ? true
                      : !allowStageChange ||
                        isChanging ||
                        paymentStatus == "Frozen 1"
                  }
                >
                  Change Stage
                </Button>
              </Col>
              <Col style={{ marginTop: "1em" }}>
                <Button className="_btn_modified">
                  <a
                    // href=  `` "https://www.google.com/maps/place/"
                    href={`https://www.google.com/maps/place?q=${fullAdd}`}
                    className="address"
                    // style={{ textDecoration: "underline" }}
                    target="_blank"
                  >
                    Directions
                  </a>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="_btns">
            {showMetric && (
              <Row style={{ flexDirection: "column" }}>
                <Col style={{ marginBottom: "1em" }}>
                  <Button
                    onClick={() => {
                      RedirectTo("roof", data);
                      localStorage.removeItem("BuildingName");
                    }}
                    className="_btn_modified"
                    disabled={!roof || paymentStatus == "Frozen 1"}
                  >
                    Roof Metric
                  </Button>
                </Col>
                <Col style={{ marginTop: "1em" }}>
                  <Button
                    onClick={() => {
                      RedirectTo("gutters", data);
                      localStorage.removeItem("BuildingName");
                    }}
                    className="_btn_modified"
                    disabled={!gutters || paymentStatus == "Frozen 1"}
                  >
                    Gutters Metric
                  </Button>
                </Col>
              </Row>
            )}
          </div>
          <div className="_btns">
            {displayExtraButton && (
              <Row style={{ flexDirection: "column" }}>
                <Col style={{ marginBottom: "1em" }}>
                  <Button
                    className="_btn_modified"
                    disabled={!roof || paymentStatus == "Frozen 1"}
                  >
                    <Link
                      //  to={`/dashboard/proposal-quotes/${id}`}
                      to={{
                        pathname: `/dashboard/proposal-quotes/${id}`,
                        state: { isSalesCard: true },
                      }}
                    >
                      Roof Proposals
                    </Link>
                  </Button>
                </Col>
                <Col style={{ marginTop: "1em" }}>
                  <Button
                    className="_btn_modified"
                    disabled={!gutters || paymentStatus == "Frozen 1"}
                  >
                    <Link to={`/dashboard/proposal-quotes/${id}`}>
                      Gutters Proposals
                    </Link>
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </div>
        {updatedText === "Canceled" && (
          <div
            style={{
              display: "flex",
              marginTop: "2rem",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <strong style={{ margin: 0 }}>Cancelation Type:</strong>
              <span>
                {cancellationData?.type[0]?.toUpperCase() +
                  cancellationData?.type?.slice(1)}
              </span>
            </div>
            <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <strong>Detailed Reason: </strong>
              <input
                type="text"
                disabled={!isEditing}
                value={cancellationData?.detail}
                onChange={(e) =>
                  setCancellationData((prev) => ({
                    ...prev,
                    detail: e.target.value,
                  }))
                }
              />
              {!isEditing ? (
                <Button
                  variant="primary"
                  onClick={() => {
                    // setIsEditing(true);
                    // setPrevCancelDetail(cancellationData.detail);
                    setAddModal(true);
                    setCancelModalId(id);
                  }}
                >
                  Edit
                </Button>
              ) : (
                <div style={{ display: "flex", gap: "1rem" }}>
                  <Button
                    onClick={() => {
                      saveEditCancellation({
                        cancellationReason: cancellationData?.detail,
                      });
                      setIsEditing(false);
                    }}
                    variant="success"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEditing(false);
                      setCancellationData((prev) => ({
                        ...prev,
                        detail: prevCancelDetail,
                      }));
                    }}
                    variant="warning"
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div>
        {addModal && cancelModalId === id && (
          <CancelledModal
            addModal={addModal}
            cancelModalId={cancelModalId}
            setAddModal={setAddModal}
            setCancelModalId={setCancelModalId}
            setActive={setActive}
            id={id}
            selectedStage={selectedStage}
            setHide={setHide}
            saveEditCancellation={saveEditCancellation}
            cancellationData={cancellationData}
          />
        )}
      </div>
    </div>
  );
}
