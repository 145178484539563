import axios from "axios";
import axiosInstance from "services/axios-instance";
import AxiosInstanceForm from "services/axios-instance-formdata";
import settings from "lib/config/baseSettings";

const axiosInstanceForm = AxiosInstanceForm.getInstance();
const axiosInstance1 = axiosInstance;
const axiosConfig = {
  headers: { "ngrok-skip-browser-warning": "true" },
  baseURL: settings.baseUrl,
  timeout: settings.axiosTimeout,
  timeoutErrorMessage: "Sorry! Unable to perform operation. Please try again.",
};

const axiosNoAuth = axios.create(axiosConfig);

axiosNoAuth.interceptors.request.use((config) => {
  /* some logic */
  const controller = new AbortController();

  if (!navigator.onLine) {
    console.log("aborting", config.url);
    controller.abort();
  }

  return {
    ...config,
    signal: controller.signal,
  };
});

export {
  axiosNoAuth as axios,
  axiosInstance1 as axiosInstance,
  axiosInstanceForm,
};
