import React from "react";
import "../metric.scss";

export default function MetricButton({
  label,
  array,
  data,
  prevMetric,
  icon,
  exec = false,
  onClickBtn,
  size,
  isQuoteLock = false,
}) {
  return data.map((item) => (
    <button
      disabled={prevMetric !== undefined ? !prevMetric : false}
      key={item.id}
      // style={typeof width !== "undefined" ? { width } : { width: "23%" }}
      // style={
      //   label === "Elevation Details"
      //     ? {}
      //     : item.name === "Galvanized"
      //     ? { width: "28%" }
      //     : { width: "24%" }
      // }
      style={
        isQuoteLock && array === item.id
          ? { width: size, backgroundColor: "#60abfb", borderColor: "#60abfb" }
          : { width: size }
      }
      className={`ClickableBtn ${array === item.id ? "selected" : ""} `}
      onClick={() => onClickBtn(label, item.id, exec)}
    >
      {item.name} {icon || ""}
    </button>
  ));
}
