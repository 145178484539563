"use strict";

const { CHECK_ROOFING, getInstallType } = require("../../../lib/constants/data");

const getHideProperties = (
  metaData,
  install_types,
  totalFlat,
  tab_area,
  designer_area,
  architechtural_area,
  single_ply_area,
  two_ply_area
) => {
  const hideData = {};
  hideData.hasShingles = !metaData.hasShingles;

  if (metaData.hasFlat) {
    hideData["Flat Roofing"] = false;
    if (getInstallType[install_types] === getInstallType["007"]) {
      hideData["twoPly"] = false;
      hideData["twoPlyArea"] = two_ply_area;
    }
    if (getInstallType[install_types] === getInstallType["006"]) {
      hideData["singlePly"] = false;
      hideData["singlePlyArea"] = single_ply_area;
    }
  }

  if (metaData.hasShingles) {
    hideData.Roofing = false;
    hideData.Underlayments = false;
    CHECK_ROOFING.forEach((roofingType) => {
      if (getInstallType[install_types] === roofingType) {
        hideData[roofingType] = false;
        roofingType === "3-tab"
          ? (hideData["3-tab-area"] = tab_area)
          : roofingType === "Architechtural"
          ? (hideData["ArchitechturalArea"] = architechtural_area)
          : (hideData["DesignerArea"] = designer_area);
      }
    });
  }

  return hideData;
};

export { getHideProperties };
