import React from "react";
import { Button, Modal } from "react-bootstrap";
import PopupModal from "./popup-modal/popup-modal";

export const DeleteModalFooter = ({
  onCancel,
  onSubmit,
  onCancelDisabled,
  onSubmitDisabled,
  btnText,
}) => (
  <Modal.Footer
    style={{
      justifyContent: "flex-end",
      border: "none",
    }}
  >
    <Button
      style={{ width: "130px", height: "50px" }}
      variant="danger modified-btn"
      onClick={onSubmit}
      disabled={onSubmitDisabled}
    >
      {btnText ? btnText : "Yes, Delete"}
    </Button>
    <Button
      style={{ width: "130px", height: "50px" }}
      onClick={onCancel}
      disabled={onCancelDisabled}
    >
      No
    </Button>
  </Modal.Footer>
);

export const DeleteModalLayout = ({
  displayText,
  onCancel,
  hideDeleteModal,
  deleteData,
  processing,
  btnText,
}) => (
  <>
    <h5 style={{ color: "red", fontWeight: "bold" }}>{displayText}</h5>
    <DeleteModalFooter
      onCancel={onCancel || hideDeleteModal}
      onSubmit={deleteData}
      onCancelDisabled={processing}
      onSubmitDisabled={processing}
      btnText={btnText}
    />
  </>
);

const DeleteModal = ({
  showDeleteModal,
  deleteData,
  hideDeleteModal,
  onCancel,
  processing,
  heading = "Delete Confirmation",
  dltSubHead,
  centered = false,
  btnText,
}) => (
  <PopupModal
    show={showDeleteModal}
    heading={heading}
    onHide={hideDeleteModal}
    bold
    centered={centered}
    styles={{ border: "none" }}
  >
    <DeleteModalLayout
      onCancel={onCancel}
      hideDeleteModal={hideDeleteModal}
      deleteData={deleteData}
      processing={processing}
      displayText={
        dltSubHead ||
        "This is a permanent delete, this data will be lost.Continue with delete?"
      }
      btnText={btnText}
    />
  </PopupModal>
);

export default DeleteModal;
