import React from "react";
import { Link, Route, useHistory, Switch } from "react-router-dom";
import Proposal from "./Proposal";
import DropdownMenus from "./DropdownMenus/DropdownMenus";

import Subscription from "./Subscription/Subscription";
import "./ApplicationSettings.scss";
import { Row, Col } from "react-bootstrap";
import UserSubscription from "./Subscription/UserSubscription";
import { useSelector } from "react-redux";
import userPermission from "hooks/usePermission";
import LetterHead from "./Leaderhead/Letterhead";

export default function ApplicationSettings() {
  const { user } = useSelector((state) => state.login);
  const { permission } = useSelector((state) => state.allPermissions);

  const links = [
    { name: "Subscription / Users", link: "/dashboard/application-settings" },
    {
      name: "Proposal Text & Templates",
      link: "/dashboard/application-settings/proposal",
    },
    {
      name: "Proposal & Contract Letterhead",
      link: "/dashboard/application-settings/letterHead",
    },
    {
      name: "Drop Down Menus",
      link: "/dashboard/application-settings/drop-down-menus",
    },
  ];
  const {
    location: { pathname },
  } = useHistory();

  const { hasAccessForProposal, hasAccesSubscription, hasAccessForDropDown } = userPermission(permission);

  return (
    <div className="wrapper">
      <h4 className="text-lg font-bold p-0">
        <span >Application Settings</span>
      </h4>
      <Row style={{ marginRight: 0, marginTop: "10px" }}>
        <Col style={{ paddingRight: 0 }}>
          <div className="_row">
            <div className="_menu_left">
              <ul>
                {links.map((link) =>
                  link.name == "Subscription / Users" ? (
                    <li key={link.name}>
                      <Link
                        className={pathname === link.link ? "active" : ""}
                        to={link.link}
                      >
                        <span>{link.name}</span>
                      </Link>
                    </li>
                  ) : link.name == "Proposal & Contract Letterhead" ? (
                    <li key={link.name}>
                      <Link
                        className={pathname === link.link ? "active" : ""}
                        to={link.link}
                        style={
                          !hasAccessForProposal
                            ? { pointerEvents: "none", color: "gray" }
                            : {}
                        }
                      >
                        <span>{link.name}</span>
                      </Link>
                    </li>
                  ) : link.name == "Proposal Text & Templates" ? (
                    !hasAccessForProposal ? (
                      <li key={link.name}>
                        <Link
                          className={pathname === link.link ? "active" : ""}
                          style={{ pointerEvents: "none", color: "gray" }}
                        >
                          <span>{link.name}</span>
                        </Link>
                      </li>
                    ) : (
                      <li key={link.name}>
                        <Link
                          className={pathname === link.link ? "active" : ""}
                          to={link.link}
                        >
                          <span>{link.name}</span>
                        </Link>
                      </li>
                    )
                  ) : !hasAccessForDropDown ? (
                    <li key={link.name}>
                      <Link
                        className={pathname === link.link ? "active" : ""}
                        // to={link.link}
                        style={{ pointerEvents: "none", color: "gray" }}
                      >
                        <span>{link.name}</span>
                      </Link>
                    </li>
                  ) : (
                    <li key={link.name}>
                      <Link
                        className={pathname === link.link ? "active" : ""}
                        to={link.link}
                      >
                        <span>{link.name}</span>
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="_menu_right">
              {user.userData.paymentStatus === "Frozen 2" ? (
                <Switch>
                  <Route exact path={links[0].link} component={Subscription} />
                </Switch>
              ) : hasAccesSubscription ? (
                <Switch>
                  <Route exact path={links[3].link} component={DropdownMenus} />
                  <Route exact path={links[1].link} component={Proposal} />
                  <Route exact path={links[0].link} component={Subscription} />
                  <Route exact path={links[2].link} component={LetterHead} />
                </Switch>
              ) : (
                <Switch>
                  <Route exact path={links[2].link} component={LetterHead} />
                  <Route exact path={links[3].link} component={DropdownMenus} />
                  <Route exact path={links[1].link} component={Proposal} />
                  <Route
                    exact
                    path={links[0].link}
                    component={UserSubscription}
                  />
                </Switch>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
