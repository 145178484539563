import React, {
  useState, useEffect, useCallback,
} from 'react';
import {
  checkQuoteProposalData,
} from 'lib/redux/quote-proposals/quotes-proposals.actions';

import { toast } from 'react-toastify';

export default function APIHook({ metricId }) {
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState(null);

  const checkData = useCallback((metricId) => {
    if (loading) return;
    setLoading(true);
    setErr(null);
    setResData([]);
    checkQuoteProposalData(metricId).then((res) => res.data).then((res) => {
      if (res.success) {
        onSuccess(res);
      } else {
        onFailure(res.error);
      }
    }).catch((e) => onFailure(e.message))
      .finally(onFinally);
  }, []);

  useEffect(() => {
    if (metricId) {
      checkData(metricId);
    }
  }, [metricId]);

  const onSuccess = (res) => {
    if (res.result && res.result.length > 0) {
      setResData(res.result);
    } else {
      setResData(res.success);
    }
  };

  const onFailure = (msg) => {
    toast.error(msg);
    setErr(msg);
  };
  const onFinally = () => {
    setLoading(false);
  };

  const onReload = () => {
    checkData(metricId);
  };

  return {
    loading,
    err,
    resData,
    onReload,
  };
}
