import React from "react";
import "react-datepicker/dist/react-datepicker.css";


import {
  CalendarPersons,
  SalesPersons,
  RepairPersons,
  CalendarRequests,
  CalendarRequestsRepair,
  CalendarRequestsSales,
  salesEvents,
  repairEvents,
  CalendarEvents,
} from "lib/redux/calendar/calendar.selector";
import { connect } from "react-redux";
import {
  deleteEvent,
  clearAvailablePersons,
  addToCalendar,
  fetchForCalendar,
  fetchAvailabelPersons,
  fetchEvents,
} from "lib/redux/calendar/calendar.actions";
import { memo } from "react";
import ProjectManagement from "./ProjectManagement";


const mapStateToProps = (state) => {
  const {
    calendarInputs: { isLoading, errors },
    availablePersons: { isLoadingAvPersons, personsData, personsErrors },
    scheduleCalendar: { isSubmitting, submitSuccess, submitFail },
    calendarEvents: { eventsError, isFetching },
    unscheduleEvent: { isunScheduling, unscheduleSuccess, unscheduleFail },
    login,
  } = state;
  
  return {
    isunScheduling,
    unscheduleSuccess,
    unscheduleFail,
    eventsError,
    isFetching,
    isSubmitting,
    submitSuccess,
    submitFail,
    isLoading,
    errors,
    isLoadingAvPersons,
    personsData,
    personsErrors,
    evnts: CalendarEvents(state),
    reqs: CalendarRequests(state),
    Persons: CalendarPersons(state),
    sales: CalendarRequestsSales(state),
    repairs: CalendarRequestsRepair(state),
    salesPersons: SalesPersons(state),
    repairPersons: RepairPersons(state),
    salesEvents: salesEvents(state),
    repairEvents: repairEvents(state),
    userRole: login.user ? login.user.userData.role : null,
    paymentStatus: login?.user?.userData?.paymentStatus
      ? login.user.userData.paymentStatus
      : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchForCalendar: () => dispatch(fetchForCalendar()),
  fetchAvailabelPersons: (data) => dispatch(fetchAvailabelPersons({ ...data })),
  addToCalendar: (data) => dispatch(addToCalendar({ ...data })),
  fetchEvents: () => dispatch(fetchEvents()),
  clearAvailablePersons: () => dispatch(clearAvailablePersons()),
  deleteEvent: (data) => dispatch(deleteEvent({ ...data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(ProjectManagement));