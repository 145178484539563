import React from "react";
import { Container } from "react-bootstrap";

const Welcome = () => (
  <Container className="p-3">
    <div className="mt-4 p-5 bg-primary text-white rounded">
      <h1 className="header">Welcome To Organised Roofer</h1>
    </div>
  </Container>
);

export default Welcome;
