import { calculateTotal } from "services/offlineComputing/lib/utils";
import cartingPricingCompany from "services/offlineComputing/models/cartingPricingCompany";

const {
  default: quoteDebrisModel,
} = require("services/offlineComputing/models/quoteDebris");
const {
  checkProposalData,
} = require("services/offlineComputing/services/generateProposal");
const { getData } = require("services/storageManager");

const get_metric_data = async (requestId, metricType) => {
  let prefix = "";
  if (metricType === "gutters") {
    prefix = "G";
  }
  const metric_data = await getData(`${prefix}metrics${requestId}`);
  // if (metric_data === null) {
  //   return null;
  // }
  return [metric_data.data];
};

const get_dumpsters_needed = async (total_size, companyId) => {
  let dumpster_required = await total_size.map(async (val) => {
    let dataArray = await cartingPricingCompany.find({
      size: val,
      companyId: companyId,
    });
    let data = dataArray[0];
    if (data) {
      return data;
    } else {
      return { msg: "No Data Found" };
    }
  });
  return Promise.all(dumpster_required);
};

const get_total_area = (metricDatas, excludedBuildings, excludedSections) => {
  let { metricData } = metricDatas;
  let total_area = 0;
  let total_flat_area = 0;

  let all_dumpster_required = [];
  metricData.forEach((element) => {
    let {
      removal_types,
      other_rems,
      install_types,
      totalFlat,
      work_type,
      sectionId,
    } = element;
    total_flat_area = total_flat_area + totalFlat;

    if (!excludedSections.includes(sectionId)) {
      if ((work_type === "001" || work_type === "002") && work_type !== "003") {
        all_dumpster_required.push("New/Overlay");
      } else {
        if (other_rems === "001") {
          all_dumpster_required.push("Remove");
          total_area = total_area + (totalFlat * 1) / 4;
        }
        if (other_rems === "002") {
          all_dumpster_required.push("Remove");
          total_area = total_area + (totalFlat * 2) / 4;
        }
        if (other_rems === "003") {
          all_dumpster_required.push("Remove");
          total_area = total_area + (totalFlat * 3) / 4;
        }
        // if (other_rems === "") {
        //   total_area = total_area + totalFlat * 1;
        // }
        if (removal_types === "003") {
          all_dumpster_required.push("Plywood Rip");
          return;
        }
        if (removal_types === "004") {
          all_dumpster_required.push("Cedar Rip");
          return;
        }
        if (removal_types === "005") {
          all_dumpster_required.push("Slate Rip");
          return;
        }
      }
    }
  });

  let dumpster_needed = 0; //(Math.ceil(total_area/500))*5
  if (total_area / 100 <= 5) {
    dumpster_needed = 5;
  }
  if (total_area / 100 > 5 && total_area / 100 <= 10) {
    dumpster_needed = 10;
  }
  if (total_area / 100 > 10 && total_area / 100 <= 15) {
    dumpster_needed = 15;
  }
  if (total_area / 100 > 15 && total_area / 100 <= 20) {
    dumpster_needed = 20;
  }
  if (total_area / 100 > 20) {
    dumpster_needed = 30;
  }

  if (
    all_dumpster_required.includes("Plywood Rip") === true ||
    all_dumpster_required.includes("Cedar Rip") === true ||
    all_dumpster_required.includes("Slate Rip") === true
  ) {
    if (total_flat_area < 1499) {
      all_dumpster_required = [20];
    } else {
      all_dumpster_required = [30];
    }
  }
  if (all_dumpster_required.every((n) => n === "New/Overlay")) {
    all_dumpster_required = [5];
  }
  if (all_dumpster_required.includes("Remove") === true) {
    all_dumpster_required = [dumpster_needed];
  }

  return all_dumpster_required;
};

export const debrisSection = async (props) => {
  const { supplierId, metricId, proposalId, companyId, _id } = props;

  const proposalData = await checkProposalData({
    _id: proposalId,
    requestId: metricId,
  });
  const { excludedBuildings, excludedSections } = proposalData;

  const responseDataDb = await quoteDebrisModel.find({
    requestId: metricId,
    supplierId,
    companyId,
    proposalId,
  });

  let metricType = "roof";
  let metric_data = await get_metric_data(metricId, metricType);

  let total_size = await get_total_area(
    metric_data[0],
    excludedBuildings,
    excludedSections
  );

  const dumpster_needed = await get_dumpsters_needed(total_size, companyId);

  let total_dumpsters = [];

  dumpster_needed.map((val) => {
    let obj = {
      pricingId: val._id,
      pricingType: val.pricingType,
      size: val.size,
      qty: "1",
      uomPrice: Number(val.uomPrice).toFixed(2),
      total: Number(1 * Number(val.uomPrice)).toFixed(2),
    };
    total_dumpsters.push(obj);
  });

  if (responseDataDb && responseDataDb.length > 0) {
    const { debrisRemovalTotal, sectionData, _id } = responseDataDb[0];
    return {
      sectionData,
      dataId: _id,
      debrisRemovalTotal: Number(debrisRemovalTotal).toFixed(2),
    };
  }

  let newData = {
    requestId: metricId,
    sectionData: total_dumpsters,
    supplierId,
    companyId,
    proposalId,
    userId: _id,
  };

  newData.debrisRemovalTotal = Number(total_dumpsters[0].total).toFixed(2);

  newData._id = await quoteDebrisModel.save(newData);

  return {
    sectionData: newData.sectionData,
    dataId: newData._id,
    debrisRemovalTotal: Number(newData.debrisRemovalTotal).toFixed(2),
  };
};

export const editDebrisSection = async (props) => {
  const { dataId, sectionData, companyId, _id } = props;

  let data = {
    sectionData,
  };

  data.debrisRemovalTotal = calculateTotal(sectionData);

  const resData = await quoteDebrisModel.updateOne(
    {
      _id: dataId,
      companyId,
      userId: _id,
    },
    data
  );

  return {
    ...data,
    dataId,
  };
};
