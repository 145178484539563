import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from 'lib/config/baseStyles';

export default function LoadingSkeleton() {
  return (
    <Row style={{ alignItems: 'center', margin: '1em 0' }}>
      <Col style={{ ...styles.newCol, ...styles.reduceWidthCol }}>
        <EmptySkeleton />
      </Col>

      <Col style={styles.reduceWidthCol} lg="2">
        <EmptySkeleton />
      </Col>
      <Col style={styles.reduceWidthCol} lg="4">
        <EmptySkeleton />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <EmptySkeleton />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <EmptySkeleton />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <EmptySkeleton />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <EmptySkeleton />
      </Col>

    </Row>
  );
}

function EmptySkeleton() {
  return <div style={{ width: '100%' }} />;
}
