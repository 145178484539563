import { useEffect } from "react";
import { useState } from "react";
// import { GET_ALLVIDEO } from "utils/utils";
import { axiosInstance as axios } from "services";
import { getEndPoints } from "api/api";
import { getAllScreen } from "api/api";
import { ApiEndpoints } from "lib/config/baseSettings";
const url = getEndPoints(process.env.REACT_APP_MODE);

const useHelp = () => {
  const [selectCategory, setSelectCategory] = useState("");
  const [category, setCategory] = useState("");
  const [addCategory, setAddCategory] = useState(false);
  const [allScreen, setAllScreen] = useState([]);
  const [uploadVideo, setUploadVideo] = useState("");
  const [allVideo, setAllVideo] = useState([]);
  const [description, setDesCription] = useState("");
  const [progressBar, setProgressBar] = useState();
  const [isDelete, setIsDelete] = useState(false);
  const [selectSetup, setSelectSetup] = useState("");

  const { GET_ALLVIDEO } = ApiEndpoints.SCHEDULE_MODAL;

  useEffect(() => {
    getAllScreen("/admin/permission/screen")
      .then((response) => {
        setAllScreen(response?.data?.result);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, []);

  useEffect(() => {
    if (selectCategory) {
      setAllVideo([]);
      setSelectSetup("");
      if (selectCategory !== "First Time Setup") {
        getAllVideo({ id: selectCategory });
      }
    }
  }, [selectCategory]);
  useEffect(() => {
    if (selectSetup) {
      getAllVideo({
        category: selectSetup,
      });
    }
  }, [selectSetup]);

  const getAllVideo = (body) => {
    return axios
      .post(`${url}${GET_ALLVIDEO}`, body)

      .then((response) => {
        setAllVideo(response?.data?.result);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };
  //   const UploadVideos = (subScreens) => {
  //     const bodyFormData = new FormData();
  //     bodyFormData.append("file", uploadVideo);
  //     bodyFormData.append("subScreens", subScreens);
  //     bodyFormData.append("descriptions", description);

  //     uploadedVideo(
  //       bodyFormData,
  //       getAllVideo,
  //       selectCategory,
  //       setProgressBar,
  //       setDesCription,
  //       setUploadVideo
  //     );
  //   };

  //   const addSubCategoeies = () => {
  //     const body = {
  //       screenId: selectCategory,
  //       subScreens: category,
  //     };
  //     addSubcategory(body, getAllVideo, selectCategory, setAddCategory);
  //   };

  //   const deleteIndivisualVideo = (id) => {
  //     deleteVideo(id, getAllVideo, selectCategory, setIsDelete);
  //   };
  const displaypTypeOptionsScreen = () => {
    const screenAllData = allScreen?.filter((elem) => {
      return elem.isDeleted !== true;
    });

    return screenAllData?.map((elem) => {
      return <option value={elem._id}>{elem.screenName}</option>;
    });
  };

  return {
    selectCategory,
    setSelectCategory,
    setCategory,
    category,
    addCategory,
    setAddCategory,
    displaypTypeOptionsScreen,
    setUploadVideo,
    uploadVideo,
    allVideo,
    description,
    setDesCription,
    progressBar,
    setIsDelete,
    isDelete,
    setSelectSetup,
    selectSetup,
  };
};
export default useHelp;
