export const NewLeadTypes = {
  NEW_LEAD_START: "NEW_LEAD_START",
  NEW_LEAD_STOP: "NEW_LEAD_STOP",
  SCHEDULE_LEAD_STOP: "SCHEDULE_LEAD_STOP",
  NEW_LEAD_SUCCESS: "NEW_LEAD_SUCCESS",
  NEW_LEAD_FAILURE: "NEW_LEAD_FAILURE",
  FETCH_LEADS_START: "FETCH_LEADS_START",
  FETCH_LEADS_SUCCESS: "FETCH_LEADS_SUCCESS",
  FETCH_LEADS_FAILURE: "FETCH_LEADS_FAILURE",
  DELETE_LEAD_START: "DELETE_LEAD_START",
  DELETE_LEAD_SUCCESS: "DELETE_LEAD_SUCCESS",
  DELETE_LEAD_FAILURE: "DELETE_LEAD_FAILURE",
  EDIT_LEAD_START: "EDIT_LEAD_START",
  EDIT_LEAD_SUCCESS: "EDIT_LEAD_SUCCESS",
  EDIT_LEAD_FAILURE: "EDIT_LEAD_FAILURE",
  SCHEDULE_LEAD_START: "SCHEDULE_LEAD_START",
  SCHEDULE_LEAD_SUCCESS: "SCHEDULE_LEAD_SUCCESS",
  SCHEDULE_LEAD_FAILURE: "SCHEDULE_LEAD_FAILURE",
  EMAIL_TYPE_START: "EMAIL_TYPE_START",
  EMAIL_TYPE_SUCCESS: "EMAIL_TYPE_SUCCESS",
  EMAIL_TYPE_FAILURE: "EMAIL_TYPE_FAILURE",
  PHONE_TYPE_START: "PHONE_TYPE_START",
  PHONE_TYPE_SUCCESS: "PHONE_TYPE_SUCCESS",
  PHONE_TYPE_FAILURE: "PHONE_TYPE_FAILURE",
  PROPERTY_TYPE_START: "PROPERTY_TYPE_START",
  PROPERTY_TYPE_SUCCESS: "PROPERTY_TYPE_SUCCESS",
  PROPERTY_TYPE_FAILURE: "PROPERTY_TYPE_FAILURE",
  RELATION_TYPE_START: "RELATION_TYPE_START",
  RELATION_TYPE_SUCCESS: "RELATION_TYPE_SUCCESS",
  RELATION_TYPE_FAILURE: "RELATION_TYPE_FAILURE",
  TASKS_RATE_START: "TASKS_RATE_START",
  TASKS_RATE_SUCCESS: "TASKS_RATE_SUCCESS",
  TASKS_RATE_FAILURE: "TASKS_RATE_FAILURE",
};
