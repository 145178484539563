import { axiosInstance, axiosInstanceForm } from "services";
import { getData, getEmailId, removeData, saveData } from "./storageManager";
import authService from "./auth-service";
import quoteModel from "services/offlineComputing/models/quote";
import quoteMaterialModel from "services/offlineComputing/models/quoteMaterial";
import quoteDebrisModel from "services/offlineComputing/models/quoteDebris";
import quoteGuttersModel from "services/offlineComputing/models/quoteGutters";
import quoteLaborModel from "services/offlineComputing/models/quoteLabor";
import projectsModel from "services/offlineComputing/models/projects";
import projectPaymentDetailsModel from "services/offlineComputing/models/projectPaymentDetails";
import projectsCrewsModel from "services/offlineComputing/models/projectsCrews";
import { useState } from "react";
import {
  getAllImages,
  updateImage,
} from "services/offlineComputing/models/offlineImageModel";
const IS_PUSHING = "IS_PUSHING";
const deleteAll = async () => {
  const query = { isOfflineCreated: true };
  const quoteData = await quoteModel.deleteMany(query);
  const quoteMaterial = await quoteMaterialModel.deleteMany(query);
  const quoteDebris = await quoteDebrisModel.deleteMany(query);
  const quoteGutters = await quoteGuttersModel.deleteMany(query);
  const quoteLabor = await quoteLaborModel.deleteMany(query);
  const projectsData = await projectsModel.deleteMany(query);
  const projectPaymentDetailsData = await projectPaymentDetailsModel.deleteMany(
    query
  );
  const projectsCrewData = await projectsCrewsModel.deleteMany(query);
  if (
    quoteData &&
    quoteDebris &&
    quoteMaterial &&
    quoteGutters &&
    quoteLabor &&
    projectsData &&
    projectPaymentDetailsData &&
    projectsCrewData
  ) {
    return true;
  }
  return false;
};
const pushQuoteGeneration = async () => {
  const query = { isOfflineChange: true };
  const quoteData = await quoteModel.find({ ...query });
  const quoteMaterial = await quoteMaterialModel.find({ ...query });
  const quoteDebris = await quoteDebrisModel.find({ ...query });
  const quoteGutters = await quoteGuttersModel.find({ ...query });
  const quoteLabor = await quoteLaborModel.find({ ...query });
  const projectsData = await projectsModel.find({ ...query });
  const projectPaymentDetailsData = await projectPaymentDetailsModel.find({
    ...query,
  });
  const projectsCrewData = await projectsCrewsModel.find({ ...query });

  const postData = {
    quoteData,
    quoteMaterial,
    quoteDebris,
    quoteGutters,
    quoteLabor,
    projectsData,
    projectPaymentDetailsData,
    projectsCrewData,
  };

  await axiosInstance
    .post("/offlineQuoteData/synchronize", postData)
    .then(async (res) => {
      if (res.status && res.data.success) {
        console.log("all quote offline deleted:", await deleteAll());
      } else {
        console.error("Sync failed");
      }
    })
    .catch((err) => {
      console.error(err);
    });
};
const pushImages = async () => {
  const mappingLocalToGlobalImage = {};

  const allImages = await getAllImages();
  
  const promises = allImages.map((data) => {
    const image = data.image;
    if (image) {
      if (data.isPushed) return;
      const form = new FormData();
      form.append("uploads", image);
      return axiosInstanceForm
        .post(`/files`, form)
        .then((response) => {
          if (response.data.status) {
            mappingLocalToGlobalImage[data.id] = response.data.data[0].uri;
            //deleteImage(data.id);
            updateImage(image, data.id, {
              isPushed: true,
              metricId: data.metricId,
            });
          } else {
            console.error("upload image key: ", image.offlineKey, "failed");
          }
        })
        .catch((err) => console.error(err));
    }
  });

  await Promise.all(promises);
  return mappingLocalToGlobalImage;
};
export const getIsPushing = () => {
  const result = getData(IS_PUSHING);
  if (result === null) {
    return false;
  }
  return result === "true";
};
const resolveOfflineImageURI = (changesMetric, mappingObj) => {
  const metricData = changesMetric.metricData;

  const newMetricData = metricData.map((data, i) => {
    const images = data.imagesList;
    const newImagesArray = images.map((image, i) => {
      if (!image) return undefined;
      if ("offlineKey" in image) {
        if (image.isPushed) return;
        if (image.offlineKey in mappingObj) {
          image.uri = mappingObj[image.offlineKey];
          image.thumb = `thumb/${mappingObj[image.offlineKey]}`;
          //delete image.offlineKey;
        }
      }
      return image;
    });
    return { ...data, imagesList: newImagesArray };
  });

  return { ...changesMetric, metricData: newMetricData };
};
const usePushData = () => {
  const [isPushing, setIsPushing] = useState(getIsPushing());

  const startPush = () => {
    saveData(IS_PUSHING, true);
    setIsPushing(true);
  };

  const endPush = () => {
    saveData(IS_PUSHING, false);
    setIsPushing(false);
  };
  const getAllMetrics = () => {
    const tabs = ["sales", "repair"];
    let metricsList = [];
    for (const tab of tabs) {
      const stageIds =
        tab === "sales"
          ? ["102", "103", "104", "105", "106", "107", "108"]
          : ["102", "103", "104", "105"];

      for (const id of stageIds) {
        const scheduleStage = getData(`past${tab}Stage${id}`);
        if (scheduleStage !== null) {
          metricsList = [...metricsList, ...scheduleStage.data.stageData.data];
        }
      }
      const scheduleStage = getData(`stage${tab}Stage102`);
      if (scheduleStage !== null) {
        metricsList = [...metricsList, ...scheduleStage.data.stageData.data];
      }

      const metricRequestIdList = metricsList.map((value) => {
        return value.requestId;
      });

      const scheduleStage103 = getData(`stage${tab}Stage103`);
      if (scheduleStage103 !== null && scheduleStage103.data) {
        const filterStageData = scheduleStage103.data.stageData.data.filter(
          (value) => {
            return !metricRequestIdList.includes(value.requestId);
          }
        );

        metricsList = [...metricsList, ...filterStageData];
      }
    }
    return metricsList;
  };
  const pushAllMetrics = (metricsList, mappingObj) => {
    let promises = [];

    const func = async (metric) => {
      if ("repair" in metric) {
        const changesMetricsString = getData(
          `changesRepair${metric.requestId}`
        );
        if (changesMetricsString !== null) {
          const changesMetric = changesMetricsString;
          const response = await axiosInstance.post(
            "/activity/updateRepairRequest",
            changesMetric
          );
          if (response.data.success === true && response.status === 200) {
            removeData(`changesRepair${metric.requestId}`);
          } else {
            console.error(response.data.error);
          }
        }
      }
      if (metric.hasRoof) {
        const changesMetricsString = getData(
          `changesMetric${metric.requestId}`
        );
        if (changesMetricsString !== null) {
          const changesMetric = resolveOfflineImageURI(
            changesMetricsString,
            mappingObj
          );

          axiosInstance
            .post("/metrics", changesMetric)
            .then((response) => {
              removeData(`changesMetric${metric.requestId}`);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
      if (metric.hasGutters) {
        const changesMetricsString = getData(
          `changesGMetric${metric.requestId}`
        );
        if (changesMetricsString !== null) {
          const changesMetric = resolveOfflineImageURI(
            changesMetricsString,
            mappingObj
          );

          axiosInstance
            .post("/metrics", changesMetric)
            .then((response) => {
              removeData(`changesGMetric${metric.requestId}`);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    };

    for (const metric of metricsList) {
      promises.push(func(metric));
    }

    return promises;
  };

  const changeStagePromise = (stageChangeList) => {
    let promises = [];
    const func = async (request) => {
      try {
        await axiosInstance.post(`/activity/changeStage`, request);
      } catch (error) {
        console.error(error);
        pushStageDone = false;
        endPush();
        return;
      }
    };
    for (const request of stageChangeList.data) {
      promises.push(func(request));
    }

    return promises;
  };

  const offlineDataPush = async () => {
    //PUSH metrics changes
    //check if it is online
    //check if there is a change entry for every lead
    //if yes PUSH IT!!!
    //if no then hehe
    try {
      //#region checking to run
      if (getIsPushing() === true) return;
      startPush();
      if (!navigator.onLine) {
        endPush();
        return;
      }

      const emailId = getEmailId();
      if (emailId === null || !authService.isAuthenticated()) {
        endPush();
        return;
      }
      //#endregion finish checking

      //push images. This must go before metrics push
      const mappingObj = await pushImages();

      //Get all metrics
      const metricsList = getAllMetrics();

      //push metrics
      const push_metrics = pushAllMetrics(metricsList, mappingObj);
      await Promise.all(push_metrics);

      //push stage changes

      //let pushStageDone = true;
      const stageChangeListString = getData("changeStageList");
      if (stageChangeListString !== null) {
        const changeStage = changeStagePromise(stageChangeListString);
        await Promise.all(changeStage)
          .then(() => {
            removeData("changeStageList");
          })
          .catch(() => {
            console.log("change stage failed");
          });


      }

      await pushQuoteGeneration();
      endPush();
    } catch (err) {
      console.error(err);
      endPush();
    }
  };
  return { isPushing, offlineDataPush };
};

export default usePushData;
