import { axios } from "services";
import { axiosInstance } from "services";

import { basePricingActionTypes } from "./base-pricing.types";
import { ApiEndpoints } from "lib/config/baseSettings";


/**
 * getAll subscription Information
 */

export const getAllSubscriptionInfoStart = () => ({
  type: basePricingActionTypes.SUBSCRIPTION_INFO_START,
});

export const getAllSubscriptionInfoSuccess = (data) => ({
  type: basePricingActionTypes.SUBSCRIPTION_INFO_SUCCESS,
  payload: data,
});

export const getAllSubscriptionInfoFailure = (errors) => ({
  type: basePricingActionTypes.SUBSCRIPTION_INFO_FAILURE,
  payload: errors,
});

export const getAllSubscriptionInfo = () => {
  return (dispatch) => {
    dispatch(getAllSubscriptionInfoStart());

    axiosInstance
      .get(ApiEndpoints.SUBSCRIPTION.GET_ALL_SUBSCRIPTION_INFO)
      .then((res) => res.data)
      .then((data) => dispatch(getAllSubscriptionInfoSuccess(data)))
      .catch((error) => dispatch(getAllSubscriptionInfoFailure(error)));
  };
};

/**
 *
 *
 */
// export const editSubscriptionInfo = (data) => {
//   return (dispatch) => {
//     dispatch({ type: basePricingActionTypes.EDIT_SUBSCRIPTION_INFO_START });

//     return axiosInstance
//       .patch(`/admin/updateBillingReport`, { ...data })
//       .then((res) => res.data)
//       .then((data) => {
//         if (data.success) {
//           return dispatch({
//             type: basePricingActionTypes.EDIT_SUBSCRIPTION_INFO_SUCCESS,
//             payload: data,
//           });
//         } else {
//           return dispatch({
//             type: basePricingActionTypes.EDIT_SUBSCRIPTION_INFO_FAILURE,
//             payload: data.error,
//           });
//         }
//       })
//       .catch((errors) =>
//         dispatch({
//           type: basePricingActionTypes.EDIT_SUBSCRIPTION_INFO_FAILURE,
//           payload: errors.message,
//         })
//       );
//   };
// };

export const editSubscriptionInfo = (data) => {
  const Url = "/admin/updateBillingReport";
  return axiosInstance.patch(ApiEndpoints.SUBSCRIPTION.EDIT_SUBSCRIPTION_INFO, {
    ...data,
  });
};
