import React, { useState, useEffect } from "react";
import "./Suppliers.scss";
import RequiredText from "components/required-text/required-text";
import validator from "validator";
import { checkPhone, convertDate } from "utils/utils";

import { uuid } from "uuidv4";
import Select from "react-select";
import Personnel from "components/Personnel/Personnel";
import CommonAddressFields from "components/CommonAddressFields/CommonAddressFields";
import PhoneDetails from "components/PhoneDetails/PhoneDetails";
import DatePickerNew from "components/DatePickerWithRemove";

import { Row, Col, Button, Form } from "react-bootstrap";

import {
  getSubcontractorSuppliers,
  addSubcontractorSuppliers,
  updateSubcontractorSuppliers,
  deleteSubcontractorSuppliers,
  getCrewSuppliers,
  toggleIsPreferred,
} from "lib/redux/suppliers/suppliers.action";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import DeleteModal from "../../components/DeleteModal";
import { TABS_SUPPLIERS } from "./const";

import InsuranceFields from "./InsuranceFields";
import userPersonnelData from "hooks/usePersonelData";

export default function Contractor({
  subcontractor,
  setSubcontractor,
  selectedSupp,
  setSelectedSupp,
  addNew,
  activeTab,
  permission,
}) {
  const [_id, setId] = useState(uuid());
  const [isActive, setIsActive] = useState(true);
  const [isPreferred, setIsPreferred] = useState(false);
  const [opened, setOpened] = useState("");
  const [closed, setClosed] = useState("");
  const [name, setName] = useState("");
  const [services, setServices] = useState([]);
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, changeState] = useState("");
  const [zip, setZip] = useState("");
  const [note, setNotes] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [insuranceCompany2, setInsuranceCompany2] = useState("");
  const [underwriter, setUnderwriter] = useState("");
  const [underwriter2, setUnderwriter2] = useState("");
  const [policy, setPolicy] = useState("");
  const [policy2, setPolicy2] = useState("");
  const [effective, setEffective] = useState("");
  const [effective2, setEffective2] = useState("");
  const [expiration, setExpiration] = useState("");
  const [expiration2, setExpiration2] = useState("");
  const [personnels, setPersonnels] = useState([]);
  const [POS, setPOS] = useState([]);
  const [SERVICES, setSERVICES] = useState([]);
  const [prevData, setPrevData] = useState({});

  const [processing, setProcessing] = useState(false);
  const [edit, setEdit] = useState(false);

  const dispatch = useDispatch();
  const { data, errors, isLoading } = useSelector((state) => state.staticAll);
  const [dltModal, setDltModal] = useState(false);

  const hideDeleteModal = () => setDltModal(false);
  const { supplierPersonnels = [] } = userPersonnelData();

  useEffect(() => {
    if (data) {
      setPOS(data.personnelSubsPos);
      setSERVICES(data.services);
    }
  }, [data, activeTab]);

  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);

  const getObjs = (servicesData) => {
    if (servicesData.length > 0 && data.services.length > 0) {
      const res = [];
      servicesData.map((item) =>
        res.push(data.services.find((service) => service.value === item))
      );
      return res;
    }
    return [];
  };

  const getValues = (dataList) => {
    if (dataList.length > 0) {
      const res = [];
      dataList.map((item) => res.push(item.value));
      return res;
    }
    return [];
  };

  const hasNotChangedFields = () =>
    JSON.stringify(prevData) ===
    JSON.stringify({
      _id,
      isActive,
      isPreferred,
      name,
      services: getValues(services),
      phone1: phone1.toString(),
      phone2: phone2 ? phone2.toString() : "",
      fax: fax ? fax.toString() : "",
      email,
      address1,
      address2: address2 || "",
      city,
      zip: `${zip}`,
      note,
      personnels,
      insuranceCompany,
      insuranceCompany2,
      underwriter,
      underwriter2,
      policy,
      policy2,
      effective: effective ? convertDate(effective) : "",
      effective2: effective2 ? convertDate(effective2) : "",
      expiration: expiration ? convertDate(expiration) : "",
      expiration2: expiration2 ? convertDate(expiration2) : "",
      opened: opened ? convertDate(opened) : "",
      closed: closed ? convertDate(closed) : "",
    });

  useEffect(() => {
    if (selectedSupp) {
      const data = subcontractor.find((supp) => supp._id === selectedSupp);
      if (data) {
        setId(data._id);
        setIsActive(data.isActive);
        setIsPreferred(data.isPreferred);
        setName(data.name);
        setServices(getObjs(data.services));
        setPhone1(data.phone1.toString());
        setPhone2(data.phone2 ? data.phone2.toString() : "");
        setFax(data.fax ? data.fax.toString() : "");
        setEmail(data.email);
        setAddress1(data.address1);
        setAddress2(data.address2 ? data.address2 : "");
        setCity(data.city);
        changeState(data.state);
        setZip(`${data.zip}`);
        setNotes(data.note);
        setPersonnels(data.personnels);
        setInsuranceCompany(data.insuranceCompany);
        setInsuranceCompany2(data.insuranceCompany2);
        setUnderwriter(data.underwriter);
        setUnderwriter2(data.underwriter2);
        setPolicy(data.policy);
        setPolicy2(data.policy2);
        setEffective(data.effective ? convertDate(data.effective) : "");
        setEffective2(data.effective2 ? convertDate(data.effective2) : "");
        setExpiration(data.expiration ? convertDate(data.expiration) : "");
        setExpiration2(data.expiration2 ? convertDate(data.expiration2) : "");
        setClosed(data.closed ? convertDate(data.closed) : "");
        setOpened(data.opened ? convertDate(data.opened) : "");

        setPrevData({
          _id: data._id,
          isActive: data.isActive,
          isPreferred: data.isPreferred,
          name: data.name,
          services: data.services,
          phone1: data.phone1.toString(),
          phone2: data.phone2 ? data.phone2.toString() : "",
          fax: data.fax ? data.fax.toString() : "",
          email: data.email,
          address1: data.address1,
          address2: data.address2 ? data.address2 : "",
          city: data.city,
          zip: `${data.zip}`,
          note: data.note,
          personnels: data.personnels,
          insuranceCompany: data.insuranceCompany,
          insuranceCompany2: data.insuranceCompany2,
          underwriter: data.underwriter,
          underwriter2: data.underwriter2,
          policy: data.policy,
          policy2: data.policy2,
          effective: data.effective ? convertDate(data.effective) : "",
          effective2: data.effective2 ? convertDate(data.effective2) : "",
          expiration: data.expiration ? convertDate(data.expiration) : "",
          expiration2: data.expiration2 ? convertDate(data.expiration2) : "",
          opened: data.opened ? convertDate(data.opened) : "",
          closed: data.closed ? convertDate(data.closed) : "",
        });
      }
    } else {
      resetFields();
    }
  }, [selectedSupp, subcontractor, activeTab]);

  const resetFields = () => {
    setId(uuid());
    setIsActive(true);
    setIsPreferred(false);
    setOpened("");
    setClosed("");
    setName("");
    setServices([]);
    setPhone1("");
    setPhone2("");
    setFax("");
    setEmail("");
    setAddress1("");
    setAddress2("");
    setCity("");
    changeState("");
    setZip("");
    setNotes("");
    setInsuranceCompany("");
    setInsuranceCompany2("");
    setUnderwriter("");
    setUnderwriter2("");
    setPolicy("");
    setPolicy2("");
    setEffective("");
    setEffective2("");
    setExpiration("");
    setExpiration2("");
    setPersonnels([]);
    setPrevData({});
  };

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const addSuppliers = () => {
    if (!selectedSupp) {
      setProcessing(true);
      return dispatch(
        addSubcontractorSuppliers({
          isActive,
          opened,
          closed,
          name,
          services: getValues(services),
          phone1,
          phone2,
          fax,
          email,
          address1,
          address2,
          city,
          state,
          zip,
          note,
          personnels,
          insuranceCompany,
          insuranceCompany2,
          underwriter,
          underwriter2,
          policy,
          policy2,
          effective,
          effective2,
          expiration,
          expiration2,
        })
      )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            const newData = res.result;
            dispatch(
              toggleIsPreferred(1, {
                supplierId: newData._id,
                isPreferred,
                isSubs: true,
              })
            )
              .then((resData) => resData.data)
              .then((resData) => {
                if (resData) {
                  setSubcontractor([
                    ...subcontractor,
                    { ...newData, isPreferred },
                  ]);
                  toast.success("Subcontractor added successfully.");
                  setSelectedSupp(newData._id);
                  dispatch(getSubcontractorSuppliers());
                } else {
                  toast.error(res.error);
                }
              })
              .catch((err) => {
                toast.error(err);
              })
              .finally(() => setProcessing(false));
          } else {
            toast.error(res.error);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => setProcessing(false));
    }
    if (selectedSupp === _id) {
      const idx = subcontractor.findIndex((supp) => supp._id === _id);
      setProcessing(true);
      return dispatch(
        updateSubcontractorSuppliers(_id, {
          isActive,
          opened,
          closed,
          name,
          services: getValues(services),
          phone1,
          phone2,
          fax,
          email,
          address1,
          address2,
          city,
          state,
          zip,
          note,
          personnels,
          insuranceCompany,
          insuranceCompany2,
          underwriter,
          underwriter2,
          policy,
          policy2,
          effective,
          effective2,
          expiration,
          expiration2,
        })
      )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            dispatch(
              toggleIsPreferred(1, {
                supplierId: _id,
                isPreferred,
                isSubs: true,
              })
            )
              .then((resData) => resData.data)
              .then((resData) => {
                if (resData) {
                  subcontractor.splice(idx, 1, {
                    _id,
                    isActive,
                    isPreferred,
                    opened,
                    closed,
                    name,
                    services: getValues(services),
                    phone1,
                    phone2,
                    fax,
                    email,
                    address1,
                    address2,
                    city,
                    state,
                    zip,
                    note,
                    personnels,
                    insuranceCompany,
                    insuranceCompany2,
                    underwriter,
                    underwriter2,
                    policy,
                    policy2,
                    effective,
                    effective2,
                    expiration,
                    expiration2,
                  });
                  setSubcontractor([...subcontractor]);
                  toast.success("Subcontractor updated successfully.");
                  dispatch(getSubcontractorSuppliers());
                  dispatch(getCrewSuppliers());
                } else {
                  toast.error(res.error);
                }
              })
              .catch((err) => {
                toast.error(err);
              })
              .finally(() => setProcessing(false));
          } else {
            toast.error(res.error);
          }
        })
        .catch((err) => {
          toast.error(err);
          setProcessing(false);
        })
        .finally(() => setProcessing(false));
    }
  };

  const handleMultiSelect = (value) => {
    if (!value) return setServices([]);
    setServices(value);
  };

  const dltData = () => {
    const idx = subcontractor.findIndex((supp) => supp._id === _id);
    setProcessing(true);
    dispatch(deleteSubcontractorSuppliers(_id))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          hideDeleteModal();
          subcontractor.splice(idx, 1);
          setSubcontractor([...subcontractor]);
          toast.success("Subcontractor deleted successfully.");
          addNew(TABS_SUPPLIERS[1].id);
        } else {
          toast.error(res.error);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setProcessing(false));
  };

  return (
    <div
      style={
        permission
          ? { padding: "1em" }
          : { padding: "1em", pointerEvents: "none" }
      }
    >
      <Row className="_form_top" style={{ alignItems: "center" }}>
        <Col lg="3" md="6">
          <Form.Group>
            <Form.Check
              disabled={!permission}
              name="isActive"
              checked={isActive}
              onChange={(event) => setIsActive(event.target.checked)}
              label="Active"
            />
          </Form.Group>
        </Col>

        {[
          {
            label: "Opened",
            value: opened,
            setValue: setOpened,
            disabled: !isActive,
            key: "_1",
          },
          {
            label: "Closed",
            value: closed,
            setValue: setClosed,
            disabled: isActive,
            key: "_2",
          },
        ].map((datePick) => (
          <Col lg="3" md="6" key={datePick.label}>
            <DatePickerNew
              label={datePick.label}
              value={datePick.value}
              setValue={datePick.setValue}
              disabled={datePick.disabled}
              key={datePick.key}
            />
          </Col>
        ))}

        <Col>
          <RequiredText>*</RequiredText> <span>Required Fields</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Check
              name="isPrefered"
              disabled={!isActive}
              checked={isPreferred}
              onChange={(event) => setIsPreferred(event.target.checked)}
              label="Preferred Subcontractor/Crew"
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Contractor Section */}
      <Row className="mt-5">
        <Col md="6" lg="4">
          <Form.Group>
            <Form.Label>
              Subcontractor Name: <RequiredText>*</RequiredText>
            </Form.Label>
            <Form.Control
              type="text"
              disabled={!isActive}
              name="name"
              value={name}
              onChange={(event) => handleChange(event, setName)}
            />
          </Form.Group>
        </Col>
        <Col md="6" lg="4">
          <Form.Group>
            <Form.Label>
              Services: <RequiredText>*</RequiredText>
            </Form.Label>

            <Select
              onChange={!isActive ? {} : handleMultiSelect}
              options={SERVICES}
              value={services}
              isMulti
              name="services"
              className={!isActive ? "_multiselect_disabled" : ""}
            />
          </Form.Group>
        </Col>
      </Row>

      {/* Address Section */}
      <Row className="mt-3">
        <CommonAddressFields
          address1Name="address1"
          address2Name="address2"
          cityName="city"
          zipName="zip"
          stateName="state"
          address1={address1}
          address2={address2}
          city={city}
          zip={zip}
          state={state}
          setAddress1={setAddress1}
          setAddress2={setAddress2}
          setCity={setCity}
          setZip={setZip}
          setState={changeState}
          handleChange={handleChange}
          required
          allowDisabling={!isActive}
        />
      </Row>

       {/* Phome Section */}
       <Row className="mt-5">
        <PhoneDetails
          phone1Name="phone1"
          phone2Name="phone2"
          faxName="fax"
          emailName="email"
          phone1={phone1}
          phone2={phone2}
          fax={fax}
          email={email}
          state={state}
          setPhone1={setPhone1}
          setPhone2={setPhone2}
          setEmail={setEmail}
          setFax={setFax}
          handleChange={handleChange}
          required
          disabled={!isActive}
        />
      </Row>

       {/* Insurance Section */}
       <Row className="mt-5">
        <InsuranceFields
          company={insuranceCompany}
          setCompany={setInsuranceCompany}
          underwriter={underwriter}
          setUnderwriter={setUnderwriter}
          policy={policy}
          setPolicy={setPolicy}
          effective={effective}
          setEffective={setEffective}
          expiration={expiration}
          setExpiration={setExpiration}
          handleChange={handleChange}
          disabled={!isActive}
        />
      </Row>
      <Row>
        <InsuranceFields
          company={insuranceCompany2}
          setCompany={setInsuranceCompany2}
          underwriter={underwriter2}
          setUnderwriter={setUnderwriter2}
          policy={policy2}
          setPolicy={setPolicy2}
          effective={effective2}
          setEffective={setEffective2}
          expiration={expiration2}
          setExpiration={setExpiration2}
          handleChange={handleChange}
          disabled={!isActive}
        />
      </Row>

      <Personnel
        personnel={personnels}
        setPersonnel={setPersonnels}
        posOptions={supplierPersonnels}
        disabled={!isActive || !permission}
      />


    {/* Note Section */}
      <Row className="mt-5">
        <Col sm="12" md="12">
          <Form.Group>
            <Form.Label>General Note About Supplier:</Form.Label>
            <Form.Control
              as="textarea"
              rows="1"
              name="note"
              value={note}
              disabled={!isActive}
              onChange={(event) => handleChange(event, setNotes)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row
        style={{
          //  justifyContent: "flex-end",
          margin: "1em 0",
          float: "right",
        }}
      >
        <div style={{ margin: "10px 1em", marginBottom: "2em" }}>
          <Button
            disabled={!isActive || hasNotChangedFields()}
            variant="danger"
            onClick={() => {
              resetFields();
            }}
            style={{ marginRight: "1em" }}
          >
            Cancel
          </Button>

          <Button
            disabled={
              !name ||
              services.length === 0 ||
              !phone1 ||
              !email ||
              !address1 ||
              !city ||
              !state ||
              !zip ||
              isNaN(zip) ||
              zip.length !== 5 ||
              !validator.isEmail(email) ||
              checkPhone(phone1) ||
              processing ||
              hasNotChangedFields()
            }
            onClick={() => addSuppliers()}
          >
            Save
          </Button>
        </div>
      </Row>
      <DeleteModal
        showDeleteModal={dltModal}
        deleteData={dltData}
        hideDeleteModal={hideDeleteModal}
        processing={processing}
      />
    </div>
  );
}
