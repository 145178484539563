import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { formatNumber, getFlatOrShingles } from "utils/utils";
import "./common.scss";
import { FcLock } from "react-icons/fc";
import { getOtherRemovalType, getRemovalType } from "lib/constants/const";

export default function BasicTable({
  tableData,
  editSection,
  sectionData,
  coopUnits,
  data,
  type,
  headerWrapperClasses = "_head_tableGutters",
  bodyClasses = "_body_tableGutters",
  isQuoteLock = false,
  removalTypes,
  otherRems,
}) {
  const buldingTotal = (list, isFlat) => {
    const filteredData = list.filter((listData) =>
      isFlat
        ? getFlatOrShingles(listData.install_types) === "Flat"
        : getFlatOrShingles(listData.install_types) !== "Flat"
    );
    return filteredData.reduce((acc, item) => (acc = acc + item.totalFlat), 0);
  };

  const gbuldingTotal = (list, selectTotal) => {
    return list.reduce(
      (acc, item) => (acc = Number(acc) + Number(item[selectTotal])),
      0
    );
  };

  const detailRemove = `${getRemovalType[removalTypes]}${
    otherRems ? ` - ${getOtherRemovalType[otherRems]}` : ""
  }`;

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <div>
        <div>
          <table className="table table-hover">
            <thead>
              {coopUnits.length > 0 &&
                (type === "roofing" ? (
                  <tr>
                    <th></th>
                    <th colSpan={9} style={{ fontSize: "1.2rem" }}>
                      All Buildings Total
                    </th>

                    <th
                      style={{
                        paddingRight: "calc(2px * 1.5vw",
                        width: editSection ? "100px" : "108px",

                        borderTop: "none",
                      }}
                    >
                      <span className="totalData">
                        {formatNumber(buldingTotal(sectionData, false)) !== "0"
                          ? `${formatNumber(
                              buldingTotal(sectionData, false)
                            )}(S)`
                          : ""}
                      </span>
                    </th>
                    <th
                      style={{
                        paddingRight: "calc(2px * 1.5vw",
                        width: editSection ? "100px" : "108px",

                        borderTop: "none",
                      }}
                    >
                      <span className="totalData">
                        {formatNumber(buldingTotal(sectionData, true)) !== "0"
                          ? `${formatNumber(
                              buldingTotal(sectionData, true)
                            )}(F)`
                          : ""}
                      </span>
                    </th>
                    {editSection && <th></th>}
                  </tr>
                ) : (
                  <tr>
                    <th></th>
                    <th colSpan={3} style={{ fontSize: "1.2rem" }}>
                      All Buildings Total
                    </th>
                    <th
                      style={{
                        paddingRight: "calc(2px * 1.5vw",
                        width: editSection ? "75px" : "175px",
                        textAlign: "center",
                      }}
                    >
                      <span className="totalData">
                        {formatNumber(
                          gbuldingTotal(sectionData, "guttersTotal")
                        ) !== "0"
                          ? `${formatNumber(
                              gbuldingTotal(sectionData, "guttersTotal")
                            )}(G)`
                          : ""}
                      </span>
                    </th>
                    <th
                      style={{
                        paddingRight: "calc(2px * 1.5vw",
                        width: editSection ? "100px" : "120px",
                        textAlign: "center",
                      }}
                    >
                      <span className="totalData">
                        {formatNumber(
                          gbuldingTotal(sectionData, "leadersTotal")
                        ) !== "0"
                          ? `${formatNumber(
                              gbuldingTotal(sectionData, "leadersTotal")
                            )}(L)`
                          : ""}
                      </span>
                    </th>

                    <th
                      style={{
                        paddingRight: "calc(2px * 1.5vw",
                        width: editSection ? "100px" : "118px",
                        textAlign: "center",
                      }}
                    >
                      <span className="totalData">
                        {formatNumber(
                          gbuldingTotal(sectionData, "screensTotal")
                        ) !== "0"
                          ? `${formatNumber(
                              gbuldingTotal(sectionData, "screensTotal")
                            )}(S)`
                          : ""}
                      </span>
                    </th>
                    <th
                      style={{
                        paddingRight: "calc(2px * 1.5vw",
                        width: editSection ? "100px" : "90px",
                        textAlign: "center",
                      }}
                    >
                      <span className="totalData">
                        {formatNumber(
                          gbuldingTotal(sectionData, "fasciaTotal")
                        ) !== "0"
                          ? `${formatNumber(
                              gbuldingTotal(sectionData, "fasciaTotal")
                            )}(F)`
                          : ""}
                      </span>
                    </th>
                    <th
                      style={{
                        paddingRight: "calc(2px * 1.5vw",
                        width: editSection ? "100px" : "90px",
                        textAlign: "center",
                      }}
                    >
                      <span className="totalData">
                        {formatNumber(
                          gbuldingTotal(sectionData, "cappingTotal")
                        ) !== "0"
                          ? `${formatNumber(
                              gbuldingTotal(sectionData, "cappingTotal")
                            )}(C)`
                          : ""}
                      </span>
                    </th>
                    <th
                      style={{
                        paddingRight: "calc(2px * 1.5vw",
                        width: editSection ? "100px" : "40px",
                        textAlign: "center",
                      }}
                    >
                      <span className="totalData">
                        {formatNumber(
                          gbuldingTotal(sectionData, "leaderheadsTotal")
                        ) !== "0"
                          ? `${formatNumber(
                              gbuldingTotal(sectionData, "leaderheadsTotal")
                            )}(H)`
                          : ""}
                      </span>
                    </th>
                    <th></th>
                  </tr>
                ))}
            </thead>
            <thead
              style={{
                background: "rgba(200, 200, 200, 0.301)",
                border: "1px solid #ccc",
              }}
            >
              {type === "roofing" ? (
                <tr>
                  <th></th>
                  <th colSpan={9} style={{ fontSize: "1.2rem" }}>
                    Current Building Total
                  </th>

                  <th
                    style={{
                      paddingRight: "calc(2px * 1.5vw",
                      width: editSection ? "100px" : "125px",
                      textAlign: "center",
                    }}
                  >
                    <span className="totalData">
                      {formatNumber(buldingTotal(tableData, false)) !== "0"
                        ? `${formatNumber(buldingTotal(tableData, false))}(S)`
                        : ""}
                    </span>
                  </th>
                  <th
                    style={{
                      paddingRight: "calc(2px * 1.5vw",
                      width: editSection ? "50px" : "125px",
                      textAlign: "center",
                    }}
                  >
                    <span className="totalData">
                      {formatNumber(buldingTotal(tableData, true)) !== "0"
                        ? `${formatNumber(buldingTotal(tableData, true))}(F)`
                        : ""}
                    </span>
                  </th>
                  {editSection === true && <th></th>}
                </tr>
              ) : (
                <tr>
                  <th></th>

                  <th colSpan={3} style={{ fontSize: "1.2rem" }}>
                    Current Building Total
                  </th>
                  <th
                    style={{
                      paddingRight: "calc(2px * 1.5vw",
                      width: editSection ? "75px" : "175px",
                      textAlign: "center",
                    }}
                  >
                    <span className="totalData">
                      {formatNumber(
                        gbuldingTotal(tableData, "guttersTotal")
                      ) !== "0"
                        ? `${formatNumber(
                            gbuldingTotal(tableData, "guttersTotal")
                          )}(G)`
                        : ""}
                    </span>
                  </th>
                  <th
                    style={{
                      paddingRight: "calc(2px * 1.5vw",
                      width: editSection ? "100px" : "120px",
                      textAlign: "center",
                    }}
                  >
                    <span className="totalData">
                      {formatNumber(
                        gbuldingTotal(tableData, "leadersTotal")
                      ) !== "0"
                        ? `${formatNumber(
                            gbuldingTotal(tableData, "leadersTotal")
                          )}(L)`
                        : ""}
                    </span>
                  </th>

                  <th
                    style={{
                      paddingRight: "calc(2px * 1.5vw",
                      width: editSection ? "100px" : "118px",
                      textAlign: "center",
                    }}
                  >
                    <span className="totalData">
                      {formatNumber(
                        gbuldingTotal(tableData, "screensTotal")
                      ) !== "0"
                        ? `${formatNumber(
                            gbuldingTotal(tableData, "screensTotal")
                          )}(S)`
                        : ""}
                    </span>
                  </th>
                  <th
                    style={{
                      paddingRight: "calc(2px * 1.5vw",
                      width: editSection ? "100px" : "90px",
                      textAlign: "center",
                    }}
                  >
                    <span className="totalData">
                      {formatNumber(gbuldingTotal(tableData, "fasciaTotal")) !==
                      "0"
                        ? `${formatNumber(
                            gbuldingTotal(tableData, "fasciaTotal")
                          )}(F)`
                        : ""}
                    </span>
                  </th>
                  <th
                    style={{
                      paddingRight: "calc(2px * 1.5vw",
                      width: editSection ? "100px" : "90px",
                      textAlign: "center",
                    }}
                  >
                    <span className="totalData">
                      {formatNumber(
                        gbuldingTotal(tableData, "cappingTotal")
                      ) !== "0"
                        ? `${formatNumber(
                            gbuldingTotal(tableData, "cappingTotal")
                          )}(C)`
                        : ""}
                    </span>
                  </th>
                  <th
                    style={{
                      paddingRight: "calc(2px * 1.5vw",
                      width: editSection ? "100px" : "40px",
                      textAlign: "center",
                    }}
                  >
                    <span className="totalData">
                      {formatNumber(
                        gbuldingTotal(tableData, "leaderheadsTotal")
                      ) !== "0"
                        ? `${formatNumber(
                            gbuldingTotal(tableData, "leaderheadsTotal")
                          )}(H)`
                        : ""}
                    </span>
                  </th>
                  {/* <th></th> */}
                  {editSection === true && <th></th>}
                </tr>
              )}
            </thead>
            <thead
            // style={{ borderBottom: "inset", borderWidth: "unset" }}
            >
              {type === "roofing" ? (
                <tr>
                  <th scope="col" style={{ width: "10%" }}></th>
                  <th scope="col" style={{ width: "7%", textAlign: "center" }}>
                    Sec
                  </th>
                  <th scope="col" style={{ width: "7%", textAlign: "center" }}>
                    Floor
                  </th>
                  <th scope="col" style={{ width: "7%", textAlign: "center" }}>
                    Elevation
                  </th>
                  <th scope="col" style={{ width: "8%", textAlign: "center" }}>
                    Pitch
                  </th>
                  <th scope="col" style={{ width: "9%", textAlign: "center" }}>
                    Work Type
                  </th>
                  <th scope="col" style={{ width: "9%", textAlign: "center" }}>
                    Install
                  </th>
                  <th scope="col" style={{ width: "6%", textAlign: "center" }}>
                    L
                  </th>
                  <th scope="col" style={{ width: "6%", textAlign: "center" }}>
                    W
                  </th>
                  <th scope="col" style={{ width: "6%", textAlign: "center" }}>
                    F
                  </th>
                  <th scope="col" style={{ width: "9%", textAlign: "center" }}>
                    Sq Ft(S)
                  </th>
                  <th scope="col" style={{ width: "9%", textAlign: "center" }}>
                    Sq Ft(F)
                  </th>

                  {editSection === true && (
                    <th scope="col" style={{ width: "8%" }}></th>
                  )}
                </tr>
              ) : (
                <tr>
                  <th scope="col" style={{ width: "9%" }}></th>
                  <th scope="col" style={{ width: "8%", textAlign: "center" }}>
                    Sec
                  </th>
                  <th scope="col" style={{ width: "8%", textAlign: "center" }}>
                    Level
                  </th>
                  <th scope="col" style={{ width: "9%", textAlign: "center" }}>
                    Elevation
                  </th>
                  <th scope="col" style={{ width: "8%", textAlign: "center" }}>
                    Gutter
                  </th>
                  <th scope="col" style={{ width: "8%", textAlign: "center" }}>
                    Leaders
                  </th>
                  <th scope="col" style={{ width: "8%", textAlign: "center" }}>
                    Screens
                  </th>
                  <th scope="col" style={{ width: "8%", textAlign: "center" }}>
                    Fascia
                  </th>
                  <th scope="col" style={{ width: "8%", textAlign: "center" }}>
                    Capping
                  </th>
                  <th scope="col" style={{ width: "8%", textAlign: "center" }}>
                    Heads
                  </th>
                  {editSection === true && (
                    <th
                      scope="col"
                      style={{ width: "8%", textAlign: "center" }}
                    ></th>
                  )}
                </tr>
              )}
            </thead>

            <tbody>
              {type === "roofing"
                ? data.map((elem, index) => {
                    return (
                      <tr>
                        <th scope="row">
                          <button
                            className="btn btn-primary"
                            onClick={elem.edit.props.onClick}
                            disabled={elem.edit.props.disabled}
                          >
                            {elem.edit.props.children[0]}

                            {elem.edit.props.children[1]}
                          </button>
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.sec}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.floor}
                        </th>
                        <th
                          scope="row"
                          style={{
                            fontWeight: "500",
                            whiteSpace: "pre-wrap",
                            // display: "flex",
                            textAlign: "center",
                          }}
                        >
                          {elem.elevation}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.pitch}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.workType === "Remove" ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p style={{ margin: 0 }}>
                                {getRemovalType[tableData[index].removal_types]}
                              </p>
                              <p style={{ margin: 0 }}>
                                {tableData[index].other_rems
                                  ? getOtherRemovalType[
                                      tableData[index].other_rems
                                    ]
                                  : ""}
                              </p>
                            </div>
                          ) : (
                            elem.workType
                          )}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.install}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.l}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.w}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.f}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.sq}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.flat}
                        </th>

                        {editSection === true && (
                          <th scope="row">
                            <button
                              className="tblDel btn btn-danger"
                              onClick={elem.delete.props.onClick}
                              disabled={elem.delete.props.disabled}
                              style={
                                isQuoteLock ? { pointerEvents: "none" } : {}
                              }
                            >
                              {isQuoteLock && <FcLock size={20} />}
                              {elem.delete.props.children}
                            </button>
                          </th>
                        )}
                      </tr>
                    );
                  })
                : data.map((elem) => {
                    return (
                      <tr>
                        <th scope="row">
                          <button
                            className="btn btn-primary"
                            onClick={elem.edit.props.onClick}
                            disabled={elem.edit.props.disabled}
                          >
                            {elem.edit.props.children[0]}

                            {elem.edit.props.children[1]}
                          </button>
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.sec}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.level}
                        </th>
                        <th
                          scope="row"
                          style={{
                            fontWeight: "500",
                            whiteSpace: "pre-wrap",
                            textAlign: "center",
                          }}
                        >
                          {elem.elevation}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.gutters}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.leaders}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.screens}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.fascia}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.cap}
                        </th>
                        <th
                          scope="row"
                          style={{ fontWeight: "500", textAlign: "center" }}
                        >
                          {elem.heads}
                        </th>

                        {editSection === true && (
                          <th scope="row">
                            <button
                              className="tblDel btn btn-danger"
                              onClick={elem.delete.props.onClick}
                              disabled={elem.delete.props.disabled}
                              style={
                                isQuoteLock ? { pointerEvents: "none" } : {}
                              }
                            >
                              {isQuoteLock && <FcLock size={20} />}
                              {elem.delete.props.children}
                            </button>
                          </th>
                        )}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
