import { getData } from "services/storageManager";
import { DropDownActionTypes } from "./drop-down.action.types";
import { axiosInstance } from "services";
import { ApiEndpoints } from "lib/config/baseSettings";

export const getDropDowns = () => {
  return (dispatch) => {
    dispatch({ type: DropDownActionTypes.GET_DROPDOWN_START });

    return axiosInstance
      .get(ApiEndpoints.DROP_DOWN.GET_DROPDOWN)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: DropDownActionTypes.GET_DROPDOWN_SUCCESS,
            payload: data.result,
          });
        } else {
          return dispatch({
            type: DropDownActionTypes.GET_DROPDOWN_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: DropDownActionTypes.GET_DROPDOWN_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const getDDData = (apiType) => {
  return (dispatch) => {
    dispatch({ type: DropDownActionTypes.GET_DDDATA_START });

    if (!navigator.onLine) {
      const storedData = getData(apiType);
      if (storedData === null) {
        return dispatch({
          type: DropDownActionTypes.GET_DDDATA_FAILURE,
          payload: `${apiType} data not catched`,
        });
      }
      return dispatch({
        type: DropDownActionTypes.GET_DDDATA_SUCCESS,
        payload: storedData,
      });
    }

    return axiosInstance
      .get(`${ApiEndpoints.DROP_DOWN.ADMIN}${apiType}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: DropDownActionTypes.GET_DDDATA_SUCCESS,
            payload: data.result,
          });
        } else {
          return dispatch({
            type: DropDownActionTypes.GET_DDDATA_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: DropDownActionTypes.GET_DDDATA_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const addDDData = (apiId, data) => {
  return (dispatch) => {
    return axiosInstance.post(`${ApiEndpoints.DROP_DOWN.ADMIN}${apiId}`, {
      ...data,
    });
  };
};

export const updateDDData = (apiId, id, data) => {
  return (dispatch) => {
    return axiosInstance.put(`${ApiEndpoints.DROP_DOWN.ADMIN}${apiId}/${id}`, {
      ...data,
    });
  };
};

export const deleteDDData = (apiId, id) => {
  return (dispatch) => {
    return axiosInstance.delete(
      `${ApiEndpoints.DROP_DOWN.ADMIN}${apiId}/${id}`
    );
  };
};

export const updateQuote = (data) => {
  return (dispatch) => {
    return axiosInstance.put(ApiEndpoints.DROP_DOWN.QUOTE_MARKUP, { ...data });
  };
};

export const getQuoteMarkup = (apiId) => {
  return (dispatch) => {
    if (!navigator.onLine) {
      return new Promise((resolve, reject) => {
        resolve({ data: { success: true, result: getData("quoteMarkup") } });
      });
    }
    return axiosInstance.get(ApiEndpoints.DROP_DOWN.QUOTE_MARKUP);
  };
};
