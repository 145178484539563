import {DropDownActionTypes} from './drop-down.action.types'

const INITIAL_STATE = {
	gettingDropDown: false,
	ddData: null,
	ddErr: null,
}

export const GetDropDownReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case DropDownActionTypes.GET_DROPDOWN_START:
			return {
				...state,
				gettingDropDown: true,
				ddData: null,
				ddErr: null,
			}

		case DropDownActionTypes.GET_DROPDOWN_SUCCESS:
			return {
				...state,
				gettingDropDown: false,
				ddData: action.payload,
				ddErr: null,
			}

		case DropDownActionTypes.GET_DROPDOWN_FAILURE:
			return {
				...state,
				gettingDropDown: false,
				ddData: null,
				ddErr: action.payload,
			}

		default:
			return state
	}
}

const INITIAL_STATE_DDDATA = {
	gettingDDData: false,
	dataList: null,
	listErr: null,
}

export const GetDDDataReducer = (state = INITIAL_STATE_DDDATA, action) => {
	switch (action.type) {
		case DropDownActionTypes.GET_DDDATA_START:
			return {
				...state,
				gettingDDData: true,
				dataList: null,
				listErr: null,
			}

		case DropDownActionTypes.GET_DDDATA_SUCCESS:
			return {
				...state,
				gettingDDData: false,
				dataList: action.payload,
				listErr: null,
			}

		case DropDownActionTypes.GET_DDDATA_FAILURE:
			return {
				...state,
				gettingDDData: false,
				dataList: null,
				listErr: action.payload,
			}

		default:
			return state
	}
}



