import { isSectionIncluded } from "./parseMetricData";
import {
  GUTTERSTYPES,
  GUTTERS_DATATYPE,
  G_GUTTERS,
  G_LEADERSCOLOR,
  G_LEADERSOTHER,
  G_LEADERS,
  G_SCREENSOTHER,
  G_SCREENS,
  G_FASCIA,
  G_FASCIADATA,
  G_CAPPING,
  G_LEADERHEADSOTHER,
} from "../../../lib/constants/data";

const getDataLabel = (data, id) => {
  if (data === undefined || !id) {
    return "N/A";
  }
  const match = data.find((item) => item.id === id);
  if (match) {
    return match.name;
  }
  return "N/A";
};

const getArray = (category, type, metricData) => {
  if (category === GUTTERSTYPES[0].categoryType) {
    if (type === GUTTERS_DATATYPE[0]) {
      return G_GUTTERS[3];
    } else if (type === GUTTERS_DATATYPE[1]) {
      return G_GUTTERS[2];
    } else if (type === GUTTERS_DATATYPE[2]) {
      return G_GUTTERS[1];
    } else if (type === GUTTERS_DATATYPE[3]) {
      return G_GUTTERS[0];
    }
  } else if (category === GUTTERSTYPES[1].categoryType) {
    if (type === GUTTERS_DATATYPE[0]) {
      return [...G_LEADERSCOLOR[metricData.leadersSelection.type]];
    } else if (type === GUTTERS_DATATYPE[1]) {
      return [
        ...G_LEADERSOTHER[metricData.leadersSelection.type][
          metricData.leadersSelection.style
        ][0],
      ];
    } else if (type === GUTTERS_DATATYPE[2]) {
      return G_LEADERS[1];
    } else if (type === GUTTERS_DATATYPE[3]) {
      return G_LEADERS[0];
    }
  } else if (category === GUTTERSTYPES[2].categoryType) {
    if (type === GUTTERS_DATATYPE[0]) {
      return G_SCREENSOTHER[metricData.screensSelection.type][1];
    } else if (type === GUTTERS_DATATYPE[1]) {
      return G_SCREENSOTHER[metricData.screensSelection.type][0];
    } else if (type === GUTTERS_DATATYPE[2]) {
      return undefined;
    } else if (type === GUTTERS_DATATYPE[3]) {
      return G_SCREENS;
    }
  } else if (category === GUTTERSTYPES[3].categoryType) {
    if (type === GUTTERS_DATATYPE[0]) {
      return G_LEADERHEADSOTHER[metricData.leaderheadsSelection.type][0];
    } else if (type === GUTTERS_DATATYPE[1]) {
      return undefined;
    } else if (type === GUTTERS_DATATYPE[2]) {
      return undefined;
    } else if (type === GUTTERS_DATATYPE[3]) {
      return G_SCREENS;
    }
  } else if (category === GUTTERSTYPES[4].categoryType) {
    if (type === GUTTERS_DATATYPE[0]) {
      return undefined;
    } else if (type === GUTTERS_DATATYPE[1]) {
      return G_FASCIA[metricData.fasciaSelection.type][0];
    } else if (type === GUTTERS_DATATYPE[2]) {
      return G_FASCIA[metricData.fasciaSelection.type][1];
    } else if (type === GUTTERS_DATATYPE[3]) {
      return G_FASCIADATA;
    }
  } else if (category === GUTTERSTYPES[5].categoryType) {
    if (type === GUTTERS_DATATYPE[0]) {
      return undefined;
    } else if (type === GUTTERS_DATATYPE[1]) {
      return undefined;
    } else if (type === GUTTERS_DATATYPE[2]) {
      return undefined;
    } else if (type === GUTTERS_DATATYPE[3]) {
      return G_CAPPING[0];
    }
  }
};
export const parseGuttersMetricData = (
  metric,
  excludedBuildings = [],
  excludedSections = []
) => {
  let totals = {};
  let parsedData = [];
  
  if (metric && metric.metricData.length > 0) {
    metric.metricData.forEach((metric) => {
      const { sectionId, building } = metric;
      const isIncluded = !isSectionIncluded({
        excludedBuildings,
        excludedSections,
        sectionId,
        building,
      });

      if (isIncluded) {
        GUTTERSTYPES.map((typ) => {
          if (JSON.stringify(metric[typ.selection]) !== "{}") {
            const key = `${metric[typ.selection].color}${
              metric[typ.selection].size ? metric[typ.selection].size : ""
            }${metric[typ.selection].style ? metric[typ.selection].style : ""}${
              metric[typ.selection].type
            }`;
            let total = metric[typ.total] ? metric[typ.total] : 0;

            if (totals[key]) {
              totals[key].total = totals[key].total
                ? totals[key].total + total
                : total;
            } else {
              const NO_DATA = "N/A";

              totals[key] = {
                total,
                category: typ.category,
                categoryType: typ.categoryType,

                color: metric[typ.selection].color
                  ? getDataLabel(
                      getArray(typ.categoryType, GUTTERS_DATATYPE[0], metric),
                      metric[typ.selection].color
                    )
                  : metric[typ.selection].length
                  ? getDataLabel(
                      getArray(typ.categoryType, GUTTERS_DATATYPE[2], metric),
                      metric[typ.selection].length
                    )
                  : NO_DATA,
                size: metric[typ.selection].size
                  ? getDataLabel(
                      getArray(typ.categoryType, GUTTERS_DATATYPE[1], metric),
                      metric[typ.selection].size
                    )
                  : NO_DATA,
                style: metric[typ.selection].style
                  ? getDataLabel(
                      getArray(typ.categoryType, GUTTERS_DATATYPE[2], metric),
                      metric[typ.selection].style
                    )
                  : NO_DATA,
                subType: metric[typ.selection].type
                  ? getDataLabel(
                      getArray(typ.categoryType, GUTTERS_DATATYPE[3], metric),
                      metric[typ.selection].type
                    )
                  : NO_DATA,
              };
            }
          }
        });
      }
    });
  }

  Object.keys(totals).map((key, id) => {
    const { total, ...rest } = totals[key];
    return parsedData.push({ _id: id, qty: total, ...rest });
  });

  return parsedData;
};
