import { NewLeadTypes } from "./new-lead.types";

const INITIAL_STATE = {
  isLoading: false,
  success: null,
  errors: null,
};

export function NewLeadReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NewLeadTypes.NEW_LEAD_START:
      return {
        ...state,
        isLoading: true,
        success: null,
        errors: null,
      };

    case NewLeadTypes.NEW_LEAD_SUCCESS:
      return {
        ...state,
        success: action.payload,
        isLoading: false,
        errors: null,
      };

    case NewLeadTypes.NEW_LEAD_FAILURE:
      return {
        ...state,
        success: null,
        isLoading: false,
        errors: action.payload,
      };
    case NewLeadTypes.NEW_LEAD_STOP:
      return {
        ...state,
        success: null,
        isLoading: false,
        errors: null,
      };
    default:
      return state;
  }
}

const INITIAL_STATE_FETCH_LEADS = {
  isLoadingFetchLeads: false,
  data: null,
  errors: null,
};

export function FetchLeadsReducer(state = INITIAL_STATE_FETCH_LEADS, action) {
  switch (action.type) {
    case NewLeadTypes.FETCH_LEADS_START:
      return {
        ...state,
        isLoadingFetchLeads: true,
        data: null,
        errors: null,
      };

    case NewLeadTypes.FETCH_LEADS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoadingFetchLeads: false,
        errors: null,
        
      };

    case NewLeadTypes.FETCH_LEADS_FAILURE:
      return {
        ...state,
        data: null,
        isLoadingFetchLeads: false,
        errors: action.payload,
      };

    default:
      return state;
  }
}

const INITIAL_STATE_DELETE = {
  isLoadingDelete: false,
  deleteSuccess: null,
  deleteErrors: null,
};

export function DeleteLeadReducer(state = INITIAL_STATE_DELETE, action) {
  switch (action.type) {
    case NewLeadTypes.DELETE_LEAD_START:
      return {
        ...state,
        isLoadingDelete: true,
        deleteSuccess: null,
        deleteErrors: null,
      };

    case NewLeadTypes.DELETE_LEAD_SUCCESS:
      return {
        ...state,
        deleteSuccess: action.payload,
        isLoadingDelete: false,
        deleteErrors: null,
      };

    case NewLeadTypes.DELETE_LEAD_FAILURE:
      return {
        ...state,
        deleteSuccess: null,
        isLoadingDelete: false,
        deleteErrors: action.payload,
      };

    default:
      return state;
  }
}

const INITIAL_STATE_EDIT = {
  isLoadingEdit: false,
  successEdit: null,
  errorsEdit: null,
};

export function EditLeadReducer(state = INITIAL_STATE_EDIT, action) {
  switch (action.type) {
    case NewLeadTypes.EDIT_LEAD_START:
      return {
        ...state,
        isLoadingEdit: true,
        successEdit: null,
        errorsEdit: null,
      };

    case NewLeadTypes.EDIT_LEAD_SUCCESS:
      return {
        ...state,
        successEdit: action.payload,
        isLoadingEdit: false,
        errorsEdit: null,
      };

    case NewLeadTypes.EDIT_LEAD_FAILURE:
      return {
        ...state,
        successEdit: null,
        isLoadingEdit: false,
        errorsEdit: action.payload,
      };

    default:
      return state;
  }
}

const INITIAL_STATE_SCHEDULE = {
  schedulling: false,
  successSchedule: false,
  errorsSchedule: null,
};

export function ScheduleLeadReducer(state = INITIAL_STATE_SCHEDULE, action) {
  switch (action.type) {
    case NewLeadTypes.SCHEDULE_LEAD_START:
      return {
        ...state,
        schedulling: true,
        successSchedule: null,
        errorsSchedule: null,
      };

    case NewLeadTypes.SCHEDULE_LEAD_SUCCESS:
      return {
        ...state,
        successSchedule: true,
        schedulling: false,
        errorsSchedule: null,
      };

    case NewLeadTypes.SCHEDULE_LEAD_FAILURE:
      return {
        ...state,
        successSchedule: false,
        schedulling: false,
        errorsSchedule: action.payload,
      };
    case NewLeadTypes.SCHEDULE_LEAD_STOP:
      return {
        ...state,
        successSchedule: false,
        schedulling: false,
        errorsSchedule: null,
      };
    default:
      return state;
  }
}

/**
 * New api email type phone type etc.
 */

const INITIAL_STATE_EMAIL_TYPE = {
  isLoadingEmailtype: false,
  emaildata: null,
  emailerrors: null,
};

export function EmailTypesReducer(state = INITIAL_STATE_EMAIL_TYPE, action) {
  switch (action.type) {
    case NewLeadTypes.EMAIL_TYPE_START:
      return {
        ...state,
        isLoadingEmailtype: true,
        emaildata: null,
        emailerrors: null,
      };

    case NewLeadTypes.EMAIL_TYPE_SUCCESS:
      return {
        ...state,
        emaildata: action.payload,
        isLoadingEmailtype: false,
        emailerrors: null,
      };

    case NewLeadTypes.EMAIL_TYPE_FAILURE:
      return {
        ...state,
        emaildata: null,
        isLoadingEmailtype: false,
        emailerrors: action.payload,
      };

    default:
      return state;
  }
}

const INITIAL_STATE_PHONE_TYPE = {
  isLoadingPhonetype: false,
  phonedata: null,
  phoneerrors: null,
};

export function PhoneTypesReducer(state = INITIAL_STATE_PHONE_TYPE, action) {
  switch (action.type) {
    case NewLeadTypes.PHONE_TYPE_START:
      return {
        ...state,
        isLoadingPhonetype: true,
        phonedata: null,
        phoneerrors: null,
      };

    case NewLeadTypes.PHONE_TYPE_SUCCESS:
      return {
        ...state,
        phonedata: action.payload,
        isLoadingPhonetype: false,
        phoneerrors: null,
      };

    case NewLeadTypes.PHONE_TYPE_FAILURE:
      return {
        ...state,
        phonedata: null,
        isLoadingPhonetype: false,
        phoneerrors: action.payload,
      };

    default:
      return state;
  }
}

const INITIAL_STATE_PROPERTY_TYPE = {
  isLoadingPropertytype: false,
  propertydata: null,
  propertyerrors: null,
};

export function PropertyTypesReducer(
  state = INITIAL_STATE_PROPERTY_TYPE,
  action
) {
  switch (action.type) {
    case NewLeadTypes.PROPERTY_TYPE_START:
      return {
        ...state,
        isLoadingPropertytype: true,
        propertydata: null,
        propertyerrors: null,
      };

    case NewLeadTypes.PROPERTY_TYPE_SUCCESS:
      return {
        ...state,
        propertydata: action.payload,
        isLoadingPropertytype: false,
        propertyerrors: null,
      };

    case NewLeadTypes.PROPERTY_TYPE_FAILURE:
      return {
        ...state,
        propertydata: null,
        isLoadingPropertytype: false,
        propertyerrors: action.payload,
      };

    default:
      return state;
  }
}

const INITIAL_STATE_RELATION_TYPE = {
  isLoadingRelationtype: false,
  propertydata: null,
  propertyerrors: null,
};

export function RelationTypesReducer(
  state = INITIAL_STATE_RELATION_TYPE,
  action
) {
  switch (action.type) {
    case NewLeadTypes.RELATION_TYPE_START:
      return {
        ...state,
        isLoadingRelationtype: true,
        relationdata: null,
        relationerrors: null,
      };

    case NewLeadTypes.RELATION_TYPE_SUCCESS:
      return {
        ...state,
        relationdata: action.payload,
        isLoadingRelationtype: false,
        relationerrors: null,
      };

    case NewLeadTypes.RELATION_TYPE_FAILURE:
      return {
        ...state,
        relationdata: null,
        isLoadingRelationtype: false,
        relationerrors: action.payload,
      };

    default:
      return state;
  }
}

const INITIAL_STATE_TASK_RATE = {
  isLoadingTasksRate: false,
  taskratedata: null,
  tasksrateerror: null,
};

export function TasksRateReducer(state = INITIAL_STATE_TASK_RATE, action) {
  switch (action.type) {
    case NewLeadTypes.TASKS_RATE_START:
      return {
        ...state,
        isLoadingTasksRate: true,
        taskratedata: null,
        tasksrateerror: null,
      };

    case NewLeadTypes.TASKS_RATE_SUCCESS:
      return {
        ...state,
        taskratedata: action.payload,
        isLoadingTasksRate: false,
        tasksrateerror: null,
      };

    case NewLeadTypes.TASKS_RATE_FAILURE:
      return {
        ...state,
        taskratedata: null,
        isLoadingTasksRate: false,
        tasksrateerror: action.payload,
      };

    default:
      return state;
  }
}

export function EditLeadDataReducer(state ={}, action) {
  if (action.type === "EDITLEADDATA") {
    return action.payload;
  } else return state;
}
