import axios from 'axios';

import AuthService from './auth-service';
import settings from 'lib/config/baseSettings';

class AxiosServiceForm {
	axiosInstance = {};

	constructor() {
		this.initInstance();
	}

	initInstance() {
		this.axiosInstance = axios.create({
			baseURL: settings.baseUrl,
		});

		this.axiosInstance.interceptors.request.use((config) => {
			const token = AuthService.getToken();

			if (token) {
				config.headers['x-access-token'] = `${token}`;
			}
			config.headers['Content-Type'] = 'multipart/form-data';

			return config;
		});
		return this.axiosInstance;
	}

	getInstance() {
		return this.axiosInstance || this.initInstance();
	}
}

export default new AxiosServiceForm();
