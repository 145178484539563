/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import "./Suppliers.scss";
import RequiredText from "components/required-text/required-text";
import validator from "validator";
import { checkPhone } from "utils/utils";

import { uuid } from "uuidv4";
import Personnel from "components/Personnel/Personnel";
import CommonAddressFields from "components/CommonAddressFields/CommonAddressFields";
import PhoneDetails from "components/PhoneDetails/PhoneDetails";

import { Row, Col, Button, Form } from "react-bootstrap";

import {
  getMSuppliers,
  addMSuppliers,
  updateMSuppliers,
  deleteMSuppliers,
  toggleIsPreferred,
} from "lib/redux/suppliers/suppliers.action";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { TABS_SUPPLIERS } from "./const";
import DeleteModal from "../../components/DeleteModal";
import userPersonnelData from "hooks/usePersonelData";

export default function BranchSuppliers({
  mateSuppliers,
  setMateSuppliers,
  isPrimary,
  selectedSupp,
  setSelectedSupp,
  nameV,
  addNew,
  permission,
}) {
  const [_id, setId] = useState(uuid());
  const [isActive, setIsActive] = useState(true);
  const [isPreferred, setIsPreferred] = useState(false);
  const [name, setName] = useState(nameV || "");
  const [locOrBranch, setLocOrBranch] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, changeState] = useState("");
  const [zip, setZip] = useState("");
  const [note, setNote] = useState("");
  const [personnels, setPersonnels] = useState([]);
  const [primarySupplier, setPrimarySupplier] = useState("");
  const [processing, setProcessing] = useState(false);
  const [dltModal, setDltModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [primarySuppActive, setPrimarySuppActive] = useState(true);
  const [prevData, setPrevData] = useState({});

  const hideDeleteModal = () => setDltModal(false);

  const [POS, setPOS] = useState([]);

  const dispatch = useDispatch();
  const { materialPersonnels = [] } = userPersonnelData();

  const { data, errors, isLoading } = useSelector((state) => state.staticAll);
  useEffect(() => {
    if (data) {
      setPOS(data.personnelPos);
    }
  }, [data]);

  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);

  useEffect(() => {
    if (selectedSupp) {
      const data = mateSuppliers.find(
        (supp) => supp._id === selectedSupp && supp.isPrimary
      );

      if (data) {
        setName(data.name);
        setPrimarySupplier(data._id);
      } else {
        const match = mateSuppliers.find(
          (supp) => supp._id === selectedSupp && !supp.isPrimary
        );

        if (match) {
          setId(match._id);
          setIsActive(match.isActive);
          setIsPreferred(match.isPreferred);
          setName(match.name);
          setLocOrBranch(match.locOrBranch);
          setPhone1(match.phone1.toString());
          setPhone2(match.phone2 ? match.phone2.toString() : "");
          setFax(match.fax ? match.fax.toString() : "");
          setEmail(match.email);
          setAddress1(match.address1);
          setAddress2(match.address2);
          setCity(match.city);
          changeState(match.state);
          setZip(`${match.zip}`);
          setNote(match.note);
          setPersonnels(match.personnels);
          setPrimarySupplier(match.primarySupplier);
          setEdit(true);
          const data = mateSuppliers.find(
            (supp) => supp._id === match.primarySupplier && supp.isPrimary
          );
          if (data) {
            setPrimarySuppActive(!data.isActive);
          }
          setPrevData({
            _id: match._id,
            isActive: match.isActive,
            isPreferred: match.isPreferred,
            name: match.name,
            locOrBranch: match.locOrBranch,
            phone1: match.phone1.toString(),
            phone2: match.phone2 ? match.phone2.toString() : "",
            fax: match.fax ? match.fax.toString() : "",
            email: match.email,
            address1: match.address1,
            address2: match.address2,
            city: match.city,
            state: match.state,
            zip: `${match.zip}`,
            note: match.note,
            personnels: match.personnels,
            primarySupplier: match.primarySupplier,
          });
        }
      }
    } else {
      resetFields();
    }
  }, [selectedSupp, mateSuppliers]);

  const resetFields = () => {
    setId(uuid());
    setIsActive(true);
    setIsPreferred(false);
    setName("");
    setLocOrBranch("");
    setPhone1("");
    setPhone2("");
    setFax("");
    setEmail("");
    setAddress1("");
    setAddress2("");
    setCity("");
    changeState("");
    setZip("");
    setNote("");
    setPersonnels([]);
    setPrimarySupplier("");
    setPrevData({});
  };

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const hasNotChangedFields = () => {
    const updatedDate = {
      _id,
      isActive,
      isPreferred,
      name,
      locOrBranch,
      phone1: phone1.toString(),
      phone2: phone2 ? phone2.toString() : "",
      fax: fax ? fax.toString() : "",
      email,
      address1,
      address2,
      city,
      state,
      zip: `${zip}`,
      note,
      personnels,
      primarySupplier,
    };
    return JSON.stringify(prevData) === JSON.stringify(updatedDate);
  };

  const addSuppliers = () => {
    if (selectedSupp === _id) {
      const idx = mateSuppliers.findIndex((supp) => supp._id === _id);
      setProcessing(true);
      dispatch(
        updateMSuppliers(_id, {
          isActive,
          name,
          locOrBranch,
          phone1,
          phone2,
          fax,
          email,
          address1,
          address2,
          city,
          state,
          zip,
          note,
          personnels,
          isPrimary,
          primarySupplier,
        })
      )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            dispatch(
              toggleIsPreferred(0, {
                supplierId: _id,
                isPreferred,
              })
            )
              .then((resData) => resData.data)
              .then((resData) => {
                if (resData) {
                  mateSuppliers.splice(idx, 1, {
                    _id,
                    isPreferred,
                    isActive,
                    name,
                    locOrBranch,
                    phone1,
                    phone2,
                    fax,
                    email,
                    address1,
                    address2,
                    city,
                    state,
                    zip,
                    note,
                    personnels,
                    isPrimary,
                    primarySupplier,
                  });
                  setMateSuppliers([...mateSuppliers]);
                  dispatch(getMSuppliers());

                  toast.success("Branch Supplier updated successfully.");
                } else {
                  toast.error(res.error);
                }
              })
              .catch((err) => {
                toast.error(err);
              })
              .finally(() => setProcessing(false));
          } else {
            toast.error(res.error);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => setProcessing(false));
    } else {
      setProcessing(true);
      dispatch(
        addMSuppliers({
          isActive,
          name,
          locOrBranch,
          phone1,
          phone2,
          fax,
          email,
          address1,
          address2,
          city,
          state,
          zip,
          note,
          personnels,
          isPrimary,
          primarySupplier,
        })
      )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            const newData = res.result;
            dispatch(
              toggleIsPreferred(0, {
                supplierId: newData._id,
                isPreferred,
              })
            )
              .then((resData) => resData.data)
              .then((resData) => {
                if (resData) {
                  setMateSuppliers([
                    ...mateSuppliers,
                    { ...newData, isPreferred },
                  ]);
                  setSelectedSupp(newData._id);
                  dispatch(getMSuppliers());

                  dispatch(getMSuppliers());

                  toast.success("Branch Supplier added successfully.");
                } else {
                  toast.error(res.error);
                }
              })
              .catch((err) => {
                toast.error(err);
              })
              .finally(() => setProcessing(false));
          } else {
            toast.error(res.error);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => setProcessing(false));
    }
  };

  const dltData = () => {
    const idx = mateSuppliers.findIndex((supp) => supp._id === _id);
    setProcessing(true);
    dispatch(deleteMSuppliers(_id))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          hideDeleteModal();
          mateSuppliers.splice(idx, 1);
          setMateSuppliers([...mateSuppliers]);
          toast.success("Supplier deleted successfully.");
          addNew(TABS_SUPPLIERS[0].id);
        } else {
          toast.error(res.error);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setProcessing(false));
  };

  return (
    <div
      style={
        permission
          ? { padding: "1em" }
          : { padding: "1em", pointerEvents: "none" }
      }
    >
      <div
        className="_form_top"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Form.Group>
            <Form.Check
              disabled={primarySuppActive}
              name="isActive"
              checked={isActive}
              onChange={(event) => setIsActive(event.target.checked)}
              label="Branch Active"
            />
          </Form.Group>
        </div>

        <div>
          <RequiredText>*</RequiredText> <span>Required Fields</span>
        </div>
      </div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Check
              name="isPrefered"
              checked={isPreferred}
              onChange={(event) => setIsPreferred(event.target.checked)}
              label="Preferred Branch/Location"
              disabled={!isActive}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="6" lg="3">
          <Form.Group>
            <Form.Label>
              Supplier Name: <RequiredText>*</RequiredText>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={name}
              disabled
              onChange={(event) => handleChange(event, setName)}
            />
          </Form.Group>
        </Col>
        <Col md="6" lg="3">
          <Form.Group>
            <Form.Label>
              Branch/Location Name: <RequiredText>*</RequiredText>
            </Form.Label>

            <Form.Control
              type="text"
              name="locOrBranch"
              value={locOrBranch}
              disabled={!isActive}
              onChange={(event) => handleChange(event, setLocOrBranch)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <CommonAddressFields
          address1Name="address1"
          address2Name="address2"
          cityName="city"
          zipName="zip"
          stateName="state"
          address1={address1}
          address2={address2}
          city={city}
          zip={zip}
          state={state}
          setAddress1={setAddress1}
          setAddress2={setAddress2}
          setCity={setCity}
          setZip={setZip}
          setState={changeState}
          handleChange={handleChange}
          required
          allowDisabling={!isActive}
        />
      </Row>
      <Row>
        <PhoneDetails
          phone1Name="phone1"
          phone2Name="phone2"
          faxName="fax"
          emailName="email"
          phone1={phone1}
          phone2={phone2}
          fax={fax}
          email={email}
          state={state}
          setPhone1={setPhone1}
          setPhone2={setPhone2}
          setEmail={setEmail}
          setFax={setFax}
          handleChange={handleChange}
          required
          disabled={!isActive}
        />
      </Row>

      <Personnel
        personnel={personnels}
        setPersonnel={setPersonnels}
        posOptions={materialPersonnels}
        disabled={!isActive}
      />
      <Row>
        <Col sm="12" md="12">
          <Form.Group>
            <Form.Label>General Note About Supplier:</Form.Label>
            <Form.Control
              as="textarea"
              rows="1"
              name="note"
              value={note}
              disabled={!isActive}
              onChange={(event) => handleChange(event, setNote)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row
        style={{
          // justifyContent: "flex-end",
          margin: "1em 0",
          marginBottom: "2em",
        }}
      >
        <div style={{ margin: "10px 1em", marginBottom: "2em" }}>
          {false ? (
            <Button
              disabled={!isActive}
              onClick={() => setDltModal(true)}
              variant="danger"
              style={{ marginRight: "1em" }}
            >
              Delete
            </Button>
          ) : (
            <Button
              disabled={!isActive || hasNotChangedFields()}
              onClick={() => resetFields()}
              variant="danger"
              style={{ marginRight: "1em" }}
            >
              Cancel
            </Button>
          )}

          <Button
            disabled={
              !name ||
              !locOrBranch ||
              !phone1 ||
              !email ||
              !address1 ||
              !city ||
              !state ||
              !zip ||
              isNaN(zip) ||
              zip.length !== 5 ||
              !validator.isEmail(email) ||
              checkPhone(phone1) ||
              processing ||
              hasNotChangedFields()
            }
            onClick={() => addSuppliers()}
          >
            Save
          </Button>
        </div>
      </Row>
      <DeleteModal
        showDeleteModal={dltModal}
        deleteData={dltData}
        hideDeleteModal={hideDeleteModal}
        processing={processing}
      />
    </div>
  );
}
