import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import PickerDate from "react-datepicker";
import { Form } from "react-bootstrap";
import { convertDate } from "utils/utils";

export default function DatePickerNew({
  disabled,
  setValue,
  value,
  label,
  extraStyles = false,
}) {
  const iconCal = (disabled) => (
    <FontAwesomeIcon
      color={disabled ? "#999" : "#111"}
      className="ico"
      icon={faCalendarAlt}
    />
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
      className={`supp_date_picker_wrap${extraStyles ? " _noMargin" : ""}`}
    >
      {label && <Form.Label disabled={disabled}>{label}</Form.Label>}

      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div className="_picker_wrapper">
          <PickerDate
            value={value}
            onChange={(date) => setValue(convertDate(date))}
            className="s_date_picker"
            disabled={disabled}
          />

          {value && (
            <button
              className="_clear_btn"
              onClick={() => setValue("")}
              disabled={disabled}
            >
              <svg
                height="14"
                width="14"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-6q0nyr-Svg"
              >
                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
              </svg>
            </button>
          )}
        </div>
        {iconCal(disabled)}
      </div>
    </div>
  );
}
