import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { PriceInput } from "components";
import QTYInput from "../components/QTYInput";

import styles from "lib/config/baseStyles";
import { debounce } from "lodash";

import validator from "validator";

export default function DebrisListData({
  listData,
  updateData,
  idx,
  allTotals,
  setAllTotals,
  data,
  deleteItem,
  editItem,
  isLockQuoteData,
  sectionData,
}) {
  const [selectedCategory, setSelectedCategory] = useState(data.pricingId);

  const handleEdit = (newData) => editItem(newData);
  const changeData = (key, value) => ({
    ...data,
    [key]: value,
  });
  const onChangeQty = (e) => {
    const value = e.target.value;

    const newTotal =
      validator.isNumeric(value) &&
      validator.isNumeric(data.uomPrice.toString())
        ? Number(value * data.uomPrice).toFixed(2)
        : data.total;

    const newData = changeData("qty", value);
    newData.total = newTotal;

    handleEdit(newData);
  };

  const onChangeCategory = (e) => {
    const selectedId = e.target.value;

    const match = listData.find((pricing) => pricing._id === selectedId);
    let editedPricing = {
      pricingId: "",
      pricingType: "",
      size: "",
      uomPrice: 0.0,
      total: 0.0,
    };

    if (match) {
      const { pricingType, uomPrice, size } = match;
      const newTotal =
        validator.isNumeric(uomPrice.toString()) &&
        validator.isNumeric(data.qty.toString())
          ? Number(uomPrice * data.qty).toFixed(2)
          : data.total;

      editedPricing.pricingId = selectedId;
      editedPricing.size = size;
      editedPricing.pricingType = pricingType;
      editedPricing.uomPrice = Number(uomPrice).toFixed(2);
      editedPricing.total = newTotal;
    }

    handleEdit({ ...data, ...editedPricing });
    setSelectedCategory(selectedId);
  };

  return (
    <DebrisDataDisplay
      deleteItem={deleteItem}
      pricingType={data.pricingType}
      size={data.size}
      listData={listData}
      selectedCategory={selectedCategory}
      onChangeCategory={onChangeCategory}
      onChangeQty={onChangeQty}
      qty={data.qty}
      price={data.uomPrice}
      total={data.total}
      isLockQuoteData={isLockQuoteData}
      sectionData={sectionData}
    />
  );
}

function DebrisDataDisplay({
  price,
  qty,
  onChangeQty,
  selectedCategory,
  onChangeCategory,
  listData,
  deleteItem,
  isLockQuoteData,
  total,
  sectionData,
}) {
  return (
    <Row>
      <Col
        style={{
          ...styles.reduceWidthCol,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
        lg="2"
      >
        <Button
          style={{ width: "fit-content" }}
          variant="danger"
          onClick={deleteItem}
          disabled={isLockQuoteData?.isLocked}
        >
          Delete
        </Button>
      </Col>

      <Col style={styles.reduceWidthCol} lg="2">
        <Form.Group>
          <Form.Label style={{ marginLeft: "40%" }}>
            <strong>Size</strong>
          </Form.Label>
          <Form.Control
            as="select"
            value={selectedCategory}
            onChange={onChangeCategory}
            disabled={isLockQuoteData?.isLocked}
          >
            <option
              value={{
                pricingType: "",
                size: "",
              }}
            >
              Choose...
            </option>
            {listData && listData.length > 0 ? (
              listData.map((pricing, idx) => (
                <option key={pricing._id} value={pricing._id}>
                  {pricing.size} {pricing.pricingType}
                </option>
              ))
            ) : (
              <option
                value={{
                  pricingType: "",
                  size: "",
                }}
              >
                No data available
              </option>
            )}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col style={styles.reduceWidthCol} lg="4"></Col>

      <Col style={styles.reduceWidthCol}></Col>
      <Col
        style={{
          ...styles.reduceWidthCol,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <QTYInput
          //style={{ width: "fit-content" }}
          value={qty}
          onChange={onChangeQty}
          disabled={isLockQuoteData?.isLocked}
        />
      </Col>
      <Col
        style={{
          ...styles.reduceWidthCol,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <PriceInput
          //style={{ width: "fit-content" }}
          disabled={true}
          price={price}
        />
      </Col>
      <Col
        style={{
          ...styles.reduceWidthCol,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <PriceInput
          //style={{ width: "fit-content" }}
          disabled={true}
          price={total}
          input="Total"
        />
      </Col>
    </Row>
  );
}
