import { useEffect } from "react";

import { axiosInstance as axios } from "services";
import { getEndPoints } from "api/api";
import { ApiEndpoints } from "lib/config/baseSettings";
// import { GET_ALL_COUNT } from "utils/utils";
const url = getEndPoints(process.env.REACT_APP_MODE);
const { GET_ALL_COUNT } = ApiEndpoints.SCHEDULE_MODAL;

const useCountCheck = () => {
  useEffect(() => {
    getAllCount();
  }, []);
  const getAllCount = () => {
    return axios
      .get(`${url}${GET_ALL_COUNT}`)

      .then((response) => {
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };
  return {};
};

export default useCountCheck;
