export const salesActionTypes = {
  STAGE_START: "STAGE_START",
  STAGE_SUCCESS: "STAGE_SUCCESS",
  STAGE_FAILURE: "STAGE_FAILURE",
  CHANGE_STAGE_START: "CHANGE_STAGE_START",
  CHANGE_STAGE_SUCCESS: "CHANGE_STAGE_SUCCESS",
  CHANGE_STAGE_FAILURE: "CHANGE_STAGE_FAILURE",
  CHANGE_STAGE_RESET: "CHANGE_STAGE_RESET",
  UPDATE_FOLLOWUP_START: "UPDATE_FOLLOWUP_START",
  UPDATE_FOLLOWUP_SUCCESS: "UPDATE_FOLLOWUP_SUCCESS",
  UPDATE_FOLLOWUP_FAILURE: "UPDATE_FOLLOWUP_FAILURE",
  UPDATE_FOLLOWUP_DATA_START: "UPDATE_FOLLOWUP_DATA_START",
  UPDATE_FOLLOWUP_DATA_SUCCESS: "UPDATE_FOLLOWUP_DATA_SUCCESS",
  UPDATE_FOLLOWUP_DATA_FAILURE: "UPDATE_FOLLOWUP_DATA_FAILURE",
};
