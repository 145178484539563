/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from "react";
import "./Suppliers.scss";
import PopupModal from "components/popup-modal/popup-modal";

import { Row, Col, Button, Tab, Tabs } from "react-bootstrap";

import { fetchStaticAll } from "lib/redux/static-all/static-all.actions";
import {
  getMSuppliers,
  getSubcontractorSuppliers,
  getCrewSuppliers,
  getCartingSuppliers,
} from "lib/redux/suppliers/suppliers.action";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import InitialLoader from "components/InitilalLoader";
import {
  MODAL_OPTIONS,
  SUBS_CREWS_OPTIONS,
  MAIN_TABS,
  TABS_SUPPLIERS,
} from "./const";
import PrimarySuppliers from "./PrimarySuppliers";
import BranchSuppliers from "./BranchSuppliers";
import Contractor from "./Contractor";
import Crew, { CrewPricingList } from "./Crew";
import SubsPricingList from "./SubsPricingList";
import { PricingList } from "./Material";
import { CartingList, CartingSuppliers } from "./Carting";
import { EmptyData, CheckMark } from "./commonComponents";
import { Link, useHistory } from "react-router-dom";

import userPermission from "hooks/usePermission";

export default function ApplicationSettings() {
  const [key, setKey] = useState(MAIN_TABS[0].id);
  const [newModal, setNewModal] = useState(false);

  const reloadListData = useRef(true);

  const [activePath, setActivePath] = useState(TABS_SUPPLIERS[0].id2);
  const [mateSuppliers, setMateSuppliers] = useState([]);
  const [subcontractor, setSubcontractor] = useState([]);
  const [crews, setCrews] = useState([]);
  const [cartSuppliers, setCartSuppliers] = useState([]);

  const [selectedSupp, setSelectedSupp] = useState("");
  const [isPrimary, setIsPrimary] = useState(true);
  const [subsCrewsModal, setSubsCrewsModal] = useState(false);
  const [primaryData, setPrimaryData] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");

  const { gettingMSuppliers, mSuppliersData, mSuppliersErr } = useSelector(
    (state) => state.materialSuppliers
  );

  const { gettingSubsSuppliers, subsSuppliersData, subsSuppliersErr } =
    useSelector((state) => state.subcontractorSuppliers);

  const { gettingCrewSuppliers, crewSuppliersData, crewSuppliersErr } =
    useSelector((state) => state.crewSuppliers);

  const { gettingCartingSuppliers, cartingSuppliersData, cartingSuppliersErr } =
    useSelector((state) => state.cartingSupplier);

  const { user } = useSelector((state) => state.login);
  const { gettingPermission, permission, permissionErr } = useSelector(
    (state) => state.allPermissions
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const loadData = () => {
    if (
      activePath === TABS_SUPPLIERS[0].id2 ||
      activePath === TABS_SUPPLIERS[0].id ||
      activePath === TABS_SUPPLIERS[0].id3
    ) {
      dispatch(getMSuppliers());
    } else if (activePath === TABS_SUPPLIERS[1].id3) {
      dispatch(getSubcontractorSuppliers());
      dispatch(getCrewSuppliers());
    } else if (activePath === TABS_SUPPLIERS[1].id) {
      dispatch(getSubcontractorSuppliers());
    } else if (activePath === SUBS_CREWS_OPTIONS[1].id) {
      dispatch(getCrewSuppliers());
    } else if (
      activePath === TABS_SUPPLIERS[2].id ||
      activePath === TABS_SUPPLIERS[2].id3
    ) {
      dispatch(getCartingSuppliers());
    }
  };

  useEffect(() => {
    dispatch(fetchStaticAll());
    loadData();
  }, []);
  const { materialPointerEvent } = userPermission(permission);
  const { materialPointerEventViewOnly } = userPermission(permission);

  useEffect(() => {
    if (reloadListData.current) {
      loadData();
    }
  }, [activePath]);

  useEffect(() => {
    if (cartingSuppliersData) {
      setCartSuppliers(cartingSuppliersData);
    }
  }, [cartingSuppliersData]);

  useEffect(() => {
    if (crewSuppliersData) {
      setCrews(crewSuppliersData);
    }
  }, [crewSuppliersData]);

  useEffect(() => {
    if (mSuppliersData) {
      setMateSuppliers(mSuppliersData);
    }
  }, [mSuppliersData]);

  useEffect(() => {
    if (subsSuppliersData) {
      setSubcontractor(subsSuppliersData);
    }
  }, [subsSuppliersData]);

  useEffect(() => {
    if (subsSuppliersErr) {
      toast.error(subsSuppliersErr);
    }
  }, [subsSuppliersErr]);

  const changeActivePath = (id) => {
    clearActivePath();
    setSelectedSupp("");
    setIsPrimary(true);
    toggleNewModal(false);
    setSubsCrewsModal(false);
    setActivePath(id);
  };

  const clearActivePath = () => {
    setActivePath("");
  };
  const addNew = (id) => {
    setKey(MAIN_TABS[0].id);
    changeActivePath(id);
  };

  const selectSupp = (primary, _id) => {
    setSelectedSupp(_id);
    setIsPrimary(primary);
    setActivePath(TABS_SUPPLIERS[0].id);
  };

  const selectTab = (id) => {
    setActivePath(id);
    setSelectedSupp("");
  };

  useEffect(() => {
    if (
      activePath === TABS_SUPPLIERS[0].id ||
      activePath === TABS_SUPPLIERS[0].id2 ||
      activePath === TABS_SUPPLIERS[0].id3 ||
      activePath === TABS_SUPPLIERS[2].id
    ) {
      return;
    }
    setSelectedSupp("");
    setSearchTxt("");
  }, [activePath]);

  const switchSuppliersInfo = () => {
    switch (activePath) {
      case TABS_SUPPLIERS[0].id3:
      case TABS_SUPPLIERS[0].id2:
        return (
          <div className="_tab_msg">
            <div className="_msg">
              <p className="text-base">
                To add new, click/tap the 'Add New' button above.
              </p>
              <p className="text-base">
                Note: If suppler has more then one location/branch enter the
                main location first. After saving, you can enter the branch
                locations as needed.
              </p>
            </div>
          </div>
        );

      case TABS_SUPPLIERS[2].id3:
      case TABS_SUPPLIERS[1].id3:
        return (
          <div className="_tab_msg">
            <div className="_msg">
              <p>To add new, click/tap the 'Add New' button above.</p>
            </div>
          </div>
        );

      case TABS_SUPPLIERS[0].id:
        return isPrimary ? (
          <PrimarySuppliers
            mateSuppliers={mateSuppliers}
            setMateSuppliers={setMateSuppliers}
            setIsPrimary={setIsPrimary}
            isPrimary={isPrimary}
            selectedSupp={selectedSupp}
            setSelectedSupp={setSelectedSupp}
            addNew={addNew}
            permission={materialPointerEventViewOnly}
          />
        ) : (
          <BranchSuppliers
            mateSuppliers={mateSuppliers}
            setMateSuppliers={setMateSuppliers}
            setIsPrimary={setIsPrimary}
            isPrimary={isPrimary}
            selectedSupp={selectedSupp}
            setSelectedSupp={setSelectedSupp}
            addNew={addNew}
            permission={materialPointerEventViewOnly}
          />
        );

      case TABS_SUPPLIERS[1].id:
        return (
          <Contractor
            subcontractor={subcontractor}
            setSubcontractor={setSubcontractor}
            selectedSupp={selectedSupp}
            setSelectedSupp={setSelectedSupp}
            addNew={addNew}
            activeTab={key}
            permission={materialPointerEventViewOnly}
          />
        );

      case SUBS_CREWS_OPTIONS[1].id:
        return (
          <Crew
            crews={crews}
            setCrews={setCrews}
            setIsPrimary={setIsPrimary}
            isPrimary={isPrimary}
            selectedSupp={selectedSupp}
            setSelectedSupp={setSelectedSupp}
            setPrimaryData={setPrimaryData}
            primaryData={primaryData}
            addNew={addNew}
            activeTab={key}
            permission={materialPointerEventViewOnly}
          />
        );

      case TABS_SUPPLIERS[2].id:
        return (
          <CartingSuppliers
            cartSuppliers={cartSuppliers}
            setCartSuppliers={setCartSuppliers}
            selectedSupp={selectedSupp}
            setSelectedSupp={setSelectedSupp}
            addNew={addNew}
            permission={materialPointerEventViewOnly}
          />
        );

      default:
        return null;
    }
  };

  const switchListMaterial = () =>
    selectedSupp ? (
      isPrimary ? (
        <div style={{ padding: "1em" }}>
          <p className="__msg">
            Select a material category to start entering prices. Note: Pricing
            is unique to each material supplier, unless it is a branch office.
          </p>
          <PricingList
            materialPointerEventViewOnly={materialPointerEventViewOnly}
            selectedSupp={selectedSupp}
          />
        </div>
      ) : (
        <div className="_tab_msg">
          <p className="__msg">
            This is a branch location, all changes must be at primary supplier
            level, marked with an *.
          </p>
        </div>
      )
    ) : (
      <div className="_tab_msg">
        <p className="__msg">
          Please select a primary supplier inorder to see the pricing list.
        </p>
      </div>
    );

  const switchSubsCrewsList = () =>
    selectedSupp ? (
      <div style={{ padding: "1em" }}>
        <p className="__msg text-base">
          Select a labor category to start entering pricing. Note: Pricing is
          unique to each subcontractor.
        </p>
        <SubsPricingList
          materialPointerEventViewOnly={materialPointerEventViewOnly}
          selectedSupp={selectedSupp}
        />
      </div>
    ) : (
      <div className="_tab_msg">
        <p className="__msg">
          Please select a Subcontractor/Crew inorder to see the pricing list.
        </p>
      </div>
    );

  const switchCrewsList = () =>
    selectedSupp ? (
      <div style={{ padding: "1em" }}>
        <p className="__msg">
          Select a labor category to start entering pricing. Note: A single
          pricing list is shared between all in-house crews.
        </p>
        <CrewPricingList selectedSupp={selectedSupp} />
      </div>
    ) : (
      <div className="_tab_msg">
        <p className="__msg">
          Please select a Crew inorder to see the pricing list.
        </p>
      </div>
    );

  const switchPricingList = () => {
    switch (activePath) {
      case TABS_SUPPLIERS[0].id:
      case TABS_SUPPLIERS[0].id3:
        return switchListMaterial();

      case TABS_SUPPLIERS[1].id3:
      case TABS_SUPPLIERS[1].id:
        return switchSubsCrewsList();

      case TABS_SUPPLIERS[1].id2:
        return switchCrewsList();

      case TABS_SUPPLIERS[2].id:
      case TABS_SUPPLIERS[2].id3:
        return <CartingList selectedSupp={selectedSupp} />;

      default:
        return null;
    }
  };

  const switchMainTabs = () => {
    if (materialPointerEvent) {
      switch (key) {
        case MAIN_TABS[0].id:
          return switchSuppliersInfo();

        case MAIN_TABS[1].id:
          return switchPricingList();

        default:
          return null;
      }
    } else {
      switch (key) {
        case MAIN_TABS[0].id:
          return switchSuppliersInfo();
        default:
          return null;
      }
    }
  };

  const toggleNewModal = (value) => {
    setNewModal(value);
  };

  const switchTitle = () => {
    switch (activePath) {
      case TABS_SUPPLIERS[1].id3:
      case TABS_SUPPLIERS[1].id2:
      case TABS_SUPPLIERS[1].id:
        return "Subs/Crews Info";

      default:
        return "Supplier Info";
    }
  };

  const AddNewModal = (show, heading, hide, btns, extraDetail = false) => (
    <PopupModal
      show={show}
      heading={heading}
      onHide={() => hide(false)}
      bold
      centered={false}
      styles={{
        border: "none",
      }}
    >
      <Row
        style={{
          justifyContent: "center",
          margin: "2.5em 0",
          padding: "2em 0",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {btns.map((btn, i) => (
          <Button
            key={btn.id}
            onClick={() =>
              extraDetail
                ? i === 1
                  ? setSubsCrewsModal(true)
                  : addNew(btn.id)
                : addNew(btn.id)
            }
            className="modified_btn"
            style={{ marginRight: "1.5em", width: "25%" }}
          >
            {btn.name}
          </Button>
        ))}
      </Row>
    </PopupModal>
  );

  const hasBranch = (id) =>
    mateSuppliers.some(
      (supp) => !supp.isPrimary && supp.primarySupplier === id
    );

  const searchList = (dataList) => {
    const list = dataList.filter((data) =>
      data.name.toLowerCase().includes(searchTxt.toLowerCase())
    );
    return list;
  };

  const stopReloadingListData = () => {
    if (reloadListData.current) {
      reloadListData.current = false;
    }
  };

  const startReloadingListData = () => {
    if (!reloadListData.current) {
      reloadListData.current = true;
    }
  };

  const onSelectSupplier = (isPrimaryValue, id) => {
    stopReloadingListData();
    selectSupp(isPrimaryValue, id);
  };

  const onSelectSubsOrCrewSupplier = (address1, id) => {
    if (address1) {
      setActivePath(TABS_SUPPLIERS[1].id);
    } else {
      setActivePath(TABS_SUPPLIERS[1].id2);
    }
    setTimeout(() => {
      stopReloadingListData();
      setSelectedSupp(id);
    }, 100);
  };

  const onSelectCartSupplier = (id) => {
    setActivePath(TABS_SUPPLIERS[2].id);
    stopReloadingListData();
    setSelectedSupp(id);
  };

  const displaySuppliers = (data) => {
    if (data.length === 0) {
      return <EmptyData />;
    }
    return data.map((data) => (
      <li
        key={data._id}
        className={`${
          selectedSupp === data._id ? "active text-xs" : "text-xs"
        }${data.isActive ? "text-xs" : " closed text-xs"}`}
        role="button"
        aria-roledescription="Select Supplier"
        onClick={() => onSelectSupplier(data.isPrimary, data._id)}
      >
        {`${data.name} - ${data.locOrBranch}`}
        {data.isPrimary ? (hasBranch(data._id) ? "* " : "") : ""}
        {data.isPreferred ? <CheckMark /> : ""}
      </li>
    ));
  };

  const displaySubsOrCrew = (data) => {
    if (data.length === 0) {
      return <EmptyData />;
    }
    return data.map((data) => (
      <li
        key={data._id}
        className={`${
          selectedSupp === data._id ? "active text-xs" : "text-xs"
        }${data.isActive ? "text-xs" : " closed text-xs"}`}
        role="button"
        aria-roledescription="Tap to Select"
        onClick={() => onSelectSubsOrCrewSupplier(data.address1, data._id)}
      >
        {data.name} {data.isPreferred ? <CheckMark /> : ""}
      </li>
    ));
  };

  const displayCart = (data) => {
    if (data.length === 0) {
      return <EmptyData />;
    }
    return data.map((data) => (
      <li
        key={data._id}
        className={`${selectedSupp === data._id ? "active" : ""}${
          data.isActive ? "" : " closed"
        }`}
        role="button"
        aria-roledescription="Tap to Select"
        onClick={() => onSelectCartSupplier(data._id)}
      >
        {data.name} {data.isPreferred ? <CheckMark /> : ""}
      </li>
    ));
  };

  const DisplayLoader = ({ error }) => (
    <div className="p-3">
      <InitialLoader error={error} onReload={loadData} />
    </div>
  );

  const switchData = () => {
    switch (activePath) {
      case TABS_SUPPLIERS[0].id3:
      case TABS_SUPPLIERS[0].id2:
      case TABS_SUPPLIERS[0].id:
        return gettingMSuppliers || mSuppliersErr ? (
          <DisplayLoader error={mSuppliersErr} />
        ) : searchTxt ? (
          displaySuppliers(searchList(mateSuppliers))
        ) : (
          displaySuppliers(mateSuppliers)
        );

      case TABS_SUPPLIERS[1].id3:
        return gettingCrewSuppliers || gettingSubsSuppliers || mSuppliersErr ? (
          <DisplayLoader error={mSuppliersErr} />
        ) : searchTxt ? (
          displaySubsOrCrew(searchList([...subcontractor, ...crews]))
        ) : (
          displaySubsOrCrew([...subcontractor, ...crews])
        );

      case TABS_SUPPLIERS[1].id:
        return gettingSubsSuppliers || subsSuppliersErr ? (
          <DisplayLoader error={subsSuppliersErr} />
        ) : searchTxt ? (
          displaySubsOrCrew(searchList([...subcontractor, ...crews]))
        ) : (
          displaySubsOrCrew([...subcontractor, ...crews])
        );

      case SUBS_CREWS_OPTIONS[1].id:
        return gettingCrewSuppliers || crewSuppliersErr ? (
          <DisplayLoader error={crewSuppliersErr} />
        ) : searchTxt ? (
          displaySubsOrCrew(searchList([...subcontractor, ...crews]))
        ) : (
          displaySubsOrCrew([...subcontractor, ...crews])
        );

      case TABS_SUPPLIERS[2].id3:
      case TABS_SUPPLIERS[2].id:
        return gettingCartingSuppliers || cartingSuppliersErr ? (
          <DisplayLoader error={cartingSuppliersErr} />
        ) : searchTxt ? (
          displayCart(searchList(cartSuppliers))
        ) : (
          displayCart(cartSuppliers)
        );

      default:
        return null;
    }
  };

  const onSelectSuppliersTab = (tabId) => {
    startReloadingListData();
    selectTab(tabId);
  };

  const userDataRole = user.userData.role[0].roleName.toLowerCase();
  const disable = !materialPointerEvent || !materialPointerEventViewOnly;
  return (
    <div className="wrapper">
      <Row
        style={{
          justifyContent: "space-between",
          margin: "0 .2em",
          display: "flex",
          flexWrap: "nowrap",
        }}
      >
        <h4 className="text-lg font-bold p-0">
          <span className="">
            Suppliers Material & Labor &nbsp; &nbsp; &nbsp;
            <Link
              to={{
                pathname: "/dashboard/help",
                search: "Admin Suppliers Material Labor",
              }}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
            >
              <b style={{ color: "red" }}>?</b>
            </Link>
          </span>
        </h4>
        {/* <Button
          disabled={disable}
          onClick={() => toggleNewModal(true)}
          style={{ maxWidth: "100px" }}
        >
          Add New
        </Button> */}
      </Row>
      <Row style={{ marginRight: 0 }}>
        <Col style={{ paddingRight: 0 }}>
          <div />
          <div className="_row">
            <div className="_left_panel">
              <div
                className="_menu_left _suppliers_btns"
                style={{ height: "auto !important" }}
              >
                <div className="_btns">
                  {TABS_SUPPLIERS.map((tab, i) => (
                    <button
                      key={tab.id}
                      className={`${
                        activePath === tab.id ||
                        activePath === tab.id2 ||
                        activePath === tab.id3
                          ? "active"
                          : ""
                      }`}
                      onClick={() => onSelectSuppliersTab(tab.id3)}
                    >
                      {tab.name}
                    </button>
                  ))}
                </div>
                <div className="_search">
                  <input
                    type="search"
                    placeholder="Search"
                    className="text-xs"
                    onChange={(e) => setSearchTxt(e.target.value)}
                    value={searchTxt}
                  />
                </div>
              </div>
              <div className="mt-2">
                <Button
                  disabled={
                    disable || user?.userData?.paymentStatus === "Frozen 1"
                  }
                  onClick={() => toggleNewModal(true)}
                  className="w-100 text-xs"
                >
                  Add New
                </Button>
              </div>

              {/* Tab Section */}
              <div className="_menu_left _suppliers">
                <ul className="_suppliers-list">{switchData()}</ul>
              </div>
            </div>
            <div className="_sup_tabs">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                unmountOnExit={false}
                style={materialPointerEvent ? {} : { pointerEvents: "none" }}
              >
                {materialPointerEvent
                  ? MAIN_TABS.map((tab, i) => (
                      <Tab
                        key={tab.id}
                        eventKey={tab.id}
                        title={i === 0 ? switchTitle() : tab.name}
                        disabled={user.userData.paymentStatus === "Frozen 1"}
                      >
                        <div className="_cont_tab">{switchMainTabs()}</div>
                      </Tab>
                    ))
                  : MAIN_TABS.map((tab, i) => (
                      <Tab
                        key={tab.id}
                        eventKey={tab.id}
                        title={
                          i === 0 ? (
                            switchTitle()
                          ) : (
                            <span
                              style={{ pointerEvents: "none", color: "gray" }}
                              className="text-sm"
                            >
                              {tab.name}
                            </span>
                          )
                        }
                      >
                        <div className="_cont_tab">{switchMainTabs()}</div>
                      </Tab>
                    ))}
              </Tabs>
            </div>
          </div>
        </Col>
      </Row>
      {AddNewModal(newModal, "Add New?", toggleNewModal, MODAL_OPTIONS, true)}
      {AddNewModal(
        subsCrewsModal,
        "Subcontractor or In-house Crew?",
        setSubsCrewsModal,
        SUBS_CREWS_OPTIONS
      )}
    </div>
  );
}
