import { PopupModal } from "components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Row, Col, Form, Button, Modal, Alert } from "react-bootstrap";
import {
  getRepairRequest,
  updateRepairRequest,
} from "lib/redux/repairs/repairs.actions";

const isTrue = (data) => JSON.parse(data);
export function RepairModal({ requestId, show, setShow }) {
  const dispatch = useDispatch();

  const { dataRequest, isLoadingRequest, errorsRequest } = useSelector(
    (state) => state.getRepairReq
  );
  const { isupdatingReq } = useSelector((state) => state.updateRepairReq);

  const [repairData, setRepairData] = useState(null);

  useEffect(() => {
    dispatch(getRepairRequest({ requestId }));
  }, [requestId, show]);

  useEffect(() => {
    if (!dataRequest) return;
    setRepairData({
      ...dataRequest,
      servicePrice: dataRequest?.servicePrice.replace("$", ""),
      totalPrice: dataRequest?.totalPrice.replace("$", ""),
    });
  }, [dataRequest, isLoadingRequest, errorsRequest]);

  const saveServiceRequest = () => {
    const { requestId } = dataRequest;
    const editRepairRequest = (data) => {
      dispatch(updateRepairRequest({ ...data }));
    };
    const {
      requestFor,
      originalWorkYear,
      repairDescreption,
      requestForOther,
      servicePrice,
      taxRate,
      totalPrice,
      underWarrenty,
      originalWorkDone,
    } = repairData;
    editRepairRequest({
      requestId: requestId,
      requestFor: requestFor,
      requestForOther: requestForOther,
      originalWorkDone: isTrue(originalWorkDone),
      originalWorkYear: originalWorkYear,
      underWarrenty: isTrue(underWarrenty),
      servicePrice: `${servicePrice === 0 ? "" : `$${servicePrice}`}`,
      taxRate: taxRate,
      totalPrice: `${totalPrice === 0 ? "" : `$${totalPrice}`}`,
      repairDescreption: repairDescreption,
    });
    setShow(false);
  };
  const validateServiceRequestModalFields = () => {
    if (!repairData) return true;
    const {
      requestFor,
      originalWorkYear,
      repairDescreption,
      requestForOther,
      servicePrice,
      taxRate,
      totalPrice,
      underWarrenty,
      originalWorkDone,
    } = repairData;

    if (requestFor.length === 0) {
      return true;
    }

    if (requestFor.includes("repair06") && !requestForOther) {
      return true;
    }

    if (!isTrue(originalWorkDone) && !repairDescreption) {
      return true;
    }

    if (isTrue(originalWorkDone) && !originalWorkYear) {
      return true;
    }

    if (
      isTrue(originalWorkDone) &&
      originalWorkYear !== "" &&
      (isNaN(originalWorkYear) || Number(originalWorkYear) < 1000)
    ) {
      return true;
    }

    if (!isTrue(underWarrenty) && (!totalPrice || !taxRate || !servicePrice)) {
      return true;
    }

    return false;
  };

  const DisplayFooter = () => {
    const styles = {
      display: "flex",
      justifyContent: "flex-end",
    };

    return (
      <Modal.Footer>
        <div style={{ ...styles, width: "100%" }}>
          <div style={styles}>
            <div style={{ marginRight: "5px" }}>
              <Button
                variant="outline-danger"
                onClick={() => {
                  setShow(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div style={{ marginLeft: "5px" }}>
              <Button
                variant={"success"}
                disabled={validateServiceRequestModalFields() || isupdatingReq}
                onClick={saveServiceRequest}
              >
                {isupdatingReq ? "Saving..." : "Save Changes"}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    );
  };
  return (
    <PopupModal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      heading="Repair Request"
      bold={true}
    >
      {/* <Row>
        {msg && msgFor === "modal" && (
          <Col>
            <Alert variant={msgType}>{msg}</Alert>
          </Col>
        )}
      </Row> */}
      {repairData && (
        <DisplayNewRepairOptions data={repairData} setData={setRepairData} />
      )}
      <DisplayFooter />
    </PopupModal>
  );
}

const DisplayNewRepairOptions = ({ data, setData }) => {
  const {
    requestFor,
    originalWorkYear,
    repairDescreption,
    requestForOther,
    servicePrice,
    taxRate,
    totalPrice,
    underWarrenty,
    originalWorkDone,
  } = data;
  const newRepairs = useSelector((state) => state.staticAll.data.repairType);
  const taxRates = useSelector((state) => state.staticAll.data.taxRate);

  const displayRepairType = (requests) => {
    if (requests) {
      return requests.map((request) => (
        <button
          key={request.repairTypeId}
          className={`serviceRequestBtn ${
            requestFor.includes(request.repairTypeId) && "selected"
          }`}
          onClick={() => makeSelection(request.repairTypeId)}
        >
          {request.repairTypeName}
        </button>
      ));
    }
    return;
  };
  const displayYesNo = () => {
    const YesNo = [
      {
        name: "YES",
        value: true,
      },
      {
        name: "NO",
        value: false,
      },
    ];
    return YesNo.map((item) => (
      <option key={item.name} value={item.value}>
        {item.name}
      </option>
    ));
  };

  const checkIsUnderWarrenty = (originalWorkPerformed, isUnderWarrenty) => {
    if (isTrue(originalWorkPerformed) && isTrue(isUnderWarrenty)) {
      return false;
    }
    return true;
  };

  const displayTaxRate = (data) => {
    return data.map((item) => (
      <option key={item.taxId} value={item.taxNum}>
        {item.taxText}
      </option>
    ));
  };
  const makeSelection = (name) => {
    if (requestFor.includes(name)) {
      let temp = [...requestFor];
      const index = requestFor.indexOf(name);
      temp.splice(index, 1);
      setData({ ...data, requestFor: [...temp] });
    } else {
      setData({ ...data, requestFor: [...requestFor, name] });
    }
  };

  return (
    <div>
      <b>Repair is for:</b>
      <div style={{ margin: "1em auto", width: "100%" }}>
        {displayRepairType(newRepairs)}

        <Row>
          <Col md="12" sm="12">
            {requestFor.includes("repair06") && (
              <Row>
                <Col md="6" sm="6">
                  <Form.Group style={styles.formInput}>
                    <Form.Control
                      type="text"
                      name="repair06Name"
                      placeholder="Enter Other"
                      value={requestForOther}
                      onChange={(e) => setRepair06Name(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm="8" md="6">
                <Form.Group style={styles.formInput}>
                  <Form.Label>Did we perform original work?</Form.Label>
                  <Form.Control
                    as="select"
                    name="originalWorkPerformed"
                    value={originalWorkDone}
                    onChange={(e) =>
                      setData({ ...data, originalWorkDone: e.target.value })
                    }
                  >
                    {displayYesNo()}
                  </Form.Control>
                </Form.Group>
              </Col>
              {isTrue(originalWorkDone) && (
                <>
                  <Col sm="3" md="3">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Year</Form.Label>
                      <Form.Control
                        type="text"
                        name="WorkPerformedYear"
                        value={originalWorkYear}
                        onChange={(e) =>
                          setData({ ...data, originalWorkYear: e.target.value })
                        }
                        maxLength={4}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="6" md="3">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Is it under Warranty?</Form.Label>
                      <Form.Control
                        as="select"
                        name="isUnderWarrenty"
                        value={underWarrenty}
                        onChange={(e) =>
                          setData({ ...data, underWarrenty: e.target.value })
                        }
                      >
                        {displayYesNo()}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>

            {checkIsUnderWarrenty(originalWorkDone, underWarrenty) && (
              <Row>
                <Col sm="6" md="6">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Repair/Service Price</Form.Label>
                    <Form.Control
                      type="text"
                      name="repairServicePrice"
                      placeholder="Pre Tax $"
                      value={`$${servicePrice}`}
                      onChange={(e) => {
                        const sumPrice = !e.target.value.replace("$", "")
                          ? 0
                          : Number(e.target.value.replace("$", "")) < 0
                          ? 0
                          : Number(e.target.value.replace("$", "")) +
                            (Number(taxRate) / 100) *
                              Number(e.target.value.replace("$", ""));
                        setData({
                          ...data,
                          servicePrice: e.target.value.replace("$", ""),
                          totalPrice: sumPrice,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col sm="6" md="3">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Tax Rate</Form.Label>
                    <Form.Control
                      as="select"
                      name="taxRate"
                      value={taxRate}
                      onChange={(e) => {
                        const sumPrice = !servicePrice
                          ? 0
                          : Number(servicePrice) < 0
                          ? 0
                          : Number(servicePrice) +
                            (Number(e.target.value) / 100) *
                              Number(servicePrice);
                        setData({
                          ...data,
                          taxRate: e.target.value,
                          totalPrice: sumPrice,
                        });
                      }}
                    >
                      <option value="">Choose...</option>
                      {taxRates &&
                        taxRates.length > 0 &&
                        displayTaxRate(taxRates)}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col sm="6" md="3">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Total</Form.Label>
                    <Form.Control
                      type="text"
                      value={`$${totalPrice}`}
                      disabled={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <Form.Group style={styles.formInput}>
                  <Form.Label>Description of Repair</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="2"
                    name="repairDescription"
                    value={repairDescreption}
                    onChange={(e) =>
                      setData({ ...data, repairDescreption: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const styles = {
  formInput: {
    marginBottom: "15px",
  },
};
