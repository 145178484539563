const { getData } = require("services/storageManager");

const modelName = "allStardardStatementDatabase"; ///type array

const solveInSyntax = (key, inLogic, document) => {
  const arrayValue = inLogic["$in"];
  if (arrayValue.includes(document[key])) {
    return true;
  }
  return false;
};

/**
 * resolve mongoDB 'and' queries
 * @param {*} query
 * @param {*} document
 * @returns true if the document satisfy the query, else false
 */
const checkDocument = (query, document) => {
  for (const [key, value] of Object.entries(query)) {
    //check if the value of query is an object which means it has special meanings
    if (typeof value === "object" && !Array.isArray(value) && value !== null) {
      //solve in
      if ("$in" in value) {
        let check = solveInSyntax(key, value, document);
        if (!check) return false;
      }
    } else {
      //here solve simple equality

      if (document[key] !== value) return false;
    }
  }
  return true;
};
const find = async (query) => {
  const data = await getData(modelName);

  const result = data.filter((document) => {
    return checkDocument(query, document);
  });

  return result;
};

export const standardStatementModel = { find };
