import { repairsActionTypes } from "./repairs.types";

const INITIAL_STATE = {
  isLoading: false,
  data: null,
  errors: null,
};

export const TaskReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case repairsActionTypes.TASK_START:
      return {
        ...state,
        isLoading: true,
        data: null,
        errors: null,
      };

    case repairsActionTypes.TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.result.allTasks,
        errors: null,
      };

    // case repairsActionTypes.ADD_TASK_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     data: [...state.data, action.payload],
    //     errors: null,
    //   };

    // case repairsActionTypes.EDIT_TASK_SUCCESS: {
    //   const { dataId, payload } = action;
    //   const temp = [...state.data];
    //   temp.splice(dataId, 1, payload);
    //   return {
    //     ...state,
    //     isLoading: false,
    //     data: temp,
    //     errors: null,
    //   };
    // }

    // case repairsActionTypes.DELETE_TASK_SUCCESS: {
    //   const { idx } = action;
    //   const stateDataCopy = [...state.data];
    //   stateDataCopy.splice(idx, 1);
    //   return {
    //     ...state,
    //     isLoading: false,
    //     data: stateDataCopy,
    //     errors: null,
    //   };
    // }

    case repairsActionTypes.TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: null,
        errors: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_ADD = {
  isSubmitting: false,
  submitSuccess: null,
  submitFail: null,
};

export const AddTaskReducer = (state = INITIAL_STATE_ADD, action) => {
  switch (action.type) {
    case repairsActionTypes.ADD_TASK_START:
      return {
        ...state,
        isSubmitting: true,
        submitSuccess: null,
        submitFail: null,
      };

    case repairsActionTypes.ADD_TASK_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        submitSuccess: action.message,
        submitFail: null,
      };

    case repairsActionTypes.ADD_TASK_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        submitSuccess: null,
        submitFail: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_DELETE = {
  isDeleting: false,
  deletingSuccess: null,
  deletingFail: null,
};

export const DeleteTaskReducer = (state = INITIAL_STATE_DELETE, action) => {
  switch (action.type) {
    case repairsActionTypes.DELETE_TASK_START:
      return {
        ...state,
        isDeleting: true,
        deletingSuccess: null,
        deletingFail: null,
      };

    case repairsActionTypes.DELETE_TASK_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deletingSuccess: action.message,
        deletingFail: null,
      };

    case repairsActionTypes.DELETE_TASK_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deletingSuccess: null,
        deletingFail: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_EDIT = {
  isEditing: false,
  editingSuccess: null,
  editingFail: null,
};

export const EditTaskReducer = (state = INITIAL_STATE_EDIT, action) => {
  switch (action.type) {
    case repairsActionTypes.EDIT_TASK_START:
      return {
        ...state,
        isEditing: true,
        editingSuccess: null,
        editingFail: null,
      };

    case repairsActionTypes.EDIT_TASK_SUCCESS:
      return {
        ...state,
        isEditing: false,
        editingSuccess: action.message,
        editingFail: null,
      };

    case repairsActionTypes.EDIT_TASK_FAILURE:
      return {
        ...state,
        isEditing: false,
        editingSuccess: null,
        editingFail: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_REQUEST = {
  isLoadingRequest: false,
  dataRequest: null,
  errorsRequest: null,
};

export const GetRepairRequestReducer = (
  state = INITIAL_STATE_REQUEST,
  action
) => {
  switch (action.type) {
    case repairsActionTypes.GET_REPAIR_REQUEST_START:
      return {
        ...state,
        isLoadingRequest: true,
        dataRequest: null,
        errorsRequest: null,
      };

    case repairsActionTypes.GET_REPAIR_REQUEST_SUCCESS:
      return {
        ...state,
        isLoadingRequest: false,
        dataRequest: action.payload.result,
        errorsRequest: null,
      };

    case repairsActionTypes.GET_REPAIR_REQUEST_FAILURE:
      return {
        ...state,
        isLoadingRequest: false,
        dataRequest: null,
        errorsRequest: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_UPDATE_REQ = {
  isupdatingReq: false,
  updateReqSuccess: null,
  updateReqFail: null,
};

export const UpdateRepairRequestReducer = (
  state = INITIAL_STATE_UPDATE_REQ,
  action
) => {
  switch (action.type) {
    case repairsActionTypes.UPDATE_REPAIR_REQUEST_START:
      return {
        ...state,
        isupdatingReq: true,
        updateReqSuccess: null,
        updateReqFail: null,
      };

    case repairsActionTypes.UPDATE_REPAIR_REQUEST_SUCCESS:
      return {
        ...state,
        isupdatingReq: false,
        updateReqSuccess: action.payload.result.message,
        updateReqFail: null,
      };

    case repairsActionTypes.UPDATE_REPAIR_REQUEST_FAILURE:
      return {
        ...state,
        isupdatingReq: false,
        updateReqSuccess: null,
        updateReqFail: action.payload,
      };

    default:
      return state;
  }
};
