import { useEffect } from "react";
import { useState } from "react";
// import { GET_ALL_HELP_VIDEO } from "utils/utils";
import { axiosInstance as axios } from "services";
import { getEndPoints } from "api/api";
import { ApiEndpoints } from "lib/config/baseSettings";
const url = getEndPoints(process.env.REACT_APP_MODE);
const { GET_ALL_HELP_VIDEO } = ApiEndpoints.SCHEDULE_MODAL;

const useHelpAll = () => {
  const [allHelp, setAllHelp] = useState([]);

  useEffect(() => {
    getAllHelp();
  }, []);

  const getAllHelp = () => {
    return axios
      .get(`${url}${GET_ALL_HELP_VIDEO}`)

      .then((response) => {
        setAllHelp(response?.data?.result);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  return {
    setAllHelp,
    allHelp,
  };
};
export default useHelpAll;
