import React from "react";
import styles from "lib/config/baseStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { MailOutline } from "react-ionicons";
import { FcLock } from "react-icons/fc";
import { Row, Col } from "react-bootstrap";

export const QuoteProposalLayout = ({
  children,
  proposalsData,
  changeProposalId,
  currentProposal,
}) => (
  <Row style={{ marginRight: 0 }}>
    <Col style={{ ...styles.reduceWidthCol, paddingRight: 0 }}>
      <div />
      <div className="__quote_screen">
        <div className="__quote_proposals">
          <div style={{ fontWeight: "bold" }}>
            Proposals ({proposalsData.length})
          </div>
          <div className="__quote_proposals_list">
            <ProposalList
              currentProposal={currentProposal}
              proposalsData={proposalsData}
              changeProposalId={changeProposalId}
            />
          </div>
        </div>
        {children}
      </div>
    </Col>
  </Row>
);

const ProposalList = ({ proposalsData, changeProposalId, currentProposal }) => {
  return (
    <>
      <div style={{ height: "10px" }} />
      <p className="proposal-grp-lablel">Contract</p>
      <ul style={{ marginBottom: "10px" }}>
        {proposalsData.map(
          (proposal, idx) =>
            proposal.makeContract &&
            !proposal.proposalsNotSent &&
            proposal.proposalsSent && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ProposalItem
                  active={currentProposal === proposal._id}
                  idx={idx}
                  key={proposal._id}
                  changeProposalId={changeProposalId}
                  data={proposal}
                />
                <FontAwesomeIcon icon={faHandshake} />
              </div>
            )
        )}
      </ul>
      <p className="proposal-grp-lablel">Sent</p>
      <ul style={{ marginBottom: "10px" }}>
        {proposalsData.map(
          (proposal, idx) =>
            proposal.proposalsSent &&
            !proposal.proposalsNotSent &&
            !proposal.makeContract && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ProposalItem
                  active={currentProposal === proposal._id}
                  idx={idx}
                  key={proposal._id}
                  changeProposalId={changeProposalId}
                  data={proposal}
                />
                <MailOutline color={"#00000"} height="1.5rem" width="1.5rem" />
              </div>
            )
        )}
      </ul>
      <p className="proposal-grp-lablel">Not sent</p>
      <ul style={{ marginBottom: "10px" }}>
        {proposalsData.map(
          (proposal, idx) =>
            proposal.proposalsNotSent &&
            !proposal.makeContract &&
            !proposal.proposalsSent && (
              <ProposalItem
                active={currentProposal === proposal._id}
                idx={idx}
                key={proposal._id}
                changeProposalId={changeProposalId}
                data={proposal}
              />
            )
        )}
      </ul>
    </>
  );
};

const ProposalItem = ({ idx, data, changeProposalId, active }) => (
  <li
    role="button"
    aria-label={data.quoteName}
    onClick={() => changeProposalId(data._id, idx)}
    className={active ? "active" : ""}
    title={data?.quoteName}
  >
    {data?.isLocked && <FcLock size={20} />}
    {data.quoteName}
  </li>
);
