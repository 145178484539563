import materialPricingCompany from "services/offlineComputing/models/materialPricingCompany";
import {
  getGuttersMetrics,
  getRoofMetrics,
} from "services/offlineComputing/models/metricsModel";
import projectPaymentDetailsModel from "services/offlineComputing/models/projectPaymentDetails";
import projectsModel from "services/offlineComputing/models/projects";
import projectsCrewsModel from "services/offlineComputing/models/projectsCrews";
import quoteDebrisModel from "services/offlineComputing/models/quoteDebris";
import quoteGuttersModel from "services/offlineComputing/models/quoteGutters";
import quoteLaborModel from "services/offlineComputing/models/quoteLabor";
import { checkProposalData } from "services/offlineComputing/services/generateProposal";
import { getData, saveData } from "services/storageManager";

const { default: quoteModel } = require("services/offlineComputing/models/quote");
const {
  default: quoteMaterialModel,
} = require("services/offlineComputing/models/quoteMaterial");
const {
  materialSectionAllData,
} = require("services/offlineComputing/services/quoteMaterial");

const calculateTotal = (sectionData) => {
  let total = 0;
  if (sectionData.length > 0) {
    total = sectionData.reduce((acc, data) => {
      return (acc = Number.parseFloat(acc) + Number.parseFloat(data.total));
    }, 0);
  }

  return Number.parseFloat(total).toFixed(2);
};

export const materialSection = async (props) => {
  const { supplierId, metricId, proposalId, companyId, _id } = props;

  console.log(
    "material section props",
    supplierId,
    metricId,
    proposalId,
    companyId,
    _id
  );

  const proposalData = await checkProposalData({
    _id: proposalId,
    requestId: metricId,
  });

  const { excludedBuildings, excludedSections } = proposalData;

  const responseDataDb = await quoteMaterialModel.find({
    proposalId,
    requestId: metricId,
    supplierId,
    companyId,
  });
  // .populate("proposalId");    find corresponding proposal

  if (responseDataDb && responseDataDb.length > 0) {
    // const { isLocked, isLockedTime, materialSubTotal, total, stateTax, sectionData, _id, updatedAt, proposalId } =
    let response = responseDataDb[0];
    return {
      proposalId: proposalData,
      sectionData: response.sectionData,
      materialSubTotal: Number(response.materialSubTotal).toFixed(2),
      dataId: response._id,
      total: Number(
        response.materialSubTotal * (1 + proposalData.stateTax / 100)
      ).toFixed(2),
    };
  }

  const responseData = await materialSectionAllData(
    metricId,
    supplierId,
    companyId,
    excludedBuildings,
    excludedSections
  );

  let newData = {
    requestId: metricId,
    sectionData: responseData,
    supplierId,
    companyId,
    proposalId,
    userId: _id,
  };

  newData.materialSubTotal = calculateTotal(responseData);
  newData.total = Number(0).toFixed(2);

  newData._id = await quoteMaterialModel.save(newData);

  const responseDatas = await quoteMaterialModel.find({
    proposalId,
    requestId: metricId,
    supplierId,
    companyId,
  });

  let response = responseDatas[0];

  let obj = {
    sectionData: responseData,
    dataId: newData._id, //later
    materialSubTotal: Number(newData.materialSubTotal).toFixed(2),
    proposalId: proposalData,
    total:
      proposalData.quoteName === "As-Collected"
        ? Number(proposalData.total).toFixed(2)
        : Number(
            response.materialSubTotal * (1 + proposalData.stateTax / 100)
          ).toFixed(2),
  };

  return obj;
};

export const editMaterialSection = async (props) => {
  const {
    dataId,
    sectionData,
    stateTax,
    taxExemptId,
    proposalId,
    companyId,
    _id,
  } = props;

  const responseData = await quoteMaterialModel.find({
    _id: dataId,
    companyId,
  });
  if (responseData && responseData.length > 0) {
    if (responseData[0].userId !== _id) {
      console.error(messages.NO_AUTHORIZES_PERMISSION_MESSAGE);
      return;
    }
  }

  let data = {
    sectionData,
  };
  data =
    stateTax && stateTax !== ""
      ? { ...data, stateTax: Number.parseFloat(stateTax) }
      : data;
  data = taxExemptId !== "" ? { ...data, taxExemptId } : data;
  let materialSubTotal = 0;
  let total = 0;
  materialSubTotal = Number.parseFloat(calculateTotal(sectionData)).toFixed(2);

  if (stateTax && Number.parseFloat(stateTax) !== 0) {
    total =
      Number.parseFloat(materialSubTotal) +
      (stateTax / 100) * Number.parseFloat(materialSubTotal);
  } else if (stateTax && Number.parseFloat(stateTax) === 0) {
    total = materialSubTotal;
  } else {
    total = 0;
  }

  data.materialSubTotal = materialSubTotal;
  data.total = Number.parseFloat(total).toFixed(2);

  if (proposalId) {
    await quoteMaterialModel.updateOne(
      {
        _id: dataId,
      },
      data
    );

    await quoteModel.updateOne(
      { _id: proposalId },
      { stateTax: stateTax, total: data.total }
    );
  } else {
    await quoteMaterialModel.updateOne(
      {
        _id: dataId,
      },
      data
    );
  }

  let quote_proposal_data = await quoteModel.findOne({
    _id: proposalId,
  });

  return {
    ...data,
    dataId,
    proposalId: quote_proposal_data,
  };
};

const getMaterialPricing = async (o, q) => {
  const query = { ...q, ...o };

  const materialPricing = await materialPricingCompany.find(query);

  return materialPricing;
};

export const getMaterialsPricing = async (props) => {
  const { supplierId, companyId, postData } = props;

  const pricings = await getMaterialPricing(
    { companyId, supplierId },
    postData.q
  );

  return pricings;
};

export const editIsLocked = async (props) => {
  const { supplierId, metricId, proposalId, postData, companyId, _id } = props;

  try {
    //let deposit_data = await contract.findOne({ leadRequestId: metricId });
    let quote_proposal_data = await quoteModel.findOne({
      _id: proposalId,
    });
    let quote_material_data = await quoteMaterialModel.findOne({
      proposalId: proposalId,
    });
    let quote_labor_data = await quoteLaborModel.findOne({
      proposalId: proposalId,
    });
    let quote_gutter_data = await quoteGuttersModel.findOne({
      proposalId: proposalId,
    });
    let quote_debris_data = await quoteDebrisModel.findOne({
      proposalId: proposalId,
    });

    let payment_details_obj = {
      total: quote_proposal_data.total,
      proposalId: proposalId,
    };

    let crew_details_obj = {
      proposalId: proposalId,
      crewSupplierId: quote_labor_data?.supplierId
        ? [quote_labor_data.supplierId]
        : null,
      cartingSupplierId: quote_debris_data?.supplierId
        ? [quote_debris_data.supplierId]
        : null,
      materialSupplierId: quote_material_data?.supplierId
        ? [quote_material_data.supplierId]
        : null,
      gutterSupplierId: quote_gutter_data?.supplierId
        ? [quote_gutter_data.supplierId]
        : null,
    };

    // let metric_data = await metric.find({ requestId: metricId });
    // await metric.updateMany(
    //   { requestId: metricId },
    //   { isLocked: true },
    //   { session }
    // );
    //////transform here
    let metric_data = [];
    const roofMetric = getRoofMetrics(metricId); //getData(`metrics${metricId}`);
    const gutterMetric = getGuttersMetrics(metricId); //getData(`Gmetrics${metricId}`);

    let type = "";

    if (roofMetric !== null) {
      metric_data.push(roofMetric.data);

      roofMetric.data.isLocked = true;
      type = "roof";
      saveData(`metrics${metricId}`, roofMetric);
    }
    if (gutterMetric !== null) {
      metric_data.push(gutterMetric.data);

      gutterMetric.data.isLocked = true;
      type = "gutter";
      saveData(`Gmetrics${metricId}`, gutterMetric);
    }

    await quoteModel.updateOne({ _id: proposalId }, postData);
    await projectPaymentDetailsModel.save(payment_details_obj); //add model
    await projectsCrewsModel.save(crew_details_obj); //add model

    if (metric_data.length === 1) {
      await projectsModel.save({
        leadRequestId: metricId,
        addedByUserCompany: companyId,
        addedByUser: _id,
        type: type,
        proposalId: proposalId,
      });
    }
    if (metric_data.length === 2) {
      await projectsModel.save({
        leadRequestId: metricId,
        addedByUserCompany: companyId,
        addedByUser: _id,
        type: "roof",
        proposalId: proposalId,
      });
      await projectsModel.save({
        leadRequestId: metricId,
        addedByUserCompany: companyId,
        addedByUser: _id,
        type: "gutter",
        proposalId: proposalId,
      });
    }

    return true;
  } catch (error) {
    console.log({ error });
    return error;
  }
};
