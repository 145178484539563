import React, { useState, useEffect } from "react";
import "./Suppliers.scss";
import validator from "validator";

import { Row, Col, Button, Form } from "react-bootstrap";

import {
  getMaterialOptions,
  getPreferedMaterial,
  getPricingMaterial,
  patchMSuppliers,
  resetPreferdMaterial,
  resetPricingMaterial,
} from "lib/redux/suppliers/suppliers.action";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { LoadingLoader } from "components";

import ListData, {
  NewMiscListData,
  MiscListData,
  NewListData,
} from "./ListData";
import { EmptyData } from "./commonComponents";
import RequiredText from "components/required-text/required-text";

export function MiscPriceList({
  data,
  customItemDefaultValue,
  addItem,
  setAddItem,
  newData,
  uomOptions,
  suppliersId,
  customItem,
}) {
  const [hide, setHide] = useState(false);
  const [dataList, setDataList] = useState([...data]);
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [disable, setDisable] = useState(true);

  const [prevData, setPrevData] = useState([]);

  const handleChange = (e, callback) => {
    callback(e.target.checked);
  };
  useEffect(() => {
    if (hide) {
      setPrevData(dataList);
      setDataList(
        dataList.filter((priceData) => Number(priceData.uomPrice) !== 0)
      );
    } else if (prevData.length !== 0) {
      setDataList([...prevData]);
    }
  }, [hide]);

  const changePrice = (id, value) => {
    const newList = [...dataList];
    newList.splice(id, 1, {
      ...dataList[id],
      ...value,
    });
    setDataList(newList);
  };

  const handleSave = () => {
    if (dataList.length === 0) return;
    setProcessing(true);
    setDisable(true);
    const toastId = toast.info("Updating data. Please Wait...!", {
      autoClose: false,
    });

    const invalidInputs = dataList.some(
      (priceData) => !validator.isNumeric(`${priceData.uomPrice}`)
    );
    if (invalidInputs) {
      toast.dismiss(toastId);
      toast.error("Please enter valid price.");
      setProcessing(false);
    } else {
      dataList.map((priceData, idx) => {
        dispatch(
          patchMSuppliers(priceData._id, {
            uomPrice: Number(priceData.uomPrice).toFixed(2),
          })
        )
          .then((res) => res.data)
          .then((res) => {
            if (res.success) {
              if (idx === dataList.length - 1) {
                toast.dismiss(toastId);
                toast.success("Data updated successfully.");
              }
            } else {
              toast.dismiss(toastId);
              toast.error(res.error);
            }
          })
          .catch((err) => {
            toast.dismiss(toastId);
            toast.error(err.message);
          })
          .finally(() => {
            setProcessing(false), setDisable(true);
          });
      });
    }
  };

  const delData = (id) => {
    const idx = dataList.findIndex((info) => info._id === id);
    const newList = [...dataList];
    newList.splice(idx, 1);
    setDataList(newList);
    return true;
  };

  const displayList = () =>
    dataList.length > 0
      ? dataList.map((priceData, idx) => (
          <MiscListData
            setAddItem={setAddItem}
            setDataList={setDataList}
            dataList={dataList}
            customItemDefaultValue={customItemDefaultValue}
            newData={newData}
            uomOptions={uomOptions}
            delData={delData}
            key={priceData._id}
            _id={priceData._id}
            priceData={priceData}
            idx={idx}
            isNewData={false}
            changePrice={changePrice}
            setDisabl={setDisable}
          />
        ))
      : !addItem && (
          <div className="_tab_msg">
            <p className="__msg">No records are available.</p>
          </div>
        );

  return (
    <Row>
      <Col>
        <Row>
          <Col />
          <Col lg="3" />
          <Col />

          {/* <Col style={{ textAlign: "right" }} lg="2">
            <Form.Group>
              <Form.Check
                style={{ marginBottom: "1em" }}
                name="hide"
                checked={hide}
                onChange={(e) => handleChange(e, setHide)}
                label="Hide non-priced item"
              />
            </Form.Group>
          </Col> */}
          <Col />
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }}>
            <Form.Label>Manufacturer</Form.Label>
          </Col>
          <Col lg="3" style={{ textAlign: "center" }}>
            <Form.Label>
              Item Description {customItem && <RequiredText>*</RequiredText>}
            </Form.Label>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <Form.Label>
              UOM {customItem && <RequiredText>*</RequiredText>}
            </Form.Label>
          </Col>
          <Col style={{ textAlign: "center" }} lg="2">
            <Form.Label>Price Per UOM</Form.Label>
          </Col>
          <Col />
        </Row>
        {processing ? <LoadingLoader /> : displayList()}

        {addItem && (
          <NewMiscListData
            setAddItem={setAddItem}
            setDataList={setDataList}
            dataList={dataList}
            customItemDefaultValue={customItemDefaultValue}
            newData={newData}
            uomOptions={uomOptions}
            isNewData
            suppliersId={suppliersId}
          />
        )}

        <Row
          style={{
            // justifyContent: "flex-end",
            margin: "2em 0",
            float: "right",
          }}
        >
          <div style={{ margin: "0 1em" }}>
            <Button variant="danger" style={{ marginRight: "1em" }}>
              Cancel
            </Button>

            <Button disabled={disable} variant="success" onClick={handleSave}>
              Save
            </Button>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export function PriceList({
  data,
  isPreferred,
  preferred,
  setPreferred,
  hasPreferedOption,
  multiPreferedOption,
  customItemDefaultValue,
  addItem,
  setAddItem,
  newData,
  suppliersId,
  customItem,
  materialPointerEventViewOnly,
}) {
  const [hide, setHide] = useState(false);
  const [dataList, setDataList] = useState([...data]);
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [disable, setDisable] = useState(true);

  const [prevData, setPrevData] = useState([]);
  const showPreferred = hasPreferedOption || multiPreferedOption;
  const lgSize = showPreferred ? 3 : 5;

  const handleChange = (e, callback) => {
    callback(e.target.checked);
  };
  useEffect(() => {
    if (hide) {
      setPrevData(dataList);
      setDataList(
        dataList.filter((priceData) => Number(priceData.uomPrice) !== 0)
      );
    } else if (prevData.length !== 0) {
      setDataList([...prevData]);
    }
  }, [hide]);

  const changePrice = (id, value) => {
    const newList = [...dataList];
    newList.splice(id, 1, {
      ...dataList[id],
      ...value,
    });
    setDataList(newList);
  };

  const handleSave = () => {
    if (dataList.length === 0) return;
    setProcessing(true);
    setDisable(true);
    const toastId = toast.info("Updating data. Please Wait...!", {
      autoClose: false,
    });

    const invalidInputs = dataList.some(
      (priceData) => !validator.isNumeric(priceData.uomPrice.toString())
    );
    if (invalidInputs) {
      toast.dismiss(toastId);
      toast.error("Please enter valid price.");
      setProcessing(false);
    } else {
      dataList.map((priceData, idx) => {
        dispatch(
          patchMSuppliers(priceData._id, {
            uomPrice: Number(priceData.uomPrice).toFixed(2),
            isPreferred: priceData.isPreferred,
          })
        )
          .then((res) => res.data)
          .then((res) => {
            if (res.success) {
              if (idx === dataList.length - 1) {
                toast.dismiss(toastId);
                toast.success("Data updated successfully.");
              }
            } else {
              toast.dismiss(toastId);
              toast.error(res.error);
            }
          })
          .catch((err) => {
            toast.dismiss(toastId);
            toast.error(err.message);
          })
          .finally(() => {
            setProcessing(false), setDisable(true);
          });
      });
    }
  };

  const delData = (id) => {
    const idx = dataList.findIndex((info) => info._id === id);
    const newList = [...dataList];
    newList.splice(idx, 1);
    setDataList(newList);
    return true;
  };
  const displayList = () =>
    dataList.length > 0
      ? dataList.map((priceData, idx) => (
          <ListData
            lgSize={lgSize}
            setDataList={setDataList}
            dataList={dataList}
            hasPreferedOption={hasPreferedOption}
            multiPreferedOption={multiPreferedOption}
            customItemDefaultValue={customItemDefaultValue}
            key={priceData._id}
            _id={priceData._id}
            preffered={priceData.isPreferred}
            setPreferred={setPreferred}
            preferredData={preferred}
            price={priceData.uomPrice}
            series={priceData.series}
            uom={priceData.uom}
            uomPerSq={priceData.uomValue}
            changePrice={changePrice}
            showUOM={data[0].uomType !== "N/A"}
            id={idx}
            priceData={priceData}
            isCustom={priceData.isCustom}
            setAddItem={setAddItem}
            delData={delData}
            showPreferred={showPreferred}
            setProcessingg={setDisable}
            setDisable={setDisable}
          />
        ))
      : !addItem && (
          <div className="_tab_msg">
            <p className="__msg">No records availabe.</p>
          </div>
        );

  return (
    <Row>
      <Col>
        <Row>
          {showPreferred && <Col md="1" />}
          <Col lg={lgSize} />
          <Col />
          {data[0].uomType !== "N/A" && <Col />}
          {/* <Col style={{ textAlign: "right" }} lg="2">
            <Form.Group>
              <Form.Check
                style={{ marginBottom: "1em" }}
                name="hide"
                checked={hide}
                onChange={(e) => handleChange(e, setHide)}
                label="Hide non-priced item"
              />
            </Form.Group>
          </Col> */}
          <Col />
        </Row>
        <Row>
          {showPreferred && (
            <Col md="1" style={{ textAlign: "center" }}>
              <Form.Label>Preferred</Form.Label>
            </Col>
          )}
          <Col lg={lgSize} style={{ textAlign: "center" }}>
            <Form.Label>
              Series {customItem && <RequiredText>*</RequiredText>}
            </Form.Label>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <Form.Label>UOM</Form.Label>
          </Col>
          {data[0].uomType !== "N/A" && (
            <Col style={{ textAlign: "center" }}>
              <Form.Label>{data[0].uomType}</Form.Label>
            </Col>
          )}
          <Col style={{ textAlign: "center" }} lg="2">
            <Form.Label>Price Per UOM</Form.Label>
          </Col>
          <Col />
        </Row>
        {processing ? <LoadingLoader /> : displayList()}

        {addItem && (
          <NewListData
            lgSize={lgSize}
            setAddItem={setAddItem}
            setDataList={setDataList}
            dataList={dataList}
            showUOM={data[0].uomType !== "N/A"}
            customItemDefaultValue={customItemDefaultValue}
            newData={newData}
            suppliersId={suppliersId}
            showPreferred={showPreferred}
          />
        )}

        <Row
          style={{
            // justifyContent: "flex-end",
            margin: "1em 0",
            float: "right",
          }}
        >
          <div style={{ margin: "0 1em" }}>
            <Button variant="danger" style={{ marginRight: "1em" }}>
              Cancel
            </Button>

            <Button disabled={disable} variant="success" onClick={handleSave}>
              Save
            </Button>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export function PricingList({ selectedSupp, materialPointerEventViewOnly }) {
  const [category, setCategory] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [type, setType] = useState("");
  const [subType, setSubType] = useState("");
  const [preferred, setPreferred] = useState([]);
  const [preferredData, setPreferredData] = useState([]);

  const [series, setSeries] = useState("");
  const { data, errors, isLoading } = useSelector((state) => state.staticAll);
  const { gettingPMaterial, pMaterialData, pMaterialErr } = useSelector(
    (state) => state.materialPricing
  );
  const {
    gettingMSuppliersoPTIONS,
    mSuppliersOptionsData,
    mSuppliersOptionsErr,
  } = useSelector((state) => state.materialSuppliersOptions);
  /**
   * useselector for preferdd
   */
  const { gettingPreMaterial, preMaterialData, preMaterialErr } = useSelector(
    (state) => state.materialPreferd
  );
  const dispatch = useDispatch();

  const [pricing, setPricing] = useState([]);
  const [matchData, setMatchData] = useState(null);
  const [matchType, setMatchType] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [typesList, setTypesList] = useState([]);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [subName, setSubName] = useState("");

  const [isMisc, setIsMisc] = useState(false);
  const [hasPreferedOption, setHasPreferedOption] = useState(false);
  const [multiPreferedOption, setMultiPreferedOption] = useState(false);
  const [customItem, setCustomItem] = useState(false);
  const [customItemDefaultValue, setCustomItemDefaultValue] = useState(null);
  const [addItem, setAddItem] = useState(false);
  const [uomOptions, setUomOptions] = useState(false);
  const [singleTypePrefered, setSingleTypePrefered] = useState(false);

  useEffect(() => {
    dispatch(getMaterialOptions());
  }, []);
  useEffect(() => {
    if (pMaterialData) {
      return setPreferred(pMaterialData.materialPreferred);
    }
    setPreferred([]);
  }, [pMaterialData]);
  /**
   * useeffect for preferd data
   */
  useEffect(() => {
    if (preMaterialData) {
      return setPreferredData(preMaterialData?.materialPreferred);
    }
    setPreferredData([]);
  }, [preMaterialData]);
  useEffect(() => {
    if (mSuppliersOptionsData) {
      setPricing(mSuppliersOptionsData);
    }
  }, [mSuppliersOptionsData]);

  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);

  useEffect(() => {
    setAddItem(false);
    setManufacturerList([]);
    setSubTypes([]);
    setTypesList([]);
    setSubName("");
    setManufacturer("");
    setType("");
    setSubType("");
    setSeries("");
    setMatchData(null);
    setMatchType(null);
    setCategory("");
    setCustomItem(false);
    resetPricingMaterialData();
    resetPreferdMaterialData();
  }, [selectedSupp]);

  const resetPricingMaterialData = () => {
    if (pMaterialData) {
      dispatch(resetPricingMaterial());
    }
  };
  /**
   * reset preperdmaterial
   */
  const resetPreferdMaterialData = () => {
    if (preMaterialData) {
      dispatch(resetPreferdMaterial());
    }
  };
  useEffect(() => {
    setAddItem(false);
    const list = [];
    if (pricing.length > 0) {
      pricing.map((priceData) => list.push(priceData.category));
    }
    setCategoryList(list);
  }, [pricing]);

  useEffect(() => {
    setAddItem(false);
    setManufacturerList([]);
    setSubTypes([]);
    setSubName("");
    setManufacturer("");
    setTypesList([]);
    setType("");
    setSubType("");
    setSeries("");
    setMatchData(null);
    setMatchType(null);
    resetPricingMaterialData();
    resetPreferdMaterialData();
    if (category) {
      dispatch(
        getPreferedMaterial(
          {
            category,
          },
          selectedSupp
        )
      );
      const list = [];
      const match = pricing.find(
        (priceData) => priceData.category === category
      );
      setMatchData(match);
      setIsMisc(match.isMisc);
      match.categoryType.map((keys) => list.push(keys.typeName));

      if (list.length === 1) {
        setType(list[0]);
      } else {
        setTypesList(list);
      }
    } else {
      setMatchData(null);
      setIsMisc(false);
      setTypesList([]);
    }
  }, [category]);
  // commemts
  useEffect(() => {
    setManufacturerList([]);
    setSubTypes([]);
    setSubName("");
    setManufacturer("");
    setSubType("");
    setMatchType(null);
    resetPricingMaterialData();
    resetPreferdMaterialData();
    setAddItem(false);
    if (type) {
      dispatch(
        getPreferedMaterial(
          {
            category,
            categoryType: type,
          },
          selectedSupp
        )
      );
      const match = matchData.categoryType.find(
        (types) => types.typeName === type
      );
      setMatchType(match);
      setCustomItem(match.customItem);
      setCustomItemDefaultValue(match.customItemDefaultValue);
      setMultiPreferedOption(match.multiPreferedOption);
      setHasPreferedOption(match.hasPreferedOption);
      if (category === "Shingles") {
        if (match && match.manufacturersWithSubType.length > 0) {
          let singlesManufacturer = match?.manufacturersWithSubType?.map(
            (a) => a.manufacturer
          );

          setManufacturerList(singlesManufacturer);
        } else if (match && match.subTypeName) {
          setSubName(match.subTypeName);
          setSubTypes(match.subType);
        } else {
          dispatch(
            getPricingMaterial(
              {
                category,
                categoryType: type,
              },
              selectedSupp
            )
          );
        }
      } else {
        if (match && match.manufacturer.length > 0) {
          setManufacturerList(match.manufacturer);
        } else if (match && match.subTypeName) {
          setSubName(match.subTypeName);
          setSubTypes(match.subType);
        } else {
          dispatch(
            getPricingMaterial(
              {
                category,
                categoryType: type,
              },
              selectedSupp
            )
          );
        }
      }
    }
  }, [type]);

  useEffect(() => {
    resetPricingMaterialData();
    setAddItem(false);
    setSubType("");
    if (manufacturer) {
      if (category === "Shingles") {
        if (matchType.subTypeName) {
          let signleSeriesType = matchType.manufacturersWithSubType.filter(
            (elem) => {
              return elem.manufacturer === manufacturer;
            }
          );
          let singleSeries = signleSeriesType?.map((a) => a.subType);
          setSubName(matchType.subTypeName);
          setSubTypes(singleSeries[0]);
        } else {
          dispatch(
            getPricingMaterial(
              {
                category,
                categoryType: type,
                manufacturer,
              },
              selectedSupp
            )
          );
        }
      } else {
        if (matchType.subTypeName) {
          setSubName(matchType.subTypeName);
          setSubTypes(matchType.subType);
        } else {
          dispatch(
            getPricingMaterial(
              {
                category,
                categoryType: type,
                manufacturer,
              },
              selectedSupp
            )
          );
        }
      }
    }
  }, [manufacturer]);

  useEffect(() => {
    resetPricingMaterialData();

    setAddItem(false);
    if (subType) {
      const addData = manufacturer ? { manufacturer } : {};

      dispatch(
        getPricingMaterial(
          {
            category,
            categoryType: type,
            subType,
            ...addData,
          },
          selectedSupp
        )
      );
    }
  }, [subType]);

  useEffect(() => {
    if (isMisc) {
      setCustomItem(matchData.customItem);
      setCustomItemDefaultValue(matchData.customItemDefaultValue);

      setUomOptions(matchData.uomOptions);
      dispatch(
        getPricingMaterial(
          {
            category,
          },
          selectedSupp
        )
      );
    }
  }, [isMisc]);

  const showOptions = (list) =>
    list.length > 0 &&
    list.map((data) => (
      <option value={data} key={data}>
        {data}
      </option>
    ));

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const checkMiscData = () => {
    if (isMisc) {
      return (
        <MiscPriceList
          customItemDefaultValue={customItemDefaultValue}
          data={pMaterialData.materialPricing}
          addItem={addItem}
          setAddItem={setAddItem}
          newData={{
            category,
          }}
          uomOptions={uomOptions}
          suppliersId={selectedSupp}
          customItem={customItem}
          materialPointerEventViewOnl={materialPointerEventViewOnly}
        />
      );
    }
    if (!isMisc) {
      if (pMaterialData.materialPricing.length === 0) {
        return <EmptyData />;
      }
      return (
        <PriceList
          hasPreferedOption={hasPreferedOption}
          multiPreferedOption={multiPreferedOption}
          customItemDefaultValue={customItemDefaultValue}
          data={pMaterialData.materialPricing}
          preferred={preferred}
          setPreferred={setPreferred}
          isPreferred={preferred.length > 0 ? preferred[0]._id : ""}
          addItem={addItem}
          setAddItem={setAddItem}
          newData={{
            category,
            categoryType: type,
            manufacturer,
            subType,
            isPreferred: false,
          }}
          suppliersId={selectedSupp}
          customItem={customItem}
          materialPointerEventViewOnl={materialPointerEventViewOnly}
        />
      );
    }
  };

  const getPreferredCatgeory = (subType, categoryType, type) =>
    subType !== "N/A" ? subType : categoryType === type ? type : categoryType;

  const getList = () => {
    if (gettingPMaterial) {
      return <LoadingLoader />;
    }
    if (pMaterialErr) {
      return (
        <div className="_tab_msg">
          <p className="__msg">{pMaterialErr}</p>
        </div>
      );
    }

    if (pMaterialData && pMaterialData.materialPricing) {
      return (
        <>
          {/* fix for various here if required */}
          {preferred.length > 0 &&
            preferred.map((preferredPricing) => (
              <p className="__msg" key={preferredPricing._id}>
                Preferred{" "}
                {getPreferredCatgeory(
                  preferredPricing.subType,
                  preferredPricing.categoryType,
                  type
                )}
                :{" "}
                {preferredPricing.category !== "Drip Edging"
                  ? preferredPricing.manufacturer
                  : ""}
                {preferredPricing.category !== "Drip Edging" ? "-" : ""}
                {preferredPricing.series}
              </p>
            ))}
          {checkMiscData()}
        </>
      );
    }

    if (preMaterialData && preMaterialData?.materialPreferred) {
      return (
        <>
          {preferredData?.length > 0 &&
            preferredData.map((preferredPricing) => (
              <p className="__msg" key={preferredPricing._id}>
                Preferred{" "}
                {getPreferredCatgeory(
                  preferredPricing.subType,
                  preferredPricing.categoryType,
                  type
                )}
                : {preferredPricing.manufacturer} -{preferredPricing.series}
              </p>
            ))}
        </>
      );
    }
  };
  const handlChaneOnType = () => {
    setSingleTypePrefered(true);
  };

  if (isLoading) {
    return <LoadingLoader />;
  }
  return (
    <div style={{ padding: "1em" }}>
      <Row
        style={{
          justifyContent: "space-between",
          paddingBottom: "2em",
          paddingTop: "1em",
        }}
      >
        <Row>
          <Col md="3">
            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="pos"
                value={category}
                onChange={(event) => handleChange(event, setCategory)}
              >
                <option value="">Choose...</option>
                {showOptions(categoryList)}
              </Form.Control>
            </Form.Group>
          </Col>
          {category && typesList.length > 0 && (
            <Col md="3">
              <Form.Group>
                <Form.Label className="typelabel">{category} Type</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={type}
                  onChange={(event) => {
                    handleChange(event, setType), handlChaneOnType();
                  }}
                >
                  <option value="">Choose...</option>
                  {showOptions(typesList)}
                </Form.Control>
              </Form.Group>
            </Col>
          )}
          {type && manufacturerList.length > 0 && (
            <Col md="3">
              <Form.Group>
                <Form.Label>Manufacturer</Form.Label>
                <Form.Control
                  as="select"
                  name="manufacturer"
                  value={manufacturer}
                  onChange={(event) => handleChange(event, setManufacturer)}
                >
                  <option value="">Choose...</option>
                  {showOptions(manufacturerList)}
                </Form.Control>
              </Form.Group>
            </Col>
          )}
          {subName && subTypes && subTypes.length > 0 && (
            <Col md="3">
              <Form.Group>
                <Form.Label>
                  {subName === "subType" ? "Type" : subName}
                </Form.Label>
                <Form.Control
                  as="select"
                  name="subType"
                  value={subType}
                  onChange={(event) => handleChange(event, setSubType)}
                >
                  <option value="">Choose...</option>
                  {showOptions(subTypes)}
                </Form.Control>
              </Form.Group>
            </Col>
          )}
          {customItem && (
            <Col>
              <div style={{ float: "right" }}>
                <Button
                  disabled={addItem || !materialPointerEventViewOnly}
                  onClick={() => setAddItem(true)}
                >
                  Add New Item
                </Button>
                <div
                  style={{
                    width: "99%",
                    textAlign: "right",
                    marginTop: "10%",
                    marginRight: "5%",
                  }}
                >
                  <RequiredText>* Required</RequiredText>
                </div>
              </div>
            </Col>
          )}
        </Row>
        {/* {customItem && (
          <Row>
            <Col>
              <div style={{ float: "right" }}>
                <Button disabled={addItem} onClick={() => setAddItem(true)}>
                  Add New Item
                </Button>
              </div>
            </Col>
          </Row>
        )} */}
      </Row>
      {type === "Skylight" && (
        <p className="__msg">To add a skylight, click on Add New Item.</p>
      )}
      {getList()}
    </div>
  );
}
