import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

export default function SweetAlertComp({
	title,
	placeHolder,
	autoComplete,
	onConfirm,
	onCancel,
	inputType,
	children,
}) {
	return (
		<SweetAlert
			input
			inputType={inputType}
			showCancel
			cancelBtnBsStyle='default'
			title={title}
			placeHolder={placeHolder}
			autoComplete={autoComplete}
			onConfirm={onConfirm}
			onCancel={onCancel}
		>
			{children}
		</SweetAlert>
	);
}
