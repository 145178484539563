export const FLOOR = [
  { id: "floor001", name: "First" },
  { id: "floor002", name: "Second" },
  { id: "floor003", name: "Third" },
];

export const ELEVATION = [
  { id: "001", name: "Front" },
  { id: "002", name: "Back" },
  { id: "003", name: "Left" },
  { id: "004", name: "Right" },
];
export const ELEVATIONDETAILS = [
  { id: "001", name: "Main House" },
  { id: "002", name: "Garage" },
  { id: "003", name: "Barn" },
  { id: "004", name: "GST/HSE" },
  { id: "005", name: "Cabana" },
];

export const DIFFICULTY = [
  { id: "001", name: "Flat" },
  { id: "002", name: "Non-Walkie" },
  { id: "003", name: "Walkie" },
];

export const getDifficulty = {};
getDifficulty[`${DIFFICULTY[0].id}`] = `${DIFFICULTY[0].name}`;
getDifficulty[`${DIFFICULTY[1].id}`] = `${DIFFICULTY[1].name}`;
getDifficulty[`${DIFFICULTY[2].id}`] = `${DIFFICULTY[2].name}`;

export const WORKTYPE = [
  { id: "001", name: "New" },
  { id: "002", name: "Overlay" },
  { id: "003", name: "Remove" },
];

export const getWorkType = {};
getWorkType[`${WORKTYPE[0].id}`] = `${WORKTYPE[0].name}`;
getWorkType[`${WORKTYPE[1].id}`] = `${WORKTYPE[1].name}`;
getWorkType[`${WORKTYPE[2].id}`] = `${WORKTYPE[2].name}`;

export const ELEVATIONOTHER = [
  { id: "001", name: "Apron" },
  { id: "002", name: "Rake" },
  { id: "003", name: "Dormer" },
  { id: "004", name: "Extension" },
  { id: "005", name: "BRZ-Way" },
  { id: "006", name: "Gable" },
  { id: "007", name: "REV Gable" },
  { id: "008", name: "Overhang" },
  { id: "009", name: "Flat Roof" },
  { id: "010", name: "Portico" },
  { id: "011", name: "Mansard" },
  { id: "012", name: "Turret" },
  { id: "100", name: "Other", other: true },
];

export const METALSHEET = [
  {
    name: "Aluminum",
    id: "METALSHEET010",
  },
  {
    name: "Cooper",
    id: "METALSHEET011",
  },
];

export const DIMENSIONNAME = {
  METALSHEET010: {
    name: "Guage",
  },
  METALSHEET011: {
    name: "Ounce",
  },
};

export const METALSHEETDATA = {
  METALSHEET010: [
    [
      {
        name: ".032 (4X8)",
        id: "METALSHEET000",
      },
      {
        name: ".032 (4X10)",
        id: "METALSHEET001",
      },
      {
        name: ".040 (4X8)",
        id: "METALSHEET002",
      },
      {
        name: ".040 (4X10)",
        id: "METALSHEET003",
      },
    ],
  ],
  METALSHEET011: [
    [
      {
        name: "16oz (3X8)",
        id: "METALSHEET004",
      },
      {
        name: "16oz (3X10)",
        id: "METALSHEET005",
      },
      {
        name: "32oz (3X8)",
        id: "METALSHEET006",
      },
      {
        name: "32oz (3X10)",
        id: "METALSHEET007",
      },
    ],
  ],
};

export const getMetalSheet = {};
getMetalSheet[`${METALSHEET[0].id}`] = `${METALSHEET[0].name}`;
getMetalSheet[`${METALSHEET[1].id}`] = `${METALSHEET[1].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET010"][0][0].id}`
] = `${METALSHEETDATA["METALSHEET010"][0][0].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET010"][0][1].id}`
] = `${METALSHEETDATA["METALSHEET010"][0][1].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET010"][0][2].id}`
] = `${METALSHEETDATA["METALSHEET010"][0][2].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET010"][0][3].id}`
] = `${METALSHEETDATA["METALSHEET010"][0][3].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET011"][0][0].id}`
] = `${METALSHEETDATA["METALSHEET011"][0][0].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET011"][0][1].id}`
] = `${METALSHEETDATA["METALSHEET011"][0][1].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET011"][0][2].id}`
] = `${METALSHEETDATA["METALSHEET011"][0][2].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET011"][0][3].id}`
] = `${METALSHEETDATA["METALSHEET011"][0][3].name}`;

export const COMMONNAILS = [
  [
    {
      name: "Steel",
      id: "COMMONNAILS001",
    },
    {
      name: "Galvanized",
      id: "COMMONNAILS002",
    },
  ],
  [
    {
      name: `6d - 2"`,
      id: "CN003",
    },
    {
      name: `8d - 2.5"`,
      id: "CN004",
    },
    {
      name: `10d - 3"`,
      id: "CN005",
    },
    {
      name: `16d - 3.5"`,
      id: "CN006",
    },
  ],
];

export const getCommonNails = {};
getCommonNails[`${COMMONNAILS[0][0].id}`] = `${COMMONNAILS[0][0].name}`;
getCommonNails[`${COMMONNAILS[0][1].id}`] = `${COMMONNAILS[0][1].name}`;
getCommonNails[`${COMMONNAILS[1][0].id}`] = `${COMMONNAILS[1][0].name}`;
getCommonNails[`${COMMONNAILS[1][1].id}`] = `${COMMONNAILS[1][1].name}`;
getCommonNails[`${COMMONNAILS[1][2].id}`] = `${COMMONNAILS[1][2].name}`;
getCommonNails[`${COMMONNAILS[1][3].id}`] = `${COMMONNAILS[1][3].name}`;

export const HANDNAILS = [
  [
    {
      name: "Copper",
      id: "HANDNAILS001",
    },
    {
      name: "Galvanized",
      id: "HANDNAILS002",
    },
    {
      name: "Stainless",
      id: "HANDNAILS003",
    },
  ],
  [
    {
      name: `1"`,
      id: "HANDNAILS004",
    },
    {
      name: `1.25"`,
      id: "HANDNAILS005",
    },
    {
      name: `1.5"`,
      id: "HANDNAILS006",
    },
    {
      name: `1.75"`,
      id: "HANDNAILS007",
    },
    {
      name: `2"`,
      id: "HANDNAILS008",
    },
  ],
];

export const getHandsNails = {};
getHandsNails[`${HANDNAILS[0][0].id}`] = `${HANDNAILS[0][0].name}`;
getHandsNails[`${HANDNAILS[0][1].id}`] = `${HANDNAILS[0][1].name}`;
getHandsNails[`${HANDNAILS[0][2].id}`] = `${HANDNAILS[0][2].name}`;

getHandsNails[`${HANDNAILS[1][0].id}`] = `${HANDNAILS[1][0].name}`;
getHandsNails[`${HANDNAILS[1][1].id}`] = `${HANDNAILS[1][1].name}`;
getHandsNails[`${HANDNAILS[1][2].id}`] = `${HANDNAILS[1][2].name}`;
getHandsNails[`${HANDNAILS[1][3].id}`] = `${HANDNAILS[1][3].name}`;
getHandsNails[`${HANDNAILS[1][4].id}`] = `${HANDNAILS[1][4].name}`;

export const CHIMENYCAPS = [
  [
    {
      name: "Round",
      id: "CHIMENYCAPS001",
      other: "inches",
    },
    {
      name: "Square",
      id: "CHIMENYCAPS002",
      other: "!inches",
    },
    {
      name: "Rectangle",
      id: "CHIMENYCAPS003",
      other: "!inches",
    },
  ],
  [
    {
      name: "Aluminum",
      id: "CHIMENYCAPS004",
    },
    {
      name: "Copper",
      id: "CHIMENYCAPS005",
    },
    {
      name: "Galvanized",
      id: "CHIMENYCAPS006",
    },
    {
      name: "Stainless",
      id: "CHIMENYCAPS007",
    },
  ],
];

export const getChimneyCaps = {};
getChimneyCaps[`${CHIMENYCAPS[0][0].id}`] = `${CHIMENYCAPS[0][0].name}`;
getChimneyCaps[`${CHIMENYCAPS[0][1].id}`] = `${CHIMENYCAPS[0][1].name}`;
getChimneyCaps[`${CHIMENYCAPS[0][2].id}`] = `${CHIMENYCAPS[0][2].name}`;
getChimneyCaps[`${CHIMENYCAPS[1][0].id}`] = `${CHIMENYCAPS[1][0].name}`;
getChimneyCaps[`${CHIMENYCAPS[1][1].id}`] = `${CHIMENYCAPS[1][1].name}`;
getChimneyCaps[`${CHIMENYCAPS[1][2].id}`] = `${CHIMENYCAPS[1][2].name}`;
getChimneyCaps[`${CHIMENYCAPS[1][3].id}`] = `${CHIMENYCAPS[1][3].name}`;

export const METAL = [
  [
    {
      name: "Apron",
      id: "METAL001",
    },
    {
      name: "J-Bend",
      id: "METAL002",
    },
    {
      name: "Gravel Stop",
      id: "METAL003",
    },
  ],
];

export const getMetal = {};
getMetal[`${METAL[0][0].id}`] = `${METAL[0][0].name}`;
getMetal[`${METAL[0][1].id}`] = `${METAL[0][1].name}`;
getMetal[`${METAL[0][2].id}`] = `${METAL[0][2].name}`;

getMetal.isApron = (id) => getMetal[id] === getMetal[METAL[0][0].id];
getMetal.isJBend = (id) => getMetal[id] === getMetal[METAL[0][1].id];
getMetal.isGravelStop = (id) => getMetal[id] === getMetal[METAL[0][2].id];

export const CAPPING = [
  [
    {
      name: "Flat",
      id: "CAPPING001",
    },
    {
      name: "PVC",
      id: "CAPPING002",
    },
    {
      name: "Mar-Free",
      id: "CAPPING003",
    },
  ],
  [
    {
      name: "White",
      id: "CAPPING004",
      other: false,
    },
    {
      name: "Brown",
      id: "CAPPING005",
      other: false,
    },
    {
      name: "Other",
      id: "CAPPING006",
      other: true,
    },
  ],
];

export const RAFTER = [
  [
    {
      name: "2 x 4",
      id: "RAFTERSIZE001",
    },
    {
      name: "2 x 6",
      id: "RAFTERSIZE005",
    },
    {
      name: "2 x 8",
      id: "RAFTERSIZE002",
    },
    {
      name: "2 x 10",
      id: "RAFTERSIZE003",
    },
    {
      name: "2 x 12",
      id: "RAFTERSIZE004",
    },
  ],
  [
    {
      name: `8'`,
      id: "RAFTERTYPE001",
    },
    {
      name: `10'`,
      id: "RAFTERTYPE002",
    },
    {
      name: `12'`,
      id: "RAFTERTYPE003",
    },
  ],
];

export const getRafter = {};
getRafter[`${RAFTER[0][0].id}`] = `${RAFTER[0][0].name}`;
getRafter[`${RAFTER[0][1].id}`] = `${RAFTER[0][1].name}`;
getRafter[`${RAFTER[0][2].id}`] = `${RAFTER[0][2].name}`;
getRafter[`${RAFTER[0][3].id}`] = `${RAFTER[0][3].name}`;
getRafter[`${RAFTER[0][4].id}`] = `${RAFTER[0][4].name}`;
getRafter[`${RAFTER[0][4].id}`] = `${RAFTER[0][4].name}`;
getRafter[`${RAFTER[1][0].id}`] = `${RAFTER[1][0].name}`;
getRafter[`${RAFTER[1][1].id}`] = `${RAFTER[1][1].name}`;
getRafter[`${RAFTER[1][2].id}`] = `${RAFTER[1][2].name}`;

export const FASCIADATA = [
  {
    name: "Wood",
    id: "FASCIA009",
  },
  {
    name: "Composite",
    id: "FASCIA010",
  },
];

export const FASCIA = {
  FASCIA009: [
    [
      {
        name: "1 x 4",
        id: "FASCIA001",
      },
      {
        name: "1 x 6",
        id: "FASCIA002",
      },
      {
        name: "1 x 8",
        id: "FASCIA003",
      },
    ],
    [
      {
        name: `8'`,
        id: "FASCIA004",
      },
      {
        name: `10'`,
        id: "FASCIA005",
      },
      {
        name: `12'`,
        id: "FASCIA006",
      },
    ],
  ],
  FASCIA010: [
    [
      {
        name: "1 x 4",
        id: "FASCIA007",
      },
      {
        name: "1 x 6",
        id: "FASCIA008",
      },
      {
        name: "1 x 8",
        id: "FASCIA011",
      },
      {
        name: "1 x 12",
        id: "FASCIA012",
      },
    ],
    [
      {
        name: `12'`,
        id: "FASCIA013",
      },
      {
        name: `18'`,
        id: "FASCIA014",
      },
      {
        name: `20'`,
        id: "FASCIA015",
      },
    ],
  ],
};

export const getFascia = {};
getFascia[`${FASCIADATA[0].id}`] = `${FASCIADATA[0].name}`;
getFascia[`${FASCIADATA[1].id}`] = `${FASCIADATA[1].name}`;
getFascia[
  `${FASCIA["FASCIA009"][0][0].id}`
] = `${FASCIA["FASCIA009"][0][0].name}`;
getFascia[
  `${FASCIA["FASCIA009"][0][1].id}`
] = `${FASCIA["FASCIA009"][0][1].name}`;
getFascia[
  `${FASCIA["FASCIA009"][0][2].id}`
] = `${FASCIA["FASCIA009"][0][2].name}`;
getFascia[
  `${FASCIA["FASCIA009"][1][0].id}`
] = `${FASCIA["FASCIA009"][1][0].name}`;
getFascia[
  `${FASCIA["FASCIA009"][1][1].id}`
] = `${FASCIA["FASCIA009"][1][1].name}`;
getFascia[
  `${FASCIA["FASCIA009"][1][2].id}`
] = `${FASCIA["FASCIA009"][1][2].name}`;

getFascia[
  `${FASCIA["FASCIA010"][0][0].id}`
] = `${FASCIA["FASCIA010"][0][0].name}`;
getFascia[
  `${FASCIA["FASCIA010"][0][1].id}`
] = `${FASCIA["FASCIA010"][0][1].name}`;
getFascia[
  `${FASCIA["FASCIA010"][0][2].id}`
] = `${FASCIA["FASCIA010"][0][2].name}`;
getFascia[
  `${FASCIA["FASCIA010"][0][3].id}`
] = `${FASCIA["FASCIA010"][0][3].name}`;
getFascia[
  `${FASCIA["FASCIA010"][1][0].id}`
] = `${FASCIA["FASCIA010"][1][0].name}`;
getFascia[
  `${FASCIA["FASCIA010"][1][1].id}`
] = `${FASCIA["FASCIA010"][1][1].name}`;
getFascia[
  `${FASCIA["FASCIA010"][1][2].id}`
] = `${FASCIA["FASCIA010"][1][2].name}`;

export const FURRING = [
  [
    {
      name: "1 x 2 x 8",
      id: "FURRING001",
    },
    {
      name: "1 x 3 x 8",
      id: "FURRING002",
    },
    {
      name: "1 x 4 x 8",
      id: "FURRING003",
    },
  ],
];

export const getFurring = {};
getFurring[`${FURRING[0][0].id}`] = `${FURRING[0][0].name}`;
getFurring[`${FURRING[0][1].id}`] = `${FURRING[0][1].name}`;
getFurring[`${FURRING[0][2].id}`] = `${FURRING[0][2].name}`;

export const PLYWOODSIZE = [
  {
    name: '3/8"',
    id: "PLYWOODSIZE001",
  },
  {
    name: '1/2"',
    id: "PLYWOODSIZE002",
  },
  {
    name: '5/8"',
    id: "PLYWOODSIZE003",
  },
  {
    name: '3/4"',
    id: "PLYWOODSIZE004",
  },
];

export const getPlywoodSize = {};
getPlywoodSize[`${PLYWOODSIZE[1].id}`] = `${PLYWOODSIZE[1].name}`;
getPlywoodSize[`${PLYWOODSIZE[0].id}`] = `${PLYWOODSIZE[0].name}`;
getPlywoodSize[`${PLYWOODSIZE[2].id}`] = `${PLYWOODSIZE[2].name}`;
getPlywoodSize[`${PLYWOODSIZE[3].id}`] = `${PLYWOODSIZE[3].name}`;

export const PLYWOODTYPE = [
  {
    name: "CDX",
    id: "PLYWOODTYPE001",
  },
  {
    name: "OSB",
    id: "PLYWOODTYPE002",
  },
];

export const getPlywoodType = {};
getPlywoodType[`${PLYWOODTYPE[0].id}`] = `${PLYWOODTYPE[0].name}`;
getPlywoodType[`${PLYWOODTYPE[1].id}`] = `${PLYWOODTYPE[1].name}`;

export const columns = [
  {
    dataField: "edit",
    text: "",
  },
  {
    dataField: "sec",
    text: "Sec",
  },
  {
    dataField: "floor",
    text: "Floor",
  },
  {
    dataField: "elevation",
    text: "Elevation",
  },
  {
    dataField: "pitch",
    text: "Pitch",
  },
  {
    dataField: "workType",
    text: "Work Type",
  },
  {
    dataField: "install",
    text: "Install",
  },
  {
    dataField: "l",
    text: "L",
  },
  {
    dataField: "w",
    text: "W",
  },
  {
    dataField: "f",
    text: "F",
  },
  {
    dataField: "sq",
    text: "Sq Ft (S)",
  },
  {
    dataField: "flat",
    text: "Sq Ft (F)",
  },
  {
    dataField: "delete",
    text: "",
  },
];

export const RemoveTypes = [
  { id: "001", name: "Shingles" },
  { id: "002", name: "Flat" },
  { id: "003", name: "Plywood Rip" },
  { id: "004", name: "Cedar Rip" },
  { id: "005", name: "Slate Rip" },
];
export const ShiTypes = [
  { id: "001", name: "Single Layers Rip" },
  { id: "002", name: "Two Layers Rip" },
  { id: "003", name: "Three Layers Rip" },
];

export const getRemovalType = {};
getRemovalType[`${RemoveTypes[0].id}`] = `${RemoveTypes[0].name}`;
getRemovalType[`${RemoveTypes[1].id}`] = `${RemoveTypes[1].name}`;
getRemovalType[`${RemoveTypes[2].id}`] = `${RemoveTypes[2].name}`;
getRemovalType[`${RemoveTypes[3].id}`] = `${RemoveTypes[3].name}`;
getRemovalType[`${RemoveTypes[4].id}`] = `${RemoveTypes[4].name}`;

export const getOtherRemovalType = {};
getOtherRemovalType[`${ShiTypes[0].id}`] = `${ShiTypes[0].name}`;
getOtherRemovalType[`${ShiTypes[1].id}`] = `${ShiTypes[1].name}`;
getOtherRemovalType[`${ShiTypes[2].id}`] = `${ShiTypes[2].name}`;

export const SLOPED_TYPES = [
  { id: "001", name: "3-tab" },
  { id: "002", name: "Architechtural" },
  { id: "003", name: "Designer" },
];

export const FLATTYPES = [
  { id: "006", name: "Single Ply TD" },
  { id: "007", name: "Two Ply SA" },
];

export const getInstallType = {};
getInstallType[`${SLOPED_TYPES[0].id}`] = `${SLOPED_TYPES[0].name}`;
getInstallType[`${SLOPED_TYPES[1].id}`] = `${SLOPED_TYPES[1].name}`;

getInstallType[`${FLATTYPES[0].id}`] = `${FLATTYPES[0].name}`;
getInstallType[`${FLATTYPES[1].id}`] = `${FLATTYPES[1].name}`;

export const FLANGE = [
  { id: "flange001", name: "Copper" },
  { id: "flange002", name: "Aluminum" },
];

export const CURB = [
  { id: "001", name: "Low" },
  { id: "002", name: "High" },
];

export const VENTING = [
  { id: "001", name: "Fixed" },
  { id: "002", name: "Manual" },
  { id: "003", name: "Electric" },
];

export const HOODTYPE = [
  { id: "001", name: "Glass" },
  { id: "002", name: "Glass Tinted" },
  { id: "003", name: "Acrylic" },
  { id: "004", name: "Acrylic Tinted" },
];

export const TABS = [
  {
    id: "accessories",
    name: "Accessories",
  },
  {
    id: "flashing",
    name: "Flashing",
  },
  {
    id: "ventalition",
    name: "Ventalition",
  },
  {
    id: "skylight",
    name: "Skylight",
  },
  {
    id: "wood",
    name: "Wood",
  },
  {
    id: "fasciacapping",
    name: "Fascia and Capping",
  },
  {
    id: "miscItems",
    name: "Misc Items",
  },
];

export const G_LEVEL = [
  { id: "floor001", name: "First" },
  { id: "floor002", name: "Second" },
  { id: "floor003", name: "Third" },
];
export const G_ELEVATIONS = [
  { id: "001", name: "Front" },
  { id: "002", name: "Back" },
  { id: "003", name: "Left" },
  { id: "004", name: "Right" },
];

export const G_ELEVATION_DETAILS = [
  { id: "001", name: "Main House" },
  { id: "002", name: "Garage" },
  { id: "003", name: "Barn" },
  { id: "004", name: "GST/HSE" },
  { id: "005", name: "Cabana" },
];

export const G_SCREENS = [
  {
    name: "Aluminum",
    id: "SCREENS001",
  },
  {
    name: "Copper",
    id: "SCREENS002",
  },
  {
    name: "Vinyl",
    id: "SCREENS003",
  },
];

export const G_SCREENSSIZE = [
  {
    name: '4"',
    id: "SCREENS-0040",
  },
  {
    name: '5"',
    id: "SCREENS-0050",
  },
  {
    name: '6"',
    id: "SCREENS-0060",
  },
  // {
  //   name: '7"',
  //   id: "SCREENS-0070",
  // },
];

export const G_SCREENSCOLOR = [
  {
    name: "White",
    id: "SCREENS-011",
  },
  {
    name: "Brown",
    id: "SCREENS-012",
  },
  {
    name: "Other",
    other: true,
    id: "SCREENS-013",
  },
];

export const G_SCREENSOTHER = {
  SCREENS001: [G_SCREENSSIZE, G_SCREENSCOLOR],
  SCREENS002: [G_SCREENSSIZE, []],
  SCREENS003: [G_SCREENSSIZE, G_SCREENSCOLOR],
};

export const G_LEADERHEADSOTHER = {
  SCREENS001: [G_SCREENSCOLOR],
  SCREENS002: [[]],
  SCREENS003: [G_SCREENSCOLOR],
};

export const G_LEADERS = [
  [
    {
      name: "Aluminum",
      id: "LEADERS001",
    },
    {
      name: "Copper",
      id: "LEADERS002",
    },
  ],
  [
    {
      name: "Box",
      id: "LEADERS003",
    },
    {
      name: "Round",
      id: "LEADERS004",
    },
  ],
];

export const G_LEADERSCOLOR = {
  LEADERS001: [
    {
      name: "White",
      id: "LEADERS-011",
    },
    {
      name: "Brown",
      id: "LEADERS-012",
    },
    {
      name: "Other",
      other: true,
      id: "LEADERS-013",
    },
  ],
  LEADERS002: [],
};

export const G_LEADERSOTHER = {
  LEADERS001: {
    LEADERS003: [
      [
        {
          name: '2" x 3"',
          id: "LEADERS-0004",
        },
        {
          name: '3" x 4"',
          id: "LEADERS-0005",
        },
        {
          name: '4" x 5"',
          id: "LEADERS-0006",
        },
      ],
    ],
    LEADERS004: [
      [
        {
          name: '3"',
          id: "LEADERS-0010",
        },
        {
          name: '4"',
          id: "LEADERS-0040",
        },
        {
          name: '5"',
          id: "LEADERS-0050",
        },
        {
          name: '6"',
          id: "LEADERS-0060",
        },
      ],
    ],
  },
  LEADERS002: {
    LEADERS003: [
      [
        {
          name: '2" x 4"',
          id: "LEADERS0031",
        },
        {
          name: '3" x 4"',
          id: "LEADERS0032",
        },
        {
          name: '4" x 5"',
          id: "LEADERS0033",
        },
      ],
    ],
    LEADERS004: [
      [
        {
          name: '3"',
          id: "LEADERS0041",
        },
        {
          name: '4"',
          id: "LEADERS0042",
        },
        {
          name: '5"',
          id: "LEADERS0043",
        },
        {
          name: '6"',
          id: "LEADERS0044",
        },
      ],
    ],
  },
};

export const G_GUTTERS = [
  [
    {
      name: "Aluminum",
      id: "GUTTERS001",
    },
    {
      name: "Copper",
      id: "GUTTERS002",
    },
  ],
  [
    {
      name: "K Type",
      id: "GUTTERS002",
    },
    {
      name: "Half Round",
      id: "GUTTERS003",
    },
  ],
  [
    {
      name: '4"',
      id: "GUTTERS004",
    },
    {
      name: '5"',
      id: "GUTTERS005",
    },
    {
      name: '6"',
      id: "GUTTERS006",
    },
    // {
    //   name: '7"',
    //   id: "GUTTERS010",
    // },
  ],
  [
    {
      name: "White",
      id: "GUTTERS011",
    },
    {
      name: "Brown",
      id: "GUTTERS012",
    },
    {
      name: "Other",
      other: true,
      id: "GUTTERS013",
    },
  ],
];

export const G_CAPPING = [
  [
    {
      name: "Flat",
      id: "CAPPING001",
    },
    {
      name: "PVC",
      id: "CAPPING002",
    },
    {
      name: "Mar-Free",
      id: "CAPPING003",
    },
  ],
  [
    {
      name: "White",
      id: "CAPPING004",
      other: false,
    },
    {
      name: "Brown",
      id: "CAPPING005",
      other: false,
    },
    {
      name: "Other",
      id: "CAPPING006",
      other: true,
    },
  ],
];

export const G_FASCIADATA = [
  {
    name: "Wood",
    id: "FASCIA009",
  },
  {
    name: "Composite",
    id: "FASCIA010",
  },
];

export const G_FASCIA = {
  FASCIA009: [
    [
      {
        name: "1 x 4",
        id: "FASCIA001",
      },
      {
        name: "1 x 6",
        id: "FASCIA002",
      },
      {
        name: "1 x 8",
        id: "FASCIA003",
      },
    ],
    [
      {
        name: `8'`,
        id: "FASCIA004",
      },
      {
        name: `10'`,
        id: "FASCIA005",
      },
      {
        name: `12'`,
        id: "FASCIA006",
      },
    ],
  ],
  FASCIA010: [
    [
      {
        name: "1 x 4",
        id: "FASCIA007",
      },
      {
        name: "1 x 6",
        id: "FASCIA008",
      },
      {
        name: "1 x 8",
        id: "FASCIA011",
      },
      {
        name: "1 x 12",
        id: "FASCIA012",
      },
    ],
    [
      {
        name: `12'`,
        id: "FASCIA013",
      },
      {
        name: `18'`,
        id: "FASCIA014",
      },
      {
        name: `20'`,
        id: "FASCIA015",
      },
    ],
  ],
};

export const G_columns = [
  {
    dataField: "edit",
    text: "",
  },
  {
    dataField: "sec",
    text: "Sec",
  },
  {
    dataField: "level",
    text: "Level",
  },
  {
    dataField: "elevation",
    text: "Elevation",
  },
  {
    dataField: "gutters",
    text: "Gutters",
  },
  {
    dataField: "leaders",
    text: "Leaders",
  },
  {
    dataField: "screens",
    text: "Screens",
  },
  {
    dataField: "fascia",
    text: "Fascia",
  },
  {
    dataField: "cap",
    text: "Capping",
  },
  {
    dataField: "heads",
    text: "Heads",
  },
  {
    dataField: "delete",
    text: "",
  },
];

export const G_TABS = [
  {
    id: "gutter",
    name: "Gutter & Leaders",
  },
  {
    id: "fascia",
    name: "Fascia & Capping",
  },
];

export const envTypes = {
  // development: 'production',
  development: "development",
};
export const suppliersType = ["material", "subsorcrew", "carting"];

// User Roles
export const owner = "executive";
export const projectmanager = "project_manager";
export const salesmanager = "sales_manager";
export const salespersion = "sales";
export const repairpersion = "repair";
export const reception = "receptionist";
export const foreman = "foreman";
export const officemanager = "office_manager";
export const subscriptionadmin = "admin";

// Pages
export const newlead = "newlead";
export const subscription = "subscription";
export const proposal = "proposaltext";
export const dropdown = "dropdown";
export const quotesproposal = "quotesproposal";
export const metrics = "metrics";
export const sales = "sales";
export const repairs = "repairs";
export const suppliermaterials = "suppliermaterials";
export const projectmanagement = "projectmanagement";
export const contactmanagement = "contactmanagement";

export const UserRole = [
  {
    [subscriptionadmin]: {
      newlead: {
        read: true,
        write: true,
      },
      sales: {
        read: true,
        write: true,
      },
      repairs: {
        read: true,
        write: true,
      },
      metrics: {
        read: true,
        write: true,
      },
      quotesproposal: {
        read: true,
        write: true,
      },
      projectmanagement: {
        read: true,
        write: true,
      },
      contactmanagement: {
        read: true,
        write: true,
      },
      subscription: {
        read: true,
        write: true,
      },
      proposaltext: {
        read: true,
        write: true,
      },
      dropdown: {
        read: true,
        write: true,
      },
      suppliermaterials: {
        read: true,
        write: true,
      },
    },
    [owner]: {
      newlead: {
        read: true,
        write: true,
      },
      sales: {
        read: true,
        write: true,
      },
      repairs: {
        read: true,
        write: true,
      },
      metrics: {
        read: true,
        write: true,
      },
      quotesproposal: {
        read: true,
        write: true,
      },
      projectmanagement: {
        read: true,
        write: true,
      },
      contactmanagement: {
        read: true,
        write: true,
      },
      subscription: {
        read: false,
        write: false,
      },
      proposaltext: {
        read: true,
        write: true,
      },
      dropdown: {
        read: true,
        write: true,
      },
      suppliermaterials: {
        read: true,
        write: true,
      },
    },
    [officemanager]: {
      newlead: {
        read: true,
        write: true,
      },
      sales: {
        read: true,
        write: true,
      },
      repairs: {
        read: true,
        write: true,
      },
      metrics: {
        read: true,
        write: true,
      },
      quotesproposal: {
        read: true,
        write: true,
      },
      projectmanagement: {
        read: true,
        write: true,
      },
      contactmanagement: {
        read: true,
        write: true,
      },
      subscription: {
        read: false,
        write: false,
      },
      proposaltext: {
        read: true,
        write: true,
      },
      dropdown: {
        read: true,
        write: true,
      },
      suppliermaterials: {
        read: true,
        write: true,
      },
    },
    [projectmanager]: {
      newlead: {
        read: false,
        write: false,
      },
      sales: {
        read: false,
        write: false,
      },
      repairs: {
        read: false,
        write: false,
      },
      metrics: {
        read: true,
        write: false,
      },
      quotesproposal: {
        read: true,
        write: false,
      },
      projectmanagement: {
        read: true,
        write: true,
      },
      contactmanagement: {
        read: true,
        write: false,
      },
      subscription: {
        read: false,
        write: false,
      },
      proposaltext: {
        read: false,
        write: false,
      },
      dropdown: {
        read: true,
        write: true,
      },
      suppliermaterials: {
        read: true,
        write: false,
      },
    },
    [salesmanager]: {
      newlead: {
        read: true,
        write: true,
      },
      sales: {
        read: true,
        write: true,
      },
      repairs: {
        read: true,
        write: true,
      },
      metrics: {
        read: true,
        write: true,
      },
      quotesproposal: {
        read: true,
        write: true,
      },
      projectmanagement: {
        read: true,
        write: false,
      },
      contactmanagement: {
        read: true,
        write: true,
      },
      subscription: {
        read: false,
        write: false,
      },
      proposaltext: {
        read: true,
        write: true,
      },
      dropdown: {
        read: true,
        write: true,
      },
      suppliermaterials: {
        read: true,
        write: true,
      },
    },
    [salespersion]: {
      newlead: {
        read: true,
        write: true,
      },
      sales: {
        read: true,
        write: true,
      },
      repairs: {
        read: false,
        write: false,
      },
      metrics: {
        read: true,
        write: true,
      },
      quotesproposal: {
        read: true,
        write: true,
      },
      projectmanagement: {
        read: true,
        write: false,
      },
      contactmanagement: {
        read: true,
        write: true,
      },
      subscription: {
        read: false,
        write: false,
      },
      proposaltext: {
        read: false,
        write: false,
      },
      dropdown: {
        read: false,
        write: false,
      },
      suppliermaterials: {
        read: true,
        write: false,
      },
    },
    [repairpersion]: {
      newlead: {
        read: true,
        write: true,
      },
      sales: {
        read: false,
        write: false,
      },
      repairs: {
        read: true,
        write: true,
      },
      metrics: {
        read: true,
        write: false,
      },
      quotesproposal: {
        read: false,
        write: false,
      },
      projectmanagement: {
        read: false,
        write: false,
      },
      contactmanagement: {
        read: true,
        write: true,
      },
      subscription: {
        read: false,
        write: false,
      },
      proposaltext: {
        read: false,
        write: false,
      },
      dropdown: {
        read: false,
        write: false,
      },
      suppliermaterials: {
        read: true,
        write: false,
      },
    },
    [reception]: {
      newlead: {
        read: true,
        write: true,
      },
      sales: {
        read: true,
        write: true,
      },
      repairs: {
        read: true,
        write: true,
      },
      metrics: {
        read: true,
        write: false,
      },
      quotesproposal: {
        read: true,
        write: false,
      },
      projectmanagement: {
        read: false,
        write: false,
      },
      contactmanagement: {
        read: true,
        write: false,
      },
      subscription: {
        read: false,
        write: false,
      },
      proposaltext: {
        read: false,
        write: false,
      },
      dropdown: {
        read: false,
        write: false,
      },
      suppliermaterials: {
        read: true,
        write: false,
      },
    },
    [foreman]: {
      newlead: {
        read: false,
        write: false,
      },
      sales: {
        read: false,
        write: false,
      },
      repairs: {
        read: false,
        write: false,
      },
      metrics: {
        read: true,
        write: false,
      },
      quotesproposal: {
        read: true,
        write: false,
      },
      projectmanagement: {
        read: true,
        write: false,
      },
      contactmanagement: {
        read: true,
        write: false,
      },
      subscription: {
        read: false,
        write: false,
      },
      proposaltext: {
        read: false,
        write: false,
      },
      dropdown: {
        read: false,
        write: false,
      },
      suppliermaterials: {
        read: true,
        write: false,
      },
    },
  },
];

export const TIMINGS = [
  "6:00 AM",
  "6:15 AM",
  "6:30 AM",
  "6:45 AM",
  "7:00 AM",
  "7:15 AM",
  "7:30 AM",
  "7:45 AM",
  "8:00 AM",
  "8:15 AM",
  "8:30 AM",
  "8:45 AM",
  "9:00 AM",
  "9:15 AM",
  "9:30 AM",
  "9:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "1:00 PM",
  "1:15 PM",
  "1:30 PM",
  "1:45 PM",
  "2:00 PM",
  "2:15 PM",
  "2:30 PM",
  "2:45 PM",
  "3:00 PM",
  "3:15 PM",
  "3:30 PM",
  "3:45 PM",
  "4:00 PM",
  "4:15 PM",
  "4:30 PM",
  "4:45 PM",
  "5:00 PM",
  "5:15 PM",
  "5:30 PM",
  "5:45 PM",
  "6:00 PM",
  "6:15 PM",
  "6:30 PM",
  "6:45 PM",
  "7:00 PM",
  "7:15 PM",
  "7:30 PM",
  "7:45 PM",
  "8:00 PM",
  "8:15 PM",
  "8:30 PM",
  "8:45 PM",
  "9:00 PM",
];

export const activityrepair = "activity-repair";
export const activitysales = "activity-sales";
export const admindropdowns = "admin-drop-downs";
export const adminproposal = "admin-proposals-text-&-template";
export const adminsubscriptionusers = "admin-subscription-user";
export const calendarrepair = "calendar-repair";
export const calendarsales = "calendar-sales";
export const dashboardnewLead = "dashboard-new-lead";
export const metric = "metric-roof-gutter-images";
export const quotesproposals = "quotes-&-proposals";
export const viewonly = "view-only";
export const noacces = "no-access";
export const viewcontactinfo = "view-contact-info-only";
export const onlyself = "full-read-&-write-only-self";
export const fullreadwrite = "full-read-&-write";
export const caldaylost = "full-read-&-write-prod-cal-day-lost";

export const materiallabor = "admin-suppliers-material-labor";
export const projectmanagementScreen = "project-management";

export const project_manager_link = [
  "calendar",
  "contact-info",
  "Metrics",
  "Quotes & Proposal",
  "Images",
  "PM Details",
  "Notes",
];

export const mapNavURL = "https://www.google.com/maps/place?q=";
export const roofImgURL =
  "https://media.istockphoto.com/id/1145254175/photo/close-up-aerial-view-of-building-attic-rooms-exterior-on-metal-shingle-roof-stucco-walls-and.jpg?s=612x612&w=0&k=20&c=nunaE61MNFXNKQwTdXep9MFgHV9t_6RmkARmberadcg=";
