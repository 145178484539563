import React, { Component } from "react";
import { Modal, Button, Form, Row, Col, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getRoofData,
  updateBuilding,
  addMetric,
} from "lib/redux/metrics/metrics.actions";
import { uuid } from "uuidv4";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEquals,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import numeral from "numeral";
import PopupModal, { ModalFooter } from "components/popup-modal/popup-modal";
import Building from "./components/Building";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import Numpad from "./components/Numpad";
import Images from "./Images";
import "./metric.scss";

import {
  METALSHEET,
  DIMENSIONNAME,
  METALSHEETDATA,
  COMMONNAILS,
  HANDNAILS,
  CHIMENYCAPS,
  METAL,
  CAPPING,
  RAFTER,
  FASCIADATA,
  FASCIA,
  FURRING,
  PLYWOODSIZE,
  PLYWOODTYPE,
  columns,
  RemoveTypes,
  ShiTypes,
  SLOPED_TYPES,
  FLATTYPES,
  FLANGE,
  CURB,
  VENTING,
  HOODTYPE,
  TABS,
  FLOOR,
  ELEVATION,
  ELEVATIONDETAILS,
  DIFFICULTY,
  WORKTYPE,
  ELEVATIONOTHER,
  getOtherRemovalType,
  getRemovalType,
  getMetal,
} from "lib/constants/const";

import MetricHeader, { Header } from "./components/MetricHeader";
import DisplayBtnsTab from "./components/DisplayBtnsTab";

import {
  getDataLabel,
  getFlatOrShingles,
  getElevationLetters,
  formatNumber,
  debounce,
  getRoofingSectionData,
} from "utils/utils";

import GenerateQuote from "./components/GenerateQuoteButton";
import { DeleteSection, getBuildingName, storeMetricDataFinal } from "./utils";
import DeleteModal from "components/DeleteModal";
import DisplayModal from "./components/DisplayModal";
import MetricButton from "./components/MetricButton";
import NumberInput from "components/NumberInput";
import DisplayMetric from "./components/DisplayMetric";
import InitialLoader from "../../components/InitilalLoader";
import { removeUnwantedSections } from "./utils";
import BasicTable from "components/BasicTable";
import { changeStage } from "lib/redux/sales/sales.actions";
import DimensionTallyUI from "./components/DimensionTallyUI";

const getIcon = (name) => <FontAwesomeIcon icon={name} />;
const getFloor = (data) => getDataLabel(FLOOR, data);
const getElevation = (data) => getElevationLetters(ELEVATION, data);
const getLabel = (data) => getDataLabel(ELEVATIONDETAILS, data);
const createTableData = (
  data,
  i,
  { onClickView, disableViewBtn, onClickDelete, disableDeleteBtn }
) => ({
  edit: (
    <Button onClick={onClickView} disabled={disableViewBtn}>
      {getIcon(faSearch)} View
    </Button>
  ),
  ...getRoofingSectionData(data, i),
  delete: (
    <Button
      variant="danger"
      className="tblDel"
      disabled={disableDeleteBtn}
      onClick={onClickDelete}
    >
      Delete
    </Button>
  ),
});

const countGravelStop = (trimmetalData) =>
  trimmetalData.reduce((acc, data) => {
    const isGravelStop = getMetal.isGravelStop(data.type);
    return (acc = isGravelStop ? data.feet + acc : 0 + acc);
  }, 0);

const countJBend = (trimmetalData) =>
  trimmetalData.reduce((acc, data) => {
    const isJBend = getMetal.isJBend(data.type);
    return (acc = isJBend ? data.feet + acc : 0 + acc);
  }, 0);

const countApron = (trimmetalData) =>
  trimmetalData.reduce((acc, data) => {
    const isApron = getMetal.isApron(data.type);
    return (acc = isApron ? data.feet + acc : 0 + acc);
  }, 0);

const calculateTotalFlat = (lwfData) => {
  let total = 0;
  lwfData.map((data) => (total += data.lwfTotal));
  return total;
};

class Metric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestId: props.match.params.id,
      stageId: this.props.location?.state?.stageId,
      roofPitch: "",
      section: 0,
      address: "",
      building: "",
      floor: "",
      elevation: [],
      elevation_details: "",
      pitch: "",
      work_type: "",
      install_type: "",
      showCoopModal: false,
      coopUnits: [],
      newBuildingName: "",
      tempNewBuildingName: "",
      otherModal: false,
      elevation_other: [],
      edOther: "",
      removalModal: false,
      removal_types: "",
      other_rems: "",
      installTypeModal: false,
      install_types: "",
      dimensionModal: false,
      selectDimen: "",
      l: "",
      w: "",
      f: "",
      lwfTotal: 0,
      lwfData: [],
      totalFlat: 0,
      editDimen: "",
      viewTally: false,
      notes: "",
      selectData: "",
      starter: "",
      T0: "",
      T1: "",
      T2: "",
      T3: "",
      T4: "",
      T5: "",
      T6: "",
      T7: "",
      T8: "",
      T11: "",
      T12: "",
      T13: "",
      T14: "",
      T9: "",
      T10: "",
      T16: "",
      T17: "",
      T18: "",
      T19: "",
      T20: "",
      M0: "",
      M1: "",
      M4: "",
      M2: "",
      M3: "",
      StarterData: [],
      showTabModal: false,
      label: "",

      LeakBarrierData: [],
      EveDripData: [],
      RakeDripData: [],
      HipRidgeData: [],
      RidgeVentData: [],
      activeLabel: "",
      VentPipeData: [],
      ValleyData: [],
      StepsData: [],
      ChimneyData: [],
      WallData: [],
      page_num: 1,
      max_item: 5,
      linearFeet: "",
      faceSize: "",
      showFlashingTabModal: false,
      totalLabel: "",
      louvers: "",
      fans: "",
      louversData: [],
      hoodventsmall: "",
      hoodventlarge: "",
      fansData: [],
      hoodventsmallData: [],
      hoodventlargeData: [],
      skCount: "",
      showSkylightModal: false,
      skylightData: [],
      skylightTotal: "",
      displaySkylightDetails: false,
      flange: "",
      curb: "",
      venting: "",
      dimensions: "",
      hood_type: "",
      plywood: "",
      plywoodData: [],
      rafterstuds: "",
      rafterstudsData: [],
      furringstrips: "",
      furringstripsData: [],
      fascia: "",
      fasciaData: [],
      capping: "",
      cappingData: [],
      showWoodModal: false,
      showWoodEntry: true,
      type: "",
      size: "",
      length: "",
      height: "",
      width: "",
      material: "",
      inches: "",
      shape: "",
      chimneycaps: "",
      color: "",
      dataKeys: [],
      activeWood: 0,
      otherColor: "",
      showMiscModal: false,
      showMiscEntry: true,
      trimmetalData: [],
      chimenycapsData: [],

      trimmetal: "",
      handnailsData: [],
      handnails: "",
      commonnailsData: [],
      commonnails: "",
      metalsheet: "",
      metalsheetData: [],
      showDeleteModal: false,
      deleteData: null,
      vent_pipe: "",
      sectionData: [],
      tableData: [],
      fasciaSelection: {},
      cappingSelection: {},
      editSection: false,
      activateSave: false,
      showEntryModal: false,
      activateSaveNew: false,
      msg: "",
      msgType: "danger",
      loadingReq: false,
      editBuilding: "",
      deleteBuilding: "",
      buildingErr: "",
      dltHead: "",
      dltSubHead: "",
      allDimensionData: [],
      sectionI: "",
      selectedTab: TABS[0].id,
      imagesList: [],
      images: false,
      coverImage: [],

      metaData: {
        hasFlat: false,
        hasShingles: false,
      },
      currentSection: "",
      sectionId: uuid(),
      isDataChanged: false,
      isQuoteLock: false,
      isLocked: false,
    };

    this.loadData();

    this.saveDataIntoDb = debounce(this.saveDataIntoDb, 2000);
  }

  componentDidUpdate(prevProps) {
    this.loadData();
  }

  isAddSectionDisabled = () => {
    const {
      floor,
      elevation,
      elevation_details,
      pitch,
      work_type,
      install_types,
      lwfData,
      removal_types,
      other_rems,
    } = this.state;
    return (
      !floor ||
      !elevation ||
      !elevation_details ||
      !pitch ||
      !work_type ||
      !install_types ||
      lwfData.length === 0 ||
      (removal_types &&
        (removal_types === "001" || removal_types === "002") &&
        !other_rems)
    );
  };

  selectBuilding = (unit, empty = false) => {
    if (empty) {
      return;
    }
    if (unit) {
      localStorage.setItem("BuildingName", unit);

      this.setState({ building: unit }, () => {
        this.getBuildingSectionData();
      });
    } else {
      this.getBuildingSectionData();
    }
  };

  getBuildingSectionData = () => {
    const { sectionData, building } = this.state;
    const tableData = sectionData.filter((data) => data.building === building);
    this.setState({ tableData });
  };

  clearMsg = () => {
    setTimeout(() => {
      this.setState({
        msg: "",
        msgType: "danger",
      });
    }, 5000);
  };

  loadData = () => {
    const {
      getRoofData,
      match: {
        params: { id },
      },
    } = this.props || this.props.metricId;
    if (id) {
      getRoofData(id);
    }
  };

  componentDidMount() {
    this.loadData();
  }

  addMsg = (msg, msgType) => {
    this.setState({
      msg,
      msgType,
    });
    this.clearMsg();
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      work_type,
      install_type,
      l,
      w,
      f,
      lwfData,
      removal_types,
      selectData,
      selectDimen,
      other_rems,
      currentSection,
      editSection,
    } = this.state;
    const { data, metricErrors, buildingErrors } = this.props;

    if (metricErrors !== prevProps.metricErrors) {
      if (metricErrors) {
        this.addMsg(metricErrors, "danger");
        this.setState({ loadingReq: false });
      }
    }
    if (buildingErrors !== prevProps.buildingErrors) {
      if (buildingErrors) {
        this.setState({
          buildingErr: buildingErrors,
        });
        this.setState({ loadingReq: false });
        setTimeout(() => {
          this.setState({
            buildingErr: "",
          });
        }, 5000);
      }
    }

    if (!data) return;
    if (
      JSON.stringify(data) !== JSON.stringify(prevProps.data) ||
      this.state.address != data.address
    ) {
      if (data && JSON.stringify(data) !== "{}") {
        let dataList = {};
        let sectionData = [...data.metricData];
        let copyDataToDb = false;

        if (!data.hasRoof) {
          this.props.history.push("/dashboard/activity/sales");
        }

        if (data.buildings && data.buildings?.length > 0) {
          sectionData = removeUnwantedSections(sectionData, data.buildings);
          if (sectionData.length !== data.metricData.length) {
            copyDataToDb = true;
          }
          let buildingName = localStorage.getItem("BuildingName");

          if (buildingName === null) {
            this.setState(
              {
                coopUnits: data.buildings,
              },
              () => {
                this.selectBuilding(data.buildings[0].bid);
              }
            );
          } else {
            this.setState(
              {
                coopUnits: data.buildings,
              },
              () => {
                this.selectBuilding(buildingName);
              }
            );
          }
        } else {
          dataList = { ...dataList, tableData: data.metricData };
        }
        this.setState(
          () => ({
            address: data.address,
            ...dataList,
            sectionData,
            coverImage: data.coverImage ? data.coverImage : [],
            loadingReq: false,
            isLocked: data.isLocked,
          }),
          () => {
            this.setState({ section: this.getNewSectionNumber(0) });
            if (copyDataToDb) {
              this.saveMetricData(sectionData);
            }
          }
        );
      }
    }

    if (selectData && prevState.selectData) {
      if (this.state[selectData].length !== prevState[selectData].length) {
        this.setState({ activateSaveNew: true });
      }
    }

    if (
      JSON.stringify(this.state[`${selectDimen}Selection`]) !==
      JSON.stringify(prevState[`${selectDimen}Selection`])
    ) {
      this.setState({ activateSaveNew: true });
    }

    if (install_type !== prevState.install_type) {
      if (install_type === "001" && prevState.install_type === "") {
        this.setState({ installTypeModal: true });
      }
    }
    if (l !== prevState.l || w !== prevState.w || f !== prevState.f) {
      if (l && w && f) {
        this.setState((state) => ({
          lwfTotal: Number(state.l) * Number(state.w) * Number(state.f),
        }));
      } else {
        this.setState((state) => ({ lwfTotal: 0 }));
      }
    }
    if (lwfData.length !== prevState.lwfData.length) {
      this.setState({ activateSave: true });
    }
  }

  clearAllSectionData = () => {
    return this.setState({
      section: this.getNewSectionNumber(0),
      floor: "",
      elevation: [],
      elevation_details: "",
      pitch: "",
      roofPitch: "",
      work_type: "",
      install_type: "",
      showCoopModal: false,
      newBuildingName: "",
      tempNewBuildingName: "",
      otherModal: false,
      elevation_other: [],
      edOther: "",
      removalModal: false,
      removal_types: "",
      other_rems: "",
      installTypeModal: false,
      install_types: "",
      dimensionModal: false,
      selectDimen: "",
      l: "",
      w: "",
      f: "",
      lwfTotal: 0,
      lwfData: [],
      totalFlat: 0,
      editDimen: "",
      viewTally: false,
      notes: "",
      selectData: "",
      starter: "",
      StarterData: [],
      showTabModal: false,
      label: "",
      T0: "",
      T1: "",
      T2: "",
      T3: "",
      T4: "",
      T5: "",
      T6: "",
      T7: "",
      T8: "",
      T11: "",
      T12: "",
      T13: "",
      T14: "",
      T9: "",
      T10: "",
      T16: "",
      T17: "",
      T18: "",
      T19: "",
      T20: "",
      M0: "",
      M1: "",
      M4: "",
      M2: "",
      M3: "",

      LeakBarrierData: [],
      EveDripData: [],
      RakeDripData: [],
      HipRidgeData: [],
      RidgeVentData: [],
      activeLabel: "",
      VentPipeData: [],
      ValleyData: [],
      StepsData: [],
      ChimneyData: [],
      WallData: [],
      page_num: 1,
      max_item: 5,
      linearFeet: "",
      faceSize: "",
      showFlashingTabModal: false,
      totalLabel: "",
      louvers: "",
      fans: "",
      louversData: [],
      hoodventsmall: "",
      hoodventlarge: "",
      fansData: [],
      hoodventsmallData: [],
      hoodventlargeData: [],
      skCount: "",
      showSkylightModal: false,
      skylightData: [],
      skylightTotal: "",
      displaySkylightDetails: false,
      flange: "",
      curb: "",
      venting: "",
      dimensions: "",
      hood_type: "",
      plywood: "",
      plywoodData: [],
      rafterstuds: "",
      rafterstudsData: [],
      furringstrips: "",
      furringstripsData: [],
      fascia: "",
      fasciaData: [],
      capping: "",
      cappingData: [],
      showWoodModal: false,
      showWoodEntry: true,
      type: "",
      size: "",
      length: "",
      height: "",
      width: "",
      material: "",
      inches: "",
      shape: "",
      chimneycaps: "",
      color: "",
      dataKeys: [],
      activeWood: 0,
      otherColor: "",
      showMiscModal: false,
      showMiscEntry: true,
      trimmetalData: [],
      chimenycapsData: [],

      trimmetal: "",
      handnailsData: [],
      handnails: "",
      commonnailsData: [],
      commonnails: "",
      metalsheet: "",
      metalsheetData: [],
      showDeleteModal: false,
      deleteData: null,
      vent_pipe: "",
      sectionI: "",
      loadingReq: false,
      editBuilding: "",
      deleteBuilding: "",
      buildingErr: "",
      dltHead: "",
      dltSubHead: "",
      selectedTab: TABS[0].id,
      imagesList: [],
      metaData: {
        hasFlat: false,
        hasShingles: false,
      },
      currentSection: "",
      sectionId: "",
      gravelStop: "",
      jBend: "",
      apron: "",
    });
  };

  getAllData = () => {
    const {
      section,
      building,
      floor,
      elevation,
      elevation_details,
      pitch,
      work_type,
      install_types,
      elevation_other,
      edOther,
      removal_types,
      other_rems,

      lwfData,
      totalFlat,
      roofPitch,
      notes,
      T0,
      T1,
      T2,
      T3,
      T4,
      T5,
      T6,
      T7,
      T8,
      T11,
      T12,
      T13,
      T14,
      T9,
      T10,
      T16,
      T17,
      T18,
      T19,
      T20,
      M0,
      M1,
      M2,
      M3,
      M4,
      StarterData,
      LeakBarrierData,
      EveDripData,
      RakeDripData,
      HipRidgeData,
      RidgeVentData,
      VentPipeData,
      ValleyData,
      StepsData,
      ChimneyData,
      WallData,
      louversData,
      fansTotal,
      fansData,
      hoodventsmallData,
      hoodventlargeData,
      skylightData,
      skylightTotal,

      plywoodData,
      rafterstuds,
      rafterstudsData,
      furringstrips,
      furringstripsData,
      fasciaData,
      cappingData,
      showWoodModal,
      showWoodEntry,

      chimneycaps,
      showMiscModal,
      showMiscEntry,
      trimmetalData,
      chimenycapsData,

      trimmetal,
      handnailsData,
      handnails,
      commonnailsData,
      commonnails,
      metalsheet,
      metalsheetData,
      vent_pipe,
      sectionId,
      imagesList,
    } = this.state;

    const gravelStopTotal = countGravelStop(trimmetalData);
    const JBendTotal = countJBend(trimmetalData);
    const apronTotal = countApron(trimmetalData);
    return {
      metaData: {
        hasFlat: getFlatOrShingles(install_types) === "Flat" ? true : false,
        hasShingles: getFlatOrShingles(install_types) !== "Flat" ? true : false,
      },
      section,
      building,
      floor,
      roofPitch: getDataLabel(DIFFICULTY, pitch) === "Flat" ? "" : roofPitch,
      elevation,
      elevation_details,
      pitch,
      work_type,
      install_types,
      elevation_other,
      edOther,

      removal_types,
      other_rems,
      lwfData,
      totalFlat,

      notes,

      StarterData,
      T0: T0.toString().replaceAll(",", ""),
      T1: T1.toString().replaceAll(",", ""),
      T2: T2.toString().replaceAll(",", ""),
      T3: T3.toString().replaceAll(",", ""),
      T4: T4.toString().replaceAll(",", ""),
      T5: T5.toString().replaceAll(",", ""),
      T6: T6.toString().replaceAll(",", ""),
      T7: T7.toString().replaceAll(",", ""),
      T8: T8.toString().replaceAll(",", ""),
      T11: T11.toString().replaceAll(",", ""),
      T12: T12.toString().replaceAll(",", ""),
      T13: T13.toString().replaceAll(",", ""),
      T14: T14.toString().replaceAll(",", ""),
      T9: T9.toString().replaceAll(",", ""),
      T10: T10.toString().replaceAll(",", ""),
      T16,
      T17,
      T18,
      T19,
      T20,
      M0,
      M1,
      M2,
      M3,
      M4,

      LeakBarrierData,
      EveDripData,
      RakeDripData,
      HipRidgeData,
      RidgeVentData,
      VentPipeData,
      ValleyData,
      StepsData,
      ChimneyData,
      WallData,
      louversData,
      fansTotal,
      fansData,
      hoodventsmallData,
      hoodventlargeData,
      skylightData,
      skylightTotal,
      plywoodData,
      rafterstuds,
      rafterstudsData,
      furringstrips,
      furringstripsData,

      fasciaData,

      cappingData,
      showWoodModal,
      showWoodEntry,

      chimneycaps,
      showMiscModal,
      showMiscEntry,
      trimmetalData,
      chimenycapsData,

      trimmetal,
      handnailsData,
      handnails,
      commonnailsData,
      commonnails,
      metalsheet,
      metalsheetData,
      vent_pipe,
      sectionId,
      gravelStop: gravelStopTotal ? gravelStopTotal : "",
      apron: apronTotal ? apronTotal : "",
      jBend: JBendTotal ? JBendTotal : "",

      imagesList:
        imagesList.length > 0 ? imagesList : new Array(5).fill(undefined),
    };
  };

  autoMoveToDue = () => {
    if (this.state.stageId !== "salesStage102") return;
    this.props.changeStage({
      requestId: this.props.match?.params?.id || this.props.leadReqId,
      stageId: "salesStage103",
    });
    this.setState({ stageId: "salesStage103" });
  };

  onSuccess = (sectionData, del, empty) => {
    this.setState(
      (state) => ({
        loadingReq: false,
        editSection: true,
        sectionI: state.section,
        currentSection: state.sectionId,
        sectionData: [...sectionData],
        tableData: empty ? [] : state.tableData,
        isDataChanged: true,
      }),
      () => this.selectBuilding(this.state.building, empty)
    );
    if (del) {
      this.addAnotherSection();
    }
    this.hideDeleteModal();

    this.autoMoveToDue();
  };

  saveMetricData = (sectionData, empty = false, del = false) => {
    const { coverImage, editSection } = this.state;
    const reqId = this.props.match?.params?.id || this.props.leadReqId;

    if (editSection && this.isAddSectionDisabled()) return;

    storeMetricDataFinal(
      this.props.addMetric,
      "roof",
      reqId,
      coverImage,
      sectionData,
      () => this.onSuccess(sectionData, del, empty)
    );
  };

  addSection = () => {
    const data = this.getAllData();

    const { sectionData, currentSection, editSection } = this.state;
    if (currentSection && editSection) {
      const { imagesList, ...data } = this.getAllData();

      let temp = sectionData;
      const Idx = sectionData.findIndex(
        (data) => data.sectionId === currentSection
      );
      temp.splice(Idx, 1, { ...temp[Idx], ...data });

      this.setState(
        () => ({
          loadingReq: true,
        }),
        () => {
          this.saveMetricData(temp);
        }
      );
    } else {
      this.setState(
        (state) => ({
          loadingReq: true,
        }),
        () => {
          this.saveMetricData([...this.state.sectionData, { ...data }]);
        }
      );
    }
  };

  editSectionData = (data, sectionId, idx) => {
    const { other_rems } = data;
    const { isLocked, isQuoteLock } = this.state;
    this.setState(
      {
        ...data,
        editSection: true,
        sectionI: idx - 1,
        section: idx,
        showCoopModal: false,
        installTypeModal: false,
        dimensionModal: false,
        viewTally: false,
        showTabModal: false,
        showFlashingTabModal: false,
        showSkylightModal: false,
        displaySkylightDetails: false,
        showWoodModal: false,
        showWoodEntry: true,
        showMiscModal: false,
        showMiscEntry: true,
        showDeleteModal: false,
        showEntryModal: false,
        currentSection: sectionId,
      },
      () => {
        this.setState({
          other_rems,
        });
      }
    );
    if (isLocked) {
      this.setState({
        isQuoteLock: true,
      });
    } else {
      this.setState({
        isQuoteLock: false,
      });
    }
  };

  deleteAllSectionData = (sectionId) => {
    const { sectionData } = this.state;
    let temp = sectionData;
    const Idx = sectionData.findIndex((data) => data.sectionId === sectionId);
    temp.splice(Idx, 1);
    this.setState(
      () => ({
        loadingReq: true,
      }),
      () => {
        this.saveMetricData(temp, false, true);
      }
    );
  };

  deleteAllSectionDataByBuilding = (id) => {
    const { sectionData, deleteBuilding, building } = this.state;

    const newData = sectionData.filter(
      (data) => data.building !== deleteBuilding
    );

    this.setState(
      () => ({
        loadingReq: true,
        deleteBuilding: "",
      }),
      () => {
        if (newData.length !== sectionData.length) {
          this.saveMetricData(newData, id === building);
        } else {
          this.setState({ loadingReq: false });
        }
      }
    );
  };

  buldingTotal = (list, isFlat) => {
    const filteredData = list.filter((listData) =>
      isFlat
        ? getFlatOrShingles(listData.install_types) === "Flat"
        : getFlatOrShingles(listData.install_types) !== "Flat"
    );
    return filteredData.reduce((acc, item) => (acc = acc + item.totalFlat), 0);
  };

  getTableData = () => {
    let allData = [];
    const tableList = this.state.tableData;
    tableList.map((data, i) => {
      return allData.push(
        createTableData(data, i, {
          onClickView: () => this.editSectionData(data, data.sectionId, i + 1),
          disableViewBtn:
            this.state.editSection &&
            this.state.currentSection === data.sectionId,
          onClickDelete: () => this.onDeleteSection(data),
          disableDeleteBtn: !(
            this.state.currentSection === data.sectionId &&
            this.state.editSection
          ),
        })
      );
    });
    return allData;
  };

  onDeleteSection = (data) => {
    this.setState({
      dltHead: DeleteSection.heading,
      dltSubHead: DeleteSection.subHeading,
    });
    this.setOnDelete(() => {
      this.deleteAllSectionData(data.sectionId);
      this.setState({
        dltHead: "",
        dltSubHead: "",
      });
    }, false);
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  hideTabModal = () => {
    this.setState({
      showTabModal: false,
      label: "",
      selectData: "",
      selectDimen: "",
      viewTally: false,
    });
    this.resetPage();
    this.clearEdit();
  };
  hideFlashingTabModal = () => {
    this.setState({
      showFlashingTabModal: false,
      label: "",
      selectData: "",
      selectDimen: "",
      linearFeet: "",
      faceSize: "",
      viewTally: false,
    });
    this.resetPage();
    this.clearEdit();
  };

  hideSkylightModal = () => {
    this.setState({
      showSkylightModal: false,
      label: "",
      selectData: "",
      selectDimen: "",
      viewTally: false,
      totalLabel: "",
      width: "",
      height: "",
      count: "",
      skCount: "",
    });
    this.resetPage();
    this.clearEdit();
  };

  addFloor = (name, id, exec = false) => {
    const { work_type } = this.state;
    if (exec) {
      this.setState((state) => {
        let obj = {};
        state.dataKeys
          .slice(2)
          .map((key) => (obj = { ...obj, [key.toLowerCase()]: "" }));
        return {
          ...obj,
        };
      });
    }
    this.setState(
      (state) => ({ [name]: state[name] === id ? "" : id }),
      () => {
        if (
          (name.toLowerCase() === "floor" ||
            name.toLowerCase() === "pitch" ||
            name.toLowerCase() === "work_type" ||
            name.toLowerCase() === "elevation_details" ||
            work_type.length > 0) &&
          this.state.editSection
        ) {
          this.addSection();
        }
      }
    );
  };

  onCoopModalHide = () => this.setState({ showCoopModal: false });

  addBuldings = (value) => {
    this.setState({ loadingReq: true }, () => {
      this.saveBuilding(
        [
          ...this.state.coopUnits,
          {
            bid: uuid(),
            bname: this.state.newBuildingName,
          },
        ],
        value
      );
    });
  };

  saveBuilding = (coopUnits, remove) => {
    const {
      match: {
        params: { id },
      },
      updateBuilding,
    } = this.props;
    updateBuilding({
      requestId: id,
      propertyBuildings: coopUnits,
    })
      .then((res) => res.payload)
      .then((data) => {
        if (data.success) {
          this.setState(
            (state) => ({
              loadingReq: false,
              showCoopModal: true,
              coopUnits: coopUnits,
              newBuildingName: "",
              building: remove === state.building ? "" : state.building,
              editBuilding: "",
            }),
            () => {
              if (this.state.deleteBuilding) {
                this.deleteAllSectionDataByBuilding(remove);
              }
            }
          );
        }
      });
  };

  deleteBuilding = (id) => {
    this.setState({ loadingReq: true, showCoopModal: true }, () => {
      let building = this.state.coopUnits;
      const idx = building.findIndex((unit) => unit.bid === id);
      building.splice(idx, 1);
      this.saveBuilding(building, id);
    });
  };
  onEditBuilding = (unit, value = true) => {
    return this.setState((state) => {
      if (value) {
        return {
          editBuilding: unit,
          tempNewBuildingName: getBuildingName(this.state.coopUnits, unit),
        };
      } else {
        return {
          editBuilding: "",
        };
      }
    });
  };

  saveEditBuilding = (data) => {
    let building = this.state.coopUnits;
    building.splice(building.indexOf(data), 1, {
      bid: data.bid,
      bname: this.state.tempNewBuildingName,
    });
    this.saveBuilding(building);
  };

  onDeleteBuilding = (id, value = true) => {
    return this.setState(() => {
      if (value) {
        return {
          deleteBuilding: id,
          showCoopModal: false,
        };
      } else {
        return {
          deleteBuilding: "",
          showCoopModal: true,
        };
      }
    });
  };

  hideModal = () => {
    this.setState({ otherModal: false });
    this.clearEdit();
  };

  addAllData = (name, value, addIntoDb = true) => {
    let data = this.state[name];
    this.setState(
      {
        [name]: data.includes(value)
          ? this.removeFromAllData(name, value)
          : [...data, value],
      },
      () => {
        if (addIntoDb) {
          if (this.state.editSection) {
            this.addSection();
          }
        }
      }
    );
  };

  removeFromAllData = (name, value) => {
    let temp = this.state[name];
    temp.splice(temp.indexOf(value), 1);
    return temp;
  };

  displayOtherModalExtended = (
    show,
    label,
    hide,
    data,
    array,
    arrName,
    other,
    otherData,
    heading1,
    disable = false,
    addData,
    addSecondaryData
  ) => {
    return (
      <PopupModal
        show={show}
        heading={label}
        onHide={() => hide()}
        bold
        styles={{ padding: "1em 1em 0 1em", border: "none" }}
      >
        {heading1 && <label className="btnheading">{heading1}</label>}
        <Row style={{ margin: "0.2em .5em .5em .5em", alignItems: "center" }}>
          {data.map((item) => (
            <button
              style={
                item.name === "Architechtural"
                  ? { maxWidth: "20%", padding: "0" }
                  : { maxWidth: "15%", padding: "0" }
              }
              key={item.id}
              className={`ClickableBtnModal ${array === item.id ? "selected" : ""
                } `}
              onClick={() => addData(arrName, item.id)}
            >
              {item.name}
            </button>
          ))}
        </Row>

        {other && other.display && (
          <div style={{ margin: "1em 0" }}>
            {other.heading && (
              <label className="btnheading">{other.heading}</label>
            )}
            <Row
              className="Removebtn"
              style={{ margin: "0.2em .5em .5em .5em", alignItems: "center" }}
            >
              {otherData.map((item) => (
                <button
                  style={{ maxWidth: "20%", padding: "0" }}
                  key={item.id}
                  className={`ClickableBtnModal ${other.value === item.id ? "selected" : ""
                    } `}
                  onClick={() => addSecondaryData(other.name, item.id)}
                >
                  {item.name}
                </button>
              ))}
            </Row>
          </div>
        )}

        <ModalFooter
          onCancel={() => hide()}
          onSubmit={() => {
            hide();
            if (this.state.editSection) {
              this.addSection();
            }
          }}
          submitDisabled={disable}
        />
      </PopupModal>
    );
  };

  displayOtherModal = (
    show,
    label,
    hide,
    data,
    array,
    arrName,
    other,
    otherData,
    heading1,
    disable
  ) => {
    const isRemovalModal = label === "Select Removal Types";
    return this.displayOtherModalExtended(
      show,
      label,
      hide,
      data,
      array,
      arrName,
      other,
      otherData,
      heading1,
      disable ? true : false,
      isRemovalModal ? this.handleRemovalTypeChange : this.addFloor,
      isRemovalModal ? this.handleOtherRemovalTypeChange : this.addFloor
    );
  };

  getText = (value, data) => {
    const item = data.filter((dataI) => dataI.id === value);
    return item[0].name || "";
  };

  addIntoDimen = (name, value) => {
    if (typeof value === "undefined") {
      return this.setState((state) => ({ [name]: "" }));
    }
    return this.setState((state) => ({
      [name]: state[name] ? Number(`${state[name]}${value}`) : value,
    }));
  };

  handleAdd = () => {
    this.setState(
      (state) => ({
        lwfData: [
          ...state.lwfData,
          {
            lwfTotal: state.lwfTotal,
            l: state.l,
            w: state.w,
            f: state.f,
          },
        ],
        selectDimen: "l",
      }),
      () => {
        this.setState(
          { totalFlat: calculateTotalFlat(this.state.lwfData) },
          () => {
            this.clearDimen();
            this.addSection();
          }
        );
      }
    );
  };

  handleAddFromTab = () => {
    const { activeWood, selectData, selectDimen } = this.state;
    this.setState(
      (state) => ({
        [selectData]: [...state[selectData], Number(state[selectDimen])],
      }),
      () => {
        this.setState(
          {
            [activeWood]: numeral(this.getTotalFromData()).format("0,0"),
          },
          () => {
            this.clearTabData();
            this.addSection();
          }
        );
      }
    );
  };

  handleAddFlashing = () => {
    const { selectData, activeWood } = this.state;
    this.setState(
      (state) => ({
        [selectData]: [
          ...state[selectData],
          {
            linearFeet: Number(state.linearFeet),
            faceSize: Number(state.faceSize),
          },
        ],
      }),
      () => {
        this.setState(
          {
            [activeWood]: numeral(this.getTotalForFlashing()).format("0,0"),
          },
          () => {
            this.addSection();
            this.clearFlashingData();
            this.flashingTabData();
          }
        );
      }
    );
  };

  handleAddSkylight = () => {
    this.toggleSkylightDetails();
    const { selectData, flange, venting, hood_type, curb } = this.state;
    this.setState(
      (state) => ({
        [selectData]: [
          ...state[selectData],
          {
            height: Number(state.height),
            width: Number(state.width),
            count: Number(state.skCount),
            flange,
            venting,
            hood: hood_type,
            curb,
          },
        ],
      }),
      () => {
        this.setState({ skylightTotal: this.getTotalForSkylight() }, () => {
          this.clearSkylightData();
          this.resetSkylightDetails();
          this.addSection();
        });
      }
    );
  };

  onHandleEditSkylight = () => {
    this.setState(
      (state) => {
        let temp = state[state.selectData];
        temp.splice(state.editDimen, 1, {
          height: state.height,
          width: state.width,
          count: state.skCount,
          flange: state.flange,
          venting: state.venting,
          hood: state.hood_type,
          curb: state.curb,
        });
        return {
          [state.selectData]: temp,
        };
      },
      () => {
        this.setState({ skylightTotal: this.getTotalForSkylight() }, () => {
          this.clearEdit();
          this.toggleKeypad();
          this.clearSkylightData();
          this.resetSkylightDetails();
          this.addSection();
        });
      }
    );
  };

  clearEdit = () => {
    this.setState((state) => ({ editDimen: "", [state.selectDimen]: "" }));
  };

  clearSkylightData = () =>
    this.setState({ height: "", width: "", skCount: "" });
  clearFlashingData = () => this.setState({ faceSize: "", linearFeet: "" });

  clearTabData = () => {
    const { selectDimen } = this.state;
    this.setState({ [selectDimen]: "", editDimen: "" });
  };

  flashingTabData = () => {
    this.clearTabData();
    this.setState({ linearFeet: "", faceSize: "" });
  };

  getTotalFromData = () => {
    const { selectData } = this.state;
    return selectData !== ""
      ? this.state[selectData].reduce((acc, data) => (acc += data), 0)
      : 0;
  };

  getTotalForFlashing = () => {
    const { selectData } = this.state;
    return selectData !== ""
      ? this.state[selectData].reduce(
        (acc, data) => (acc += data.linearFeet),
        0
      )
      : 0;
  };

  getTotalForSkylight = () => {
    const { skylightData } = this.state;
    const total =
      skylightData.length > 0
        ? skylightData.reduce((acc, data) => (acc += data.count), 0)
        : 0;
    return total;
  };

  FlashingTabModal = () => {
    const {
      label,
      showFlashingTabModal,
      selectDimen,
      editDimen,
      viewTally,
      section,
      selectData,
    } = this.state;
    if (!showFlashingTabModal) return null;
    return (
      <PopupModal
        show={showFlashingTabModal}
        heading={label}
        onHide={() => this.hideFlashingTabModal()}
        bold
        styles={{ border: "none" }}
        scrollable={false}
      >
        <div className="_carsl">
          {!viewTally ? (
            <div className="slide-left view">
              {this.getTabTopData(
                section,
                selectData,
                numeral(this.getTotalForFlashing()).format("0,0")
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "0 auto",
                  width: "380px",
                }}
              >
                <div
                  style={{ width: "48%" }}
                  onClick={() => this.setState({ selectDimen: "linearFeet" })}
                  className={`_block ${selectDimen === "linearFeet" ? "selected" : "unselected"
                    }`}
                >
                  <label className="_block-lbl">Linear Feet</label>
                  <p className="_block-txt">{this.state["linearFeet"]}</p>
                </div>
                <div
                  style={{ width: "48%" }}
                  onClick={() => this.setState({ selectDimen: "faceSize" })}
                  className={`_block ${selectDimen === "faceSize" ? "selected" : "unselected"
                    }`}
                >
                  <label className="_block-lbl">Face Size</label>
                  <p className="_block-txt">{this.state["faceSize"]}</p>
                </div>
              </div>

              {Numpad(
                selectDimen,
                editDimen,
                this.handleAddFlashing,
                this.onHandleEditFlashing,
                this.state["linearFeet"] === "" ||
                this.state["faceSize"] === "" ||
                this.state["linearFeet"] === 0 ||
                this.state["faceSize"] === 0,
                this.addIntoDimen
              )}

              <Modal.Footer style={{ border: "none" }}>
                <Button
                  className="modified_btn"
                  variant="danger"
                  onClick={() => this.hideFlashingTabModal()}
                >
                  Close
                </Button>
                <Button
                  className="modified_btn"
                  onClick={this.toggleKeypad}
                  disabled={
                    selectData !== ""
                      ? this.state[selectData].length === 0
                      : false
                  }
                >
                  View Tally
                </Button>
              </Modal.Footer>
            </div>
          ) : (
            this.renderFlashingTallyUI(this.hideFlashingTabModal)
          )}
        </div>
      </PopupModal>
    );
  };

  renderFlashingTallyUI = (hide) => {
    const { section, selectData } = this.state;

    return (
      <div className="slide-right">
        {this.getTabTopData(
          section,
          selectData,
          numeral(this.getTotalForFlashing()).format("0,0")
        )}

        {selectData !== "" && this.state[selectData].length > 0 && (
          <>
            <table className="dimen_table">
              <thead>
                <tr>
                  <th></th>
                  <th>Linear Feet</th>
                  <th>Face Size(Inches)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{this.FlashingDataDisplay()}</tbody>
            </table>
          </>
        )}
        <Modal.Footer
          style={{ justifyContent: "space-between", border: "none" }}
        >
          <div>
            <Button
              className="modified_btn"
              style={{ float: "left" }}
              onClick={this.toggleKeypad}
            >
              Keypad
            </Button>
          </div>
          {this.showPagination()}
          <div>
            <Button
              className="modified_btn"
              variant="danger"
              onClick={() => hide()}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </div>
    );
  };

  FlashingDataDisplay = () => {
    const { page_num, max_item } = this.state;
    const lastI = page_num * max_item;
    const startI = lastI - max_item;
    return this.state[this.state.selectData]
      .slice(startI, lastI)
      .map((data, i) => (
        <tr key={i}>
          <td>
            <Button onClick={() => this.onEditTabFlashing(data, i)}>
              Edit
            </Button>
          </td>
          <td>{data.linearFeet}</td>
          <td>{data.faceSize}</td>
          <td>
            <Button
              variant="danger"
              onClick={() => this.setOnDelete(() => this.onDeleteTabDimen(i))}
            >
              Delete
            </Button>
          </td>
        </tr>
      ));
  };

  WoodModalEntry = (selectDimen, dimenValue, disable = false) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "380px",
          paddingTop: "0.2em",
          fontSize: "2.1em",
        }}
        onClick={() => this.setState({ selectDimen: selectDimen })}
        className={`_block ${disable ? "unselected" : selectDimen ? "selected" : "unselected"
          }`}
      >
        <p className="_block-txt">{dimenValue}</p>
      </div>
    </div>
  );

  toggleWoodEntry = () =>
    this.setState((state) => ({
      showWoodEntry: !state.showWoodEntry,
      showMiscEntry: !state.showMiscEntry,
      label: `${!state.showWoodEntry
          ? state.viewTally
            ? `${state.activeLabel} Tally`
            : `Enter ${state.activeLabel}`
          : `Enter ${state.activeLabel} - ${state.dataKeys
            .slice(1)
            .toString()
            .replace(",", " & ")
            .replace(",", " & ")}`
        }`,
    }));

  MiscTabModal = (
    label,
    showMiscModal,
    selectDimen,
    editDimen,
    viewTally,
    section,
    selectData,
    hideTabModal,
    showMiscEntry,
    toggleWoodEntry,
    dataValue,
    dimenValue,
    totalLbl,
    dataKeys,
    activeWood
  ) => {
    if (!showMiscModal) return null;
    return (
      <PopupModal
        show={showMiscModal}
        heading={label}
        onHide={() => hideTabModal()}
        bold
        styles={{ border: "none" }}
        scrollable={false}
      >
        <div className="_carsl">
          {!viewTally ? (
            showMiscEntry ? (
              <div className="slide-left view">
                {this.getTabTopData(
                  section,
                  selectData,
                  numeral(this.getTotalOfWood(totalLbl)).format("0,0")
                )}

                {this.WoodModalEntry(selectDimen, dimenValue)}

                {Numpad(
                  selectDimen,
                  editDimen,
                  this.toggleWoodEntry,
                  this.toggleWoodEntry,
                  dimenValue === "" || dimenValue === 0,
                  this.addIntoDimen,
                  "Next"
                )}

                <Modal.Footer style={{ border: "none" }}>
                  <Button
                    className="modified_btn"
                    variant="danger"
                    onClick={() => hideTabModal()}
                  >
                    Close
                  </Button>
                  <Button
                    className="modified_btn"
                    onClick={this.toggleKeypad}
                    disabled={
                      selectData !== "" ? dataValue.length === 0 : false
                    }
                  >
                    View Tally
                  </Button>
                </Modal.Footer>
              </div>
            ) : (
              this.showMiscTypeEntry(
                selectDimen,
                dimenValue,
                toggleWoodEntry,
                activeWood
              )
            )
          ) : (
            this.renderMiscTallyUI(hideTabModal, totalLbl)
          )}
        </div>
      </PopupModal>
    );
  };

  showMiscTypeEntry = (
    selectDimen,
    dimenValue,
    toggleWoodEntry,
    activeWood
  ) => (
    <div className="slide-right">
      {this.WoodModalEntry(selectDimen, dimenValue, true)}
      {this.switchMiscTypeEntry(activeWood, toggleWoodEntry)}
    </div>
  );

  switchMiscTypeEntry = (
    activeWood,
    toggleWoodEntry,
    dataKeys = this.state.dataKeys
  ) => {
    switch (activeWood) {
      case "M0":
        return (
          <>
            <Row style={{ margin: "2em auto", width: "76%" }}>
              <Col>
                {DisplayMetric({
                  label: "Type",
                  array: this.state.type,
                  data: METAL[0],
                  prevMetric: [],
                  size: "28%",
                  icon: "",
                  width: "180px",
                  styles: true,
                  addFloor: this.addFloor,
                })}
              </Col>
            </Row>
            {this.showWoodTypeFooter(
              toggleWoodEntry,
              () => this.saveChangesWoodTypes(),
              () => this.saveWoodTypes(),
              dataKeys
            )}
          </>
        );

      case "M1":
        return (
          <>
            <Row style={{ margin: "1em auto", width: "50%" }}>
              <Col>
                {DisplayMetric({
                  label: "Shape",
                  array: this.state.shape,
                  data: CHIMENYCAPS[0],
                  prevMetric: [],
                  size: "28%",
                  icon: "",
                  width: "100px",
                  styles: true,
                  addFloor: this.addFloor,
                })}
              </Col>
            </Row>
            <Row style={{ margin: "1em auto", width: "50%" }}>
              <Col>
                {DisplayMetric({
                  label: "Material",
                  array: this.state.material,
                  data: CHIMENYCAPS[1],
                  prevMetric: [],
                  size: "28%",
                  icon: "",
                  width: "100px",
                  styles: true,
                  addFloor: this.addFloor,
                })}
              </Col>
            </Row>
            {this.state.shape && (
              <Row style={{ margin: "1em auto", width: "50%" }}>
                <Col>
                  <label
                    style={{
                      textAlign: "center",
                      display: "block",
                      fontWeight: "bold",
                    }}
                  >
                    Size / Dimensions (Optional)
                  </label>
                </Col>
              </Row>
            )}
            <Row
              style={{
                margin: "1em auto",
                width: "50%",
                alignItems: "baseline",
              }}
            >
              {this.checkOtherMiscValue(
                CHIMENYCAPS[0],
                this.state.shape,
                "inches"
              ) && (
                  <Col>
                    <Form.Group style={styles.formInput}>
                      <Form.Control
                        type="number"
                        min={0}
                        name="inches"
                        placeholder="Inches"
                        value={this.state.inches}
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </Col>
                )}
              {this.checkOtherMiscValue(
                CHIMENYCAPS[0],
                this.state.shape,
                "!inches"
              ) && (
                  <Col>
                    <Form.Group style={styles.formInput}>
                      <Form.Control
                        type="number"
                        min={0}
                        name="length"
                        placeholder="Length"
                        value={this.state.length}
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                  </Col>
                )}
              {this.checkOtherMiscValue(
                CHIMENYCAPS[0],
                this.state.shape,
                "!inches"
              ) && (
                  <>
                    <div className="icon-btw">{getIcon(faTimes)}</div>
                    <Col>
                      <Form.Group style={styles.formInput}>
                        <Form.Control
                          type="number"
                          min={0}
                          name="width"
                          placeholder="Width"
                          value={this.state.width}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </>
                )}
            </Row>

            {this.showWoodTypeFooter(
              toggleWoodEntry,
              () =>
                this.saveChangesWoodTypes(
                  this.state.dataKeys,
                  this.checkOtherMiscValue(
                    CHIMENYCAPS[0],
                    this.state.shape,
                    "inches"
                  )
                    ? {
                      inches: this.state.inches,
                      dimensions: `${this.state.inches ? `${this.state.inches}"` : ""
                        }`,
                    }
                    : {
                      length: this.state.length,
                      width: this.state.width,
                      dimensions: `${this.state.length && this.state.width
                          ? `${this.state.length}" X ${this.state.width}"`
                          : ""
                        }`,
                    },
                  3
                ),
              () =>
                this.saveWoodTypes(
                  this.checkOtherMiscValue(
                    CHIMENYCAPS[0],
                    this.state.shape,
                    "inches"
                  )
                    ? {
                      inches: this.state.inches,
                      dimensions: `${this.state.inches ? `${this.state.inches}"` : ""
                        }`,
                    }
                    : {
                      length: this.state.length,
                      width: this.state.width,
                      dimensions: `${this.state.length && this.state.width
                          ? `${this.state.length}" X ${this.state.width}"`
                          : ""
                        }`,
                    },
                  3
                ),
              dataKeys,
              3
            )}
          </>
        );

      case "M2":
        return (
          <>
            <Row style={{ margin: "1em auto", width: "50%" }}>
              <Col>
                {DisplayMetric({
                  label: "Type",
                  array: this.state.type,
                  data: HANDNAILS[0],
                  prevMetric: [],
                  size: "28%",
                  icon: "",
                  width: "100px",
                  styles: true,
                  addFloor: this.addFloor,
                })}
              </Col>
            </Row>
            <Row style={{ margin: "1em auto", width: "60%" }}>
              <Col>
                {DisplayMetric({
                  label: "Size",
                  array: this.state.size,
                  data: HANDNAILS[1],
                  prevMetric: [],
                  size: "28%",
                  icon: "",
                  width: "70px",
                  styles: true,
                  addFloor: this.addFloor,
                })}
              </Col>
            </Row>
            {this.showWoodTypeFooter(
              toggleWoodEntry,
              () => this.saveChangesWoodTypes(),
              () => this.saveWoodTypes(),
              dataKeys
            )}
          </>
        );

      case "M3":
        return (
          <>
            <Row style={{ margin: "1em auto", width: "50%" }}>
              <Col>
                {DisplayMetric({
                  label: "Type",
                  array: this.state.type,
                  data: COMMONNAILS[0],
                  prevMetric: [],
                  size: "28%",
                  icon: "",
                  width: "180px",
                  styles: true,
                  addFloor: this.addFloor,
                })}
              </Col>
            </Row>
            <Row style={{ margin: "1em auto", width: "60%" }}>
              <Col>
                {DisplayMetric({
                  label: "Size",
                  array: this.state.size,
                  data: COMMONNAILS[1],
                  prevMetric: [],
                  size: "28%",
                  icon: "",
                  width: "100px",
                  styles: true,
                  addFloor: this.addFloor,
                })}
              </Col>
            </Row>
            {this.showWoodTypeFooter(
              toggleWoodEntry,
              () => this.saveChangesWoodTypes(),
              () => this.saveWoodTypes(),
              dataKeys
            )}
          </>
        );

      case "M4":
        return (
          <>
            <Row style={{ margin: "1em auto", width: "50%" }}>
              <Col>
                {DisplayMetric({
                  label: "Material",
                  array: this.state.material,
                  data: METALSHEET,
                  prevMetric: [],
                  size: "28%",
                  icon: "",
                  width: "180px",
                  styles: true,
                  addFloor: this.addFloor,
                })}
              </Col>
            </Row>
            {this.state.material &&
              dataKeys.slice(2).map((key, i) => (
                <Row
                  key={`M4${i}`}
                  style={{ margin: "1em auto", width: "70%" }}
                >
                  <Col>
                    {DisplayMetric({
                      label: key,
                      array: this.state[key.toLowerCase()],
                      data: METALSHEETDATA[this.state.material][i],
                      prevMetric: [],
                      size: "28%",
                      icon: "",
                      width: "120px",
                      styles: true,
                      showLabel: true,
                      optional: false,
                      displayLabel: `${key}s & ${DIMENSIONNAME[this.state.material]["name"]
                        }`,
                      addFloor: this.addFloor,
                    })}
                  </Col>
                </Row>
              ))}
            {this.showWoodTypeFooter(
              toggleWoodEntry,
              () => this.saveChangesWoodTypes(),
              () => this.saveWoodTypes(),
              dataKeys
            )}
          </>
        );

      default:
        return null;
    }
  };

  checkOtherMiscValue = (array, data, value) => {
    if (data) {
      return array.some((key) => key.id === data && key.other === value);
    }
    return false;
  };

  switchMiscTallyData = (activeWood, selectData, dataKeys) => {
    switch (activeWood) {
      case "M0":
        return this.MiscDataDisplay(this.state[selectData], dataKeys, METAL);

      case "M1":
        return this.ChimenycapsDataDisplay(
          this.state[selectData],
          dataKeys,
          CHIMENYCAPS
        );

      case "M2":
        return this.MiscDataDisplay(
          this.state[selectData],
          dataKeys,
          HANDNAILS
        );

      case "M3":
        return this.MiscDataDisplay(
          this.state[selectData],
          dataKeys,
          COMMONNAILS
        );

      case "M4":
        return this.FasciaDataDisplay(
          this.state[selectData],
          dataKeys,
          METALSHEET,
          METALSHEETDATA
        );

      default:
        return null;
    }
  };

  renderMiscTallyUI = (hide, totalLbl) => {
    const { section, selectData, dataKeys, activeWood } = this.state;

    return (
      <div className="slide-right">
        {this.getTabTopData(
          section,
          selectData,
          numeral(this.getTotalOfWood(totalLbl)).format("0,0")
        )}

        {selectData !== "" && this.state[selectData].length > 0 && (
          <>
            <table className="dimen_table">
              <thead>
                <tr>
                  <th></th>
                  {dataKeys.map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.switchMiscTallyData(activeWood, selectData, dataKeys)}
              </tbody>
            </table>
          </>
        )}
        <Modal.Footer
          style={{ justifyContent: "space-betwwen", border: "none" }}
        >
          <div>
            <Button
              className="modified_btn"
              style={{ float: "left" }}
              onClick={this.toggleKeypad}
            >
              Keypad
            </Button>
          </div>
          {this.showPagination()}
          <div>
            <Button
              className="modified_btn"
              variant="danger"
              onClick={() => hide()}
              style={{ margin: "0 1em" }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </div>
    );
  };

  ChimenycapsDataDisplay = (data, list, array) => {
    const { page_num, max_item } = this.state;
    const lastI = page_num * max_item;
    const startI = lastI - max_item;
    return data.slice(startI, lastI).map((data, i) => (
      <tr key={i}>
        <td>
          <Button onClick={() => this.onEditWoodEntry(data, i, list)}>
            Edit
          </Button>
        </td>
        {list.map((key, i) => (
          <td key={i}>
            {i < 1 || i > 2
              ? data[key.toLowerCase()]
              : getDataLabel(array[i - 1], data[key.toLowerCase()])}
          </td>
        ))}
        <td>
          <Button
            variant="danger"
            onClick={() => this.setOnDelete(() => this.deleteWoodEntry(i))}
          >
            Delete
          </Button>
        </td>
      </tr>
    ));
  };

  MiscDataDisplay = (data, list, array) => {
    const { page_num, max_item } = this.state;
    const lastI = page_num * max_item;
    const startI = lastI - max_item;
    return data.slice(startI, lastI).map((data, i) => (
      <tr key={i}>
        <td>
          <Button onClick={() => this.onEditWoodEntry(data, i, list)}>
            Edit
          </Button>
        </td>
        {list.map((key, i) => (
          <td key={i}>
            {i === 0
              ? data[key.toLowerCase()]
              : getDataLabel(array[i - 1], data[key.toLowerCase()])}
          </td>
        ))}
        <td>
          <Button
            variant="danger"
            onClick={() => this.setOnDelete(() => this.deleteWoodEntry(i))}
          >
            Delete
          </Button>
        </td>
      </tr>
    ));
  };

  WoodTabModal = (
    label,
    showWoodModal,
    selectDimen,
    editDimen,
    viewTally,
    section,
    selectData,
    hideTabModal,
    showWoodEntry,
    toggleWoodEntry,
    dataValue,
    dimenValue,
    totalLbl,
    dataKeys,
    activeWood
  ) => {
    if (!showWoodModal) return null;
    return (
      <PopupModal
        show={showWoodModal}
        heading={label}
        onHide={() => hideTabModal()}
        bold
        styles={{ border: "none" }}
        scrollable={false}
      >
        <div className="_carsl">
          {!viewTally ? (
            showWoodEntry ? (
              <div className="slide-left view">
                {this.getTabTopData(
                  section,
                  selectData,
                  numeral(this.getTotalOfWood(totalLbl)).format("0,0")
                )}

                {this.WoodModalEntry(selectDimen, dimenValue)}

                {Numpad(
                  selectDimen,
                  editDimen,
                  this.toggleWoodEntry,
                  this.toggleWoodEntry,
                  dimenValue === "" || dimenValue === 0,
                  this.addIntoDimen,
                  this.state.activeWood === "T19" ||
                    this.state.activeWood === "T20"
                    ? "Next"
                    : "Save"
                )}

                <Modal.Footer style={{ border: "none" }}>
                  <Button
                    className="modified_btn"
                    variant="danger"
                    onClick={() => hideTabModal()}
                  >
                    Close
                  </Button>
                  <Button
                    className="modified_btn"
                    onClick={this.toggleKeypad}
                    disabled={
                      selectData !== "" ? dataValue.length === 0 : false
                    }
                  >
                    View Tally
                  </Button>
                </Modal.Footer>
              </div>
            ) : (
              this.showWoodTypeEntry(
                selectDimen,
                dimenValue,
                toggleWoodEntry,
                activeWood
              )
            )
          ) : (
            this.renderWoodTallyUI(hideTabModal, totalLbl)
          )}
        </div>
      </PopupModal>
    );
  };

  renderWoodTallyUI = (hide, totalLbl) => {
    const { section, selectData, dataKeys, activeWood } = this.state;

    return (
      <div className="slide-right">
        {this.getTabTopData(
          section,
          selectData,
          numeral(this.getTotalOfWood(totalLbl)).format("0,0")
        )}

        {selectData !== "" && this.state[selectData].length > 0 && (
          <>
            <table className="dimen_table">
              <thead>
                <tr>
                  <th></th>
                  {dataKeys.map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.switchWoodTallyData(activeWood, selectData, dataKeys)}
              </tbody>
            </table>
          </>
        )}
        <Modal.Footer
          style={{ justifyContent: "space-between", border: "none" }}
        >
          <div>
            <Button
              className="modified_btn"
              style={{ float: "left" }}
              onClick={this.toggleKeypad}
            >
              Keypad
            </Button>
          </div>
          {this.showPagination()}
          <div>
            <Button
              className="modified_btn"
              variant="danger"
              onClick={() => hide()}
              style={{ margin: "0 1em" }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </div>
    );
  };

  onEditWoodEntry = (data, i, list) => {
    let obj = { editDimen: i };
    list.map((key, i) =>
      i === 0
        ? (obj = { ...obj, [this.state.selectDimen]: data[key.toLowerCase()] })
        : (obj = { ...obj, [key.toLowerCase()]: data[key.toLowerCase()] })
    );
    this.setState({
      ...obj,
    });
    this.toggleKeypad();
  };

  WoodDataDisplay = (data, list, array) => {
    const { page_num, max_item } = this.state;
    const lastI = page_num * max_item;
    const startI = lastI - max_item;
    return data.slice(startI, lastI).map((data, i) => (
      <tr key={i}>
        <td>
          <Button onClick={() => this.onEditWoodEntry(data, i, list)}>
            Edit
          </Button>
        </td>
        {list.map((key, i) => (
          <td key={i}>
            {i === 0
              ? data[key.toLowerCase()]
              : data[key.toLowerCase()]
                ? getDataLabel(array[i - 1], data[key.toLowerCase()])
                : ""}
          </td>
        ))}
        <td>
          <Button
            variant="danger"
            onClick={() => this.setOnDelete(() => this.deleteWoodEntry(i))}
          >
            Delete
          </Button>
        </td>
      </tr>
    ));
  };

  getFasciaLabel = (array, data) => {
    const match = array.find((key) => key.id === data);
    if (match) {
      return match.name;
    }
    return "";
  };

  FasciaDataDisplay = (dataList, list, array1, array2) => {
    const { page_num, max_item } = this.state;
    const lastI = page_num * max_item;
    const startI = lastI - max_item;
    return dataList.slice(startI, lastI).map((data, i) => {
      const array = array2[data.material];
      return (
        <tr key={i}>
          <td>
            <Button onClick={() => this.onEditWoodEntry(data, i, list)}>
              Edit
            </Button>
          </td>
          {list.map((key, i) => (
            <td key={key}>
              {i === 0
                ? data[key.toLowerCase()]
                : i < 2
                  ? getDataLabel(array1, data[key.toLowerCase()])
                  : `${this.getFasciaLabel(
                    array[i - 2],
                    data[key.toLowerCase()]
                  )}`}
            </td>
          ))}
          <td>
            <Button
              variant="danger"
              onClick={() => this.setOnDelete(() => this.deleteWoodEntry(i))}
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });
  };

  saveWoodTypes = (otherData = {}, lastIndex) => {
    let obj = { ...otherData };
    const dataKeys = this.state.dataKeys;
    dataKeys
      .slice(1, lastIndex)
      .map(
        (key) =>
          (obj = { ...obj, [key.toLowerCase()]: this.state[key.toLowerCase()] })
      );

    this.setState(
      (state) => ({
        [state.selectData]: [
          ...state[state.selectData],
          {
            [dataKeys[0].toLowerCase()]: state[state.selectDimen],
            ...obj,
          },
        ],
      }),
      () => {
        this.addTotalWood(this.state.activeWood, dataKeys[0].toLowerCase());
        this.toggleWoodEntry();
        this.resetWoodEntry(dataKeys);
      }
    );
  };

  saveChangesWoodTypes = (
    dataKeys = this.state.dataKeys,
    otherData = {},
    lastIndex
  ) => {
    let obj = { ...otherData };
    dataKeys
      .slice(1, lastIndex)
      .map(
        (key) =>
          (obj = { ...obj, [key.toLowerCase()]: this.state[key.toLowerCase()] })
      );
    this.setState(
      (state) => {
        let temp = state[state.selectData];
        temp.splice(state.editDimen, 1, {
          [dataKeys[0].toLowerCase()]: state[state.selectDimen],
          ...obj,
        });
        return {
          [state.selectData]: temp,
          editDimen: "",
        };
      },
      () => {
        this.toggleWoodEntry();
        this.resetWoodEntry(dataKeys);
        this.toggleKeypad();
        this.addTotalWood(this.state.activeWood, dataKeys[0].toLowerCase());
      }
    );
  };

  deleteWoodEntry = (index, dataKeys = this.state.dataKeys) => {
    this.setState(
      (state) => {
        let temp = state[state.selectData];
        temp.splice(index, 1);
        return {
          [state.selectData]: temp,
        };
      },
      () => {
        this.resetWoodEntry(dataKeys);
        this.hideDeleteModal();
        this.addTotalWood(this.state.activeWood, dataKeys[0].toLowerCase());
      }
    );
  };

  addTotalWood = (name, value) => {
    this.setState({ [name]: this.getTotalOfWood(value) }, () =>
      this.addSection()
    );
  };

  getTotalOfWood = (label) => {
    const data = label ? label : "feet";
    const { selectData } = this.state;
    const total =
      selectData !== "" && this.state[selectData].length > 0
        ? this.state[selectData].reduce((acc, value) => {
          return (acc = acc + value[data]);
        }, 0)
        : 0;
    return total;
  };

  resetWoodEntry = (dataKeys) => {
    let obj = {};
    dataKeys.slice(1).map((key) => (obj = { ...obj, [key.toLowerCase()]: "" }));

    this.setState((state) => ({
      [state.selectDimen]: "",
      ...obj,
    }));
  };
  showWoodTypeEntry = (
    selectDimen,
    dimenValue,
    toggleWoodEntry,
    activeWood
  ) => (
    <div className="slide-right">
      {this.WoodModalEntry(selectDimen, dimenValue, true)}
      {this.switchWoodTypeEntry(activeWood, toggleWoodEntry)}
    </div>
  );

  switchWoodTypeEntry = (
    activeWood,
    toggleWoodEntry,
    dataKeys = this.state.dataKeys
  ) => {
    switch (activeWood) {
      case "T16":
        return (
          <>
            <Row style={{ margin: "1em auto", width: "58%" }}>
              <Col>
                {DisplayMetric({
                  label: "Size",
                  array: this.state.size,
                  data: PLYWOODSIZE,
                  prevMetric: [],
                  size: "28%",
                  icon: "",
                  width: "60px",
                  styles: true,
                  addFloor: this.addFloor,
                })}
              </Col>
            </Row>
            <Row style={{ margin: "1em auto", width: "50%" }}>
              <Col>
                {DisplayMetric({
                  label: "Type",
                  array: this.state.type,
                  data: PLYWOODTYPE,
                  prevMetric: [],
                  size: "28%",
                  icon: "",
                  width: "180px",
                  styles: true,
                  addFloor: this.addFloor,
                })}
              </Col>
            </Row>
            {this.showWoodTypeFooter(
              toggleWoodEntry,
              () => this.saveChangesWoodTypes(),
              () => this.saveWoodTypes(),
              dataKeys
            )}
          </>
        );
      case "T17":
        return (
          <>
            {dataKeys.slice(1).map((key, i) => (
              <Row key={key} style={{ margin: "1em auto", width: "58%" }}>
                <Col>
                  {DisplayMetric({
                    label: key,
                    array: this.state[key.toLowerCase()],
                    data: RAFTER[i],
                    prevMetric: [],
                    size: "28%",
                    icon: "",
                    width: "80px",
                    styles: true,
                    addFloor: this.addFloor,
                  })}
                </Col>
              </Row>
            ))}

            {this.showWoodTypeFooter(
              toggleWoodEntry,
              () => this.saveChangesWoodTypes(),
              () => this.saveWoodTypes(),
              dataKeys
            )}
          </>
        );

      case "T18":
        return (
          <>
            {dataKeys.slice(1).map((key, i) => (
              <Row key={key} style={{ margin: "1em auto", width: "60%" }}>
                <Col>
                  {DisplayMetric({
                    label: key,
                    array: this.state[key.toLowerCase()],
                    data: FURRING[i],
                    prevMetric: [],
                    size: "28%",
                    icon: "",
                    width: "100px",
                    styles: true,
                    addFloor: this.addFloor,
                  })}
                </Col>
              </Row>
            ))}

            {this.showWoodTypeFooter(
              toggleWoodEntry,
              () => this.saveChangesWoodTypes(),
              () => this.saveWoodTypes(),
              dataKeys
            )}
          </>
        );

      case "T19":
        return (
          <>
            <Row style={{ margin: "1em auto", width: "60%" }}>
              <Col>
                {DisplayMetric({
                  label: "material",
                  array: this.state.material,
                  data: FASCIADATA,
                  prevMetric: [],
                  size: "28%",
                  icon: "",
                  width: "180px",
                  styles: true,
                  showLabel: false,
                  optional: false,
                  displayLabel: undefined,
                  exec: true,
                  addFloor: this.addFloor,
                })}
              </Col>
            </Row>
            {dataKeys.slice(2).map((key, i) => (
              <Row key={key} style={{ margin: "1em auto", width: "60%" }}>
                <Col>
                  {DisplayMetric({
                    label: key,
                    array: this.state[key.toLowerCase()],
                    data: FASCIA[this.state.material || "FASCIA009"][i],
                    prevMetric: [],
                    size: "28%",
                    icon: "",
                    width: "100px",
                    styles: true,
                    addFloor: this.addFloor,
                  })}
                </Col>
              </Row>
            ))}
            {this.showWoodTypeFooter(
              toggleWoodEntry,
              () => this.saveChangesWoodTypes(),
              () => this.saveWoodTypes(),
              dataKeys
            )}
          </>
        );

      case "T20":
        return (
          <>
            {dataKeys.slice(1, 3).map((key, i) => (
              <Row key={key} style={{ margin: "1em auto", width: "60%" }}>
                <Col>
                  {DisplayMetric({
                    label: key,
                    array: this.state[key.toLowerCase()],
                    data: CAPPING[i],
                    prevMetric: [],
                    size: "28%",
                    icon: "",
                    width: "100px",
                    styles: true,
                    showLabel: true,
                    optional: i === 1,
                    addFloor: this.addFloor,
                  })}
                </Col>
              </Row>
            ))}
            {this.checkOtherValue(CAPPING[1], this.state.color) && (
              <Row style={{ margin: "1em auto", width: "60%" }}>
                <Col>
                  <Form.Group style={styles.formInput}>
                    <Form.Control
                      type="text"
                      name="otherColor"
                      value={this.state.otherColor}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            {this.showWoodTypeFooter(
              toggleWoodEntry,
              () =>
                this.saveChangesWoodTypes(dataKeys, {
                  otherColor: this.state.otherColor,
                }),
              () =>
                this.saveWoodTypes({
                  otherColor: this.state.otherColor,
                }),
              dataKeys,
              2
            )}
          </>
        );

      default:
        return null;
    }
  };

  checkOtherValue = (array, data) => {
    if (data) {
      return array.some((key) => key.id === data && key.other);
    }

    return false;
  };
  checkOtherArrayValue = (array, data) => {
    if (data.length > 0) {
      return array.some((key) => data.includes(key.id) && key.other);
    }

    return false;
  };
  switchWoodTallyData = (activeWood, selectData, dataKeys) => {
    switch (activeWood) {
      case "T16":
        return this.WoodDataDisplay(this.state[selectData], dataKeys, [
          PLYWOODSIZE,
          PLYWOODTYPE,
        ]);
      case "T17":
        return this.WoodDataDisplay(this.state[selectData], dataKeys, RAFTER);

      case "T18":
        return this.WoodDataDisplay(this.state[selectData], dataKeys, FURRING);

      case "T19":
        return this.FasciaDataDisplay(
          this.state[selectData],
          dataKeys,
          FASCIADATA,
          FASCIA
        );
      case "T20":
        return this.WoodDataDisplay(this.state[selectData], dataKeys, CAPPING);

      default:
        return null;
    }
  };

  showWoodTypeFooter = (
    toggleWoodEntry,
    saveChangesWoodTypes,
    saveWoodTypes,
    dataKeys,
    disableBy
  ) => {
    return (
      <Modal.Footer style={{ justifyContent: "flex-end", border: "none" }}>
        <div>
          <Button
            className="modified_btn"
            variant="danger"
            onClick={() => toggleWoodEntry()}
          >
            Close
          </Button>
          {this.state.editDimen !== "" ? (
            <Button
              className="modified_btn"
              disabled={dataKeys
                .slice(1, disableBy)
                .some((key) => !this.state[key.toLowerCase()])}
              onClick={saveChangesWoodTypes}
            >
              Save Changes
            </Button>
          ) : (
            <Button
              className="modified_btn"
              disabled={dataKeys
                .slice(1, disableBy)
                .some((key) => !this.state[key.toLowerCase()])}
              onClick={saveWoodTypes}
            >
              Save
            </Button>
          )}
        </div>
      </Modal.Footer>
    );
  };

  AccessoriesTabModal = () => {
    const {
      label,
      showTabModal,
      selectDimen,
      editDimen,
      viewTally,
      section,
      selectData,
    } = this.state;
    if (!showTabModal) return null;
    return (
      <PopupModal
        show={showTabModal}
        heading={label}
        onHide={() => this.hideTabModal()}
        bold
        styles={{ border: "none" }}
        scrollable={false}
      >
        <div className="_carsl">
          {!viewTally ? (
            <div className="slide-left view">
              {this.getTabTopData(
                section,
                selectData,
                numeral(this.getTotalFromData()).format("0,0")
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "380px",
                    paddingTop: "0.2em",
                    fontSize: "2.1em",
                  }}
                  onClick={() => this.setState({ selectDimen: selectDimen })}
                  className={`_block ${selectDimen ? "selected" : "unselected"
                    }`}
                >
                  <p className="_block-txt">{this.state[selectDimen]}</p>
                </div>
              </div>

              {Numpad(
                selectDimen,
                editDimen,
                this.handleAddFromTab,
                this.onHandleEditTabDimen,
                this.state[selectDimen] === "" || this.state[selectDimen] === 0,
                this.addIntoDimen
              )}

              <Modal.Footer style={{ border: "none" }}>
                <Button
                  className="modified_btn"
                  variant="danger"
                  onClick={() => this.hideTabModal()}
                >
                  Close
                </Button>
                <Button
                  className="modified_btn"
                  onClick={this.toggleKeypad}
                  disabled={
                    selectData !== ""
                      ? this.state[selectData].length === 0
                      : false
                  }
                >
                  View Tally
                </Button>
              </Modal.Footer>
            </div>
          ) : (
            this.renderTabTallyUI(this.hideTabModal)
          )}
        </div>
      </PopupModal>
    );
  };

  getTabTopData = (section, selectData, total) => (
    <Row>
      <Col>
        <div className="_lbl">Section</div>
        <p className="_txt">{section}</p>
      </Col>

      <Col>
        <div className="_lbl">
          Total{" "}
          {this.state.totalLabel !== "" ? this.state.totalLabel : "Linear Feet"}
        </div>
        <p className="_txt">{total}</p>
      </Col>
      <Col>
        <div className="_lbl">Entries</div>
        <p className="_txt">
          {selectData !== "" ? this.state[selectData].length : 0}
        </p>
      </Col>
    </Row>
  );

  renderTabTallyUI = (hide) => {
    const { section, selectData } = this.state;

    return (
      <div className="slide-right">
        {this.getTabTopData(
          section,
          selectData,
          numeral(this.getTotalFromData()).format("0,0")
        )}

        {selectData !== "" && this.state[selectData].length > 0 && (
          <>
            <table className="dimen_table">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{this.TabDataDisplay()}</tbody>
            </table>
          </>
        )}
        <Modal.Footer
          style={{ justifyContent: "space-between", border: "none" }}
        >
          <div>
            <Button
              className="modified_btn"
              style={{ float: "left" }}
              onClick={this.toggleKeypad}
            >
              Keypad
            </Button>
          </div>
          {this.showPagination()}
          <div>
            <Button
              className="modified_btn"
              variant="danger"
              onClick={() => hide()}
              style={{ margin: "0 1em" }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </div>
    );
  };

  showPagination = () => (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      <button
        aria-label="Prev"
        className="pagination_btns"
        onClick={this.prevPage}
        disabled={this.state.page_num === 1}
      >
        {getIcon(faChevronLeft)}
      </button>
      <p className="page_num">{this.state.page_num}</p>
      <button
        aria-label="Next"
        className="pagination_btns"
        disabled={
          this.state.page_num * this.state.max_item >=
          this.state[this.state.selectData].length
        }
        onClick={this.nextPage}
      >
        {getIcon(faChevronRight)}
      </button>
    </div>
  );

  prevPage = () => this.setState((state) => ({ page_num: state.page_num - 1 }));

  nextPage = () => this.setState((state) => ({ page_num: state.page_num + 1 }));

  TabDataDisplay = () => {
    const { page_num, max_item } = this.state;
    const lastI = page_num * max_item;
    const startI = lastI - max_item;
    return this.state[this.state.selectData]
      .slice(startI, lastI)
      .map((data, i) => (
        <tr key={i}>
          <td>
            <Button
              onClick={() => this.onEditTabDimen(data, i)}
              disabled={this.state.editDimen === i}
            >
              Edit
            </Button>
          </td>
          <td>{data}</td>
          <td>
            <Button
              variant="danger"
              onClick={() => this.setOnDelete(() => this.onDeleteTabDimen(i))}
            >
              Delete
            </Button>
          </td>
        </tr>
      ));
  };

  onEditTabDimen = (data, i) => {
    const { selectDimen } = this.state;
    this.setState({ [selectDimen]: data, editDimen: i });
    this.toggleKeypad();
  };

  onEditTabFlashing = (data, i) => {
    this.setState({
      linearFeet: data.linearFeet,
      faceSize: data.faceSize,
      editDimen: i,
    });
    this.toggleKeypad();
  };

  onEditTabSkylight = (data, i) => {
    this.setState({
      height: data.height,
      width: data.width,
      skCount: data.count,
      venting: data.venting,
      curb: data.curb,
      flange: data.flange,
      hood_type: data.hood,
      editDimen: i,
      viewTally: false,
      displaySkylightDetails: false,
    });
  };

  onDeleteTabDimen = (i) => {
    const { activeWood } = this.state;

    this.setState(
      (state) => {
        let temp = state[state.selectData];
        temp.splice(i, 1);
        return { [state.selectData]: temp };
      },
      () => {
        this.setState(
          {
            [activeWood]: this.getTotalData(),
          },
          () => {
            this.clearTabData();
            this.flashingTabData();
            this.addSection();
          }
        );
      }
    );
  };

  getTotalData = () => {
    const { activeWood } = this.state;
    switch (activeWood) {
      case "T0":
      case "T1":
      case "T2":
      case "T3":
      case "T4":
      case "T5":
      case "T6":
      case "T7":
      case "T8":
      case "T11":
      case "T12":
      case "T13":
      case "T14":
        return this.getTotalFromData();

      case "T9":
      case "T10":
        return this.getTotalForFlashing();

      default:
        return 0;
    }
  };

  onDeleteSkylightTabDimen = (i) => {
    this.setState(
      (state) => {
        let temp = state[state.selectData];
        temp.splice(i, 1);
        return { [state.selectData]: temp };
      },
      () => {
        this.setState({ skylightTotal: this.getTotalForSkylight() });
        this.clearTabData();
        this.flashingTabData();
        this.hideDeleteModal();
        this.addSection();
      }
    );
  };

  onHandleEditTabDimen = () => {
    const { activeWood } = this.state;

    this.setState(
      (state) => {
        let temp = state[state.selectData];
        const data = state[state.selectDimen];
        temp.splice(state.editDimen, 1, data);
        return { [state.selectData]: temp };
      },
      () => {
        this.setState(
          {
            [activeWood]: numeral(this.getTotalFromData()).format("0,0"),
          },
          () => {
            this.clearEdit();
            this.clearTabData();
            this.addSection();
            this.toggleKeypad();
          }
        );
      }
    );
  };

  onHandleEditFlashing = () => {
    const { activeWood } = this.state;

    this.setState(
      (state) => {
        let temp = state[state.selectData];
        temp.splice(state.editDimen, 1, {
          linearFeet: state.linearFeet,
          faceSize: state.faceSize,
        });
        return { [state.selectData]: temp };
      },
      () => {
        this.setState(
          {
            [activeWood]: numeral(this.getTotalForFlashing()).format("0,0"),
          },
          () => {
            this.clearEdit();
            this.toggleKeypad();
            this.addSection();
            this.clearFlashingData();
            this.flashingTabData();
          }
        );
      }
    );
  };

  dimensionModal = (show, label, hide) => {
    const {
      l,
      w,
      f,
      selectDimen,
      lwfTotal,
      editDimen,
      viewTally,
      lwfData,
      section,
      totalFlat,
      pitch,
    } = this.state;
    return (
      <PopupModal
        show={show}
        heading={`${viewTally ? `${label} Tally` : `Enter ${label}`}`}
        onHide={() => hide()}
        bold
        size="lg"
        scrollable={false}
        styles={{ padding: "1em 1em 0 1em", border: "none" }}
      >
        <div className="_carsl">
          {!viewTally ? (
            <div className="slide-left view">
              {this.dimensionModalHeader(
                lwfData.length,
                section,
                totalFlat,
                pitch
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "1em",
                }}
              >
                <div
                  onClick={() => this.setState({ selectDimen: "l" })}
                  className={`_block ${selectDimen === "l" ? "selected" : "unselected"
                    }`}
                >
                  <label className="_block-lbl">L</label>
                  <p className="_block-txt">{l}</p>
                </div>
                <div className="icon-btw">{getIcon(faTimes)}</div>
                <div
                  onClick={() => this.setState({ selectDimen: "w" })}
                  className={`_block ${selectDimen === "w" ? "selected" : "unselected"
                    }`}
                >
                  <label className="_block-lbl">W</label>
                  <p className="_block-txt">{w}</p>
                </div>
                <div className="icon-btw">{getIcon(faTimes)}</div>
                <div
                  onClick={() => this.setState({ selectDimen: "f" })}
                  className={`_block ${selectDimen === "f" ? "selected" : "unselected"
                    }`}
                >
                  <label className="_block-lbl">F</label>
                  <p className="_block-txt">{f}</p>
                </div>
                <div className="icon-btw">{getIcon(faEquals)}</div>
                <div className="_block">
                  <label className="_block-lbl">Total</label>
                  <p className="_block-txt">
                    {numeral(lwfTotal).format("0,0")}
                  </p>
                </div>
              </div>

              {Numpad(
                selectDimen,
                editDimen,
                this.handleAdd,
                this.onHandleEditDimen,
                l === "" ||
                w === "" ||
                f === "" ||
                l === 0 ||
                w === 0 ||
                f === 0,
                this.addIntoDimen
              )}

              <Modal.Footer
                style={{
                  justifyContent: "flex-end",
                  border: "none",
                  padding: "0 .75rem",
                }}
              >
                <Button
                  className="modified_btn"
                  variant="danger"
                  onClick={() => hide()}
                >
                  Close
                </Button>
                <Button
                  className="modified_btn"
                  onClick={this.toggleKeypad}
                  disabled={!lwfData.length > 0}
                >
                  View Tally
                </Button>
              </Modal.Footer>
            </div>
          ) : (
            <DimensionTallyUI
              lwfData={this.state.lwfData}
              section={this.state.section}
              totalFlat={this.state.totalFlat}
              pitch={this.state.pitch}
              hide={hide}
              dimensionModalHeader={this.dimensionModalHeader}
              renderDimenData={this.renderDimenData}
              toggleKeypad={this.toggleKeypad}
            />
          )}
        </div>
      </PopupModal>
    );
  };

  dimensionModalHeader = (num, section, totalFlat, difficulty) => (
    <Row>
      <Col>
        <div className="_lbl">Section</div>
        <p className="_txt">{section}</p>
      </Col>

      <Col>
        <div className="_lbl">Entries</div>
        <p className="_txt">{num}</p>
      </Col>

      <Col>
        <div className="_lbl">Total(S)</div>
        <p className="_txt">
          {getDataLabel(DIFFICULTY, difficulty) === "Flat"
            ? ""
            : numeral(calculateTotalFlat(this.state.lwfData)).format("0,0")}
        </p>
      </Col>
      <Col>
        <div className="_lbl">Total(F)</div>
        <p className="_txt">
          {getDataLabel(DIFFICULTY, difficulty) === "Flat"
            ? numeral(calculateTotalFlat(this.state.lwfData)).format("0,0")
            : ""}
        </p>
      </Col>
    </Row>
  );

  // renderTallyUI = (hide) => {
  //   const { lwfData, section, totalFlat, pitch } = this.state;
  //   if (this.state.selectData !== "lwfData") {
  //     this.setState({ selectData: "lwfData" });
  //   }
  //   return (
  //     <div className="slide-right">
  //       {this.dimensionModalHeader(lwfData.length, section, totalFlat, pitch)}
  //       {lwfData.length > 0 && (
  //         <table className="dimen_table">
  //           <thead>
  //             <tr>
  //               <th></th>
  //               <th>Length</th>
  //               <th style={{ flex: "unset", width: "30px" }}></th>
  //               <th>Width</th>
  //               <th style={{ flex: "unset", width: "30px" }}></th>
  //               <th>Factor</th>
  //               <th style={{ flex: "unset", width: "30px" }}></th>
  //               <th>SQ Ft(S)</th>
  //               <th>SQ Ft(F)</th>
  //               <th></th>
  //             </tr>
  //           </thead>
  //           <tbody>{this.renderDimenData()}</tbody>
  //         </table>
  //       )}
  //       <Modal.Footer style={{ justifyContent: "unset", border: "none" }}>
  //         <div style={{ flex: "1" }}>
  //           <Button
  //             className="modified_btn"
  //             style={{ float: "left" }}
  //             onClick={this.toggleKeypad}
  //           >
  //             Keypad
  //           </Button>
  //         </div>
  //         {this.showPagination()}
  //         <div>
  //           <Button
  //             className="modified_btn"
  //             variant="danger"
  //             onClick={() => hide()}
  //           >
  //             Close
  //           </Button>
  //         </div>
  //       </Modal.Footer>
  //     </div>
  //   );
  // };

  toggleKeypad = () =>
    this.setState((state) => ({
      viewTally: !state.viewTally,
      label: `${!state.viewTally
          ? `${state.activeLabel} Tally`
          : `Enter ${state.activeLabel}`
        }`,
    }));

  onEditDimen = (data, { l, w, f, lwfTotal }, i) => {
    this.setState({ l, w, f, lwfTotal, editDimen: i, allDimensionData: data });
    this.toggleKeypad();
  };

  onDeleteDimen = (data, i) => {
    let temp = data;
    temp.splice(i, 1);
    this.setState(
      {
        lwfData: temp,
        activateSave: true,
      },
      () => {
        this.setState(
          { totalFlat: calculateTotalFlat(this.state.lwfData) },
          () => {
            this.clearDimen();
            this.addSection();
          }
        );
      }
    );
  };

  onHandleEditDimen = () => {
    let temp = this.state.allDimensionData;
    temp.splice(this.state.editDimen, 1, {
      lwfTotal: this.state.lwfTotal,
      l: this.state.l,
      w: this.state.w,
      f: this.state.f,
    });
    this.setState(
      (state) => ({
        lwfData: temp,
        activateSave: true,
      }),
      () => {
        this.setState(
          { totalFlat: calculateTotalFlat(this.state.lwfData) },
          () => {
            this.clearDimen();
            this.addSection();
          }
        );
      }
    );
  };

  clearDimen = () =>
    this.setState({ l: "", w: "", f: "", lwfTotal: 0, editDimen: "" });

  renderDimenData = (pageData) => {
    const { dataKeys, lwfData, pitch } = this.state;
    return pageData.map(({ l, w, f, lwfTotal }, i) => (
      <tr key={i}>
        <td>
          <Button
            onClick={() => this.onEditDimen(pageData, { l, w, f, lwfTotal }, i)}
          >
            Edit
          </Button>
        </td>
        <td>{l}</td>
        <td style={{ flex: "unset", width: "30px" }}>x</td>
        <td>{w}</td>
        <td style={{ flex: "unset", width: "30px" }}>x</td>
        <td>{f}</td>
        <td style={{ flex: "unset", width: "30px" }}>=</td>
        <td>
          {getDataLabel(DIFFICULTY, pitch) === "Flat"
            ? ""
            : numeral(lwfTotal).format("0,0")}
        </td>
        <td>
          {getDataLabel(DIFFICULTY, pitch) === "Flat"
            ? numeral(lwfTotal).format("0,0")
            : ""}
        </td>
        <td>
          <Button
            variant="danger"
            onClick={() =>
              this.setOnDelete(() => this.onDeleteDimen(pageData, i))
            }
          >
            Delete
          </Button>
        </td>
      </tr>
    ));
  };

  sklightDataEntry = (entries, disable = false) => {
    const { selectDimen } = this.state;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {entries.map((data, i) => (
          <React.Fragment key={i}>
            {i > 0 && (
              <div className="icon-btw">{i === 1 ? getIcon(faTimes) : " "}</div>
            )}
            <div
              onClick={() => this.setState({ selectDimen: data.name })}
              className={`_block ${disable
                  ? "unselected"
                  : selectDimen === data.name
                    ? "selected"
                    : "unselected"
                }`}
            >
              <label className="_block-lbl">{data.label}</label>
              <p className="_block-txt">{data.value}</p>
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  };

  skylightModal = () => {
    const {
      displaySkylightDetails,
      label,
      showSkylightModal,
      selectDimen,
      editDimen,
      viewTally,
      section,
      selectData,
      height,
      width,
      skCount,
    } = this.state;
    if (!showSkylightModal) return null;
    return (
      <PopupModal
        show={showSkylightModal}
        heading={label}
        onHide={() => this.hideSkylightModal()}
        bold
        styles={{ border: "none" }}
        scrollable={false}
      >
        <div className="_carsl">
          {!viewTally ? (
            displaySkylightDetails ? (
              this.renderSkylightDetails()
            ) : (
              <div className="slide-left view">
                {this.getTabTopData(
                  section,
                  selectData,
                  numeral(this.getTotalForSkylight()).format("0,0")
                )}

                {this.sklightDataEntry([
                  {
                    name: "width",
                    label: "Width",
                    value: width,
                  },
                  {
                    name: "height",
                    label: "Height",
                    value: height,
                  },
                  {
                    name: "skCount",
                    label: "Count",
                    value: skCount,
                  },
                ])}

                {Numpad(
                  selectDimen,
                  editDimen,
                  this.toggleSkylightDetails,
                  this.toggleSkylightDetails,
                  this.state["height"] === "" ||
                  this.state["width"] === "" ||
                  this.state["skCount"] === "" ||
                  this.state["height"] === 0 ||
                  this.state["width"] === 0 ||
                  this.state["skCount"] === 0,
                  this.addIntoDimen,
                  "Next"
                )}

                <Modal.Footer style={{ border: "none" }}>
                  <div>
                    <Button
                      className="modified_btn"
                      variant="danger"
                      onClick={() => this.hideSkylightModal()}
                    >
                      Close
                    </Button>
                    <Button
                      className="modified_btn"
                      onClick={this.toggleKeypad}
                      disabled={
                        selectData !== ""
                          ? this.state[selectData].length === 0
                          : false
                      }
                    >
                      View Tally
                    </Button>
                  </div>
                </Modal.Footer>
              </div>
            )
          ) : (
            this.renderSkylightTallyUI(this.hideSkylightModal)
          )}
        </div>
      </PopupModal>
    );
  };

  toggleSkylightDetails = () =>
    this.setState((state) => ({
      displaySkylightDetails: !state.displaySkylightDetails,
    }));

  renderSkylightDetails = () => {
    const {
      height,
      width,
      skCount,
      flange,
      curb,
      venting,
      hood_type,
      editDimen,
    } = this.state;

    return (
      <div className="slide-right">
        {this.sklightDataEntry(
          [
            {
              name: "width",
              label: "Width",
              value: width,
            },
            {
              name: "height",
              label: "Height",
              value: height,
            },
            {
              name: "skCount",
              label: "Count",
              value: skCount,
            },
          ],
          true
        )}
        <Row style={{ margin: "1em 2em" }}>
          <Col style={{ width: "50%" }}>
            {DisplayMetric({
              label: "Flange",
              array: flange,
              data: FLANGE,
              addFloor: this.addFloor,
              prevMetric: undefined,
              size: "28%",
            })}
          </Col>
          <Col style={{ width: "50%" }}>
            {DisplayMetric({
              label: "Curb",
              array: curb,
              data: CURB,
              addFloor: this.addFloor,
              prevMetric: undefined,
              size: "28%",
            })}
          </Col>
          <Col style={{ width: "50%" }}>
            {DisplayMetric({
              label: "Venting",
              array: venting,
              data: VENTING,
              addFloor: this.addFloor,
              prevMetric: undefined,
              size: "28%",
            })}
          </Col>
          <Col style={{ width: "50%" }}>
            {DisplayMetric({
              label: "Hood Type",
              array: hood_type,
              data: HOODTYPE,
              addFloor: this.addFloor,
              prevMetric: undefined,
              size: "28%",
            })}
          </Col>
        </Row>

        <Modal.Footer style={{ justifyContent: "flex-end", border: "none" }}>
          <div>
            <Button
              className="modified_btn"
              variant="danger"
              onClick={() => this.cancelSkylightDetails()}
            >
              Close
            </Button>
            {editDimen !== "" ? (
              <Button
                className="modified_btn"
                onClick={() => this.onHandleEditSkylight()}
              >
                Save Changes
              </Button>
            ) : (
              <Button
                className="modified_btn"
                onClick={() => this.handleAddSkylight()}
              >
                Save
              </Button>
            )}
          </div>
        </Modal.Footer>
      </div>
    );
  };

  cancelSkylightDetails = () => {
    this.resetSkylightDetails();
    this.toggleSkylightDetails();
  };

  resetSkylightDetails = () => {
    this.setState({
      flange: "",
      curb: "",
      venting: "",
      hood_type: "",
    });
  };

  renderSkylightTallyUI = (hide) => {
    const { section, selectData } = this.state;

    return (
      <div className="slide-right">
        {this.getTabTopData(
          section,
          selectData,
          numeral(this.getTotalForSkylight()).format("0,0")
        )}

        {selectData !== "" && this.state[selectData].length > 0 && (
          <>
            <table className="dimen_table">
              <thead>
                <tr>
                  <th></th>
                  <th>Width</th>
                  <th></th>
                  <th>Height</th>
                  <th>Count</th>
                  <th>Flange</th>
                  <th>Curb</th>
                  <th>Venting</th>
                  <th>Hood</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{this.SkylightDataDisplay()}</tbody>
            </table>
          </>
        )}
        <Modal.Footer
          style={{ justifyContent: "space-between", border: "none" }}
        >
          <div>
            <Button
              className="modified_btn"
              style={{ float: "left" }}
              onClick={this.toggleKeypad}
            >
              Keypad
            </Button>
          </div>
          {this.showPagination()}
          <div>
            <Button
              className="modified_btn"
              variant="danger"
              onClick={() => hide()}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </div>
    );
  };

  SkylightDataDisplay = () => {
    const { page_num, max_item } = this.state;
    const lastI = page_num * max_item;
    const startI = lastI - max_item;
    return this.state[this.state.selectData]
      .slice(startI, lastI)
      .map((data, i) => (
        <tr key={i}>
          <td>
            <Button onClick={() => this.onEditTabSkylight(data, i)}>
              Edit
            </Button>
          </td>
          <td>{data.width}</td>
          <td>{getIcon(faTimes)}</td>
          <td>{data.height}</td>
          <td>{data.count}</td>
          <td>{data.flange ? getDataLabel(FLANGE, data.flange) : "-"}</td>
          <td>{data.curb ? getDataLabel(CURB, data.curb) : "-"}</td>
          <td>{data.venting ? getDataLabel(VENTING, data.venting) : "-"}</td>
          <td>{data.hood ? getDataLabel(HOODTYPE, data.hood) : "-"}</td>
          <td>
            <Button
              variant="danger"
              onClick={() =>
                this.setOnDelete(() => this.onDeleteSkylightTabDimen(i))
              }
            >
              Delete
            </Button>
          </td>
        </tr>
      ));
  };

  displayTabModal = (label, data, name, totalLabel, index) => {
    this.setState({
      showTabModal: true,
      viewTally: false,
      activeWood: index,
    });

    this.setModalOtherState(label, data, name, totalLabel);
  };

  displayWoodModal = (label, data, name, totalLabel, dataKeys, index) => {
    this.setState({
      showWoodModal: true,
      dataKeys,
      activeWood: index,
    });

    this.setModalOtherState(label, data, name, totalLabel);
  };

  displayMiscModal = (label, data, name, totalLabel, dataKeys, index) => {
    this.setState({
      showMiscModal: true,
      dataKeys,
      activeWood: index,
      viewTally: false,
      [name.toLowerCase().replace(" ", "_")]: "",
      size: "",
      type: "",
      length: "",
      showMiscEntry: true,
    });
    this.setModalOtherState(label, data, name, totalLabel);
  };

  setModalOtherState = (label, data, name, totalLabel) => {
    let totalLbl = typeof totalLabel === "undefined" ? "" : totalLabel;

    this.setState(
      {
        label: `Enter ${label}`,
        selectData: data,
        selectDimen: name.toLowerCase().replace(" ", "_"),
        activeLabel: label,
        totalLabel: totalLbl.toLowerCase(),
      },
      () => {
        this.clearEdit();
      }
    );
  };
  displayFlashingLastModal = (label, data, name, select, index) => {
    this.setState(
      {
        selectDimen: select,
        activeWood: index,
      },
      () => {
        this.displayFlashingTabModal(label, data);
      }
    );
  };

  displayFlashingTabModal = (label, data) => {
    this.setState({
      showFlashingTabModal: true,
      label: `Enter ${label}`,
      selectData: data,
      activeLabel: label,
    });
  };

  displaySkylightModal = (label, data, name, totalLabel, index) => {
    let totalLbl = typeof totalLabel === "undefined" ? "" : totalLabel;
    this.setState(
      {
        showSkylightModal: true,
        label: `Enter ${label}`,
        selectData: data,
        selectDimen: name.toLowerCase().replace(" ", "_"),
        activeLabel: label,
        totalLabel: totalLbl,
        activeWood: index,
      },
      () => {
        this.clearEdit();
      }
    );
  };

  displayTally = () => {
    this.displaySkylightModal(
      "Skylight Dimensions & Details",
      "skylightData",
      "width",
      "count",
      "T15"
    );
    this.toggleKeypad();
  };

  renderTab = () => {
    return TABS.map(({ id, name }) => (
      <Tab key={id} eventKey={id} title={name} tabClassName="tabposition">
        {this.renderTabDimenData(id)}
      </Tab>
    ));
  };

  renderTabDimenData = (active) => {
    const {
      T0,
      T1,
      T2,
      T3,
      T4,
      T5,
      T6,
      T7,
      T8,
      T11,
      T12,
      T13,
      T14,
      T9,
      T10,
      T16,
      T17,
      T18,
      T19,
      T20,
      M0,
      M1,
      M2,
      M3,
      M4,
      skylightTotal,
      skylightData,
      isQuoteLock,
    } = this.state;
    switch (active) {
      case TABS[0].id:
        return DisplayBtnsTab([
          {
            name: "Starter",
            total: T0,
            disable: isQuoteLock,
            onClick: () =>
              this.displayTabModal(
                "Starter",
                "StarterData",
                "Starter",
                "",
                "T0"
              ),
          },
          {
            name: "Leak Barrier",
            total: T1,
            disable: isQuoteLock,
            onClick: () =>
              this.displayTabModal(
                "Leak Barrier",
                "LeakBarrierData",
                "Leak Barrier",
                "",
                "T1"
              ),
          },
          {
            name: "Eve Drip",
            total: T2,
            disable: isQuoteLock,
            onClick: () =>
              this.displayTabModal(
                "Eve Drip Edge",
                "EveDripData",
                "Eve Drip",
                "",
                "T2"
              ),
          },
          {
            name: "Rake Drip",
            total: T3,
            disable: isQuoteLock,
            onClick: () =>
              this.displayTabModal(
                "Rake Drip",
                "RakeDripData",
                "Rake Drip",
                "",
                "T3"
              ),
          },
          {
            name: "Hip & Ridge",
            total: T4,
            disable: isQuoteLock,
            onClick: () =>
              this.displayTabModal(
                "Hip & Ridge",
                "HipRidgeData",
                "Hip Ridge",
                "",
                "T4"
              ),
          },
        ]);

      case TABS[1].id:
        return DisplayBtnsTab([
          {
            name: "Vent Pipe",
            total: T6,
            disable: isQuoteLock,
            onClick: () =>
              this.displayTabModal(
                "Vent Pipe",
                "VentPipeData",
                "Vent Pipe",
                "count",
                "T6"
              ),
          },
          {
            name: "Valley",
            total: T7,
            disable: isQuoteLock,
            onClick: () =>
              this.displayTabModal("Valley", "ValleyData", "Valley", "", "T7"),
          },
          {
            name: "Steps",
            total: T8,
            disable: isQuoteLock,
            onClick: () =>
              this.displayTabModal("Steps", "StepsData", "Steps", "", "T8"),
          },
          {
            name: "Chimney",
            total: T9,
            disable: isQuoteLock,
            onClick: () =>
              this.displayFlashingLastModal(
                "Chimney Flashing",
                "ChimneyData",
                "Chimney",
                "linearFeet",
                "T9"
              ),
          },
          {
            name: "Wall",
            total: T10,
            disable: isQuoteLock,
            onClick: () =>
              this.displayFlashingLastModal(
                "Wall Flashing",
                "WallData",
                "Wall",
                "linearFeet",
                "T10"
              ),
          },
        ]);

      case TABS[2].id:
        return DisplayBtnsTab([
          {
            name: "Ridge Vent",
            total: T5,
            disable: isQuoteLock,
            onClick: () =>
              this.displayTabModal(
                "Ridge Vent",
                "RidgeVentData",
                "Ridge Vent",
                "",
                "T5"
              ),
          },
          {
            name: "Louvers",
            total: T11,
            disable: isQuoteLock,
            onClick: () =>
              this.displayTabModal(
                "Louvers",
                "louversData",
                "Louvers",
                "count",
                "T11"
              ),
          },
          {
            name: "Fans",
            total: T12,
            disable: isQuoteLock,
            onClick: () =>
              this.displayTabModal("Fans", "fansData", "Fans", "count", "T12"),
          },
          {
            name: "Hood Vent Small",
            total: T13,
            disable: isQuoteLock,
            onClick: () =>
              this.displayTabModal(
                " Small Hood Vent",
                "hoodventsmallData",
                "HoodVentSmall",
                "count",
                "T13"
              ),
          },
          {
            name: "Hood Vent Large",
            total: T14,
            disable: isQuoteLock,
            onClick: () =>
              this.displayTabModal(
                "Large Hood Vent",
                "hoodventlargeData",
                "HoodVentLarge",
                "count",
                "T14"
              ),
          },
        ]);

      case TABS[3].id:
        return DisplayBtnsTab(
          [
            {
              name: "Add Skylight",
              total: this.getTotalForSkylight(),
              disable: isQuoteLock,
              onClick: () =>
                this.displaySkylightModal(
                  "Skylight Dimensions & Details",
                  "skylightData",
                  "width",
                  "count",
                  "T15"
                ),
            },
            {
              name: "View Tally",
              onClick: () => this.displayTally(),
              disable: skylightData.length < 1 || isQuoteLock,
            },
          ],
          true
        );

      case TABS[4].id:
        return DisplayBtnsTab(
          [
            {
              name: "Plywood",
              total: T16,
              disable: isQuoteLock,
              onClick: () =>
                this.displayWoodModal(
                  "Plywood",
                  "plywoodData",
                  "plywood",
                  "Pieces",
                  ["Pieces", "Size", "Type"],
                  "T16"
                ),
            },
            {
              name: "Rafter & studs",
              total: T17,
              disable: isQuoteLock,
              onClick: () =>
                this.displayWoodModal(
                  "Rafter & Stud",
                  "rafterstudsData",
                  "rafterstuds",
                  "feet",
                  ["Feet", "Size", "Length"],
                  "T17"
                ),
            },
            {
              name: "Furring strips",
              total: T18,
              disable: isQuoteLock,
              onClick: () =>
                this.displayWoodModal(
                  "Furring strips",
                  "furringstripsData",
                  "furringstrips",
                  "feet",
                  ["Feet", "Size"],
                  "T18"
                ),
            },
          ],
          true
        );

      case TABS[5].id:
        return DisplayBtnsTab(
          [
            {
              name: "Fascia",
              total: T19,
              disable: isQuoteLock,
              onClick: () =>
                this.displayWoodModal(
                  "Fascia",
                  "fasciaData",
                  "Fascia",
                  "",
                  ["Feet", "Material", "Size", "Length"],
                  "T19"
                ),
            },
            {
              name: "Capping",
              total: T20,
              disable: isQuoteLock,
              onClick: () =>
                this.displayWoodModal(
                  "Capping",
                  "cappingData",
                  "Capping",
                  "feet",
                  ["Feet", "Type", "Color"],
                  "T20"
                ),
            },
          ],
          "100px"
        );

      case TABS[6].id:
        return DisplayBtnsTab([
          {
            name: "Trim Metal",
            total: M0,
            disable: isQuoteLock,
            onClick: () =>
              this.displayMiscModal(
                "Trim Metal",
                "trimmetalData",
                "trimmetal",
                "feet",
                ["Feet", "Type"],
                "M0"
              ),
          },
          {
            name: "Chimney Caps",
            total: M1,
            disable: isQuoteLock,
            onClick: () =>
              this.displayMiscModal(
                "Chimney Caps",
                "chimenycapsData",
                "chimneycaps",
                "count",
                ["Count", "Shape", "Material", "Dimensions"],
                "M1"
              ),
          },
          {
            name: "Hand Nails",
            total: M2,
            disable: isQuoteLock,
            onClick: () =>
              this.displayMiscModal(
                "Hand Nails",
                "handnailsData",
                "handnails",
                "pound",
                ["Pound", "Type", "Size"],
                "M2"
              ),
          },
          {
            name: "Common Nails",
            total: M3,
            disable: isQuoteLock,
            onClick: () =>
              this.displayMiscModal(
                "Common Nails",
                "commonnailsData",
                "commonnails",
                "pound",
                ["Pound", "Type", "Size"],
                "M3"
              ),
          },
          {
            name: "Metal Sheets",
            total: M4,
            disable: isQuoteLock,
            onClick: () =>
              this.displayMiscModal(
                "Metal Sheet",
                "metalsheetData",
                "metalsheet",
                "sheet",
                ["Sheet", "Material", "Dimension"],
                "M4"
              ),
          },
        ]);

      default:
        return null;
    }
  };

  hideDeleteModal = (check = true) => {
    this.setState({ showDeleteModal: false, dltHead: "", dltSubHead: "" });
    const modal = document.querySelector(".modal");
    if (check) {
      if (modal) {
        modal.classList.add("show");
      }
    }
  };

  setOnDelete = (deleteFun, check = true) => {
    this.setState({
      showDeleteModal: true,
      deleteData: () => {
        deleteFun();
        this.setState({ showDeleteModal: false });
      },
    });

    if (check) {
      const modal = document.querySelector(".modal");
      if (modal) {
        modal.classList.remove("show");
      }
    }
  };

  hideWoodModal = () => {
    this.setState((state) => ({
      showWoodModal: false,
      viewTally: false,
      [state.selectDimen]: "",
      size: "",
      type: "",
      length: "",
      showWoodEntry: true,
    }));
    this.resetPage();
  };

  resetPage = () => {
    this.setState({ page_num: 1 });
  };
  hideMiscModal = () => {
    this.setState((state) => ({
      showMiscModal: false,
      viewTally: false,
      [state.selectDimen]: "",
      size: "",
      type: "",
      length: "",
      showMiscEntry: true,
    }));
    this.resetPage();
  };

  setTab = (key) => this.setState({ selectedTab: key });
  renderTabs = () => (
    <Tabs
      id="controlled-tab-example"
      activeKey={this.state.selectedTab}
      onSelect={(key) => this.setTab(key)}
      style={{ marginBottom: "3%" }}
    >
      {this.renderTab()}
    </Tabs>
  );

  hideEntryModal = () => {
    this.setState((state) => ({
      showEntryModal: false,
      showTally: false,
      showEntry: false,
      style: "",
      type: "",
      color: "",
      fascia: "",
      capping: "",
      otherColor: "",
      size: "",
      editDimen: "",
    }));
    this.resetPage();
  };

  selectMultipleMetric = (
    label,
    data,
    array,
    arrName,
    disableBy,
    isQuoteLock = false
  ) => {
    return data.map((item) => (
      <button
        disabled={disableBy}
        key={item.id}
        style={
          isQuoteLock && array.includes(item.id)
            ? { backgroundColor: "#60abfb", borderColor: "#60abfb" }
            : {}
        }
        className={`ClickableBtn ${array.includes(item.id) ? "selected" : ""} `}
        onClick={() => this.addAllData(arrName, item.id)}
      >
        {item.name}
      </button>
    ));
  };

  setModalOtherStateNew = (label, data, name, totalLabel) => {
    let totalLbl = typeof totalLabel === "undefined" ? "" : totalLabel;

    this.setState(
      {
        label: `${label} Selections`,
        selectData: data,
        selectDimen: name.toLowerCase().replace(" ", ""),
        activeLabel: label,
        totalLabel: totalLbl,
        totalLbl: totalLbl.toLowerCase().replace(" ", ""),
      },
      () => {
        if (
          JSON.stringify(this.state[`${this.state.selectDimen}Selection`]) !==
          "{}"
        ) {
          this.setState({
            ...this.state[`${this.state.selectDimen}Selection`],
          });
        }
      }
    );
  };

  onEditEntryNew = (data, i) => {
    let obj = { editDimen: i };
    this.setState({
      ...obj,
      [this.state.selectDimen]: data,
    });
    this.toggleKeypad();
  };

  onHandleEditEntryNew = () => {
    this.setState((state) => {
      let temp = state[state.selectData];
      temp.splice(state.editDimen, 1, this.state[state.selectDimen]);
      return { [state.selectData]: temp, activateSaveNew: true };
    });
    this.toggleKeypad();
    this.clearTabData();
  };

  addAnotherSection = () => {
    this.clearAllSectionData();
    this.setState((state) => ({
      section: this.getNewSectionNumber(0),
      editSection: false,
      currentSection: "",
      sectionI: "",
      loadingReq: false,
      sectionId: uuid(),
      isQuoteLock: false,
    }));
  };

  getNewSectionNumber = (add) => {
    const { sectionData } = this.state;

    const num = sectionData.length + 1 + add;
    const match = sectionData.some((data) => data.section === num);
    return match ? this.getNewSectionNumber(add + 1) : num;
  };

  saveDataIntoDb = () => {
    if (this.state.editSection) {
      this.addSection();
    }
  };

  openImages = () => {
    const { isLocked } = this.state;
    if (isLocked) {
      this.setState({
        isQuoteLock: true,
      });
    } else {
      this.setState({
        isQuoteLock: false,
      });
    }
    this.setState({
      images: true,
    });
  };

  closeImages = () =>
    this.setState({
      images: false,
      isQuoteLock: false,
    });

  deleteImageFromSection = (section, col, img = undefined) => {
    const { sectionData, tableData } = this.state;
    let temp = [...sectionData];
    let temp2 = [...tableData];

    const idx = temp.findIndex((data) => data.sectionId === section);
    const idx2 = temp2.findIndex((data) => data.sectionId === section);
    temp[idx].imagesList[col] = img;
    temp2[idx2].imagesList[col] = img;

    let newImageList = [];
    temp[idx].imagesList.map((image) => {
      if (image) {
        newImageList.push(image);
      }
    });
    if (newImageList.length !== 5) {
      let remainingImage = new Array(5 - newImageList.length).fill(undefined);
      newImageList = [...newImageList, ...remainingImage];
    }

    temp[idx].imagesList = newImageList;
    temp2[idx2].imagesList = newImageList;

    this.setState(
      {
        sectionData: temp,
        tableData: temp2,
        editSection: false,
      },
      () => {
        this.saveImagesToDB();
      }
    );
  };

  addImageIntoSection = (section, col, img = undefined) => {
    const { sectionData, tableData } = this.state;
    let temp = [...sectionData];
    let temp2 = [...tableData];
    const idx = temp.findIndex((data) => data.sectionId === section);
    const idx2 = temp2.findIndex((data) => data.sectionId === section);
    temp[idx].imagesList[col] = img ? { ...img } : img;
    temp2[idx2].imagesList[col] = img ? { ...img } : img;
    this.setState(
      {
        sectionData: temp,
        tableData: temp2,
        editSection: false,
      },
      () => {
        this.saveImagesToDB();
      }
    );
  };

  saveImagesToDB = () => {
    this.saveMetricData(this.state.sectionData);
  };

  addCheckToImage = (checked, image) => {
    const { building, coverImage } = this.state;
    let newCoverImage = [...coverImage];
    if (building) {
      if (coverImage.length > 0) {
        const idx = coverImage.findIndex((img) => img.bid === building);
        if (idx > -1) {
          if (!checked) {
            newCoverImage.splice(idx, 1);
          } else {
            newCoverImage[idx] = {
              bid: building,
              imageSrc: image,
            };
          }
        } else {
          newCoverImage.push({
            bid: building,
            imageSrc: image,
          });
        }
      } else {
        newCoverImage = [
          {
            bid: building,
            imageSrc: image,
          },
        ];
      }
    } else {
      newCoverImage = [
        checked
          ? {
            imageSrc: image,
          }
          : { imageSrc: "" },
      ];
    }

    this.setState(
      {
        coverImage: newCoverImage,
      },
      () => {
        this.saveImagesToDB();
      }
    );
    return true;
  };

  reload = () => this.loadData();

  checkCoverImageProperty = () => {
    const { coverImage, building } = this.state;
    if (building) {
      if (coverImage.length > 0) {
        const match = coverImage.find((img) => img.bid === building);
        return match ? match.imageSrc : "";
      } else {
        return "";
      }
    } else {
      return coverImage.length > 0 ? coverImage[0].imageSrc : "";
    }
  };

  showBuildingList = () => this.setState({ showCoopModal: true });

  onSubmitElevationDetails = () => {
    this.hideModal();
    if (this.state.editSection) {
      this.addSection();
    }
  };

  handleWorkTypeChange = (id) => {
    const { editSection } = this.state;
    if (this.state.work_type === id) {
      this.setState(
        { work_type: "", removal_types: "", other_rems: "" },
        () => {
          this.addSection();
        }
      );
      return;
    }

    this.setState({ work_type: id, removal_types: "", other_rems: "" }, () => {
      /**
       * new condition added
       */
      if (editSection) {
        this.addSection();
      }
      // this.addSection();
    });

    //TODO Remove later if work type works properly
    // if (removal_types !== prevState.removal_types) {
    // 	if (prevState.removal_types && varemoval_types && other_rems && !changedAndIsEditSection) {
    // 		this.setState({ other_rems: '' })
    // 	}
    // }

    // if (work_type !== prevState.work_type) {
    // 	if (prevState.work_type && work_type && !changedAndIsEditSection) {
    // 		this.setState({ other_rems: '', removal_types: '' })
    // 	}
    // }

    // if (removal_types !== prevState.removal_types && !changedAndIsEditSection) {
    // 	if (removal_types) {
    // 		this.setState({ work_type: '003' })
    // 	} else {
    // 		this.setState({ other_rems: '', work_type: '' })
    // 	}
    // }
  };

  handleRemovalTypeChange = (key, id) => {
    const { editSection } = this.state;
    if (this.state[key] === id) {
      this.setState({ work_type: "", [key]: "", other_rems: "" }, () => {
        this.addSection();
      });
      return;
    }

    this.setState({ work_type: "003", [key]: id, other_rems: "" }, () => {
      /**
       * new condition added
       */
      if (editSection) {
        this.addSection();
      }
      // this.addSection();
    });
  };

  handleOtherRemovalTypeChange = (key, id) => {
    const { editSection } = this.state;

    if (this.state[key] === id) {
      this.setState({ [key]: "" }, () => {
        this.addSection();
      });
      return;
    }

    this.setState({ [key]: id }, () => {
      if (editSection) {
        this.addSection();
      }
      // this.addSection();
    });
  };

  render() {
    let {
      section,
      floor,
      elevation,
      elevation_details,
      pitch,
      work_type,
      install_type,
      showCoopModal,
      coopUnits,
      newBuildingName,
      tempNewBuildingName,
      otherModal,
      elevation_other,
      edOther,
      removal_types,
      other_rems,
      removalModal,
      installTypeModal,
      install_types,
      dimensionModal,
      notes,
      label,
      showWoodModal,
      selectDimen,
      editDimen,
      viewTally,
      selectData,
      showWoodEntry,
      dataKeys,
      totalLabel,
      activeWood,
      showMiscModal,
      showMiscEntry,
      deleteData,
      showDeleteModal,
      building,
      lwfData,
      totalFlat,
      address,
      msg,
      msgType,
      editBuilding,
      deleteBuilding,
      buildingErr,
      dltHead,
      dltSubHead,
      sectionData,
      tableData,
      roofPitch,
      images,
      isDataChanged,
      editSection,
      isQuoteLock,
      isLocked,
    } = this.state;

    const { data, isLoading, errors, match, leadReqId } = this.props;
    const metricId = match?.params?.id || leadReqId;

    if (errors || isLoading)
      return <InitialLoader error={errors} onReload={this.reload} />;

    if (this.props.image_type === "PM_IMAGE") {
      images = true;
    }

    const HeaderComponent = Header.bind(this, {
      address,
      metricType: "Roofing",
      buildingName: building
        ? getBuildingName(this.state.coopUnits, building)
        : "",
    });

    const DeleteModalComponent = DeleteModal.bind(this, {
      showDeleteModal: showDeleteModal,
      deleteData: () => deleteData(),
      processing: false,
      heading: dltHead ? dltHead : "Delete Confirmation",
      hideDeleteModal: this.hideDeleteModal,
      dltSubHead: dltSubHead,
    });

    const getBuildingNameForBuildingComp = getBuildingName.bind(
      this,
      this.state.coopUnits
    );

    if (images) {
      return (
        <Images
          Header={HeaderComponent}
          closeImages={this.closeImages}
          sectionData={tableData}
          getLabel={getFloor}
          getElevationLetters={getElevation}
          getDataLabel={getLabel}
          addImageIntoSection={this.addImageIntoSection}
          deleteImageFromSection={this.deleteImageFromSection}
          addCheckToImage={this.addCheckToImage}
          coverImage={this.checkCoverImageProperty}
          label="Floor"
          isQuoteLock={isQuoteLock}
          metricId={metricId}
        />
      );
    }

    const disabledAddSection = this.isAddSectionDisabled();

    return (
      <MetricHeader
        onClickBuilding={this.showBuildingList}
        openImages={this.openImages}
        addAnotherSection={this.addAnotherSection}
        disabledAddSection={disabledAddSection}
        disabledImageBtn={tableData.length === 0}
        metricId={metricId}
        hasOtherMetric={data?.hasGutters}
        isBuilding={data?.isBuilding ?? "false"}
        address={address}
        metricType="Roofing"
        msg={msg}
        msgType={msgType}
        buildingName={
          building ? getBuildingName(this.state.coopUnits, building) : ""
        }
        isQuoteLock={isLocked}
        type_page={this.props.type_page}
      >
        {DeleteModalComponent()}

        <div style={{ margin: "1em 0" }}>
          {/* First Row of Metrics */}
          <Row style={{ margin: "1em 0", justifyContent: "flex-end" }}>
            <Col>
              {DisplayMetric({
                label: "Floor",
                array: floor,
                data: FLOOR,
                prevMetric:
                  (!(data && data.isBuilding) ||
                    (data && data.isBuilding && this.state.building)) &&
                  !isQuoteLock,
                size: "24%",
                addFloor: this.addFloor,
                isQuoteLock: isQuoteLock,
              })}
            </Col>
            <Col>
              <label className="btnheading">Elevation</label>
              <div className="ele_grid">
                {this.selectMultipleMetric(
                  "Elevation",
                  ELEVATION,
                  elevation,
                  "elevation",
                  !floor || isQuoteLock,
                  isQuoteLock
                )}
              </div>
            </Col>

            <Col>
              <label className="btnheading">Elevation Details</label>
              <div className="eledetail_grid">
                {MetricButton({
                  label: "Elevation Details",
                  array: elevation_details,
                  data: ELEVATIONDETAILS,
                  prevMetric: elevation.length && !isQuoteLock,
                  onClickBtn: (label, id, exec) =>
                    this.addFloor(
                      label.replace(" ", "_").toLowerCase(),
                      id,
                      exec
                    ),
                  isQuoteLock: isQuoteLock,
                })}

                <button
                  disabled={!elevation_details || isQuoteLock}
                  style={
                    isQuoteLock && this.state.elevation_other.length > 0
                      ? { backgroundColor: "#60abfb", borderColor: "#60abfb" }
                      : {}
                  }
                  className={`ClickableBtn ${this.state.elevation_other.length > 0 ? "selected" : ""
                    }`}
                  onClick={() => this.setState({ otherModal: true })}
                >
                  More...
                </button>
              </div>
            </Col>
          </Row>
          {/* Second Row of metrics */}
          <Row style={{ margin: "1em 0", justifyContent: "flex-end" }}>
            <Col md="4">
              {DisplayMetric({
                label: "Pitch",
                array: pitch,
                data: DIFFICULTY,
                prevMetric: elevation_details && !isQuoteLock,
                size: "24%",
                addFloor: this.addFloor,
                isQuoteLock: isQuoteLock,
              })}
              {(pitch === "002" || pitch === "003") && (
                <div style={{ maxWidth: "346px", marginLeft: "-7px" }}>
                  <NumberInput
                    value={roofPitch}
                    name="roofPitch"
                    onChange={(e) => {
                      this.handleChange(e);
                      this.saveDataIntoDb();
                    }}
                    placeholder="Roof Pitch(Optional)"
                    min={1}
                  />
                </div>
              )}
            </Col>

            <Col md="4">
              <label className="btnheading">Work Type</label>
              <Row
                style={{
                  marginLeft: "-3px",
                }}
              >
                <button
                  disabled={!pitch || isQuoteLock}
                  style={
                    isQuoteLock && work_type === "001"
                      ? {
                        maxWidth: "20%",
                        backgroundColor: "#60abfb",
                        borderColor: "#60abfb",
                      }
                      : { maxWidth: "20%" }
                  }
                  className={`ClickableBtn ${work_type === "001" ? "selected" : ""
                    } `}
                  onClick={() => this.handleWorkTypeChange("001")}
                >
                  New
                </button>
                <button
                  disabled={!pitch || isQuoteLock}
                  style={
                    isQuoteLock && work_type === "002"
                      ? {
                        maxWidth: "20%",
                        backgroundColor: "#60abfb",
                        borderColor: "#60abfb",
                      }
                      : { maxWidth: "20%" }
                  }
                  className={`ClickableBtn ${work_type === "002" ? "selected" : ""
                    } `}
                  onClick={() => this.handleWorkTypeChange("002")}
                >
                  Overlay
                </button>
                <button
                  disabled={!pitch || isQuoteLock}
                  style={
                    isQuoteLock && removal_types
                      ? {
                        maxWidth: "20%",
                        backgroundColor: "#60abfb",
                        borderColor: "#60abfb",
                      }
                      : { maxWidth: "20%" }
                  }
                  className={`ClickableBtn ${removal_types ? "selected" : ""} `}
                  onClick={() => {
                    this.setState({ removalModal: true });
                  }}
                >
                  Remove
                </button>
              </Row>

              {removal_types && (
                <Row>
                  <Form.Control
                    style={{ margin: "0 0.8em", maxWidth: "318px" }}
                    type="text"
                    placeholder="Remove"
                    value={`${getRemovalType[removal_types]}${other_rems ? ` - ${getOtherRemovalType[other_rems]}` : ""
                      }`}
                    disabled={true}
                  />
                </Row>
              )}
            </Col>
            <Col md="4">
              <Row>
                <Col>
                  <label className="btnheading">Install Type</label>
                  <Row>
                    <Col>
                      <button
                        disabled={
                          !work_type ||
                          (removal_types &&
                            (removal_types === "001" ||
                              removal_types === "002") &&
                            !other_rems) ||
                          isQuoteLock
                        }
                        style={
                          isQuoteLock &&
                            (install_type === "001" || install_types)
                            ? {
                              backgroundColor: "#60abfb",
                              borderColor: "#60abfb",
                            }
                            : {}
                        }
                        className={`ClickableBtn ${install_type === "001" || install_types
                            ? "selected"
                            : ""
                          } `}
                        onClick={() => this.addFloor("install_type", "001")}
                      >
                        Select
                      </button>
                    </Col>
                  </Row>
                  {install_types && (
                    <Row>
                      <Col style={{ marginLeft: "3px" }}>
                        <Form.Control
                          type="text"
                          placeholder="Install Types"
                          value={`${this.getText(install_types, [
                            ...SLOPED_TYPES,
                            ...FLATTYPES,
                          ])}`}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  )}
                </Col>

                <Col>
                  <label className="btnheading">Dimensions</label>

                  <Col style={styles.col}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "min-content",
                      }}
                    >
                      <button
                        className={`ClickableBtn ${lwfData.length > 0 ? "selected" : ""
                          } `}
                        disabled={install_types === "" || isQuoteLock}
                        style={
                          isQuoteLock && lwfData.length > 0
                            ? {
                              backgroundColor: "#60abfb",
                              borderColor: "#60abfb",
                              height: "52.4px",
                              // width: "109px",
                              width: "fit-content",
                            }
                            : { height: "52.4px", width: "fit-content" } //, width: "109px" }
                        }
                        onClick={() =>
                          this.setState({
                            dimensionModal: true,
                            selectDimen: "l",
                          })
                        }
                      >
                        Dimensions
                      </button>
                      <Form.Control
                        style={{
                          textAlign: "center",
                          width: "calc(100% - 5px)",
                          margin: "1px 0 0 5px",
                        }}
                        type="text"
                        placeholder="Total"
                        value={totalFlat ? formatNumber(totalFlat) : ""}
                        disabled={true}
                      />
                    </div>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {/* Notes + Toppings*/}
        {lwfData.length > 0 && (
          <>
            <Row>
              <Col style={{ margin: "1em 0" }}>{this.renderTabs()}</Col>
            </Row>

            <Row style={{ marginBottom: "14px" }}>
              <Col>
                <Form.Group style={styles.formInput}>
                  <Form.Label>Section Notes:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="notes"
                    value={notes}
                    style={{ resize: "none" }}
                    onChange={(e) => {
                      this.handleChange(e);
                      this.saveDataIntoDb();
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        )}

        {/* Section summary */}
        {this.state.tableData.length > 0 && (
          <>
            <Row>
              <Col>
                {BasicTable({
                  data: this.getTableData(),
                  columns: columns,
                  type: "roofing",
                  editSection: editSection,
                  tableData: tableData,
                  coopUnits: coopUnits,
                  sectionData: sectionData,
                  isQuoteLock: this.state.isQuoteLock,
                  removalTypes: removal_types,
                  otherRems: other_rems,
                })}
              </Col>
            </Row>
            <Row>
              <Col>
                <GenerateQuote
                  disableBtn={
                    disabledAddSection &&
                    editSection &&
                    this.state.address === ""
                  }
                  isDataChanged={isDataChanged}
                  metricId={metricId}
                  type_page={this.props.type_page}
                />
              </Col>
            </Row>
          </>
        )}

        {/* Popups */}
        <Building
          onCoopModalHide={this.onCoopModalHide}
          handleChange={this.handleChange}
          newBuildingName={newBuildingName}
          coopUnits={coopUnits}
          onEditBuilding={this.onEditBuilding}
          saveEditBuilding={this.saveEditBuilding}
          tempNewBuildingName={tempNewBuildingName}
          onDeleteBuilding={this.onDeleteBuilding}
          deleteBuilding={this.deleteBuilding}
          addBuldings={this.addBuldings}
          showCoopModal={showCoopModal}
          onSelected={(unit) => {
            if (unit === building) return this.onCoopModalHide();
            this.onCoopModalHide();
            this.selectBuilding(unit);
            this.addAnotherSection();
          }}
          selected={building}
          getBuildingName={getBuildingNameForBuildingComp}
          editBuilding={editBuilding}
          deleteBuildingId={deleteBuilding}
          error={buildingErr}
        />
        {DisplayModal({
          handleChange: this.handleChange,
          checkOtherArrayValue: this.checkOtherArrayValue,
          addAllData: this.addAllData,
          show: otherModal,
          label: "Elevation Details",
          hide: this.hideModal,
          data: ELEVATIONOTHER,
          array: elevation_other,
          arrName: "elevation_other",
          other: {
            name: "edOther",
            value: edOther,
            display: elevation_other.includes("004"),
          },
          onSubmit: this.onSubmitElevationDetails,
        })}

        {this.displayOtherModal(
          removalModal,
          "Select Removal Types",
          () => {
            this.setState((state) => ({
              removalModal: false,
              removal_types: state.removal_types ? state.removal_types : "",
              work_type: state.removal_types ? state.work_type : "",
            }));
          },
          RemoveTypes,
          removal_types,
          "removal_types",
          {
            name: "other_rems",
            value: other_rems,
            display: removal_types === "001" || removal_types === "002",
          },
          ShiTypes,
          "",
          !removal_types || removal_types === "001" || removal_types === "002"
            ? !other_rems
            : false
        )}
        {this.displayOtherModal(
          installTypeModal,
          "Select Install Types",
          () =>
            this.setState((state) => ({
              installTypeModal: false,
              install_type: state.install_types ? state.install_type : "",
              install_types: state.install_types ? state.install_types : "",
            })),
          SLOPED_TYPES,
          install_types,
          "install_types",
          {
            heading: "Flat",
            name: "install_types",
            value: install_types,
            display: true,
          },
          FLATTYPES,
          "Shingles",
          !install_types
        )}
        {this.dimensionModal(dimensionModal, "Dimension", () =>
          this.setState({
            dimensionModal: false,
            viewTally: false,
            editDimen: "",
            l: "",
            w: "",
            f: "",
            lwfTotal: "",
            selectDimen: "",
          })
        )}
        {this.AccessoriesTabModal()}
        {this.FlashingTabModal()}
        {this.skylightModal()}
        {this.WoodTabModal(
          label,
          showWoodModal,
          selectDimen,
          editDimen,
          viewTally,
          section,
          selectData,
          this.hideWoodModal,
          showWoodEntry,
          this.toggleWoodEntry,
          this.state[selectData],
          this.state[selectDimen],
          totalLabel,
          dataKeys,
          activeWood
        )}
        {this.MiscTabModal(
          label,
          showMiscModal,
          selectDimen,
          editDimen,
          viewTally,
          section,
          selectData,
          this.hideMiscModal,
          showMiscEntry,
          this.toggleWoodEntry,
          this.state[selectData],
          this.state[selectDimen],
          totalLabel,
          dataKeys,
          activeWood
        )}

        {/* {this.displayEntryModalNew()} */}
      </MetricHeader>
    );
  }
}

const styles = {
  col: {
    margin: 0,
    padding: "1px",
  },
};

function mapStateToProps({
  metricsData: { isLoading, data, errors },
  addBuilding: { addingBuilding, buildingErrors, buildingSuccess },
  addMetric: { addingMetric, metricErrors, metricSuccess },
}) {
  return {
    isLoading,
    data,
    errors,
    addingBuilding,
    buildingErrors,
    buildingSuccess,
    addingMetric,
    metricErrors,
    metricSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRoofData: (id) => dispatch(getRoofData(id)),
    updateBuilding: (data) => dispatch(updateBuilding({ ...data })),
    addMetric: (data) => dispatch(addMetric({ ...data })),
    changeStage: (data) => dispatch(changeStage({ ...data })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Metric);
