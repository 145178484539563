import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getLaborPricing } from "lib/redux/quote-proposals/quotes-proposals.actions";
import { toast } from "react-toastify";
import PriceInput from "components/PriceIput/PriceInput";
import validator from "validator";

import styles from "lib/config/baseStyles";
import QTYInput from "../components/QTYInput";

export default function SkylightLaborListData({
  selectedSupplier,
  data,
  editItem,
  onChangeQty,
  isLockQuoteData,
}) {
  const dispatch = useDispatch();

  const redStyles = {
    borderColor: "red",
    color: "red",
  };
  // const types_array = ["Replace", "New Install", "Close Off"];
  const handleUpdate = ({ types, uomPrice, uom }) => {
    const newTotal =
      validator.isNumeric(`${uomPrice}`) && validator.isNumeric(`${data.qty}`)
        ? Number(data.qty * uomPrice).toFixed(2)
        : Number(data.total).toFixed(2);

    const newData = changeData("uomPrice", Number(uomPrice).toFixed(2));
    newData.uom = uom;
    newData.types = types;
    newData.total = validator.isNumeric(`${newTotal}`) ? newTotal : 0;

    editItem(newData);
  };

  const handleChangeType = async (e) => {
    const newType = e.target.value;
    const newData = changeData("types", newType);
    editItem(newData);
    try {
      if (newType) {
        const res = await dispatch(
          getLaborPricing(selectedSupplier, {
            accessory: `${data.series} ${newType}`,
          })
        );
        const resData = await res.data;
        if (resData.success) {
          handleUpdate({
            types: newType,
            uomPrice: resData.result.uomPrice,
            uom: resData.result.uom,
          });
        }
      } else {
        handleUpdate({
          types: newType,
          uomPrice: 0,
          uom: "",
        });
      }
    } catch (e) {
      toast.error(e.message);
      handleUpdate({
        types: newType,
        uomPrice: 0,
        uom: "",
      });
    }
  };

  const changeData = (key, value) => ({
    ...data,
    [key]: value,
  });
  return (
    <Row>
      <Col style={styles.reduceWidthCol} lg="2">
        <Form.Group>
          <Form.Control
            disabled
            value={`${data.series} - ${data.seriesType}`}
            type="text"
            style={{ textAlign: "left" }}
          />
        </Form.Group>
      </Col>
      {/* <Col style={styles.reduceWidthCol} lg="2">
        <Form.Group>
          <Form.Control
            disabled
            value={data.types}
            type="text"
            style={{ textAlign: "left" }}
          />
        </Form.Group>
      </Col> */}
      <Col style={styles.reduceWidthCol} lg="2">
        <Form.Group>
          <Form.Control
            value={data.types}
            onChange={handleChangeType}
            style={data.types ? {} : redStyles}
            as="select"
            disabled={isLockQuoteData?.isLocked}
          >
            <option value="">Select</option>
            {data?.types_array?.map((type) => (
              <option value={type} key={type}>
                {type}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col style={styles.reduceWidthCol} lg="2" />
      <Col style={styles.reduceWidthCol}>
        <Form.Group>
          <Form.Control
            disabled
            type="text"
            style={{ textAlign: "center" }}
            value={data.uom}
          />
        </Form.Group>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <QTYInput
          value={data.qty}
          onChange={onChangeQty}
          styless={data?.original_list?.qty != data.qty}
          disabled={isLockQuoteData?.isLocked}
        />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput disabled price={data.uomPrice} />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput disabled price={data.total} />
      </Col>
    </Row>
  );
}
