import { axiosInstance as axios } from "services";
import { getEndPoints } from "api/api";
import { useState } from "react";
import { ApiEndpoints } from "lib/config/baseSettings";

const url = getEndPoints(process.env.REACT_APP_MODE);

const usProposalTab = () => {
  const [isAddSignature, setIsAddSignature] = useState(false);
  const addSignatureInvoice = (body) => {
    setIsAddSignature(false);
    return axios
      .post(ApiEndpoints.PROPOSAL_TAB.AddSignature, body)

      .then((response) => {
        if (response.data.success) {
          setIsAddSignature(response.data.success);
          // setContactManagementSchedule(response.data.success);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };
  return {
    addSignatureInvoice,
    isAddSignature,
  };
};
export default usProposalTab;
