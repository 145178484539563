import React from "react";
import { CSSTransition } from "react-transition-group";
import Next from "assets/right.svg";
import Prev from "assets/left.svg";
import { useState, useEffect } from "react";
import { convert24hrsTo12hrs } from "./utils";

export default function MonthView({
  onPrev,
  onNext,
  weekdaysShort,
  totalSlots,
  displayRow,
  displayedEvents,
  date,
  eventClicked,
  active,
  pmData,
}) {
  const [isCheck, setIsCheck] = useState(false);
  const [scheduledEvents, setScheduledEvents] = useState([]);
  const [eventId, setEventId] = useState("");
  useEffect(() => {
    setScheduledEvents(displayedEvents);
  }, [displayedEvents]);

  const rows = [];
  let cells = [];

  totalSlots.forEach((row, i) => {
    if (i % 7 !== 0) {
      cells.push(row);
    } else {
      rows.push(cells);
      cells = [];
      cells.push(row);
    }
    if (i === totalSlots.length - 1) {
      if (cells.length === 7) {
        rows.push(cells);
      } else {
        let d = 1;
        for (let i = cells.length; i < 7; i++) {
          cells.push(
            <td key={`e${d}`} className="calendar-day empty">
              <span>{d}</span>
            </td>
          );
          d += 1;
        }
        rows.push(cells);
      }
    }
  });
  const handleCheckProject = (e, eventData) => {
    let { _id, leadRequestId, proposalId, isScheduled, type } = eventData;

    setEventId(_id);
    setIsCheck(e.target.value);
    pmData(_id, leadRequestId, type, eventData, proposalId, isScheduled);
  };

  const crews = (type, arr) => {
    let crew_arr = [];
    if (type === "roof") {
      arr[0].crewSupplierId.forEach((crew) => crew_arr.push(crew.name));
      arr[0].subSupplierId.forEach((crew) => crew_arr.push(crew.name));
    } else arr[0].gutterSupplierId.forEach((crew) => crew_arr.push(crew.name));
    return crew_arr;
  };

  let get_metric_data = (type, obj) => {
    let leaders = 0;
    let gutters = 0;
    let screens = 0;
    let shingles = 0;
    let flats = 0;
    obj.metricData.forEach((metric) => {
      if (type === "gutter") {
        if (metric.leadersTotal !== "") {
          leaders = leaders + metric.leadersTotal;
        }
        if (metric.guttersTotal !== "") {
          gutters = gutters + metric.guttersTotal;
        }
        if (metric.screensTotal !== "") {
          screens = screens + metric.screensTotal;
        }
      } else {
        if (metric.metaData.hasShingles) {
          shingles = shingles + metric.totalFlat;
        } else {
          flats = flats + metric.totalFlat;
        }
      }
    });

    return type == "gutter"
      ? `(G) ${gutters} - (L) ${leaders} - (S) ${screens}`
      : `(S) ${shingles} - (F) ${flats}`;
  };

  const renderDays = () =>
    weekdaysShort.map((day) => (
      <th key={day} className="week-day">
        {day}
      </th>
    ));

  const daysinmonth = rows.map((d, i) => {
    if (i !== 0) {
      return (
        <>
          <tr>{d}</tr>
          <CSSTransition
            in={scheduledEvents.length > 0 && displayRow === `row-${i}`}
            timeout={300}
            classNames="calendar-events-list"
            unmountOnExit
          >
            <tr>
              <>
                {scheduledEvents.length > 0 && displayRow === `row-${i}` && (
                  <td colSpan="7" className="events-display">
                    {scheduledEvents
                      .sort((a, b) => {
                        a.schedules[0].updatedAt - b.schedules[0].updatedAt;
                      })
                      .map((eventData, i) => (
                        <div className="displayed-event2">
                          <input
                            style={{ marginBottom: "8px" }}
                            type="checkbox"
                            checked={eventData._id === eventId}
                            onChange={(e) => handleCheckProject(e, eventData)}
                            value={isCheck}
                          />
                          <div
                            key={eventData._id}
                            onClick={(event) =>
                              eventClicked({ event, data: eventData })
                            }
                            className="displayed-event"
                          >
                            <button
                              style={{
                                backgroundColor: eventData.badgeColor
                                  ? eventData.badgeColor
                                  : "#0051ff",
                              }}
                              className="event-detail-badge"
                              arial-label="Event-Badge"
                            />

                            <span>{`${convert24hrsTo12hrs(
                              eventData.schedules[0].scheduleStartTime.split(
                                ":"
                              )[0],
                              eventData.schedules[0].scheduleStartTime.split(
                                ":"
                              )[1]
                            )}-${crews(
                              eventData.type,
                              eventData.schedules[0].crews
                            ).join("-")}-${
                              eventData.leadRequestData[0].leadFirstName
                            }-${
                              eventData.leadRequestData[0].propertyList[0]
                                .propertyAddress[0].propertyAddress1
                            }
                            ${
                              eventData.leadRequestData[0].propertyList[0]
                                .propertyAddress[0].propertyAddress2
                            },
                            ${
                              eventData.leadRequestData[0].propertyList[0]
                                .propertyAddress[0].propertyCity
                            },
                            ${
                              eventData.leadRequestData[0].propertyList[0]
                                .propertyAddress[0].propertyState
                            }
                            ${
                              eventData.leadRequestData[0].propertyList[0]
                                .propertyAddress[0].propertyZip
                            }
                            ${get_metric_data(
                              eventData.type,
                              eventData.metrics[0]
                            )}`}</span>
                          </div>
                        </div>
                      ))}
                  </td>
                )}
              </>
            </tr>
          </CSSTransition>
        </>
      );
    }
    return null;
  });

  return (
    <div>
      <div className="_heading">
        <div>
          <button onClick={() => onPrev()} aria-label="Previous">
            <img src={Prev} height="25" width="25" alt="prev" />
          </button>
        </div>
        <div className="month-name">{date}</div>
        <div>
          <button onClick={() => onNext()} aria-label="Next">
            <img src={Next} height="25" width="25" alt="next" />
          </button>
        </div>
      </div>
      <table className="_calendar_table_month" cellPadding={0}>
        <thead>
          <tr>{renderDays()}</tr>
        </thead>
        <tbody>{daysinmonth}</tbody>
      </table>
    </div>
  );
}
