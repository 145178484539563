import React, { useState, useEffect } from "react";
import { getWeekDate } from "components/calendar/utils";
import moment from "moment";
import GutterWeekView from "./GutterWeekView";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-date-picker";
import { sortTimeWise } from "./helpers";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

let month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ProductionScheduleGutters = (props) => {
  let { onPrevWeek, onNextWeek, dateObject, setToday, certainDate, gutter } =
    props;
    
  const [weekdaysShort, setWeekdaysShort] = useState(moment.weekdaysShort());
  const [date, setDate] = useState([]);
  const [eventss, setEvents] = useState({});
  const [eventsArrayId, setEventsArrayId] = useState();

  useEffect(() => {
    if (gutter) {
      let eventsObj = {};

      gutter.sort(sortTimeWise).forEach((event) => {
        const date = event.eventDetails.date;
        const currentDayEvents = eventsObj[date];
        if (currentDayEvents) {
          eventsObj[date] = [...currentDayEvents, event];
        } else {
          eventsObj[date] = [event];
        }
      });
      setEvents(eventsObj);
      //   setState({ events: eventsObj });
    }
  }, [gutter]);

  const getEventsByDate = (date) => {
    // const { events } = state;
    let dateArray = date.split("/");
    dateArray = dateArray.map((value) => {
      if (value.length > 1) return value;
      return `0${value}`;
    });

    const newDateFormat = `${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`;
    const currentDateEvents = eventss[newDateFormat];

    return currentDateEvents ?? [];
  };

  let tooltip_data = (eventData) => {
    let gutter = eventData.metrics.filter(
      (metric) => metric.metricType === "gutter"
    );

    let gutters = 0;
    let leaders = 0;
    let screens = 0;
    if (gutter.length) {
      gutter[0]?.metricData?.forEach((val) => {
        if (val.guttersTotal !== "") {
          gutters = gutters + val.guttersTotal;
        }
        if (val.leadersTotal !== "") {
          leaders = leaders + val.leadersTotal;
        }
        if (val.screensTotal !== "") {
          screens = screens + val.screensTotal;
        }
      });
    }

    return (
      <div>
        <div style={{ display: "flex" }}>
          <h4 style={{ padding: "1rem" }}>
            Start: {eventData?.eventDetails?.startTime}
          </h4>
          <h4 style={{ padding: "1rem" }}>Gutter Crew: {eventData?.persons}</h4>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <div>
              <a
                href={`https://www.google.com/maps/place?q=${eventData?.requestObj?.address}`}
                className="address"
                target="_blank"
              >
                <h3 style={{ padding: "1rem" }}>
                  {eventData?.requestObj?.address}
                </h3>
              </a>
            </div>
            <div>
              <h4 style={{ paddingLeft: "1rem", paddingBottom: "1rem" }}>
                Gutters - {gutters} Leaders - {leaders} Screens - {screens}
              </h4>
            </div>
            <div style={{ paddingBottom: "1rem" }}>
              <h4 style={{ paddingLeft: "1rem" }}>
                Company: {eventData?.requestObj?.companyName}
              </h4>
              <h4 style={{ paddingLeft: "1rem" }}>
                Primary Name: {eventData?.requestObj?.leadName}
              </h4>
              <h4 style={{ paddingLeft: "1rem" }}>
                Phone: {eventData?.requestObj?.primary_phone}
              </h4>
            </div>
            <div>
              <h4 style={{ paddingLeft: "1rem" }}>
                Secondary Name: {eventData?.requestObj?.secondary_name}
              </h4>
              <h4 style={{ paddingLeft: "1rem" }}>
                Phone: {eventData?.requestObj?.secondary_phone}
              </h4>
            </div>
          </div>
          {gutter[0]?.coverImage.length > 0 ||
            gutter[0]?.metricData[0]?.imagesList[0] != undefined ? (
            <img
              src={
                gutter[0]?.coverImage.length > 0
                  ? gutter[0]?.coverImage[0].imageSrc
                  : gutter[0]?.metricData[0]?.imagesList[0]
                    ? gutter[0]?.metricData[0]?.imagesList[0]?.uri
                    : ""
              }
              alt=""
              style={{
                marginTop: "5rem",
                height: "15rem",
                width: "15rem",
                border: "none",
              }}
            />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  };

  const daysInWeekView = () => {
    // const { weekdaysShort } = state;
    const { dateObject } = props;

    let hover = (eventData) => {
      setEventsArrayId(eventData?.requestObj?.requestId);
    };

    const HtmlTooltip = withStyles((theme) => ({
      tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 700,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      },
    }))(Tooltip);

    return weekdaysShort.map((day, i) => {
      const dayDate = getWeekDate(i, dateObject);
      const events = getEventsByDate(dayDate);
      const dayBlock =
        new Date(moment().subtract(1, "day")) <= new Date(dayDate) ? (
          <td className="_week_view_td" key={`${day}-${i}`}>
            {events.length > 0 &&
              events.map((eventData) => (
                <div onMouseOver={() => hover(eventData)}>
                  {eventData?.requestObj?.requestId === eventsArrayId ? (
                    <HtmlTooltip
                      title={tooltip_data(eventData)}
                      arrow
                      placement="right"
                      interactive
                    >
                      <div
                        key={eventData.requestObj.requestId}
                        className="week-event"
                        style={{
                          textAlign: "center",
                          background: eventData.badgeColor
                            ? eventData.badgeColor
                            : "#0051ff",
                        }}
                      >
                        {eventData.displayText1}
                        <br />
                        {eventData.displayText2}
                      </div>
                    </HtmlTooltip>
                  ) : (
                    <div
                      key={eventData.requestObj.requestId}
                      className="week-event"
                      style={{
                        textAlign: "center",
                        background: eventData.badgeColor
                          ? eventData.badgeColor
                          : "#0051ff",
                      }}
                    >
                      {eventData.displayText1}
                      <br />
                      {eventData.displayText2}
                    </div>
                  )}
                </div>
              ))}
          </td>
        ) : (
          <td className="_week_view_td" key={`${day}-${i}`}>
            {events.length > 0 &&
              events.map((eventData) => (
                <div
                  onMouseOver={() => hover(eventData)}
                  onMouseEnter={() => hover(eventData)}
                >
                  {eventData?.requestObj?.requestId === eventsArrayId ? (
                    <HtmlTooltip
                      title={tooltip_data(eventData)}
                      arrow
                      placement="right"
                      leaveDelay={200}
                      interactive
                    >
                      <div
                        key={eventData.requestObj.requestId}
                        className="week-event"
                        style={{
                          textAlign: "center",
                          background: eventData.badgeColor
                            ? eventData.badgeColor
                            : "#0051ff",
                        }}
                      >
                        {eventData.displayText1}
                        <br />
                        {eventData.displayText2}
                      </div>
                    </HtmlTooltip>
                  ) : (
                    <div
                      key={eventData.requestObj.requestId}
                      className="week-event"
                      style={{
                        textAlign: "center",
                        background: eventData.badgeColor
                          ? eventData.badgeColor
                          : "#0051ff",
                      }}
                    >
                      {eventData.displayText1}
                      <br />
                      {eventData.displayText2}
                    </div>
                  )}
                </div>
              ))}
          </td>
        );
      return dayBlock;
    });
  };

  const changeDate = (i, date) => {
    // setState((state) => {
    const temp = [date];
    temp.splice(i, 1, date);
    // return {
    setDate(temp && temp.length && temp[0] != null && temp); // date: temp,
    // };
  };


  const weekText = () => {
    const currentYear = moment(dateObject || new Date()).year();
    const currentMonth = moment(dateObject || new Date()).month();
    const currentWeek = moment(dateObject || new Date()).week();
    return `Week ${currentWeek} ${month[currentMonth]} ${currentYear}`;
  };

  useEffect(() => {
    certainDate(date);
  }, [date]);

  const dateChange = (date) => changeDate(0, date);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        <div className="btns-group">
          <button
            className="today-btn"
            onClick={setToday}
          >
            Today
          </button>
        </div>
        <h3 className="w-100 text-lg">Production Schedule</h3>
        <div style={{ width: "20rem" }}>
          <DatePicker
            showLeadingZeros={true}
            onChange={dateChange}
            value={date.length !== 0 ? date[0] : new Date()}
            className="__datePicker"
          />
        </div>
      </div>
      <div className="p-4">
        <GutterWeekView
          weekdaysShort={weekdaysShort}
          onPrevWeek={onPrevWeek}
          onNextWeek={onNextWeek}
          dateObject={dateObject}
          weekText={weekText()}
          daysInWeekView={daysInWeekView}
        />
      </div>
    </>
  );
};

export default ProductionScheduleGutters;
