import React, { useState, useEffect } from "react";
import RequiredText from "components/required-text/required-text";
import validator from "validator";
import { checkPhone, convertDate } from "utils/utils";
import { uuid } from "uuidv4";
import Select from "react-select";
import Personnel from "components/Personnel/Personnel";
import CommonAddressFields from "components/CommonAddressFields/CommonAddressFields";
import PhoneDetails from "components/PhoneDetails/PhoneDetails";
import DatePickerNew from "components/DatePickerWithRemove";
import { Row, Col, Button, Form } from "react-bootstrap";
import {
  getCartingSuppliers,
  addCartingSuppliers,
  deleteCartingSuppliers,
  updateCartingSuppliers,
  toggleIsPreferred,
  deleteLPricingCustom,
  patchCartingSuppliers,
  getPricingCarting,
  resetPricingCarting,
} from "lib/redux/suppliers/suppliers.action";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { LoadingLoader, PriceInput } from "components";
import DeleteModal from "../../components/DeleteModal";
import { TABS_SUPPLIERS } from "./const";
import displayMsg from "components/displayMsg";
import userPersonnelData from "hooks/usePersonelData";

export function CartingSuppliers({
  setCartSuppliers,
  cartSuppliers,
  selectedSupp,
  setSelectedSupp,
  addNew,
  permission,
}) {
  const [_id, setId] = useState(uuid());
  const [isActive, setIsActive] = useState(true);
  const [isPreferred, setIsPreferred] = useState(false);
  const [opened, setOpened] = useState("");
  const [closed, setClosed] = useState("");
  const [name, setName] = useState("");
  const [cartingTypes, setTypes] = useState([]);
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, changeState] = useState("");
  const [zip, setZip] = useState("");
  const [note, setNote] = useState("");
  const [personnels, setPersonnels] = useState([]);
  const [POS, setPOS] = useState([]);
  const [CARTTYPES, setCARTTYPES] = useState([]);
  const [prevData, setPrevData] = useState({});

  const [processing, setProcessing] = useState(false);
  const [disable, setDisable] = useState(false);

  const [edit, setEdit] = useState(false);

  const dispatch = useDispatch();
  const { data, errors, isLoading } = useSelector((state) => state.staticAll);
  const [dltModal, setDltModal] = useState(false);

  const hideDeleteModal = () => setDltModal(false);
  const { cartingPersonnels = [] } = userPersonnelData();

  useEffect(() => {
    if (data) {
      setPOS(data.personnelCartingPos);
      setCARTTYPES(data.cartingTypes);
    }
  }, [data]);

  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);

  const getObjs = (servicesData, list = data.cartingTypes) => {
    if (servicesData.length > 0 && list.length > 0) {
      const res = [];
      servicesData.map((item) =>
        res.push(list.find((service) => service.value === item))
      );
      return res;
    }
    return [];
  };

  const getValues = (typeList) => {
    if (typeList && typeList.length > 0) {
      const res = [];
      typeList.map((item) => res.push(item.value));
      return res;
    }
    return [];
  };

  const hasNotChangedFields = () =>
    JSON.stringify(prevData) ===
    JSON.stringify({
      _id,
      isActive,
      isPreferred,
      name,
      cartingTypes: getValues(cartingTypes),
      phone1: phone1.toString(),
      phone2: phone2 ? phone2.toString() : "",
      fax: fax ? fax.toString() : "",
      email,
      address1,
      address2: address2 || "",
      city,
      zip: `${zip}`,
      note,
      personnels,
      opened: opened ? convertDate(opened) : "",
      closed: closed ? convertDate(closed) : "",
    });

  useEffect(() => {
    if (selectedSupp) {
      const data = cartSuppliers.find((supp) => supp._id === selectedSupp);

      if (data) {
        setId(data._id);
        setIsActive(data.isActive);
        setName(data.name);
        setTypes(getObjs(data.cartingTypes));
        setPhone1(data.phone1.toString());
        setPhone2(data.phone2 ? data.phone2.toString() : "");
        setFax(data.fax ? data.fax.toString() : "");
        setEmail(data.email);
        setAddress1(data.address1);
        setIsPreferred(data.isPreferred);
        setAddress2(data.address2 ? data.address2 : "");
        setCity(data.city);
        changeState(data.state);
        setZip(`${data.zip}`);
        setNote(data.note);
        setPersonnels(data.personnels);
        setClosed(data.closed ? convertDate(data.closed) : "");
        setOpened(data.opened ? convertDate(data.opened) : "");

        setPrevData({
          _id: data._id,
          isActive: data.isActive,
          isPreferred: data.isPreferred,
          name: data.name,
          cartingTypes: data.cartingTypes,
          phone1: data.phone1.toString(),
          phone2: data.phone2 ? data.phone2.toString() : "",
          fax: data.fax ? data.fax.toString() : "",
          email: data.email,
          address1: data.address1,
          address2: data.address2 ? data.address2 : "",
          city: data.city,
          zip: `${data.zip}`,
          note: data.note,
          personnels: data.personnels,
          opened: data.opened ? convertDate(data.opened) : "",
          closed: data.closed ? convertDate(data.closed) : "",
        });
      }
    } else {
      resetFields();
    }
  }, [cartSuppliers, selectedSupp]);

  const resetFields = () => {
    setId(uuid());
    setIsActive(true);
    setIsPreferred(false);
    setOpened("");
    setClosed("");
    setName("");
    setTypes([]);
    setPhone1("");
    setPhone2("");
    setFax("");
    setEmail("");
    setAddress1("");
    setAddress2("");
    setCity("");
    changeState("");
    setZip("");
    setNote("");
    setPersonnels([]);
  };

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const addSuppliers = () => {
    if (!selectedSupp) {
      setProcessing(true);
      return dispatch(
        addCartingSuppliers({
          isActive,
          opened,
          closed,
          name,
          cartingTypes: getValues(cartingTypes),
          phone1,
          phone2,
          fax,
          email,
          address1,
          address2,
          city,
          state,
          zip,
          note,
          personnels,
        })
      )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            const newData = res.result;
            dispatch(
              toggleIsPreferred(2, {
                supplierId: newData._id,
                isPreferred,
              })
            )
              .then((resData) => resData.data)
              .then((resData) => {
                if (resData) {
                  setCartSuppliers([
                    ...cartSuppliers,
                    { ...newData, isPreferred },
                  ]);
                  setSelectedSupp(newData._id);
                  toast.success("Carting suppliers added successfully.");
                  dispatch(getCartingSuppliers());
                } else {
                  toast.error(res.error);
                }
              })
              .catch((err) => {
                toast.error(err);
              })
              .finally(() => setProcessing(false));
          } else {
            toast.error(res.error);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => setProcessing(false));
    }
    if (selectedSupp === _id) {
      const idx = cartSuppliers.findIndex((supp) => supp._id === _id);

      setProcessing(true);
      return dispatch(
        updateCartingSuppliers(_id, {
          isActive,
          opened,
          closed,
          name,
          cartingTypes: getValues(cartingTypes),
          phone1,
          phone2,
          fax,
          email,
          address1,
          address2,
          city,
          state,
          zip,
          note,
          personnels,
        })
      )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            dispatch(
              toggleIsPreferred(2, {
                supplierId: _id,
                isPreferred,
                isSubs: true,
              })
            )
              .then((resData) => resData.data)
              .then((resData) => {
                if (resData) {
                  cartSuppliers.splice(idx, 1, {
                    _id,
                    isActive,
                    isPreferred,
                    opened,
                    closed,
                    name,
                    cartingTypes: getValues(cartingTypes),
                    phone1,
                    phone2,
                    fax,
                    email,
                    address1,
                    address2,
                    city,
                    state,
                    zip,
                    note,
                    personnels,
                  });
                  setCartSuppliers([...cartSuppliers]);
                  toast.success("Carting suppliers updated successfully.");
                  dispatch(getCartingSuppliers());
                } else {
                  toast.error(res.error);
                }
              })
              .catch((err) => {
                toast.error(err);
              })
              .finally(() => setProcessing(false));
          } else {
            toast.error(res.error);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => setProcessing(false));
    }
  };

  const handleMultiSelect = (value) => {
    if (!value) return setTypes([]);
    setTypes(value);
  };

  const dltData = () => {
    const idx = cartSuppliers.findIndex((supp) => supp._id === _id);
    setProcessing(true);
    dispatch(deleteCartingSuppliers(_id))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          hideDeleteModal();
          cartSuppliers.splice(idx, 1);
          setCartSuppliers([...cartSuppliers]);
          toast.success("Carting Supplier deleted successfully.");
          addNew(TABS_SUPPLIERS[2].id);
          resetFields();
        } else {
          toast.error(res.error);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setProcessing(false));
  };

  return (
    <div
      style={
        permission
          ? { padding: "1em" }
          : { padding: "1em", pointerEvents: "none" }
      }
    >
      <Row className="_form_top" style={{ alignItems: "center" }}>
        <Col md="6" lg="3">
          <Form.Group>
            <Form.Check
              disabled={!permission}
              name="isActive"
              checked={isActive}
              onChange={(event) => setIsActive(event.target.checked)}
              label="Account Active"
            />
          </Form.Group>
        </Col>

        {[
          {
            label: "Opened",
            value: opened,
            setValue: setOpened,
            disabled: !isActive,
            key: "_1",
          },
          {
            label: "Closed",
            value: closed,
            setValue: setClosed,
            disabled: isActive,
            key: "_2",
          },
        ].map((datePick) => (
          <Col md="6" lg="3">
            <DatePickerNew
              label={datePick.label}
              value={datePick.value}
              setValue={datePick.setValue}
              disabled={datePick.disabled}
              key={datePick.key}
            />
          </Col>
        ))}

        <Col>
          <RequiredText>*</RequiredText> <span>Required Fields</span>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group>
            <Form.Check
              disabled={!isActive}
              name="isPrefered"
              checked={isPreferred}
              onChange={(event) => setIsPreferred(event.target.checked)}
              label="Preferred Supplier"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md="6" lg="4">
          <Form.Group>
            <Form.Label>
              Carting Supplier Name: <RequiredText>*</RequiredText>
            </Form.Label>
            <Form.Control
              type="text"
              disabled={!isActive}
              name="name"
              value={name}
              onChange={(event) => handleChange(event, setName)}
            />
          </Form.Group>
        </Col>
        <Col md="6" lg="4">
          <Form.Group>
            <Form.Label>
              Carting Types: <RequiredText>*</RequiredText>
            </Form.Label>

            <Select
              className={!isActive ? "_multiselect_disabled" : ""}
              onChange={!isActive ? {} : handleMultiSelect}
              options={CARTTYPES}
              value={cartingTypes}
              isMulti
              name="services"
            />
          </Form.Group>
        </Col>
      </Row>


      <Row className="mt-2">
        <CommonAddressFields
          address1Name="address1"
          address2Name="address2"
          cityName="city"
          zipName="zip"
          stateName="state"
          address1={address1}
          address2={address2}
          city={city}
          zip={zip}
          state={state}
          setAddress1={setAddress1}
          setAddress2={setAddress2}
          setCity={setCity}
          setZip={setZip}
          setState={changeState}
          handleChange={handleChange}
          required
          allowDisabling={!isActive}
        />
      </Row>
      <Row className="mt-2">
        <PhoneDetails
          phone1Name="phone1"
          phone2Name="phone2"
          faxName="fax"
          emailName="email"
          phone1={phone1}
          phone2={phone2}
          fax={fax}
          email={email}
          state={state}
          setPhone1={setPhone1}
          setPhone2={setPhone2}
          setEmail={setEmail}
          setFax={setFax}
          handleChange={handleChange}
          required
          disabled={!isActive}
        />
      </Row>

      <Personnel
        personnel={personnels}
        setPersonnel={setPersonnels}
        posOptions={cartingPersonnels}
        disabled={!isActive || !permission}
      />

      <Row className="mt-5">
        <Col sm="12" md="12">
          <Form.Group>
            <Form.Label>General Note About Supplier:</Form.Label>
            <Form.Control
              as="textarea"
              rows="1"
              name="note"
              disabled={!isActive}
              value={note}
              onChange={(event) => handleChange(event, setNote)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row
        style={{
          //  justifyContent: "flex-end",
          margin: "1em 0",
          float: "right",
        }}
      >
        <div style={{ margin: "10px 1em", marginBottom: "2em" }}>
          <Button
            disabled={!isActive || hasNotChangedFields() || !permission}
            variant="danger"
            onClick={() => {
              resetFields();
            }}
            style={{ marginRight: "1em" }}
          >
            Cancel
          </Button>

          <Button
            disabled={
              !name ||
              cartingTypes.length === 0 ||
              !phone1 ||
              !email ||
              !address1 ||
              !city ||
              !state ||
              !zip ||
              Number.isNaN(zip) ||
              zip.length !== 5 ||
              !validator.isEmail(email) ||
              checkPhone(phone1) ||
              processing ||
              hasNotChangedFields()
            }
            onClick={() => addSuppliers()}
          >
            Save
          </Button>
        </div>
      </Row>
      <DeleteModal
        showDeleteModal={dltModal}
        deleteData={dltData}
        hideDeleteModal={hideDeleteModal}
        processing={processing}
      />
    </div>
  );
}

export function ListDataCarting({
  price,
  changePrice,
  id,
  _id,
  isCustom,
  setAddItem,
  delData,
  size,
  pricingType,
  setDisable,
}) {
  const [newPrice, setNewPrice] = useState(
    price ? parseFloat(price).toFixed(2) : parseFloat("0").toFixed(2)
  );

  const [newUom, setNewUom] = useState("");

  const [deleting, setDel] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };
  const handleInputChange = (price) => {
    setDisable(false);
    setNewPrice(price);
  };

  useEffect(() => {
    changePrice(id, { uomPrice: newPrice });
  }, [newPrice]);

  const handleDel = async () => {
    setDel(true);
    const toastId = toast.info("Deleting data. Please wait!", {
      autoClose: false,
    });
    try {
      const res = await dispatch(deleteLPricingCustom(_id));
      if (res.data.success) {
        toast.dismiss(toastId);

        if (delData(_id)) {
          setAddItem(false);
          toast.success("Data deleted successfully.");
        }
      } else {
        toast.dismiss(toastId);
        toast.error("An Error occured deleting data.Please try again later!");
      }
    } catch (err) {
      toast.dismiss(toastId);
      toast.error(err.message);
    } finally {
      setDel(false);
    }
  };

  return (
    <Row style={{ paddingBottom: "15px" }}>
      <Col>
        <Form.Group>
          <Form.Control
            style={{ textAlign: "center" }}
            type="text"
            disabled
            value={pricingType}
            onChange={(event) => handleChange(event, setNewUom)}
          />
        </Form.Group>
      </Col>

      <Col>
        <Form.Group>
          <Form.Control
            style={{ textAlign: "center" }}
            type="text"
            disabled
            value={size}
            onChange={(event) => handleChange(event, setNewUom)}
          />
        </Form.Group>
      </Col>

      <Col lg="2">
        <PriceInput
          style={{ textAlign: "right" }}
          price={newPrice}
          onChange={handleInputChange}
        />
      </Col>
      <Col>
        {isCustom && (
          <Button
            variant="danger"
            onClick={() => handleDel()}
            disabled={deleting}
          >
            {deleting ? "Deleting..." : "Delete"}
          </Button>
        )}
      </Col>
    </Row>
  );
}

export function PriceListCarting({ data, suppliersId }) {
  const [hide, setHide] = useState(false);
  const [dataList, setDataList] = useState([...data]);
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [disable, setDisable] = useState(true);

  const [prevData, setPrevData] = useState([]);

  const handleChange = (e, callback) => {
    callback(e.target.checked);
  };
  useEffect(() => {
    if (hide) {
      setPrevData(dataList);
      setDataList(
        dataList.filter((priceData) => Number(priceData.uomPrice) !== 0)
      );
    } else if (prevData.length !== 0) {
      setDataList([...prevData]);
    }
  }, [hide]);

  const changePrice = (id, value) => {
    const newList = [...dataList];
    newList.splice(id, 1, {
      ...dataList[id],
      ...value,
    });
    setDataList(newList);
  };

  const handleSave = () => {
    if (dataList.length === 0) return;
    setProcessing(true);
    setDisable(true);
    const toastId = toast.info("Updating data. Please Wait...!", {
      autoClose: false,
    });

    const invalidInputs = dataList.some(
      (priceData) => !validator.isNumeric(`${priceData.uomPrice}`)
    );
    if (invalidInputs) {
      toast.dismiss(toastId);
      toast.error("Please enter valid price.");
      setProcessing(false);
    } else {
      dataList.map((priceData, idx) => {
        dispatch(
          patchCartingSuppliers(priceData._id, {
            uomPrice: Number(priceData.uomPrice),
          })
        )
          .then((res) => res.data)
          .then((res) => {
            if (res.success) {
              if (idx === dataList.length - 1) {
                toast.dismiss(toastId);
                toast.success("Data updated successfully.");
              }
            } else {
              toast.dismiss(toastId);
              toast.error(res.error);
            }
          })
          .catch((err) => {
            toast.dismiss(toastId);
            toast.error(err.message);
          })
          .finally(() => {
            setProcessing(false), setDisable(true);
          });
      });
    }
  };

  const delData = (id) => {
    const idx = dataList.findIndex((info) => info._id === id);
    const newList = [...dataList];
    newList.splice(idx, 1);
    setDataList(newList);
    return true;
  };

  const displayList = () =>
    dataList.length > 0 ? (
      dataList.map((priceData, idx) => (
        <ListDataCarting
          dataList={dataList}
          key={priceData._id}
          _id={priceData._id}
          price={priceData.uomPrice}
          changePrice={changePrice}
          id={idx}
          delData={delData}
          pricingType={priceData.pricingType}
          size={priceData.size}
          setDisable={setDisable}
        />
      ))
    ) : (
      <div className="_tab_msg">
        <p className="__msg">No records availabe.</p>
      </div>
    );

  return (
    <Row>
      <Col>
        <Row>
          <Col />
          <Col />
          {/* <Col style={{ textAlign: "right" }} lg="2">
            <Form.Group style={{ float: "right" }}>
              <Form.Check
                style={{ marginBottom: "1em" }}
                name="hide"
                checked={hide}
                onChange={(e) => handleChange(e, setHide)}
                label="Hide non-priced item"
              />
            </Form.Group>
          </Col> */}
          <Col />
        </Row>

        <Row>
          <Col style={{ textAlign: "center" }}>
            <Form.Label>Carting Type</Form.Label>
          </Col>

          <Col style={{ textAlign: "center" }}>
            <Form.Label>Size</Form.Label>
          </Col>
          <Col style={{ textAlign: "center" }} lg="2">
            <Form.Label>Price Per UOM</Form.Label>
          </Col>
          <Col />
        </Row>
        {processing ? <LoadingLoader /> : displayList()}

        <Row
          style={{
            // justifyContent: "flex-end",
            margin: "1em 0",
            float: "right",
          }}
        >
          <div style={{ margin: "0 1em" }}>
            <Button variant="danger" style={{ marginRight: "1em" }}>
              Cancel
            </Button>

            <Button disabled={disable} variant="success" onClick={handleSave}>
              Save
            </Button>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export const CartingList = React.memo(({ selectedSupp, ...rest }) => {
  return selectedSupp ? (
    <div style={{ padding: "1em" }}>
      <p className="__msg">
        Select a carting category to start entering prices. Note: Pricing is
        unique to each carting supplier.
      </p>
      <CartingPricingList selectedSupp={selectedSupp} />
    </div>
  ) : (
    <div className="_tab_msg">
      <p className="__msg">
        Please select a Carting Supplier inorder to see the pricing list.
      </p>
    </div>
  );
});

export function CartingPricingList({ selectedSupp }) {
  const { gettingCartMaterial, cartMaterialData, cartMaterialErr } =
    useSelector((state) => state.cartingPricing);
  const dispatch = useDispatch();
  const [category, setCategory] = useState("");

  useEffect(() => {
    dispatch(resetPricingCarting());

    if (category) {
      dispatch(
        getPricingCarting(
          {
            category,
          },
          selectedSupp
        )
      );
    }
  }, [category]);

  useEffect(() => {
    if (cartMaterialErr) {
      setCategory("");
    }
  }, [cartMaterialErr]);

  useEffect(() => {
    dispatch(resetPricingCarting());
    setCategory("");
  }, [selectedSupp]);

  const checkMiscData = () => {
    if (cartMaterialData.cartingPricing.length === 0) {
      return displayMsg("No data available.");
    }
    return (
      <>
        <div>
          <p className="__msg">
            Enter cost for either dusmpsters, trucks or both.
          </p>
        </div>
        <PriceListCarting
          data={cartMaterialData.cartingPricing}
          suppliersId={selectedSupp}
        />
      </>
    );
  };

  const getList = () => {
    if (gettingCartMaterial) {
      return <LoadingLoader />;
    }
    if (cartMaterialErr) {
      return (
        <div className="_tab_msg">
          <p className="__msg">{cartMaterialErr}</p>
        </div>
      );
    }

    if (cartMaterialData && cartMaterialData.cartingPricing) {
      return <>{checkMiscData()}</>;
    }
  };
  const handleChange = (e, callback) => {
    callback(e.target.value);
  };
  return (
    <div style={{ padding: "1em" }}>
      <Row
        style={{
          justifyContent: "space-between",
          paddingBottom: "2em",
          paddingTop: "1em",
        }}
      >
        <Row>
          <Col md="3" style={{ width: "310px" }}>
            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="pos"
                value={category}
                onChange={(event) => handleChange(event, setCategory)}
              >
                <option value="">Choose...</option>
                <option value="Carting">Carting</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Row>
      {getList()}
    </div>
  );
}
