import { axiosInstance as axios, axiosInstance } from "services";
import { getEndPoints } from "api/api";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ApiEndpoints } from "lib/config/baseSettings";

const url = getEndPoints(process.env.REACT_APP_MODE);

const useProductionSchedule = () => {
  const [events, setEvents] = useState([]);
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoading && !events.length) fetch_calendar_events();
  }, []);

  let fetch_calendar_events = () => {
    setIsLoading(true);
    return axios
      .get(ApiEndpoints.PRODUCTION_DASH.getEventsForProjectSchedule)
      .then((response) => {
        if (response.data.success) {
          setEvents(response.data.result);
        } else {
          setEvents([]);
        }
      })
      .catch((err) => {
        setIsError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  return {
    events,
    isError,
    isLoading,
  };
};

export default useProductionSchedule;
