export const SuppliersActionTypes = {
  GET_MSUPPLIERS_START: "GET_MSUPPLIERS_START",
  GET_MSUPPLIERS_SUCCESS: "GET_MSUPPLIERS_SUCCESS",
  GET_MSUPPLIERS_FAILURE: "GET_MSUPPLIERS_FAILURE",
  GET_MSUPPLIERSOPTIONS_START: "GET_MSUPPLIERSOPTIONS_START",
  GET_MSUPPLIERSOPTIONS_SUCCESS: "GET_MSUPPLIERSOPTIONS_SUCCESS",
  GET_MSUPPLIERSOPTIONS_FAILURE: "GET_MSUPPLIERSOPTIONS_FAILURE",
  PRICING_MATERIAL_START: "PRICING_MATERIAL_START",
  PRICING_MATERIAL_SUCCESS: "PRICING_MATERIAL_SUCCESS",
  PRICING_MATERIAL_FAILURE: "PRICING_MATERIAL_FAILURE",
  PRICING_MATERIAL_RESET: "PRICING_MATERIAL_RESET",
  /*
   *supllier type fro preferd
   */
  PREFERD_MATERIAL_START: "PREFERD_MATERIAL_START",
  PREFERD_MATERIAL_SUCCESS: "PREFERD_MATERIAL_SUCCESS",
  PREFERD_MATERIAL_FAILURE: "PREFERD_MATERIAL_FAILURE",
  PREFERD_MATERIAL_RESET: "PREFERD_MATERIAL_RESET",
  PRICING_LABOR_START: "PRICING_LABOR_START",
  PRICING_LABOR_SUCCESS: "PRICING_LABOR_SUCCESS",
  PRICING_LABOR_FAILURE: "PRICING_LABOR_FAILURE",
  PRICING_LABOR_RESET: "PRICING_LABOR_RESET",
  PRICING_CREW_START: "PRICING_CREW_START",
  PRICING_CREW_SUCCESS: "PRICING_CREW_SUCCESS",
  PRICING_CREW_FAILURE: "PRICING_CREW_FAILURE",
  PRICING_CREW_RESET: "PRICING_CREW_RESET",
  GET_SUBCONTRACTORSUPPLIERS_START: "GET_SUBCONTRACTORSUPPLIERS_START",
  GET_SUBCONTRACTORSUPPLIERS_SUCCESS: "GET_SUBCONTRACTORSUPPLIERS_SUCCESS",
  GET_SUBCONTRACTORSUPPLIERS_FAILURE: "GET_SUBCONTRACTORSUPPLIERS_FAILURE",
  GET_CREWSUPPLIERS_START: "GET_CREWSUPPLIERS_START",
  GET_CREWSUPPLIERS_SUCCESS: "GET_CREWSUPPLIERS_SUCCESS",
  GET_CREWSUPPLIERS_FAILURE: "GET_CREWSUPPLIERS_FAILURE",
  GET_CARTINGSUPPLIERS_START: "GET_CARTINGSUPPLIERS_START",
  GET_CARTINGSUPPLIERS_SUCCESS: "GET_CARTINGSUPPLIERS_SUCCESS",
  GET_CARTINGSUPPLIERS_FAILURE: "GET_CARTINGSUPPLIERS_FAILURE",
  PRICING_CARTING_START: "PRICING_CARTING_START",
  PRICING_CARTING_SUCCESS: "PRICING_CARTING_SUCCESS",
  PRICING_CARTING_FAILURE: "PRICING_CARTING_FAILURE",
  PRICING_CARTING_RESET: "PRICING_CARTING_RESET",
};
