import * as quoteTypes from "./quotes-proposals.types";

const INITIAL_STATE_PROPOSAL_TAB = {
  showProposalTab: false,
  showProposalModal: false,
  proposalModalActiveIdx: 0,
};

export const ProposalTabReducer = (
  state = INITIAL_STATE_PROPOSAL_TAB,
  action
) => {
  switch (action.type) {
    case quoteTypes.QUOTE_PROPOSAL_TAB_DISPLAY:
      return {
        ...state,
        showProposalTab: action.payload,
      };

    case quoteTypes.QUOTE_PROPOSAL_MODAL_DISPLAY:
      return {
        ...state,
        showProposalModal: action.payload,
      };

    case quoteTypes.QUOTE_PROPOSAL_TAB_MODAL_INDEX:
      return {
        ...state,
        proposalModalActiveIdx: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_PROPOSAL_MODAL = {
  gettingModalData: false,
  modalData: null,
  modalErr: null,
};

export const QuoteProposalModalReducer = (
  state = INITIAL_STATE_PROPOSAL_MODAL,
  action
) => {
  switch (action.type) {
    case quoteTypes.QUOTE_PROPOSAL_MODAL_START:
      return {
        ...state,
        gettingModalData: true,
        modalData: null,
        modalErr: null,
      };

    case quoteTypes.QUOTE_PROPOSAL_MODAL_SUCCESS:
      return {
        ...state,
        gettingModalData: false,
        modalData: action.payload,
        modalErr: null,
      };

    case quoteTypes.QUOTE_PROPOSAL_MODAL_FAILURE:
      return {
        ...state,
        gettingModalData: false,
        modalData: null,
        modalErr: action.payload,
      };

    case quoteTypes.QUOTE_PROPOSAL_MODAL_RESET:
      return {
        ...state,
        ...INITIAL_STATE_PROPOSAL_MODAL,
      };

    default:
      return state;
  }
};

const INITIAL_STATE = {
  gettingData: false,
  metrics: null,
  metricsErr: null,
};

export const QuoteMetricsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case quoteTypes.GET_QUOTE_START:
      return {
        ...state,
        gettingData: true,
        metrics: null,
        metricsErr: null,
      };

    case quoteTypes.GET_QUOTE_SUCCESS:
      return {
        ...state,
        gettingData: false,
        metrics: action.payload,
        metricsErr: null,
      };

    case quoteTypes.GET_QUOTE_FAILURE:
      return {
        ...state,
        gettingData: false,
        metrics: null,
        metricsErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_MSUPP = {
  gettingQuoteMSuppliers: false,
  quoteMSuppliersData: null,
  quoteMSuppliersErr: null,
};

export const QuoteMaterialSuppliersReducer = (
  state = INITIAL_STATE_MSUPP,
  action
) => {
  switch (action.type) {
    case quoteTypes.QUOTE_MSUPPLIERS_START:
      return {
        ...state,
        gettingQuoteMSuppliers: true,
        quoteMSuppliersData: null,
        quoteMSuppliersErr: null,
      };

    case quoteTypes.QUOTE_MSUPPLIERS_SUCCESS:
      return {
        ...state,
        gettingQuoteMSuppliers: false,
        quoteMSuppliersData: action.payload,
        quoteMSuppliersErr: null,
      };

    case quoteTypes.QUOTE_MSUPPLIERS_FAILURE:
      return {
        ...state,
        gettingQuoteMSuppliers: false,
        quoteMSuppliersData: null,
        quoteMSuppliersErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_SCSUPP = {
  gettingQuoteSCSuppliers: false,
  quoteSCSuppliersData: null,
  quoteSCSuppliersErr: null,
};

export const QuoteSubsCrewSuppliersReducer = (
  state = INITIAL_STATE_SCSUPP,
  action
) => {
  switch (action.type) {
    case quoteTypes.QUOTE_SUBSCREWSUPPLIERS_START:
      return {
        ...state,
        gettingQuoteSCSuppliers: true,
        quoteSCSuppliersData: null,
        quoteSCSuppliersErr: null,
      };

    case quoteTypes.QUOTE_SUBSCREWSUPPLIERS_SUCCESS:
      return {
        ...state,
        gettingQuoteSCSuppliers: false,
        quoteSCSuppliersData: action.payload,
        quoteSCSuppliersErr: null,
      };

    case quoteTypes.QUOTE_SUBSCREWSUPPLIERS_FAILURE:
      return {
        ...state,
        gettingQuoteSCSuppliers: false,
        quoteSCSuppliersData: null,
        quoteSCSuppliersErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_CSUPP = {
  gettingQuoteCSuppliers: false,
  quoteCSuppliersData: null,
  quoteCSuppliersErr: null,
};

export const QuoteCartingSuppliersReducer = (
  state = INITIAL_STATE_CSUPP,
  action
) => {
  switch (action.type) {
    case quoteTypes.QUOTE_CARTINGSUPPLIERS_START:
      return {
        ...state,
        gettingQuoteCSuppliers: true,
        quoteCSuppliersData: null,
        quoteCSuppliersErr: null,
      };

    case quoteTypes.QUOTE_CARTINGSUPPLIERS_SUCCESS:
      return {
        ...state,
        gettingQuoteCSuppliers: false,
        quoteCSuppliersData: action.payload,
        quoteCSuppliersErr: null,
      };

    case quoteTypes.QUOTE_CARTINGSUPPLIERS_FAILURE:
      return {
        ...state,
        gettingQuoteCSuppliers: false,
        quoteCSuppliersData: null,
        quoteCSuppliersErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_MATERIAL_SECTION = {
  gettingMaterialSectionData: false,
  quoteMaterialSectionData: null,
  quoteMaterialSectionDataErr: null,
};

export const QuoteMaterialSectionDataReducer = (
  state = INITIAL_STATE_MATERIAL_SECTION,
  action
) => {
  switch (action.type) {
    case quoteTypes.QUOTE_MATERAILSECTION_DATA_START:
      return {
        ...state,
        gettingMaterialSectionData: true,
        quoteMaterialSectionData: null,
        quoteMaterialSectionDataErr: null,
      };

    case quoteTypes.QUOTE_MATERAILSECTION_DATA_SUCCESS:
      return {
        ...state,
        gettingMaterialSectionData: false,
        quoteMaterialSectionData: action.payload,
        quoteMaterialSectionDataErr: null,
      };

    case quoteTypes.QUOTE_MATERAILSECTION_DATA_EDIT:
      return {
        ...state,
        gettingMaterialSectionData: false,
        quoteMaterialSectionData: action.payload.sectionData,
        quoteMaterialSectionDataErr: null,
      };

    case quoteTypes.QUOTE_MATERAILSECTION_DATA_UPDATETOTAL: {
      const { sectionData, ...rest } = action.payload.sectionData;
      return {
        ...state,
        gettingMaterialSectionData: false,
        quoteMaterialSectionData: {
          ...rest,
          sectionData: state.quoteMaterialSectionData.sectionData,
        },
        quoteMaterialSectionDataErr: null,
      };
    }

    case quoteTypes.QUOTE_MATERAILSECTION_DATA_FAILURE:
      return {
        ...state,
        gettingMaterialSectionData: false,
        quoteMaterialSectionData: null,
        quoteMaterialSectionDataErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_LABOR_SECTION = {
  gettingLaborSectionData: false,
  quoteLaborSectionData: null,
  quoteLaborSectionDataErr: null,
};

export const QuoteLaborSectionDataReducer = (
  state = INITIAL_STATE_LABOR_SECTION,
  action
) => {
  switch (action.type) {
    case quoteTypes.QUOTE_LABORSECTION_DATA_START:
      return {
        ...state,
        gettingLaborSectionData: true,
        quoteLaborSectionData: null,
        quoteLaborSectionDataErr: null,
      };

    case quoteTypes.QUOTE_LABORSECTION_DATA_SUCCESS:
      return {
        ...state,
        gettingLaborSectionData: false,
        quoteLaborSectionData: action.payload,
        quoteLaborSectionDataErr: null,
      };

    case quoteTypes.QUOTE_LABORSECTION_DATA_EDIT:
      return {
        ...state,
        gettingLaborSectionData: false,
        quoteLaborSectionData: action.payload.sectionData,
        quoteLaborSectionDataErr: null,
      };

    case quoteTypes.QUOTE_LABORSECTION_DATA_UPDATETOTAL: {
      const { sectionData, ...rest } = action.payload.sectionData;
      return {
        ...state,
        gettingLaborSectionData: false,
        quoteLaborSectionData: {
          ...rest,
          sectionData: state.quoteLaborSectionData.sectionData,
        },
        quoteLaborSectionDataErr: null,
      };
    }

    case quoteTypes.QUOTE_LABORSECTION_DATA_FAILURE:
      return {
        ...state,
        gettingLaborSectionData: false,
        quoteLaborSectionData: null,
        quoteLaborSectionDataErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_DEBRIS_SECTION = {
  gettingDebrisSectionData: false,
  quoteDebrisSectionData: null,
  quoteDebrisSectionDataErr: null,
};

export const QuoteDebrisSectionDataReducer = (
  state = INITIAL_STATE_DEBRIS_SECTION,
  action
) => {
  switch (action.type) {
    case quoteTypes.QUOTE_DEBRISSECTION_DATA_START:
      return {
        ...state,
        gettingDebrisSectionData: true,
        quoteDebrisSectionData: null,
        quoteDebrisSectionDataErr: null,
      };

    case quoteTypes.QUOTE_DEBRISSECTION_DATA_SUCCESS:
      return {
        ...state,
        gettingDebrisSectionData: false,
        quoteDebrisSectionData: action.payload,
        quoteDebrisSectionDataErr: null,
      };

    case quoteTypes.QUOTE_DEBRISSECTION_DATA_EDIT:
      return {
        ...state,
        gettingDebrisSectionData: false,
        quoteDebrisSectionData: action.payload.sectionData,
        quoteDebrisSectionDataErr: null,
      };

    case quoteTypes.QUOTE_DEBRISSECTION_DATA_UPDATETOTAL: {
      const { sectionData, ...rest } = action.payload.sectionData;
      return {
        ...state,
        gettingDebrisSectionData: false,
        quoteDebrisSectionData: {
          ...rest,
          sectionData: state.quoteDebrisSectionData.sectionData,
        },
        quoteDebrisSectionDataErr: null,
      };
    }

    case quoteTypes.QUOTE_DEBRISSECTION_DATA_FAILURE:
      return {
        ...state,
        gettingDebrisSectionData: false,
        quoteDebrisSectionData: null,
        quoteDebrisSectionDataErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_GUTTERS_SECTION = {
  gettingGuttersSectionData: false,
  quoteGuttersSectionData: null,
  quoteGuttersSectionDataErr: null,
};

export const QuoteGuttersSectionDataReducer = (
  state = INITIAL_STATE_GUTTERS_SECTION,
  action
) => {
  switch (action.type) {
    case quoteTypes.QUOTE_GUTTERSSECTION_DATA_START:
      return {
        ...state,
        gettingGuttersSectionData: true,
        quoteGuttersSectionData: null,
        quoteGuttersSectionDataErr: null,
      };

    case quoteTypes.QUOTE_GUTTERSSECTION_DATA_SUCCESS:
      return {
        ...state,
        gettingGuttersSectionData: false,
        quoteGuttersSectionData: action.payload,
        quoteGuttersSectionDataErr: null,
      };

    case quoteTypes.QUOTE_GUTTERSSECTION_DATA_EDIT:
      return {
        ...state,
        gettingGuttersSectionData: false,
        quoteGuttersSectionData: action.payload.sectionData,
        quoteGuttersSectionDataErr: null,
      };

    case quoteTypes.QUOTE_GUTTERSSECTION_DATA_UPDATETOTAL: {
      const { sectionData, ...rest } = action.payload.sectionData;
      return {
        ...state,
        gettingGuttersSectionData: false,
        quoteGuttersSectionData: {
          ...rest,
          sectionData: state.quoteGuttersSectionData.sectionData,
        },
        quoteGuttersSectionDataErr: null,
      };
    }

    case quoteTypes.QUOTE_GUTTERSSECTION_DATA_FAILURE:
      return {
        ...state,
        gettingGuttersSectionData: false,
        quoteGuttersSectionData: null,
        quoteGuttersSectionDataErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_PROPOSAL = {
  gettingQuoteProposalData: false,
  quoteProposalData: null,
  quoteProposalErr: null,
  gettingStart: false,
};

export const QuoteProposalReducer = (
  state = INITIAL_STATE_PROPOSAL,
  action
) => {
  switch (action.type) {
    case "STARTING":
      return {
        ...state,
        gettingStart: true,
      };
    case "ENDING":
      return {
        ...state,
        gettingStart: false,
      };
    case quoteTypes.QUOTE_PROPOSAL_DATA_START:
      return {
        ...state,
        gettingQuoteProposalData: true,
        quoteProposalData: null,
        quoteProposalErr: null,
      };

    case quoteTypes.QUOTE_PROPOSAL_DATA_SUCCESS:
      return {
        ...state,
        gettingQuoteProposalData: false,
        quoteProposalData: action.payload,
        quoteProposalErr: null,
      };

    case quoteTypes.QUOTE_PROPOSAL_DATA_ADD:
      return {
        ...state,
        gettingQuoteProposalData: false,
        quoteProposalData: [...state.quoteProposalData, action.payload],
        quoteProposalErr: null,
      };

    case quoteTypes.QUOTE_PROPOSAL_DATA_EDIT_ALL: {
      const { updatedData } = action.payload;
      return {
        ...state,
        gettingQuoteProposalData: false,
        quoteProposalData: updatedData,
        quoteProposalErr: null,
      };
    }

    case quoteTypes.QUOTE_PROPOSAL_DATA_DELETE: {
      const { idx } = action.payload;
      const temp = [...state.quoteProposalData];
      temp.splice(idx, 1);

      return {
        ...state,
        gettingQuoteProposalData: false,
        quoteProposalData: temp,
        quoteProposalErr: null,
      };
    }

    case quoteTypes.QUOTE_PROPOSAL_DATA_FAILURE:
      return {
        ...state,
        gettingQuoteProposalData: false,
        quoteProposalData: null,
        quoteProposalErr: action.payload,
      };

    case quoteTypes.UPDATE_STAGE_SUCCESS:
      return {
        ...state,
        gettingQuoteProposalData: false,
        quoteProposalData: action.payload,
        quoteProposalErr: null,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_ACTIVE_PROPOSAL = {
  proposalId: "",
  proposalIdx: "",
};

export const ActiveProposalReducer = (
  state = INITIAL_STATE_ACTIVE_PROPOSAL,
  action
) => {
  switch (action.type) {
    case quoteTypes.QUOTE_PROPOSAL_CHANGE_ACTIVE:
      return {
        proposalId: action.payload.proposalId,
        proposalIdx: action.payload.proposalIdx,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_LOCKED_QUOTE = {
  gettingLockquoteData: false,
  quotelockData: null,
  quotelockErr: null,
};

export const lockQuoteReducer = (
  state = INITIAL_STATE_LOCKED_QUOTE,
  action
) => {
  switch (action.type) {
    case quoteTypes.LOCK_QUOTE_DATA_START:
      return {
        ...state,
        gettingLockquoteData: true,
        quotelockData: null,
        quotelockErr: null,
      };

    case quoteTypes.LOCK_QUOTE_DATA_SUCCESS:
      return {
        ...state,
        gettingLockquoteData: false,
        quotelockData: action.payload,
        quotelockErr: null,
      };

    case quoteTypes.LOCK_QUOTE_DATA_FAILURE:
      return {
        ...state,
        gettingLockquoteData: false,
        quotelockData: null,
        quotelockErr: action.payload,
      };

    default:
      return state;
  }
};
