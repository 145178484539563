import { standardStatementModel } from "services/offlineComputing/models/standardStatement";

const getCompanyStatements = async (query, selection) => {
  return standardStatementModel.find(query);
};

const getStatements = async (query, selection) => {
  try {
    return await getCompanyStatements(query, selection);
  } catch (e) {
    throw new Error(e);
  }
};

export { getStatements };
