import { getData, saveData } from "services/storageManager";
import { salesActionTypes } from "./sales.types";

import { axiosInstance } from "services";
import { ApiEndpoints } from "lib/config/baseSettings";

export const getStage = (data) => {
  return (dispatch) => {
    const isRepair = data.isRepair;
    const stageId = data.stageId;
    dispatch({ type: salesActionTypes.STAGE_START });
    if (!navigator.onLine) {
      let prefix = "stage";
      let stored_data = getData(prefix + data.stageId);

      if (!stored_data) {
        let cached_stage = isRepair
          ? getData("pastrepairStage102")
          : getData("pastsalesStage102");
        cached_stage.data.stageData.data = [];
        return dispatch({
          type: salesActionTypes.STAGE_SUCCESS,
          payload: { result: cached_stage.data, success: true },
        });
      } else {
        let calendar_fetch_stored_data = stored_data;

        return dispatch({
          type: salesActionTypes.STAGE_SUCCESS,
          payload: { result: calendar_fetch_stored_data.data, success: true },
        });
      }
    } else {
      return axiosInstance
        .post(ApiEndpoints.STAGE.GET_STAGE, { ...data })
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            return dispatch({
              type: salesActionTypes.STAGE_SUCCESS,
              payload: data,
            });
          } else {
            return dispatch({
              type: salesActionTypes.STAGE_FAILURE,
              payload: data.error,
            });
          }
        })
        .catch((errors) =>
          dispatch({
            type: salesActionTypes.STAGE_FAILURE,
            payload: errors.message,
          })
        );
    }
  };
};


export const changeStage = (data) => {
  return (dispatch) => {
    dispatch({ type: salesActionTypes.CHANGE_STAGE_START });

    if (!navigator.onLine) {
      let isRepair = true;
      if (data.stageId.includes("salesStage")) {
        isRepair = false;
      }
      let theLead;
      //find and delete lead from old stage
      const stageIds = isRepair
        ? ["000", "102", "103", "104", "105"]
        : ["000", "102", "103", "104", "105", "106", "107", "108"];
        
      for (const stage of stageIds) {
        let idString;
        if (stage === "000") {
          idString = isRepair ? "stagerepairStage102" : "stagesalesStage102";
        } else {
          idString = (isRepair ? "pastrepairStage" : "pastsalesStage") + stage;
        }
        const storedData = getData(idString);
        if (storedData !== null) {
          const parsedData = storedData;
          const leadList = parsedData.data.stageData.data;
          const newLeadList = leadList.filter((lead) => {
            if (lead.requestId === data.requestId) {
              theLead = lead;
              return false;
            }
            return true;
          });
          const newData = parsedData;
          newData.data.stageData.data = newLeadList;
          saveData(idString, newData);
        }
      }

      // add the lead to new stage

      const newStageID = data.stageId;
      const storedData = getData("past" + newStageID);
      if (storedData !== null) {
        const parsedData = storedData;
        const leadList = parsedData.data.stageData.data;
        const newLeadList = [...leadList, theLead];
        const newData = parsedData;
        newData.data.stageData.data = newLeadList;
        saveData("past" + newStageID, newData);
      } else {
        const sampleStage = isRepair
          ? getData("pastrepairStage102")
          : getData("pastsalesStage102");
        if (sampleStage === null) throw "no cache: operation error";
        const newData = sampleStage;
        newData.data.stageData.data = [theLead];
        saveData("past" + newStageID, newData);
      }

      const changeStageRequestList = getData("changeStageList");
      let newRequestList;
      if (changeStageRequestList !== null) {
        const parsedRequestList = changeStageRequestList;
        newRequestList = parsedRequestList;
        newRequestList.data = [...parsedRequestList.data, data];
      } else {
        newRequestList = { data: [data] };
      }
      saveData("changeStageList", newRequestList);
      return dispatch({
        type: salesActionTypes.CHANGE_STAGE_SUCCESS,
        payload: {
          result: { message: "Stage updated successfully." },
          success: true,
        },
      });
    }
    return axiosInstance
      .post(ApiEndpoints.STAGE.CHANGE_STAGE, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: salesActionTypes.CHANGE_STAGE_SUCCESS,
            payload: data,
          });
        } else {
          return dispatch({
            type: salesActionTypes.CHANGE_STAGE_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: salesActionTypes.CHANGE_STAGE_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const updateFollowup = (data) => {
  return (dispatch) => {
    dispatch({ type: salesActionTypes.UPDATE_FOLLOWUP_START });

    return axiosInstance
      .post(ApiEndpoints.FOLLOW_UP.UPDATE_FOLLOW_UP, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: salesActionTypes.UPDATE_FOLLOWUP_SUCCESS,
            payload: data,
          });
        } else {
          return dispatch({
            type: salesActionTypes.UPDATE_FOLLOWUP_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: salesActionTypes.UPDATE_FOLLOWUP_FAILURE,
          payload: errors.message,
        })
      );
  };
};
export const updateFollowupData = (data) => {
  return (dispatch) => {
    dispatch({ type: salesActionTypes.UPDATE_FOLLOWUP_DATA_START });

    return axiosInstance
      .post(ApiEndpoints.FOLLOW_UP.UPDATE_WON_OR_FUTURE_FOLLOW_UP, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: salesActionTypes.UPDATE_FOLLOWUP_DATA_SUCCESS,
            payload: data,
          });
        } else {
          return dispatch({
            type: salesActionTypes.UPDATE_FOLLOWUP_DATA_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: salesActionTypes.UPDATE_FOLLOWUP_DATA_FAILURE,
          payload: errors.message,
        })
      );
  };
};
