const { saveData, getData } = require("services/storageManager");
const {
  find: findMongo,
  save: saveMongo,
  findOne: findOneMongo,
  updateOne: updateOneMongo,
  deleteAll: deleteAllMongo,
  deleteMany: deleteManyMongo,
} = require("./mongoDB");

const modelName = "quoteMaterial";

const find = (query) => findMongo(modelName, query);
const save = (data) => saveMongo(modelName, data);
const findOne = (query) => findOneMongo(modelName, query);
const updateOne = (query, data) => updateOneMongo(modelName, query, data);
const deleteAll = () => deleteAllMongo(modelName);
const deleteMany = (query) => deleteManyMongo(modelName, query);

const getLength = () => {
  const documents = getData(modelName);
  if (documents === null) {
    return 0;
  }
  return documents.length;
};

const quoteMaterialModel = {
  find,
  save,
  findOne,
  updateOne,
  deleteAll,
  getLength,
  deleteMany,
};
export default quoteMaterialModel;
