import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import colors from "lib/constants/color";
import Logo from "components/Logo";
import { MdNotifications } from "react-icons/md";
import { IoPersonCircleOutline } from "react-icons/io5";

import "./header.css";

import userPermission from "hooks/usePermission";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Box from "@material-ui/core/Box";

import Modal from "@material-ui/core/Modal";
import {
  getAllNotification,
  updateNotification,
} from "lib/redux/user/user.actions";
import DeleteModal from "components/DeleteModal";
import { timeAgo } from "utils/utils";

const styyle = {
  "@media (max-width: 600px)": { width: 500 },
  "@media (min-width: 600px)": { width: 500 },
  "@media (min-width: 821px)": { width: 500 },

  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 300,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  // p: 2,
  overflow: "auto",
  borderRadius: "10px",
};
const styles = {
  navBar: {
    background: colors.primaryColor,
    padding: "0 15px",
    minHeight: "65px",
  },
};

export default function Header({ signOut, userRole, paymentStatus }) {
  const { gettingPermission, permission, permissionErr } = useSelector(
    (state) => state.allPermissions
  );
  const { notificationList, NotificationlistErr } = useSelector(
    (state) => state.allnotification
  );
  const { notificationUpdateList } = useSelector(
    (state) => state.notificationupdate
  );

  // const isFetchingRedux = useSelector((state) => state.fetch.isFetching);

  const loginData = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [dropDown, setDropDown] = useState(false);
  const [dropDownUser, setDropDownUser] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [notification, setNotification] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [notificationId, setNotificationId] = useState("");

  //#region online offline handle
  const history = useHistory();
  const location = useLocation();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // const [isFetching, setIsFetching] = useState(isFetchingRedux);

  // const setOnline = () => {
  //   setIsOnline(true);
  // };

  // const setOffline = () => {
  //   console.log("setting is offline");
  //   const isOfflinePath =
  //     /activity/.test(location.pathname) ||
  //     /metrics/.test(location.pathname) ||
  //     /proposal-quotes/.test(location.pathname);
      
  //   if (!isOfflinePath) {
  //     history.replace("/dashboard/activity/sales");
  //   }
  //   setIsOnline(false);
  // };

  // useEffect(() => {
  //   addEventListener("offline", setOffline);
  //   addEventListener("online", setOnline);

  //   return () => {
  //     removeEventListener("offline", setOffline);
  //     removeEventListener("online", setOnline);
  //   };
  // }, []);
  //#endregion online offline handle

  // useEffect(() => {
  //   setIsFetching(isFetchingRedux);
  // }, [isFetchingRedux]);

  useEffect(() => {
    dispatch(getAllNotification());
  }, []);

  useEffect(() => {
    if (notificationUpdateList?.success === true) {
      setOpenModal(false);

      dispatch(getAllNotification());
    }
  }, [notificationUpdateList]);
  const {
    hasAccessForSale,
    hasAccessForRepairs,
    hasAccessForMaterial,
    hasAccessForSaleCalender,
    hasAccessForRepairsCalender,
  } = userPermission(permission);
  console.log({
    ggg: permission,
    hasAccessForSale,
    hasAccessForRepairs,
    hasAccessForMaterial,
    hasAccessForSaleCalender,
    hasAccessForRepairsCalender,
    isOnline,
  });

  const [showSignOut, setShowSignout] = useState(false);
  const hidingModal = () => {
    setShowSignout(false);
  };

  const DeleteModalComponent = DeleteModal.bind(this, {
    showDeleteModal: showSignOut,
    deleteData: () => signOut(),
    processing: false,
    heading: "Confirm Signout?",
    hideDeleteModal: hidingModal,
    dltSubHead: "Cannot login back in offline mode",
    btnText: "Yes, Sign out",
  });

  const handleOnClickDropDownNotification = (res) => {
    setNotification(res?.message);
    setNotificationId(res?._id);
    setOpenModal(true);
  };
  const hideModal = () => {
    dispatch(updateNotification(notificationId));
  };
  useEffect(() => {
    if (notificationList && notificationList.length) {
      const filterNotificationData = notificationList?.filter((elem) => {
        return elem.checked !== true && elem.type !== "SubScription Admin";
      });
      setNotificationData(filterNotificationData);
    }
  }, [notificationList]);

  return (
    <>
      {DeleteModalComponent()}
      <Navbar
        className="nav-bar"
        collapseOnSelect
        expand="lg"
        style={styles.navBar}
        variant="light"
      >
        <Link to="/" className="logo">
          <Logo
            height="45px"
            style={{
              height: "45px",
              width: "110px",
            }}
          />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Link
              to="/dashboard/production"
              className="nav-links"
              style={
                !isOnline ? { color: "gray", pointerEvents: "none" } : null
              }
            >
              Dashboard
            </Link>
            {hasAccessForSaleCalender &&
            hasAccessForRepairsCalender &&
            isOnline ? (
              <Link className="nav-links" to="/dashboard/calendar">
                Calendar
              </Link>
            ) : null}
            {/* {(hasAccessForSaleCalender && hasAccessForRepairsCalender) ||
              !isOnline ? (
              <Link
                className="nav-links"
                to="/dashboard/calendar"
                style={{ color: "gray", pointerEvents: "none" }}
              >
                Calendar
              </Link>
            ) : (
              <Link className="nav-links" to="/dashboard/calendar">
                Calendar
              </Link>
            )} */}

            <NavDropdown
              className="nav-drop-down-menu"
              title="Activity"
              id="collasible-nav-dropdown"
            >
              {!hasAccessForSale ? (
                <Link
                  className="menu-items"
                  to=""
                  style={{ color: "gray", pointerEvents: "none" }}
                >
                  Sales
                </Link>
              ) : (
                <Link className="menu-items" to="/dashboard/activity/sales">
                  Sales
                </Link>
              )}
              {!hasAccessForRepairs ? (
                <Link
                  className="menu-items"
                  to=""
                  style={{ color: "gray", pointerEvents: "none" }}
                >
                  Repairs
                </Link>
              ) : (
                <Link className="menu-items" to="/dashboard/activity/repairs">
                  Repairs
                </Link>
              )}
            </NavDropdown>

            <Link
              to="/dashboard/project-management"
              className="nav-links"
              style={
                !isOnline
                  ? {
                      color: "gray",
                      pointerEvents: "none",
                      whiteSpace: "nowrap",
                    }
                  : { whiteSpace: "nowrap" }
              }
            >
              Project Management
            </Link>
            <NavDropdown
              className="nav-drop-down-menu"
              title={
                <span style={!isOnline ? { color: "gray" } : null}>Admin</span>
              }
              id="collasible-nav-dropdown"
              disabled={!isOnline}
              style={
                !isOnline ? { color: "gray", pointerEvents: "none" } : null
              }
            >
              <Link
                target="_blank"
                rel="noreferrer"
                style={{ width: "250px" }}
                className="menu-items"
                to="/dashboard/application-settings"
              >
                Application Settings
              </Link>
              {!hasAccessForMaterial ? (
                <p className="menu-items" style={{ color: "gray" }}>
                  Suppliers Material & Labor
                </p>
              ) : (
                <Link
                  target="_blank"
                  rel="noreferrer"
                  style={{ width: "270px" }}
                  className="menu-items"
                  to="/dashboard/suppliers"
                >
                  Suppliers Material & Labor
                </Link>
              )}
            </NavDropdown>
            <Link
              className="nav-links"
              to="/dashboard/help"
              target="_blank"
              rel="noreferrer"
              style={
                !isOnline ? { color: "gray", pointerEvents: "none" } : null
              }
            >
              Help
            </Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          style={{ justifyContent: "flex-end" }}
        >
          <div>
            <span
              style={{
                fontSize: "1.3rem",
                fontWeight: "500",
                fontFamily: "monospace",
                color: "#FFE976",
              }}
            >
              {/* {!isOnline ? `Offline` : isFetching ? "Syncing" : ""} */}
            </span>
            <span
              style={{
                fontSize: "1.3rem",
                fontWeight: "500",
                fontFamily: "monospace",
                color: "white",
              }}
            >
              {paymentStatus == "Waived/Comp"
                ? `Evaluation`
                : paymentStatus === "Frozen 1"
                ? `Read-Only`
                : ""}
            </span>
          </div>
          <div class="dropdown" style={{ marginRight: "1rem" }}>
            <button
              className="btn dropdown-toggle nav-links"
              type="button"
              id="dropdownMenu2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => {
                setDropDown(!dropDown);
                setDropDownUser(false);
              }}
              style={notificationData?.length > 0 ? { color: "yellow" } : {}}
            >
              <span className="badge badge-pill badge-warning">
                {notificationData?.length > 0 ? notificationData?.length : null}
              </span>
              <MdNotifications size={25} />
            </button>
            <div className={dropDown ? "dropdown-menu show" : "dropdown-menu"}>
              {notificationData?.length > 0 ? (
                notificationData?.map((res, index) => {
                  return (
                    <div
                      className="dropdown-item"
                      onClick={() => handleOnClickDropDownNotification(res)}
                    >
                      <p className="m-0 text-sm">
                        {index + 1}. {res?.message?.split(" ", 2)}
                      </p>

                      <p style={{ fontSize: "0.6rem" }}>
                        {" "}
                        {timeAgo(res?.createdAt)}
                      </p>
                    </div>
                  );
                })
              ) : (
                <p className="empty-text-notification text-sm">
                  No notification found
                </p>
              )}
            </div>
          </div>
          <div
            onClick={() => {
              setDropDownUser(!dropDownUser);
              setDropDown(false);
            }}
            style={{ marginRight: "1rem", cursor: "pointer" }}
          >
            <IoPersonCircleOutline size={25} color="white" />
            <div
              className={
                dropDownUser
                  ? "dropdown-menu show p-2 m-2"
                  : "dropdown-menu p-2"
              }
            >
              <strong
                style={{ margin: 0 }}
                className="text-sm"
              >{`${loginData.user.userData.firstName} ${loginData.user.userData.lastName}`}</strong>
              <p
                style={{ margin: 0, marginTop: "0.3rem", fontSize: "0.8rem" }}
              >{`${loginData.user.userData.role[0].roleName}`}</p>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <button
                  onClick={() => {
                    if (!navigator.onLine) {
                      setShowSignout(true);
                    } else signOut();
                  }}
                  className="nav-links"
                  style={{
                    whiteSpace: "nowrap",
                    padding: 0,
                    color: colors.primaryColor,
                  }}
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>

          <div></div>
        </Navbar.Collapse>
      </Navbar>
      <Modal
        keepMounted
        open={openModal}
        // onClose={hideModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styyle}>
          <div
            style={{
              backgroundColor: "yellow",
              textAlign: "center",
              padding: "2%",
              borderRadius: "0 0 15px 15px",
            }}
          >
            <h3>Organized Roofer Notification</h3>
          </div>
          <div style={{ padding: "20px", height: "53%", overflow: "auto" }}>
            <h4>{notification}</h4>
          </div>
          <div style={{ width: "20%", float: "right", marginTop: "5%" }}>
            <button
              className="btn btn-primary"
              onClick={hideModal}
              style={{ width: "80%" }}
            >
              Ok
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
