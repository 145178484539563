import React from "react";
import { Form, InputGroup, Col } from "react-bootstrap";
import RequiredText from "components/required-text/required-text";
import MaskedInput from "react-maskedinput";

export default function PhoneDetails({
  phone1Name,
  phone2Name,
  faxName,
  emailName,
  phone1,
  phone2,
  fax,
  email,
  setPhone1,
  setPhone2,
  setEmail,
  setFax,
  handleChange,
  required,
  disabled = false,
}) {
  return (
    <>
      <Col md="6" lg="3">
        <Form.Group>
          <Form.Label>
            Main Phone: {required && <RequiredText>*</RequiredText>}
          </Form.Label>

          <InputGroup>
            <MaskedInput
              className="form-control"
              mask="(111) 111-1111"
              type="text"
              name={phone1Name}
              value={phone1}
              placeholder=""
              required="required"
              maxLength={10}
              onChange={(event) => handleChange(event, setPhone1)}
              disabled={disabled}
            />
          </InputGroup>
        </Form.Group>
      </Col>

      <Col md="6" lg="3">
        <Form.Group>
          <Form.Label>Main Phone 2:</Form.Label>

          <InputGroup>
            <MaskedInput
              className="form-control"
              mask="(111) 111-1111"
              type="text"
              name={phone2Name}
              value={phone2}
              placeholder=""
              required="required"
              maxLength={10}
              onChange={(event) => handleChange(event, setPhone2)}
              disabled={disabled}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md="6" lg="3">
        <Form.Group>
          <Form.Label>Main Fax:</Form.Label>

          <InputGroup>
            <MaskedInput
              className="form-control"
              mask="(111) 111-1111"
              type="text"
              name={faxName}
              value={fax}
              placeholder=""
              required="required"
              maxLength={10}
              onChange={(event) => handleChange(event, setFax)}
              disabled={disabled}
            />
          </InputGroup>
        </Form.Group>
      </Col>

      <Col md="6" lg="3">
        <Form.Group>
          <Form.Label>
            Main Email: {required && <RequiredText>*</RequiredText>}
          </Form.Label>
          <Form.Control
            type="text"
            name={emailName}
            value={email}
            placeholder=""
            required="required"
            onChange={(event) => handleChange(event, setEmail)}
            disabled={disabled}
          />
        </Form.Group>
      </Col>
    </>
  );
}
