export const calculateTotal = (sectionData) => {
  let total = 0;
  if (sectionData.length > 0) {
    total = sectionData.reduce((acc, data) => {
      return (acc = Number.parseFloat(acc) + Number.parseFloat(data.total));
    }, 0);
  }

  return Number.parseFloat(total).toFixed(2);
};

export const checkAndUpdate = async (model, data) => {
  const check = await model.find({ _id: data._id });
  console.log("check finding", check);
  if (check.length > 0) {
    //update
    model.updateOne({ _id: data._id }, { ...data });
  } else {
    //save new
    model.save({ ...data });
  }
};
