export const removeUnwantedSections = (sectionsData, buildingsData) => {
  let buildingIds = new Set();
  buildingsData.forEach((building) => buildingIds.add(building.bid));
  return sectionsData.filter((section) => buildingIds.has(section.building));
};

export const storeMetricDataFinal = (
  addMetric,
  metricType,
  requestId,
  coverImage,
  sectionData,
  onSuccess
) => {
  addMetric({
    metricType,
    requestId,
    metricData: sectionData,
    coverImage,
  })
    .then((res) => res.payload)
    .then((data) => {
      if (data.success) {
        onSuccess();
      }
    });
};

export const DeleteSection = {
  heading: "Section Delete Confirmation",
  subHeading:
    "This is a permanent delete, all the collected data and images for this section will be lost. Continue with delete?",
};

export const getBuildingName = (coopUnits, id) => {
  const match = coopUnits.find((unit) => unit.bid === id);
  if (match) {
    return match.bname;
  }
  return;
};
