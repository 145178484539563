import React, { useState } from "react";
import Draggable from "react-draggable";
import "./letterHead.css";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";

const LetterheadCanvas = (props) => {
  let {
    columns,
    setColumns,
    setX,
    setY,
    chosenIndex,
    setIndex,
    setShowMessage,
  } = props;
  const [show, setShow] = useState(false);

  const eventHandler = (e, data, index) => {
    columns[index].x = data.x;
    columns[index].y = data.y;
    setX(data.x);
    setY(data.y);
    setColumns(columns);
  };

  const togglePopup = () => {
    setShow(!show);
  };

  const Modal = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h5 style={{ margin: "7px" }}>Are you sure you want to delete this</h5>
        <span>
          <button
            style={{ margin: "5px" }}
            className="btn btn-danger"
            onClick={() => {}}
          >
            Delete
          </button>
          <button
            style={{ margin: "5px" }}
            className="btn btn-warning"
            onClick={togglePopup}
          >
            Cancel
          </button>
        </span>
      </div>
    );
  };
  return (
    <>
      <div style={{ position: "relative" }}>
        <Popup open={show} contentStyle={{ width: "40rem", height: "6rem" }}>
          <Modal />
        </Popup>
        {columns.length
          ? columns.map((column, index) => {
              let { content, x, y, length, breadth, type } = column;
              if (type && type === "image") {
                return (
                  <Draggable
                    onDrag={(e, data) => eventHandler(e, data, index)}
                    position={{ x: x, y: y }}
                  >
                    <img
                      onClick={() => {
                        setIndex(index);
                      }}
                      src={content}
                      style={{
                        width: breadth,
                        height: length,
                        position: "absolute",
                        cursor: "move",
                        //border:
                        //index == chosenIndex ? "3px solid green" : "none",
                      }}
                    />
                  </Draggable>
                );
              } else {
                return (
                  <Draggable
                    onDrag={(e, data) => eventHandler(e, data, index)}
                    position={{ x: x, y: y }}
                  >
                    <div
                      onClick={() => {
                        setIndex(index);
                      }}
                      dangerouslySetInnerHTML={{ __html: content }}
                      style={{
                        position: "absolute",
                        cursor: "move",
                        //border:
                        //index == chosenIndex ? "3px solid green" : "none",
                      }}
                    ></div>
                  </Draggable>
                );
              }
            })
          : ""}
      </div>
    </>
  );
};

export default LetterheadCanvas;
