import React from "react";
import moment from "moment";
import Next from "../../assets/right.svg";
import Prev from "../../assets/left.svg";
import { getWeekDate } from "./utils";

export default function WeekView({
  onPrevWeek,
  onNextWeek,
  weekdaysShort,
  dateObject,
  weekText,
  daysInWeekView,
}) {
  const renderWeekDays = () => {
    const today = moment().format("l");
    return weekdaysShort.map((day, i) => {
      const dateString = new Date(getWeekDate(i, dateObject))
        .toDateString()
        .split(" ");
      return (
        <th
          key={day}
          className={`week-day${
            today === getWeekDate(i, dateObject) ? " today " : ""
          } _week_view_th`}
        >
          <div>{day}</div>
          <div className="date">{`${dateString[1]} ${Number(
            dateString[2]
          )}`}</div>
        </th>
      );
    });
  };

  const BtnIcon = ({ src, alt }) => (
    <img src={src} height="25" width="25" alt={alt} />
  );

  return (
    <div>
      <div className="_heading">
        <div>
          <button onClick={onPrevWeek}>
            <BtnIcon src={Prev} alt="prev" />
          </button>
        </div>
        <div className="month-name" style={{ fontSize: "1.8em" }}>
          {weekText}
        </div>
        <div>
          <button onClick={onNextWeek}>
            <BtnIcon src={Next} alt="next" />
          </button>
        </div>
      </div>
      <table
        className="_calendar_table_week"
        cellPadding={0}
        // style={daysInWeekView ? { height: "187px" } : { height: "" }}
      >
        <thead>
          <tr style={{ border: "1px solid rgb(222, 222, 222)" }}>
            {renderWeekDays()}
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              border: "1px solid rgb(222, 222, 222)",
              borderTop: "none",
              height: "8em",
            }}
          >
            {daysInWeekView()}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
