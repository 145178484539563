import { LoadingLoader, ModalTemplate } from "components";
import { ModalFooter } from "components/popup-modal/popup-modal";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { axiosInstance } from "services";

export function EmailModal({ isShow, onHide, sendEmails, leadId }) {
  const [selectedContact, setSelectedContact] = useState(null);
  const [emailList, setEmailList] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [emailSeletion, setEmailSelection] = useState([]);

  useEffect(() => {
    if (!isShow) return;
    axiosInstance
      .post(`contactManagement/getEmailModal`, { leadRequestId: leadId })

      .then((response) => {
        if (response.data.success) {
          setSelectedContact(response.data.result[0]);
        } else {
          setSelectedContact(null);
          console.error("error email modal");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response);
          setSelectedContact(null);
        }
      });
  }, [leadId, isShow]);

  useEffect(() => {
    if (selectedContact) {
      extractEmailList(selectedContact);
    }
  }, [selectedContact]);

  useEffect(() => {
    const isEmailSelected = emailList.map((value) => {
      return selectedEmail.includes(value.email);
    });
    setEmailSelection(isEmailSelected);
  }, [selectedEmail]);

  const extractEmailList = (data) => {
    const { primaryEmail1, secondaryAddress1 } = data.primary_data[0];
    setEmailList([
      { label: "Primary Email - 1", email: primaryEmail1 },
      { label: "Primary Email - 2", email: secondaryAddress1 },
    ]);
  };

  const selectEmail = (email) => {
    if (selectedEmail.includes(email)) {
      const removeIndex = selectedEmail.indexOf(email);
      const newSelectedEmail = selectedEmail;
      newSelectedEmail.splice(removeIndex, 1);
      setSelectedEmail((prev) => [...newSelectedEmail]);
    } else {
      setSelectedEmail((prev) => [...prev, email]);
    }
  };

  return (
    <ModalTemplate
      show={isShow}
      styleBody={{ width: '80%' }}
      onHide={onHide}
      heading={"Send Email to Contacts"}
    >
      <div>
        {emailList?.length > 0 ? (
          emailList.map((value, i) => (
            <EmailLine
              key={i}
              label={value.label}
              value={value.email}
              onSelect={selectEmail}
              isSelected={emailSeletion[i]}
            />
          ))
        ) : (
          <LoadingLoader />
        )}
      </div>
      <ModalFooter
        onCancel={onHide}
        onSubmit={() => {
          sendEmails(selectedEmail);
          onHide();
        }}
        submitText="Apply"
      />
    </ModalTemplate>
  );
}

function EmailLine({ label, value, onSelect, isSelected }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1rem",
      }}
    >
      <strong style={{ fontSize: '1rem' }}>{`${label}: `}</strong>
      <strong style={{ fontSize: '1rem' }}  >{value || 'NA'}</strong>
      <Button
        onClick={() => {
          onSelect(value);
        }}
        variant={isSelected ? "danger" : "primary"}
        disabled={!value || value === ""}
      >
        {isSelected ? "Deselect" : "Select"}
      </Button>
    </div>
  );
}
