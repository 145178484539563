
export const styles = {
    container: {
      boxShadow: "0 0 10px #99999996",
      background: "#fff",
      padding: "2em 1em",
    },
    heading: {
      flex: 1,
    },
    header: {
      padding: "25px 0",
    },
    formInput: {
      marginBottom: "15px",
    },
    formStyle: {
      // marginTop: "5%"
    },
  };
  