import { useState } from "react";

const { ApiEndpoints } = require("lib/config/baseSettings");
const { axiosInstance } = require("services");

export const useCompany = () => {

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [updateResponse, setUpdateResponse] = useState(null);
    const [paymentMethodDetails, setPaymentMethodDetails] = useState(null);

    const updatePaymentMethod = (cId, body) => {
        setIsLoading(true);
        axiosInstance.post(ApiEndpoints.USER.update_payment_method(cId), body)
            .then((response) => {
                setIsLoading(false);
                setUpdateResponse(response?.data?.result);
                return { error: null, response };
            })
            .catch((error) => {
                if (error.response) {
                    setIsLoading(false);
                    setError(error.response)
                    return { error: error.response };
                }
            });
    }

    return {
        updatePaymentMethod,
        error,
        isLoading,
        updateResponse
    }
}