import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import logoImg from 'images/or-logo-white.png';
import colors from 'lib/constants/color';

const newStyle = {
	imgStyle: {
		backgroundColor: colors.primaryColor,
	},
};

class Logo extends Component {
	render() {
		const styleProps = this.props.style;
		const style = styleProps || {};

		return (
			<div
				style={{
					height: this.props.height || 'auto',
				}}
			>
				<Image
					src={logoImg}
					style={{ ...newStyle.imgStyle, ...style }}
					rounded
					fluid
					alt='Logo'
				/>
			</div>
		);
	}
}

export default Logo;
