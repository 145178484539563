import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import styles from "lib/config/baseStyles";
import { ModalTemplate } from "components";

export default function QuoteNameInput({
  quoteName,
  changeQuoteName,
  dispalyInp,
  setDisplayInp,
}) {
  const inpRef = useRef("");

  const copyNameToInput = () => {
    inpRef.current.value = quoteName;
  };

  useEffect(() => {
    copyNameToInput();
  }, [quoteName]);

  const disableInput = () => {
    setDisplayInp(false);
  };

  const onSave = () => {
    const newVal = inpRef.current.value;
    disableInput();
    if (newVal === quoteName || !newVal.trim()) {
      return copyNameToInput();
    }
    changeQuoteName(newVal);
  };

  const enableInput = () => {
    setDisplayInp(true);
  };

  const onEdit = () => {
    enableInput();
  };

  const onCancel = () => {
    copyNameToInput();
    disableInput();
  };

  return (
    <Col style={styles.reduceWidthCol} md="12" lg="6">
      <Row style={{ marginBottom: "1em" }}>
        <Col style={styles.reduceWidthCol} md="8" lg="8">
          <div className="_search">
            <input
              ref={inpRef}
              style={{ width: "100%", fontWeight: "bold" }}
              disabled={!dispalyInp}
            />
          </div>
        </Col>
        <Col style={styles.reduceWidthCol} lg="4">
          {dispalyInp ? (
            <>
              <Button variant="success" onClick={onSave}>
                <strong>Save</strong>
              </Button>
              <Button className="ml-1" variant="danger" onClick={onCancel}>
                <strong>Cancel</strong>
              </Button>
            </>
          ) : (
            <Button onClick={onEdit}>
              <strong>Edit</strong>
            </Button>
          )}
        </Col>
      </Row>
    </Col>
  );
}

const QuoteNamePopup = ({
  currentName,
  setDisplayInp,
  displayInp,
  changeQuoteName,
}) => {
  const [quoteName, setQuoteName] = useState(currentName);
  return (
    <ModalTemplate
      show={displayInp}
      confirm={() => {}}
      onHide={() => setDisplayInp(false)}
      heading="Edit Proposal Name"
      styleBody={{}}
      // textStyle={{ color: "red" }}
      // text={`Deleting '${quoteName}' is permanent. Continue with delete?`}
    >
      <input
        style={{ width: "100%", marginBottom: "2rem", padding: "0.5rem" }}
        type="text"
        value={quoteName}
        onChange={(e) => {
          setQuoteName(e.target.value);
        }}
      />

      <Modal.Footer
        style={{
          justifyContent: "flex-end",
          border: "none",
          padding: 0,
        }}
      >
        <Button
          // disabled={deleting}
          style={{ width: "130px", height: "50px" }}
          variant="danger modified-btn"
          onClick={() => setDisplayInp(false)}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            changeQuoteName(quoteName);
            setDisplayInp(false);
          }}
          style={{ width: "130px", height: "50px", marginRight: 0 }}
        >
          Save
        </Button>
      </Modal.Footer>
    </ModalTemplate>
  );
};

export { QuoteNamePopup };
