import React, { useState, useEffect, useRef } from "react";
import "../css/date.scss";
import { Col, Form } from "react-bootstrap";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

function ChangeOrder(props) {
  const { order, styles, type } = props;
  const [changeOrder, setChangeOrder] = useState();
  const [changeOrderSign, setChangeOrderSign] = useState();
  const [changeOrderNotes, setChangeOrderNotes] = useState();
  const [changeOrderEdit, setChangeOrderEdit] = useState(false);

  const { editChangeOrderForSingleOrder } = props;

  let change_order = (e) => {
    setChangeOrder(e.target.value);
  };

  let change_order_notes = (e) => {
    setChangeOrderNotes(e.target.value);
  };

  let change_order_sign = (e) => {
    setChangeOrderSign(e.target.value);
  };

  let change_order_value = () => {
    return changeOrder === "" || changeOrder
      ? changeOrder
      : order.changeOrderPrice;
  };

  let change_order_notes_value = () => {
    return changeOrderNotes === "" || changeOrderNotes
      ? changeOrderNotes
      : order.changeOrderNotes;
  };

  return (
    <>
      <Col lg="2" md="3" sm="3" xs="3" style={{ marginLeft: "14.5rem" }}>
        <Form.Group style={styles.formInput}>
          <Form.Label></Form.Label>
          {type === "roof" ? (
            <Form.Control
              type="number"
              disabled={!changeOrderEdit}
              value={change_order_value()}
              placeholder="$"
              onChange={change_order}
            ></Form.Control>
          ) : (
            <Form.Control
              type="number"
              disabled={!changeOrderEdit}
              value={change_order_value()}
              placeholder="$"
              onChange={change_order}
            ></Form.Control>
          )}
        </Form.Group>
      </Col>
      <Col md="3" sm="12" style={{ marginTop: "-.5rem" }}>
        <Form.Group style={styles.formInput}>
          <Form.Label> </Form.Label>
          {type === "roof" ? (
            <Form.Control
              as="textarea"
              placeholder="C/O Notes"
              rows="2"
              disabled={!changeOrderEdit}
              value={change_order_notes_value()}
              onChange={change_order_notes}
            ></Form.Control>
          ) : (
            <Form.Control
              as="textarea"
              placeholder="C/O Notes"
              disabled={!changeOrderEdit}
              rows="2"
              value={change_order_notes_value()}
              onChange={change_order_notes}
            ></Form.Control>
          )}
        </Form.Group>
      </Col>
      <Col style={{ marginTop: "-.5rem" }}>
        <Form.Group style={styles.formInput}>
          <Form.Label>Signed</Form.Label>
          <Form.Control
            disabled={!changeOrderEdit}
            type="date"
            onChange={change_order_sign}
            value={
              changeOrderSign
                ? changeOrderSign
                : order.signature
                ? order.signature
                : ""
            }
          ></Form.Control>
        </Form.Group>
      </Col>
      <Col>
        {changeOrderEdit ? (
          <>
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginTop: "1.5rem" }}
              disabled={!changeOrderSign && !changeOrder && !changeOrderNotes}
              onClick={() => {
                setChangeOrderEdit(false);
                editChangeOrderForSingleOrder({
                  _id: order?._id,
                  changeOrderPrice: changeOrder,
                  changeOrderNotes: changeOrderNotes,
                  signature: changeOrderSign,
                });
                onReload();
              }}
            >
              Save
            </button>
            <button
              type="button"
              class="btn btn-warning"
              style={{ marginTop: "1.5rem", marginLeft: "1.5rem" }}
              onClick={() => {
                setChangeOrder(order?.changeOrderPrice);
                setChangeOrderNotes(order?.changeOrderNotes);
                setChangeOrderSign(order?.signature);
                setChangeOrderEdit(false);
              }}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginTop: "1.5rem" }}
            disabled={false}
            onClick={() => {
              setChangeOrderEdit(true);
              //setSaveDisable(true)
            }}
          >
            Edit
          </button>
        )}
      </Col>
    </>
  );
}

export default ChangeOrder;
