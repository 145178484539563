import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "services";

export function SearchBox({
  first_name = "",
  last_name = "",
  address = "",
  company_name = "",
}) {
  const [resultByFirstName, setResultByFirstName] = useState([]);
  const [resultByLastName, setResultByLastName] = useState([]);
  const [resultByAddress, setResultByAddress] = useState([]);
  const [resultByCompanyName, setResultByCompanyName] = useState([]);

  const [results, setResults] = useState([]);
  const [reload, setReload] = useState(0);

  const [finishWaitFirstName, setFinishWaitFirstName] = useState(false);
  const firstNameTimer = useRef(null);

  const [finishWaitLastName, setFinishWaitLastName] = useState(false);
  const lastNameTimer = useRef(null);

  const [finishWaitAddress, setFinishWaitAddress] = useState(false);
  const addressTimer = useRef(null);

  const [finishWaitCompanyName, setFinishWaitCompanyName] = useState(false);
  const companyNameTimer = useRef(null);

  const debounceFunction = (setFinishWait, time, timerRef) => {
    clearTimeout(timerRef.current);
    setFinishWait(false);
    timerRef.current = setTimeout(() => {
      setFinishWait(true);
    }, time);
  };

  useEffect(() => {
    debounceFunction(setFinishWaitFirstName, 1000, firstNameTimer);
  }, [first_name]);

  useEffect(() => {
    debounceFunction(setFinishWaitLastName, 1000, lastNameTimer);
  }, [last_name]);

  useEffect(() => {
    debounceFunction(setFinishWaitAddress, 1000, addressTimer);
  }, [address]);

  useEffect(() => {
    debounceFunction(setFinishWaitCompanyName, 1000, companyNameTimer);
  }, [company_name]);

  useEffect(() => {
    if (!finishWaitFirstName) return;
    if (first_name === "") {
      setResultByFirstName((prev) => []);
      return;
    }
    axiosInstance
      .post("/contactManagement/search", {
        search_string: first_name,
        search_field: "primaryFirstName",
      })
      .then((res) => {
        const result = res.data.result;
        const temp = result.map((value) => {
          const primary_data = value.primary_data[0];
          primary_data.property_count = value.property_list.length;
          return primary_data;
        });
        setResultByFirstName((prev) => [...temp]);
      });
  }, [finishWaitFirstName]);

  useEffect(() => {
    if (!finishWaitLastName) return;
    if (last_name === "") {
      setResultByLastName([]);
      return;
    }
    axiosInstance
      .post("/contactManagement/search", {
        search_string: last_name,
        search_field: "primaryLastName",
      })
      .then((res) => {
        const result = res.data.result;
        const temp = result.map((value) => {
          let primary_data = value.primary_data[0];
          primary_data.property_count = value.property_list.length;
          return primary_data;
        });
        setResultByLastName((prev) => [...temp]);
      });
  }, [finishWaitLastName]);

  useEffect(() => {
    if (!finishWaitAddress) return;
    if (address === "") {
      setResultByAddress([]);
      return;
    }
    axiosInstance
      .post("/contactManagement/search", {
        search_string: address,
        search_field: "primaryAddress1",
      })
      .then((res) => {
        const result = res.data.result;
        const temp = result.map((value) => {
          let primary_data = value.primary_data[0];
          primary_data.property_count = value.property_list.length;
          return primary_data;
        });
        setResultByAddress((prev) => [...temp]);
      });
  }, [finishWaitAddress]);

  useEffect(() => {
    if (!finishWaitCompanyName) return;
    if (company_name === "") {
      setResultByCompanyName([]);
      return;
    }
    axiosInstance
      .post("/contactManagement/search", {
        search_string: company_name,
        search_field: "companyName",
      })
      .then((res) => {
        const result = res.data.result;
        const temp = result.map((value) => {
          let primary_data = value.primary_data[0];
          primary_data.property_count = value.property_list.length;
          return primary_data;
        });
        setResultByCompanyName((prev) => [...temp]);
      });
  }, [finishWaitCompanyName]);

  useEffect(() => {
    //filter out the same lead address
    let listOfId = [];
    const tmp_lastNameRes = resultByLastName.filter((value) => {
      if (listOfId.includes(value._id)) return false;
      listOfId.push(value._id);
      return true;
    });
    const tmp_firstNameRes = resultByFirstName.filter((value) => {
      if (listOfId.includes(value._id)) return false;
      listOfId.push(value._id);
      return true;
    });
    const tmp_addressRes = resultByAddress.filter((value) => {
      if (listOfId.includes(value._id)) return false;
      listOfId.push(value._id);
      return true;
    });

    const tmp_companyNameRes = resultByCompanyName.filter((value) => {
      if (listOfId.includes(value._id)) return false;
      listOfId.push(value._id);
      return true;
    });

    setResults((prev) => [
      ...tmp_lastNameRes,
      ...tmp_firstNameRes,
      ...tmp_addressRes,
      ...tmp_companyNameRes,
    ]);
  }, [
    resultByFirstName,
    resultByLastName,
    resultByAddress,
    resultByCompanyName,
    reload,
  ]);

  useEffect(() => {
    if (
      first_name === "" &&
      last_name === "" &&
      address === "" &&
      company_name === ""
    ) {
      setResults([]);
    }
    if (first_name === "") {
      setResultByFirstName([]);
    }
    if (last_name === "") {
      setResultByLastName([]);
    }
    if (address === "") {
      setResultByAddress([]);
    }
    if (company_name === "") {
      setResultByCompanyName([]);
    }
  }, [first_name, last_name, address, company_name, reload]);

  return (
    <div>
      {results.length > 0 && (
        <div>
          <strong style={{ marginBottom: "1rem", marginRight: "1rem" }}>
            Available in Contact Management
          </strong>
          <span
            onClick={() => {
              setReload((prev) => prev + 1);
            }}
          >
            Reload
          </span>
        </div>
      )}
      <div style={{ display: "flex", gap: "1rem", overflowX: "scroll" }}>
        {results.map((data, i) => {
          return <Box data={data} key={i} />;
        })}
      </div>
    </div>
  );
}

const Box = ({ data }) => {

  const displayAddress =
    `${data.primaryAddress1}, ${data.primaryAddress2}`.slice(0, 100) +
    "..." +
    `${data.primaryAddress2?.length > 0 ? "," : ""}`;
  return (
    <Link
      to={{
        pathname: "/dashboard/contactmanagement",
        state: { leadAddressId: data._id },
      }}
      style={{
        width: "20%",
        minWidth: "20%",
        height: "150px",
        borderRadius: "10px",
        border: "solid 1px black",
        padding: "1rem",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ margin: 0 }}>{data.companyName}</p>
        <strong
          style={{ margin: 0, marginBottom: "0.3rem" }}
        >{`${data.primaryFirstName} ${data.primaryLastName} `}</strong>
        <p
          style={{ margin: 0, fontSize: "0.85rem" }}
        >{`${displayAddress} ${data.primaryCity}, ${data.primaryState}, ${data.primaryZip}`}</p>

        <div style={{ flex: 1 }} />
        <p
          style={{ margin: 0 }}
        >{`Property Count - ${data?.property_count}`}</p>
      </div>
    </Link>
  );
};
