import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { PriceInput } from "components";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styles from "lib/config/baseStyles";
import { debounce } from "lodash";

import { uuid } from "uuidv4";

import {
  getPricingCarting,
  resetPricingCarting,
} from "lib/redux/suppliers/suppliers.action";
import {
  editQuoteDebrisSectionData,
  saveEditedQuoteDebrisSectionData,
  updateTotalQuoteDebrisSectionData,
} from "lib/redux/quote-proposals/quotes-proposals.actions";
import displayMsg from "components/displayMsg";
import SelectSuppliers, {
  SupplierItemOnlyName,
} from "../components/SelectSupliers";

import LoadingSkeleton from "../components/LoadingSkeleton";
import DebrisListData from "./DebrisListData";
import { supplierEmptyText } from "../utils";

export default function DebrisRemoval({
  metricId,
  selectedSupplier,
  setSelectedSupplier,
  suppliers,
  isLockQuoteData,
}) {
  const [stateDebrisData, setStateDebrisData] = useState([]);
  const [stateCartMaterialData, setStateCartMaterialData] = useState([]);
  const dispatch = useDispatch();
  const didMount = useRef(false);

  const {
    gettingDebrisSectionData,
    quoteDebrisSectionData,
    quoteDebrisSectionDataErr,
  } = useSelector((state) => state.quoteDebrisSectionData);

  const { gettingCartMaterial, cartMaterialData, cartMaterialErr } =
    useSelector((state) => state.cartingPricing);

  useEffect(() => {
    setStateDebrisData(quoteDebrisSectionData);
  }, [
    quoteDebrisSectionData,
    gettingDebrisSectionData,
    quoteDebrisSectionDataErr,
  ]);

  useEffect(() => {
    setStateCartMaterialData(cartMaterialData);
  }, [gettingCartMaterial, cartMaterialData, cartMaterialErr]);

  useEffect(() => {
    if (cartMaterialErr) {
      toast.error(cartMaterialErr);
    }
  }, [cartMaterialErr]);

  const handleChange = (e, cb) => cb(e.target.value);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    } else {
      dispatch(resetPricingCarting());

      if (selectedSupplier) {
        dispatch(
          getPricingCarting(
            {
              category: "Carting",
            },
            selectedSupplier
          )
        );
      }
    }
  }, [selectedSupplier]);

  const saveSectionDataToDB = (data) => {
    dispatch(saveEditedQuoteDebrisSectionData(data))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          setError(false);
          updateTotals(res.result);
        }
      })
      .catch((err) => setError(true));
  };

  const debounceSaveData = useRef(
    debounce((dataArr) => saveSectionDataToDB(dataArr), 1000)
  );

  const addAditional = () => {
    const initialAmount = Number(0).toFixed(2);
    const newData = {
      _id: uuid(),
      pricingId: "",
      pricingType: "",
      size: "",
      qty: "",
      uomPrice: initialAmount,
      total: initialAmount,
    };

    addItem(newData);
  };

  const [error, setError] = useState(false);
  const getSubmissionData = (sectionData) => ({
    dataId: quoteDebrisSectionData.dataId,
    sectionData,
  });
  const getTotalData = () => ({
    debrisRemovalTotal: quoteDebrisSectionData.debrisRemovalTotal,
  });

  const updateTotals = (updatedData) => {
    dispatch(updateTotalQuoteDebrisSectionData(updatedData));
  };

  const saveData = (dataArr) => {
    const data = getSubmissionData(dataArr);
    const dataWithTotal = {
      ...getTotalData(),
      ...data,
    };
    dispatch(editQuoteDebrisSectionData(dataWithTotal));
    debounceSaveData.current(data);
  };

  const addItem = (item) => {
    const dataArr = quoteDebrisSectionData.sectionData.slice();
    dataArr.push(item);
    saveData(dataArr);
  };

  const editItem = (idx, dataItem) => {
    const dataArr = quoteDebrisSectionData.sectionData.slice();
    dataArr.splice(idx, 1, dataItem);
    saveData(dataArr);
  };

  const deleteItem = (idx) => {
    const dataArr = quoteDebrisSectionData.sectionData.slice();
    dataArr.splice(idx, 1);
    saveData(dataArr);
  };

  const retry = () => {
    saveSectionDataToDB(quoteDebrisSectionData);
  };

  const onChangeSupplier = (e) => handleChange(e, setSelectedSupplier);

  return (
    <div style={{ margin: "1em" }}>
      <section>
        <Row>
          <Col style={styles.reduceWidthCol}>
            <h4 style={{ marginBottom: "-.5rem" }}>Debris Removal</h4>
          </Col>
        </Row>
        <Row style={{ marginBottom: "2rem" }}>
          <SelectSuppliers
            selectedSupplier={selectedSupplier}
            onSelectSupplier={onChangeSupplier}
            loading={false}
            suppliers={suppliers}
            emptyText={supplierEmptyText(suppliers.length)}
            SupplierItem={SupplierItemOnlyName}
            isLockQuoteData={isLockQuoteData}
          />
        </Row>

        <DebrisSectionDataList
          cartMaterialData={stateCartMaterialData}
          loadingData={gettingDebrisSectionData || gettingCartMaterial}
          sectionData={
            stateDebrisData ? stateDebrisData.sectionData : stateDebrisData
          }
          dataError={quoteDebrisSectionDataErr}
          deleteItem={deleteItem}
          editItem={editItem}
          isLockQuoteData={isLockQuoteData}
        />

        <Row>
          <Col style={styles.reduceWidthCol} lg="2">
            <Button
              onClick={() => addAditional()}
              disabled={isLockQuoteData?.isLocked}
            >
              <strong>Add</strong>
            </Button>
          </Col>
        </Row>
        {stateDebrisData && (
          <TotalSection total={stateDebrisData.debrisRemovalTotal} />
        )}
      </section>
    </div>
  );
}

function TotalSection({ total }) {
  return (
    <Row>
      <Col style={styles.reduceWidthCol} lg="6" />
      <Col style={{ ...styles.reduceWidthCol, ...{ flex: "77%" } }}>
        <Row>
          <Col
            style={{
              ...styles.reduceWidthCol,
              ...{ textAlign: "right", paddingRight: "none" },
            }}
          >
            <Form.Label>Debris Removal Total:</Form.Label>
          </Col>
        </Row>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput
          price={total}
          disabled
          style={{ background: "#28a745", color: "#fff" }}
        />
      </Col>
    </Row>
  );
}

function DebrisSectionDataList({
  loadingData,
  sectionData,
  dataError,
  deleteItem,
  editItem,
  cartMaterialData,
  isLockQuoteData,
}) {
  if (dataError) {
    console.error(dataError);
    return displayMsg({ msg: "No data available." });
  }
  if (
    sectionData &&
    sectionData.length > 0 &&
    cartMaterialData &&
    cartMaterialData.cartingPricing
  ) {
    return sectionData.map((sectionPricing, idx) => (
      <DebrisListData
        listData={cartMaterialData.cartingPricing}
        key={sectionPricing._id}
        data={sectionPricing}
        deleteItem={() => deleteItem(idx)}
        editItem={(dataItem) => editItem(idx, dataItem)}
        isLockQuoteData={isLockQuoteData}
        sectionData={sectionData}
      />
    ));
  }

  if (sectionData && sectionData.length === 0) {
    return displayMsg({ msg: "No data available." });
  }

  if (loadingData) {
    return new Array(10).fill(1).map((_, i) => <LoadingSkeleton key={i} />);
  }

  return null;
}
