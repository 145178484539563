import React from 'react'

import InputWithSymbol from 'components/InputWithSymbol'


export default function PercentageInput({
	disabled,
	style,
	percent = 0,
	symbol = '%',
	onChange,
	...rest
}) {
	const styles = style
		? { textAlign: 'right', ...style }
		: { textAlign: 'right' }

	const changePrice = (e) => {
		const changedValue = removeSymbol(e.target.value)
		onChange(changedValue)
	}

	const removeSymbol = (value) => value.replace(symbol, '').trim()

	const onBlur = (e) => {
		const changedValue = removeSymbol(e.target.value)
		const newValue = isNaN(changedValue) || changedValue == '' ? Number(0) : Number(changedValue)
		onChange(newValue)
	}

	const onKeyDown = e => {
		if (e.key == "Backspace") {
			const changedValue = removeSymbol(e.target.value)
			const newValue = changedValue.substring(0, changedValue.length - 1);
			onChange(newValue)
		}
	}

	const onKeyUp = e => {
		if (e.key !== "Backspace") {
			const changedValue = removeSymbol(e.target.value)
			const newValue = changedValue.replace(/[^.0-9]/g, '')
			onChange(newValue)
		}
	}

	const displayValue = percent ? percent : percent !== '' ? Number(0) : ''

	return (
		<InputWithSymbol
			onChange={changePrice}
			disabled={disabled}
			value={displayValue + symbol}
			styles={styles}
			onKeyDown={onKeyDown}
			changeValue={changePrice}
			onKeyUp={onKeyUp}
			{...rest}
		/>
	)
}
