import { createSelector } from "reselect";

const selectCalendarData = (state) => state?.calendarInputs?.data;
const selectCalendarEvents = (state) => state?.calendarEvents?.events;

export const CalendarRequests = createSelector([selectCalendarData], (data) =>
  data ? data.requests : null
);

export const CalendarEvents = createSelector([selectCalendarEvents], (events) =>
  events ? events : null
);

export const CalendarRequestsSales = createSelector(
  [CalendarRequests],
  (data) => (data ? data?.filter((request) => !request?.isRepair) : null)
);

export const CalendarRequestsRepair = createSelector(
  [CalendarRequests],
  (data) => (data ? data.filter((request) => request?.isRepair) : null)
);

export const CalendarPersons = createSelector([selectCalendarData], (data) =>
  data ? data?.persons : []
);

export const SalesPersons = createSelector([CalendarPersons], (persons) =>
  persons?.length > 0 ? persons?.filter((person) => person?.isSales) : []
);

export const RepairPersons = createSelector([CalendarPersons], (persons) =>
  persons?.length > 0 ? persons?.filter((person) => person?.isRepair) : []
);

export const salesEvents = createSelector([CalendarEvents], (data) =>
  data ? data?.filter(({ requestObj: { isRepair } }) => !isRepair) : []
);

export const repairEvents = createSelector([CalendarEvents], (data) =>
  data ? data?.filter((event) => event?.requestObj?.isRepair) : []
);
