import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import Components, {
  PriceInput,
  ModalTemplate,
} from "components";
import { useDispatch, useSelector } from "react-redux";
import useContactData from "hooks/useEmailContract";
import styles from "lib/config/baseStyles";
import { ModalFooter } from "components/popup-modal/popup-modal";
import {
  createProposal,
  deleteQuote,
  changeActiveProposal,
  displayProposalTab,
  getQuoteProposalData,
  changeProposalModalIndex,
} from "lib/redux/quote-proposals/quotes-proposals.actions";
import { toast } from "react-toastify";
import DebrisRemoval from "./DebrisRemoval";
import BuildingListModal from "./components/BuildingListModal";
import SectionListModal from "./components/SectionListModal";
import staticTexts from "./components/staticTexts";
import Material from "./Material";
import RoofingLabor from "./Labor";
import NewQuoteModal from "./components/NewQuoteModal";
import { QuoteNamePopup } from "./components/QuoteNameInput";
import Gutters from "./Gutters";
import PercentageModal from "./ProposalTab/PercentageModal";
import moment from "moment";
import useLockQuote from "hooks/useLockQuote";
import { getPricingCarting } from "lib/redux/suppliers/suppliers.action";

export default function Estimator({
  metricId,
  gettingQuoteMSuppliers,
  setSelectedDebrisSupplier,
  setSelectedGuttersSupplier,
  setSelectedLaborSupplier,
  setSelectedMaterialSupplier,
  setSelectedBuilding,
  showBuilding,
  markUp,
  markUpAmt,
  totalWithMarkUp,
  totalWithoutMarkUp,
  dataList,
  selectedMaterialSupplier,
  mateSuppliers,
  setExcludedSections,
  excludedSections,
  setExcludedBuildings,
  selectedBuilding,
  excludedBuildings,
  metrics,
  changeQuoteName,
  quoteName,
  showGutter,
  showMaterial,
  selectedGuttersSupplier,
  subsCrewsSuppliers,
  selectedDebrisSupplier,
  cartSuppliers,
  selectedLaborSupplier,
  onChangeMarkup,
  quoteProposalLength,
  resetExcludedData,
  updateAutoTextData,
  setCreateProposal,
  setSelectedBuildingName,
  selectedBuildingName,
  quoteMaterialSectionData,
  changeProposalPercentage,
  changeProposalDisPlayText,
  isLockQuoteData,
  minMarkUp,
  maxMarkUp,
  changeTotalValue,
  type,
  quoteDebrisSectionData,
  changeProposalStatus,
  key,
  setKey,
}) {
  const dispatch = useDispatch();

  const MAIN_TABS = [
    {
      name: "Estimator",
      id: "001",
    },
    {
      name: `${type ? `Proposal/Contract` : `Proposal`}`,
      id: "002",
    },
    {
      name: "Job Notes",
      id: "003",
    },
  ];

  const TABS_HEAD = ["Material", "Labor", "Carting", "Gutters", "Total"];

  const hideTabMappig = {
    Material: !showMaterial,
    Labor: !showMaterial,
    Gutters: !showGutter,
  };

  const [displayInp, setDisplayInp] = useState(false);
  const [showNewQuote, setShowNewQuote] = useState(false);
  // const [key, setKey] = useState(MAIN_TABS[0].id);
  const [selectedTab, setSelectedTab] = useState("");
  const [confirmNQModal, setConfirmNQModal] = useState(false);
  const [QPDeleteModal, setQPDeleteModal] = useState(false);
  const [QLockedModal, setQLockedModal] = useState(false);
  const [buildingListModal, setBuildingModal] = useState(false);
  const [sectionModal, setSectionModal] = useState(false);
  const [finalLoader, setFinalLoader] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [name, setName] = useState("");
  const [isAllSectionRoof, setIsAllSectionRoof] = useState(false);
  const [isAllSectionGutter, setIsAllSectionGutter] = useState(false);
  const [modalShow, setModalShow] = useState("proposal");
  const history = useHistory();
  const { proposalId, proposalIdx } = useSelector(
    (state) => state.activeProposal
  );
  const { quoteProposalData } = useSelector((state) => state.quoteProposal);

  const { setFirstTime } = useContactData();

  const { quotelockData, gettingLockquoteData, lockeQuoteData } =
    useLockQuote();
  useEffect(() => {
    if (quotelockData) {
      dispatch(getQuoteProposalData(metricId));
      setQLockedModal(false);
    }
  }, [quotelockData]);

  useLayoutEffect(() => {
    if (showMaterial) {
      setSelectedTab(TABS_HEAD[0]);
    } else {
      setSelectedTab(TABS_HEAD[3]);
    }
  }, [showMaterial, showGutter]);
  useEffect(() => {
    console.log("selectedDebrisSupplier ", selectedDebrisSupplier);
    if (selectedDebrisSupplier) {
      dispatch(
        getPricingCarting(
          {
            category: "Carting",
          },
          selectedDebrisSupplier
        )
      );
    }

  }, []);
  const changeTab = (tab) => (selectedTab !== tab ? setSelectedTab(tab) : {});

  const hideNQModal = (setShowModal) => {
    setShowNewQuote(false);
    setShowModal(true);
  };

  const displayBuildingsOrSections = () => {
    if (showBuilding) {
      hideNQModal(setBuildingModal);
    } else {
      hideNQModal(setSectionModal);
    }
  };

  const toggleBuildingAndSectionModal = (secModal, buildingModal) => {
    setBuildingModal(buildingModal);
    setSectionModal(secModal);
  };

  const showFinalLoader = () => {
    setIsAllSectionRoof(false);
    setIsAllSectionGutter(false);
    setSectionModal(false);
    setFinalLoader(true);
  };

  const hideFinalLoader = () => {
    setFinalLoader(false);
  };

  const onSuccess = (createdProposalId) => {
    toast.success("Successfully added alternate quote.");
    resetExcludedData();
    dispatch(changeActiveProposal(createdProposalId, quoteProposalData.length));
  };

  const onFailure = (err) => {
    toast.error(err);
  };

  const onSaveNewQuote = () => {
    setCreateProposal(true);
    const newProposal = {
      quoteName: name,
      excludedBuildings,
      excludedSections,
    };
    showFinalLoader();
    dispatch(
      createProposal(
        metricId,
        newProposal,
        onSuccess,
        onFailure,
        hideFinalLoader
      )
    );
  };

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const onConfirmNQModal = () => {
    setShowNewQuote(false);
    setConfirmNQModal(true);
  };

  const onSuccessDelete = (msg) => {
    setQPDeleteModal(false);
    toast.success(msg);
    if (quoteProposalLength === 1) {
      history.replace(getMetricLink());
    }
  };

  const hideQPDeleteModal = () => {
    setQPDeleteModal(false);
  };

  const onFinally = () => {
    setDeleting(false);
  };

  const deleteQuoteData = () => {
    setDeleting(true);
    dispatch(
      deleteQuote(
        proposalId,
        proposalIdx,
        onSuccessDelete,
        onFailure,
        onFinally
      )
    );
  };
  const LockeQuoteData = async () => {
    // dispatch(
    await lockeQuoteData(selectedMaterialSupplier, metricId, proposalId, {
      isLocked: true,
      isLockedTime: new Date(),
    });
    // );
  };

  const switchTab = () => {
    switch (selectedTab) {
      case TABS_HEAD[0]:
        return (
          <Material
            loadingSuppliers={gettingQuoteMSuppliers}
            setSelectedSupplier={setSelectedMaterialSupplier}
            selectedSupplier={selectedMaterialSupplier}
            metricId={metricId}
            mateSuppliers={mateSuppliers}
            dataList={dataList}
            isLockQuoteData={isLockQuoteData}
          />
        );

      case TABS_HEAD[1]:
        return (
          <RoofingLabor
            suppliers={subsCrewsSuppliers}
            metricId={metricId}
            selectedSupplier={selectedLaborSupplier}
            setSelectedSupplier={setSelectedLaborSupplier}
            isLockQuoteData={isLockQuoteData}
          />
        );
      case TABS_HEAD[2]:
        return (
          <DebrisRemoval
            suppliers={cartSuppliers}
            metricId={metricId}
            selectedSupplier={selectedDebrisSupplier}
            setSelectedSupplier={setSelectedDebrisSupplier}
            isLockQuoteData={isLockQuoteData}
            quoteDebrisSectionData={quoteDebrisSectionData}
          />
        );
      case TABS_HEAD[3]:
        return (
          <Gutters
            metricId={metricId}
            suppliers={subsCrewsSuppliers}
            selectedSupplier={selectedGuttersSupplier}
            setSelectedSupplier={setSelectedGuttersSupplier}
            isLockQuoteData={isLockQuoteData}
          />
        );
      case TABS_HEAD[4]:
        return (
          <AllTotalSection
            totalWithoutMarkUp={totalWithoutMarkUp}
            markUp={markUp}
            markUpAmt={markUpAmt}
            onChangeMarkup={onChangeMarkup}
            totalWithMarkUp={totalWithMarkUp}
            isLockQuoteData={isLockQuoteData}
            maxMarkUp={maxMarkUp}
            minMarkUp={minMarkUp}
          />
        );

      default:
        return (
          <div style={{ paddingTop: "50px" }}>
            <div className="__msg">
              Please selecte one of the above tab to continue.
            </div>
          </div>
        );
    }
  };

  const changeTabKey = (key) => setKey(key);

  const changeTabProposal = () => {
    dispatch(displayProposalTab(true));

    /**
     * Chnage Proposal Modal Added in OnClick Of Write proposal
     */
    dispatch(changeProposalModalIndex(0));
    setModalShow("Write Proposal");
    setFirstTime(true, proposalId);
    changeTabKey(MAIN_TABS[1].id);
  };

  const LinkBtn = ({ to }) => (
    <Link rel="noreferrer" to={to}>
      <Button variant="outline-secondary">
        <strong>Open Metric</strong>
      </Button>
    </Link>
  );

  const showAlternateQuoteModal = () => {
    setShowNewQuote(true);
    setName("");
  };

  const getMetricLink = () => {
    const hasRoof =
      metrics && metrics.roof && metrics.roof.metricData.length > 0;
    const metricLink = `/dashboard/metrics/${hasRoof ? "roof" : "gutters"
      }/${metricId}`;
    return metricLink;
  };

  const SupplierInfo = () => (
    <div className="_cont_tab __cont_quote">
      <div className="_quote_cont">
        <Row
          style={{
            justifyContent: "space-between",
          }}
          className="__quote_estimator_head"
        >
          <Col style={styles.reduceWidthCol}>
            <div className="__quote_top_btns">
              <Button
                disabled={isLockQuoteData?.isLocked}
                variant="danger"
                onClick={() => setQLockedModal(true)}
              >
                <strong>
                  {isLockQuoteData?.isLocked ? "Locked" : "Lock Quote"}
                </strong>
              </Button>
              <Button
                variant="danger"
                onClick={() => setQPDeleteModal(true)}
                disabled={isLockQuoteData?.isLocked}
              >
                <strong>Delete Quote</strong>
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setDisplayInp(true);
                }}
              >
                <strong>Edit Name</strong>
              </Button>
              {metrics && !type && <LinkBtn to={getMetricLink()} />}
              {!type && (
                <Button
                  variant="outline-secondary"
                  onClick={showAlternateQuoteModal}
                >
                  <strong>Alternate Quote</strong>
                </Button>
              )}
              <Button onClick={changeTabProposal}>
                <strong>Write Proposal</strong>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      &nbsp;&nbsp;
      {isLockQuoteData?.isLocked && (
        <span style={{ fontWeight: "bold", fontSize: "larger" }}>
          {moment(isLockQuoteData?.isLockedTime).format("lll")}
        </span>
      )}
      <div className="__quote_new_tab">
        <ul>
          {TABS_HEAD.map((tab) => {
            if (hideTabMappig[tab]) return null;

            return (
              <li
                key={tab}
                role="button"
                onClick={() => changeTab(tab)}
                className={selectedTab === tab ? "active" : ""}
              >
                {tab}
              </li>
            );
          })}
        </ul>
      </div>
      {switchTab()}
    </div>
  );

  useEffect(() => {
    if (key === MAIN_TABS[0].id) {
      setModalShow("Write Proposal");
    }
  }, [key]);

  const onHideModal = () => {
    setShowNewQuote(false);
  };

  // if (redirect) {
  //   return <Redirect to={getMetricLink()} />;
  // }
  const onHide = () => {
    setSectionModal(false);
    setIsAllSectionRoof(false);
    setIsAllSectionGutter(false);
  };
  return (
    <div className="__quote_estimator">
      {SupplierInfo()}
      <NewQuoteModal
        show={showNewQuote}
        onHide={onHideModal}
        name={name}
        handleChange={(e) => handleChange(e, setName)}
        confirm={displayBuildingsOrSections}
        disableSave={!name.trim()}
      />

      {displayInp && (
        <QuoteNamePopup
          currentName={quoteName}
          setDisplayInp={setDisplayInp}
          displayInp={displayInp}
          changeQuoteName={changeQuoteName}
        />
      )}
      <ModalTemplate
        show={confirmNQModal}
        confirm={() => { }}
        onHide={() => setConfirmNQModal(false)}
        text={staticTexts.newQuotehead}
      >
        <ModalFooter
          onCancel={() => setConfirmNQModal(false)}
          onSubmit={displayBuildingsOrSections}
          submitText="Yes"
          cancelText="No"
        />
      </ModalTemplate>

      <ModalTemplate
        show={QPDeleteModal}
        confirm={() => { }}
        onHide={() => setQPDeleteModal(false)}
        heading="Quote & Proposal Delete Confirmation"
        textStyle={{ color: "red" }}
        text={`Deleting '${quoteName}' is permanent. Continue with delete?`}
      >
        <p style={{ padding: "1em 0", fontSize: "14px", fontWeight: "bold" }}>
          Note: If deleting the final Quote & proposal from the list, you will
          be redirected to the metrics screen. Click 'Generate Quote' to start
          over.
        </p>

        <Modal.Footer
          style={{
            justifyContent: "flex-end",
            border: "none",
          }}
        >
          <Button
            disabled={deleting}
            style={{ width: "130px", height: "50px" }}
            variant="danger modified-btn"
            onClick={deleteQuoteData}
          >
            {deleting ? "Deleting..." : "Yes,Delete"}
          </Button>
          <Button
            onClick={hideQPDeleteModal}
            style={{ width: "130px", height: "50px" }}
          >
            No
          </Button>
        </Modal.Footer>
      </ModalTemplate>
      <ModalTemplate
        show={QLockedModal}
        confirm={() => { }}
        onHide={() => setQLockedModal(false)}
        heading="Quote Lock Confirmation"
        textStyle={{ color: "black" }}
        text={`Locking protects this quote from accidental changes and can not be unlocked. Any change requires a new quote to be generated.`}
      >
        <h5
          style={{
            padding: "0.5em 0",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Continue Locking Quote?
        </h5>

        <Modal.Footer
          style={{
            justifyContent: "flex-end",
            border: "none",
          }}
        >
          <Button
            disabled={gettingLockquoteData}
            style={{
              width: "130px",
              height: "50px",
              fontSize: "larger",
              fontWeight: "700",
            }}
            variant="danger modified-btn"
            onClick={LockeQuoteData}
          >
            {gettingLockquoteData ? "Locking..." : "Yes"}
          </Button>
          <Button
            onClick={() => setQLockedModal(false)}
            style={{
              width: "130px",
              height: "50px",
              fontSize: "larger",
              fontWeight: "700",
            }}
            disabled={gettingLockquoteData}
          >
            No
          </Button>
        </Modal.Footer>
      </ModalTemplate>

      <BuildingListModal
        metrics={metrics}
        show={buildingListModal}
        onHide={() => setBuildingModal(false)}
        toggleModals={toggleBuildingAndSectionModal}
        setSelectedBuilding={setSelectedBuilding}
        setSelectedBuildingName={setSelectedBuildingName}
      />
      <SectionListModal
        showBuilding={showBuilding}
        metrics={metrics}
        show={sectionModal}
        onHide={onHide}
        toggleModals={toggleBuildingAndSectionModal}
        selectedBuilding={selectedBuilding}
        selectedBuildingName={selectedBuildingName}
        setSelectedBuilding={setSelectedBuilding}
        setSelectedBuildingName={setSelectedBuildingName}
        excludedBuildings={excludedBuildings}
        setExcludedBuildings={setExcludedBuildings}
        excludedSections={excludedSections}
        setExcludedSections={setExcludedSections}
        onSaveNewQuote={onSaveNewQuote}
        isAllSectionRoof={isAllSectionRoof}
        setIsAllSectionRoof={setIsAllSectionRoof}
        isAllSectionGutter={isAllSectionGutter}
        setIsAllSectionGutter={setIsAllSectionGutter}
      />
      <Components.LoaderModal show={finalLoader} name={name} />
    </div>
  );
}

const AllTotalSection = ({
  totalWithoutMarkUp,
  markUp,
  markUpAmt,
  onChangeMarkup,
  totalWithMarkUp,
  isLockQuoteData,
  maxMarkUp,
  minMarkUp,
}) => {
  const {
    gettingMaterialSectionData,
    quoteMaterialSectionData,
    quoteMaterialSectionDataErr,
  } = useSelector((state) => state.quoteMaterialSectionData);

  const { quoteProposalData } = useSelector((state) => state.quoteProposal);
  const { proposalId, proposalIdx } = useSelector(
    (state) => state.activeProposal
  );

  const [per, setPer] = useState(markUp);
  const [percent, setPercent] = useState(per);
  const [stepperModal, setStepperModal] = useState(false);
  const [currentProposal, setCurrentProposal] = useState(null);

  useEffect(() => {
    setCurrentProposal({ ...quoteProposalData[proposalIdx] });
  }, [proposalId, proposalIdx, quoteProposalData]);

  const changePer = (value) => setPer(value);

  const changePerData = (increase = true) => {
    let newPer = percent;
    if (increase) {
      newPer += 1;
    } else {
      newPer -= 1;
    }
    setPercent(newPer < 0 ? 0 : newPer);
  };
  const showPercentageStepperModal = () => {
    setStepperModal(true);
  };

  const hidePercentageStepperModal = () => {
    setStepperModal(false);
  };
  const applyChanges = () => {
    changePer(percent);
    onChangeMarkup(percent);
    hidePercentageStepperModal();
  };

  const cancelChanges = () => {
    setPercent(per);
    hidePercentageStepperModal();
  };

  const badStateTax =
    (!currentProposal?.stateTax && currentProposal?.stateTax !== 0) ||
    currentProposal?.stateTax === "";
  return (
    <div style={{ padding: "1em" }}>
      <section>
        <Row>
          {badStateTax && (
            <p style={{ textAlign: "right" }}>
              Please choose tax rate for material to enable total price
              calculations
            </p>
          )}
          <Col style={styles.reduceWidthCol} lg="6" />
          <Col style={styles.reduceWidthCol} lg="6">
            <Row
              style={{
                justifyContent: "flex-end",
              }}
            >
              <Col
                style={{
                  paddingTop: "1.5em",
                  ...styles.reduceWidthCol,
                }}
                lg="9"
              />
            </Row>
            <Row style={{ marginBottom: "1%" }}>
              <Col
                style={{ ...styles.reduceWidthCol, textAlign: "right" }}
                lg="9"
              >
                <Form.Label className="mt-1">
                  Total Material & Labor:
                </Form.Label>
              </Col>
              <Col style={styles.reduceWidthCol} lg="3">
                <PriceInput
                  input="Total"
                  price={badStateTax ? 0 : totalWithoutMarkUp}
                  disabled
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "1%" }}>
              <Col
                style={{ ...styles.reduceWidthCol, textAlign: "right" }}
                lg="6"
              >
                <Form.Label className="mt-1">Mark-Up:</Form.Label>
              </Col>
              <Col style={styles.reduceWidthCol} lg="3">
                <button
                  onClick={showPercentageStepperModal}
                  style={{
                    textAlign: "center",
                    background: "yellow",
                    color: "#111",
                  }}
                  className="form-control"
                  disabled={isLockQuoteData?.isLocked}
                >
                  {markUp}%
                </button>
                <PercentageModal
                  onHide={hidePercentageStepperModal}
                  show={stepperModal}
                  percent={percent}
                  onCancel={cancelChanges}
                  onSubmit={applyChanges}
                  changePerData={changePerData}
                  submitText="Apply"
                  heading="Mark-Up Percentage"
                  maxMarkUp={maxMarkUp}
                  minMarkUp={minMarkUp}
                />
              </Col>
              <Col style={styles.reduceWidthCol} lg="3">
                <PriceInput
                  input="Total"
                  price={badStateTax ? 0 : markUpAmt}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col
                style={{ ...styles.reduceWidthCol, textAlign: "right" }}
                lg="9"
              >
                <Form.Label className="mt-1">Total Sale Price:</Form.Label>
              </Col>
              <Col style={styles.reduceWidthCol} lg="3">
                <PriceInput
                  input="Total"
                  price={badStateTax ? 0 : totalWithMarkUp}
                  style={{
                    background: "#28a745",
                    color: "#fff",
                  }}
                  disabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </div>
  );
};
