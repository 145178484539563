import React, { useRef, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import useLetterHead from "hooks/useLetterHead";
import { getEndPoints } from "api/api";
import { axiosInstance as axios } from "services";
import { toast } from "react-toastify";
import LetterheadCanvas from "./LetterheadCanvas";
import InitialLoader from "components/InitilalLoader";
import domtoimage from "dom-to-image";
import { PopupModal } from "components";
import { ImageOutline } from "react-ionicons";
import { ResizeOutline } from "react-ionicons";
import { TextOutline } from "react-ionicons";
import { TrashOutline } from "react-ionicons";
import { SaveOutline } from "react-ionicons";

const url = getEndPoints(process.env.REACT_APP_MODE);

const LetterHead = () => {
  const [show, setShow] = useState(false);
  const [textArea, setTextArea] = useState();
  const [bool, setBool] = useState(false);
  const [columns, setColumns] = useState([]);
  const [image, setImage] = useState("");
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [index, setIndex] = useState();
  const [imageForClient, setImageForClient] = useState();
  const targetRef = useRef(null);
  const [showMessage, setShowMessage] = useState(false);
  const [imageModal, setImageModal] = useState(false);

  const {
    save_letter_head_text_to_backend,
    get_letter_head,
    upload_image_to_letter_head,
    isLoading,
    isError,
    setIsLoading,
    setIsError,
  } = useLetterHead();

  const quillModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"], // Text styling options
      [{ color: [] }, { background: [] }], // Color options
      ["clean"], // Remove formatting,
      [{ font: [] }],
    ],
  };

  const quillFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
  ];

  let handleCapture = () => {
    let targetElement = targetRef.current;
    targetElement.style.border = "none";
    try {
      let options = { cacheBust: true };
      domtoimage.toPng(targetElement, options).then(async function (dataUrl) {
        await save_letter_head_text_to_backend({
          letterHeadDetails: columns,
          letterHeadImageForClientSide: dataUrl,
        });
      });
    } catch (error) {
      console.log("Error capturing image:", error);
    }
    //   html2canvas(targetElement, {
    //     allowTaint: true,
    //     logging: true,
    //     letterRendering: 1,
    //     useCORS: false,
    //   })
    //     .then(async function (canvas) {
    //       const base64Image = canvas.toDataURL("image/png");
    //       toast.success("Image Captured");
    //       await save_letter_head_text_to_backend({
    //         letterHeadDetails: columns,
    //         letterHeadImageForClientSide: base64Image,
    //       });
    //     })
    //     .then(() => {
    //       targetElement.style.border = "medium solid";
    //     });
    // } catch (error) {
    //   console.log("Error capturing image:", error);
    // }
  };

  useEffect(() => {
    let get_letter_head_func = async () => {
      let text = await get_letter_head();
      if (text.length == 0 || !text) {
        await save_letter_head_text_to_backend();
        let { letterHeadDetails } = await get_letter_head();

        setColumns(letterHeadDetails);
        setTextArea(text.letterHeadText ? text.letterHeadText : []);
      } else {
        setColumns(text.letterHeadDetails);
        setTextArea(text.letterHeadText ? text.letterHeadText : []);
      }
    };
    get_letter_head_func();
  }, [bool]);

  const togglePopup = () => {
    setShow(!show);
    setBool(false);
  };

  let handle_image = (e) => {
    setImage(e.target.files[0]);
  };

  let save_width_and_height = async (width, height) => {
    columns[index] = { ...columns[index], breadth: width, length: height };

    await save_letter_head_text_to_backend({ letterHeadDetails: columns });
  };

  let handle_upload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("uploads", image);
    setIsLoading(true);
    axios
      .post(`${url}letterHead/image`, formData)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.result);
          setBool(!bool);
        } else {
          toast.error("Something went wrong");
          setBool(!bool);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
        setImage("");
        setBool(!bool);
      });
  };

  const Modal_Image = () => {
    let { content, length, breadth } = columns[index];
    const [width, setWidth] = useState(breadth);
    const [height, setHeight] = useState(length);

    const style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "solid 1px #ddd",
    };
    return (
      <div style={{ width: "50%", height: "50vh" }}>
        <div
          style={{
            width: "200%",
            height: "80%",
            border: "solid",
            borderRadius: "2px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Resizable
            style={style}
            size={{ width, height }}
            onResizeStop={(e, direction, ref, d) => {
              setWidth(width + d.width);
              setHeight(height + d.height);
            }}
          >
            <img style={{ width: width, height: height }} src={content} />
          </Resizable>
        </div>
        <div style={{ display: "flex", padding: "4px" }}>
          <button
            className="btn btn-primary"
            style={{ marginRight: "3px" }}
            onClick={() => {
              save_width_and_height(width, height);
              setShowMessage(!showMessage);
            }}
          >
            Save
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              setShowMessage(!showMessage);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const Modal = () => {
    const [value, setValue] = useState("");

    return (
      <div style={{ height: "45vh", width: "100%", borderRadius: "1rem" }}>
        <h4>Text Area</h4>
        <div style={{ width: "100%", height: "34vh" }} id="IdeaDetails">
          <ReactQuill
            theme="snow"
            value={value}
            onChange={setValue}
            modules={quillModules}
            formats={quillFormats}
            style={{ height: "70%" }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <button
            className="btn btn-primary"
            onClick={async () => {
              setTextArea(value);
              let text = await get_letter_head();
              text.letterHeadDetails = [
                ...text.letterHeadDetails,
                { x: 0, y: 0, content: value },
              ];
              save_letter_head_text_to_backend({
                letterHeadDetails: text.letterHeadDetails,
              }).then((res) => setBool(true));
              setShow(false);
              setValue("");
            }}
            style={{ marginRight: "0.5rem" }}
          >
            save
          </button>
          <button className="btn btn-danger" onClick={togglePopup}>
            cancel
          </button>
        </div>
      </div>
    );
  };

  // let handleSave = async () => {
  //   let text = await get_letter_head();
  //   text.letterHeadDetails = [...text.letterHeadDetails, {x:0,y:0,content: textArea}]
  //   console.log({letterHeadDetails: text.letterHeadDetails})
  //   save_letter_head_text_to_backend({letterHeadDetails: text.letterHeadDetails}).then(res => setBool(true))
  // }

  if (isLoading || isError) {
    return <InitialLoader error={isError} />;
  }

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Popup open={show}>
            <Modal />
          </Popup>

          <PopupModal show={showMessage} heading={"Resize Image"}>
            <Modal_Image />
          </PopupModal>
        </div>
        <h5 style={{ marginLeft: "3rem", marginTop: "2rem" }}>
          Upload company logos and add text to customize proposal and contract
          letterhead
        </h5>

        <div
          style={{
            width: "90%",
            height: "34vh",
            border: "solid",
            marginLeft: "3rem",
            overflow: "hidden",
            position: "relative",
          }}
          ref={targetRef}
        >
          <LetterheadCanvas
            columns={columns}
            setColumns={setColumns}
            setX={setX}
            setY={setY}
            setIndex={setIndex}
            chosenIndex={index}
            setShowMessage={setShowMessage}
          />
        </div>
        <div
          style={{ display: " flex", justifyContent: "center", gap: "10px" }}
        >
          <button
            type="button"
            className="btn btn-primary"
            style={{ margin: "2%", padding: "0.7rem" }}
            onClick={() => setImageModal(true)}
          >
            <ImageOutline color={"#00000"} height="50px" width="50px" />
          </button>

          <button
            type="button"
            className="btn btn-primary"
            style={{ margin: "2%", padding: "0.7rem" }}
            disabled={index === undefined || index === null || index === ""}
            onClick={() => {
              index == 0 || index
                ? setShowMessage(true)
                : setShowMessage(false);
            }}
          >
            <ResizeOutline color={"#00000"} height="50px" width="50px" />
          </button>

          <button
            type="button"
            className="btn btn-primary"
            style={{ margin: "2%", padding: "0.7rem" }}
            onClick={togglePopup}
          >
            <TextOutline color={"#00000"} height="50px" width="50px" />
          </button>
          {/* <button
            type="button"
            className="btn btn-primary"
            style={{ margin: "2%", padding: "0.7rem" }}
            onClick={(e) => handleSave()}
          >
            Insert Text
          </button> */}

          <Button
            type="button"
            variant="success"
            style={{ margin: "2%", padding: "0.7rem" }}
            onClick={handleCapture}
          >
            <SaveOutline color={"#00000"} height="50px" width="50px" />
          </Button>

          <button
            type="button"
            className="btn btn-danger"
            style={{ margin: "2%", padding: "0.7rem" }}
            disabled={index === undefined || index === null || index === ""}
            onClick={() => {
              (index == 0 || index) &&
                save_letter_head_text_to_backend({
                  letterHeadDetails: columns.filter(
                    (ele, idx, self) => idx != index
                  ),
                }).then((res) => {
                  setBool(!bool);
                });
            }}
          >
            <TrashOutline color={"#00000"} height="50px" width="50px" />
          </button>
        </div>
      </div>

      <PopupModal
        show={imageModal}
        onHide={() => setImageModal(false)}
        heading={"Upload Image"}
      >
        <div>
          <input
            className="btn btn-primary"
            type="file"
            name="file"
            onChange={handle_image}
          />

          <Button
            type="button"
            variant="success"
            style={{ margin: "2%", padding: "0.7rem" }}
            disabled={image == ""}
            onClick={handle_upload}
          >
            Insert Image
          </Button>
        </div>
      </PopupModal>
    </>
  );
};

export default LetterHead;
