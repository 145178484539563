import { useState } from "react";
import React from "react";
import { Modal, Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const DimensionTallyUI = ({
  lwfData,
  section,
  totalFlat,
  pitch,
  hide,
  dimensionModalHeader,
  renderDimenData,
  toggleKeypad,
}) => {
  const maxItem = 5;
  const getIcon = (name) => <FontAwesomeIcon icon={name} />;
  const [pageContent, setPageContent] = useState(
    lwfData.slice(maxItem * 0, maxItem)
  );
  const [pageNum, setPageNum] = useState(0);

  const PaginationUI = () => {
    return (
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <button
          aria-label="Prev"
          className="pagination_btns"
          onClick={() => {
            setPageNum((prev) => {
              if (prev > 0) return prev - 1;
            });
            if (pageNum > 0)
              setPageContent(
                lwfData.slice((pageNum - 1) * maxItem, pageNum * maxItem)
              );
          }}
          disabled={pageNum === 0}
        >
          {getIcon(faChevronLeft)}
        </button>
        <p className="page_num">{pageNum + 1}</p>
        <button
          aria-label="Next"
          className="pagination_btns"
          disabled={(pageNum + 1) * maxItem >= lwfData.length}
          onClick={() => {
            setPageNum((prev) => {
              if ((prev + 1) * maxItem < lwfData.length) return prev + 1;
            });
            setPageContent(
              lwfData.slice((pageNum + 1) * maxItem, (pageNum + 2) * maxItem)
            );
          }}
        >
          {getIcon(faChevronRight)}
        </button>
      </div>
    );
  };
  return (
    <div className="slide-right">
      {dimensionModalHeader(lwfData.length, section, totalFlat, pitch)}
      {lwfData.length > 0 && (
        <table className="dimen_table">
          <thead>
            <tr>
              <th></th>
              <th>Length</th>
              <th style={{ flex: "unset", width: "30px" }}></th>
              <th>Width</th>
              <th style={{ flex: "unset", width: "30px" }}></th>
              <th>Factor</th>
              <th style={{ flex: "unset", width: "30px" }}></th>
              <th>SQ Ft(S)</th>
              <th>SQ Ft(F)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{renderDimenData(pageContent)}</tbody>
        </table>
      )}
      <Modal.Footer style={{ justifyContent: "space-between", border: "none" }}>
        <div>
          <Button
            className="modified_btn"
            style={{ float: "left" }}
            onClick={toggleKeypad}
          >
            Keypad
          </Button>
        </div>
        <PaginationUI />
        <div>
          <Button
            className="modified_btn"
            variant="danger"
            onClick={() => hide()}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </div>
  );
};

export default DimensionTallyUI;
