export const UserActionTypes = {
  LOGIN_START: "LOGIN_START",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  SIGN_OUT: "SIGN_OUT",
  GET_USERS_START: "GET_USERS_START",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  GET_USERSROLES_START: "GET_USERSROLES_START",
  GET_USERSROLES_SUCCESS: "GET_USERSROLES_SUCCESS",
  GET_USERSROLES_FAILURE: "GET_USERSROLES_FAILURE",
  BASE_START: "BASE_START",
  BASE_SUCCESS: "BASE_SUCCESS",
  BASE_FAILURE: "BASE_FAILURE",
  GET_BASE_START: "GET_BASE_START",
  GET_BASE_SUCCESS: "GET_BASE_SUCCESS",
  GET_BASE_FAILURE: "GET_BASE_FAILURE",
  GET_NOTIFICATION_START: "GET_NOTIFICATION_START",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAILURE: "GET_NOTIFICATION_FAILURE",
  NOTIFICATION_START: "NOTIFICATION_START",
  NOTIFICATION_SUCCESS: "NOTIFICATION_SUCCESS",
  NOTIFICATION_FAILURE: "NOTIFICATION_FAILURE",
  GET_BASE_PLAN_START: "GET_BASE_PLAN_START",
  GET_BASE_PLAN_SUCCESS: "GET_BASE_PLAN_SUCCESS",
  GET_BASE_PLAN_FAILURE: "GET_BASE_PLAN_FAILURE",
  GET_COMPANY_START: "GET_COMPANY_START",
  GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
  GET_COMPANY_FAILURE: "GET_COMPANY_FAILURE",
  UPDATE_OTP_SUBMITTED_STATUS: "UPDATE_OTP_SUBMITTED_STATUS",
  RESNED_OTP_BUTTON_CLICKED: "RESNED_OTP_BUTTON_CLICKED",
};
