import React, { useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import PersonnelFields from "components/PersonnelFields/PersonnelFields";
import RequiredText from "components/required-text/required-text";
import { useEffect } from "react";

export default function Personnel({
  personnel,
  setPersonnel,
  posOptions,
  disabled,
}) {
  const [add, setAdd] = useState(false);
  const [view, setView] = useState(true);
  const [value, setValue] = useState();
  const [email, setEmail] = useState("");
  const saveData = (data) => {
    setPersonnel([...personnel, data]);
    setAdd(false);
  };
  const editValue = (value) => {
    setValue(value);
  };
  const emailValue = (value) => {
    setEmail(value);
  };
  useEffect(() => {
    setView(true);
  }, [value]);
  //   useEffect(() => {
  //     if (!view) {
  //       setView(true);
  //     }
  //   }, [!view]);

  const editData = (data) => {
    const idx = personnel.findIndex((list) => list.id === data.id);
    personnel.splice(idx, 1, data);
    setPersonnel([...personnel]);
  };
  const deleteData = (id) => {
    const idx = personnel.findIndex((list) => list.id === id);
    personnel.splice(idx, 1);
    setPersonnel([...personnel]);
  };
  const toggleAdd = (id) => {
    setAdd(false);
  };
  const handleOnClickAdd = (viiew) => {
    setAdd(!add);
  };

  return (
    <Row style={{ margin: "1em 0" }}>
      <Col>
        <div>
          <p style={{ padding: "0", margin: "2% 0 1% 9%" }} className="text-base">
            Supplier Personnel (When Entering <RequiredText>*</RequiredText> are
            required fields)
          </p>
          <table
          //    className="_tbl_supp"
          >
            <thead>
              <tr>
                <th>
                  <Button
                    disabled={disabled}
                    onClick={() => handleOnClickAdd(true)}
                  >
                    Add
                  </Button>
                </th>
                <th style={{ width: "3%" }}></th>
                <th style={{ textAlign: "center" }}>
                  Position <RequiredText>*</RequiredText>
                </th>
                <th style={{ width: "13%" }}></th>
                <th style={{ textAlign: "center" }}>
                  First <RequiredText>*</RequiredText>
                </th>
                <th style={{ width: "13%" }}></th>
                <th style={{ textAlign: "center" }}>
                  Last <RequiredText>*</RequiredText>
                </th>
                <th style={{ width: "14%" }}></th>
                <th style={{ textAlign: "center" }}>Phone</th>
                <th style={{ width: "13%" }}></th>
                <th style={{ textAlign: "center" }}>Email</th>
                <th style={{ width: "11%" }}></th>
              </tr>
            </thead>
            <tbody>
              {/* {personnel.map((data) => (
                <PersonnelFields
                  key={data.id}
                  idV={data.id}
                  firstV={data.first}
                  lastV={data.last}
                  emailV={data.email}
                  phoneV={data.phone}
                  posV={data.pos}
                  saveData={editData}
                  deleteData={deleteData}
                  posOptions={posOptions}
                  add={add}
                  editValue={editValue}
                  setView={setView}
                  emailValue={emailValue}
                />
              ))}
              {add && (
                <PersonnelFields
                  posOptions={posOptions}
                  saveData={saveData}
                  deleteData={toggleAdd}
                  editData={true}
                  disabled={disabled}
                  add={add}
                  editValue={editValue}
                  setView={setView}
                  emailValue={emailValue}
                />
              )} */}
            </tbody>
          </table>
          {personnel.map((data) => (
            <PersonnelFields
              key={data.id}
              idV={data.id}
              firstV={data.first}
              lastV={data.last}
              emailV={data.email}
              phoneV={data.phone}
              posV={data.pos}
              saveData={editData}
              deleteData={deleteData}
              posOptions={posOptions}
              add={add}
              editValue={editValue}
              setView={setView}
              emailValue={emailValue}
            />
          ))}
          {add && (
            <PersonnelFields
              posOptions={posOptions}
              saveData={saveData}
              deleteData={toggleAdd}
              editData={true}
              disabled={disabled}
              add={add}
              editValue={editValue}
              setView={setView}
              emailValue={emailValue}
            />
          )}
        </div>
      </Col>
    </Row>
  );
}
