import React from 'react';
import moment from 'moment';
import NextIcon from '../../assets/right.svg';
import PrevIcon from '../../assets/left.svg';
import { getWeekDate } from 'components/calendar/utils';

const RoofWeekView = ({
  onPrevWeek,
  onNextWeek,
  weekdaysShort,
  dateObject,
  weekText,
  daysInWeekView,
}) => {

  const renderWeekDays = () => {
    const today = moment().format('l');
    return weekdaysShort.map((day, i) => {
      const dateString = new Date(getWeekDate(i, dateObject))
        .toDateString()
        .split(' ');
      return (
        <th
          key={day}
          className={`week-day ${today === getWeekDate(i, dateObject) ? 'today' : ''} _week_view_th`}
        >
          <div className='text-sm'>{day}</div>
          <div className="date text-sm">{`${dateString[1]} ${Number(dateString[2])}`}</div>
        </th>
      );
    });
  };

  const BtnIcon = ({ src, alt }) => (
    <img src={src} height="25" width="25" alt={alt} />
  );

  return (
    <div>
      <div className="_heading flex justify-between items-center p-4">
        <button onClick={onPrevWeek}>
          <BtnIcon src={PrevIcon} alt="prev" />
        </button>
        <div className="text-sm">{weekText}</div>
        <button onClick={onNextWeek}>
          <BtnIcon src={NextIcon} alt="next" />
        </button>
      </div>
      <table className="_calendar_table_week border-collapse w-full">
        <thead>
          <tr className="border border-sky-500">
            {renderWeekDays()}
          </tr>
        </thead>
        <tbody className='p-2'>
          <tr className="border border-sky-500">
            {daysInWeekView()}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RoofWeekView;
