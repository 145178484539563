import React from 'react';

const showOption = (option) => (
  <option value={option} key={option}>
    {option}
  </option>
);

export default function showOptions(optionsList = [], loading = false) {
  if (loading) {
    return <option value="">Loading...</option>;
  }

  if (!optionsList || (optionsList && optionsList.length === 0)) {
    return <option value="">No data available.</option>;
  }

  return optionsList.map(showOption);
}
