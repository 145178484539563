import metricSelection from "./metricSelection";
import { getMaterialPricingBySuppliers } from "services/offlineComputing/controllers/materialPricingCompany";
import data from "lib/constants/constDataForCodeCorrection";
import { getRoofMetrics } from "services/offlineComputing/models/metricsModel";
import {
  CATEGORYDATA_MATERIAL,
  MANUFACTURERS,
  SLOPED_TYPES,
} from "lib/constants/data";
const {
  get_uom_category_options,
  add_to_options,
  get_uom_categoryType_options,
  value,
} = data;

const { parseMetricData } = require("./parseMetricData");
// const {
//   CATEGORYDATA_MATERIAL,
//   MANUFACTURERS,
//   SLOPED_TYPES,
// } = require("../../../lib/constants/constDataForCodeCorrection");

const getMetricSelectionData = async (
  parsedMetricData,
  supplierId,
  companyId
) => {
  let materialSectionData = [];
  CATEGORYDATA_MATERIAL.forEach(async (category) => {
    const { dataKey, key, additionalData, typeKey } = category;
    const hasMetricData = dataKey ? parsedMetricData[dataKey] : null;
    let validation = {
      supplierId: supplierId,
      companyId: companyId,
    };
    let query = { ...validation };

    if (hasMetricData) {
      const data = metricSelection(category, hasMetricData, key);
      if (data.length > 0) {
        if (category.noChanges) {
          data.map((pricing) => {
            materialSectionData.push({
              order: category.order,
              ...pricing,
              noChanges: category.noChanges,
            });
          });
        } else {
          data.map((pricing) => {
            query = additionalData
              ? { ...query, ...additionalData }
              : { ...query, [typeKey]: key };

            query = { ...query, series: pricing.series };

            const { subType, categoryType } = pricing;

            if (subType) {
              query = { ...query, subType };
            }

            if (categoryType) {
              query = { ...query, categoryType };
            }
            materialSectionData.push({
              order: category.order,
              ...pricing,
              fromMetric: true,
              query,
              noChanges: false,
            });
          });
        }
      }
    }
  });
  return materialSectionData;
};

const getUnparsedRoofingMetricData = async ({ requestId }) => {
  const metricData = getRoofMetrics(requestId); //getData(`metrics${requestId}`);
  if (metricData.length === 0) {
    return [];
  }

  return [metricData.data];
};

const getRoofingMetricData = async ({
  requestId,
  excludedBuildings,
  excludedSections,
}) => {
  const metricData = await getUnparsedRoofingMetricData({
    requestId,
  });

  const parsedMetricData = await parseMetricData(
    metricData[0],
    excludedBuildings,
    excludedSections
  );

  return parsedMetricData;
};

const removeDuplicatesFromData = async (metricSelectedData) => {
  const nonDuplicates = {};

  metricSelectedData.forEach((data) => {
    const key = data.category + "-" + data.series;
    if (nonDuplicates[key]) {
      nonDuplicates[key].qty = nonDuplicates[key].qty + data.qty;
    } else {
      nonDuplicates[key] = data;
    }
  });

  return Object.values(nonDuplicates);
};
const attachPriceToMetricSelectedData = async (dataList) => {
  const newList = await dataList.map(async (metricSelectedData) => {
    if (!metricSelectedData.noChanges) {
      const pricing = await getMaterialPricingBySuppliers(
        metricSelectedData.query
      );
      return { data: pricing, oldData: metricSelectedData };
    } else {
      return { oldData: metricSelectedData };
    }
  });
  return Promise.all(newList);
};

const getPricingForMetricSelectedData = async (attachedPriceDataList) => {
  if (attachedPriceDataList.length === 0) return [];
  return attachedPriceDataList.map((priceData) => {
    const { oldData, data } = priceData;

    if (data && data.length > 0) {
      const {
        order,
        manufacturer,
        series,
        qty,
        category,
        fromMetric,
        total,
        subType,
        categoryType,
      } = oldData;

      const { uomPrice, _id, uom } = data[0];
      let original_list = {
        manufacturer: manufacturer,
        series: series,
        qty: qty,
      };

      return {
        uomPrice: Number(uomPrice).toFixed(2),
        _id,
        order,
        manufacturer,
        series,
        qty,
        uom,
        category,
        fromMetric,
        total: Number(uomPrice * qty).toFixed(2),
        subType,
        categoryType,
        original_list: original_list,
      };
    } else if (oldData) {
      const { query, noChanges, ...rest } = oldData;
      return rest;
    }
  });
};
export const materialSectionAllData = async (
  requestId,
  supplierId,
  companyId,
  excludedBuildings,
  excludedSections
) => {
  const parsedMetricData = await getRoofingMetricData({
    requestId,
    excludedBuildings,
    excludedSections,
  });

  const metricSelectedData = await getMetricSelectionData(
    parsedMetricData,
    supplierId,
    companyId
  );

  const nonDuplicateMetricSelectedData = await removeDuplicatesFromData(
    metricSelectedData
  );

  const metricSelectedDataWithPricing = await attachPriceToMetricSelectedData(
    nonDuplicateMetricSelectedData
  );

  const metricSelectedParsedData = await getPricingForMetricSelectedData(
    metricSelectedDataWithPricing
  );

  const data = await getParsedPricings(parsedMetricData, supplierId, companyId);
  return [...data, ...metricSelectedParsedData].sort(
    (a, b) => a.order - b.order
  );
};

const checkData = (parsedMetricData, supplierId, companyId) => {
  let allPromises = [];

  const { hideData, totals } = parsedMetricData;
  const allTotalData = totals;

  let addedPlywood = false;

  CATEGORYDATA_MATERIAL.forEach((category) => {
    const { typeKey, totalKey, key, additionalData, uom, order } = category;

    const hide = hideData[key];
    const hasTotalValueInMetric = totalKey && allTotalData[totalKey];
    const hasTotalKey = totalKey;

    let validation = { supplierId, companyId };
    let query = { isPreferred: true, ...validation };
    // if(!hideData[plywood_rip] || !hideData[cedar_rip]){

    // }
    if (
      (!hide && key === "Flat Roofing") ||
      (key === "Roofing" && !hideData["noRemovePly"])
    ) {
      if (
        addedPlywood == false &&
        hideData["noRemovePly"] === false &&
        hideData["overlay"] === true &&
        hideData["slate_rip"] === true
      ) {
        addedPlywood = true;
        const q = {
          ...query,
          category: "Wood",
          categoryType: "Plywood",
        };
        allPromises.push({
          data: getMaterialPricingBySuppliers(q),
          key: "Plywood",
          order: 100,
        });
      }
    }

    if (!hide && key === "Flat Roofing" && !hideData["twoPly"]) {
      const q = {
        ...query,
        category: "Flat Roofing Adhesive",
        categoryType: "Flat Roof Adhesive",
      };
      allPromises.push({
        data: getMaterialPricingBySuppliers(q),
        key: "Flat Roofing Adhesive",
        order,
        val: "",
      });
    }

    if (
      !hideData["onlyOverlay"] &&
      !hide &&
      key === "Roofing" &&
      (!hideData["3-tab"] || !hideData["Architechtural"])
    ) {
      const q = {
        ...query,
        category: "Nails",
        categoryType: "Coil",
      };
      allPromises.push({
        data: getMaterialPricingBySuppliers(q),
        key: "Coil Nails",
        order: 100,
        val: "",
      });
    }

    if (!hide && key === "Roofing" && !hideData["Designer"]) {
      const q = {
        ...validation,
        category: "Nails",
        series: 'Galvanized 1.5"',
        categoryType: "Coil",
      };

      allPromises.push({
        data: getMaterialPricingBySuppliers(q),
        key: "Coil Nails - Designer",
        order: 100,
        hasDuplicateKey: true,
        val: "",
      });
    }

    if (!hide && hasTotalValueInMetric && !uom) {
      if (key === "Trim Metal") {
        if (!hideData["apronOrJBend"]) {
          let qData = {
            ...query,
            category: "Coil Rolls",
            categoryType: "Coil Rolls",
            series: "Flat White",
          };
          allPromises.push({
            data: getMaterialPricingBySuppliers(qData),
            key: "Coil Rolls",
            order,
            val: "",
          });
        }

        if (!hideData["gravelStop"]) {
          let qData = {
            ...query,
            category: "Metal Sheets",
            categoryType: "Metal Sheets",
            subType: "Aluminum",
          };
          allPromises.push({
            data: getMaterialPricingBySuppliers(qData),
            key: "Metal Sheets",
            order,
            val: "",
          });
        }
      } else {
        query = additionalData
          ? { ...query, ...additionalData }
          : { ...query, [typeKey]: key };

        allPromises.push({
          data: getMaterialPricingBySuppliers(query),
          key,
          order,
          val: key === "Chimney" ? "Chimney" : key === "Wall" ? "wall" : "",
        });
      }
    } else if (!hide && !hasTotalKey && !uom) {
      if (key === "Flat Roofing") {
        if (!hideData["twoPly"]) {
          query = {
            ...query,
            [typeKey]: key,
            categoryType: {
              $not: {
                $regex: "Torch",
              },
            },
          };

          allPromises.push({
            data: getMaterialPricingBySuppliers(query),
            key,
            order,
            val: "",
          });
        }

        if (!hideData["singlePly"]) {
          query = {
            ...query,
            [typeKey]: key,
            categoryType: {
              $regex: "Torch",
            },
          };

          allPromises.push({
            data: getMaterialPricingBySuppliers(query),
            key,
            order,
            val: "",
          });
        }
        return;
      }

      if (key === "Underlayments" && !hideData["overlay"]) {
        const q = {
          ...validation,
          category: "Nails",
          series: 'Galvanized 1.5"',
          categoryType: "Coil",
        };

        allPromises.push({
          data: getMaterialPricingBySuppliers(q),
          key: "Coil Nails - Overlay",
          order: 100,
          val: "",
        });
      }
      query = additionalData
        ? { ...query, ...additionalData }
        : { ...query, [typeKey]: key };

      allPromises.push({
        data: getMaterialPricingBySuppliers(query),
        key,
        order,
        hasDuplicateKey: true,
        val: key === "Chimney" ? "Chimney" : key === "Wall" ? "wall" : "",
      });
    }
  });

  return Promise.all(allPromises);
};

const getPricingData = (
  pricings,
  key,
  order,
  hideMetaData,
  hasDuplicateKey,
  val,
  parsedMetricData
) => {
  let allPricings = [];

  let ctr =
    parsedMetricData.hideData["3-tab-area"] +
    parsedMetricData.hideData["DesignerArea"] +
    parsedMetricData.hideData["ArchitechturalArea"];
  let coil_nails =
    parsedMetricData.hideData["3-tab-area"] +
    parsedMetricData.hideData["ArchitechturalArea"];

  pricings.forEach((pricing) => {
    const {
      _id,
      category,
      series,
      uomPrice,
      uom,
      subType,
      categoryType,
      uomValue,
    } = pricing;

    const {
      Roofing,
      Designer,
      DesignerArea,
      Architechtural,
      ArchitechturalArea,
      Underlayments,
      twoPly,
      twoPlyArea,
      singlePly,
      singlePlyArea,
      apronOrJBend,
      gravelStop,
      overlay,
      noRemovePly,
      onlyOverlay,
      plywood_area,
    } = parsedMetricData.hideData;

    const { jBend, apron } = parsedMetricData.totals;

    if (!hideMetaData[subType]) {
      let qty =
        hideMetaData[SLOPED_TYPES[0].name] === false &&
        category === get_uom_category_options.SHINGLES &&
        subType === SLOPED_TYPES[0].name
          ? Math.ceil((uomValue * hideMetaData["3-tab-area"]) / 100)
          : hideMetaData[SLOPED_TYPES[2].name] === false &&
            category === get_uom_category_options.SHINGLES &&
            subType === SLOPED_TYPES[2].name
          ? Math.ceil((uomValue * DesignerArea) / 100)
          : hideMetaData[SLOPED_TYPES[1].name] === false &&
            category === get_uom_category_options.SHINGLES &&
            subType === SLOPED_TYPES[1].name
          ? Math.ceil((uomValue * ArchitechturalArea) / 100)
          : category === get_uom_category_options.FLAT_ROOFING &&
            hideMetaData["singlePly"] === false
          ? Math.ceil(singlePlyArea / uomValue)
          : category === get_uom_category_options.FLAT_ROOFING &&
            hideMetaData["twoPly"] === false
          ? Math.ceil(twoPlyArea / uomValue)
          : category === get_uom_category_options.SHINGLES &&
            categoryType === add_to_options.STARTER
          ? Math.ceil(Number(parsedMetricData.totals["T0"]) / uomValue)
          : category === get_uom_category_options.SHINGLES &&
            categoryType === add_to_options.HIP_AND_RIDGE
          ? Math.ceil(Number(parsedMetricData.totals["T4"]) / uomValue)
          : category === get_uom_category_options.UNDERLAYMENTS &&
            categoryType === get_uom_categoryType_options.LEAK_BARRIER
          ? Math.ceil(Number(parsedMetricData.totals["T1"]) / uomValue)
          : category === get_uom_category_options.UNDERLAYMENTS
          ? Math.ceil(ctr / uomValue)
          : category === get_uom_category_options.NAILS &&
            categoryType === get_uom_categoryType_options.COIL &&
            hideMetaData[Designer] === false &&
            series === 'Galvanized 1.5"'
          ? Math.ceil(DesignerArea / uomValue)
          : category === get_uom_category_options.NAILS &&
            categoryType === get_uom_categoryType_options.COIL &&
            (hideMetaData[SLOPED_TYPES[0].name] === false ||
              hideMetaData[SLOPED_TYPES[1].name] === false)
          ? Math.ceil(coil_nails / uomValue)
          : category === get_uom_category_options.FLAT_ROOFING_ADHESIVE &&
            categoryType === get_uom_categoryType_options.FLAT_ROOF_ADHESIVE
          ? Math.ceil(twoPlyArea / uomValue)
          : category === get_uom_category_options.VENTILATION &&
            categoryType === get_uom_categoryType_options.RIDGE_VENT
          ? Math.ceil(Number(parsedMetricData.totals["T5"]) / uomValue)
          : category === get_uom_category_options.DRIP_EDGING &&
            categoryType === get_uom_categoryType_options.EVE
          ? Math.ceil(Number(parsedMetricData.totals["T2"]) / uomValue)
          : category === get_uom_category_options.DRIP_EDGING &&
            categoryType === get_uom_categoryType_options.RAKE
          ? Math.ceil(Number(parsedMetricData.totals["T3"]) / uomValue)
          : category === get_uom_category_options.FLASHINGS &&
            categoryType === get_uom_categoryType_options.VALLEY
          ? Math.ceil(Number(parsedMetricData.totals["T7"]) / uomValue)
          : category === get_uom_category_options.FLASHINGS &&
            categoryType === get_uom_categoryType_options.STEP
          ? Math.ceil(Number(parsedMetricData.totals["T8"]) / uomValue)
          : category === get_uom_category_options.COIL_ROLLS &&
            categoryType === get_uom_category_options.COIL_ROLLS
          ? Math.ceil((jBend + apron) / 400)
          : category === get_uom_category_options.METALSHEETS &&
            categoryType === get_uom_category_options.METALSHEETS &&
            val === ""
          ? Math.ceil(parsedMetricData.totals["gravelStop"] / 50)
          : category === get_uom_category_options.VENTILATION &&
            categoryType === get_uom_categoryType_options.LOUVERS
          ? Math.ceil(parsedMetricData.totals["T11"])
          : category === get_uom_category_options.VENTILATION &&
            categoryType === get_uom_categoryType_options.POWER_FAN
          ? Math.ceil(parsedMetricData.totals["T12"])
          : series === "Model 636 Steel Roof Cap Small"
          ? Math.ceil(parsedMetricData.totals["T13"])
          : series === "Model 634 Steel Roof Cap Large"
          ? Math.ceil(parsedMetricData.totals["T14"])
          : category === get_uom_category_options.METALSHEETS &&
            val === value.CHIMNEY
          ? 1 //Math.ceil(parsedMetricData.totals['T9'])
          : category === get_uom_category_options.METALSHEETS &&
            val === value.WALL
          ? 1 //Math.ceil(parsedMetricData.totals['T10'])
          : category === get_uom_category_options.FLASHINGS &&
            categoryType === get_uom_categoryType_options.VENT_PIPE
          ? Math.ceil(parsedMetricData.totals["T6"])
          : category === get_uom_category_options.WOOD &&
            categoryType === get_uom_categoryType_options.PLYWOOD
          ? Math.ceil(plywood_area / 32)
          : 0;
      const manufacturer =
        pricing.manufacturer === "Various" ? "" : pricing.manufacturer;
      const total = Number(qty * uomPrice).toFixed(2);
      let original_list = {
        manufacturer: manufacturer,
        series: series,
        qty: qty,
      };

      allPricings.push({
        order,
        _id: _id,
        manufacturer,
        series: series,
        uomPrice: Number(uomPrice).toFixed(2),
        subType,
        pricingCategory: category,
        categoryType,
        qty: qty,
        uom: uom,
        category: key,
        manufacturers: MANUFACTURERS[key] ? MANUFACTURERS[key] : [],
        fromMetric: false,
        uomValue,
        total,
        original_list: original_list,
      });
    }
  });
  ctr = 0;
  coil_nails = 0;
  return allPricings;
};

const getParsedPricings = async (parsedMetricData, supplierId, companyId) => {
  let data = [];
  const pricings = await checkData(parsedMetricData, supplierId, companyId);
  const allData = await pricings.map(async (pricing) => {
    if (pricing) {
      const pricingsData = await pricing.data;

      const reqPricing = getPricingData(
        pricingsData,
        pricing.key,
        pricing.order,
        parsedMetricData.hideData,
        pricing.hasDuplicateKey,
        pricing.val,
        parsedMetricData
      );
      data = [...data, ...reqPricing];
    }
  });

  await Promise.all(allData);
  return data;
};
