"use strict";

const {
  CATEGORYDATA_MATERIAL,
  CHECK_ROOFING,
  getInstallType,
  METAL,
  WORKTYPE,
  RemoveTypes,
  AUTO_TEXTS_MODAL_SUBCATEGORIES,
  SLOPED_TYPES,
  FLATTYPES,
} = require("../../../lib/constants/data");

const { getHideProperties } = require("./getHideProperties");

export const isSectionIncluded = ({
  excludedSections,
  excludedBuildings,
  sectionId,
  building,
}) => {
  return (
    excludedSections.includes(sectionId) || excludedBuildings.includes(building)
  );
};
export const parseMetricData = (
  metric,
  excludedBuildings = [],
  excludedSections = []
) => {
  let parsedData = {};
  if (metric.metricData.length > 0) {
    let totals = {};
    parsedData.skylightData = [];
    parsedData.rafterstudsData = [];
    parsedData.plywoodData = [];
    parsedData.furringstripsData = [];
    parsedData.fasciaData = [];
    parsedData.chimenycapsData = [];
    parsedData.cappingData = [];
    parsedData.commonnailsData = [];
    parsedData.handnailsData = [];
    parsedData.metalsheetData = [];
    parsedData.laborInitialData = [];
    parsedData.sectionId = [];

    parsedData.hideData = {
      "Flat Roofing": true,
      Roofing: true,
      "3-tab": true,
      "3-tab-area": 0,
      Designer: true,
      DesignerArea: 0,
      Architechtural: true,
      ArchitechturalArea: 0,
      Underlayments: true,
      twoPly: true,
      twoPlyArea: 0,
      singlePly: true,
      singlePlyArea: 0,
      apronOrJBend: true,
      gravelStop: true,
      slate_rip: true,
      overlay: true,
      noRemovePly: true,
      onlyOverlay: true,
      tab_area_flat: 0,
      designer_area_flat: 0,
      architechtural_area_flat: 0,
      single_ply_area_flat: 0,
      two_ply_area_flat: 0,
      tab_area_nw: 0,
      designer_area_nw: 0,
      architechtural_area_nw: 0,
      single_ply_area_nw: 0,
      two_ply_area_nw: 0,
      tab_area_w: 0,
      designer_area_w: 0,
      architechtural_area_w: 0,
      single_ply_area_w: 0,
      two_ply_area_w: 0,
      plywood_area: 0,
      plywood_rip_area: 0,
      cedar_rip_area: 0,
      tab_flat_shingle_slr: 0,
      tab_flat_shingle_tlr: 0,
      tab_flat_shingle_thlr: 0,
      tab_flat_flat_slr: 0,
      tab_flat_flat_tlr: 0,
      tab_flat_flat_thlr: 0,
      tab_nw_shingle_slr: 0,
      tab_nw_shingle_tlr: 0,
      tab_nw_shingle_thlr: 0,
      tab_nw_flat_slr: 0,
      tab_nw_flat_tlr: 0,
      tab_nw_flat_thlr: 0,
      tab_w_shingle_slr: 0,
      tab_w_shingle_tlr: 0,
      tab_w_shingle_thlr: 0,
      tab_w_flat_slr: 0,
      tab_w_flat_tlr: 0,
      tab_w_flat_thlr: 0,
      arch_flat_shingle_slr: 0,
      arch_flat_shingle_tlr: 0,
      arch_flat_shingle_thlr: 0,
      arch_flat_flat_slr: 0,
      arch_flat_flat_tlr: 0,
      arch_flat_flat_thlr: 0,
      arch_nw_shingle_slr: 0,
      arch_nw_shingle_tlr: 0,
      arch_nw_shingle_thlr: 0,
      arch_nw_flat_slr: 0,
      arch_nw_flat_tlr: 0,
      arch_nw_flat_thlr: 0,
      arch_w_shingle_slr: 0,
      arch_w_shingle_tlr: 0,
      arch_w_shingle_thlr: 0,
      arch_w_flat_slr: 0,
      arch_w_flat_tlr: 0,
      arch_w_flat_thlr: 0,
      designer_flat_shingle_slr: 0,
      designer_flat_shingle_tlr: 0,
      designer_flat_shingle_thlr: 0,
      designer_flat_flat_slr: 0,
      designer_flat_flat_tlr: 0,
      designer_flat_flat_thlr: 0,
      designer_nw_shingle_slr: 0,
      designer_nw_shingle_tlr: 0,
      designer_nw_shingle_thlr: 0,
      designer_nw_flat_slr: 0,
      designer_nw_flat_tlr: 0,
      designer_nw_flat_thlr: 0,
      designer_w_shingle_slr: 0,
      designer_w_shingle_tlr: 0,
      designer_w_shingle_thlr: 0,
      designer_w_flat_slr: 0,
      designer_w_flat_tlr: 0,
      designer_w_flat_thlr: 0,
      sp_flat_shingle_slr: 0,
      sp_flat_shingle_tlr: 0,
      sp_flat_shingle_thlr: 0,
      sp_flat_flat_slr: 0,
      sp_flat_flat_tlr: 0,
      sp_flat_flat_thlr: 0,
      sp_nw_shingle_slr: 0,
      sp_nw_shingle_tlr: 0,
      sp_nw_shingle_thlr: 0,
      sp_nw_flat_slr: 0,
      sp_nw_flat_tlr: 0,
      sp_nw_flat_thlr: 0,
      sp_w_shingle_slr: 0,
      sp_w_shingle_tlr: 0,
      sp_w_shingle_thlr: 0,
      sp_w_flat_slr: 0,
      sp_w_flat_tlr: 0,
      sp_w_flat_thlr: 0,
      tp_flat_shingle_slr: 0,
      tp_flat_shingle_tlr: 0,
      tp_flat_shingle_thlr: 0,
      tp_flat_flat_slr: 0,
      tp_flat_flat_tlr: 0,
      tp_flat_flat_thlr: 0,
      tp_nw_shingle_slr: 0,
      tp_nw_shingle_tlr: 0,
      tp_nw_shingle_thlr: 0,
      tp_nw_flat_slr: 0,
      tp_nw_flat_tlr: 0,
      tp_nw_flat_thlr: 0,
      tp_w_shingle_slr: 0,
      tp_w_shingle_tlr: 0,
      tp_w_shingle_thlr: 0,
      tp_w_flat_slr: 0,
      tp_w_flat_tlr: 0,
      tp_w_flat_thlr: 0,
    };

    let tab_area = 0;
    let designer_area = 0;
    let architechtural_area = 0;
    let single_ply_area = 0;
    let two_ply_area = 0;
    let tab_area_flat = 0;
    let designer_area_flat = 0;
    let architechtural_area_flat = 0;
    let single_ply_area_flat = 0;
    let two_ply_area_flat = 0;
    let tab_area_nw = 0;
    let designer_area_nw = 0;
    let architechtural_area_nw = 0;
    let single_ply_area_nw = 0;
    let two_ply_area_nw = 0;
    let tab_area_w = 0;
    let designer_area_w = 0;
    let architechtural_area_w = 0;
    let single_ply_area_w = 0;
    let two_ply_area_w = 0;
    let plywood_area = 0;
    let plywood_rip_area = 0;
    let cedar_rip_area = 0;

    metric.metricData.map((val) => {
      const {
        sectionId,
        metaData,
        skylightData,
        rafterstudsData,
        plywoodData,
        furringstripsData,
        fasciaData,
        chimenycapsData,
        cappingData,
        commonnailsData,
        handnailsData,
        metalsheetData,
        removal_types,
        pitch,
        install_types,
        other_rems,
        trimmetalData,
        work_type,
        building,
        totalFlat,
      } = val;

      if (!excludedSections.includes(sectionId)) {
        if (removal_types === "003" || removal_types === "004") {
          plywood_area = plywood_area + totalFlat;
          if (removal_types === "003") {
            plywood_rip_area = plywood_rip_area + totalFlat;
          }
          if (removal_types === "004") {
            cedar_rip_area = cedar_rip_area + totalFlat;
          }
        }

        if (install_types === SLOPED_TYPES[0].id) {
          tab_area = tab_area + totalFlat;
          if (pitch === "001") {
            tab_area_flat = tab_area_flat + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["tab_flat_shingle_slr"] =
                  parsedData.hideData["tab_flat_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["tab_flat_shingle_tlr"] =
                  parsedData.hideData["tab_flat_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["tab_flat_shingle_thlr"] =
                  parsedData.hideData["tab_flat_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["tab_flat_flat_slr"] =
                  parsedData.hideData["tab_flat_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["tab_flat_flat_tlr"] =
                  parsedData.hideData["tab_flat_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["tab_flat_flat_thlr"] =
                  parsedData.hideData["tab_flat_flat_thlr"] + totalFlat;
              }
            }
          }
          if (pitch === "002") {
            tab_area_nw = tab_area_nw + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["tab_nw_shingle_slr"] =
                  parsedData.hideData["tab_nw_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["tab_nw_shingle_tlr"] =
                  parsedData.hideData["tab_nw_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["tab_nw_shingle_thlr"] =
                  parsedData.hideData["tab_nw_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["tab_nw_flat_slr"] =
                  parsedData.hideData["tab_nw_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["tab_nw_flat_tlr"] =
                  parsedData.hideData["tab_nw_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["tab_nw_flat_thlr"] =
                  parsedData.hideData["tab_nw_flat_thlr"] + totalFlat;
              }
            }
          }
          if (pitch === "003") {
            tab_area_w = tab_area_w + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["tab_w_shingle_slr"] =
                  parsedData.hideData["tab_w_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["tab_w_shingle_tlr"] =
                  parsedData.hideData["tab_w_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["tab_w_shingle_thlr"] =
                  parsedData.hideData["tab_w_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["tab_w_flat_slr"] =
                  parsedData.hideData["tab_w_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["tab_w_flat_tlr"] =
                  parsedData.hideData["tab_w_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["tab_w_flat_thlr"] =
                  parsedData.hideData["tab_w_flat_thlr"] + totalFlat;
              }
            }
          }
        }
        if (install_types === SLOPED_TYPES[1].id) {
          architechtural_area = architechtural_area + totalFlat;

          if (pitch === "001") {
            architechtural_area_flat = architechtural_area_flat + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["arch_flat_shingle_slr"] =
                  parsedData.hideData["arch_flat_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["arch_flat_shingle_tlr"] =
                  parsedData.hideData["arch_flat_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["arch_flat_shingle_thlr"] =
                  parsedData.hideData["arch_flat_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["arch_flat_flat_slr"] =
                  parsedData.hideData["arch_flat_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["arch_flat_flat_tlr"] =
                  parsedData.hideData["arch_flat_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["arch_flat_flat_thlr"] =
                  parsedData.hideData["arch_flat_flat_thlr"] + totalFlat;
              }
            }
          }
          if (pitch === "002") {
            architechtural_area_nw = architechtural_area_nw + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["arch_nw_shingle_slr"] =
                  parsedData.hideData["arch_nw_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["arch_nw_shingle_tlr"] =
                  parsedData.hideData["arch_nw_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["arch_nw_shingle_thlr"] =
                  parsedData.hideData["arch_nw_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["arch_nw_flat_slr"] =
                  parsedData.hideData["arch_nw_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["arch_nw_flat_tlr"] =
                  parsedData.hideData["arch_nw_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["arch_nw_flat_thlr"] =
                  parsedData.hideData["arch_nw_flat_thlr"] + totalFlat;
              }
            }
          }
          if (pitch === "003") {
            architechtural_area_w = architechtural_area_w + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["arch_w_shingle_slr"] =
                  parsedData.hideData["arch_w_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["arch_w_shingle_tlr"] =
                  parsedData.hideData["arch_w_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["arch_w_shingle_thlr"] =
                  parsedData.hideData["arch_w_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["arch_w_flat_slr"] =
                  parsedData.hideData["arch_w_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["arch_w_flat_tlr"] =
                  parsedData.hideData["arch_w_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["arch_w_flat_thlr"] =
                  parsedData.hideData["arch_w_flat_thlr"] + totalFlat;
              }
            }
          }
        }
        if (install_types === SLOPED_TYPES[2].id) {
          designer_area = designer_area + totalFlat;

          if (pitch === "001") {
            designer_area_flat = designer_area_flat + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["designer_flat_shingle_slr"] =
                  parsedData.hideData["designer_flat_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["designer_flat_shingle_tlr"] =
                  parsedData.hideData["designer_flat_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["designer_flat_shingle_thlr"] =
                  parsedData.hideData["designer_flat_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["designer_flat_flat_slr"] =
                  parsedData.hideData["designer_flat_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["designer_flat_flat_tlr"] =
                  parsedData.hideData["designer_flat_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["designer_flat_flat_thlr"] =
                  parsedData.hideData["designer_flat_flat_thlr"] + totalFlat;
              }
            }
          }
          if (pitch === "002") {
            designer_area_nw = designer_area_nw + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["designer_nw_shingle_slr"] =
                  parsedData.hideData["designer_nw_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["designer_nw_shingle_tlr"] =
                  parsedData.hideData["designer_nw_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["designer_nw_shingle_thlr"] =
                  parsedData.hideData["designer_nw_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["designer_nw_flat_slr"] =
                  parsedData.hideData["designer_nw_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["designer_nw_flat_tlr"] =
                  parsedData.hideData["designer_nw_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["designer_nw_flat_thlr"] =
                  parsedData.hideData["designer_nw_flat_thlr"] + totalFlat;
              }
            }
          }
          if (pitch === "003") {
            designer_area_w = designer_area_w + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["designer_w_shingle_slr"] =
                  parsedData.hideData["designer_w_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["designer_w_shingle_tlr"] =
                  parsedData.hideData["designer_w_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["designer_w_shingle_thlr"] =
                  parsedData.hideData["designer_w_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["designer_w_flat_slr"] =
                  parsedData.hideData["designer_w_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["designer_w_flat_tlr"] =
                  parsedData.hideData["designer_w_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["designer_w_flat_thlr"] =
                  parsedData.hideData["designer_w_flat_thlr"] + totalFlat;
              }
            }
          }
        }
        if (install_types === FLATTYPES[0].id) {
          single_ply_area = single_ply_area + totalFlat;

          if (pitch === "001") {
            single_ply_area_flat = single_ply_area_flat + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["sp_flat_shingle_slr"] =
                  parsedData.hideData["sp_flat_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["sp_flat_shingle_tlr"] =
                  parsedData.hideData["sp_flat_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["sp_flat_shingle_thlr"] =
                  parsedData.hideData["sp_flat_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["sp_flat_flat_slr"] =
                  parsedData.hideData["sp_flat_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["sp_flat_flat_tlr"] =
                  parsedData.hideData["sp_flat_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["sp_flat_flat_thlr"] =
                  parsedData.hideData["sp_flat_flat_thlr"] + totalFlat;
              }
            }
          }
          if (pitch === "002") {
            single_ply_area_nw = single_ply_area_nw + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["sp_nw_shingle_slr"] =
                  parsedData.hideData["sp_nw_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["sp_nw_shingle_tlr"] =
                  parsedData.hideData["sp_nw_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["sp_nw_shingle_thlr"] =
                  parsedData.hideData["sp_nw_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["sp_nw_flat_slr"] =
                  parsedData.hideData["sp_nw_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["sp_nw_flat_tlr"] =
                  parsedData.hideData["sp_nw_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["sp_nw_flat_thlr"] =
                  parsedData.hideData["sp_nw_flat_thlr"] + totalFlat;
              }
            }
          }
          if (pitch === "003") {
            single_ply_area_w = single_ply_area_w + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["sp_w_shingle_slr"] =
                  parsedData.hideData["sp_w_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["sp_w_shingle_tlr"] =
                  parsedData.hideData["sp_w_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["sp_w_shingle_thlr"] =
                  parsedData.hideData["sp_w_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["sp_w_flat_slr"] =
                  parsedData.hideData["sp_w_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["sp_w_flat_tlr"] =
                  parsedData.hideData["sp_w_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["sp_w_flat_thlr"] =
                  parsedData.hideData["sp_w_flat_thlr"] + totalFlat;
              }
            }
          }
        }
        if (install_types === FLATTYPES[1].id) {
          two_ply_area = two_ply_area + totalFlat;

          if (pitch === "001") {
            two_ply_area_flat = two_ply_area_flat + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["tp_flat_shingle_slr"] =
                  parsedData.hideData["tp_flat_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["tp_flat_shingle_tlr"] =
                  parsedData.hideData["tp_flat_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["tp_flat_shingle_thlr"] =
                  parsedData.hideData["tp_flat_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["tp_flat_flat_slr"] =
                  parsedData.hideData["tp_flat_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["tp_flat_flat_tlr"] =
                  parsedData.hideData["tp_flat_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["tp_flat_flat_thlr"] =
                  parsedData.hideData["tp_flat_flat_thlr"] + totalFlat;
              }
            }
          }
          if (pitch === "002") {
            two_ply_area_nw = two_ply_area_nw + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["tp_nw_shingle_slr"] =
                  parsedData.hideData["tp_nw_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["tp_nw_shingle_tlr"] =
                  parsedData.hideData["tp_nw_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["tp_nw_shingle_thlr"] =
                  parsedData.hideData["tp_nw_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["tp_nw_flat_slr"] =
                  parsedData.hideData["tp_nw_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["tp_nw_flat_tlr"] =
                  parsedData.hideData["tp_nw_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["tp_nw_flat_thlr"] =
                  parsedData.hideData["tp_nw_flat_thlr"] + totalFlat;
              }
            }
          }
          if (pitch === "003") {
            two_ply_area_w = two_ply_area_w + totalFlat;

            if (removal_types === "001") {
              if (other_rems === "001") {
                parsedData.hideData["tp_w_shingle_slr"] =
                  parsedData.hideData["tp_w_shingle_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["tp_w_shingle_tlr"] =
                  parsedData.hideData["tp_w_shingle_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["tp_w_shingle_thlr"] =
                  parsedData.hideData["tp_w_shingle_thlr"] + totalFlat;
              }
            }
            if (removal_types === "002") {
              if (other_rems === "001") {
                parsedData.hideData["tp_w_flat_slr"] =
                  parsedData.hideData["tp_w_flat_slr"] + totalFlat;
              }
              if (other_rems === "002") {
                parsedData.hideData["tp_w_flat_tlr"] =
                  parsedData.hideData["tp_w_flat_tlr"] + totalFlat;
              }
              if (other_rems === "003") {
                parsedData.hideData["tp_w_flat_thlr"] =
                  parsedData.hideData["tp_w_flat_thlr"] + totalFlat;
              }
            }
          }
        }
      }
    });

    parsedData.hideData.tab_area_flat = tab_area_flat;
    parsedData.hideData.architechtural_area_flat = architechtural_area_flat;
    parsedData.hideData.designer_area_flat = designer_area_flat;
    parsedData.hideData.single_ply_area_flat = single_ply_area_flat;
    parsedData.hideData.two_ply_area_flat = two_ply_area_flat;
    parsedData.hideData.tab_area_nw = tab_area_nw;
    parsedData.hideData.architechtural_area_nw = architechtural_area_nw;
    parsedData.hideData.designer_area_nw = designer_area_nw;
    parsedData.hideData.single_ply_area_nw = single_ply_area_nw;
    parsedData.hideData.two_ply_area_nw = two_ply_area_nw;
    parsedData.hideData.tab_area_w = tab_area_w;
    parsedData.hideData.architechtural_area_w = architechtural_area_w;
    parsedData.hideData.designer_area_w = designer_area_w;
    parsedData.hideData.single_ply_area_w = single_ply_area_w;
    parsedData.hideData.two_ply_area_w = two_ply_area_w;
    parsedData.hideData.plywood_area = plywood_area;
    parsedData.hideData.plywood_rip_area = plywood_rip_area;
    parsedData.hideData.cedar_rip_area = cedar_rip_area;

    metric.metricData.forEach((metric) => {
      const {
        sectionId,
        metaData,
        skylightData,
        rafterstudsData,
        plywoodData,
        furringstripsData,
        fasciaData,
        chimenycapsData,
        cappingData,
        commonnailsData,
        handnailsData,
        metalsheetData,
        removal_types,
        pitch,
        install_types,
        other_rems,
        trimmetalData,
        work_type,
        totalFlat,
        building,
      } = metric;

      parsedData.totalFlat = totalFlat;
      // parsedData.lwfData = lwfData;

      const isIncluded = !isSectionIncluded({
        excludedBuildings,
        excludedSections,
        sectionId,
        building,
      });

      parsedData.sectionId.push(sectionId);
      if (isIncluded) {
        if (removal_types === "005") {
          parsedData.hideData["slate_rip"] = false;
        }
        if (parsedData.hideData.noRemovePly) {
          RemoveTypes.forEach((types, i) => {
            if (i > 1 && types.id === removal_types) {
              parsedData.hideData.noRemovePly = false;
            }
          });
        }

        if (parsedData.hideData.overlay) {
          let isOverlay = WORKTYPE[1].id === work_type;
          parsedData.hideData.overlay = !isOverlay;
        }

        if (parsedData.hideData.onlyOverlay) {
          parsedData.hideData.onlyOverlay =
            WORKTYPE[1].id !== work_type ? false : true;
        }

        if (parsedData.hideData.gravelStop) {
          parsedData.hideData.gravelStop = !trimmetalData.some(
            (detail) => detail.type === METAL[0][2].id
          );
        }

        if (parsedData.hideData.apronOrJBend) {
          parsedData.hideData.apronOrJBend = !trimmetalData.some(
            (detail) =>
              detail.type === METAL[0][0].id || detail.type === METAL[0][1].id
          );
        }

        parsedData.skylightData = [...parsedData.skylightData, ...skylightData];
        parsedData.rafterstudsData = [
          ...parsedData.rafterstudsData,
          ...rafterstudsData,
        ];
        parsedData.plywoodData = [...parsedData.plywoodData, ...plywoodData];
        parsedData.furringstripsData = [
          ...parsedData.furringstripsData,
          ...furringstripsData,
        ];
        parsedData.fasciaData = [...parsedData.fasciaData, ...fasciaData];
        parsedData.cappingData = [...parsedData.cappingData, ...cappingData];
        parsedData.chimenycapsData = [
          ...parsedData.chimenycapsData,
          ...chimenycapsData,
        ];
        parsedData.commonnailsData = [
          ...parsedData.commonnailsData,
          ...commonnailsData,
        ];
        parsedData.handnailsData = [
          ...parsedData.handnailsData,
          ...handnailsData,
        ];
        parsedData.metalsheetData = [
          ...parsedData.metalsheetData,
          ...metalsheetData,
        ];
        parsedData.laborInitialData.push({
          _id: sectionId,
          difficulty: pitch,
          workType: work_type,
          otherRems: other_rems,
          removalTypes: removal_types,
          installType: install_types,
        });

        if (metaData) {
          const hideData = getHideProperties(
            metaData,
            install_types,
            totalFlat,
            tab_area,
            designer_area,
            architechtural_area,
            single_ply_area,
            two_ply_area
          );

          parsedData.hideData = { ...parsedData.hideData, ...hideData };
        }
        CATEGORYDATA_MATERIAL.map((category) => {
          if (category.totalKey) {
            let prevTotal = totals[category.totalKey]
              ? Number(totals[category.totalKey])
              : 0;
            return (totals[category.totalKey] =
              prevTotal + Number(metric[category.totalKey]));
          }
        });
      }
    });
    parsedData.totals = totals;
  }
  return parsedData;
};
