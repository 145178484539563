import React, { Component, useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Color from "lib/constants/color";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { styles } from "../styles";
import { Link } from "react-router-dom";
import CommonAddressFields from "components/CommonAddressFields/CommonAddressFields";

export default function CompanyFields({
  selectedContact,
  editContactManagementProperty,
}) {
  const [showCompanyFields, setShowCompanyFields] = useState(false);
  const [isEditLeadC, setIsEditLeadC] = useState(false);

  //filed data
  const [cName, setCName] = useState("");
  const [cAddress1, setCAddress1] = useState("");
  const [cAddress2, setCAddress2] = useState("");
  const [cCity, setCCity] = useState("");
  const [cState, setCState] = useState("");
  const [cZip, setCZip] = useState("");

  useEffect(() => {
    loadFieldData(selectedContact.primary_data[0]);
  }, [selectedContact]);

  const loadFieldData = (lead) => {
    const {
      companyAddress1,
      companyAddress2,
      companyCity,
      companyName,
      companyState,
      companyZip,
    } = lead;
    setCAddress1(companyAddress1);
    setCAddress2(companyAddress2);
    setCCity(companyCity);
    setCName(companyName ? companyName : "");
    setCState(companyState);
    setCZip(companyZip);
  };

  const handleAddressChange = (event, changeFunc) => {
    changeFunc(event.target.value);
    setIsEditLeadC(true);
  };

  const editCompanyData = () => {
    const allData = {
      _id: selectedContact.primary_data[0]._id,
      companyAddress1: cAddress1,
      companyAddress2: cAddress2,
      companyCity: cCity,
      companyName: cName,
      companyState: cState,
      companyZip: cZip === null ? null : cZip,
    };

    return allData;
  };

  const handleOnClickEditContactInformaton = () => {
    editContactManagementProperty({
      ...editCompanyData(),
    });

    setIsEditLeadC(false);
  };

  return (
    <Row style={styles.header}>
      <Col md="4">
        <h4 style={styles.heading}>
          <Link
            to={{
              pathname: "/dashboard/help",
              search: "Dashboard New Lead",
              hash: "#the-hash",
              state: "Dashboard New Lead",
            }}
            target="_blank"
            rel="noreferrer"
            style={{ cursor: "pointer" }}
          ></Link>
        </h4>
      </Col>
      <Col md="8 text-end">
        <strong
          id="companyBtn"
          onClick={() => setShowCompanyFields((prev) => !prev)}
          style={{
            color: Color.primaryColor,
            float: "right",
            cursor: "pointer",
            position: "relative",
            top: "4.2rem",
          }}
        >
          {showCompanyFields ? `Hide ` : `Show `}Company Fields
        </strong>
      </Col>
      <Col xs="12" md="12" className={`div ${showCompanyFields && "_show"}`}>
        <h5 style={{ padding: "25px 0" }}>Company Contact Information</h5>
        {isEditLeadC && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              type="button"
              className=" btn btn-success mb-4 ms-4"
              onClick={() => handleOnClickEditContactInformaton("C")}
            >
              Save
            </button>
            <button
              type="button"
              className=" btn btn btn-danger mb-4 ms-4 "
              onClick={() => {
                setIsEditLeadC(false);
                loadFieldData(selectedContact.primary_data[0]);
              }}
            >
              Cancel
            </button>
          </div>
        )}
        <Row>
          <Col xs="12" md="6">
            <Form.Group style={styles.formInput}>
              <Form.Label>Company Name </Form.Label>
              <Form.Control
                type="text"
                name="cName"
                value={cName}
                placeholder="Enter company name"
                onChange={(event) => {
                  setCName(event.target.value);
                  setIsEditLeadC(true);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <CommonAddressFields
            address1Name="cAddress1"
            address1={cAddress1}
            address2Name="cAddress2"
            address2={cAddress2}
            cityName="cCity"
            city={cCity}
            stateName="cState"
            state={cState}
            zipName="cZip"
            zip={cZip}
            setAddress1={setCAddress1}
            setAddress2={setCAddress2}
            setCity={setCCity}
            setState={setCState}
            setZip={setCZip}
            handleChange={handleAddressChange}
          />
        </Row>
      </Col>
    </Row>
  );
}
