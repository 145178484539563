import React, { useState } from 'react';
import { faSyncAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SmRetryBtn({
  retry,
}) {
  const [trying, setTrying] = useState(false);

  const tryAgain = () => {
    setTrying(true);
    retry();
  };

  return trying ? (
    <span className="sm-btn" title="Retrying. Please wait!">
      <FontAwesomeIcon
        color="#fff"
        className="ico spinner"
        icon={faSyncAlt}
      />
    </span>
  ) : (
    <span
      style={{ fontSize: '1.5rem' }}
      role="button"
      title="Error while saving data. Please Tap to retry!"
      onClick={() => tryAgain()}
    >
      <FontAwesomeIcon
        color="red"
        className="ico"
        icon={faExclamationTriangle}
      />
    </span>
  );
}
