import axios from "axios";

import AuthService from "./auth-service";
import settings from "lib/config/baseSettings";
import baseSettings from "lib/config/baseSettings";
import store from "lib/redux/store";
// import useFetchData from "./newOfflineFetch";

const axiosConfig = {
  baseURL: settings.baseUrl,
  timeout: baseSettings.axiosTimeout,
  timeoutErrorMessage: "Sorry! Unable to perform operation. Please try again.",
};

const APItoCache = ["http://localhost:4001/admin/permission/getAllPermission"];

//check if the API is cached and is okay to serve offline
const checkMatch = (url) => {
  for (const value of APItoCache) {
    if (url === value) {
      return true;
    }
  }
  return false;
};

const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use(async (config) => {
  const token = await AuthService.getToken();

  if (token) {
    config.headers["x-access-token"] = `${token}`;
  }

  return config;
});

axiosInstance.interceptors.request.use((config) => {
  config.headers["ngrok-skip-browser-warning"] = "true";
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  const { config } = response;
  const { method, url } = config;

  // const { isFetching, fetchOfflineData } = useFetchData();

  const updatingURL = [
    { url: /\/metrics/, method: "post" },
    { url: /\/activity\/changeStage/, method: "post" },
    { url: /\/quote/, method: "put" },
    { url: /\/quote/, method: "post" },
    { url: /\//, method: "delete" },
    { url: /\/quote\/proposal/, method: "get" },
  ];
  const checkUrl = () => {
    for (const req of updatingURL) {
      if (req.url.test(url) && req.method === method) {
        return true;
      }
    }
    return false;
  };
  // if (checkUrl()) {
  //   fetchOfflineData();
  // }

  return response;
});

axiosInstance.interceptors.request.use((config) => {
  /* some logic */
  const controller = new AbortController();

  if (!navigator.onLine) {
    controller.abort();
  }

  return {
    ...config,
    signal: controller.signal,
  };
});

export default axiosInstance;
