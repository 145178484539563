import React from 'react'
import moment from "moment";
import Next from "../../assets/right.svg";
import Prev from "../../assets/left.svg";
import { getWeekDate } from 'components/calendar/utils';

const RepairWeekView = ({
  onPrevWeek,
  onNextWeek,
  weekdaysShort,
  dateObject,
  weekText,
  daysInWeekView,
}) => {

  const renderWeekDays = () => {
    const today = moment().format("l");
    return weekdaysShort.map((day, i) => {
      const dateString = new Date(getWeekDate(i, dateObject))
        .toDateString()
        .split(" ");
      return (
        <th
          key={day}
          className={`week-day${today === getWeekDate(i, dateObject) ? " today " : ""
            } _week_view_th`}
        >
          <div className='text-sm'>{day}</div>
          <div className="date text-sm">{`${dateString[1]} ${Number(
            dateString[2]
          )}`}</div>
        </th>
      );
    });
  };

  const BtnIcon = ({ src, alt }) => (
    <img src={src} height="25" width="25" alt={alt} />
  );
  return (
    <div>
    <div className="_heading flex justify-between items-center p-4">
        <div>
          <button onClick={onPrevWeek}>
            <BtnIcon src={Prev} alt="prev" />
          </button>
        </div>
        <div className='text-sm'>
          {weekText}
        </div>
        <div>
          <button onClick={onNextWeek}>
            <BtnIcon src={Next} alt="next" />
          </button>
        </div>
      </div>
      <table
        className="_calendar_table_week"
        cellPadding={0}
      >
        <thead>
          <tr className='border border-sky-500 h-10'>
            {renderWeekDays()}
          </tr>
        </thead>
        <tbody>
          <tr
            style={{
              height: "8em",
            }}
            className='border border-sky-500 h-10'
          >
            {daysInWeekView()}
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default RepairWeekView