import React, { Component } from 'react';
import { Container, Button, Form, Row, Col, Alert } from 'react-bootstrap';
import Logo from 'components/Logo';
import { clog } from 'utils/utils';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { axios } from 'services';

const newStyle = {
	fullHt: {
		minHeight: '100vh',
	},
	loginContent: {
		margin: 'auto',
		paddingBottom: '15px',
		paddingTop: '15px',
		background: 'white',
		borderRadius: '5px',
		boxShadow: '5px 5px 5px 0px #88888863',
	},
	formStyle: {
		marginTop: '5%',
	},
	formInput: {
		marginBottom: '15px',
	},
	forgotButton: {
		paddingLeft: '0px',
	},
};

class ForgotPass extends Component {
	constructor(props) {
		super(props);
		this.state = {
			msgTxt: 'Reset your password here.',
			msgType: 'primary', // primary || secondary || success || danger || warning || info
		};
		this.onSubmit = this.onSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	onSubmit(e) {
		e.preventDefault();
		clog('@handle');
		const { password, password2 } = this.state;
		const { email, otp } = this.props.match.params;
		clog(email);
		clog(otp);
		clog(password);
		clog(password2);
		if (password === password2) {
			return axios
				.post(`/setnewpassword`, {
					email,
					newpassword: password,
					otp,
				})
				.then((res) => res.data)
				.then((data) => {
					clog(data);
					if (data.success) {
						this.setState({
							msgTxt:
								'Your password has been changed successfully. Please login to continue.',
							msgType: 'success',
						});
					} else {
						this.setState({
							msgTxt: data.error,
							msgType: 'danger',
						});
					}
				})
				.catch((err) => {
					clog(err.message);
					this.setState({
						msgTxt: err.message,
						msgType: 'danger',
					});
				});
		} else {
			this.setState({
				msgTxt: 'Password and Confirm password mismatch.',
				msgType: 'danger',
			});
		}
	}

	submitForgotPass(re) {
		this.setState({ forgotPassView: false });
		toast(re);
	}

	render() {
		const { msgTxt, msgType } = this.state;
		return (
			<Container>
				<Row className='justify-content-md-center' style={newStyle.fullHt}>
					<Col xs='12' md='6' lg='4' style={newStyle.loginContent}>
						<Logo />
						<Form onSubmit={this.onSubmit} style={newStyle.formStyle}>
							{msgTxt && <Alert variant={msgType}>{msgTxt}</Alert>}

							<Form.Group style={newStyle.formInput}>
								<Form.Label>Password</Form.Label>
								<Form.Control
									type='password'
									placeholder='Enter here'
									name='password'
									required='required'
									onChange={(event) => this.handleChange(event)}
								/>
							</Form.Group>

							<Form.Group style={newStyle.formInput}>
								<Form.Label>Confirm Password</Form.Label>
								<Form.Control
									type='password'
									placeholder='Enter here'
									name='password2'
									required='required'
									onChange={(event) => this.handleChange(event)}
								/>
							</Form.Group>

							<Form.Group style={newStyle.formInput}>
								<Button type='submit' variant='success'>
									Submit
								</Button>
							</Form.Group>

							<div style={newStyle.formInput}>
								<Button variant='link' style={newStyle.forgotButton}>
									<Link to='/login'>Back to Login</Link>
								</Button>
							</div>
						</Form>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default ForgotPass;
