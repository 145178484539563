import { axiosInstance as axios } from "services";
import { getEndPoints } from "api/api";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const url = getEndPoints(process.env.REACT_APP_MODE);

const useProjectData = (id) => {
  const [unscheduledProject, setUnscheduledProject] = useState([]);
  const [supplier, setSupplier] = useState({});
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [scheduledProject, setScheduledProject] = useState([]);
  const [afterSchedule, setAfterSchedule] = useState(false);
  const [unSchdeule, setUnSchedule] = useState(false);
  const [updateProject, setUpdateProject] = useState(false);
  const [error, setError] = useState("");
  const [notes, setNotes] = useState([]);
  const [saveNotes, setSaveNotes] = useState();
  const [deleteNotes, setDeleteNotes] = useState();
  const [updateProjectData, setUpdateProjectData] = useState();
  const [updateSupplier, setUpdateSupplier] = useState();
  const [changeOrderData, setChangeOrderData] = useState();
  const [installment, setInstallment] = useState();
  const [deposit, setDeposit] = useState();
  const [indivisualNoteEdit, setIndivisualNoteEdit] = useState();
  const [installmentEditOnly, setInstallmentEditOnly] = useState();
  const [isAddOrDelete, setIsAddOrDelete] = useState(false);

  let fetch_un_scheduled_events = () => {
    setIsLoading(true);
    return axios
      .get(`${url}projects/getAllUnScheduledEvents`)
      .then((response) => {
        if (response.data.success) {
          setUnscheduledProject(response.data.result);
          return { unscheduledProject: response.data.result };
        } else {
          setUnscheduledProject([]);
          toast.error(response.data.error);
          return { unScheduledProject: [] };
        }
      })
      .catch((err) => {
        setIsError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  let fetch_scheduled_events = () => {
    setIsLoading(true);

    return axios
      .get(`${url}projects/getAllScheduledEvents`)
      .then((response) => {
        if (response.data.success) {
          setScheduledProject(response.data.result);
          return { scheduledProject: response.data.result };
        } else {
          toast.error(response.data.error);
          setScheduledProject([]);
          return { scheduledProject: [] };
        }
      })
      .catch((err) => {
        setIsError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  let fetch_supplier = () => {
    setIsLoading(true);
    return axios
      .get(`${url}projects/getSuppliers`)
      .then((response) => {
        if (response.data.success) {
          setSupplier(response.data.result);
        } else {
          toast.error(response.data.error);
          setSupplier([]);
        }
      })
      .catch((err) => {
        setIsError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // if(card_details_url === "projects/getSuppliers"){
  //   return {supplier,isLoading,isError}
  // }if(card_details_url === "projects/getAllUnScheduledEvents"){
  //   return { unscheduledProject, isLoading, isError };
  // }if(card_details_url === "projects/getAllScheduledEvents"){
  //   return { scheduledProject, isLoading, isError}
  // }

  const useScheduleProject = (data) => {
    return axios
      .patch(`${url}projects/schedule`, { ...data })
      .then(async (response) => {
        if (response.data.success) {
          setAfterSchedule(response.data.success);
          let data1 = await fetch_un_scheduled_events();
          let data2 = await fetch_scheduled_events();
          return { data1, data2 };
        } else {
          toast.error(response.data.error);
          setAfterSchedule(false);
        }
        if (response.data.error === false) {
          return toast.error("No Crew Available...");
        }
      })
      .catch((error) => {
        setIsError(error);
      });
  };

  const useReScheduleProject = (data) => {
    return axios
      .patch(`${url}projects/unSchedule`, { ...data })
      .then(async (response) => {
        if (response.data.success) {
          setUnSchedule(response.data.success);
          // window.location.reload()
          let data1 = await fetch_un_scheduled_events();
          let data2 = await fetch_scheduled_events();
          return { data1, data2 };
        } else {
          toast.error(response.data.error);
          setUnSchedule(false);
        }
      })
      .catch((error) => {
        setIsError(error);
      });
  };

  const useUpdateProject = (data) => {
    return axios
      .patch(`${url}projects/updateProject`, { ...data })
      .then(async (response) => {
        if (response.data.success) {
          setUpdateProject(response.data.success);
          // window.location.reload()
          let data1 = await fetch_un_scheduled_events();
          let data2 = await fetch_scheduled_events();
          return { data1, data2 };
        } else {
          toast.error(response.data.error);
          setUpdateProject(false);
        }
      })
      .catch((error) => {
        setIsError(error);
      });
  };

  const getNotesForPM = (id) => {
    setIsLoading(true);
    return axios
      .post(`${url}projects/getNotesForOneProject`, { ...id })
      .then((response) => {
        if (response.data.success) {
          setNotes(response.data.result);
          setIsLoading(false);
          return { response: response.data.result };
        } else {
          //toast.error(response.data.error);
          return { response: [] };
        }
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);
          setError(error.response.message | "Something went wrong...");
          return { error: error.response };
        }
      });
  };

  const saveProjectNotes = (body) => {
    setIsLoading(true);
    setIsAddOrDelete(true);
    return axios
      .post(`${url}projects/addNotes`, { ...body })
      .then((response) => {
        if (response.data.success) {
          setSaveNotes(response.data.result);
          setIsLoading(false);
          toast.success(" A new note added successfully...!");
        } else {
          toast.error(response.data.error);
        }
        setIsAddOrDelete(false);
        return { error: null, response };
      })
      .catch((error) => {
        setIsAddOrDelete(false);
        if (error.response) {
          setIsLoading(false);
          setError(error.response.message | "Something went wrong...");
          return { error: error.response };
        }
      });
  };

  const deleteProjectNotes = (id) => {
    setIsLoading(true);
    setIsAddOrDelete(true);
    return axios
      .post(`${url}projects/deleteIndivisualNotes`, { ...id })
      .then((response) => {
        setIsAddOrDelete(false);
        if (response.data.success) {
          setDeleteNotes(response.data.result);
          setIsLoading(false);
          toast.success(" Successfully deleted a note..!");
        } else {
          toast.error(response.data.error);
        }
        return { error: null, response };
      })
      .catch((error) => {
        setIsAddOrDelete(false);
        if (error.response) {
          setIsLoading(false);
          setError(error.response.message | "Something went wrong...");
          return { error: error.response };
        }
      });
  };

  const updateProjectScheduleDetails = (data) => {
    setIsLoading(true);
    return axios
      .post(`${url}projects/addOrUpdateProjectScheduleDetails`, { ...data })
      .then((response) => {
        if (response.data.success) {
          setUpdateProjectData(response.data.result);
          setIsLoading(false);
        } else {
          toast.error(response.data.error);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);
          setError(error.response.message | "Something went wrong...");
          return { error: error.response };
        }
      });
  };
  const updateProjectSupplier = (data) => {
    setIsLoading(true);
    return axios
      .post(`${url}projects/updateSuppliers`, { ...data })
      .then((response) => {
        if (response.data.success) {
          setUpdateSupplier(response.data.result);
          setIsLoading(false);
        } else {
          toast.error(response.data.error);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);
          setError(error.response.message | "Something went wrong...");
          return { error: error.response };
        }
      });
  };

  const changeOrderUpdate = (data) => {
    setIsLoading(true);
    return axios
      .post(`${url}projects/addChangeOrder`, { ...data })
      .then((response) => {
        if (response.data.success) {
          setChangeOrderData(response.data.result);
          setIsLoading(false);
          toast.success(" Saved successfully...!");
        } else {
          toast.error(response.data.error);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);
          setError(error.response.message | "Something went wrong...");
          return { error: error.response };
        }
      });
  };

  const editChangeOrderForSingleOrder = (data) => {
    setIsLoading(true);
    return axios
      .post(`${url}projects/editChangeOrder`, { ...data })
      .then((response) => {
        if (response.data.success) {
          // setChangeOrderData(response.data.result);
          setIsLoading(false);
          toast.success(" Updated successfully...!");
        } else {
          toast.error(response.data.error);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);
          setError(error.response.message | "Something went wrong...");
          return { error: error.response };
        }
      });
  };

  const projectInstallmentDetails = (data) => {
    setIsLoading(true);
    return axios
      .post(`${url}projects/addInstallments `, { ...data })
      .then((response) => {
        if (response.data.success) {
          toast.success(" A new installment added");
          setInstallment(response.data.result);
          setIsLoading(false);
        } else {
          toast.error(response.data.error);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);
          setError(error.response.message | "Something went wrong...");
          return { error: error.response };
        }
      });
  };

  const updateDepositData = (data) => {
    setIsLoading(true);
    return axios
      .post(`${url}projects/addOrUpdatePayment`, { ...data })
      .then((response) => {
        if (response.data.success) {
          setDeposit(response.data.result);
          setIsLoading(false);
        } else {
          toast.error(response.data.error);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);
          setError(error.response.message | "Something went wrong...");
          return { error: error.response };
        }
      });
  };

  const getNotesEdit = (data) => {
    setIsLoading(true);
    return axios
      .post(`${url}projects/editIndivisualNotes`, { ...data })
      .then((response) => {
        if (response.data.success) {
          toast.success(" Note Updated Successfully");
          setIndivisualNoteEdit(response.data.result);
          setIsLoading(false);
        } else {
          toast.error(response.data.error);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);
          setError(error.response.message | "Something went wrong...");
          return { error: error.response };
        }
      });
  };

  const getInstallmentEdit = (data) => {
    setIsLoading(true);
    return axios
      .post(`${url}projects/editInstallments`, { ...data })
      .then((response) => {
        if (response.data.success) {
          toast.success(" Installment Updated Successfully");
          setInstallmentEditOnly(response.data.result);
          setIsLoading(false);
        } else {
          toast.error(response.data.error);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);
          setError(error.response.message | "Something went wrong...");
          return { error: error.response };
        }
      });
  };

  return {
    fetch_un_scheduled_events,
    fetch_scheduled_events,
    fetch_supplier,
    unscheduledProject,
    setUnscheduledProject,
    scheduledProject,
    supplier,
    isLoading,
    isError,
    useScheduleProject,
    useReScheduleProject,
    useUpdateProject,
    updateProject,
    afterSchedule,
    unSchdeule,
    setScheduledProject,
    setIsLoading,
    getNotesForPM,
    notes,
    setNotes,
    saveProjectNotes,
    saveNotes,
    setSaveNotes,
    deleteProjectNotes,
    deleteNotes,
    setDeleteNotes,
    updateProjectScheduleDetails,
    updateProjectData,
    setUpdateProjectData,
    updateProjectSupplier,
    updateSupplier,
    changeOrderUpdate,
    changeOrderData,
    projectInstallmentDetails,
    installment,
    updateDepositData,
    deposit,
    getNotesEdit,
    indivisualNoteEdit,
    installmentEditOnly,
    getInstallmentEdit,
    editChangeOrderForSingleOrder,
    isAddOrDelete,
  };
};

export default useProjectData;
