import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import styles from "lib/config/baseStyles";

export default function SelectSuppliers({
  selectedSupplier,
  onSelectSupplier,
  loading,
  suppliers,
  emptyText,
  SupplierItem,
  isLockQuoteData,
}) {
  const IsPreferedData = suppliers.filter((data) => {
    return data._id === selectedSupplier;
  });
  return (
    <Col style={styles.reduceWidthCol} lg="6" md="12">
      <Col
        lg="10"
        md="12"
        style={{ ...styles.reduceWidthCol, paddingLeft: "0" }}
      >
        <Form.Group>
          <Form.Control
            as="select"
            value={selectedSupplier}
            onChange={onSelectSupplier}
            style={
              IsPreferedData[0]?.isPreferred
                ? {}
                : { background: "rgb(246, 212, 111)" }
            }
            disabled={isLockQuoteData?.isLocked}
          >
            <option value="">{loading ? "Loading..." : emptyText}</option>
            <SuppliersList suppliers={suppliers} SupplierItem={SupplierItem} />
          </Form.Control>
        </Form.Group>
      </Col>
    </Col>
  );
}

export function SelectSuppliersSection({ children }) {
  return (
    <Row>
      {children}

      <Col style={{ ...styles.reduceWidthCol, textAlign: "center" }}>
        <Form.Label>UOM</Form.Label>
      </Col>
      <Col style={{ ...styles.reduceWidthCol, textAlign: "center" }}>
        <Form.Label>QTY</Form.Label>
      </Col>
      <Col style={{ ...styles.reduceWidthCol, textAlign: "center" }}>
        <Form.Label>Price Per UOM</Form.Label>
      </Col>

      <Col style={{ ...styles.reduceWidthCol, textAlign: "right" }}>
        <Form.Label>Total</Form.Label>
      </Col>
    </Row>
  );
}

export function SuppliersList({ suppliers, SupplierItem }) {
  return (
    suppliers.length > 0 &&
    suppliers.map((supplier) => (
      <SupplierItem key={supplier._id} supplier={supplier} />
    ))
  );
}

export function SupplierItemOnlyName({ supplier }) {
  return <SelectOption value={supplier._id} display={supplier.name} />;
}

export function SupplierItem({ supplier }) {
  return (
    <SelectOption
      value={supplier._id}
      display={`${supplier.name} - ${supplier.locOrBranch}`}
    />
  );
}

export function SelectOption({ value, display }) {
  return <option value={value}>{display}</option>;
}

export const getOptions = (options) =>
  options.map((option) => (
    <SelectOption key={option} value={option} display={option} />
  ));
