import { ApiEndpoints } from "lib/config/baseSettings";
import { calendarActionTypes } from "./calendar.types";

import { axiosInstance } from "services";
export const CalendarStart = () => ({
  type: calendarActionTypes.CALENDAR_START,
});

export const CalendarSuccess = (data) => ({
  type: calendarActionTypes.CALENDAR_SUCCESS,
  payload: data,
});

export const CalendarFailure = (errors) => ({
  type: calendarActionTypes.CALENDAR_FAILURE,
  payload: errors,
});

export const fetchForCalendar = () => {
  return (dispatch) => {
    dispatch(CalendarStart());
    if (!navigator.onLine) {
      let stored_data = JSON.parse(
        localStorage.getItem("persist:persist-store")
      );
      if (!stored_data) {
        return dispatch(CalendarFailure("No data found"));
      }
      let calendar_stored_data = JSON.parse(stored_data.calendarInputs);
      return dispatch(
        CalendarSuccess({ result: calendar_stored_data.data, success: true })
      );
    } else {
      return axiosInstance
        .get(ApiEndpoints.CALENDAR.FETCH_CALENDAR)
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            return dispatch(CalendarSuccess(data));
          } else {
            return dispatch(CalendarFailure(data.error));
          }
        })
        .catch((errors) => {
          console.log({ errorsCalendar: errors });
          dispatch(CalendarFailure(errors.message));
        });
    }
  };
};

export const clearAvailablePersons = () => {
  return (dispatch) =>
    dispatch({ type: calendarActionTypes.AVAILABLE_PERSON_CLEAR });
};

export const fetchAvailabelPersons = (data) => {
  return (dispatch) => {
    dispatch({ type: calendarActionTypes.AVAILABLE_PERSON_START });

    return axiosInstance
      .post(ApiEndpoints.CALENDAR.GET_AVAILABLE_PERSONS, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: calendarActionTypes.AVAILABLE_PERSON_SUCCESS,
            payload: data,
          });
        } else {
          return dispatch({
            type: calendarActionTypes.AVAILABLE_PERSON_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: calendarActionTypes.AVAILABLE_PERSON_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const addToCalendar = (data) => {
  return (dispatch) => {
    dispatch({ type: calendarActionTypes.ADD_TO_CALENDAR_START });

    return axiosInstance
      .post(ApiEndpoints.CALENDAR.SCHEDULE_EVENT, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: calendarActionTypes.ADD_TO_CALENDAR_SUCCESS,
            payload: data,
          });
        } else {
          return dispatch({
            type: calendarActionTypes.ADD_TO_CALENDAR_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: calendarActionTypes.ADD_TO_CALENDAR_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const fetchEvents = () => {
  return (dispatch) => {
    dispatch({ type: calendarActionTypes.FETCH_EVENTS_START });
    if (!navigator.onLine) {
      let stored_data = JSON.parse(
        localStorage.getItem("persist:persist-store")
      );
      let calendar_fetch_stored_data = JSON.parse(stored_data.calendarEvents);
      return dispatch({
        type: calendarActionTypes.FETCH_EVENTS_SUCCESS,
        payload: { result: calendar_fetch_stored_data.events },
      });
    } else {
      return axiosInstance
        .get(ApiEndpoints.CALENDAR.FETCH_EVENTS)
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            return dispatch({
              type: calendarActionTypes.FETCH_EVENTS_SUCCESS,
              payload: data,
            });
          } else {
            return dispatch({
              type: calendarActionTypes.FETCH_EVENTS_FAILURE,
              payload: data.error,
            });
          }
        })
        .catch((errors) => {
          console.log({ errors });
          return dispatch({
            type: calendarActionTypes.FETCH_EVENTS_FAILURE,
            payload: errors.message,
          });
        });
    }
  };
};

export const deleteEvent = (data) => {
  return (dispatch) => {
    dispatch({ type: calendarActionTypes.DELETE_EVENTS_START });

    return axiosInstance
      .post(ApiEndpoints.CALENDAR.UNSCHEDULE_EVENT, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: calendarActionTypes.DELETE_EVENTS_SUCCESS,
            payload: data,
          });
        } else {
          return dispatch({
            type: calendarActionTypes.DELETE_EVENTS_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: calendarActionTypes.DELETE_EVENTS_FAILURE,
          payload: errors.message,
        })
      );
  };
};
