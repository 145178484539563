import React, { Component, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Form,
  Modal,
  InputGroup,
} from "react-bootstrap";

import RequiredText from "components/required-text/required-text";
import validator from "validator";

import MaskedInput from "react-maskedinput";

import Color from "lib/constants/color";
import PopupModal from "components/popup-modal/popup-modal";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { styles } from "../styles";
import CommonPhoneAndEmailFields from "components/CommonPhoneAndEmailFields/CommonPhoneAndEmailFields";
import CommonAddressFields from "components/CommonAddressFields/CommonAddressFields";
import {
  DisplayFooter,
  DisplayNewInstallationOption,
  DisplayNewRepairOptions,
  DisplayServiceRequestOption,
} from "./Modals";
import { uuid } from "uuidv4";

const SubScriptText = ({ children }) => (
  <sub style={{ color: "#999" }}>({children})</sub>
);

export default function SiteContact_Property({
  selectedContact,
  editContactManagementProperty,
  contactTypes,
  relationTypess,
  chosenProperty,
  newInstallationType,
  repairType,
  tasksRate,
  coopUnits,
  setShowCoopModal,
  editLeadLoading,
  EditLead,
  setCurrentAction,
  addingProperty,
  AddContactManagementProperty,
  scrollToTop,
  selectedLeadId,
  setAddProperty,
  setChosenProperty,
  addingService,
  AddNewSvcRequestToProperty,
}) {
  const { propertyTypess } = contactTypes;

  // #region states
  const [showSiteContactInfo, setShowSiteContactInfo] = useState(false);

  const [pcAddress1, setPcAddress1] = useState("");
  const [pcAddress2, setPcAddress2] = useState("");
  const [pcCity, setPcCity] = useState("");
  const [pcState, setPcState] = useState("");
  const [pcZip, setPcZip] = useState("");

  const [pName, setPName] = useState("");
  const [pType, setPType] = useState("");

  const [primaryAddressChecked, setPrimaryAddressChecked] = useState(false);

  const [leadSource, setLeadSource] = useState("");
  const [leadSourceText, setLeadSourceText] = useState("");

  const [phoneAndEmail, setPhoneAndEmail] = useState({
    phoneType: "",
    pNumber: "",
    phoneType2: "",
    pNumber2: "",
    phoneType3: "",
    pNumber3: "",
    emailType: "",
    email: "",
    emailType2: "",
    email2: "",
    emailType3: "",
    email3: "",
  });

  const [relationshipData, setRelationshipData] = useState({
    relationship: "",
    fname: "",
    lname: "",
  });

  const [showContact, setShowContact] = useState({
    showPhone2: false,
    showPhone3: false,
    showEmail2: false,
    showEmail3: false,
  });

  const [showServiceRequestModal, setShowServiceRequestModal] = useState(false);
  const [serviceRequestModalType, setServiceRequestModalType] = useState("");

  const [pNotes, setPNotes] = useState("");
  const [isActive, setIsActive] = useState(false);

  const [isRepair, setIsRepair] = useState(null);
  const [repairSelect, setRepairSelect] = useState({
    repair01: false,
    repair02: false,
    repair03: false,
    repair04: false,
    repair05: false,
    repair06: false,
  });

  const [repairDetails, setRepairDetails] = useState({
    requestForOther: "",
    originalWorkDone: "",
    originalWorkYear: "",
    underWarrenty: "",
    servicePrice: "",
    taxRate: "",
    totalPrice: "",
    repairDescreption: "",
  });

  const [saleSelect, setSaleSelect] = useState({
    sales01: false,
    sales02: false,
  });

  const [showNext, setShowNext] = useState(false);
  // #endregion

  // #region effects
  useEffect(() => {
    if (!selectedContact.primary_data || chosenProperty === "") return;
    loadPropertyFieldData(selectedContact.primary_data[0]);
    setShowNext(false);
  }, [selectedContact, chosenProperty]);

  useEffect(() => {
    if (primaryAddressChecked) {
      const {
        primaryAddress1,
        primaryAddress2,
        primaryCity,
        primaryState,
        primaryZip,
      } = selectedContact.primary_data[0];

      setPcAddress1(primaryAddress1);
      setPcAddress2(primaryAddress2);
      setPcCity(primaryCity);
      setPcState(primaryState);
      setPcZip(primaryZip);

      handleOnClickEditContactInformaton();
    }
  }, [primaryAddressChecked]);

  useEffect(() => {
    const { property, request } = chosenProperty;
    if (!addingProperty && ((!property && property !== 0) || property === "")) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [addingProperty, chosenProperty]);
  // #endregion

  // #endregion

  // #region function
  const changePhoneAndEmailDetail = (value) => {
    setPhoneAndEmail((prev) => ({ ...prev, ...value }));
  };

  //WARNING: PROBLEM FRONT END IMPLEMENTATION FOR BACKEND ISSUES
  const checkIfCoop = (pType) => {
    const searchId = propertyTypess.propertydata.filter((value) => {
      if (value.roleType === "Condo/Coop" && value._id === pType) {
        return true;
      }
      return false;
    });

    return searchId.length > 0;
  };

  const isValidated = () => {
    if (pcAddress1 === "") {
      // setErrMsg("Primary Contact Address 1 is required.");
      // setErrAt("pcAddress1");
      // setErrType("danger");

      return false;
    } else if (pcCity === "") {
      // setErrMsg("Primary Contact City is required.");
      // setErrAt("pcCity");
      // setErrType("danger");
      return false;
    } else if (pcState === "") {
      // setErrMsg("Primary Contact State is required.");
      // setErrAt("pcState");
      // setErrType("danger");

      return false;
    } else if (pcZip === "") {
      // setErrMsg("Primary Contact Zip is required");
      // setErrAt("pcZip");
      // setErrType("danger");

      return false;
    } else if (!validator.isNumeric(pcZip.toString())) {
      // setErrMsg("Primary Contact Zip should have only numbers.");
      // setErrAt("pcZip");
      // setErrType("warning");

      return false;
    } else if (pcZip !== "" && pcZip.length < 5) {
      // setErrMsg("Primary Contact Zip should have 5 digits.");
      // setErrAt("pcZip");
      // setErrType("warning");
      return false;
    } else if (selectedContact.primary_data[0].leadSource === "") {
      // setErrMsg("Lead Source is required.");
      // setErrAt("leadSource");
      // setErrType("danger");
      return false;
    } else if (
      // showLeadSourceText(leadSourcesType, leadSource) &&
      false &&
      leadSourceText === ""
    ) {
      // setErrMsg("Referrer Name is required.");
      // setErrAt("leadSourceText");
      // setErrType("danger");

      return false;
    } else if (pType === "") {
      // setErrMsg("Please Select property type.");
      // setErrAt("pType");
      // setErrType("danger");

      return false;
    } else if (isRepair === null) {
      // setErrMsg("Please Select a service request..");
      // setErrAt("");
      // setErrType("danger");

      return false;
    }
    return true;
  };

  const addProperty = () => {
    if (isValidated()) {
      const dataa = getAllFields();
      AddContactManagementProperty({ ...dataa });
    }

    //dismissMessage();
    setShowNext(false);
    setAddProperty(false);
    scrollToTop();
  };

  const loadPropertyFieldData = () => {
    const { property, request } = chosenProperty;
    if (property === "") {
      clearAll();
      return;
    }

    if (!selectedContact.property_list[property]) {
      clearAll();
      return;
    }

    const {
      propertyContactRelationship,
      propertyContactFirstName,
      propertyContactLastName,
      propertyContactPhone1Type,
      propertyContactPhone1,
      propertyContactPhone2Type,
      propertyContactPhone2,
      propertyContactEmail1Type,
      propertyContactEmail1,
      propertyContactEmail2Type,
      propertyContactEmail2,
      propertyAddressSameAsPrimaryAddress,
      propertyName,
      propertyAddress1,
      propertyAddress2,
      propertyCity,
      propertyState,
      propertyZip,
      propertyType,
      propertyNotes,
      leadSource,
      leadSourceText,
    } = selectedContact.property_list[property].property_address[0];

    setPcAddress1(propertyAddress1);
    setPcAddress2(propertyAddress2);
    setPcCity(propertyCity);
    setPcState(propertyState);
    setPcZip(propertyZip);

    setLeadSource(leadSource);
    setLeadSourceText(leadSourceText);

    setPrimaryAddressChecked(propertyAddressSameAsPrimaryAddress);
    setPName(propertyName);
    setPNotes(propertyNotes);
    setPType(propertyType);

    setRelationshipData({
      relationship: propertyContactRelationship,
      lname: propertyContactLastName,
      fname: propertyContactFirstName,
    });

    setPhoneAndEmail({
      phoneType: propertyContactPhone1Type,
      pNumber: propertyContactPhone1,
      phoneType2: propertyContactPhone2Type,
      pNumber2: propertyContactPhone2,

      emailType: propertyContactEmail1Type,
      email: propertyContactEmail1,
      emailType2: propertyContactEmail2Type,
      email2: propertyContactEmail2,
    });

    if (request === "" || !request) {
      clearDetails();
      return;
    }

    const {
      requestForOther,
      originalWorkDone,
      originalWorkYear,
      underWarrenty,
      servicePrice,
      taxRate,
      totalPrice,
      repairDescreption,
    } = request;

    if (request.isRepairRequest) {
      setIsRepair(true);
      setRepairDetails({
        requestForOther,
        originalWorkDone,
        originalWorkYear,
        underWarrenty,
        servicePrice,
        taxRate,
        totalPrice,
        repairDescreption,
      });
      let temp_repair_select = {
        repair01: false,
        repair02: false,
        repair03: false,
        repair04: false,
        repair05: false,
        repair06: false,
      };
      request.requestFor.map((value) => {
        temp_repair_select[value] = true;
      });
      setRepairSelect(temp_repair_select);
    } else {
      setIsRepair(false);

      let temp_sale_select = { sales01: false, sales02: false };
      request.requestFor.map((value) => {
        temp_sale_select[value] = true;
      });
      setSaleSelect(temp_sale_select);
    }
  };

  const clearDetails = () => {
    const requestForOther = "",
      originalWorkDone = "",
      originalWorkYear = "",
      underWarrenty = "",
      servicePrice = "",
      taxRate = "",
      totalPrice = "",
      repairDescreption = "";

    setIsRepair(null);
    setRepairDetails({
      requestForOther,
      originalWorkDone,
      originalWorkYear,
      underWarrenty,
      servicePrice,
      taxRate,
      totalPrice,
      repairDescreption,
    });

    setRepairSelect({
      repair01: false,
      repair02: false,
      repair03: false,
      repair04: false,
      repair05: false,
      repair06: false,
    });

    let temp_sale_select = { sales01: false, sales02: false };

    setSaleSelect(temp_sale_select);
  };

  const clearAll = () => {
    setPcAddress1("");
    setPcAddress2("");
    setPcCity("");
    setPcState("");
    setPcZip("");

    setLeadSource("");
    setLeadSourceText("");

    setPrimaryAddressChecked(false);
    setPName("");
    setPNotes("");
    setPType("");

    setRelationshipData({
      relationship: "",
      lname: "",
      fname: "",
    });

    setPhoneAndEmail({
      phoneType: "",
      pNumber: "",
      phoneType2: "",
      pNumber2: "",

      emailType: "",
      email: "",
      emailType2: "",
      email2: "",
    });
    clearDetails();
  };
  const handleAddressChange = (event, changeFunc) => {
    changeFunc(event.target.value);
    //setIsEditLeadP(true);
  };

  const editCompanyData = () => {
    const {
      phoneType,
      pNumber,
      phoneType2,
      pNumber2,
      phoneType3,
      pNumber3,
      emailType,
      email,
      emailType2,
      email2,
      emailType3,
      email3,
    } = phoneAndEmail;

    const allData = {
      lead: {
        leadId: selectedContact.primary_data[0]._id,
      },
      property: {
        address: {
          propertyContactPhone1Type: phoneType,
          propertyContactPhone1: pNumber,
          propertyContactPhone2Type: phoneType2,
          propertyContactPhone2: pNumber2,

          propertyContactEmail1Type: emailType,
          propertyContactEmail1: email,
          propertyContactEmail2Type: emailType2,
          propertyContactEmail2: email2,

          propertyAddressSameAsPrimaryAddress: primaryAddressChecked,
          propertyType: pType,
          propertyNotes: pNotes,
          propertyName: pName,
          propertyContactRelationship: relationshipData.relationship,
          propertyContactFirstName: relationshipData.fname,
          propertyContactLastName: relationshipData.lname,
          propertyAddress1: pcAddress1,
          propertyAddress2: pcAddress2,
          propertyCity: pcCity,
          propertyState: pcState,
          propertyZip: pcZip,
        },
      },
    };

    return allData;
  };

  const handleOnClickEditContactInformaton = () => {
    editContactManagementProperty({
      ...editCompanyData(),
    });

    //setIsEditLeadP(false);
  };

  const onChangeRel = (value) => {
    setRelationshipData((prev) => ({ ...prev, ...value }));
  };
  const displaypTypeOptions = () => {
    const filterData = propertyTypess?.propertydata?.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return filterData?.map((item) => (
      <option key={item?._id} value={item?._id}>
        {item?.roleType}
      </option>
    ));
  };

  const onServiceRequestModalCancel = () => {
    setShowServiceRequestModal(false);
    loadPropertyFieldData();
    setShowNext(false);
  };

  const saveServiceRequest = () => {
    if (isRepair) {
      setSaleSelect({ sales01: false, sales02: false });
    }
    if (!isRepair) {
      setRepairSelect({
        repair01: false,
        repair02: false,
        repair03: false,
        repair04: false,
        repair05: false,
        repair06: false,
      });

      setRepairDetails({
        requestForOther: "",
        originalWorkDone: "",
        originalWorkYear: "",
        underWarrenty: "",
        servicePrice: 0,
        taxRate: "",
        totalPrice: "",
        repairDescreption: "",
      });

      // setServiceChargePolicy(false);
    }

    setShowServiceRequestModal(false);
    //validateServiceRequestFields();
  };

  const AddIntoArray = (array, item, itemName) => {
    if (item) {
      array.push(itemName);
    }
  };

  const propertyListData = () => {
    const phoneCode = "+1";

    let newInstallationFor = [];
    let repairFor = [];

    if (saleSelect.sales01) {
      newInstallationFor.push("sales01");
    }

    if (saleSelect.sales02) {
      newInstallationFor.push("sales02");
    }
    const { repair01, repair02, repair03, repair04, repair05, repair06 } =
      repairSelect;

    AddIntoArray(repairFor, repair01, "repair01");
    AddIntoArray(repairFor, repair02, "repair02");
    AddIntoArray(repairFor, repair03, "repair03");
    AddIntoArray(repairFor, repair04, "repair04");
    AddIntoArray(repairFor, repair05, "repair05");
    AddIntoArray(repairFor, repair06, "repair06");

    const {
      requestForOther,
      originalWorkDone,
      originalWorkYear,
      underWarrenty,
      servicePrice,
      taxRate,
      totalPrice,
      repairDescreption,
    } = repairDetails;

    const propertyBuilding = Object.keys(coopUnits).map((value) => {
      return {
        bid: uuid(),
        bname: value,
      };
    });

    const allData = {
      propertyAddressSameAsPrimaryAddress: primaryAddressChecked,
      propertyAddress1: pcAddress1,
      propertyName: pName,
      propertyAddress2: pcAddress2,
      propertyCity: pcCity,
      propertyState: pcState,
      propertyZip: pcZip,
      propertyType: pType,
      propertyBuildings: propertyBuilding,
      propertyNotes: pNotes,
      doNewInstallation: isRepair === "" ? false : !isRepair,
      newInstallationFor: newInstallationFor,
      newInstallationForOther: "",
      doRepair: isRepair ? isRepair : false,
      repairFor,

      repairForOther: requestForOther,
      originalWorkDone: originalWorkDone === "" ? false : originalWorkDone,
      originalWorkYear: originalWorkYear,
      underWarrenty: underWarrenty === "" ? false : underWarrenty,
      servicePrice: `${servicePrice === 0 ? "" : `$${servicePrice}`}`,
      taxRate: taxRate,
      totalPrice: `${totalPrice === 0 ? "" : `$${totalPrice}`}`,
      repairDescreption: repairDescreption,
    };

    return allData;
  };

  const editContactManagementAllData = () => {
    const phoneCode = "+1";

    let newInstallationFor = [];
    let repairFor = [];

    if (saleSelect.sales01) {
      newInstallationFor.push("sales01");
    }

    if (saleSelect.sales02) {
      newInstallationFor.push("sales02");
    }

    const { repair01, repair02, repair03, repair04, repair05, repair06 } =
      repairSelect;
    AddIntoArray(repairFor, repair01, "repair01");
    AddIntoArray(repairFor, repair02, "repair02");
    AddIntoArray(repairFor, repair03, "repair03");
    AddIntoArray(repairFor, repair04, "repair04");
    AddIntoArray(repairFor, repair05, "repair05");
    AddIntoArray(repairFor, repair06, "repair06");

    const {
      companyAddress1,
      companyAddress2,
      companyCity,
      companyName,
      companyState,
      companyZip,
      primaryFirstName,
      primaryLastName,
      primaryPhone1Type,
      primaryPhone1Code,
      primaryPhone1,
      primaryPhone2Type,
      primaryPhone2Code,
      lead_uid,
      primaryPhone2,
      primaryPhone3Type,
      primaryPhone3Code,
      primaryPhone3,
      primaryEmail1Type,
      primaryEmail1,
      primaryEmail2Type,
      primaryEmail2,
      primaryEmail3Type,
      primaryEmail3,
      primaryAddress1,
      primaryAddress2,
      primaryCity,
      primaryState,
      primaryZip,
      secondaryRelationship,
      secondaryFirstName,
      secondaryLastName,
      secondaryPhone1Type,
      secondaryPhone1Code,
      secondaryPhone1,
      secondaryPhone2Code,
      secondaryPhone2Type,
      secondaryPhone2,
      secondaryEmail1Type,
      secondaryEmail1,
      secondaryEmail2Type,
      secondaryEmail2,
      secondaryAddress1,
      secondaryAddress2,
      secondaryCity,
      secondaryState,
      secondaryZip,
      leadSource,
      leadSourceText,
    } = selectedContact.primary_data[0];

    const {
      phoneType,
      pNumber,
      phoneType2,
      pNumber2,
      phoneType3,
      pNumber3,
      emailType,
      email,
      emailType2,
      email2,
      emailType3,
      email3,
    } = phoneAndEmail;

    const propertyBuilding = Object.keys(coopUnits).map((value) => {
      return {
        bid: uuid(),
        bname: value,
      };
    });
    const allData = {
      lead: {
        leadId: selectedContact.primary_data[0]._id,
        address: {
          companyAddress1: companyAddress1,
          companyAddress2: companyAddress2,
          companyCity: companyCity,
          companyName: companyName,
          companyState: companyState,
          companyZip: companyZip,
          primaryFirstName: primaryFirstName,
          primaryLastName: primaryLastName,
          primaryPhone1Type: primaryPhone1Type,
          primaryPhone1Code: primaryPhone1Code,
          primaryPhone1: primaryPhone1,
          primaryPhone2Type: primaryPhone2Type,
          primaryPhone2Code: primaryPhone2Code,

          lead_uid: lead_uid,
          primaryPhone2: primaryPhone2,
          primaryPhone3Type: primaryPhone3Type,
          primaryPhone3Code: primaryPhone3Code,
          primaryPhone3: primaryPhone3,
          primaryEmail1Type: primaryEmail1Type,
          primaryEmail1: primaryEmail1,
          primaryEmail2Type: primaryEmail2Type,
          primaryEmail2: primaryEmail2,
          primaryEmail3Type: primaryEmail3Type,
          primaryEmail3: primaryEmail3,

          primaryAddress1: primaryAddress1,
          primaryAddress2: primaryAddress2,
          primaryCity: primaryCity,
          primaryState: primaryState,
          primaryZip: primaryZip,

          secondaryRelationship: secondaryRelationship,
          secondaryFirstName: secondaryFirstName,
          secondaryLastName: secondaryLastName,
          secondaryPhone1Type: secondaryPhone1Type,
          secondaryPhone1Code: secondaryPhone1Code,

          secondaryPhone1: secondaryPhone1,
          secondaryPhone2Code: secondaryPhone2Code,
          secondaryPhone2Type: secondaryPhone2Type,
          secondaryPhone2: secondaryPhone2,

          secondaryEmail1Type: secondaryEmail1Type,
          secondaryEmail1: secondaryEmail1,
          secondaryEmail2Type: secondaryEmail2Type,
          secondaryEmail2: secondaryEmail2,

          secondaryAddress1: secondaryAddress1,
          secondaryAddress2: secondaryAddress2,
          secondaryCity: secondaryCity,
          secondaryState: secondaryState,
          secondaryZip: secondaryZip,
        },
      },

      property: {
        propertyId:
          selectedContact.property_list[chosenProperty.property]
            .property_address[0]._id,
        address: {
          leadSource,
          leadSourceText,
          propertyContactPhone1Type: phoneType,
          propertyContactPhone1: pNumber,
          propertyContactPhone2Type: phoneType2,
          propertyContactPhone2: pNumber2,
          propertyContactPhone3Type: phoneType3,
          propertyContactPhone3: pNumber3,

          propertyContactEmail1Type: emailType,
          propertyContactEmail1: email,
          propertyContactEmail2Type: emailType2,
          propertyContactEmail2: email2,
          propertyContactEmail3Type: emailType3,
          propertyContactEmail3: email3,

          propertyAddressSameAsPrimaryAddress: primaryAddressChecked,
          propertyType: pType,
          propertyNotes: pNotes,

          propertyName: pName,

          propertyContactRelationship: relationshipData.relationship,
          propertyContactFirstName: relationshipData.fname,
          propertyContactLastName: relationshipData.lname,

          propertyAddress1: pcAddress1,
          propertyAddress2: pcAddress2,
          propertyCity: pcCity,
          propertyState: pcState,
          propertyZip: pcZip,
          propertyBuildings: propertyBuilding,
        },
      },
      requests: {
        ...propertyListData(),
        requestId: [chosenProperty.request._id],
      },
    };

    return allData;
  };

  const getAllFields = () => {
    const { primaryFirstName, primaryLastName } =
      selectedContact.primary_data[0];

    const allData = {
      _id: selectedContact.primary_data[0]._id,

      leadAddressId: selectedLeadId,
      primaryFirstName: primaryFirstName,
      primaryLastName: primaryLastName,
      ...propertyListData(),
    };

    return allData;
  };
  const onCancelEdit = () => {
    setCurrentAction("");
    setChosenProperty({ property: "", request: "" });
    setAddProperty(false);
    loadPropertyFieldData();
    setShowNext(false);
  };

  const handleEditing = () => {
    {
      EditLead({ ...editContactManagementAllData() });
      // dismissMessage();
      setChosenProperty({ property: "", request: "" });
      setShowNext(false);
    }
  };

  const handleAddService = () => {
    const dataa = getAllFields();
    AddNewSvcRequestToProperty({
      ...dataa,
      propertyId: selectedContact.property_list[chosenProperty.property]._id,
    });
  };
  // #endregion

  return (
    <Col xs="12" md="12">
      <h5 style={{ padding: "25px 0" }}>Service Property Details</h5>
      <strong>
        <Form.Group>
          <Form.Check
            name="primaryAddressChecked"
            checked={primaryAddressChecked}
            disabled={!isActive}
            onChange={(e) => setPrimaryAddressChecked(e.target.checked)}
            label="Same as primary address"
          />
        </Form.Group>
      </strong>
      <Row>
        <Col md="12" sm="12" className="left-Text">
          <strong
            id="siteBtn"
            style={{
              color: Color.primaryColor,
              float: "right",
              cursor: isActive ? "pointer" : "default",
            }}
            onClick={() =>
              isActive ? setShowSiteContactInfo((prev) => !prev) : null
            }
          >
            {`${showSiteContactInfo ? "Hide " : "Add "}`} Site Contact
            Information
          </strong>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6">
          <Form.Group style={styles.formInput}>
            <Form.Label>
              Property Name <SubScriptText>If applicable</SubScriptText>
            </Form.Label>
            <Form.Control
              type="text"
              name="pName"
              value={pName}
              disabled={!isActive}
              placeholder="Enter property name"
              onChange={(e) => {
                setPName(e.target.value);
                // setIsEditLeadSc(true); //Pr
              }}
              //isInvalid={errAt === "pName"}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className={`div ${showSiteContactInfo && isActive && "_show"}`}>
        <RelationshipInfo
          relationTypess={relationTypess}
          relationshipData={relationshipData}
          onChangeRel={onChangeRel}
        />
        <CommonPhoneAndEmailFields
          changePhoneAndEmailDetail={changePhoneAndEmailDetail}
          neededData={contactTypes}
          showData={showContact}
          formData={phoneAndEmail}
          required={true}
          setIsEditLead={() => {}}
        />
      </Row>
      <Row>
        <CommonAddressFields
          allowDisabling={!isActive || primaryAddressChecked || addingService}
          required={true}
          address1Name="pcAddress1"
          address1={pcAddress1}
          address2Name="pcAddress2"
          address2={pcAddress2}
          cityName="pcCity"
          city={pcCity}
          stateName="pcState"
          state={pcState}
          zipName="pcZip"
          zip={pcZip}
          setAddress1={setPcAddress1}
          setAddress2={setPcAddress2}
          setCity={setPcCity}
          setState={setPcState}
          setZip={setPcZip}
          handleChange={handleAddressChange}
        />
      </Row>

      <Row>
        <Col md="6" sm="12">
          <Row>
            {propertyTypess && (
              <Col md="4" sm="12">
                <Form.Group style={styles.formInput}>
                  <Form.Label>
                    Property Type <RequiredText>*</RequiredText>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="pType"
                    disabled={!isActive}
                    value={pType}
                    //isInvalid={errAt === "pType"}
                    onChange={(e) => {
                      setPType(e.target.value);
                      //setIsEditLeadPr(true);
                    }}
                  >
                    <option value="">Choose...</option>
                    {displaypTypeOptions()}
                  </Form.Control>
                </Form.Group>
              </Col>
            )}

            {propertyTypess?.propertydata?.length > 0 && checkIfCoop(pType) && (
              <Col md="4" sm="6">
                <Form.Group style={styles.formInput}>
                  <Form.Label>Buildings</Form.Label>
                  <div>
                    <Button
                      id="addBuilding"
                      onClick={() => setShowCoopModal(true)}
                      style={{ width: "100%" }}
                      disabled={
                        !pType ||
                        !pcAddress1 ||
                        !pcCity ||
                        !pcState ||
                        !pcZip ||
                        !isActive
                      }
                    >
                      Enter Building Names
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            )}

            <Col md="4" sm="6">
              <Form.Group style={styles.formInput}>
                <Form.Label>
                  Service Request <RequiredText>*</RequiredText>
                </Form.Label>
                <div>
                  <Button
                    id="serviceRequest"
                    onClick={() => {
                      setShowServiceRequestModal(true);
                      setServiceRequestModalType("serviceRequest");
                      //setIsEditLeadPr(true);
                    }}
                    style={{ width: "100%" }}
                    disabled={
                      !pType ||
                      !pcAddress1 ||
                      !pcCity ||
                      !pcState ||
                      !pcZip ||
                      !isActive
                    }
                  >
                    Select
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col md="6" sm="12">
          <Form.Group style={styles.formInput}>
            <Form.Label>Property Notes</Form.Label>
            <Form.Control
              type="text"
              name="pNotes"
              value={pNotes}
              disabled={!isActive}
              onChange={(e) => {
                setPNotes(e.target.value);
                // setIsEditLeadPr(true);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="12" sm="12">
          <Button
            variant="danger"
            onClick={onCancelEdit}
            style={{ float: "right", margin: "40px 20px" }}
          >
            Cancel
          </Button>

          <Button
            variant="outline-success"
            disabled={editLeadLoading}
            onClick={() => {
              if (addingProperty) addProperty();
              else if (addingService) handleAddService();
              else handleEditing();
            }}
            style={{ float: "right", margin: "40px 0" }}
          >
            {editLeadLoading ? "Saving..." : "Save Changes"}
          </Button>
        </Col>
      </Row>

      <PopupModal
        show={showServiceRequestModal}
        onHide={onServiceRequestModalCancel}
        heading={"Service Request"}
        bold={true}
      >
        {/* <Col xs="12">
          {errMsg && showServiceRequestModal && errFor === "sr-modal" && (
            <Alert variant={errType}>{errMsg}</Alert>
          )}
        </Col> */}

        {!showNext && (
          <DisplayServiceRequestOption
            isRepair={isRepair}
            onServiceRequestModalCancel={onServiceRequestModalCancel}
            setShowNext={setShowNext}
            setIsRepair={setIsRepair}
          />
        )}
        {showNext && isRepair === false && (
          <DisplayNewInstallationOption
            saleSelect={saleSelect}
            newInstallationType={newInstallationType}
            setSaleSelect={setSaleSelect}
          />
        )}
        {showNext && isRepair && (
          <DisplayNewRepairOptions
            repairType={repairType}
            repairSelect={repairSelect}
            setRepairSelect={setRepairSelect}
            repairDetails={repairDetails}
            setRepairDetails={setRepairDetails}
            taxRates={tasksRate}
          />
        )}
        {showNext && (
          <DisplayFooter
            saveServiceRequest={saveServiceRequest}
            onServiceRequestModalCancel={onServiceRequestModalCancel}
            isRepair={isRepair}
            saleSelect={saleSelect}
            repairSelect={repairSelect}
            repairDetail={repairDetails}
            setShowNext={setShowNext}
          />
        )}
      </PopupModal>
    </Col>
  );
}

const RelationshipInfo = ({
  relationTypess,
  relationshipData,
  onChangeRel,
  required = false,
}) => {
  const { relationship, fname, lname } = relationshipData;

  const displayRelationshipTypes = () => {
    const filterData = relationTypess?.relationdata?.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return filterData?.map((item) => (
      <option key={item?._id} value={item?._id}>
        {item?.roleType}
      </option>
    ));
  };
  return (
    <Col md="12" sm="12">
      <Row>
        <Col md="6" sm="12">
          {relationTypess && (
            <Row>
              <Col md="6" sm="12">
                <Form.Group style={styles.formInput}>
                  <Form.Label>
                    Relationship<RequiredText>*</RequiredText>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={relationship}
                    onChange={(e) => {
                      onChangeRel({ relationship: e.target.value });
                      //setIsEditLead(true);
                    }}
                  >
                    <option value="">Choose...</option>
                    {displayRelationshipTypes()}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md="6" sm="12">
                <Form.Group style={styles.formInput}>
                  <Form.Label disabled={!relationship}>
                    First Name {required && <RequiredText>*</RequiredText>}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={fname}
                    disabled={!relationship}
                    onChange={(event) => {
                      onChangeRel({ fname: event.target.value });
                      // setIsEditLead(true);
                    }}
                    // isInvalid={errAt === fnameName}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
        </Col>

        <Col sm="12" md="6">
          <Form.Group style={styles.formInput}>
            <Form.Label>
              Last Name {required && <RequiredText>*</RequiredText>}
            </Form.Label>
            <Form.Control
              type="text"
              value={lname}
              disabled={!relationship}
              onChange={(event) => {
                onChangeRel({ lname: event.target.value });
                // setIsEditLead(true);
              }}
              //isInvalid={errAt === lnameName}
            />
          </Form.Group>
        </Col>
      </Row>
    </Col>
  );
};
