import React from "react";
import { Row, Col, Form } from "react-bootstrap";

import { PriceInput } from "components";
import styles from "lib/config/baseStyles";
import QTYInput from "../components/QTYInput";
import { getOptions } from "../components/SelectSupliers";

export default function MaterialIndividualData({
  manufacturerList,
  manufacturer,
  category,
  allData,
  disabled,
  openModal,
  series,
  uom,
  qty,
  setQty,
  handleChange,
  price,
  total,
  changeManufacturer,
  handleChangeQty,
  disablePriceChange,
  handlePriceChange,
  original_list,
  isLockQuoteData,
  isLoading,
}) {
  return (
    <>
      <Row>
        <Col style={styles.reduceWidthCol}>
          {manufacturer && manufacturerList.length > 0 && (
            <Form.Group>
              <Form.Control
                disabled={isLockQuoteData}
                as="select"
                value={manufacturer}
                onChange={(e) => changeManufacturer(e.target.value)}
                style={
                  original_list?.manufacturer !== manufacturer
                    ? {
                        backgroundColor: "#f6d46f",
                      }
                    : {}
                }
              >
                {manufacturer && getOptions(manufacturerList)}
              </Form.Control>
            </Form.Group>
          )}
        </Col>

        <Col style={styles.reduceWidthCol} lg="2">
          {category && (
            <Form.Group>
              <Form.Control
                disabled
                type="text"
                style={{ textAlign: "center" }}
                value={category}
              />
            </Form.Group>
          )}
        </Col>
        <Col style={styles.reduceWidthCol} lg="4">
          <button
            disabled={disabled || isLockQuoteData || isLoading}
            onClick={openModal}
            className="__quote_series_txt"
            style={
              original_list?.series !== series
                ? {
                    backgroundColor: "#f6d46f",
                  }
                : {}
            }
          >
            {series}
          </button>
        </Col>
        <Col style={styles.reduceWidthCol}>
          <Form.Group>
            <Form.Control
              disabled
              type="text"
              style={{ textAlign: "center" }}
              value={uom}
            />
          </Form.Group>
        </Col>
        <Col style={styles.reduceWidthCol}>
          <QTYInput
            value={qty?.toString()}
            onChange={handleChangeQty}
            styless={original_list?.qty != qty}
            disabled={isLockQuoteData || isLoading}
          />
        </Col>
        <Col style={styles.reduceWidthCol}>
          <PriceInput
            disabled={disablePriceChange || isLockQuoteData || isLoading}
            price={price}
            onChange={(value) => handlePriceChange(value)}
          />
        </Col>
        <Col style={styles.reduceWidthCol}>
          <PriceInput disabled price={total} input="Total" />
        </Col>
      </Row>
    </>
  );
}
