import React, {  useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import Weather from "./Weather";
import ProductionScheduleRoof from "./ProductionScheduleRoof";
import ProductionScheduleGutters from "./ProductionScheduleGutters";
import ProductionScheduleRepairs from "./ProductionScheduleRepairs";
import useProductionSchedule from "hooks/useProductionSchedule";
import { separate_events } from "./helpers";
const ProductionSchedule = () => {
  const [paymentStatus, setPaymentStatus] = useState();

  const [MAIN_TABS] = useState([
    {
      name: "Roofing",
      id: "001",
    },
    {
      name: "Gutters",
      id: "002",
    },
    {
      name: "Repairs",
      id: "003",
    },
  ]);

  const [key, setKey] = useState(MAIN_TABS[0].id);
  const [dateObject, setDateObject] = useState(new Date());

  let { events, isError, isLoading } = useProductionSchedule();

  let { roof, gutter, repair } = separate_events(events);

  const onNextWeek = () => {
    setDateObject(moment(dateObject).add(1, "weeks"));
  };

  const onPrevWeek = () => {
    setDateObject(moment(dateObject).subtract(1, "weeks"));
  };

  const setToday = () => {
    setDateObject(new Date());
  };

  const certainDate = (data) => {
    setDateObject(data[0]);
  };

  const switchMainTabs = () => {
    switch (key) {
      case MAIN_TABS[0].id:
        return (
          <ProductionScheduleRoof
            dateObject={dateObject}
            setToday={setToday}
            onNextWeek={onNextWeek}
            onPrevWeek={onPrevWeek}
            certainDate={certainDate}
            roof={roof}
            isLoading={isLoading}
          />
        );

      case MAIN_TABS[1].id:
        return (
          <ProductionScheduleGutters
            dateObject={dateObject}
            setToday={setToday}
            onNextWeek={onNextWeek}
            onPrevWeek={onPrevWeek}
            certainDate={certainDate}
            gutter={gutter}
          />
        );

      case MAIN_TABS[2].id:
        return (
          <ProductionScheduleRepairs
            dateObject={dateObject}
            setToday={setToday}
            onNextWeek={onNextWeek}
            onPrevWeek={onPrevWeek}
            certainDate={certainDate}
            repair={repair}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div>
        <Weather paymentStatus={paymentStatus} />

        <div className="__quote_estimator" style={{ minHeight: "60vh" }}>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            style={{ border: "none", boxShadow: "none" }}
            fill={false}
          >
            {MAIN_TABS.map((tab) => (
              <Tab
                key={tab.id}
                eventKey={tab.id}
                title={tab.name}
                style={{
                  backgroundColor: "#f9f9f9",
                  marginRight: "-0.85rem",
                  marginLeft: "-0.85rem",
                }}
                // disabled={tab.id === MAIN_TABS[1].id ? !showProposalTab : false}
              >
                {switchMainTabs()}
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default ProductionSchedule;
