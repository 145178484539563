import baseSettings from "lib/config/baseSettings";
import Localbase from "localbase";

const db = new Localbase(baseSettings.dbName);

export const addUserLocal = async (userData) =>
  db
    .collection(baseSettings.dbColName)
    .doc(baseSettings.dbUserId)
    .set({ ...userData, time: Date.now() });
// .then(() => true)
// .catch(() => false);

export const deleteUserLocal = () =>
  db
    .collection(baseSettings.dbColName)
    .doc(baseSettings.dbUserId)
    .delete()
    .then(() => true)
    .catch(() => false);

export const getUserLocal = async () => {
  try {
    const user = await db
      .collection(baseSettings.dbColName)
      .doc(baseSettings.dbUserId)
      .get();
    return user;
  } catch (e) {
    return null;
  }
};
