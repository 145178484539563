import React from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";

const newStyle = {
  formInput: {
    marginBottom: "15px",
  },
  displayPrice: {
    maxWidth: "150px",
  },
  pricingCol: {
    display: "flex",
    justifyContent: "left",
  },
};

const AdditionalUserInput = ({
  label,
  currency,
  additionalPricePerUser,
  children,
  ...rest
}) => (
  <React.Fragment>
    <label style={{ textAlign: "left" }}>
      {label
        ? label
        : `Each additional user
			${
        currency ? (currency === "USD" ? "$" : currency) : ""
      }${additionalPricePerUser}
			per user/per month`}
    </label>
    <Form.Group style={newStyle.formInput}>
      <Row>
        <Col style={newStyle.pricingCol}>
          <InputGroup style={newStyle.displayPrice}>
            <Form.Control style={{ textAlign: "center" }} {...rest} />
          </InputGroup>
          {children}
        </Col>
      </Row>
    </Form.Group>
  </React.Fragment>
);

export default AdditionalUserInput;
