import { axiosInstance } from "services";
import * as quoteTypes from "./quotes-proposals.types";

export const getProposalTextsData =
  (proposalId, onSuccess, onFailure, onFinally) => (dispatch) =>
    axiosInstance
      .get(`/quote/proposal/write/${proposalId}`)
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          onSuccess(res.result);
        } else {
          onFailure(res.error);
        }
      })
      .catch((e) => onFailure(e.message))
      .finally(onFinally);

export const getQuoteProposalModalData = (quoteId) => (dispatch) => {
  dispatch({
    type: quoteTypes.QUOTE_PROPOSAL_MODAL_START,
  });
  const onFailureModalData = (errMsg) =>
    dispatch({
      type: quoteTypes.QUOTE_PROPOSAL_MODAL_FAILURE,
      payload: errMsg,
    });

  if (!navigator.onLine) {
    return dispatch({
      type: quoteTypes.QUOTE_PROPOSAL_MODAL_SUCCESS,
      payload: [
        {
          _id: "Underlayment",
          order: 6.18,
          isMultiSelect: false,
          data: [
            {
              _id: "6538a4fd76b1d344a01995e6",
              displayText: "Install 15 lb. felt underlayment.",
              order: 6.18,
              isSelected: true,
            },
            {
              _id: "6538a4fe76b1d344a01995e8",
              displayText: "Install 30 lb. felt underlayment.",
              order: 6.19,
              isSelected: true,
            },
            {
              _id: "6538a4fe76b1d344a01995ea",
              displayText: "Install Synthetic underlayment.",
              order: 6.2,
              isSelected: true,
            },
          ],
        },
      ],
    });
  }
  return axiosInstance
    .get(`/quote/proposaltab/modal/${quoteId}`)
    .then((res) => res.data)
    .then((res) => {
      if (res.success) {
        return dispatch({
          type: quoteTypes.QUOTE_PROPOSAL_MODAL_SUCCESS,
          payload: res.result,
        });
      }
      onFailureModalData(res.error);
    })
    .catch((e) => onFailureModalData(e.message));
};
