import { legacy_createStore as createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import rootReducer from "./root-reducer";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "persist-store",
  storage,
};

let middlewares = [thunk];

// if (process.env.NODE_ENV === "development") {
//   middlewares.push(logger);
// }
//const persistedReducer = persistReducer(persistConfig,rootReducer)
const store = createStore(rootReducer, applyMiddleware(...middlewares));
//export const persistor = persistStore(store)

export default store;
