const mapSupplierNames = (crews, supplierList) => {
  if (crews && (crews[0]?.crewSupplierId || crews[0]?.subSupplierId)) {
    let crew_labor = crews[0].crewSupplierId.map((crew) => ({
      value: crew.name,
      label: crew.name.toUpperCase(),
    }));

    let sub_labor = crews[0].subSupplierId.map((crew) => ({
      value: crew.name,
      label: crew.name.toUpperCase(),
    }));
    return [...crew_labor, ...sub_labor];
  } else if (supplierList) {
    return supplierList.map((supplier) => ({
      label: supplier.name,
      value: supplier.name.toUpperCase(),
    }));
  } else {
    return [];
  }
};

const getStartDate = (data, type) =>
  data.length > 0
    ? data[0]?.schedules[0]?.scheduleStartDate
    : data.length === 0 && type === "gutter"
    ? data[0]?.schedules[0]?.scheduleStartDate
    : "";

export const pm_details_obj = (
  filtered_data_roof,
  filtered_data_gutter,
  supplier,
  isScheduled,
  filtered_unScheduled_roof,
  filtered_unScheduled_gutter
) => {

  let obj = {};
  obj.roof_start_time = filtered_data_roof[0]?.schedules[0]?.scheduleStartTime;
  obj.roof_start_date = getStartDate(filtered_data_roof, "roof");

  obj.gutter_start_time =
    filtered_data_gutter[0]?.schedules[0]?.scheduleStartTime;
  obj.gutter_start_date = getStartDate(filtered_data_gutter, "gutter");

  obj.labor_supplier_name = [];
  obj.material_supplier_name = [];
  obj.carting_supplier_name = [];
  obj.gutter_supplier_name = [];

  if (filtered_data_roof?.length === 0 && filtered_data_gutter?.length === 0) {
    if (
      filtered_unScheduled_roof?.length > 0 &&
      filtered_unScheduled_gutter?.length > 0
    ) {
      obj.labor_supplier_name = mapSupplierNames(
        filtered_unScheduled_roof,
        supplier.labor_supplier
      );

      obj.material_supplier_name = mapSupplierNames(
        filtered_unScheduled_roof,
        supplier.material_supplier
      );

      obj.carting_supplier_name = mapSupplierNames(
        filtered_unScheduled_roof,
        supplier.carting_supplier
      );

      obj.gutter_supplier_name = mapSupplierNames(
        filtered_unScheduled_roof,
        supplier.labor_supplier
      );
    }
    if (
      filtered_unScheduled_roof.length > 0 &&
      filtered_unScheduled_gutter.length == 0
    ) {
      obj.labor_supplier_name = mapSupplierNames(
        filtered_unScheduled_roof,
        supplier.labor_supplier
      );

      obj.material_supplier_name = mapSupplierNames(
        filtered_unScheduled_roof,
        supplier.material_supplier
      );

      obj.carting_supplier_name = mapSupplierNames(
        filtered_unScheduled_roof,
        supplier.carting_supplier
      );

      obj.gutter_supplier_name = mapSupplierNames(
        filtered_unScheduled_roof,
        supplier.labor_supplier
      );
    }
    if (
      filtered_unScheduled_roof.length === 0 &&
      filtered_unScheduled_gutter.length > 0
    ) {
      obj.labor_supplier_name = mapSupplierNames(
        filtered_unScheduled_roof,
        supplier.labor_supplier
      );

      obj.material_supplier_name = mapSupplierNames(
        filtered_unScheduled_roof,
        supplier.material_supplier
      );
      obj.carting_supplier_name = mapSupplierNames(
        filtered_unScheduled_roof,
        supplier.carting_supplier
      );

      obj.gutter_supplier_name = mapSupplierNames(
        filtered_unScheduled_roof,
        supplier.labor_supplier
      );
    }
  } else if (filtered_data_roof.length && filtered_data_gutter.length === 0) {
    obj.labor_supplier_name =
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.crewSupplierId.length >
        0 ||
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.subSupplierId.length > 0
        ? [
            ...filtered_data_roof[0]?.schedules[0]?.crews[0]?.crewSupplierId.map(
              (crew) => {
                return { value: crew.name, label: crew.name.toUpperCase() };
              }
            ),
            ...filtered_data_roof[0]?.schedules[0]?.crews[0]?.subSupplierId.map(
              (crew) => {
                return { value: crew.name, label: crew.name.toUpperCase() };
              }
            ),
          ]
        : null;

    obj.material_supplier_name =
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.materialSupplierId.length >
      0
        ? filtered_data_roof[0]?.schedules[0]?.crews[0]?.materialSupplierId.map(
            (crew) => {
              return { value: crew.name, label: crew.name.toUpperCase() };
            }
          )
        : supplier.material_supplier.filter((supplier) => {
            if (
              supplier._id ==
              filtered_data_roof[0]?.Quote_Material[0]?.supplierId
            ) {
              return {
                label: supplier.name,
                value: supplier.name.toUpperCase(),
              };
            }
          });

    obj.carting_supplier_name =
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.cartingSupplierId.length >
      0
        ? filtered_data_roof[0]?.schedules[0]?.crews[0]?.cartingSupplierId.map(
            (crew) => {
              return { value: crew.name, label: crew.name.toUpperCase() };
            }
          )
        : supplier.carting_supplier.filter((supplier) => {
            if (
              supplier._id == filtered_data_roof[0]?.Quote_Debris[0]?.supplierId
            ) {
              return {
                label: supplier.name,
                value: supplier.name.toUpperCase(),
              };
            }
          });

    obj.gutter_supplier_name =
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.gutterSupplierId.length >
        0 ||
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.gutterCrewSupplierId
        .length > 0
        ? [
            ...filtered_data_roof[0]?.schedules[0]?.crews[0]?.gutterSupplierId.map(
              (crew) => {
                return { value: crew.name, label: crew.name.toUpperCase() };
              }
            ),
            ...filtered_data_roof[0]?.schedules[0]?.crews[0]?.gutterCrewSupplierId.map(
              (crew) => {
                return { value: crew.name, label: crew.name.toUpperCase() };
              }
            ),
          ]
        : null;

  } else if (filtered_data_roof.length === 0 && filtered_data_gutter.length) {
    obj.labor_supplier_name =
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.crewSupplierId.length >
        0 ||
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.subSupplierId.length > 0
        ? [
            ...filtered_data_roof[0]?.schedules[0]?.crews[0]?.crewSupplierId.map(
              (crew) => {
                return { value: crew.name, label: crew.name.toUpperCase() };
              }
            ),
            ...filtered_data_roof[0]?.schedules[0]?.crews[0]?.subSupplierId.map(
              (crew) => {
                return { value: crew.name, label: crew.name.toUpperCase() };
              }
            ),
          ]
        : supplier.labor_supplier.filter((supplier) => {
            if (
              supplier._id == filtered_data_roof[0]?.Quote_Labor[0]?.supplierId
            ) {
              return {
                label: supplier.name,
                value: supplier.name.toUpperCase(),
              };
            }
          });

    obj.material_supplier_name =
      filtered_data_gutter[0]?.schedules[0]?.crews[0]?.materialSupplierId
        .length > 0
        ? filtered_data_gutter[0]?.schedules[0]?.crews[0]?.materialSupplierId.map(
            (crew) => {
              return { value: crew.name, label: crew.name.toUpperCase() };
            }
          )
        : supplier.material_supplier.filter((supplier) => {
            if (
              supplier._id ==
              filtered_data_roof[0]?.Quote_Material[0]?.supplierId
            ) {
              return {
                label: supplier.name,
                value: supplier.name.toUpperCase(),
              };
            }
          });

    obj.carting_supplier_name =
      filtered_data_gutter[0]?.schedules[0]?.crews[0]?.cartingSupplierId
        .length > 0
        ? filtered_data_gutter[0]?.schedules[0]?.crews[0]?.cartingSupplierId.map(
            (crew) => {
              return { value: crew.name, label: crew.name.toUpperCase() };
            }
          )
        : supplier.carting_supplier.filter((supplier) => {
            if (
              supplier._id == filtered_data_roof[0]?.Quote_Debris[0]?.supplierId
            ) {
              return {
                label: supplier.name,
                value: supplier.name.toUpperCase(),
              };
            }
          });

    obj.gutter_supplier_name =
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.gutterSupplierId.length >
        0 ||
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.gutterCrewSupplierId
        .length > 0
        ? [
            ...filtered_data_roof[0]?.schedules[0]?.crews[0]?.gutterSupplierId.map(
              (crew) => {
                return { value: crew.name, label: crew.name.toUpperCase() };
              }
            ),
            ...filtered_data_roof[0]?.schedules[0]?.crews[0]?.gutterCrewSupplierId.map(
              (crew) => {
                return { value: crew.name, label: crew.name.toUpperCase() };
              }
            ),
          ]
        : null;
  } else if (filtered_data_roof.length && filtered_data_gutter.length) {
    obj.labor_supplier_name =
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.crewSupplierId.length >
        0 ||
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.subSupplierId.length > 0
        ? [
            ...filtered_data_roof[0]?.schedules[0]?.crews[0]?.crewSupplierId.map(
              (crew) => {
                return { value: crew.name, label: crew.name.toUpperCase() };
              }
            ),
            ...filtered_data_roof[0]?.schedules[0]?.crews[0]?.subSupplierId.map(
              (crew) => {
                return { value: crew.name, label: crew.name.toUpperCase() };
              }
            ),
          ]
        : supplier.labor_supplier.filter((supplier) => {
            if (
              supplier._id == filtered_data_roof[0]?.Quote_Labor[0]?.supplierId
            ) {
              return {
                label: supplier.name,
                value: supplier.name.toUpperCase(),
              };
            }
          });

    obj.material_supplier_name =
      filtered_data_gutter[0]?.schedules[0]?.crews[0]?.materialSupplierId
        .length > 0
        ? filtered_data_gutter[0]?.schedules[0]?.crews[0]?.materialSupplierId.map(
            (crew) => {
              return { value: crew.name, label: crew.name.toUpperCase() };
            }
          )
        : supplier.material_supplier.filter((supplier) => {
            if (
              supplier._id ==
              filtered_data_roof[0]?.Quote_Material[0]?.supplierId
            ) {
              return {
                label: supplier.name,
                value: supplier.name.toUpperCase(),
              };
            }
          });

    obj.carting_supplier_name =
      filtered_data_gutter[0]?.schedules[0]?.crews[0]?.cartingSupplierId
        .length > 0
        ? filtered_data_gutter[0]?.schedules[0]?.crews[0]?.cartingSupplierId.map(
            (crew) => {
              return { value: crew.name, label: crew.name.toUpperCase() };
            }
          )
        : supplier.carting_supplier.filter((supplier) => {
            if (
              supplier._id == filtered_data_roof[0]?.Quote_Debris[0]?.supplierId
            ) {
              return {
                label: supplier.name,
                value: supplier.name.toUpperCase(),
              };
            }
          });

    obj.gutter_supplier_name =
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.gutterSupplierId.length >
        0 ||
      filtered_data_roof[0]?.schedules[0]?.crews[0]?.gutterCrewSupplierId
        .length > 0
        ? [
            ...filtered_data_roof[0]?.schedules[0]?.crews[0]?.gutterSupplierId.map(
              (crew) => {
                return { value: crew.name, label: crew.name.toUpperCase() };
              }
            ),
            ...filtered_data_roof[0]?.schedules[0]?.crews[0]?.gutterCrewSupplierId.map(
              (crew) => {
                return { value: crew.name, label: crew.name.toUpperCase() };
              }
            ),
          ]
        : null;
  }

  obj.total =
    filtered_data_roof.length > 0
      ? total_calc_scheduled(filtered_data_roof)
      : filtered_data_gutter.length > 0
      ? total_calc_scheduled(filtered_data_gutter)
      : filtered_unScheduled_roof.length > 0
      ? total_calc_unscheduled(filtered_unScheduled_roof)
      : filtered_unScheduled_gutter.length > 0
      ? total_calc_unscheduled(filtered_unScheduled_gutter)
      : "";

  obj.roofing_finish_date =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Project_Details[0]?.roofingFinishDate
      : filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Project_Details[0]?.roofingFinishDate
      : filtered_unScheduled_roof.length > 0
      ? filtered_unScheduled_roof[0]?.Project_Details[0]?.roofingFinishDate
      : filtered_unScheduled_gutter.length > 0
      ? filtered_unScheduled_gutter[0]?.Project_Details[0]?.roofingFinishDate
      : "";

  obj.labor_expiration_date =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Project_Details[0]?.roofingLaborExpirationDate
      : filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Project_Details[0]?.roofingLaborExpirationDate
      : filtered_unScheduled_roof.length > 0
      ? filtered_unScheduled_roof[0]?.Project_Details[0]
          ?.roofingLaborExpirationDate
      : filtered_unScheduled_gutter.length > 0
      ? filtered_unScheduled_gutter[0]?.Project_Details[0]
          ?.roofingLaborExpirationDate
      : "";

  obj.shingle_manufacturer_warranty =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Project_Details[0]?.shingleManufacturerWarranty
      : filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Project_Details[0]?.shingleManufacturerWarranty
      : filtered_unScheduled_roof.length > 0
      ? filtered_unScheduled_roof[0]?.Project_Details[0]
          ?.shingleManufacturerWarranty
      : filtered_unScheduled_gutter.length > 0
      ? filtered_unScheduled_gutter[0]?.Project_Details[0]
          ?.shingleManufacturerWarranty
      : "";

  obj.flat_manufacturer_warranty =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Project_Details[0]?.flatManufacturerWarranty
      : filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Project_Details[0]?.flatManufacturerWarranty
      : filtered_unScheduled_roof.length > 0
      ? filtered_unScheduled_roof[0]?.Project_Details[0]
          ?.flatManufacturerWarranty
      : filtered_unScheduled_gutter.length > 0
      ? filtered_unScheduled_gutter[0]?.Project_Details[0]
          ?.flatManufacturerWarranty
      : "";

  obj.gutter_finish_date =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Project_Details[0]?.gutterFinishDate
      : filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Project_Details[0]?.gutterFinishDate
      : filtered_unScheduled_roof.length > 0
      ? filtered_unScheduled_roof[0]?.Project_Details[0]?.gutterFinishDate
      : filtered_unScheduled_gutter.length > 0
      ? filtered_unScheduled_gutter[0]?.Project_Details[0]?.gutterFinishDate
      : "";

  obj.gutter_labor_expiration_date =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Project_Details[0]?.gutterLaborExpirationDate
      : filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Project_Details[0]?.gutterLaborExpirationDate
      : filtered_unScheduled_roof.length > 0
      ? filtered_unScheduled_roof[0]?.Project_Details[0]
          ?.gutterLaborExpirationDate
      : filtered_unScheduled_gutter.length > 0
      ? filtered_unScheduled_gutter[0]?.Project_Details[0]
          ?.gutterLaborExpirationDate
      : "";

  obj.deposit =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Payment_Details[0]?.deposit
      : filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Payment_Details[0]?.deposit
      : filtered_unScheduled_roof.length > 0
      ? filtered_unScheduled_roof[0]?.Payment_Details[0]?.deposit
      : filtered_unScheduled_gutter.length > 0
      ? filtered_unScheduled_gutter[0]?.Payment_Details[0]?.deposit
      : "";

  obj.deposit_received_date =
    filtered_data_roof.length > 0
      ? date_converter(filtered_data_roof[0]?.Payment_Details[0]?.receivedDate)
      : filtered_data_gutter.length > 0
      ? date_converter(
          filtered_data_gutter[0]?.Payment_Details[0]?.receivedDate
        )
      : filtered_unScheduled_roof.length > 0
      ? date_converter(
          filtered_unScheduled_roof[0]?.Payment_Details[0]?.receivedDate
        )
      : filtered_unScheduled_gutter.length > 0
      ? date_converter(
          filtered_unScheduled_gutter[0]?.Payment_Details[0]?.receivedDate
        )
      : "";

  obj.deposit_payment_method =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Payment_Details[0]?.paymentMethod
      : filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Payment_Details[0]?.paymentMethod
      : filtered_unScheduled_roof.length > 0
      ? filtered_unScheduled_roof[0]?.Payment_Details[0]?.paymentMethod
      : filtered_unScheduled_gutter.length > 0
      ? filtered_unScheduled_gutter[0]?.Payment_Details[0]?.paymentMethod
      : "";

  obj.start_payment =
    filtered_data_roof.length > 0
      ? start_payment_calc(
          total_calc_without_deposit_scheduled(filtered_data_roof),
          filtered_data_roof[0]?.Quote_Proposal[0]?.fixedStandardStatements[3]
            ?.percentage
        )
      : filtered_data_gutter.length > 0
      ? start_payment_calc(
          total_calc_without_deposit_scheduled(filtered_data_gutter),
          filtered_data_gutter[0]?.Quote_Proposal[0]?.fixedStandardStatements[3]
            ?.percentage
        )
      : filtered_unScheduled_roof.length > 0
      ? start_payment_calc(
          total_calc_without_deposit_unscheduled(filtered_unScheduled_roof),
          filtered_unScheduled_roof[0]?.Quote_Proposal[0]
            ?.fixedStandardStatements[3]?.percentage
        )
      : filtered_unScheduled_gutter.length > 0
      ? start_payment_calc(
          total_calc_without_deposit_unscheduled(filtered_unScheduled_gutter),
          filtered_unScheduled_gutter[0]?.Quote_Proposal[0]
            ?.fixedStandardStatements[3]?.percentage
        )
      : "";

  obj.start_payment_payment_method =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Payment_Details[0]?.startPaymentPaymentMethod
      : filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Payment_Details[0]?.startPaymentPaymentMethod
      : filtered_unScheduled_roof.length > 0
      ? filtered_unScheduled_roof[0]?.Payment_Details[0]
          ?.startPaymentPaymentMethod
      : filtered_unScheduled_gutter.length > 0
      ? filtered_unScheduled_gutter[0]?.Payment_Details[0]
          ?.startPaymentPaymentMethod
      : "";

  obj.start_payment_payment_date =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Payment_Details[0]?.startPaymentReceivedDate
      : filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Payment_Details[0]?.startPaymentReceivedDate
      : filtered_unScheduled_roof.length > 0
      ? filtered_unScheduled_roof[0]?.Payment_Details[0]
          ?.startPaymentReceivedDate
      : filtered_unScheduled_gutter.length > 0
      ? filtered_unScheduled_gutter[0]?.Payment_Details[0]
          ?.startPaymentReceivedDate
      : "";

  obj.change_order_data =
    filtered_data_roof.length > 0 &&
    filtered_data_roof[0]?.Project_Orders.length > 0
      ? filtered_data_roof[0]?.Project_Orders
      : filtered_data_gutter.length &&
        filtered_data_gutter[0]?.Project_Orders.length > 0
      ? filtered_data_gutter[0]?.Project_Orders
      : filtered_unScheduled_roof.length > 0 &&
        filtered_unScheduled_roof[0]?.Project_Orders.length > 0
      ? filtered_unScheduled_roof[0]?.Project_Orders
      : filtered_unScheduled_gutter.length > 0 &&
        filtered_unScheduled_gutter[0]?.Project_Orders.length > 0
      ? filtered_unScheduled_gutter[0]?.Project_Orders
      : "";

  obj.change_order_price_roof =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Project_Orders[0]?.changeOrderPrice
      : "";

  obj.change_order_notes_roof =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Project_Orders[0]?.changeOrderNotes
      : "";

  obj.change_order_price_gutter =
    filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Project_Orders[0]?.changeOrderPrice
      : "";

  obj.change_order_notes_gutter =
    filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Project_Orders[0]?.changeOrderNotes
      : "";

  obj.installment_id =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Payment_Details[0]?._id
      : filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Payment_Details[0]?._id
      : filtered_unScheduled_roof.length > 0
      ? filtered_unScheduled_roof[0]?.Payment_Details[0]?._id
      : filtered_unScheduled_gutter.length > 0
      ? filtered_unScheduled_gutter[0]?.Payment_Details[0]?._id
      : "";

  obj.installments_data =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Payment_Details[0]?.Project_Installments
      : filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Payment_Details[0]?.Project_Installments
      : filtered_unScheduled_roof.length > 0
      ? filtered_unScheduled_roof[0]?.Payment_Details[0]?.Project_Installments
      : filtered_unScheduled_gutter.length > 0
      ? filtered_unScheduled_gutter[0]?.Payment_Details[0]?.Project_Installments
      : [];

  obj.balance =
    filtered_data_roof.length > 0
      ? balance(
          obj.total,
          filtered_data_roof[0]?.Payment_Details[0]?.Project_Installments
            ? filtered_data_roof[0]?.Payment_Details[0]?.Project_Installments
            : [],
          filtered_data_roof
        )
      : filtered_data_gutter.length > 0
      ? balance(
          obj.total,
          filtered_data_gutter[0]?.Payment_Details[0]?.Project_Installments
            ? filtered_data_gutter[0]?.Payment_Details[0]?.Project_Installments
            : [],
          filtered_data_gutter
        )
      : filtered_unScheduled_roof.length > 0
      ? balance(
          obj.total,
          filtered_unScheduled_roof[0]?.Payment_Details[0]?.Project_Installments
            ? filtered_unScheduled_roof[0]?.Payment_Details[0]
                ?.Project_Installments
            : [],
          filtered_unScheduled_roof
        )
      : filtered_unScheduled_gutter.length > 0
      ? balance(
          obj.total,
          filtered_unScheduled_gutter[0]?.Payment_Details[0]
            ?.Project_Installments
            ? filtered_unScheduled_roof[0]?.Payment_Details[0]
                ?.Project_Installments
            : [],
          filtered_unScheduled_gutter
        )
      : 0;

  obj.paid_in_full_date =
    filtered_data_roof.length > 0
      ? filtered_data_roof[0]?.Project_Details[0]?.paidFullDate
      : filtered_data_gutter.length > 0
      ? filtered_data_gutter[0]?.Project_Details[0]?.paidFullDate
      : filtered_unScheduled_roof.length > 0
      ? filtered_unScheduled_roof[0]?.Project_Details[0]?.paidFullDate
      : filtered_unScheduled_gutter.length > 0
      ? filtered_unScheduled_gutter[0]?.Project_Details[0]?.paidFullDate
      : "";

  return obj;
};

let date_converter = (date) => {
  let arr = [];
  if (date) {
    if (date.split("").includes("-")) {
      return date;
    }
    let date_arr = date?.split("/");
    arr[0] = date_arr[2];
    arr[1] = date_arr[0] > 9 ? date_arr[0] : `0${date_arr[0]}`;
    arr[2] = date_arr[1] > 9 ? date_arr[1] : `0${date_arr[1]}`;
    return arr.join("-");
  } else {
    return "";
  }
};

let balance = (total, installments, data) => {
  let [
    {
      Payment_Details: [{ deposit }],
      Quote_Proposal: [{ markUp, fixedStandardStatements }],
      Project_Orders,
    },
  ] = data;
  let project_order_total = 0;

  Project_Orders.forEach((order) => {
    let { changeOrderPrice } = order;
    if (!changeOrderPrice) return project_order_total;
    project_order_total = project_order_total + order?.changeOrderPrice;
  });
  let balance =
    total -
    deposit +
    project_order_total -
    total * (fixedStandardStatements[3]?.percentage / 100);
  if (installments.length === 0) return `${balance}`;
  installments.forEach((installment) => {
    balance = balance - installment.installmentAmount;
  });

  return balance < 0 ? 0 : balance;
};

let total_calc_scheduled = (data) => {
  let gross = 0;
  let project_order_total = 0;
  let {
    Quote_Debris,
    Quote_Gutter,
    Quote_Material,
    Quote_Labor,
    Quote_Proposal,
    Project_Orders,
    Payment_Details,
  } = data[0];

  let [{ debrisRemovalTotal }] = Quote_Debris?.length > 0 ? Quote_Debris : [{}];
  let [{ total }] = Quote_Material?.length > 0 ? Quote_Material : [{}];
  let [{ roofingLaborSubTotal }] = Quote_Labor?.length > 0 ? Quote_Labor : [{}];
  let [{ markUp, fixedStandardStatements }] =
    Quote_Proposal?.length > 0 ? Quote_Proposal : [{}];
  let [{ deposit }] = Payment_Details?.length > 0 ? Payment_Details : [{}];

  Project_Orders.forEach((order) => {
    let { changeOrderPrice } = order;
    if (!changeOrderPrice) return project_order_total;
    project_order_total = project_order_total + order?.changeOrderPrice;
  });


  if (Quote_Gutter.length > 0) {
    gross =
      (debrisRemovalTotal +
        roofingLaborSubTotal +
        total +
        Quote_Gutter[0].guttersSubTotal) *
      (1 + markUp / 100);
  } else {
    gross =
      (debrisRemovalTotal + roofingLaborSubTotal + total) * (1 + markUp / 100);
  }
  return gross.toFixed(2);
};

let total_calc_unscheduled = (data) => {
  let gross = 0;
  let project_order_total = 0;
  let {
    Quote_Debris,
    Quote_Gutter,
    Quote_Material,
    Quote_Labor,
    Quote_Proposal,
    Project_Orders,
    Payment_Details,
  } = data[0];

  let [{ debrisRemovalTotal }] = Quote_Debris?.length > 0 ? Quote_Debris : [{}];
  let [{ total }] = Quote_Material?.length > 0 ? Quote_Material : [{}];
  let [{ roofingLaborSubTotal }] = Quote_Labor?.length > 0 ? Quote_Labor : [{}];
  let [{ markUp, fixedStandardStatements }] =
    Quote_Proposal?.length > 0 ? Quote_Proposal : [{}];
  let [{ deposit }] = Payment_Details?.length > 0 ? Payment_Details : [{}];

  Project_Orders.forEach((order) => {
    let { changeOrderPrice } = order;
    if (!changeOrderPrice) return project_order_total;
    project_order_total = project_order_total + order?.changeOrderPrice;
  });

  if (Quote_Gutter && Quote_Gutter.length > 0) {
    gross =
      (debrisRemovalTotal +
        roofingLaborSubTotal +
        total +
        Quote_Gutter[0].guttersSubTotal) *
      (1 + markUp / 100);
  } else {
    gross =
      (debrisRemovalTotal + roofingLaborSubTotal + total) * (1 + markUp / 100);
  }

  return gross.toFixed(2);
};

let start_payment_calc = (total, percentage) => {
  if (percentage === 0) {
    return 0;
  } else {
    return (total * percentage) / 100;
  }
};

let total_calc_without_deposit_scheduled = (data) => {
  let gross = 0;
  let project_order_total = 0;
  let {
    Quote_Debris,
    Quote_Gutter,
    Quote_Material,
    Quote_Labor,
    Quote_Proposal,
    Project_Orders,
    Payment_Details,
  } = data[0];

  let [{ debrisRemovalTotal }] = Quote_Debris?.length > 0 ? Quote_Debris : [{}];
  let [{ total }] = Quote_Material?.length > 0 ? Quote_Material : [{}];
  let [{ roofingLaborSubTotal }] = Quote_Labor?.length > 0 ? Quote_Labor : [{}];
  let [{ markUp, fixedStandardStatements }] =
    Quote_Proposal?.length > 0 ? Quote_Proposal : [{}];
  let [{ deposit }] = Payment_Details?.length > 0 ? Payment_Details : [{}];

  if (Quote_Gutter.length > 0) {
    gross =
      (debrisRemovalTotal +
        roofingLaborSubTotal +
        total +
        Quote_Gutter[0].guttersSubTotal) *
      (1 + markUp / 100);
  } else {
    gross =
      (debrisRemovalTotal + roofingLaborSubTotal + total) * (1 + markUp / 100);
  }

  return gross.toFixed(2);
};

let total_calc_without_deposit_unscheduled = (data) => {
  let gross = 0;
  let project_order_total = 0;
  let [
    {
      Quote_Carting: [{ debrisRemovalTotal }],
      Quote_Gutter,
      Quote_Material: [{ total }],
      Quote_Labor: [{ roofingLaborSubTotal }],
      Quote_Proposal: [{ markUp, fixedStandardStatements }],
      Project_Orders,
      Payment_Details: [{ deposit }],
    },
  ] = data;

  if (Quote_Gutter && Quote_Gutter.length > 0) {
    gross =
      (debrisRemovalTotal +
        roofingLaborSubTotal +
        total +
        Quote_Gutter[0].guttersSubTotal) *
      (1 + markUp / 100);
  } else {
    gross =
      (debrisRemovalTotal + roofingLaborSubTotal + total) * (1 + markUp / 100);
  }

  return gross.toFixed(2);
};
