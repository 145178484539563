import { getData, saveData } from "services/storageManager";
import { metricsActionTypes } from "./metrics.types";

import { axiosInstance, axiosInstanceForm } from "services";
import { storeImage } from "services/offlineComputing/models/offlineImageModel";
import { v4 as uuidv4 } from "uuid";
import { ApiEndpoints } from "lib/config/baseSettings";

export const addMetric = (data) => {
  return (dispatch) => {
    dispatch({ type: metricsActionTypes.ADD_METRICS_START });
    if (!navigator.onLine) {
      let prefix = "";
      if (data.metricType === "gutter") {
        prefix = "G";
      }
      try {
        const addMtric = new Promise((resolve, reject) => {
          const metricData = { ...data };
          saveData(`changes${prefix}Metric${data.requestId}`, metricData);
          resolve({ payload: { success: true } });
        });

        return addMtric
          .then((res) => {
            if (res.payload.success) {
              return dispatch({
                type: metricsActionTypes.ADD_METRICS_SUCCESS,
                payload: { success: true },
              });
            } else {
              return dispatch({
                type: metricsActionTypes.ADD_METRICS_FAILURE,
                payload: data.error,
              });
            }
          })
          .catch((err) => console.error(err));
      } catch (error) {
        console.error(error);
      }
    }

    return axiosInstance
      .post(ApiEndpoints.METRICS.ADD_METRIC, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: metricsActionTypes.ADD_METRICS_SUCCESS,
            payload: data,
          });
        } else {
          return dispatch({
            type: metricsActionTypes.ADD_METRICS_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: metricsActionTypes.ADD_METRICS_FAILURE,
          payload: errors.message,
        })
      );
  };
};
export const updateBuilding = (data) => {
  return (dispatch) => {
    dispatch({ type: metricsActionTypes.ADD_BUILDING_START });

    return axiosInstance
      .put(ApiEndpoints.METRICS.UPDATE_BUILDING, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: metricsActionTypes.ADD_BUILDING_SUCCESS,
            payload: data,
          });
        } else {
          return dispatch({
            type: metricsActionTypes.ADD_BUILDING_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: metricsActionTypes.ADD_BUILDING_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const getRoofData = (data) => {
  return (dispatch) => {
    dispatch({ type: metricsActionTypes.GET_ROOF_START });
    if (!navigator.onLine) {
      try {
        const stored_data = getData(`metrics${data}`);

        const changesMetricsString = getData(`changesMetric${data}`);
        if (changesMetricsString !== null) {
          const changesMetric = changesMetricsString;
          stored_data.data = {
            ...stored_data.data,
            metricData: changesMetric.metricData,
          };
        }

        return dispatch({
          type: metricsActionTypes.GET_ROOF_SUCCESS,
          payload: {
            result: stored_data.data,
            success: true,
          },
        });
      } catch (error) {
        console.log("storage error", error);
      }
    } else {
      return axiosInstance
        .get(`${ApiEndpoints.METRICS.GET_ROOF_DATA}${data}`)
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            return dispatch({
              type: metricsActionTypes.GET_ROOF_SUCCESS,
              payload: data,
            });
          } else {
            return dispatch({
              type: metricsActionTypes.GET_ROOF_FAILURE,
              payload: data.error,
            });
          }
        })
        .catch((errors) =>
          dispatch({
            type: metricsActionTypes.GET_ROOF_FAILURE,
            payload: errors.message,
          })
        );
    }
  };
};

export const getGuttersData = (data) => {
  return (dispatch) => {
    dispatch({ type: metricsActionTypes.GET_GUTTERS_START });
    if (!navigator.onLine) {
      try {
        const stored_data = getData(`Gmetrics${data}`);

        const changesMetricsString = getData(`changesGMetric${data}`);
        if (changesMetricsString !== null) {
          const changesMetric = changesMetricsString;
          stored_data.data = {
            ...stored_data.data,
            metricData: changesMetric.metricData,
          };
        }

        return dispatch({
          type: metricsActionTypes.GET_GUTTERS_SUCCESS,
          payload: {
            result: stored_data.data,
            success: true,
          },
        });
      } catch (error) {
        console.log("storage error", error);
      }
    } else {
      return axiosInstance
        .get(`${ApiEndpoints.METRICS.GET_GUTTERS_DATA}${data}`)
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            return dispatch({
              type: metricsActionTypes.GET_GUTTERS_SUCCESS,
              payload: data,
            });
          } else {
            return dispatch({
              type: metricsActionTypes.GET_GUTTERS_FAILURE,
              payload: data.error,
            });
          }
        })
        .catch((errors) =>
          dispatch({
            type: metricsActionTypes.GET_GUTTERS_FAILURE,
            payload: errors.message,
          })
        );
    }
  };
};

export const editTask = (data) => {
  return (dispatch) => {
    dispatch({ type: metricsActionTypes.EDIT_TASK_START });

    return axiosInstance
      .post(ApiEndpoints.METRICS.EDIT_TASK, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: metricsActionTypes.EDIT_TASK_SUCCESS,
            payload: data,
          });
        } else {
          return dispatch({
            type: metricsActionTypes.EDIT_TASK_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: metricsActionTypes.EDIT_TASK_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const completeTask = (data) => {
  return (dispatch) => {
    dispatch({ type: metricsActionTypes.DELETE_TASK_START });

    return axiosInstance
      .post(ApiEndpoints.METRICS.COMPLETE_TASK, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: metricsActionTypes.DELETE_TASK_SUCCESS,
            payload: data,
          });
        } else {
          return dispatch({
            type: metricsActionTypes.DELETE_TASK_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: metricsActionTypes.DELETE_TASK_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const getRepairRequest = (data) => {
  return (dispatch) => {
    dispatch({ type: metricsActionTypes.GET_REPAIR_REQUEST_START });
    if (!navigator.onLine) {
      try {
        let stored_data = getData(`repairMetrics${data}`);

        const changesMetricsString = getData(`changesRepair${data}`);
        if (changesMetricsString !== null) {
          const changesMetric = changesMetricsString;
          stored_data = changesMetric;
        }

        return dispatch({
          type: metricsActionTypes.GET_REPAIR_REQUEST_SUCCESS,
          payload: stored_data,
        });
      } catch (error) {
        console.log("storage error", error);
      }
    } else {
      return axiosInstance
        .post(ApiEndpoints.METRICS.GET_REPAIR_REQUEST, { ...data })
        .then((res) => res.data)
        .then((data) => {
          if (data.success) {
            return dispatch({
              type: metricsActionTypes.GET_REPAIR_REQUEST_SUCCESS,
              payload: data,
            });
          } else {
            return dispatch({
              type: metricsActionTypes.GET_REPAIR_REQUEST_FAILURE,
              payload: data.error,
            });
          }
        })
        .catch((errors) =>
          dispatch({
            type: metricsActionTypes.GET_REPAIR_REQUEST_FAILURE,
            payload: errors.message,
          })
        );
    }
  };
};
export const updateRepairRequest = (data) => {
  return (dispatch) => {
    dispatch({ type: metricsActionTypes.UPDATE_REPAIR_REQUEST_START });

    return axiosInstance
      .post(ApiEndpoints.METRICS.UPDATE_REPAIR_REQUEST, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: metricsActionTypes.UPDATE_REPAIR_REQUEST_SUCCESS,
            payload: data,
          });
        } else {
          return dispatch({
            type: metricsActionTypes.UPDATE_REPAIR_REQUEST_FAILURE,
            payload: data.error,
          });
        }
      })
      .catch((errors) =>
        dispatch({
          type: metricsActionTypes.UPDATE_REPAIR_REQUEST_FAILURE,
          payload: errors.message,
        })
      );
  };
};

export const uploadData = (data, metricId, config = {}) => {
  return (dispatch) => {
    if (!navigator.onLine) {
      return new Promise((resolve, reject) => {
        try {
          //return right result
          const result = [];
          data.getAll("uploads").map((file) => {
            const url = URL.createObjectURL(file);
            const key = uuidv4();
            storeImage(file, key, { isPushed: false, metricId: metricId });
            result.push({
              mimetype: file.type,
              name: file.name,
              size: 8197,
              thumb: `thumb/${url}`,
              uri: url,

              offlineKey: key,
            });
          });
          resolve({
            data: {
              status: true,
              message: "File(s) are uploaded successfully.",
              data: result,
            },
          });
        } catch (err) {
          console.error("offline upload data errors", err);
        }
      });
    }
    return axiosInstanceForm.post(
      ApiEndpoints.METRICS.UPLOAD_DATA,
      data,
      config
    );
  };
};
