import { basePricingActionTypes } from "./base-pricing.types";
/**
 * get all subscription information reducer
 */
const INITIAL_STATE_INFO = {
  isLoadingInfo: false,
  subscriptionInfo: null,
  infoErrors: null,
};

export const SubscriptionInfoReducer = (state = INITIAL_STATE_INFO, action) => {
  switch (action.type) {
    case basePricingActionTypes.SUBSCRIPTION_INFO_START:
      return {
        ...state,
        isLoadingInfo: true,
        subscriptionInfo: null,
        infoErrors: null,
      };

    case basePricingActionTypes.SUBSCRIPTION_INFO_SUCCESS:
      return {
        ...state,
        isLoadingInfo: false,
        subscriptionInfo: action.payload.result,
        infoErrors: null,
      };

    case basePricingActionTypes.SUBSCRIPTION_INFO_FAILURE:
      return {
        ...state,
        isLoadingInfo: false,
        subscriptionInfo: null,
        infoErrors: action.payload,
      };

    default:
      return state;
  }
};
/**
 * update  subscription information reducer
 */
const INITIAL_STATE_INFO_EDIT = {
  isLoadingInfoEdit: false,
  subscriptionInfoEdit: null,
  infoErrorsEdit: null,
};

export const SubscriptionInfoEditReducer = (
  state = INITIAL_STATE_INFO_EDIT,
  action
) => {
  switch (action.type) {
    case basePricingActionTypes.EDIT_SUBSCRIPTION_INFO_START:
      return {
        ...state,
        isLoadingInfoEdit: true,
        subscriptionInfoEdit: null,
        infoErrorsEdit: null,
      };

    case basePricingActionTypes.EDIT_SUBSCRIPTION_INFO_SUCCESS:
      return {
        ...state,
        isLoadingInfoEdit: false,
        subscriptionInfoEdit: action.payload.result,
        infoErrorsEdit: null,
      };

    case basePricingActionTypes.EDIT_SUBSCRIPTION_INFO_FAILURE:
      return {
        ...state,
        isLoadingInfoEdit: false,
        subscriptionInfoEdit: null,
        infoErrorsEdit: action.payload,
      };

    default:
      return state;
  }
};
