import { axiosInstance as axios } from "services";
import { getEndPoints } from "api/api";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const url = getEndPoints(process.env.REACT_APP_MODE);

const useLetterHead = () => {
  const [letterHeadText, setLetterHeadText] = useState();
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    get_letter_head();
  }, [letterHeadText]);

  let save_letter_head_text_to_backend = (body) => {
    setIsLoading(true);
    return axios
      .post(`${url}letterHead/text`, { ...body })
      .then((response) => {
        if (response.data.success) {
          setLetterHeadText(response.data.result);
          toast.success(response.data.result);
          return "Yayy";
        } else {
          toast.error(response.data.error);
          return "Naaaa";
        }
      })
      .catch((err) => {
        console.log({ err });
        setIsError(err);
        toast.error("Something went wrong");
        return "Hmmmm";
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  let get_letter_head = () => {
    setIsLoading(true);
    return axios
      .get(`${url}letterHead/getLetterHead`)
      .then((response) => {
        if (response.data.success) {
          return response?.data?.result ? response?.data?.result : [];
        } else {
          return [];
        }
      })
      .catch((err) => {
        setIsError(err);
        toast.error("Cannot fetch letterhead data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  let upload_image_to_letter_head = (body) => {
    setIsLoading(true);
    return axios
      .post(`${url}letterHead/image`, { ...body })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.result);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        setIsError(err);
        toast.error("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    isError,
    letterHeadText,
    save_letter_head_text_to_backend,
    get_letter_head,
    upload_image_to_letter_head,
    setIsLoading,
    setIsError,
  };
};

export default useLetterHead;
