import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import validator from "validator";
import { PriceInput } from "components";
import styles from "lib/config/baseStyles";
import QTYInput from "../components/QTYInput";

export default function MaterialMiscItem({
  data,
  deleteMiscItem,
  editMiscItem,
  isLoading,
}) {
  const handleChangeQty = (e) => {
    const value = e.target.value;

    const newTotal =
      validator.isNumeric(value) && validator.isNumeric(data.uomPrice)
        ? Number(value * data.uomPrice).toFixed(2)
        : data.total;

    const newData = changeData("qty", value);
    newData.total = newTotal;

    editMiscItem(newData);
  };

  const handlePriceChange = (value) => {
    const newTotal =
      validator.isNumeric(value) && validator.isNumeric(data.qty)
        ? Number(value * data.qty).toFixed(2)
        : data.total;
    const newData = changeData("uomPrice", value);
    newData.total = newTotal;

    editMiscItem(newData);
  };

  const onChangeSeries = (e) => {
    const newData = changeData("series", e.target.value);
    editMiscItem(newData);
  };

  const changeData = (key, value) => ({
    ...data,
    [key]: value,
  });

  return (
    <Row>
      <Col style={styles.reduceWidthCol}></Col>
      <Col style={styles.reduceWidthCol} lg="2">
        <Button variant="danger" onClick={deleteMiscItem}>
          Delete
        </Button>
      </Col>
      <Col style={styles.reduceWidthCol} lg="4">
        <Form.Group>
          <Form.Control
            type="text"
            value={data.series}
            // disabled={isLoading}
            onChange={onChangeSeries}
          />
        </Form.Group>
      </Col>

      <Col style={styles.reduceWidthCol}></Col>
      <Col style={styles.reduceWidthCol}>
        <QTYInput
          value={data.qty}
          // disabled={isLoading}
          onChange={handleChangeQty}
        />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput
          price={data.uomPrice}
          onChange={(value) => handlePriceChange(value)}
          style={{ textAlign: "right" }}
          // disabled={isLoading}
        />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput disabled={true} price={data.total} />
      </Col>
    </Row>
  );
}
