import React from "react";
import { Row, Col, Form, Alert, InputGroup, Button } from "react-bootstrap";
import RequiredText from "components/required-text/required-text";
import InitialLoader from "components/InitilalLoader";
import OtpInput from 'react-otp-input';

const newStyle = {
  formInput: {
    marginBottom: "15px",
  },
  formStyle: {
    // marginTop: "5%"
  },
  otp_label: {
    fontSize: "0.9rem",
    lineHeight: "0.9rem",
  }
};

class OtpDetails extends React.Component {
  constructor(props) {
    super(props);
    // this.onSubmit = this.onSubmit.bind(this);
    this.handleOtpChange = this.handleOtpChange.bind(this);

    this.state = {
      errMsg: "",
      errAt: "",
      errType: "warning", //danger, success, info, warning
      otp: "",
      check: false,
      isLoading: false,
      timer: 60 // Initial timer value
    };

    this.companyNameField = React.createRef();
  }

  componentDidMount() {
    // this.startTimer();
    // if (timer)
  }

  // componentWillUnmount() {
  //   clearInterval(this.timerInterval);
  // }

  // startTimer() {
  //   this.timerInterval = setInterval(() => {
  //     this.setState(prevState => ({
  //       timer: prevState.timer - 1
  //     }), () => {
  //       if (this.state.timer === 0) {
  //         clearInterval(this.timerInterval);
  //       }
  //     });
  //   }, 1000);
  // }

  isValidated() {
    const { otp, check } = this.state;
    if (!otp) {
      this.setState({
        errMsg: "Otp is required.",
        errAt: "fName",
        errType: "danger",
      });
      return false;
    }
    return true;
  }

  handleOtpChange(e) {
    const { handleChange } = this.props;
    // e.preventDefault();

    handleChange({ aOtp: e });

    this.setState({
      otp: e,
      errMsg: "",
      errAt: "",
      errType: "",
    });
  }

  handleResendOtp() {
    // Implement logic to resend OTP
    // Reset timer
    // this.setState({ timer: 60 });
    console.log({ pp: this.props });
    if (this.props.updateResendButtonStatus) {
      this.props.updateResendButtonStatus(true)
    }
    // Start timer again
  }

  render() {
    const { errType, errMsg, errAt, isLoading, otp, timer } = this.state;

    if (isLoading) {
      return <InitialLoader />;
    }
    return (
      <Form onSubmit={this.onSubmit} style={newStyle.formStyle}>
        <Row className="mt-4 mb-4 p-2">
          <Col xs="12">
            {errMsg && <Alert variant={errType}>{errMsg}</Alert>}
          </Col>
          <Col xs="12">
            <Row style={{ marginBottom: "1rem", alignItems: "flex-end" }}>

              <div className="mt-4">
                <p className="text-sm" style={newStyle.otp_label}>
                  Please check the provided email for a One-Time Password verification. Check your spam folder if not received.
                </p>
              </div>

              <Col md="6">



                <Form.Group>
                  <Form.Label className="text-xs" >
                    Enter Otp <RequiredText>*</RequiredText>
                  </Form.Label>

                  <OtpInput
                    value={otp}
                    onChange={(event) => this.handleOtpChange(event)}
                    numInputs={6}
                    separator={<span style={{ width: "20px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      // border: "1px solid transparent",
                      borderRadius: "8px",
                      width: "54px",
                      height: "54px",
                      fontSize: "12px",
                      color: "#000",
                      fontWeight: "400",
                      caretColor: "blue"
                    }}
                    focusStyle={{
                      border: "1px solid #CFD3DB",
                      outline: "none"
                    }}
                  />
                </Form.Group>

              </Col>

              <div className="mt-5" >
                <p className="text-sm">
                  Please wait for 30 seconds for the OTP to arrive.
                </p>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <p className="mb-0">{"Didn't recieve the verification OTP?"}</p>
                  <Button className="ml-4" type="button" disabled={this.props.otpSubmittedStatus} variant="link" onClick={() => this.handleResendOtp()}>
                    Resend again
                  </Button>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default OtpDetails;
