export const separate_events = (events) => {
  let obj = {};
  obj.roof = [];
  obj.gutter = [];
  obj.repair = [];
  events.forEach((event) => {
    let {
      requestObj: { isRepair, requestFor },
      metrics,
      projects,
      eventDetailsRoof,
      eventDetailsGutter,
    } = event;

    if (isRepair) {
      obj.repair.push(event);
    } else {
      if (projects.length == 2) {
        if (eventDetailsRoof) {
          let person =
            event.projects[0]?.Project_Schedules[0]?.crews[0]?.crewSupplier[0]
              ?.name;

          let start_time =
            eventDetailsRoof?.startTime !== "" &&
            Number(eventDetailsRoof?.startTime.split(":")[0]) > 12
              ? `${Number(eventDetailsRoof?.startTime.split(":")[0]) - 12}:${
                  eventDetailsRoof?.startTime.split(":")[1]
                } PM`
              : eventDetailsRoof?.startTime !== "" &&
                Number(eventDetailsRoof?.startTime.split(":")[0]) < 12
              ? `${Number(eventDetailsRoof?.startTime.split(":")[0])}:${
                  eventDetailsRoof?.startTime.split(":")[1]
                } AM`
              : "";
          let roof_object = {
            displayText1: `${start_time} ${person}`,
            displayText2: event.displayText2,
            requestObj: event.requestObj,
            persons: person,
            eventDetails: eventDetailsRoof,
            metrics: event?.metrics,
          };
          obj.roof.push(roof_object);
        }
        if (eventDetailsGutter) {
          let person =
            event.projects[0]?.Project_Schedules[0]?.crews[0]?.crewSupplier[0]
              ?.name;
          let start_time =
            eventDetailsGutter?.startTime !== "" &&
            Number(eventDetailsGutter?.startTime.split(":")[0]) > 12
              ? `${Number(eventDetailsGutter?.startTime.split(":")[0]) - 12}:${
                  eventDetailsGutter?.startTime.split(":")[1]
                } PM`
              : eventDetailsGutter?.startTime !== "" &&
                Number(eventDetailsGutter?.startTime.split(":")[0]) < 12
              ? `${Number(eventDetailsGutter?.startTime.split(":")[0])}:${
                  eventDetailsGutter?.startTime.split(":")[1]
                } AM`
              : "";
          let roof_object = {
            displayText1: `${start_time} ${person}`,
            displayText2: event.displayText2,
            requestObj: event.requestObj,
            persons: person,
            eventDetails: eventDetailsGutter,
            metrics: event?.metrics,
          };
          obj.gutter.push(roof_object);
        }
      } else {
        if (projects.length === 1 && projects[0].type === "roof") {
          if (eventDetailsRoof) {
            let person =
              event.projects[0]?.Project_Schedules[0]?.crews[0]?.crewSupplier[0]
                ?.name;
            let start_time =
              eventDetailsRoof?.startTime !== "" &&
              Number(eventDetailsRoof?.startTime.split(":")[0]) > 12
                ? `${Number(eventDetailsRoof?.startTime.split(":")[0]) - 12}:${
                    eventDetailsRoof?.startTime.split(":")[1]
                  } PM`
                : eventDetailsRoof?.startTime !== "" &&
                  Number(eventDetailsRoof?.startTime.split(":")[0]) < 12
                ? `${Number(eventDetailsRoof?.startTime.split(":")[0])}:${
                    eventDetailsRoof?.startTime.split(":")[1]
                  } AM`
                : "";
            let roof_object = {
              displayText1: `${start_time} ${person}`,
              displayText2: event.displayText2,
              requestObj: event.requestObj,
              persons: person,
              eventDetails: eventDetailsRoof,
              metrics: event?.metrics,
            };
            obj.roof.push(roof_object);
          }
        } else {
          if (eventDetailsGutter) {
            let person =
              event.projects[0]?.Project_Schedules[0]?.crews[0]?.crewSupplier[0]
                ?.name;
            let start_time =
              eventDetailsGutter?.startTime !== "" &&
              Number(eventDetailsGutter?.startTime.split(":")[0]) > 12
                ? `${
                    Number(eventDetailsGutter?.startTime.split(":")[0]) - 12
                  }:${eventDetailsGutter?.startTime.split(":")[1]} PM`
                : eventDetailsGutter?.startTime !== "" &&
                  Number(eventDetailsGutter?.startTime.split(":")[0]) < 12
                ? `${Number(eventDetailsGutter?.startTime.split(":")[0])}:${
                    eventDetailsGutter?.startTime.split(":")[1]
                  } AM`
                : "";
            let roof_object = {
              displayText1: `${start_time} ${person}`,
              displayText2: event.displayText2,
              requestObj: event.requestObj,
              persons: person,
              eventDetails: eventDetailsGutter,
              metrics: event?.metrics,
            };
            obj.gutter.push(roof_object);
          }
        }
      }
    }
  });
  return obj;
};

export const sortTimeWise = (a, b) => {
  let start = new Date(a.eventDetails.date);
  let end = new Date(a.eventDetails.date);

  const startHour = Number(a.eventDetails.startTime.split(":")[0]);
  const startMin = Number(a.eventDetails.startTime.split(":")[1]);
  const endHour = Number(a.eventDetails.endTime.split(":")[0]);
  const endMin = Number(a.eventDetails.endTime.split(":")[1]);
  start.setHours(startHour);
  start.setMinutes(startMin);
  end.setHours(endHour);
  end.setMinutes(endMin);
  let bstart = new Date(b.eventDetails.date);
  let bend = new Date(b.eventDetails.date);

  const bstartHour = Number(b.eventDetails.startTime.split(":")[0]);
  const bstartMin = Number(b.eventDetails.startTime.split(":")[1]);
  const bendHour = Number(b.eventDetails.endTime.split(":")[0]);
  const bendMin = Number(b.eventDetails.endTime.split(":")[1]);
  bstart.setHours(bstartHour);
  bstart.setMinutes(bstartMin);
  bend.setHours(bendHour);
  bend.setMinutes(bendMin);
  if (start < bstart || end < bend) return -1;
  if (start > bstart) return 1;
  return 0;
};

export const weather_manipulation = (data) => {
  return data?.week;
};

export const manipulated_hourly_data = (data) => {
  let arr = [];
  let new_data =
    data &&
    data.list &&
    data?.list.filter(
      (val) =>
        val.dt_txt.split(" ")[0] === new Date().toISOString().split("T")[0]
    );

  return new_data;
};
