import React from "react";

export default function LoadingLoader({ message }) {
  return (
    <div className="loading">
      <div className="icon" />
      <p>{message ? message : null}</p>
    </div>
  );
}
