import React, { useState } from "react";
import { Row, Col, Form, Button, Modal, Alert } from "react-bootstrap";
import "./repairs-activity.scss";
import SearchField from "components/search-field/search-field";
import moment from "moment";
import DatePicker from "react-date-picker";
import Clock from "components/clock/clock";
import Tabs from "components/tabs/RepairsTabs";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { count, stageData } from "lib/redux/sales/sales.selector";
import { getStage, changeStage } from "lib/redux/sales/sales.actions";
import InitialLoader from "components/InitilalLoader";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Select from "react-select";

import { convertDate } from "utils/utils";

import { fetchStaticAll } from "lib/redux/static-all/static-all.actions";
import {
  addTask,
  editTask,
  fetchTask,
  completeTask,
  getRepairRequest,
  updateRepairRequest,
} from "lib/redux/repairs/repairs.actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchForCalendar } from "lib/redux/calendar/calendar.actions";
import { RepairPersons } from "lib/redux/calendar/calendar.selector";
import {
  repairsStages,
  repairsTabs,
  repairsStagesChange,
  taxRates,
  newRepairs,
} from "lib/redux/static-all/static-all.selector";
import PopupModal from "components/popup-modal/popup-modal";
import MonthPickerInput from "react-month-picker-input";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import useActiveSales from "hooks/useActiveSales";
import { GetTasksRate } from "lib/redux/new-lead/new-lead.actions";
import userPermission from "hooks/usePermission";

const RepairsCard = ({
  id,
  name,
  data,
  fullAdd,
  tel,
  time,
  visit,
  type,
  created,
  repairs,
  repairsRep,
  changeStage,
  allowStageChange,
  optionalVisible,
  optionalField,
  optionalValue,
  isChanging,
  getRequest,
  isLoadingRequest,
  activeI,
}) => {
  return (
    <>
      {name?.length > 0 && (
        <div className="_sales-card">
          <div className="p-details" style={{ maxWidth: "300px" }}>
            {data?.companyName && (
              <div className="names"> {data?.companyName}</div>
            )}
            <div className="names">
              {name.length > 0 &&
                name.map((data, i) => (
                  <h5 key={i}>
                    {data.name}
                  </h5>
                ))}
            </div>
            {tel.length > 0 && (
              <div className="tels">
                {tel.map((data, i) => (
                  <div key={i} className="tel">
                    {data.phone}
                  </div>
                ))}
              </div>
            )}
            <a className="address">{fullAdd}</a>
          </div>

          <div className="o-details">
            {activeI === 0 && (
              <div className="bold">
                Time: <span className="bold">{time}</span>
              </div>
            )}
            <div className="bold">
              {activeI === 0 ? "Visit" : "Visited"}:{" "}
              <span className="bold">{moment(visit).format("LL")}</span>
            </div>

            <div>
              <label>Repair:</label> <span>{repairs}</span>
            </div>
            <div>
              <label>Type:</label> <span>{type}</span>
            </div>
            {optionalVisible && (
              <div>
                <label>{optionalField}:</label>{" "}
                <span>
                  {optionalValue === "Unpaid"
                    ? optionalValue
                    : moment(optionalValue).format("LL")}
                </span>
              </div>
            )}
            <div>
              <label>Repair Rep.:</label> <span>{repairsRep}</span>
            </div>
            <div>
              <label>Created:</label>{" "}
              <span>{moment(created).format("LL")}</span>
            </div>
          </div>
          <div className="_btns">
            <Row style={{ flexDirection: "column" }}>
              <Col style={{ marginBottom: "1em" }}>
                <Button
                  className="_btn_modified"
                  onClick={changeStage}
                  disabled={!allowStageChange || isChanging}
                >
                  Change Stage
                </Button>
              </Col>
              <Col style={{ marginTop: "1em" }}>
                <Button className="_btn_modified">
                  <a
                    href={`https://www.google.com/maps/place?q=${fullAdd}`}
                    className="address"
                    // style={{ textDecoration: "underline" }}
                    target="_blank"
                  >
                    Directions
                  </a>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="_btns">
            <Button
              className="_btn_modified"
              disabled={!repairs || isLoadingRequest}
              onClick={() => getRequest(id)}
            >
              Open Repairs
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
const RepairsCardNotUpon = ({
  allowStageChange,
  paymentStatus,
  isChanging,
  isLoadingRequest,
  activeI,
  stageBeforeData,
  getBeforeRequest,
  changeStageBefore,
  active,
}) => {
  return (
    <>
      {active === "repairStage102" && (
        <>
          <h5 style={{ margin: "7% 2% 3%" }}>Appointments Not Acted Upon</h5>
          {stageBeforeData?.length > 0 ? (
            stageBeforeData?.map((res) => {
              return (
                <div className="_sales-card">
                  <div className="p-details" style={{ maxWidth: "300px" }}>
                    {res?.companyName && (
                      <div className="names"> {data?.companyName}</div>
                    )}
                    <div className="names">
                      {res?.name?.length > 0 &&
                        res?.name.map((data, i) => (
                          <h5 key={i}>{data.name}</h5>
                        ))}
                    </div>
                    {res?.phone?.length > 0 && (
                      <div className="tels">
                        {res?.phone?.map((data, i) => (
                          <div key={i} className="tel">
                            {data.phone}
                          </div>
                        ))}
                      </div>
                    )}
                    <a
                      // href=  `` "https://www.google.com/maps/place/"
                      href={`https://www.google.com/maps/place?q=${res?.address}`}
                      className="address"
                      style={{ textDecoration: "underline" }}
                      target="_blank"
                    >
                      {res?.address}
                    </a>
                  </div>

                  <div className="o-details">
                    {activeI === 0 && (
                      <div className="bold">
                        Time: <span className="bold">{res?.time}</span>
                      </div>
                    )}
                    <div className="bold">
                      {activeI === 0 ? "Visit" : "Visited"}:{" "}
                      <span className="bold">
                        {moment(res?.visit).format("LL")}
                      </span>
                    </div>

                    <div>
                      <label>Repair:</label> <span>{res?.repair}</span>
                    </div>
                    <div>
                      <label>Type:</label> <span>{res?.type}</span>
                    </div>
                    {res?.optionalVisible && (
                      <div>
                        <label>{res?.optionalField}:</label>{" "}
                        <span>
                          {res?.optionalValue === "Unpaid"
                            ? res?.optionalValue
                            : moment(res?.optionalValue).format("LL")}
                        </span>
                      </div>
                    )}
                    <div>
                      <label>Repair Rep.:</label> <span>{res?.persons}</span>
                    </div>
                    <div>
                      <label>Created:</label>{" "}
                      <span>{moment(res?.created).format("LL")}</span>
                    </div>
                  </div>
                  <div className="_btns">
                    <Row style={{ flexDirection: "column" }}>
                      <Col style={{ marginBottom: "1em" }}>
                        <Button
                          className="_btn_modified"
                          onClick={() => changeStageBefore(res?.requestId)}
                          disabled={
                            !allowStageChange ||
                            isChanging ||
                            paymentStatus == "Frozen 1"
                          }
                        >
                          Change Stage
                        </Button>
                      </Col>
                      <Col style={{ marginTop: "1em" }}>
                        <Button className="_btn_modified">
                          <a
                            // href=  `` "https://www.google.com/maps/place/"
                            href={`https://www.google.com/maps/place?q=${res?.address}`}
                            className="address"
                            // style={{ textDecoration: "underline" }}
                            target="_blank"
                          >
                            Directions
                          </a>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className="_btns">
                    <Button
                      className="_btn_modified"
                      disabled={
                        !res?.repair ||
                        isLoadingRequest ||
                        paymentStatus == "Frozen 1"
                      }
                      onClick={() => getBeforeRequest(res?.requestId)}
                    >
                      Open Repairs
                    </Button>
                  </div>
                </div>
              );
            })
          ) : (
            <h6 style={{ textAlign: "center", marginBottom: "2%" }}>
              No Previous Appointments
            </h6>
          )}
        </>
      )}
    </>
  );
};
const TaskCard = ({
  dateChoosen,
  handleDateChangesave,
  // handleChange,
  setTaskName,
  taskName,
  addTask,
  onCancel,
  isSubmitting,
  handleOnClicksaveAdd,
}) => (
  <Row style={{ alignItems: "flex-start" }}>
    <Col lg="2" md="1">
      <div className="_title">Created</div>
      <div className="created-date">{convertDate(Date.now())}</div>
    </Col>
    <Col lg="2" md="4">
      <div className="_title">Due Date</div>
      <div>
        <Datepicker
          selected={new Date(dateChoosen)}
          onChange={(date) => handleDateChangesave(date)}
          className="_datePicker"
        />
      </div>
    </Col>
    <Col lg="4" md="3">
      <Form.Group>
        <div className="_title">Task Detail</div>
        <Form.Control
          as="textarea"
          row="2"
          name="taskName"
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
        />
      </Form.Group>
    </Col>
    <Col
      lg="4"
      md="4"
      // style={{ paddingTop: "1.5em" }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingTop: "1.5em",
      }}
    >
      <Row>
        <Col lg="6" md="6">
          <Button
            onClick={handleOnClicksaveAdd}
            disabled={!taskName.trim() || isSubmitting}
            style={{ width: "10rem" }}
          >
            {isSubmitting ? "Saving..." : "Save"}
          </Button>
        </Col>
        <Col lg="6" md="6">
          <Button
            style={{ margin: "auto 1em", width: "10rem" }}
            onClick={onCancel}
            variant="danger"
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Col>
  </Row>
);

const RepairsActivity = (props) => {
  const [activeI, setActiveI] = useState(0);
  const [active, setActive] = useState("repairStage102");
  const [page, setPage] = useState(1);
  const [date, setDate] = useState([new Date()]);
  const [modal, setModal] = useState(false);
  const [countt, setCount] = useState([0, 0, 0, 0]);
  const [filterYear, setFilterYear] = useState(new Date().getFullYear());
  const [filterMonth, setFilterMonth] = useState(new Date().getMonth());
  const [requestId, setRequestId] = useState("");
  const [msgType, setMsgType] = useState("");
  const [msgFor, setMsgFor] = useState("!modal");

  const [msg, setMsg] = useState("");
  const [searchString, setSearchString] = useState("");
  const [selectPerson, setSelectPerson] = useState([]);
  const [dateChoosen, setDateChoosen] = useState(new Date());
  const [pickFirst, setPickFirst] = useState(true);
  // const [permission, setPermission] = useState({});
  const [tasks, setTasks] = useState([]);
  const [addTask, setAddTask] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [editName, setEditName] = useState("");
  const [editDate, setEditDate] = useState("");
  const [pickCustomTab, setPickCustomTab] = useState(false);
  const [requestFor, setRequestFor] = useState([]);
  const [repair06Name, setRepair06Name] = useState("");
  const [originalWorkPerformed, setOriginalWorkPerformed] = useState(false);
  const [WorkPerformedYear, setWorkPerformedYear] = useState("");
  const [isUnderWarrenty, setIsUnderWarrenty] = useState(false);
  const [repairServicePrice, setRepairServicePrice] = useState(0);
  const [taxRate, setTaxRate] = useState("");
  const [nrTotal, setNrTotal] = useState(0);
  const [repairDescription, setRepairDescription] = useState("");
  const [showServiceRequestModal, setShowServiceRequestModal] = useState(false);
  const [taxRates, setTaxRates] = useState([]);
  //#region online offline handle
  const [isOnline, setIsOnline] = useState(true);

  const setOnline = () => {
    console.log("setting is online");
    setIsOnline(true);
  };

  const setOffline = () => {
    console.log("setting is offline");
    setIsOnline(false);
  };

  useEffect(() => {
    addEventListener("offline", setOffline);
    addEventListener("online", setOnline);

    return () => {
      removeEventListener("offline", setOffline);
      removeEventListener("online", setOnline);
    };
  }, []);

  //#endregion online offline handle

  const { gettingPermission, permission, permissionErr } = useSelector(
    (state) => state.allPermissions
  );
  const { getStageBeforeData, stageBeforeData } = useActiveSales();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetTasksRate());
  }, []);

  const { tasksRate } = props;
  useEffect(() => {
    setTaxRates(tasksRate?.taskratedata);
  }, [tasksRate]);
  const { activitySalesPointerEvent } = userPermission(permission);
  const getPersons = (selectPerson) => {
    let data = [];
    if (selectPerson) {
      selectPerson.map((per) => data.push(per.value));
    }
    return data;
  };

  const getStagesData = (repairsTabs) => {
    if (!repairsTabs) return;

    let data = [];
    // const { date } = state;
    repairsTabs.map(({ repairStageId }, i) =>
      data.push({
        stageId: repairStageId,
        date: i < 2 ? convertDate(date[i]) : convertFilterDate(date[i]),
      })
    );
    return data;
  };

  const convertFilterDate = (date) => {
    if (date) {
      const month = Number(date.split("/")[0]) + 1;
      const year = date.split("/")[1];
      return `${month}/${year}`;
    }
    return `${new Date().getMonth() + 1}/${new Date().getFullYear()}`;
  };

  const addIntoDate = (repairsTabs) => {
    let date = [];
    repairsTabs.map((data, i) => {
      const formattedDate =
        i < 2
          ? new Date()
          : `${new Date().getMonth()}/${new Date().getFullYear()}`;
      return date.push(formattedDate);
    });
    setDate(date);
  };

  const changeDate = (i, date) => {
    const temp = [date];
    temp.splice(i, 1, date);
    setDate(temp);
  };

  const loadData = () => {
    props.fetchForCalendar();
    props.fetchStaticAll();
    props.fetchTask();
  };

  useEffect(() => {
    loadData();
  }, []);

  const addIntoTasks = (data) => {
    let temp = [];
    data?.map((item) => temp.push({ ...item, edit: false }));
    setTasks(temp);
  };

  const loadStageData = () => {
    const { data, repairsTabs } = props;
    const formattedDate =
      activeI < 2
        ? convertDate(date[activeI])
        : convertFilterDate(date[activeI]);

    if (active === "customtab01") {
      if (selectPerson !== null && selectPerson.length > 0) {
        let newTasks = data.filter((task) =>
          selectPerson.some((person) => person.value === task.person)
        );
        addIntoTasks(newTasks);
      } else {
        addIntoTasks(data);
      }
    }
    return (
      props.getStage({
        isRepair: true,
        searchString: searchString,
        persons: getPersons(selectPerson),
        stageId: active,
        date: formattedDate,
        page: page,
        stages: getStagesData(repairsTabs),
      }),
      getStageBeforeData({
        isRepair: true,
        searchString: searchString,
        persons: getPersons(selectPerson),
        stageId: active,
        date: formattedDate,
        page: page,
        stages: getStagesData(repairsTabs),
      })
    );
  };

  const {
    data,
    errors,
    submitSuccess,
    submitFail,
    editingSuccess,
    editingFail,
    deletingSuccess,
    deletingFail,
    changeFail,
    changeSuccess,
    staticErrors,
    dataErrors,
    updateReqFail,
    updateReqSuccess,
    errorsRequest,
    dataRequest,
    repairsTabs,
    count,
  } = props;

  const formattedDate =
    activeI < 2 ? convertDate(date[activeI]) : convertFilterDate(date[activeI]);
  useEffect(() => {
    if (selectPerson && repairsTabs) {
      if (selectPerson !== null && selectPerson.length > 0) {
        let newTasks = data.filter((task) =>
          selectPerson.some((person) => person.value === task.person)
        );
        addIntoTasks(newTasks);
      } else {
        addIntoTasks(data);
      }
      props.getStage({
        isRepair: true,
        searchString: searchString,
        persons: getPersons(selectPerson),
        stageId: active,
        date: formattedDate,
        page: page,
        stages: getStagesData(repairsTabs),
      });
    }
  }, [selectPerson, repairsTabs]);

  useEffect(() => {
    if (repairsTabs) {
      addIntoDate(repairsTabs);
      props.getStage({
        isRepair: true,
        searchString: searchString,
        persons: getPersons(selectPerson),
        stageId: active,
        date: formattedDate,
        page: page,
        stages: getStagesData(repairsTabs),
      });
      getStageBeforeData({
        isRepair: true,
        searchString: searchString,
        persons: getPersons(selectPerson),
        stageId: active,
        date: formattedDate,
        page: page,
        stages: getStagesData(repairsTabs),
      });
    }
  }, [repairsTabs]);

  useEffect(() => {
    // if (data !== prevProps.data) {
    if (data) {
      if (selectPerson !== null && selectPerson.length > 0) {
        let newTasks = data.filter((task) =>
          selectPerson.some((person) => person.value === task.person)
        );
        addIntoTasks(newTasks);
      } else {
        addIntoTasks(data);
      }
    }
    // }
  }, [data]);

  useEffect(() => {
    if (submitSuccess) {
      setMsg(submitSuccess);
      setMsgType("success");
      setAddTask(false);
      setDateChoosen(new Date());
      setTaskName("");
      props.fetchTask();
      clearMsg();
    }
  }, [submitSuccess]);

  useEffect(() => {
    //  if (dataRequest !== prevProps.dataRequest) {
    if (dataRequest) {
      const {
        requestFor,
        originalWorkYear,
        repairDescreption,
        requestForOther,
        servicePrice,
        taxRate,
        totalPrice,
        underWarrenty,
        originalWorkDone,
      } = dataRequest;
      setShowServiceRequestModal(true);
      setRequestFor(requestFor);
      setRepair06Name(requestForOther);
      setOriginalWorkPerformed(`${originalWorkDone}`);
      setWorkPerformedYear(originalWorkYear);
      setIsUnderWarrenty(`${underWarrenty}`);
      setRepairServicePrice(servicePrice.replace("$", ""));
      setTaxRate(taxRate);
      setNrTotal(totalPrice.replace("$", ""));
      setRepairDescription(repairDescreption);
    }
    // }
  }, [dataRequest]);

  useEffect(() => {
    if (updateReqSuccess && repairsTabs) {
      setMsg(updateReqSuccess);
      setMsgType("success");
      // setState({ msg: updateReqSuccess, msgType: "success" });
      clearMsg();
      hideSRModal();
      props.getStage({
        isRepair: true,
        searchString: searchString,
        persons: getPersons(selectPerson),
        stageId: active,
        date: formattedDate,
        page: page,
        stages: getStagesData(repairsTabs),
      });
    }
  }, [updateReqSuccess, repairsTabs]);

  useEffect(() => {
    if (editingSuccess) {
      setMsg(editingSuccess);
      setMsgType("success");
      props.fetchTask();
      clearMsg();
    }
  }, [editingSuccess]);

  useEffect(() => {
    if (deletingSuccess) {
      setMsg(deletingSuccess);
      setMsgType("success");
      props.fetchTask();
      clearMsg();
    }
  }, [deletingSuccess]);

  useEffect(() => {
    if (updateReqFail) {
      setMsg(updateReqFail);
      setMsgType("danger");
      setMsgFor("modal");
      // setState({ msg: updateReqFail, msgType: "danger", msgFor: "modal" });
      clearMsg();
    }
  }, [updateReqFail]);

  useEffect(() => {
    if (errorsRequest) {
      setMsg(errorsRequest);
      setMsgType("danger");
      // setState({ msg: errorsRequest, msgType: "danger" });
      clearMsg();
    }
  }, [errorsRequest]);

  useEffect(() => {
    if (submitFail) {
      setMsg(submitFail);
      setMsgType("danger");
      clearMsg();
    }
  }, [submitFail]);

  useEffect(() => {
    if (staticErrors) {
      setMsg(staticErrors);
      setMsgType("danger");
      clearMsg();
    }
  }, [staticErrors]);

  useEffect(() => {
    if (errors) {
      setMsg(errors);
      setMsgType("danger");
      clearMsg();
    }
  }, [errors]);

  useEffect(() => {
    if (editingFail) {
      setMsg(editingFail);
      setMsgType("danger");
      clearMsg();
    }
  }, [editingFail]);

  useEffect(() => {
    if (deletingFail) {
      setMsg(deletingFail);
      setMsgType("danger");
      clearMsg();
    }
  }, [deletingFail]);

  useEffect(() => {
    if ((filterMonth || filterYear) && repairsTabs) {
      props.getStage({
        isRepair: true,
        searchString: searchString,
        persons: getPersons(selectPerson),
        stageId: active,
        date: formattedDate,
        page: page,
        stages: getStagesData(repairsTabs),
      });
    }
  }, [filterMonth, filterYear, repairsTabs]);

  useEffect(() => {
    if (date && repairsTabs) {
      props.getStage({
        isRepair: true,
        searchString: searchString,
        persons: getPersons(selectPerson),
        stageId: active,
        date: formattedDate,
        page: page,
        stages: getStagesData(repairsTabs),
      });
    }
  }, [date, repairsTabs]);

  useEffect(() => {
    if (active && repairsTabs) {
      loadStageData();
    }
  }, [active, repairsTabs]);

  useEffect(() => {
    if (searchString && repairsTabs) {
      props.getStage({
        isRepair: true,
        searchString: searchString,
        persons: getPersons(selectPerson),
        stageId: active,
        date: formattedDate,
        page: page,
        stages: getStagesData(repairsTabs),
      });
    }
  }, [searchString, repairsTabs]);

  useEffect(() => {
    if (page && repairsTabs) {
      props.getStage({
        isRepair: true,
        searchString: searchString,
        persons: getPersons(selectPerson),
        stageId: active,
        date: formattedDate,
        page: page,
        stages: getStagesData(repairsTabs),
      });
    }
  }, [page, repairsTabs]);

  useEffect(() => {
    if (dataErrors) {
      setMsg(dataErrors);
      setMsgType("danger");
      clearMsg();
    }
  }, [dataErrors]);

  useEffect(() => {
    if (changeFail) {
      setMsg(changeFail);
      setMsgType("danger");
      clearMsg();
    }
  }, [changeFail]);

  useEffect(() => {
    if (changeSuccess && repairsTabs) {
      setMsg(changeSuccess);
      setMsgType("success");
      clearMsg();
      onHide();
      props.getStage({
        isRepair: true,
        searchString: searchString,
        persons: getPersons(selectPerson),
        stageId: active,
        date: formattedDate,
        page: page,
        stages: getStagesData(repairsTabs),
      });
    }
  }, [changeSuccess, repairsTabs]);

  useEffect(() => {
    if (count.length > 0) {
      setCount([]);
      if (count.length > 0) {
        count?.map((data) => {
          return setCount(...count, data.count);
        });
      } else {
        setCount([0, 0, 0, 0, tasks.length]);
      }
    }
  }, [count]);

  useEffect(() => {
    if (originalWorkPerformed) {
      if (!isTrue(originalWorkPerformed)) {
        setIsUnderWarrenty(false);
        setWorkPerformedYear("");
      }
    }
  }, [originalWorkPerformed]);

  useEffect(() => {
    if (isUnderWarrenty) {
      if (isTrue(isUnderWarrenty)) {
        setNrTotal(0);
        setTaxRate("");
        setRepairServicePrice(0);
      }
    }
  }, [isUnderWarrenty]);

  useEffect(() => {
    if (repairServicePrice) {
      if (!isNaN(Number(repairServicePrice))) {
        setNrTotal(
          Number(repairServicePrice) < 0
            ? 0
            : Number(repairServicePrice) +
            (Number(taxRate) / 100) * Number(repairServicePrice)
        );
      } else {
        setNrTotal(0);
      }
    }
  }, [repairServicePrice]);

  useEffect(() => {
    if (taxRate) {
      if (!isNaN(repairServicePrice)) {
        setNrTotal(
          Number(repairServicePrice) < 0
            ? 0
            : Number(repairServicePrice) +
            (Number(taxRate) / 100) * Number(repairServicePrice)
        );
      } else {
        setNrTotal(Number(taxRate) / 100);
      }
    }
  }, [taxRate]);

  useEffect(() => {
    if (requestFor.length > 0) {
      if (!requestFor.includes("repair06")) {
        setRepair06Name("");
      }
    }
  });

  // }

  const clearMsg = () =>
    setTimeout(
      () => {
        setMsg("");
        setMsgType("");
      },
      10000
    );

  const displayTaxRate = (data) => {
    return data.map((item) => (
      <option key={item.taxId} value={item.taxNum}>
        {item.taxText}
      </option>
    ));
  };

  const displayRepairType = (requests) => {
    if (requests) {
      return requests.map((request) => (
        <button
          key={request.repairTypeId}
          className={`serviceRequestBtn ${requestFor.includes(request.repairTypeId) && "selected"
            }`}
          onClick={() => makeSelection(request.repairTypeId)}
        >
          {request.repairTypeName}
        </button>
      ));
    }
    return;
  };

  const displayYesNo = (items) => {
    return items.map((item) => (
      <option key={item.name} value={item.value}>
        {item.name}
      </option>
    ));
  };

  const validateServiceRequestModalFields = () => {

    if (requestFor.length === 0) {
      return true;
    }

    if (requestFor.includes("repair06") && !repair06Name) {
      return true;
    }

    if (!isTrue(originalWorkPerformed) && !repairDescription) {
      return true;
    }

    if (isTrue(originalWorkPerformed) && !WorkPerformedYear) {
      return true;
    }

    if (
      isTrue(originalWorkPerformed) &&
      WorkPerformedYear !== "" &&
      (isNaN(WorkPerformedYear) || Number(WorkPerformedYear) < 1000)
    ) {
      return true;
    }

    if (
      !isTrue(isUnderWarrenty) &&
      (!nrTotal || !taxRate || !repairServicePrice)
    ) {
      return true;
    }

    return false;
  };

  const checkIsUnderWarrenty = (originalWorkPerformed, isUnderWarrenty) => {
    if (isTrue(originalWorkPerformed) && isTrue(isUnderWarrenty)) {
      return false;
    }
    return true;
  };

  const handleServicePriceChange = (event) =>
    setRepairServicePrice(event.target.value.replace("$", ""));

  const makeSelection = (name) => {
    if (requestFor.includes(name)) {
      let temp = [...requestFor];
      const index = requestFor.indexOf(name);
      temp.splice(index, 1);
      setRequestFor(temp);
    }
    setRequestFor([...requestFor, name]);
  };

  const displayNewRepairOptions = () => {
    // const { taxRates } = props;
    return (
      <div>
        <b>Repair is for:</b>
        <div style={{ margin: "1em auto", width: "100%" }}>
          {displayRepairType(props.newRepairs)}

          <Row>
            <Col md="12" sm="12">
              {requestFor.includes("repair06") && (
                <Row>
                  <Col md="6" sm="6">
                    <Form.Group style={styles.formInput}>
                      <Form.Control
                        type="text"
                        name="repair06Name"
                        placeholder="Enter Other"
                        value={repair06Name}
                        onChange={(e) => setRepair06Name(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm="8" md="6">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Did we perform original work?</Form.Label>
                    <Form.Control
                      as="select"
                      name="originalWorkPerformed"
                      value={originalWorkPerformed}
                      onChange={(e) => setOriginalWorkPerformed(e.target.value)}
                    >
                      {displayYesNo(YesNo)}
                    </Form.Control>
                  </Form.Group>
                </Col>
                {isTrue(originalWorkPerformed) && (
                  <>
                    <Col sm="3" md="3">
                      <Form.Group style={styles.formInput}>
                        <Form.Label>Year</Form.Label>
                        <Form.Control
                          type="text"
                          name="WorkPerformedYear"
                          value={WorkPerformedYear}
                          onChange={(e) => setWorkPerformedYear(e.target.value)}
                          maxLength={4}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="6" md="3">
                      <Form.Group style={styles.formInput}>
                        <Form.Label>Is it under Warranty?</Form.Label>
                        <Form.Control
                          as="select"
                          name="isUnderWarrenty"
                          value={isUnderWarrenty}
                          onChange={() => setIsUnderWarrenty(e.target.value)}
                        >
                          {displayYesNo(YesNo)}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>

              {checkIsUnderWarrenty(originalWorkPerformed, isUnderWarrenty) && (
                <Row>
                  <Col sm="6" md="6">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Repair/Service Price</Form.Label>
                      <Form.Control
                        type="text"
                        name="repairServicePrice"
                        placeholder="Pre Tax $"
                        value={`$${repairServicePrice}`}
                        onChange={handleServicePriceChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="6" md="3">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Tax Rate</Form.Label>
                      <Form.Control
                        as="select"
                        name="taxRate"
                        value={taxRate}
                        onChange={(e) => setTaxRate(e.target.value)}
                      >
                        <option value="">Choose...</option>
                        {taxRates &&
                          taxRates.length > 0 &&
                          displayTaxRate(taxRates)}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col sm="6" md="3">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Total</Form.Label>
                      <Form.Control
                        type="text"
                        value={`$${nrTotal}`}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Description of Repair</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="2"
                      name="repairDescription"
                      value={repairDescription}
                      onChange={(e) => setRepairDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const onServiceRequestModalCancel = () => {
    setRequestFor("");
    setRepair06Name("");
    setOriginalWorkPerformed(false);
    setWorkPerformedYear("");
    setIsUnderWarrenty(false);
    setRepairServicePrice("");
    setTaxRate("");
    setNrTotal(0);
    setRepairDescription("");
    hideSRModal();
  };

  const hideSRModal = () => {
    setShowServiceRequestModal(false);
  };

  const handleDateChange = (date, name = "dateChoosen") => {
    setEditDate(date);
  };
  const handleDateChangesave = (date, name = "dateChoosen") => {
    setDateChoosen(date);
  };

  const handleChangeStage = (requestId, stageId) => {
    props.changeStage({
      requestId,
      stageId,
    });
  };

  const getRequest = (requestId) => {
    props.getRepairRequest({
      requestId,
    });
  };

  const renderFilter = (date, i) => {
    const year = Number(date.split("/")[1]);
    const month = Number(date.split("/")[0]);

    return (
      <Row>
        <Col
          style={{
            margin: "1em auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <div>
            <h6>Filter By Month</h6>
            <MonthPickerInput
              year={year}
              month={month}
              onChange={(maskedValue, selectedYear, selectedMonth) => {
                changeDateYear(selectedYear, selectedMonth, i);
              }}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const changeDateYear = (year, month, i) => {
    // setState((state) => {
    const temp = [...date];
    temp.splice(i, 1, `${month}/${year}`);
    setDate(temp);
    // return { date: temp };
    // });
  };

  const dateChange = (date) => changeDate(0, date);

  const closeAllEdit = () => {
    // const { tasks } = state;
    let temp = [...tasks];
    temp.map((task) => (task.edit = false));
    setTasks(temp);
    // setState({ tasks: temp });
  };
  const editTask = (id) => {
    // const { tasks } = state;
    let temp = [...tasks];
    const { edit, ...rest } = tasks[id];
    if (!edit) {
      closeAllEdit();
      setAddTask(false);
      setEditDate(rest.dueDate);
      setEditName(rest.details);
      // setState({
      //   addTask: false,
      //   editDate: rest.dueDate,
      //   editName: rest.details,
      // });
    }
    temp.splice(id, 1, { edit: !edit, ...rest });
    setTasks(temp);
    // setState({ tasks: temp });
  };
  const handleEdit = (created, id, idx) => {
    // const { editDate, editName } = state;

    props.editTask(
      {
        _id: id,
        details: editName,
        dueDate: convertDate(editDate),
      },
      idx
    );
    // props.fetchTask();
  };
  const handleOnClicksaveAdd = () => {
    props.addTask({
      details: taskName,
      dueDate: dateChoosen,
    });
  };
  const completeTask = (id, idx) => {
    props.completeTask(
      {
        _id: id,
      },
      idx
    );
  };
  const clearFields = () => {
    setTaskName("");
    setDateChoosen(new Date());
  };
  const displayTasks = () => {
    if (tasks.length === 0) {
      return (
        <div className="empty-block">
          <label>No Task.</label>
        </div>
      );
    }
    return (
      <TransitionGroup>
        {tasks.length > 0 &&
          tasks.map((task, i) => (
            <CSSTransition key={task._id} timeout={500}>
              <Row style={{ alignItems: "flex-start" }} key={task._id}>
                <Col lg="2" md="1">
                  <div className="_title">Created</div>
                  <div className="created-date">{task.created}</div>
                </Col>
                <Col lg="2" md="4">
                  <div className="_title">Due Date</div>
                  <div>
                    {task.edit ? (
                      <Datepicker
                        selected={new Date(editDate)}
                        onChange={(date) => handleDateChange(date, "editDate")}
                        className="_datePicker"
                        disabled={!task.edit}
                      />
                    ) : (
                      <Datepicker
                        selected={new Date(task.dueDate)}
                        className="_datePicker"
                        disabled={!task.edit}
                      />
                    )}
                  </div>
                </Col>
                <Col lg="4" md="3">
                  <Form.Group>
                    <div className="_title">Task Detail</div>

                    {task.edit ? (
                      <Form.Control
                        name="editName"
                        as="textarea"
                        row="1"
                        value={editName}
                        disabled={!task.edit}
                        onChange={(e) => setEditName(e.target.value)}
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        as="textarea"
                        row="1"
                        value={task.details}
                        disabled={!task.edit}
                      />
                    )}
                  </Form.Group>
                </Col>
                <Col
                  lg="4"
                  md="4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingTop: "1.5em",
                  }}
                >
                  <Row>
                    {task.edit ? (
                      <>
                        <Col lg="6" md="6">
                          <Button
                            variant="danger"
                            onClick={() => editTask(i)}
                            style={{ width: "10rem" }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col lg="6" md="6">
                          <Button
                            onClick={() =>
                              handleEdit(task.created, task._id, i)
                            }
                            disabled={editName === "" || isEditing}
                            style={{ margin: "auto 1em", width: "10rem" }}
                            variant="outline-success"
                          >
                            {isEditing ? "Saving..." : "Save"}
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg="6" md="6">
                          <Button
                            variant="warning"
                            onClick={() => editTask(i)}
                            style={{ width: "10rem" }}
                          >
                            Edit
                          </Button>
                        </Col>
                        <Col lg="6" md="6">
                          <Button
                            // disabled={isDeleting}
                            style={{ margin: "auto 1em", width: "10rem" }}
                            onClick={() => completeTask(task._id, i)}
                          >
                            Complete/Delete
                          </Button>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </CSSTransition>
          ))}
      </TransitionGroup>
    );
  };

  const displayFooter = () => {
    const styles = {
      display: "flex",
      justifyContent: "flex-end",
    };
    const { isupdatingReq } = props;
    return (
      <Modal.Footer>
        <div style={{ ...styles, width: "100%" }}>
          <div style={styles}>
            <div style={{ marginRight: "5px" }}>
              <Button
                variant="outline-danger"
                onClick={onServiceRequestModalCancel}
              >
                Cancel
              </Button>
            </div>
            <div style={{ marginLeft: "5px" }}>
              <Button
                variant={"success"}
                disabled={validateServiceRequestModalFields() || isupdatingReq}
                onClick={saveServiceRequest}
              >
                {isupdatingReq ? "Saving..." : "Save Changes"}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    );
  };

  const saveServiceRequest = () => {
    const {
      updateRepairRequest,
      dataRequest: { requestId },
    } = props;

    updateRepairRequest({
      requestId: requestId,
      requestFor: requestFor,
      requestForOther: repair06Name,
      originalWorkDone: isTrue(originalWorkPerformed),
      originalWorkYear: WorkPerformedYear,
      underWarrenty: isTrue(isUnderWarrenty),
      servicePrice: `${repairServicePrice === 0 ? "" : `$${repairServicePrice}`
        }`,
      taxRate: taxRate,
      totalPrice: `${nrTotal === 0 ? "" : `$${nrTotal}`}`,
      repairDescreption: repairDescription,
    });
  };

  const isTrue = (data) => JSON.parse(data);

  const displayPersons = (persons) => {
    let Persons = [];
    persons.map(({ personId, personName }) => {
      return Persons.push({
        value: personId,
        label: personName,
      });
    });
    return Persons;
  };

  const displayStageData = (stageData, repairsStages) => {
    if (stageData !== null) {
      if (stageData.data.length === 0 && active !== "repairStage102") {
        return renderEmptyTab();
      }
      return stageData.data.length > 0
        ? stageData.data.map((data) => {
          return renderData(data, repairsStages);
        })
        : renderData("", repairsStages);
    }
  };

  const renderData = (data, repairsStages) => {
    // const { active, activeI } = state;
    const { isChanging, isLoadingRequest } = props;

    switch (active) {
      case repairsStages[0].repairStageId:
        return (
          <RepairsCard
            key={data.requestId}
            id={data.requestId}
            data={data}
            name={data.name}
            address1={""}
            fullAdd={data.address}
            tel={data.phone}
            time={data.time}
            visit={data.visit}
            created={data.created}
            repairs={data.repair}
            type={data.type}
            repairsRep={data.persons}
            window
            gutters
            optionalVisible={data.optionalVisible}
            optionalField={data.optionalField}
            optionalValue={data.optionalValue}
            changeStage={() => changeStage(data.requestId)}
            allowStageChange={repairsStages[0].showInChange}
            isChanging={isChanging}
            getRequest={() => getRequest(data.requestId)}
            isLoadingRequest={isLoadingRequest}
            activeI={activeI}
            stageBeforeData={stageBeforeData}
            changeStageBefore={changeStage}
            getBeforeRequest={getRequest}
            active={active}
          />
        );

      case repairsStages[1].repairStageId:
        return (
          <RepairsCard
            key={data.requestId}
            id={data.requestId}
            data={data}
            name={data.name}
            address1={""}
            fullAdd={data.address}
            tel={data.phone}
            time={data.time}
            visit={data.visit}
            created={data.created}
            repairs={data.repair}
            type={data.type}
            repairsRep={data.persons}
            window
            gutters
            optionalVisible={data.optionalVisible}
            optionalField={data.optionalField}
            optionalValue={data.optionalValue}
            changeStage={() => changeStage(data.requestId)}
            allowStageChange={repairsStages[1].showInChange}
            isChanging={isChanging}
            getRequest={() => getRequest(data.requestId)}
            isLoadingRequest={isLoadingRequest}
            activeI={activeI}
          />
        );

      case repairsStages[2].repairStageId:
        return (
          <RepairsCard
            key={data.requestId}
            id={data.requestId}
            data={data}
            name={data.name}
            address1={""}
            fullAdd={data.address}
            tel={data.phone}
            time={data.time}
            visit={data.visit}
            created={data.created}
            repairs={data.repair}
            type={data.type}
            repairsRep={data.persons}
            window
            gutters
            optionalVisible={data.optionalVisible}
            optionalField={data.optionalField}
            optionalValue={data.optionalValue}
            changeStage={() => changeStage(data.requestId)}
            allowStageChange={repairsStages[2].showInChange}
            isChanging={isChanging}
            getRequest={() => getRequest(data.requestId)}
            isLoadingRequest={isLoadingRequest}
            activeI={activeI}
          />
        );

      case repairsStages[3].repairStageId:
        return (
          <RepairsCard
            key={data.requestId}
            id={data.requestId}
            data={data}
            name={data.name}
            address1={""}
            fullAdd={data.address}
            tel={data.phone}
            time={data.time}
            visit={data.visit}
            created={data.created}
            repairs={data.repair}
            type={data.type}
            repairsRep={data.persons}
            window
            gutters
            optionalVisible={data.optionalVisible}
            optionalField={data.optionalField}
            optionalValue={data.optionalValue}
            changeStage={() => changeStage(data.requestId)}
            allowStageChange={repairsStages[3].showInChange}
            isChanging={isChanging}
            getRequest={() => getRequest(data.requestId)}
            isLoadingRequest={isLoadingRequest}
            activeI={activeI}
          />
        );

      default:
        return null;
    }
  };

  const changeTab = (active, i) => {
    setActive(active);
    setActiveI(i);
    setPickFirst(i < 2 ? true : false);
  };

  const displayTabItem = (active, repairsStages) => {
    const { stageData, isSubmitting, isDataLoading, dataErrors } = props;

    if (isDataLoading || dataErrors)
      return <InitialLoader error={dataErrors} onReload={loadStageData} />;

    switch (active) {
      case repairsStages[0].repairStageId:
        return (
          <>
            <div className="date_time">
              <div className="time">
                <Clock />
              </div>
              <div className="day_date">
                {date && (
                  <div className="day">{moment(date[0]).format("dddd")}</div>
                )}
                <div className="date-picker">
                  <DatePicker
                    value={date[0]}
                    onChange={dateChange}
                    showLeadingZeros={true}
                  />
                </div>
              </div>
            </div>

            <div className="sales-cards">
              {displayStageData(stageData, repairsStages)}
              <RepairsCardNotUpon
                stageBeforeData={stageBeforeData}
                changeStageBefore={changeStage}
                getBeforeRequest={getRequest}
                active={active}
                activeI={activeI}
                paymentStatus={paymentStatus}
                // isLoadingRequest={isLoadingRequest}
                isLoadingRequest={""}
                // isChanging={isChanging}
                isChanging={""}
                allowStageChange={repairsStages[0].showInChange}
              />
            </div>
          </>
        );

      case repairsStages[1].repairStageId:
        return (
          <>
            <div className="sales-cards">
              {displayStageData(stageData, repairsStages)}
            </div>
          </>
        );

      case repairsStages[2].repairStageId:
        return (
          <>
            <Col>{renderFilter(date[2], 2)}</Col>

            <div className="sales-cards">
              {displayStageData(stageData, repairsStages)}
            </div>
          </>
        );

      case repairsStages[3].repairStageId:
        return (
          <>
            <Col>{renderFilter(date[3], 3)}</Col>

            <div className="sales-cards">
              {displayStageData(stageData, repairsStages)}
            </div>
          </>
        );

      case "customtab01":
        return (
          <div style={{ marginBottom: "2%", marginLeft: "1%" }}>
            <Row>
              <Col>
                <Button
                  style={{ float: "right", margin: "1em" }}
                  onClick={
                    () => setAddTask(true)
                    // setState({ addTask: true })
                  }
                >
                  New Task
                </Button>
              </Col>
            </Row>
            <Col>
              <CSSTransition
                in={addTask}
                timeout={300}
                // classNames="list_item"
                unmountOnExit
              >
                <TaskCard
                  dateChoosen={dateChoosen}
                  handleDateChangesave={handleDateChangesave}
                  // handleChange={handleChange}
                  setTaskName={setTaskName}
                  taskName={taskName}
                  addTask={addTask}
                  onCancel={() => setAddTask(false)}
                  isSubmitting={isSubmitting}
                  handleOnClicksaveAdd={handleOnClicksaveAdd}
                />
              </CSSTransition>

              {displayTasks()}
            </Col>
          </div>
        );

      default:
        return (
          <div className="empty-_tab">
            <p>Please select one of the above tab.</p>
          </div>
        );
    }
  };

  const mapCases = () => { };

  // const clearMsg = () =>
  //   (msg = setTimeout(
  //     () => {
  //       setMsg("");
  //       setMsgFor("!modal");
  //       setMsgType("");
  //     },

  //     // setState({ msg: "", msgType: "", msgFor: "!modal" }),
  //     10000
  //   ));

  const renderEmptyTab = () => (
    <div className="empty-_tab">
      <p>No new visits are scheduled for today at this time.</p>
    </div>
  );

  const changeStage = (id) => {
    setModal(true);
    setRequestId(id);
  };

  const onHide = () => {
    setModal(false);
  };
  useEffect(() => {
    clearTimeout(msg);
  }, [msg]);

  const {
    // repairsTabs,
    repairsStagesChange,
    repairPersons,
    staticLoading,
    staticError,
    paymentStatus,
  } = props;

  if (staticLoading || staticError) {
    return <InitialLoader error={staticError} onReload={loadData} />;
  }

  return (
    <div style={{ margin: "2em 1.5em", position: "relative" }}>
      <Row>
        {msg && msgFor === "!modal" && (
          <Col>
            <Alert variant={msgType}>{msg}</Alert>
          </Col>
        )}
      </Row>
      <Row style={{ alignItems: "center" }}>
        <Col md="2">
          <h3 className="_title">
            Repairs Activity &nbsp;
            <Link
              to={{
                pathname: "/dashboard/help",
                search: "Activity Repair",
              }}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
            >
              <span style={{ color: "red" }}>?</span>
            </Link>
          </h3>
        </Col>
        {isOnline ? (
          <Col md="6">
            <SearchField
              type="search"
              placeholder="Search for repair"
              value={searchString}
              name="searchString"
              onChange={(e) => setSearchString(e.target.value)}
              sm
              paymentStatus={paymentStatus}
            />
          </Col>
        ) : (
          <p>
            You are working in offline mode. What you edit in Scheduled tasks
            will be upload to server when you are back online!
          </p>
        )}
        {isOnline && (
          <Col md="4">
            <Form.Group style={{ margin: "0 4em" }}>
              {repairPersons && repairPersons.length > 0 && (
                <Select
                  isMulti
                  placeholder="View activity by repairs persons"
                  name="selectPerson"
                  value={selectPerson}
                  onChange={
                    (value) => setSelectPerson(value)
                    // setState(() => ({ selectPerson: value }))
                  }
                  options={displayPersons(repairPersons)}
                />
              )}
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          {repairsTabs && repairsTabs.length > 0 && (
            <>
              <Tabs
                tabs={[
                  ...repairsTabs,
                  { repairStageId: "customtab01", repairStageText: "Tasks" },
                ]}
                count={[
                  ...count,
                  // , tasks.length
                ]}
                active={active}
                setTab={changeTab}
              />
              <div
                className="_tab-content"
                style={
                  activitySalesPointerEvent ? {} : { pointerEvents: "none" }
                }
              >
                {displayTabItem(active, repairsTabs)}
              </div>
            </>
          )}
        </Col>
      </Row>

      <PopupModal show={modal} onHide={onHide} heading="Select Sales Stage">
        <Row style={{ margin: "1em" }}>
          {repairsStagesChange &&
            repairsStagesChange.map((stage) => (
              <button
                disabled={active === stage.repairStageId}
                key={stage.repairStageId}
                onClick={() =>
                  handleChangeStage(requestId, stage.repairStageId)
                }
                className="stage"
              >
                {stage.repairStageText}
              </button>
            ))}
        </Row>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </PopupModal>

      <PopupModal
        show={showServiceRequestModal}
        onHide={onServiceRequestModalCancel}
        heading="Repair Request"
        bold={true}
      >
        <Row>
          {msg && msgFor === "modal" && (
            <Col>
              <Alert variant={msgType}>{msg}</Alert>
            </Col>
          )}
        </Row>
        {displayNewRepairOptions()}
        {displayFooter()}
      </PopupModal>
    </div>
  );
};
// }

const YesNo = [
  {
    name: "YES",
    value: true,
  },
  {
    name: "NO",
    value: false,
  },
];

const styles = {
  formInput: {
    marginBottom: "15px",
  },
};

const mapStateToProps = (state) => {
  const {
    fetchTask: { isLoading, data, errors },
    addTask: { isSubmitting, submitSuccess, submitFail },
    editTask: { isEditing, editingSuccess, editingFail },
    deleteTask: { isDeleting, deletingSuccess, deletingFail },
    changeStage: { changeFail, changeSuccess, isChanging },
    staticAll,
    getRepairReq: { dataRequest, errorsRequest, isLoadingRequest },
    tasksRate: { isLoadingTasksRate, taskratedata, tasksrateerror },
    updateRepairReq: { isupdatingReq, updateReqFail, updateReqSuccess },
    login,
  } = state;
  return {
    isupdatingReq,
    updateReqFail,
    updateReqSuccess,
    dataRequest,
    errorsRequest,
    isLoadingRequest,
    staticErrors: staticAll.errros,
    taxRates: taxRates(state),
    newRepairs: newRepairs(state),
    tasksRate: { isLoadingTasksRate, taskratedata, tasksrateerror },
    changeFail,
    changeSuccess,
    isChanging,
    dataErrors: state.getStage.errors,
    isDataLoading: state.getStage.isLoading,
    isLoading,
    data,
    errors,
    isEditing,
    editingSuccess,
    editingFail,
    isSubmitting,
    submitSuccess,
    submitFail,
    isDeleting,
    deletingSuccess,
    deletingFail,
    repairsStages: repairsStages(state),
    repairsTabs: repairsTabs(state),
    repairsStagesChange: repairsStagesChange(state),
    repairPersons: RepairPersons(state),
    stageData: stageData(state),
    count: count(state),
    staticLoading: staticAll.isLoading,
    staticError: staticAll.errors,
    userRole: login.user ? login.user.userData.role : null,
    paymentStatus: login?.user?.userData?.paymentStatus
      ? login.user.userData.paymentStatus
      : null,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchStaticAll: () => dispatch(fetchStaticAll()),
    getStage: (data) => dispatch(getStage({ ...data })),
    addTask: (data) => dispatch(addTask({ ...data })),
    editTask: (data) => dispatch(editTask({ ...data })),
    fetchTask: () => dispatch(fetchTask()),
    GetTasksRate: () => dispatch(GetTasksRate()),
    completeTask: (data) => dispatch(completeTask({ ...data })),
    fetchForCalendar: () => dispatch(fetchForCalendar()),
    changeStage: (data) => dispatch(changeStage({ ...data })),
    getRepairRequest: (data) => dispatch(getRepairRequest({ ...data })),
    updateRepairRequest: (data) => dispatch(updateRepairRequest({ ...data })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RepairsActivity);
