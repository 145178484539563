import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './CheckoutForm'
import { Elements } from "@stripe/react-stripe-js";
import { get_stripe_test_public_key, get_stripe_test_secret_key } from 'lib/redux/user/user.actions';
import useSubscription from 'hooks/useSubscription';


const StripePaymentPage = (props) => {
  const { company_id, email, type, onHide, from, additionalUser } = props
  const [stripePromise, setStripePromise] = useState(null)
  const [clientSecret, setClientSecret] = useState("")
  const [payIntent, setPayIntent] = useState()
  const [stripeClientId, setStripeClientId] = useState()

  const { particularCompanyData } = useSubscription()

  useEffect(() => {
    if (particularCompanyData && particularCompanyData.length) {
      const [
        {
          stripeCustomerId
        }
      ] = particularCompanyData

      if (stripeCustomerId) setStripeClientId(stripeCustomerId)
    }
  }, [particularCompanyData])

  useEffect(() => {
    get_stripe_test_public_key().then(res => {
      if (res?.data?.success) {
        setStripePromise(loadStripe(res.data.result))
      }
    })
  }, [])

  useEffect(() => {
    get_stripe_test_secret_key().then(res => {
      if (res?.data?.success) {
        setPayIntent(res.data.result.intent)
        setClientSecret(res.data.result.client_secret)
      }
    })
  }, [])

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {
        stripePromise && clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm company_id={company_id} payIntent={payIntent}
              clientSecret={clientSecret} email={email} stripeClientId={stripeClientId} type={type}
              onHide={onHide} from={from} additionalUser={additionalUser} />
          </Elements>
        )
      }
    </>
  )
}

export default StripePaymentPage