import { ModalTemplate } from "components";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ModalFooter } from "components/popup-modal/popup-modal";
import { useEffect, useState } from "react";

function AutoTextModal({ heading, show, children, onHide, fromProposal }) {
  return (
    <ModalTemplate
      heading={heading}
      onHide={onHide}
      show={show}
      fromProposal={fromProposal}
    >
      {children}
    </ModalTemplate>
  );
}

export function AutoTextModalDetail({
  modalDataDetail,
  onCancelModal,
  onInsertMultipleData,
  onInsertSingleData,
  onSelectMultipleData,
  onSelectSingleData,
  multipleData,
  singleData,
  fromProposal,
  onHideModal,
  showProposalModal,
}) {
  const styleFoot = {
    marginTop: "30px",
    justifyContent: "space-between",
    border: "none",
    padding: ".75rem",
  };

  const { gettingStart } = useSelector((state) => state.quoteProposal);
  const [isGetting, setIsGetting] = useState(gettingStart);

  useEffect(() => {
    setIsGetting(gettingStart);
  }, [gettingStart]);

  console.log({showProposalModal});
  return (
    <AutoTextModal
      style={fromProposal === true ? { height: "56vh", overflow: "auto" } : ""}
      fromProposal={true}
      heading={`${modalDataDetail._id} Select Line Item${modalDataDetail.isMultiSelect ? "(s)" : ""
        } to Insert`}
      show={showProposalModal}
      onHide={onHideModal}
    >
      <div className="__proposal_tab_select_modal">
        {modalDataDetail.data.map((data) => (
          <Row key={data._id} style={{ padding: ".5em 0", marginTop: "10px" }}>
            <Col>
              <h5 style={{ fontWeight: "bold" }}>{data.displayText}</h5>
            </Col>
            <Col lg="2">
              {modalDataDetail.isMultiSelect ? (
                multipleData.includes(data._id) ? (
                  <Button
                    style={{ height: "50px", width: "90px" }}
                    variant="danger "
                    onClick={() => onSelectMultipleData(data._id)}
                  >
                    Deselect
                  </Button>
                ) : (
                  <Button
                    style={{ height: "50px", width: "90px" }}
                    onClick={() => onSelectMultipleData(data._id)}
                  >
                    Select
                  </Button>
                )
              ) : singleData === data._id ? (
                <Button
                  style={{ height: "50px", width: "90px" }}
                  variant="danger"
                  onClick={() => onSelectSingleData(data._id)}
                >
                  Deselect
                </Button>
              ) : (
                <Button
                  style={{ height: "50px", width: "90px" }}
                  onClick={() => onSelectSingleData(data._id)}
                >
                  Select
                </Button>
              )}
            </Col>
          </Row>
        ))}
      </div>

      <ModalFooter
        styleFoot={styleFoot}
        onCancel={onCancelModal}
        onSubmit={
          modalDataDetail.isMultiSelect
            ? onInsertMultipleData
            : onInsertSingleData
        }
        submitText="Insert"
        submitDisabled={(!singleData && !multipleData.length > 0) || isGetting}
      />
    </AutoTextModal>
  );
}
