import { ApiEndpoints } from "lib/config/baseSettings";
import { axios as axiosNoAuth } from "services";

export const get_county_tax_by_state_zip = (stateId, zip) => {
    return axiosNoAuth
      .get(ApiEndpoints.getCountyByStateAndZip(stateId, zip));
  }


  export const create_subscription = (body) => {
    const { payment_method, email, companyId, additionalUser } = body;
    // setLoading(true);
    return axiosNoAuth
      .post(ApiEndpoints.SUBSCRIPTION.createSubscription, {
        payment_method,
        email,
        companyId,
        additionalUser,
      });
  };