import {
  getAllCartingPersonnel,
  getAllMaterialPersonnel,
  getAllSupplierPersonnel,
} from "api/api";
import { ApiEndpoints } from "lib/config/baseSettings";
import { useEffect, useState } from "react";

export default function userPersonnelData() {
  const [materialPersonnels, setMaterialPersonnels] = useState([]);
  const [cartingPersonnels, setCartingPersonnels] = useState([]);
  const [supplierPersonnels, setSupplierPersonnels] = useState([]);

  useEffect(() => {
    MaterialPersonnel();
    SuplierPersonnel();
    CartingPersonnel();
  }, []);

  const MaterialPersonnel = () => {
    getAllMaterialPersonnel(ApiEndpoints.PERSONEL_DATA.MaterialPersonnel)
      .then((response) => {
        // setIsLoading(false);
        setMaterialPersonnels(response.data.result);
        return { error: null, response };
      })
      .catch((error) => {
        // setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  const SuplierPersonnel = () => {
    getAllSupplierPersonnel(ApiEndpoints.PERSONEL_DATA.SuplierPersonnel)
      .then((response) => {
        setSupplierPersonnels(response.data.result);
        return { error: null, response };
      })
      .catch((error) => {
        // setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  const CartingPersonnel = () => {
    getAllCartingPersonnel(ApiEndpoints.PERSONEL_DATA.CartingPersonnel)
      .then((response) => {
        setCartingPersonnels(response.data.result);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  return {
    materialPersonnels,
    cartingPersonnels,
    supplierPersonnels,
  };
}
