import { getCartingPricing } from "services/offlineComputing/controllers/cartingPricingCompany";
import {
  editIsLocked,
  editMaterialSection,
  getMaterialsPricing,
  materialSection,
} from "services/offlineComputing/controllers/quoteMaterial";
import {
  debrisSection,
  editDebrisSection,
} from "services/offlineComputing/controllers/quoteDebris";
import { laborSection } from "services/offlineComputing/controllers/quoteLabor";
import quoteModel from "services/offlineComputing/models/quote";
import {
  editGuttersSection,
  guttersSection,
} from "services/offlineComputing/controllers/quoteGutter";
import {
  getGuttersMetrics,
  getRoofMetrics,
} from "services/offlineComputing/models/metricsModel";

const {
  createNewProposal,
  checkProposal,
  deleteQuote,
  addNewProposal,
  editProposal,
  recalculate_unlocked_quotes,
} = require("services/offlineComputing/controllers/quoteProposal");

export const getProposal = async (props) => {
  const { metricId, companyId } = props;
  const _id = "6537d9f3c4641240b4ec8c3c";

  //check metricId validity, later adding gutters
  const metricData = getRoofMetrics(metricId); //getData(`metrics${metricId}`);
  const GmetricData = getGuttersMetrics(metricId); //getData(`Gmetrics${metricId}`);

  if (metricData === null && GmetricData === null) {
    console.log("metric data is not available");
    return;
  }

  //check if quote is generated?
  const responseDataDb = await quoteModel.find({ requestId: metricId });
  //check if the quote already exist, if yes return all
  if (responseDataDb !== null && responseDataDb.length > 0) {
    return responseDataDb;
  }

  const quoteData = await createNewProposal({
    metricId,
    companyId,
    userId: _id,
  });

  return quoteData;
};

export const getMaterialSection = async (props) => {
  props._id = "6537d9f3c4641240b4ec8c3c";
  return await materialSection(props);
};

export const getLaborSection = async (props) => {
  props._id = "6537d9f3c4641240b4ec8c3c";
  return await laborSection(props);
};

export const getGutterSection = async (props) => {
  props._id = "6537d9f3c4641240b4ec8c3c";
  return await guttersSection(props);
};
export const pricingCarting = async (props) => {
  const { supplierId, companyId, postData } = props;

  try {
    const cartingPricing = await getCartingPricing(
      { companyId, supplierId },
      postData.q
    );

    return { cartingPricing };
  } catch (e) {
    return e;
  }
};

export const getDebrisSection = async (props) => {
  props._id = "6537d9f3c4641240b4ec8c3c";
  return await debrisSection(props);
};

export const editMaterial = async (props) => {
  props._id = "6537d9f3c4641240b4ec8c3c";
  return await editMaterialSection(props);
};

export const getMaterialsPrice = async (props) => {
  props._id = "6537d9f3c4641240b4ec8c3c";
  return await getMaterialsPricing(props);
};

export const editDebris = async (props) => {
  props._id = "6537d9f3c4641240b4ec8c3c";
  return await editDebrisSection(props);
};

export const editGutters = async (props) => {
  props._id = "6537d9f3c4641240b4ec8c3c";
  return await editGuttersSection(props);
};

export const checkProposals = async (props) => {
  props._id = "6537d9f3c4641240b4ec8c3c";
  return await checkProposal(props);
};

export const deleteProposal = async (props) => {
  props._id = "6537d9f3c4641240b4ec8c3c";
  return await deleteQuote(props);
};

export const editLock = async (props) => {
  props._id = "6537d9f3c4641240b4ec8c3c";
  return await editIsLocked(props);
};

export const createNewQuote = async (props) => {
  props._id = "6537d9f3c4641240b4ec8c3c";
  return await addNewProposal(props);
};

export const modifyProposal = async (props) => {
  props.userId = "6537d9f3c4641240b4ec8c3c";
  return await editProposal(props);
};

export const reCalculate = async (props) => {
  props.userId = "6537d9f3c4641240b4ec8c3c";
  return await recalculate_unlocked_quotes(props);
};
