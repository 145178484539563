import React, { useState, useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import { PriceInput } from "components";
import validator from "validator";
import QTYInput from "../components/QTYInput";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import showOptions from "../components/showOptions";

import styles from "lib/config/baseStyles";

export default function GuttersMiscItem({
  data,
  deleteMiscItem,
  editMiscItem,
}) {
  const [qty, setQty] = useState("");
  const [price, setPrice] = useState(Number(0).toFixed(2));
  const [total, setTotal] = useState(0);
  const staticData = useSelector((state) => state.staticAll);

  const handleChange = (e) => {
    if (e.key === "Enter") {
      if (validator.isNumeric(`${qty}`)) {
        const newQty = Math.round(qty);
        if (price > 0) {
          const newTotal = price * newQty;
          setTotal(newTotal);
        }

        setQty(newQty);
      }
    }
  };

  const handleEdit = (newData) => editMiscItem(newData);

  const handleChangeQty = (e) => {
    const value = e.target.value;

    const newTotal =
      validator.isNumeric(value) && validator.isNumeric(data.uomPrice)
        ? Number(value * data.uomPrice).toFixed(2)
        : data.total;

    const newData = changeData("qty", value);
    newData.total = newTotal;

    handleEdit(newData);
  };

  const handleChangeSeries = (e) => {
    const newData = changeData("series", e.target.value);

    handleEdit(newData);
  };

  const handlePriceChange = (value) => {
    const newTotal =
      validator.isNumeric(value) && validator.isNumeric(data.qty)
        ? Number(value * data.qty).toFixed(2)
        : data.total;
    const newData = changeData("uomPrice", value);
    newData.total = newTotal;

    handleEdit(newData);
  };

  const changeData = (key, value) => ({
    ...data,
    [key]: value,
  });

  const width = {
    flex: "0 0 37.5%",
    maxWidth: "37.5%",
  };

  return (
    <Row>
      <Col style={styles.reduceWidthCol}>
        <Button variant="danger" onClick={deleteMiscItem}>
          Delete
        </Button>
      </Col>

      <Col style={{ ...styles.reduceWidthCol, ...width }}>
        <Form.Group>
          <Form.Control
            type="text"
            value={data.series}
            onChange={handleChangeSeries}
          />
        </Form.Group>
      </Col>

      <Col style={styles.reduceWidthCol}>
        <Form.Group>
          <Form.Control as="select">
            {showOptions(
              staticData.data ? staticData.data.quoteGuttersUom : [],
              staticData.isLoading
            )}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <QTYInput
          value={data.qty}
          onChange={handleChangeQty}
          styless={data?.original_list?.qty != data.qty}
        />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput
          price={data.uomPrice}
          onChange={(value) => handlePriceChange(value)}
          style={{ textAlign: "right" }}
        />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput disabled={true} price={data.total} />
      </Col>
    </Row>
  );
}
