import { salesActionTypes } from "./sales.types";

const INITIAL_STATE = {
  isLoading: false,
  data: null,
  errors: null,
};

export const StageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case salesActionTypes.STAGE_START:
      return {
        ...state,
        isLoading: true,
        data: null,
        errors: null,
      };

    case salesActionTypes.STAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.result,
        errors: null,
      };

    case salesActionTypes.STAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: null,
        errors: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_CHANGE = {
  isChanging: false,
  changeSuccess: null,
  changeFail: null,
};

export const ChangeStageReducer = (state = INITIAL_STATE_CHANGE, action) => {
  console.log({action: action.payload});
  switch (action.type) {
    case salesActionTypes.CHANGE_STAGE_START:
      return {
        ...state,
        isChanging: true,
        changeSuccess: null,
        changeFail: null,
      };

    case salesActionTypes.CHANGE_STAGE_SUCCESS:
      return {
        ...state,
        isChanging: false,
        changeSuccess: action.payload.result.message,
        changeFail: null,
      };

    case salesActionTypes.CHANGE_STAGE_FAILURE:
      return {
        ...state,
        isChanging: false,
        changeSuccess: null,
        changeFail: action.payload,
      };

    case salesActionTypes.CHANGE_STAGE_RESET:
      return {
        ...INITIAL_STATE_CHANGE,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_UPDATE_FOLLOWUP = {
  isUpdating: false,
  updateSuccess: null,
  updateFail: null,
};

export const UpdateFollowUpReducer = (
  state = INITIAL_STATE_UPDATE_FOLLOWUP,
  action
) => {
  switch (action.type) {
    case salesActionTypes.UPDATE_FOLLOWUP_START:
      return {
        ...state,
        isUpdating: true,
        updateSuccess: null,
        updateFail: null,
      };

    case salesActionTypes.UPDATE_FOLLOWUP_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updateSuccess: action.payload.result.message,
        updateFail: null,
      };

    case salesActionTypes.UPDATE_FOLLOWUP_FAILURE:
      return {
        ...state,
        isUpdating: false,
        updateSuccess: null,
        updateFail: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_UPDATE_FOLLOWUPDATA = {
  isUpdatingData: false,
  updateSuccessData: null,
  updateFailData: null,
};

export const UpdateFollowUpDataReducer = (
  state = INITIAL_STATE_UPDATE_FOLLOWUPDATA,
  action
) => {
  switch (action.type) {
    case salesActionTypes.UPDATE_FOLLOWUP_DATA_START:
      return {
        ...state,
        isUpdatingData: true,
        updateSuccessData: null,
        updateFailData: null,
      };

    case salesActionTypes.UPDATE_FOLLOWUP_DATA_SUCCESS:
      return {
        ...state,
        isUpdatingData: false,
        updateSuccessData: action.payload.result.message,
        updateFailData: null,
      };

    case salesActionTypes.UPDATE_FOLLOWUP_DATA_FAILURE:
      return {
        ...state,
        isUpdatingData: false,
        updateSuccessData: null,
        updateFailData: action.payload,
      };

    default:
      return state;
  }
};
