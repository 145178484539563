import React, { useState } from "react";
import { Modal, Form, Row, Col, Button, Alert } from "react-bootstrap";
import PopupModal from "components/popup-modal/popup-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "components/DeleteModal";

const styles = {
  formInput: {
    marginBottom: "15px",
  },
};

export default function AddBuilding({
  onCoopModalHide,
  handleChange,
  newBuildingName,
  coopUnits,
  onEditBuilding,
  saveEditBuilding,
  tempNewBuildingName,
  onDeleteBuilding,
  deleteBuilding,
  showCoopModal,
  addBuldings,
  onSelected,
  selected,
  getBuildingName,
  editBuilding,
  deleteBuildingId,
  error,
}) {
  const [pageNum, setPageNum] = useState(1);
  const [maxItem] = useState(5);

  const prevPage = () => setPageNum(pageNum - 1);

  const nextPage = () => setPageNum(pageNum + 1);

  const getIcon = (name) => <FontAwesomeIcon icon={name} />;

  const lastI = pageNum * maxItem;
  const startI = lastI - maxItem;

  const matchName = (name) =>
    coopUnits.some((unit) => unit.bname.toLowerCase() === name.toLowerCase());

  return (
    <>
      <PopupModal
        show={showCoopModal}
        heading="Condo/Coop Buildings"
        onHide={onCoopModalHide}
        bold={false}
        styles={{ padding: "1em 1em 0 1em", border: "none" }}
      >
        {error && (
          <Row>
            <Col>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col md="8" sm="9" xs="8" lg="10">
            <Form.Group style={styles.formInput}>
              <Form.Control
                type="text"
                placeholder="New Building Name"
                name="newBuildingName"
                value={newBuildingName}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Button
              variant="outline-success"
              disabled={
                !newBuildingName.trim() || matchName(newBuildingName.trim())
              }
              onClick={() => addBuldings(newBuildingName)}
            >
              Add +
            </Button>
          </Col>
        </Row>

        {coopUnits.length > 0 && (
          <Row>
            <Col>
              <div>
                <h5
                  style={{
                    textAlign: "center",
                    paddingTop: "1em",
                    margin: "0",
                  }}
                >
                  <b>Condo/Coop Buildings</b>
                </h5>
                <p className="buildingSubhead">Tap Building Name to Select</p>
                <ul className="buildings-list">
                  {coopUnits.slice(startI, lastI).map((unit) => (
                    <li key={unit.bid} className="coop-units">
                      <Row
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Col lg="4">
                          {unit.bid === editBuilding ? (
                            <>
                              <Button
                                className="modified_btnBuilding"
                                variant="danger"
                                onClick={() => onEditBuilding(unit.bid, false)}
                                style={{ marginRight: "5px" }}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="modified_btnBuilding"
                                variant="success"
                                onClick={() => saveEditBuilding(unit)}
                                disabled={
                                  matchName(tempNewBuildingName.trim()) ||
                                  !tempNewBuildingName.trim() ||
                                  getBuildingName(unit.bid) ===
                                    tempNewBuildingName.trim()
                                }
                                style={{ marginLeft: "5px" }}
                              >
                                Save
                              </Button>
                            </>
                          ) : (
                            <Button
                              className="modified_btnBuilding"
                              onClick={() => onEditBuilding(unit.bid)}
                            >
                              Edit
                            </Button>
                          )}
                        </Col>
                        <Col>
                          {unit.bid === editBuilding ? (
                            <Form.Group style={styles.formInput}>
                              <Form.Control
                                type="text"
                                name="tempNewBuildingName"
                                value={tempNewBuildingName}
                                autoFocus
                                onChange={handleChange}
                              />
                            </Form.Group>
                          ) : (
                            <p
                              onClick={() => onSelected(unit.bid)}
                              style={{
                                textAlign: "center",
                                cursor: "pointer",
                                margin: " auto 0",
                                fontWeight: "bold",
                                fontSize: "larger",
                              }}
                            >
                              {getBuildingName(unit.bid)}
                            </p>
                          )}
                        </Col>
                        <Col lg="4">
                          <Button
                            className="modified_btnBuilding"
                            variant="danger"
                            disabled={selected === unit.bid}
                            style={{ float: "right" }}
                            onClick={() => onDeleteBuilding(unit.bid)}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        )}
        <Modal.Footer
          style={{
            justifyContent: "space-between",
            border: "none",
            padding: "0 .75rem",
          }}
        >
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <button
              aria-label="Prev"
              className="pagination_btns"
              onClick={() => prevPage()}
              disabled={pageNum === 1}
            >
              {getIcon(faChevronLeft)}
            </button>
            <p className="page_num">{pageNum}</p>
            <button
              aria-label="Next"
              className="pagination_btns"
              disabled={pageNum * maxItem >= coopUnits.length}
              onClick={() => nextPage()}
            >
              {getIcon(faChevronRight)}
            </button>
          </div>
          <Button variant="outline-danger" onClick={onCoopModalHide}>
            Close
          </Button>
        </Modal.Footer>
      </PopupModal>

      <DeleteModal
        showDeleteModal={deleteBuildingId !== ""}
        deleteData={() => deleteBuilding(deleteBuildingId)}
        hideDeleteModal={() => onDeleteBuilding(deleteBuildingId, false)}
        onCancel={() => onDeleteBuilding(deleteBuildingId, false)}
        heading="Delete Building"
        dltSubHead={`All collected metrics for "
      ${getBuildingName(deleteBuildingId)}
      " will
      be lost and will be unrecoverable, proceed with delete?`}
        centered
      />
    </>
  );
}
