import React, { useState, useEffect, useMemo } from "react";

import { Row, Col, Button, Form, Modal, Alert } from "react-bootstrap";
import "../../calendar/calendar.scss";
import PopupModal from "components/popup-modal/popup-modal";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { convertDate, displayPersons, sliceHoursAndMin } from "utils/utils";

import {
  CalendarPersons,
  SalesPersons,
  RepairPersons,
  CalendarRequests,
  CalendarRequestsRepair,
  CalendarRequestsSales,
  salesEvents,
  repairEvents,
  CalendarEvents,
} from "lib/redux/calendar/calendar.selector";
import { connect } from "react-redux";
import {
  deleteEvent,
  clearAvailablePersons,
  addToCalendar,
  fetchForCalendar,
  fetchAvailabelPersons,
  fetchEvents,
} from "lib/redux/calendar/calendar.actions";
import use_quote from "hooks/useQuote";

let cancellation_type = [
  {
    type: "Customer Cancelled",
    value: "customer cancelled",
  },
  {
    type: "Contract Error",
    value: "contract error",
  },
  {
    type: "Other",
    value: "other",
  },
];
export const CancelledModal = ({ ...props }) => {
  const {
    addModal,
    setAddModal,
    setActive,
    id,
    selectedStage,
    setHide,
    saveEditCancellation,
    cancellationData,
    cancelModalId,
    setCancelModalId,
  } = props;
  console.log({ propertiesCalendar: props });
  let { save_cancelleation_reason, update_followup } = use_quote();
  const [cancellationType, setCancellationType] = useState();
  const [cancellationReason, setCancellationReason] = useState();

  const editCancellation = () => {
    const cancData = {
      cancellationType: cancellationType,
      cancellationReason: cancellationReason,
    };
    saveEditCancellation({ ...cancData });
    setAddModal(false);
    setCancelModalId(null);
  };

  useEffect(() => {
    console.log("check Id", id);
    setCancellationType(cancellationData?.type);
    setCancellationReason(cancellationData?.detail);
  }, [cancellationData]);

  const onHide = () => {
    setAddModal(false);
    setCancelModalId(null);
    if (!cancellationData) {
      update_followup({
        requestId: id,
        stageId: selectedStage,
      });
      setActive(selectedStage);
    }
    setHide(true);
  };

  let cancellation_reason = async () => {
    let { error, response } = await save_cancelleation_reason({
      requestId: id,
      cancellationType: cancellationType,
      cancellationReason: cancellationReason,
    });
    
    if (!response.data.success) {
      update_followup({
        requestId: id,
        stageId: selectedStage,
      });
      setActive(selectedStage);
    }
    setAddModal(false);
    setCancelModalId(null);
  };
  return (
    <PopupModal
      show={addModal && cancelModalId === id}
      onHide={onHide}
      heading={`Cancellation`}
      bold={true}
      styles={{ background: "#000099", color: "#fff" }}
    >
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "2rem",
          }}
        >
          <Button
            type="button"
            //className="btn btn-primary"
            variant={
              cancellationType === "customer cancelled"
                ? "primary"
                : "outline-primary"
            }
            style={{ height: "5rem", width: "10rem" }}
            onClick={() => {
              setCancellationType(
                cancellation_type.filter(
                  (type) => type.type === "Customer Cancelled"
                )[0].value
              );
            }}
          >
            Customer Cancelled
          </Button>
          <Button
            type="button"
            variant={
              cancellationType === "contract error"
                ? "primary"
                : "outline-primary"
            }
            style={{ height: "5rem", width: "10rem" }}
            onClick={() => {
              setCancellationType(
                cancellation_type.filter(
                  (type) => type.type === "Contract Error"
                )[0].value
              );
            }}
          >
            Contract Error
          </Button>
          <Button
            type="button"
            variant={
              cancellationType === "other" ? "primary" : "outline-primary"
            }
            style={{ height: "5rem", width: "10rem" }}
            onClick={() => {
              setCancellationType(
                cancellation_type.filter((type) => type.type === "Other")[0]
                  .value
              );
            }}
          >
            Other
          </Button>
        </div>
        <div>
          <h6>Enter Details *</h6>
          <Form.Control
            as="textarea"
            placeholder="Cancellation Reason"
            rows="2"
            disabled={!cancellationType}
            value={cancellationReason}
            onChange={(e) => {
              setCancellationReason(e.target.value);
            }}

            // value="C/O Notes"
            // name={smWaranty}
          ></Form.Control>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <strong>
            Note: Cancelled contracts cannot be uncancelled, create new
          </strong>
        </div>

        <div style={{ float: "right" }}>
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginTop: "1.5rem" }}
            disabled={!cancellationType || !cancellationReason}
            onClick={cancellationData ? editCancellation : cancellation_reason}
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-warning"
            style={{ marginTop: "1.5rem", marginLeft: "1.5rem" }}
            onClick={onHide}
          >
            Cancel
          </button>
        </div>
      </>
    </PopupModal>
  );
};

const styles = {
  container: {
    background: "#fff",
    padding: "2em .5em",
  },
  heading: {
    flex: 1,
  },
  header: {
    padding: "25px 20px",
  },
  formInput: {
    marginBottom: "15px",
  },
  formStyle: {
    // marginTop: "5%"
  },
  tabItem: { padding: "1em" },
};

export default CancelledModal;
