const staticTexts = {
  sectionHeading: "Section List ",
  sectionSubHead:
    "To remove a section or sections from this Alternate Quote & Proposal, Click/Tap the 'Exclude' then 'Save'.",
  sectionSubHead2: " sections, Click/Tap 'Exclude All Section' then 'Save'",
  buildingHeading: "Building List ",
  buildingListSubHead:
    "To remove, or add buidling or sections from a building click/Tap the 'View Sections' Button then 'Save'. To Undo Click/Tap 'Add' then 'Save'",
  newQuotehead: "Does this alternate quote require any exclusions or additions",
};
export default staticTexts;
