import { axiosInstance } from "services";

import * as quoteTypes from "./quotes-proposals.types";
import {
  checkProposals,
  createNewQuote,
  deleteProposal,
  editDebris,
  editGutters,
  editMaterial,
  editQuoteDebris,
  getDebrisSection,
  getGutterSection,
  getLaborSection,
  getMaterialSection,
  getMaterialsPrice,
  getProposal,
  modifyProposal,
} from "services/offlineComputing/routes/generateQuote";
import { getUserLocal } from "lib/constants/localbase";
import { getData } from "services/storageManager";
import { getIsPushing } from "services/offlineDataPush";
import store from "lib/redux/store";
import { ApiEndpoints } from "lib/config/baseSettings";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const getQuoteMetricData = (id) => async (dispatch) => {
  dispatch({ type: quoteTypes.GET_QUOTE_START });

  if (!navigator.onLine) {
    let roofData = getData(`metrics${id}`);
    let guttersData = getData(`Gmetrics${id}`);

    if (roofData === null && guttersData === null) {
      dispatch({
        type: quoteTypes.GET_QUOTE_FAILURE,
        payload: "no data for both",
      });
    } else {
      if (roofData === null) {
        roofData = { data: { ...guttersData.data, metricData: [] } };
      }
      if (guttersData === null) {
        guttersData = { data: { ...roofData.data, metricData: [] } };
      }
      dispatch({
        type: quoteTypes.GET_QUOTE_SUCCESS,
        payload: {
          roof: roofData.data,
          gutter: guttersData.data,
        },
      });
    }
  } else {
    try {
      const roofData = await axiosInstance.get(
        `${ApiEndpoints.METRICS.GET_ROOF_DATA}${id}`
      );
      const guttersData = await axiosInstance.get(
        `${ApiEndpoints.METRICS.GET_GUTTERS_DATA}${id}`
      );

      if (roofData.data.success && guttersData.data.success) {
        dispatch({
          type: quoteTypes.GET_QUOTE_SUCCESS,
          payload: {
            roof: roofData.data.result,
            gutter: guttersData.data.result,
          },
        });
      } else {
        dispatch({
          type: quoteTypes.GET_QUOTE_FAILURE,
          payload: roofData.data.error || guttersData.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: quoteTypes.GET_QUOTE_FAILURE,
        payload: err.message,
      });
    }
  }
};

export const getSupplierPricing =
  (supplierId, queryData) => async (dispatch) => {
    const query = {
      q: queryData,
    };

    if (!navigator.onLine) {
      return new Promise(async (resolve, reject) => {
        try {
          let userDb = await getUserLocal();
          const companyId = userDb.userData.companyId;
          if (!companyId) {
            throw "companyId not found";
          }
          const resulte = await getMaterialsPrice({
            supplierId,
            companyId,
            postData: query,
          });

          resolve({ data: { success: true, result: resulte } });
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    }
    return axiosInstance.post(
      `${ApiEndpoints.PRICING.FETCH_MATERIAL_PRICING}${supplierId}`,
      query
    );
  };

export const getLaborPricing = (supplierId, queryData) => {
  const query = {
    q: queryData,
  };
  return (dispatch) =>
    axiosInstance.post(
      `${ApiEndpoints.PRICING.FETCH_LABOR_PRICING}${supplierId}`,
      query
    );
};

export const getQuoteMatSuppliers = () => (dispatch) => {
  dispatch({ type: quoteTypes.QUOTE_MSUPPLIERS_START });

  if (!navigator.onLine) {
    const matData = getData("materialSuppliers");
    if (matData !== null) {
      return dispatch({
        type: quoteTypes.QUOTE_MSUPPLIERS_SUCCESS,
        payload: matData,
      });
    }
    return dispatch({
      type: quoteTypes.QUOTE_MSUPPLIERS_FAILURE,
      payload: "data not cached",
    });
  }

  return axiosInstance
    .get(ApiEndpoints.SUPPLIERS.FETCH_MATERIAL_SUPPLIERS)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return dispatch({
          type: quoteTypes.QUOTE_MSUPPLIERS_SUCCESS,
          payload: data.result,
        });
      }
      return dispatch({
        type: quoteTypes.QUOTE_MSUPPLIERS_FAILURE,
        payload: data.error,
      });
    })
    .catch((errors) =>
      dispatch({
        type: quoteTypes.QUOTE_MSUPPLIERS_FAILURE,
        payload: errors.message,
      })
    );
};

export const getQuoteSubsCrewSuppliers = () => (dispatch) => {
  dispatch({ type: quoteTypes.QUOTE_SUBSCREWSUPPLIERS_START });

  if (!navigator.onLine) {
    const crewData = getData("subsCrewSuppliers");
    if (crewData !== null) {
      return dispatch({
        type: quoteTypes.QUOTE_SUBSCREWSUPPLIERS_SUCCESS,
        payload: crewData,
      });
    }
    return dispatch({
      type: quoteTypes.QUOTE_SUBSCREWSUPPLIERS_FAILURE,
      payload: "data not cached",
    });
  }

  return axiosInstance
    .get(ApiEndpoints.SUPPLIERS.FETCH_SUBS_CREW_SUPPLIERS)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return dispatch({
          type: quoteTypes.QUOTE_SUBSCREWSUPPLIERS_SUCCESS,
          payload: data.result,
        });
      }
      return dispatch({
        type: quoteTypes.QUOTE_SUBSCREWSUPPLIERS_FAILURE,
        payload: data.error,
      });
    })
    .catch((errors) =>
      dispatch({
        type: quoteTypes.QUOTE_SUBSCREWSUPPLIERS_FAILURE,
        payload: errors.message,
      })
    );
};

export const getQuoteCartSuppliers = () => (dispatch) => {
  dispatch({ type: quoteTypes.QUOTE_CARTINGSUPPLIERS_START });

  if (!navigator.onLine) {
    const cartData = getData("cartingSuppliers");
    if (cartData !== null) {
      return dispatch({
        type: quoteTypes.QUOTE_CARTINGSUPPLIERS_SUCCESS,
        payload: cartData,
      });
    }
    return dispatch({
      type: quoteTypes.QUOTE_CARTINGSUPPLIERS_FAILURE,
      payload: "data not cached",
    });
  }

  return axiosInstance
    .get(ApiEndpoints.SUPPLIERS.FETCH_CARTING_SUPPLIERS)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return dispatch({
          type: quoteTypes.QUOTE_CARTINGSUPPLIERS_SUCCESS,
          payload: data.result,
        });
      }
      return dispatch({
        type: quoteTypes.QUOTE_CARTINGSUPPLIERS_FAILURE,
        payload: data.error,
      });
    })
    .catch((errors) =>
      dispatch({
        type: quoteTypes.QUOTE_CARTINGSUPPLIERS_FAILURE,
        payload: errors.message,
      })
    );
};

export const getQuoteMaterialSectionData =
  (supplierId, metricId, proposalId) => async (dispatch) => {
    dispatch({ type: quoteTypes.QUOTE_MATERAILSECTION_DATA_START });

    if (!navigator.onLine) {
      try {
        let userDb = await getUserLocal();
        const companyId = userDb.userData.companyId;

        if (!companyId) {
          throw "companyId not found";
        }
        const result = await getMaterialSection({
          supplierId,
          metricId,
          proposalId,
          companyId,
        });

        return dispatch({
          type: quoteTypes.QUOTE_MATERAILSECTION_DATA_SUCCESS,
          payload: result,
        });
      } catch (err) {
        console.error(err);
        return dispatch({
          type: quoteTypes.QUOTE_MATERAILSECTION_DATA_FAILURE,
          payload: err,
        });
      }
    }

    return axiosInstance
      .get(
        `${ApiEndpoints.QUOTE.MATERIAL_SECTION_DATA}?supplierId=${supplierId}&metricId=${metricId}&proposalId=${proposalId}`
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: quoteTypes.QUOTE_MATERAILSECTION_DATA_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: quoteTypes.QUOTE_MATERAILSECTION_DATA_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: quoteTypes.QUOTE_MATERAILSECTION_DATA_FAILURE,
          payload: errors.message,
        })
      );
  };

export const editQuoteMaterialSectionData = (sectionData) => (dispatch) =>
  dispatch({
    type: quoteTypes.QUOTE_MATERAILSECTION_DATA_EDIT,
    payload: {
      sectionData,
    },
  });

export const updateTotalQuoteMaterialSectionData =
  (sectionData) => (dispatch) =>
    dispatch({
      type: quoteTypes.QUOTE_MATERAILSECTION_DATA_UPDATETOTAL,
      payload: {
        sectionData,
      },
    });

export const saveEditedQuoteMaterialSectionData =
  (data) => async (dispatch) => {
    if (!navigator.onLine) {
      return new Promise(async (resolve, reject) => {
        try {
          let userDb = await getUserLocal();
          const companyId = userDb.userData.companyId;
          if (!companyId) {
            throw "companyId not found";
          }
          const resulte = await editMaterial({ ...data, companyId });
          resolve({ data: { success: true, result: resulte } });
        } catch (err) {
          console.error("error editing material", err);
          reject(err);
        }
      });
    } else
      return axiosInstance.put(ApiEndpoints.QUOTE.MATERIAL_SECTION_DATA, data);
  };

export const getQuoteLaborSectionData =
  (supplierId, metricId, proposalId) => async (dispatch) => {
    dispatch({ type: quoteTypes.QUOTE_LABORSECTION_DATA_START });

    if (!navigator.onLine) {

      try {
        let userDb = await getUserLocal();
        const companyId = userDb.userData.companyId;
        if (!companyId) {
          throw "companyId not found";
        }
        const result = await getLaborSection({
          supplierId,
          metricId,
          proposalId,
          companyId,
        });

        return dispatch({
          type: quoteTypes.QUOTE_LABORSECTION_DATA_SUCCESS,
          payload: result,
        });
      } catch (err) {
        console.error(err);
        return dispatch({
          type: quoteTypes.QUOTE_LABORSECTION_DATA_FAILURE,
          payload: err,
        });
      }
    }
    return axiosInstance
      .get(
        `${ApiEndpoints.QUOTE.LABOR_SECTION_DATA}?supplierId=${supplierId}&metricId=${metricId}&proposalId=${proposalId}`
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: quoteTypes.QUOTE_LABORSECTION_DATA_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: quoteTypes.QUOTE_LABORSECTION_DATA_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: quoteTypes.QUOTE_LABORSECTION_DATA_FAILURE,
          payload: errors.message,
        })
      );
  };

export const editQuoteLaborSectionData = (sectionData) => (dispatch) =>
  dispatch({
    type: quoteTypes.QUOTE_LABORSECTION_DATA_EDIT,
    payload: {
      sectionData,
    },
  });

export const updateTotalQuoteLaborSectionData = (sectionData) => (dispatch) =>
  dispatch({
    type: quoteTypes.QUOTE_LABORSECTION_DATA_UPDATETOTAL,
    payload: {
      sectionData,
    },
  });

export const saveEditedQuoteLaborSectionData = (data) => (dispatch) =>
  axiosInstance.put(ApiEndpoints.QUOTE.LABOR_SECTION_DATA, data[0]);

export const getQuoteDebrisSectionData =
  (supplierId, metricId, proposalId) => async (dispatch) => {
    dispatch({ type: quoteTypes.QUOTE_DEBRISSECTION_DATA_START });

    if (!navigator.onLine) {
      try {
        let userDb = await getUserLocal();
        const companyId = userDb.userData.companyId;
        if (!companyId) {
          throw "companyId not found";
        }
        const result = await getDebrisSection({
          supplierId,
          metricId,
          proposalId,
          companyId,
        });

        return dispatch({
          type: quoteTypes.QUOTE_DEBRISSECTION_DATA_SUCCESS,
          payload: result,
        });
      } catch (err) {
        console.error(err);
        return dispatch({
          type: quoteTypes.QUOTE_DEBRISSECTION_DATA_FAILURE,
          payload: err,
        });
      }
    }

    return axiosInstance
      .get(
        `${ApiEndpoints.QUOTE.DEBRIS_SECTION_DATA}?supplierId=${supplierId}&metricId=${metricId}&proposalId=${proposalId}`
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: quoteTypes.QUOTE_DEBRISSECTION_DATA_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: quoteTypes.QUOTE_DEBRISSECTION_DATA_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: quoteTypes.QUOTE_DEBRISSECTION_DATA_FAILURE,
          payload: errors.message,
        })
      );
  };

export const editQuoteDebrisSectionData = (sectionData) => (dispatch) =>
  dispatch({
    type: quoteTypes.QUOTE_DEBRISSECTION_DATA_EDIT,
    payload: {
      sectionData,
    },
  });

export const updateTotalQuoteDebrisSectionData = (sectionData) => (dispatch) =>
  dispatch({
    type: quoteTypes.QUOTE_DEBRISSECTION_DATA_UPDATETOTAL,
    payload: {
      sectionData,
    },
  });

export const saveEditedQuoteDebrisSectionData = (data) => (dispatch) => {
  if (!navigator.onLine) {
    return new Promise(async (resolve, reject) => {
      try {
        let userDb = await getUserLocal();
        const companyId = userDb.userData.companyId;

        if (!companyId) {
          throw "companyId not found";
        }
        const result = await editDebris({ ...data, companyId });
        resolve({ data: { success: true, result: result } });
      } catch (err) {
        console.error("error editing material", err);
        reject(err);
      }
    });
  } else return axiosInstance.put(ApiEndpoints.QUOTE.DEBRIS_SECTION_DATA, data);
};
export const getQuoteGuttersSectionData =
  (supplierId, metricId, proposalId) => async (dispatch) => {
    dispatch({ type: quoteTypes.QUOTE_GUTTERSSECTION_DATA_START });

    if (!navigator.onLine) {
      try {
        let userDb = await getUserLocal();
        const companyId = userDb.userData.companyId;
        if (!companyId) {
          throw "companyId not found";
        }
        const resulte = await getGutterSection({
          supplierId,
          metricId,
          proposalId,
          companyId,
        });
        return dispatch({
          type: quoteTypes.QUOTE_GUTTERSSECTION_DATA_SUCCESS,
          payload: resulte,
        });
      } catch (err) {
        return dispatch({
          type: quoteTypes.QUOTE_GUTTERSSECTION_DATA_FAILURE,
          payload: err,
        });
      }
    }

    return axiosInstance
      .get(
        `${ApiEndpoints.QUOTE.GUTTERS_SECTION_DATA}?supplierId=${supplierId}&metricId=${metricId}&proposalId=${proposalId}`
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: quoteTypes.QUOTE_GUTTERSSECTION_DATA_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: quoteTypes.QUOTE_GUTTERSSECTION_DATA_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: quoteTypes.QUOTE_GUTTERSSECTION_DATA_FAILURE,
          payload: errors.message,
        })
      );
  };

export const editQuoteGuttersSectionData = (sectionData) => (dispatch) =>
  dispatch({
    type: quoteTypes.QUOTE_GUTTERSSECTION_DATA_EDIT,
    payload: {
      sectionData,
    },
  });

export const updateTotalQuoteGuttersSectionData = (sectionData) => (dispatch) =>
  dispatch({
    type: quoteTypes.QUOTE_GUTTERSSECTION_DATA_UPDATETOTAL,
    payload: {
      sectionData,
    },
  });

export const saveEditedQuoteGuttersSectionData = (data) => (dispatch) => {
  if (!navigator.onLine) {
    return new Promise(async (resolve, reject) => {
      try {
        let userDb = await getUserLocal();
        const companyId = userDb.userData.companyId;
        if (!companyId) {
          throw "companyId not found";
        }
        const resulte = await editGutters({ ...data[0], companyId });
        resolve({ data: { success: true, result: resulte } });
      } catch (err) {
        console.error(err);
        reject("lol error");
      }
    });
  } else
    return axiosInstance.put(ApiEndpoints.QUOTE.GUTTERS_SECTION_DATA, data[0]);
};

export const updateProposalStage = (metricId) => async (dispatch) => {
  return axiosInstance
    .get(`${ApiEndpoints.QUOTE.QUOTE_PROPOSAL_DATA}?metricId=${metricId}`)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        dispatch({ type: "ENDING" });
        return dispatch({
          type: quoteTypes.UPDATE_STAGE_SUCCESS,
          payload: data.result,
        });
      }
    });
};

export const getQuoteProposalData =
  (metricId) => async (dispatch) => {
    dispatch({ type: quoteTypes.QUOTE_PROPOSAL_DATA_START });
    await new Promise(async (resolve, reject) => {
      while (true) {
        await delay(200);
        const res = getIsPushing();
        if (res === false) {
          resolve(true);
        }
      }
    });
    if (!navigator.onLine) {
      try {
        let userDb = await getUserLocal();
        const companyId = userDb.userData.companyId;

        if (!companyId) {
          throw "companyId not found";
        }

        const offlineData = await getProposal({ metricId, companyId });

        return dispatch({
          type: quoteTypes.QUOTE_PROPOSAL_DATA_SUCCESS,
          payload: offlineData,
        });
      } catch (err) {
        return dispatch({
          type: quoteTypes.QUOTE_PROPOSAL_DATA_FAILURE,
          payload: err,
        });
      }
    }
    return axiosInstance
      .get(`${ApiEndpoints.QUOTE.QUOTE_PROPOSAL_DATA}?metricId=${metricId}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: quoteTypes.QUOTE_PROPOSAL_DATA_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: quoteTypes.QUOTE_PROPOSAL_DATA_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: quoteTypes.QUOTE_PROPOSAL_DATA_FAILURE,
          payload: errors.message,
        })
      );
  };

export const editQuoteProposalData = (updatedData) => (dispatch) =>
  dispatch({
    type: quoteTypes.QUOTE_PROPOSAL_DATA_EDIT_ALL,
    payload: {
      updatedData,
    },
  });

export const changeActiveProposal = (proposalId, proposalIdx) => (dispatch) =>
  dispatch({
    type: quoteTypes.QUOTE_PROPOSAL_CHANGE_ACTIVE,
    payload: {
      proposalId,
      proposalIdx,
    },
  });

export const saveEditedQuoteProposalData = (data) => (dispatch) => {
  dispatch({ type: "STARTING" });
  if (!navigator.onLine) {
    return new Promise(async (resolve, reject) => {
      try {
        let userDb = await getUserLocal();
        const companyId = userDb.userData.companyId;
        if (!companyId) {
          throw "companyId not found";
        }
        const resulte = await modifyProposal({ ...data[0], companyId });
        resolve({ data: { success: true, result: resulte } });

      } catch (err) {
        console.error(err);
        reject("lol error");
      }
    });
  }
  return axiosInstance
    .put(ApiEndpoints.QUOTE.QUOTE_PROPOSAL_DATA, data[0])
    .then(() => {
      dispatch({ type: "ENDING" });
    });
};

export const checkQuoteProposalData = (metricId) => {
  if (!navigator.onLine) {
    return new Promise(async (resolve, reject) => {
      try {
        let userDb = await getUserLocal();
        const companyId = userDb.userData.companyId;

        if (!companyId) {
          throw "companyId not found";
        }
        const result = await checkProposals({ metricId, companyId });
        resolve({ data: { success: true, result: result } });
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }
  return axiosInstance.get(
    `${ApiEndpoints.QUOTE.CHECK_QUOTE_PROPOSAL_DATA}?metricId=${metricId}`
  );
};

export const deleteProposalData = (metricId, onSuccess, onFailure, onFinally) =>
  axiosInstance
    .delete(`${ApiEndpoints.QUOTE.DELETE_PROPOSAL_DATA}?metricId=${metricId}`)
    .then((res) => res.data)
    .then((res) => {
      if (res.success) {
        onSuccess(res);
      } else {
        onFailure(res.error);
      }
    })
    .catch((e) => onFailure(e.message))
    .finally(onFinally);

export const createProposal =
  (metricId, data, onSuccess, onFailure, onFinally) => (dispatch) => {
    if (!navigator.onLine) {
      return new Promise(async (resolve, reject) => {
        try {
          let userDb = await getUserLocal();

          const companyId = userDb.userData.companyId;

          if (!companyId) {
            throw "companyId not found";
          }
          const result = await createNewQuote({ metricId, companyId, ...data });
          dispatch({
            type: quoteTypes.QUOTE_PROPOSAL_DATA_ADD,
            payload: result[0],
          });
          onSuccess(result[0]._id);
        } catch (err) {
          console.error("error editing material", err);
          onFailure(err);
        } finally {
          onFinally();
        }
      });
    } else
      return axiosInstance
        .post(
          `${ApiEndpoints.QUOTE.CREATE_PROPOSAL}?metricId=${metricId}`,
          data
        )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            const createdProposal = res.result[0];

            dispatch({
              type: quoteTypes.QUOTE_PROPOSAL_DATA_ADD,
              payload: createdProposal,
            });
            onSuccess(createdProposal._id);
          } else {
            onFailure(res.error);
          }
        })
        .catch((e) => onFailure(e.message))
        .finally(onFinally);
  };

export const deleteQuote =
  (quoteId, quoteIdx, onSuccessDelete, onFailure, onFinally) => (dispatch) => {
    if (!navigator.onLine) {
      return new Promise(async (resolve, reject) => {
        try {
          let userDb = await getUserLocal();
          const companyId = userDb.userData.companyId;

          if (!companyId) {
            throw "companyId not found";
          }
          const result = await deleteProposal({ quoteId, companyId });
          onSuccessDelete(result.message);
          dispatch({
            type: quoteTypes.QUOTE_PROPOSAL_DATA_DELETE,
            payload: {
              idx: quoteIdx,
            },
          });
        } catch (err) {
          console.error("error editing material", err);
          onFailure(err);
        }
      });
    } else {
      return axiosInstance
        .delete(`${ApiEndpoints.QUOTE.QUOTE_PROPOSAL_DATA}/${quoteId}`)
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            onSuccessDelete(res.result.message);
            dispatch({
              type: quoteTypes.QUOTE_PROPOSAL_DATA_DELETE,
              payload: {
                idx: quoteIdx,
              },
            });
          } else {
            onFailure(res.error);
          }
        })
        .catch((e) => onFailure(e.message))
        .finally(onFinally);
    }
  };

export const displayProposalTab = (show) => (dispatch) =>
  dispatch({
    type: quoteTypes.QUOTE_PROPOSAL_TAB_DISPLAY,
    payload: show,
  });

export const displayProposalTabModal =
  (show = false) =>
  (dispatch) =>
    dispatch({
      type: quoteTypes.QUOTE_PROPOSAL_MODAL_DISPLAY,
      payload: show,
    });

export const changeProposalModalIndex = (currentIndex) => (dispatch) =>
  dispatch({
    type: quoteTypes.QUOTE_PROPOSAL_TAB_MODAL_INDEX,
    payload: currentIndex,
  });

export const lockeQuoteData =
  (supplierId, metricId, proposalId, data) => (dispatch) => {
    dispatch({ type: quoteTypes.LOCK_QUOTE_DATA_START });
    return axiosInstance
      .patch(
        `${ApiEndpoints.QUOTE.LOCK_QUOTE_DATA}?supplierId=${supplierId}&metricId=${metricId}&proposalId=${proposalId}`,
        data
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: quoteTypes.LOCK_QUOTE_DATA_SUCCESS,
            payload: data.result,
          });
        }
        return dispatch({
          type: quoteTypes.LOCK_QUOTE_DATA_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: quoteTypes.LOCK_QUOTE_DATA_FAILURE,
          payload: errors.message,
        })
      );
  };
