import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { PriceInput } from "components";

import { useDispatch, useSelector } from "react-redux";

import SectionDataList from "../components/SectionDataList";
import { changeSupplier, supplierEmptyText } from "../utils";

import validator from "validator";

import {
  editQuoteGuttersSectionData,
  saveEditedQuoteGuttersSectionData,
  updateTotalQuoteGuttersSectionData,
} from "lib/redux/quote-proposals/quotes-proposals.actions";

import SelectSuppliers, {
  SupplierItemOnlyName,
  SelectSuppliersSection,
} from "../components/SelectSupliers";

import QTYInput from "../components/QTYInput";

import styles from "lib/config/baseStyles";

import { uuid } from "uuidv4";
import LoadingSkeleton from "../components/LoadingSkeleton";
import displayMsg from "components/displayMsg";
import GuttersMiscItem from "./GuttersMiscItem";
import { debounce } from "utils/utils";
import { SuppliersText } from "../const";

export default function Gutters({
  suppliers,
  selectedSupplier,
  setSelectedSupplier,
  metricId,
  isLockQuoteData,
}) {
  const dispatch = useDispatch();

  const [allTotals, setAllTotals] = useState({});

  const {
    gettingGuttersSectionData,
    quoteGuttersSectionData,
    quoteGuttersSectionDataErr,
  } = useSelector((state) => state.quoteGuttersSectionData);
  const { quoteMaterialSectionData } = useSelector(
    (state) => state.quoteMaterialSectionData
  );
  // const updateData = (idx, newData) => {
  // 	let tempData = [...quoteData]
  // 	tempData.splice(idx, 1, newData)
  // 	setListData(tempData)
  // }
  const handleChange = (e, cb) => cb(e.target.value);

  // useEffect(() => {
  // 	if (!isEmpty(allTotals)) {
  // 		let amt = 0
  // 		Object.keys(allTotals).map(key => (amt += allTotals[key]))
  // 		setGutterTotal(amt)
  // 	} else {
  // 		setGutterTotal(0)
  // 	}
  // }, [allTotals])

  // useEffect(() => {
  // 	setAllTotals({})
  // }, [selectedSupplier])

  const onChangeSupplier = (e) => {
    changeSupplier(e.target.value, setSelectedSupplier);
  };

  const [error, setError] = useState(false);
  const saveSectionDataToDB = (data) => {
    dispatch(saveEditedQuoteGuttersSectionData(data))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          setError(false);
          updateTotals(res.result);
        }
      })
      .catch((err) => setError(true));
  };

  const getSubmissionData = (sectionData) => ({
    dataId: quoteGuttersSectionData.dataId,
    sectionData,
  });
  const getTotalData = () => ({
    guttersSubTotal: quoteGuttersSectionData.guttersSubTotal,
  });

  const updateTotals = (updatedData) => {
    dispatch(updateTotalQuoteGuttersSectionData(updatedData));
  };

  const debounceSaveData = useRef(
    debounce((dataArr) => saveSectionDataToDB(dataArr), 1000)
  );

  const saveData = (dataArr) => {
    const data = getSubmissionData(dataArr);
    const dataWithTotal = {
      ...getTotalData(),
      ...data,
    };
    dispatch(editQuoteGuttersSectionData(dataWithTotal));
    debounceSaveData.current(data);
  };

  const onAddMisc = () => {
    const initialAmount = Number(0).toFixed(2);
    const newData = {
      _id: uuid(),
      series: "",
      uom: "",
      qty: "",
      uomPrice: initialAmount,
      total: initialAmount,
      isCustom: true,
    };

    addItem(newData);
  };

  const getGuttersData = () => quoteGuttersSectionData.sectionData.slice();

  const addItem = (item) => {
    let dataArr = getGuttersData();
    dataArr.push(item);
    saveData(dataArr);
  };

  const editItem = (idx, dataItem) => {
    let dataArr = getGuttersData();
    dataArr.splice(idx, 1, dataItem);
    saveData(dataArr);
  };

  const deleteItem = (idx) => {
    let dataArr = getGuttersData();
    dataArr.splice(idx, 1);
    saveData(dataArr);
  };

  const retry = () => {
    saveSectionDataToDB(quoteGuttersSectionData);
  };

  return (
    <div style={{ margin: "1em" }}>
      <section>
        <Row>
          <Col style={styles.reduceWidthCol}>
            <h4>Gutters / Leaders</h4>
          </Col>
        </Row>

        <SelectSuppliers
          selectedSupplier={selectedSupplier}
          onSelectSupplier={onChangeSupplier}
          loading={false}
          suppliers={suppliers}
          emptyText={supplierEmptyText(suppliers.length)}
          SupplierItem={SupplierItemOnlyName}
          isLockQuoteData={isLockQuoteData}
        />
        <SelectSuppliersSection>
          <Col>
            <Form.Label style={{ marginTop: "10%", marginLeft: "30%" }}>
              Type
            </Form.Label>
          </Col>
          <Col>
            <Form.Label style={{ marginTop: "10%", marginRight: "13%" }}>
              Style
            </Form.Label>
          </Col>
          <Col>
            <Form.Label style={{ marginTop: "10%" }}>Size</Form.Label>
          </Col>
          <Col>
            <Form.Label style={{ marginTop: "10%" }}>Color</Form.Label>
          </Col>
        </SelectSuppliersSection>

        <SectionDataList
          metricId={metricId}
          saveSectionDataToDB={saveSectionDataToDB}
          selectedSupplier={selectedSupplier}
          MiscItemComponent={GuttersMiscItem}
          LoadingSkeleton={LoadingSkeleton}
          IndividualDataItem={GuttersIndividualDataItem}
          displayMsg={displayMsg}
          loadingData={gettingGuttersSectionData}
          sectionData={
            quoteGuttersSectionData
              ? quoteGuttersSectionData.sectionData
              : quoteGuttersSectionData
          }
          dataError={quoteGuttersSectionDataErr}
          deleteMiscItem={deleteItem}
          editMiscItem={editItem}
          isLockQuoteData={isLockQuoteData}
        />

        <MiscItemComponent
          onAddMisc={onAddMisc}
          deleteItem={deleteItem}
          editItem={editItem}
          isLockQuoteData={isLockQuoteData}
        />

        {quoteGuttersSectionData && (
          <TotalSection total={quoteGuttersSectionData.guttersSubTotal} />
        )}
      </section>
    </div>
  );
}

function TotalSection({ total }) {
  return (
    <Row>
      <Col style={styles.reduceWidthCol} lg="6"></Col>
      <Col style={styles.reduceWidthCol} style={{ flex: "77%" }}>
        <Row>
          <Col style={styles.reduceWidthCol} style={{ textAlign: "right" }}>
            <Form.Label style={{ marginTop: "2%" }}>
              Gutter Labor Sub Total:
            </Form.Label>
          </Col>
        </Row>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput
          price={total}
          disabled={true}
          style={{ background: "#28a745", color: "#fff" }}
        />
      </Col>
    </Row>
  );
}

const MiscItemComponent = ({ onAddMisc, isLockQuoteData }) => (
  <Row>
    <Col style={styles.reduceWidthCol} lg="2">
      <Button onClick={onAddMisc} disabled={isLockQuoteData?.isLocked}>
        Add Misc Item
      </Button>
    </Col>
  </Row>
);

function GuttersListData({
  listData,
  updateData,
  idx,
  color,
  size,
  style,
  subtype,
  selectedSupplier,
  categoryType,
  category,
  qtyValue,
  allTotals,
  setAllTotals,
  qty,
  changeQty,
  uom,
  uomPrice,
  total,
  quoteMaterialSectionData,
  original_list,
  isLockQuoteData,
}) {
  return (
    <Row>
      <Col style={styles.reduceWidthCol}>
        <Form.Group>
          <Form.Control
            disabled={true}
            value={subtype}
            type="text"
            style={{ textAlign: "center" }}
          />
        </Form.Group>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <Form.Group>
          <Form.Control
            disabled={true}
            value={style === "N/A" ? categoryType : style}
            type="text"
            style={{ textAlign: "center" }}
          />
        </Form.Group>
      </Col>
      <Col style={styles.reduceWidthCol}>
        {size !== "N/A" ? (
          <Form.Group>
            <Form.Control
              disabled={true}
              value={size}
              type="text"
              style={{ textAlign: "center" }}
            />
          </Form.Group>
        ) : null}
      </Col>
      <Col style={styles.reduceWidthCol}>
        {color !== "N/A" ? (
          <Form.Group>
            <Form.Control
              disabled={true}
              value={color}
              type="text"
              style={{ textAlign: "center" }}
            />
          </Form.Group>
        ) : null}
      </Col>

      <Col style={styles.reduceWidthCol}>
        <Form.Group>
          <Form.Control
            disabled={true}
            value={uom}
            type="text"
            style={{ textAlign: "center" }}
          />
        </Form.Group>
      </Col>
      <Col style={styles.reduceWidthCol}>
        <QTYInput
          value={qty}
          onChange={changeQty}
          styless={original_list?.qty != qty}
          disabled={true} //isLockQuoteData?.isLocked} //client request: gutters' QTY is readonly
        />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput disabled={true} price={uomPrice} />
      </Col>
      <Col style={styles.reduceWidthCol}>
        <PriceInput disabled={true} price={total} input="Total" />
      </Col>
    </Row>
  );
}

function GuttersIndividualDataItem({
  pricing,
  selectedSupplier,
  idx,
  metricId,
  sectionData,
  saveSectionDataToDB,
  editItem,
  isLockQuoteData,
}) {
  const {
    _id,
    categoryType,
    category,
    color,
    size,
    style,
    subType,
    qty,
    uomPrice,
    uom,
    total,
    original_list,
  } = pricing;

  const changeQty = (e) => {};

  const dispatch = useDispatch();

  const handleEdit = (newData) => editItem(newData);

  const changeData = (key, value) => ({
    ...pricing,
    [key]: value,
  });

  const handleChangeQty = (e) => {
    const value = e.target.value;

    const newTotal =
      validator.isNumeric(value) && validator.isNumeric(uomPrice)
        ? Number(value * uomPrice).toFixed(2)
        : total;

    const newData = changeData("qty", value);
    newData.total = newTotal;

    handleEdit(newData);
  };

  return (
    <>
      <GuttersListData
        key={_id}
        categoryType={categoryType}
        category={category}
        color={color}
        size={size}
        style={style}
        subtype={subType}
        selectedSupplier={selectedSupplier}
        qty={qty}
        idx={idx}
        changeQty={handleChangeQty}
        uom={uom}
        uomPrice={uomPrice}
        total={total}
        original_list={original_list}
        isLockQuoteData={isLockQuoteData}
      />
    </>
  );
}
