import React, { useState, useEffect, useLayoutEffect } from "react";
import { Button, Modal, Col, Row } from "react-bootstrap";
import { PopupModal, ArrowPagination } from "components";
import { getRoofingSectionData, getGuttersSectionData } from "utils/utils";

import staticTexts from "./staticTexts";

const MetricTypes = {
  roof: "Switch to Roofing",
  gutter: "Switch to  Gutter",
};

const RoofingTableHeadsAndDataMapping = {
  sec: "Sec",
  floor: "Floor",
  elevation: "Elevation",
  pitch: "Pitch",
  workType: "Work Type",
  install: "Install",
  l: "L",
  w: "W",
  f: "F",
  sq: "Sq Ft(S)",
  flat: "Sq Ft(F)",
};

const GutterTableHeadsAndDataMapping = {
  sec: "Sec",
  level: "Level",
  elevation: "Elevation",
  gutters: "Gutters",
  leaders: "Leaders",
  screens: "Screens",
  fascia: "Fascia",
  cap: "Cap",
  heads: "Heads",
};

const SectionTable = ({
  list,
  startI,
  lastI,
  includeItem,
  excludeItem,
  excludedSections,
  setExcludedSections,
  tableDataMapping,
}) =>
  list.length > 0 ? (
    <div className="__quote_tbl_wrapper">
      <table className="__quote_tbl">
        <thead>
          <tr>
            {Object.keys(tableDataMapping).map((key) => (
              <th key={key}>{tableDataMapping[key]}</th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {list.slice(startI, lastI).map((info) => (
            <tr key={info.id}>
              {Object.keys(tableDataMapping).map((key) => (
                <td style={{ fontWeight: "500" }} key={key}>
                  {info[key]}
                </td>
              ))}
              <td>
                {excludedSections.includes(info.id) ? (
                  <Button
                    onClick={() =>
                      excludeItem(
                        excludedSections,
                        setExcludedSections,
                        info.id
                      )
                    }
                  >
                    Include
                  </Button>
                ) : (
                  <Button
                    variant="danger"
                    onClick={() =>
                      includeItem(
                        excludedSections,
                        setExcludedSections,
                        info.id
                      )
                    }
                  >
                    Exclude
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div
      style={{
        textAlign: "center",
        margin: "3em 0 1em 0",
        color: "#999",
        fontWeight: "bold",
      }}
    >
      No section data available.
    </div>
  );
const SectionTableHeader = ({
  selectedBuildingName,
  currentMetricType,
  MetricTypes,
  isAllSectionRoof,
  isAllSectionGutter,
  includeAllSectionRoof,
  excludeAllSectionRoof,
  includeAllSectionGutter,
  excludeAllSectionGutter,
}) => {
  const hyphen = selectedBuildingName ? " - " : "";
  return currentMetricType === MetricTypes.roof ? (
    <Row>
      <Col>
        <h4 style={{ textAlign: "center", margin: "8px 0" }} className="my-2  ">
          {currentMetricType === MetricTypes.roof
            ? selectedBuildingName + hyphen + "Roofing"
            : selectedBuildingName + hyphen + "Gutters"}
        </h4>
      </Col>
      <Col lg="3">
        {isAllSectionRoof ? (
          <Button
            style={{
              width: "250px",
              height: "50px",
              margin: "0 1em 1em 0",
              float: "right",
              fontWeight: "bold",
            }}
            // onClick={() =>
            //   excludeItem(
            //     excludedBuildings,
            //     setExcludedBuildings,
            //     selectedBuilding
            //   )
            // }
            onClick={includeAllSectionRoof}
          >
            Include All Section
          </Button>
        ) : (
          <Button
            style={{
              width: "250px",
              height: "50px",
              margin: "0 1em 1em 0",
              float: "right",
              fontWeight: "bold",
            }}
            className="btn btn-danger"
            onClick={excludeAllSectionRoof}
          >
            Exclude All Sections
          </Button>
        )}
      </Col>
    </Row>
  ) : (
    <Row>
      <Col>
        <h4 style={{ textAlign: "center", margin: "8px 0" }} className="my-2  ">
          {currentMetricType === MetricTypes.roof
            ? selectedBuildingName + hyphen + "Roofing"
            : selectedBuildingName + hyphen + "Gutters"}
        </h4>
      </Col>
      <Col lg="3">
        {isAllSectionGutter ? (
          <Button
            style={{
              width: "250px",
              height: "50px",
              margin: "0 1em 1em 0",
              float: "right",
              fontWeight: "bold",
            }}
            // onClick={() =>
            //   excludeItem(
            //     excludedBuildings,
            //     setExcludedBuildings,
            //     selectedBuilding
            //   )
            // }
            onClick={includeAllSectionGutter}
          >
            Include All Section
          </Button>
        ) : (
          <Button
            style={{
              width: "250px",
              height: "50px",
              margin: "0 1em 1em 0",
              float: "right",
              fontWeight: "bold",
            }}
            className="btn btn-danger"
            // onClick={() =>
            //   includeItem(
            //     excludedBuildings,
            //     setExcludedBuildings,
            //     selectedBuilding
            //   )
            // }
            onClick={excludeAllSectionGutter}
          >
            Exclude All Sections
          </Button>
        )}
      </Col>
    </Row>
  );
};
const GutterSection = ({
  list,
  startI,
  lastI,
  includeItem,
  excludeItem,
  excludedSections,
  setExcludedSections,
}) =>
  list.length > 0 ? (
    <div className="__quote_tbl_wrapper">
      <table className="__quote_tbl">
        <thead>
          <tr>
            <th>Sec</th>
            <th>Level</th>
            <th>Elevation</th>
            <th>Gutters</th>
            <th>Leaders</th>

            <th>Screens</th>
            <th>Fascia</th>
            <th>Cap</th>
            <th>Heads</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {list.slice(startI, lastI).map((info) => (
            <tr key={info.id}>
              <td>{info.sec}</td>
              <td>{info.level}</td>
              <td>{info.elevation}</td>
              <td>{info.gutters}</td>

              <td>{info.leaders}</td>
              <td>{info.screens}</td>
              <td>{info.fascia}</td>
              <td>{info.cap}</td>
              <td>{info.heads}</td>
              <td>
                {excludedSections.includes(info.id) ? (
                  <Button
                    onClick={() =>
                      excludeItem(
                        excludedSections,
                        setExcludedSections,
                        info.id
                      )
                    }
                  >
                    Include
                  </Button>
                ) : (
                  <Button
                    variant="danger"
                    onClick={() =>
                      includeItem(
                        excludedSections,
                        setExcludedSections,
                        info.id
                      )
                    }
                  >
                    Exclude
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div
      style={{
        textAlign: "center",
        margin: "3em 0 1em 0",
        color: "#999",
        fontWeight: "bold",
      }}
    >
      No section data available.
    </div>
  );

const getSectionList = (metricData = [], getSectionData, selectedBuilding) => {
  const sectionList = [];
  metricData.map((section, i) => {
    if (selectedBuilding && selectedBuilding !== section.building.toString())
      return;
    return sectionList.push(getSectionData(section, i));
  });
  return sectionList;
};

function SectionListModal({
  show,
  onHide,
  metrics,
  showBuilding,
  toggleModals,
  selectedBuilding,
  excludedSections,
  setExcludedSections,
  onSaveNewQuote,
  selectedBuildingName,
  setIsAllSectionGutter,
  isAllSectionGutter,
  setIsAllSectionRoof,
  isAllSectionRoof,
}) {
  const [pageNum, setPageNum] = useState(1);
  const [maxItem] = useState(5);
  const [address, setAddress] = useState(5);

  const [showDifferentMetric, setShowDifferentMetric] = useState(false);
  const [currentMetricType, setCurrentMetricType] = useState(
    metrics.roof.hasRoof ? MetricTypes.roof : MetricTypes.gutter
  );
  const [excludeDisable, setExcludeDisable] = useState(false);
  const changeShowDifferentMetric = (value) => {
    setShowDifferentMetric(value);
  };

  const diplayShowDifferentMetric = () => {
    changeShowDifferentMetric(true);
  };
  useEffect(() => {
    if (metrics && metrics.gutter.hasGutters) {
      setAddress(metrics.roof.address);
      if (metrics.gutter.hasRoof) {
        diplayShowDifferentMetric();
      }
    }

    if (metrics && metrics.roof.hasRoof) {
      if (metrics.roof.hasGutters) {
        diplayShowDifferentMetric();
      }
    }
  }, [metrics, show, selectedBuilding]);
  useEffect(() => {
    if (!show) {
      setExcludeDisable(false);
    }
  }, [show]);
  useLayoutEffect(() => {
    if (!metrics) return;
    if (currentMetricType === MetricTypes.roof) {
      if (metrics.roof.metricData && metrics.roof.metricData.length > 0) {
        setList(
          getSectionList(
            metrics.roof.metricData,
            getRoofingSectionData,
            selectedBuilding
          )
        );
        setPageNum(1);
      }
    } else if (
      metrics.gutter.metricData &&
      metrics.gutter.metricData.length > 0
    ) {
      setList(
        getSectionList(
          metrics.gutter.metricData,
          getGuttersSectionData,
          selectedBuilding
        )
      );
      setPageNum(1);
    }
  }, [currentMetricType, metrics, selectedBuilding]);
  const [list, setList] = useState([]);

  const prevPage = () => setPageNum(pageNum - 1);

  const nextPage = () => setPageNum(pageNum + 1);

  const lastI = pageNum * maxItem;
  const startI = lastI - maxItem;

  const excludeItem = (array, setArray, item) => {
    const temp = [...array];
    if (temp.includes(item)) {
      temp.splice(temp.indexOf(item), 1);
    }
    setArray(temp);
  };

  const includeItem = (array, setArray, item) => {
    // setExcludeDisable(item);
    setExcludeDisable(true);

    const temp = [...array];
    temp.push(item);
    setArray(temp);
  };
  const excludeAllSectionRoof = () => {
    setExcludeDisable(true);
    setIsAllSectionRoof(true);
    const arrayofexcludeId = list.map((a) => a.id);
    const conncatExclude = excludedSections.concat(arrayofexcludeId);
    const allExcludeSection = conncatExclude.filter(
      (item, index) => conncatExclude.indexOf(item) === index
    );
    setExcludedSections(allExcludeSection);
  };
  const includeAllSectionRoof = () => {
    setExcludeDisable(false);

    setIsAllSectionRoof(false);
    const arrayofid = list.map((a) => a.id);
    const temp = [...excludedSections];
    arrayofid.map((val) => {
      if (temp.includes(val) === true) {
        let duplicateValue = temp.indexOf(val);
        temp.splice(duplicateValue, 1);
      }
    });
    setExcludedSections(temp);
  };
  const excludeAllSectionGutter = () => {
    setIsAllSectionGutter(true);
    setExcludeDisable(true);

    const arrayofexcludeId = list.map((a) => a.id);
    const conncatExclude = excludedSections.concat(arrayofexcludeId);
    const allExcludeSection = conncatExclude.filter(
      (item, index) => conncatExclude.indexOf(item) === index
    );
    setExcludedSections(allExcludeSection);
  };
  const includeAllSectionGutter = () => {
    setExcludeDisable(false);

    setIsAllSectionGutter(false);
    const arrayofid = list.map((a) => a.id);
    const temp = [...excludedSections];
    arrayofid.map((val) => {
      if (temp.includes(val) === true) {
        let duplicateValue = temp.indexOf(val);
        temp.splice(duplicateValue, 1);
      }
    });
    setExcludedSections(temp);
  };
  const toggleMetric = () => {
    setList([]);
    if (currentMetricType === MetricTypes.roof) {
      setCurrentMetricType(MetricTypes.gutter);
    } else {
      setCurrentMetricType(MetricTypes.roof);
    }
  };

  return (
    <PopupModal
      show={show}
      heading=""
      onHide={() => onHide()}
      bold
      centered={false}
      styles={{ border: "none" }}
      size="2xl"
    >
      <Row style={{ marginTop: "-1.5em" }}>
        <Col>
          <h3 style={{ fontWeight: "bold" }}>
            {staticTexts.sectionHeading} - &nbsp;
            {address}
          </h3>
        </Col>
      </Row>
      <p
        style={{
          fontWeight: "500",
          // marginTop: '1rem',
          lineHeight: "1.1rem",
          // marginTop: '-1.5em'
        }}
      >
        {staticTexts.sectionSubHead}
      </p>
      <p
        style={{
          fontWeight: "500",
          marginTop: "-0.9rem",
          lineHeight: "1.1rem",
        }}
      >
        {`To remove all ${
          currentMetricType === MetricTypes.roof ? "roofing" : "gutter"
        }` + staticTexts.sectionSubHead2}
      </p>

      <SectionTableHeader
        selectedBuildingName={selectedBuildingName}
        currentMetricType={currentMetricType}
        MetricTypes={MetricTypes}
        isAllSectionRoof={isAllSectionRoof}
        isAllSectionGutter={isAllSectionGutter}
        includeAllSectionRoof={includeAllSectionRoof}
        excludeAllSectionRoof={excludeAllSectionRoof}
        includeAllSectionGutter={includeAllSectionGutter}
        excludeAllSectionGutter={excludeAllSectionGutter}
      />
      <SectionTable
        list={list}
        excludedSections={excludedSections}
        setExcludedSections={setExcludedSections}
        includeItem={includeItem}
        excludeItem={excludeItem}
        startI={startI}
        lastI={lastI}
        tableDataMapping={
          currentMetricType === MetricTypes.roof
            ? RoofingTableHeadsAndDataMapping
            : GutterTableHeadsAndDataMapping
        }
      />

      <Modal.Footer
        style={{
          justifyContent: "space-between",
          border: "none",
          padding: "0 .75rem",
          marginTop: "20px",
        }}
      >
        <div>
          {showDifferentMetric && (
            <Button
              style={{
                width: "200px",
                height: "50px",
                margin: "0 1em 1em 0",
                float: "left",
                fontWeight: "bold",
              }}
              onClick={toggleMetric}
            >
              {currentMetricType === MetricTypes.roof
                ? MetricTypes.gutter
                : MetricTypes.roof}
            </Button>
          )}
        </div>
        <ArrowPagination
          totalItem={list.length}
          pageNum={pageNum}
          prevPage={prevPage}
          nextPage={nextPage}
          maxItem={maxItem}
        />
        <div>
          <Button
            style={{
              width: "130px",
              height: "50px",
              margin: "0 1em 1em 0",
              fontWeight: "bold",
            }}
            variant="danger modified-btn"
            onClick={onHide}
          >
            Cancel
          </Button>
          {showBuilding && (
            <Button
              style={{
                width: "200px",
                height: "50px",
                margin: "0 1em 1em 0",
                fontWeight: "bold",
              }}
              onClick={() => toggleModals(false, true)}
            >
              Show Building List
            </Button>
          )}
          <Button
            style={{
              width: "130px",
              height: "50px",
              margin: "0 1em 1em 0",
              fontWeight: "bold",
            }}
            onClick={onSaveNewQuote}
            // disabled={!excludeDisable}
          >
            Generate
          </Button>
        </div>
      </Modal.Footer>
    </PopupModal>
  );
}

export default SectionListModal;
