import React from "react";
import { Row, Col, Form, Alert, InputGroup, Button } from "react-bootstrap";
import validator from "validator";
import RequiredText from "components/required-text/required-text";
import MaskedInput from "react-maskedinput";

import { checkPhone } from "utils/utils";
import InitialLoader from "components/InitilalLoader";

const newStyle = {
  formInput: {
    marginBottom: "15px",
  },
  formStyle: {
    // marginTop: "5%"
  },
};

class AdminDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errMsg: "",
      errAt: "",
      errType: "warning", //danger, success, info, warning
      submitting: false,
    };
    this.firstNameField = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentStep } = this.props;
    if (currentStep !== prevProps.currentStep && currentStep === 1) {
      this.firstNameField.current.focus();
    }
  }

  isValidated() {
    const {
      fName,
      lName,
      aEmail,
      aConfirmEmail,
      password,
      confirmPassword,
      aPhone,
      type,
      company_id,
    } = this.props;
    const checkPassword = /^(?=.*\d)(?=.*[A-Za-z]).{6,20}$/;

    if (fName === "" && !type) {
      this.setState({
        errMsg: "First Name is required.",
        errAt: "fName",
        errType: "danger",
      });
      return false;
    } else if (lName === "" && !type) {
      this.setState({
        errMsg: "Last Name is required.",
        errAt: "lName",
        errType: "danger",
      });
      return false;
    } else if (aEmail === "" && !type) {
      this.setState({
        errMsg: "Email is required.",
        errAt: "aEmail",
        errType: "danger",
      });
      return false;
    } else if (!validator.isEmail(aEmail) && !type) {
      this.setState({
        errMsg: "Invalid email address.",
        errAt: "aEmail",
        errType: "warning",
      });
      return false;
    } else if (aConfirmEmail === "" && !type) {
      this.setState({
        errMsg: "Please re-enter email.",
        errAt: "aConfirmEmail",
        errType: "danger",
      });
      return false;
    } else if (aEmail !== aConfirmEmail && !type) {
      this.setState({
        errMsg: "Email and confirm email mismatch.",
        errAt: "aConfirmEmail",
        errType: "warning",
      });
      return false;
    } else if (
      password === "" &&
      type !== "edit" &&
      type !== "Edit_But_Not_Verified"
    ) {
      this.setState({
        errMsg: "Password is required.",
        errAt: "password",
        errType: "danger",
      });
      return false;
    } else if (
      password === "" &&
      type !== "edit" &&
      type !== "Edit_But_Not_Verified"
    ) {
      this.setState({
        errMsg: "Please re-enter password.",
        errAt: "password",
        errType: "danger",
      });
      return false;
    } else if (
      password !== "" &&
      password.length < 6 &&
      type !== "edit" &&
      type !== "Edit_But_Not_Verified"
    ) {
      this.setState({
        errMsg:
          "Password must be at least 6 characters and must contain one letter or one number.",
        errAt: "password",
        errType: "warning",
      });
      return false;
    } else if (
      password !== "" &&
      password.length >= 6 &&
      !password.match(checkPassword) &&
      type !== "edit" &&
      type !== "Edit_But_Not_Verified"
    ) {
      this.setState({
        errMsg:
          "Password must be at least 6 characters and must contain one letter or one number.",
        errAt: "password",
        errType: "warning",
      });
      return false;
    } else if (
      confirmPassword !== password &&
      type !== "edit" &&
      type !== "Edit_But_Not_Verified"
    ) {
      this.setState({
        errMsg: "Password and confirm password mismatch.",
        errAt: "confirmPassword",
        errType: "warning",
      });
      return false;
    } else if (aPhone !== "" && checkPhone(aPhone) && !type) {
      this.setState({
        errMsg: "Phone should have 10 digits.",
        errAt: "aPhone",
        errType: "warning",
      });
      return false;
    } else {
      this.setState({
        errMsg: "",
        errAt: "",
        errType: "",
      });
      return true;
    }
  }

  render() {
    const {
      fName,
      lName,
      aEmail,
      aConfirmEmail,
      aPhone,
      password,
      confirmPassword,
      handleChange,
      type,
      baseUsers,
      company_id,
    } = this.props;

    const { errType, errMsg, errAt, submitting } = this.state;
    return (
      <>
        {submitting ? (
          <InitialLoader />
        ) : (
          <Form onSubmit={this.onSubmit} style={newStyle.formStyle}>
            <div className="mt-4">
              <p className="text-sm">
                This person is the administrator of the Organized Roofer
                subscription and one of the ({baseUsers}) user of the application.
              </p>
            </div>
            <div style={{ width: "100%", textAlign: "right" }}>
              <RequiredText>* Required</RequiredText>
            </div>
            <Row>
              <Col xs="12">
                {errMsg && <Alert variant={errType}>{errMsg}</Alert>}
              </Col>

              <Col xs="12">
                <Row>
                  <Col md="6">
                    <Form.Group style={newStyle.formInput}>
                      <Form.Label>
                        First Name <RequiredText>*</RequiredText>
                      </Form.Label>
                      <Form.Control
                        ref={this.firstNameField}
                        type="text"
                        name="fName"
                        value={fName}
                        placeholder="Enter first name"
                        required="required"
                        onChange={(event) => handleChange(event)}
                        isInvalid={errAt === "fName"}
                      />
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group style={newStyle.formInput}>
                      <Form.Label>
                        Last Name <RequiredText>*</RequiredText>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lName"
                        value={lName}
                        placeholder="Enter last name"
                        required="required"
                        onChange={(event) => handleChange(event)}
                        isInvalid={errAt === "lName"}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col xs="12">
                <Row>
                  <Col md="6">
                    <Form.Group style={newStyle.formInput}>
                      <Form.Label>
                        Email <RequiredText>*</RequiredText>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="aEmail"
                        value={aEmail}
                        placeholder="Enter email address"
                        required="required"
                        onChange={(event) => handleChange(event)}
                        isInvalid={errAt === "aEmail"}
                      />
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group style={newStyle.formInput}>
                      <Form.Label>
                        Retype Email <RequiredText>*</RequiredText>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="aConfirmEmail"
                        value={aConfirmEmail}
                        placeholder="Re-enter email address"
                        required="required"
                        onChange={(event) => handleChange(event)}
                        isInvalid={errAt === "aConfirmEmail"}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              {type === "edit" ? (
                ""
              ) : (
                <Col xs="12">
                  <Row>
                    <Col md="6">
                      <Form.Group style={newStyle.formInput}>
                        <Form.Label>
                          Password <RequiredText>*</RequiredText>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          value={password}
                          placeholder="Enter password"
                          required="required"
                          onChange={(event) => handleChange(event)}
                          isInvalid={errAt === "password"}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group style={newStyle.formInput}>
                        <Form.Label>
                          Confirm Password <RequiredText>*</RequiredText>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          value={confirmPassword}
                          placeholder="Re-enter password"
                          required="required"
                          onChange={(event) => handleChange(event)}
                          isInvalid={errAt === "confirmPassword"}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              )}

              <Col md="6">
                <Form.Group style={newStyle.formInput}>
                  <Form.Label>
                    Admin Phone(If different than company)
                  </Form.Label>
                  <InputGroup>
                    {/* <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">+1</InputGroup.Text>
                </InputGroup.Prepend> */}
                    <MaskedInput
                      className="form-control"
                      mask="(111) 111-1111"
                      type="text"
                      name="aPhone"
                      value={aPhone}
                      placeholder="Enter Phone"
                      required="required"
                      maxLength={10}
                      onChange={(event) => handleChange(event)}
                      isInvalid={errAt === "aPhone"}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            {/* {!type ? (
              <Button
                variant="outline-success"
                disabled={
                  fName &&
                    lName &&
                    aEmail &&
                    aConfirmEmail &&
                    // aPhone &&
                    password &&
                    confirmPassword
                    ? false
                    : true
                }
                onClick={this.onSubmit}
                style={{
                  width: "5.5rem",
                  height: "2.4rem",
                  marginBottom: "1rem",
                }}
              >
                Submit
              </Button>
            ) : (
              ""
            )} */}
          </Form>
        )}
      </>
    );
  }
}

export default AdminDetails;
