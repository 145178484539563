export const basePricingActionTypes = {
  BASE_PRICING_START: "BASE_PRICING_START",
  BASE_PRICING_SUCCESS: "BASE_PRICING_SUCCESS",
  BASE_PRICING_FAILURE: "BASE_PRICING_FAILURE",
  SUBSCRIPTION_INFO_START: "SUBSCRIPTION_INFO_START",
  SUBSCRIPTION_INFO_SUCCESS: "SUBSCRIPTION_INFO_SUCCESS",
  SUBSCRIPTION_INFO_FAILURE: "SUBSCRIPTION_INFO_FAILURE",
  EDIT_SUBSCRIPTION_INFO_START: "EDIT_SUBSCRIPTION_INFO_START",
  EDIT_SUBSCRIPTION_INFO_SUCCESS: "EDIT_SUBSCRIPTION_INFO_SUCCESS",
  EDIT_SUBSCRIPTION_INFO_FAILURE: "EDIT_SUBSCRIPTION_INFO_FAILURE",
};
