import React, { useState, useEffect, useRef } from "react";

import { Row, Col, Button, Tab, Tabs, Form, Modal } from "react-bootstrap";
import { debounce, includes } from "lodash";
import { updateStandardStatements } from "lib/redux/auto-text/auto-text.actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const styles = {
  btnWrapper: {
    flex: "0 0 10%",
  },
  priceInp: {
    flex: "0 0 12%",
  },
};

const EmptyBlockText =
  "Area of proposal where the work type description line items will be inserted unique to each proposal.";

const CommonBtnsAndTitles = ({
  children,
  edit,
  displayText,
  setEdit,
  detail,
  hasPrice,
  price,
  hideTitle = false,
  permission,
  showPercentageStepperModal,
  idx,
  special,
}) => {
  const [loading, setLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(detail.isSelected);
  const dispatch = useDispatch();

  const onSuccess = () => {
    toast.success("Successfully updated the statement.");
    setEdit(false);
    setLoading(false);
  };

  const onError = (errMsg) => {
    toast.error(errMsg);
    setLoading(false);
  };

  const updateData = (include) => {
    setLoading(true);
    let postData = {
      isSelected: include === undefined ? isSelected : include,
      displayText,
    };
    postData = hasPrice ? { ...postData, price } : postData;
    dispatch(
      updateStandardStatements(detail._id, postData, onSuccess, onError)
    );
  };

  const includeOrExclude = (include) => {
    setIsSelected(include);
    updateData(include);
  };

  return (
    <>
      {!hideTitle ? (
        <Row
          style={
            permission
              ? { marginTop: "1em" }
              : { pointerEvents: "none", marginTop: "1em" }
          }
        >
          <Col style={styles.btnWrapper} />
          <Col>
            <Form.Label style={{ fontWeight: special ? "bold" : "normal" }}>
              {detail.label}
            </Form.Label>
          </Col>
          <Col style={styles.btnWrapper} />
        </Row>
      ) : null}
      <Row style={permission ? {} : { pointerEvents: "none" }}>
        <Col style={styles.btnWrapper}>
          {isSelected ? (
            <Button
              variant="danger"
              disabled={loading || edit}
              onClick={() => {
                includeOrExclude(false);
              }}
            >
              Exclude
            </Button>
          ) : (
            <Button
              variant="success"
              disabled={loading || edit}
              onClick={() => {
                includeOrExclude(true);
              }}
            >
              Include
            </Button>
          )}
        </Col>
        <Col
          style={
            isSelected ? {} : { textDecoration: "line-through", color: "gray" }
          }
        >
          {children}
        </Col>
        <Col style={styles.btnWrapper}>
          {edit ? (
            <Button
              className="__btn_expanded"
              disabled={loading}
              variant="outline-success"
              onClick={() => updateData()}
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          ) : (
            <Button
              className="__btn_expanded"
              variant="outline-secondary"
              disabled={loading}
              onClick={() => setEdit(true)}
            >
              Edit
            </Button>
          )}
        </Col>
        <Col style={styles.btnWrapper}>
          {edit ? (
            <Button
              className="__btn_expanded"
              variant="danger"
              disabled={loading}
              onClick={() => setEdit(false)}
            >
              Cancel
            </Button>
          ) : (
            detail.percentage !== undefined &&
            hasPrice && (
              <Button
                className="__btn_expanded"
                variant="outline-primary"
                disabled={loading}
                onClick={() => showPercentageStepperModal(detail, idx)}
              >
                <span style={{ fontSize: "0.9em" }}>Percentage</span>
              </Button>
            )
          )}
        </Col>
      </Row>
    </>
  );
};

function LongTextInputWithPrice({
  detail,
  permission,
  showPercentageStepperModal,
  idx,
  updateFixedStandardStatementsPercentage,
}) {
  const [edit, setEdit] = useState(false);
  const text =
    detail.percentage !== undefined
      ? detail.displayText.replace(":", " " + detail.percentage + "%:")
      : detail.displayText;
  const [displayText, setDisplayText] = useState(text);
  const [priceValue, setPriceValue] = useState(detail.price ? detail.price : 0);
  useEffect(() => {
    if (detail.price === undefined) return;
    if (!isNaN(detail.price)) {
      setPriceValue(Number(detail.price).toFixed(2));
    } else {
      setPriceValue(Number(0).toFixed(2));
    }
  }, [detail.price]);

  const debounceChangePrice = useRef(
    debounce((value) => {
      if (!isNaN(value)) {
        setPriceValue(Number(value).toFixed(2));
      } else {
        if (Number(value) === 0) return;
        setPriceValue(Number(0).toFixed(2));
      }
    }, 1500)
  );

  const handleInputChange = (e) => {
    const value = e.target.value.replace("$", "");
    setPriceValue(value);
    debounceChangePrice.current(value);
  };

  return (
    <CommonBtnsAndTitles
      price={priceValue}
      hideTitle
      detail={detail}
      displayText={displayText}
      edit={edit}
      setEdit={setEdit}
      hasPrice={detail.price !== undefined}
      permission={permission}
      showPercentageStepperModal={showPercentageStepperModal}
      idx={idx}
    >
      <Row>
        <Col>
          <Form.Group style={{ marginBottom: "15px" }}>
            <Form.Control
              onChange={(e) => setDisplayText(e.target.value)}
              value={displayText}
              disabled={!edit}
              type="text"
              style={{ marginBottom: "15px" }}
            />
          </Form.Group>
        </Col>
        {detail.price !== undefined &&
          detail.price !== "" &&
          detail.dataId !== "STD010" && (
            <Col style={styles.priceInp}>
              <Form.Group>
                <Form.Control
                  disabled={!edit}
                  type="text"
                  value={`$${priceValue}`}
                  style={{ textAlign: "right" }}
                  onChange={(event) => handleInputChange(event)}
                />
              </Form.Group>
            </Col>
          )}
      </Row>
    </CommonBtnsAndTitles>
  );
}

function LongTextInput({ detail, permission, special }) {
  const [edit, setEdit] = useState(false);
  const [displayText, setDisplayText] = useState(detail.displayText);
  return (
    <CommonBtnsAndTitles
      detail={detail}
      displayText={displayText}
      edit={edit}
      setEdit={setEdit}
      hasPrice={false}
      permission={permission}
      special={special}
    >
      <Form.Group style={{ marginBottom: "15px" }}>
        <Form.Control
          onChange={(e) => setDisplayText(e.target.value)}
          value={displayText}
          disabled={!edit}
          type="text"
          style={{
            marginBottom: "15px",
            textAlign: special ? "center" : "left",
            fontWeight: special ? "bold" : "normal",
          }}
        />
      </Form.Group>
    </CommonBtnsAndTitles>
  );
}

function LongMultiTextIput({ detail, permission }) {
  const [edit, setEdit] = useState(false);
  const [displayText, setDisplayText] = useState(detail.displayText);

  return (
    <CommonBtnsAndTitles
      detail={detail}
      displayText={displayText}
      edit={edit}
      setEdit={setEdit}
      hasPrice={false}
      permission={permission}
    >
      <Form.Group style={{ marginBottom: "15px" }}>
        <Form.Control
          as="textarea"
          rows="3"
          style={{ resize: "none", marginBottom: "15px" }}
          value={displayText}
          disabled={!edit}
          onChange={(e) => setDisplayText(e.target.value)}
        />
      </Form.Group>
    </CommonBtnsAndTitles>
  );
}

function EmptyBlock() {
  return (
    <Row>
      <Col style={styles.btnWrapper} />
      <Col>
        <div className="__empty_block">
          <span>{EmptyBlockText}</span>
        </div>
      </Col>
      <Col style={styles.btnWrapper} />
    </Row>
  );
}

export default function StandardStatements({
  data,
  permission,
  showPercentageStepperModal,
  updateFixedStandardStatementsPercentage,
}) {
  const CATEGORIES = [
    "Standard Proposal Statements",
    "Warranty & Guarantee Statements",
    "Good For Disclosure",
  ];
  const [proposalOpening, setProposalOpening] = useState(null);
  const [standardStatements, setStandardStatements] = useState([]);
  const [warrantyStatements, setWarrantyStatements] = useState([]);
  const [priceStatements, setPriceStatements] = useState([]);
  const [multiLineStatements, setMultiLineStatements] = useState([]);
  const [disclosureStatements, setDisclosureStatements] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const standardStatementInit = [];
      const warrantyStatementsInit = [];
      const priceStatementsInit = [];
      const multiLineStatementsInit = [];
      const disclosureStatementInit = [];

      data.map((statement, i) => {
        if (statement.category === "") {
          setProposalOpening(statement);
        } else if (statement.category === CATEGORIES[0]) {
          standardStatementInit.push(statement);
        } else if (statement.category === CATEGORIES[1]) {
          if (statement.isMultiLine) {
            return multiLineStatementsInit.push(statement);
          }
          if (statement.label.includes("Price")) {
            return priceStatementsInit.push(statement);
          }
          warrantyStatementsInit.push(statement);
        } else if (statement.category === CATEGORIES[2]) {
          disclosureStatementInit.push(statement);
        }
      });
      setStandardStatements(standardStatementInit);
      setWarrantyStatements(warrantyStatementsInit);
      setPriceStatements(priceStatementsInit);
      setMultiLineStatements(multiLineStatementsInit);
      setDisclosureStatements(disclosureStatementInit);
    }
  }, [data]);

  const CategoryTitle = ({ title }) => (
    <Row>
      <Col style={{ textAlign: "center", margin: "1em 0" }}>{title}</Col>
    </Row>
  );
  return (
    <>
      {proposalOpening && (
        <LongTextInput detail={proposalOpening} permission={permission} />
      )}
      <EmptyBlock />
      <CategoryTitle title={CATEGORIES[0]} />
      {standardStatements.map((statement) => (
        <LongTextInput
          key={statement._id}
          detail={statement}
          permission={permission}
        />
      ))}

      <CategoryTitle title={CATEGORIES[1]} />
      {warrantyStatements.map((statement) => (
        <LongTextInput
          key={statement._id}
          detail={statement}
          permission={permission}
        />
      ))}
      {multiLineStatements.map((statement) => (
        <LongMultiTextIput
          key={statement._id}
          detail={statement}
          permission={permission}
        />
      ))}
      <Row style={{ marginTop: "1em" }}>
        <Col style={styles.btnWrapper} />
        <Col>
          <Form.Label>Price:</Form.Label>
        </Col>
        <Col style={styles.btnWrapper} />
      </Row>
      {priceStatements.map((statement, idx) => (
        <LongTextInputWithPrice
          key={statement._id}
          detail={statement}
          permission={permission}
          idx={idx}
          showPercentageStepperModal={showPercentageStepperModal}
          updateFixedStandardStatementsPercentage={
            updateFixedStandardStatementsPercentage
          }
        />
      ))}
      {disclosureStatements.map((statement) => (
        <LongTextInput
          key={statement._id}
          detail={statement}
          permission={permission}
          special={true}
        />
      ))}
    </>
  );
}
