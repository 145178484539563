import React, { useState } from 'react'
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ({ totalItem, prevPage, nextPage, pageNum, maxItem }) {
	const getIcon = name => <FontAwesomeIcon icon={name} />

	return (
		<div style={{ display: 'flex', alignItems: 'baseline' }}>
			<button
				aria-label='Prev'
				className='pagination_btns'
				onClick={() => prevPage()}
				disabled={pageNum === 1}
			>
				{getIcon(faChevronLeft)}
			</button>
			<p className='page_num'>{pageNum}</p>
			<button
				aria-label='Next'
				className='pagination_btns'
				disabled={pageNum * maxItem >= totalItem}
				onClick={() => nextPage()}
			>
				{getIcon(faChevronRight)}
			</button>
		</div>
	)
}
