"use strict";

const { calculateTotal } = require("../lib/utils");
const {
  checkProposalData,
} = require("services/offlineComputing/services/generateProposal");
const {
  default: quoteLaborModel,
} = require("services/offlineComputing/models/quoteLabor");
const { laborSectionAllData } = require("services/offlineComputing/services/quoteLabor");

// const editLaborSection = catchAsync(async (req, res) => {
//   const { dataId, sectionData } = req.body;
//   const { companyId, _id } = req.userData;
//   let schema = Joi.object({
//     dataId: Joi.string().required(),
//     sectionData: Joi.array().required(),
//   });

//   let validation = schema.validate(req.body);
//   if (validation.error) {
//     throw new Error(validation.error.details[0].message);
//   }

//   let data = {
//     sectionData,
//   };

//   data.roofingLaborSubTotal = calculateTotal(sectionData);

//   const resData = await quoteLaborData.updateOne(
//     {
//       _id: dataId,
//       companyId,
//       userId: _id,
//     },
//     data
//   );

//   helpers.resSuccess(res, {
//     ...data,
//     dataId,
//   });
// });

const laborSection = async (props) => {
  const { supplierId, metricId, proposalId, companyId, _id } = props;

  const proposalData = await checkProposalData({
    _id: proposalId,
    requestId: metricId,
  });

  const { excludedBuildings, excludedSections } = proposalData;

  const responseDataDb = await quoteLaborModel.find({
    requestId: metricId,
    supplierId,
    companyId,
    proposalId,
  });
  if (responseDataDb && responseDataDb.length > 0) {
    const { roofingLaborSubTotal, sectionData, _id } = responseDataDb[0];
    return {
      sectionData,
      dataId: _id,
      roofingLaborSubTotal: Number(roofingLaborSubTotal).toFixed(2),
    };
  }
  const responseData = await laborSectionAllData(
    metricId,
    supplierId,
    companyId,
    excludedBuildings,
    excludedSections
  );

  let sectionData = [];

  if (responseData.length > 0) {
    sectionData = responseData;
  }

  let newData = {
    requestId: metricId,
    sectionData: responseData,
    supplierId,
    companyId,
    proposalId,
    userId: _id,
  };
  newData.roofingLaborSubTotal = calculateTotal(responseData);
  newData._id = await quoteLaborModel.save(newData);

  return {
    sectionData,
    dataId: newData._id,
    roofingLaborSubTotal: Number.parseFloat(
      newData.roofingLaborSubTotal
    ).toFixed(2),
  };
};

export {
  laborSection,
  //   editLaborSection,
};
