import React from "react";
import { Button, Form, Row } from "react-bootstrap";
import numeral from "numeral";

export default function DisplayBtnsTab(btns, width = false) {
  return (
    <Row
      style={{
        margin: "1em 0",
        width: width ? `${btns.length * 200}px` : "100%",
        justifyContent: "space-between",
      }}
    >
      {btns.map((data) => (
        <div
          style={{
            margin: "0 1em 1em 0 ",
            maxWidth: "170px",
            minWidth: "150px",
          }}
          key={data.name}
        >
          <Button
            style={{
              margin: "0 0 1em 0",
              width: "100%",
              height: "45px",
              padding: "0",
              fontWeight: "700",
            }}
            onClick={data.onClick}
            disabled={data.disable}
          >
            {data.name}
          </Button>
          {typeof data.total !== "undefined" && (
            <Form.Control
              style={{
                textAlign: "center",
                height: "45px",
                fontWeight: "600",
              }}
              type="text"
              disabled
              value={data.total ? numeral(data.total).format("0,0") : ""}
              placeholder="Total"
            />
          )}
        </div>
      ))}
    </Row>
  );
}
