export const calendarActionTypes = {
    CALENDAR_START: 'CALENDAR_START',
    CALENDAR_SUCCESS: 'CALENDAR_SUCCESS',
    CALENDAR_FAILURE: 'CALENDAR_FAILURE',
    AVAILABLE_PERSON_CLEAR:'AVAILABLE_PERSON_CLEAR',
    AVAILABLE_PERSON_START:'AVAILABLE_PERSON_START',
    AVAILABLE_PERSON_SUCCESS:'AVAILABLE_PERSON_SUCCESS',
    AVAILABLE_PERSON_FAILURE:'AVAILABLE_PERSON_FAILURE',
    ADD_TO_CALENDAR_START: 'ADD_TO_CALENDAR_START',
    ADD_TO_CALENDAR_SUCCESS: 'ADD_TO_CALENDAR_SUCCESS',
    ADD_TO_CALENDAR_FAILURE: 'ADD_TO_CALENDAR_FAILURE',
    FETCH_EVENTS_START: 'FETCH_EVENTS_START',
    FETCH_EVENTS_SUCCESS: 'FETCH_EVENTS_SUCCESS',
    FETCH_EVENTS_FAILURE: 'FETCH_EVENTS_FAILURE',
    DELETE_EVENTS_START: 'DELETE_EVENTS_START',
    DELETE_EVENTS_SUCCESS: 'DELETE_EVENTS_SUCCESS',
    DELETE_EVENTS_FAILURE: 'DELETE_EVENTS_FAILURE',
}