import React, { Component, useState, useEffect } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import RequiredText from "components/required-text/required-text";
import validator from "validator";

import MaskedInput from "react-maskedinput";

import { checkPhone } from "utils/utils";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { styles } from "./styles";

const Btns = ({ children, type, ...rest }) => (
  <button
    className={`addRemoveBtn ${type === "add" ? "add" : "remove"}`}
    {...rest}
  >
    {children}
  </button>
);

export default function CommonPhoneAndEmailFields({
  changePhoneAndEmailDetail,
  neededData,
  formData,
  required = false,
  setIsEditLead,
}) {
  const [showContact, setShowContact] = useState({
    showPhone2: false,
    showPhone3: false,
    showEmail2: false,
    showEmail3: false,
  });

  const { showPhone2, showPhone3, showEmail2, showEmail3 } = showContact;
  const { phoneTypess, emailTypess } = neededData;
  const {
    phoneType,
    pNumber,
    phoneType2,
    pNumber2,
    phoneType3,
    pNumber3,
    emailType,
    email,
    emailType2,
    email2,
    emailType3,
    email3,
  } = formData;

  const displayEmailTypes = () => {
    const filterData = emailTypess?.emaildata?.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return (
      <>
        {filterData?.map((item) => (
          <option key={item?._id} value={item?._id}>
            {item?.roleType}
          </option>
        ))}
      </>
    );
  };

  const displayPhoneTypes = () => {
    const filterData = phoneTypess?.phonedata?.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return (
      <>
        {filterData?.map((item) => (
          <option key={item?._id} value={item?._id}>
            {item?.roleType}
          </option>
        ))}
      </>
    );
  };

  return (
    <Col md="12" sm="12">
      <Row>
        {phoneTypess && (
          <Col md="6" sm="12">
            <Row>
              <Col md="6" sm="12">
                <Form.Group style={styles.formInput}>
                  <Form.Label>Phone Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={phoneType}
                    // isInvalid={errAt === phoneTypeName}
                    onChange={(event) => {
                      changePhoneAndEmailDetail({
                        phoneType: event.target.value,
                      });
                      setIsEditLead(true);
                    }}
                  >
                    <option value="">Choose...</option>
                    {displayPhoneTypes()}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md="6" sm="12">
                <Form.Group style={styles.formInput}>
                  <Form.Label>
                    Phone Number
                    <Btns
                      type="add"
                      disabled={
                        showPhone2 ||
                        showPhone3 ||
                        pNumber === "" ||
                        checkPhone(pNumber)
                      }
                      onClick={() =>
                        setShowContact((prev) => ({
                          ...prev,
                          showPhone2: true,
                        }))
                      }
                    >
                      Add +
                    </Btns>
                  </Form.Label>
                  <InputGroup>
                    <MaskedInput
                      className="form-control"
                      mask="(111) 111-1111"
                      type="text"
                      value={pNumber}
                      disabled={!phoneType}
                      placeholder=""
                      onChange={(event) => {
                        changePhoneAndEmailDetail({
                          pNumber: event.target.value,
                        });
                        setIsEditLead(true);
                      }}
                      required="required"
                      maxLength={10}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            {showPhone2 && (
              <Row>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Phone Type</Form.Label>
                    <Form.Control
                      as="select"
                      value={phoneType2}
                      onChange={(event) => {
                        changePhoneAndEmailDetail({
                          phoneType2: event.target.value,
                        });
                        setIsEditLead(true);
                      }}
                    >
                      <option value="">Choose...</option>
                      {displayPhoneTypes(phoneTypess)}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>
                      Phone Number
                      <Btns
                        type="add"
                        disabled={
                          showPhone3 || pNumber2 === "" || checkPhone(pNumber2)
                        }
                        onClick={() =>
                          setShowContact((prev) => ({
                            ...prev,
                            showPhone3: true,
                          }))
                        }
                      >
                        Add +
                      </Btns>
                      <Btns
                        type="remove"
                        onClick={() => {
                          changePhoneAndEmailDetail({
                            pNumber2: "",
                            phoneType2: "",
                          });
                          setShowContact((prev) => ({
                            ...prev,
                            showPhone2: false,
                          }));
                        }}
                      >
                        Remove -
                      </Btns>
                    </Form.Label>
                    <InputGroup>
                      <MaskedInput
                        className="form-control"
                        mask="(111) 111-1111"
                        type="text"
                        value={pNumber2}
                        disabled={!phoneType2}
                        placeholder=""
                        onChange={(event) => {
                          changePhoneAndEmailDetail({
                            pNumber2: event.target.value,
                          });
                          setIsEditLead(true);
                        }}
                        required="required"
                        maxLength={10}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            )}

            {showPhone3 && (
              <Row>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Phone Type</Form.Label>
                    <Form.Control
                      as="select"
                      value={phoneType3}
                      onChange={(event) => {
                        changePhoneAndEmailDetail({
                          phoneType3: event.target.value,
                        });
                        setIsEditLead(true);
                      }}
                    >
                      <option value="">Choose...</option>
                      {displayPhoneTypes(phoneTypess)}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>
                      Phone Number
                      <Btns
                        type="add"
                        disabled={
                          showPhone2 || pNumber3 === "" || checkPhone(pNumber3)
                        }
                        onClick={() => showAdditionalField(showPhone2Name)}
                      >
                        Add +
                      </Btns>
                      <Btns
                        type="remove"
                        onClick={() => {
                          changePhoneAndEmailDetail({
                            pNumber3: "",
                            phoneType3: "",
                          });
                          setShowContact((prev) => ({
                            ...prev,
                            showPhone3: false,
                          }));
                        }}
                      >
                        Remove -
                      </Btns>
                    </Form.Label>
                    <InputGroup>
                      <MaskedInput
                        className="form-control"
                        mask="(111) 111-1111"
                        type="text"
                        value={pNumber3}
                        disabled={!phoneType3}
                        placeholder=""
                        onChange={(event) => {
                          changePhoneAndEmailDetail({
                            pNumber3: event.target.value,
                          });
                          setIsEditLead(true);
                        }}
                        required="required"
                        maxLength={10}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Col>
        )}

        {emailTypess && (
          <Col md="6" sm="12">
            <Row>
              <Col md="6" sm="12">
                <Form.Group style={styles.formInput}>
                  <Form.Label>
                    Email Type {required && <RequiredText>*</RequiredText>}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={emailType}
                    onChange={(event) => {
                      changePhoneAndEmailDetail({
                        emailType: event.target.value,
                      });
                      setIsEditLead(true);
                    }}
                  >
                    <option value="">Choose...</option>
                    {displayEmailTypes()}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md="6" sm="12">
                <Form.Group style={styles.formInput}>
                  <Form.Label>
                    Email
                    {required && <RequiredText>*</RequiredText>}
                  </Form.Label>

                  <Form.Control
                    type="text"
                    disabled={!emailType}
                    value={email}
                    // isInvalid={errAt === emailName}
                    onChange={(event) => {
                      changePhoneAndEmailDetail({ email: event.target.value });
                      setIsEditLead(true);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            {showEmail2 && (
              <Row>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Email Type</Form.Label>
                    <Form.Control
                      as="select"
                      value={emailType2}
                      onChange={(event) => {
                        changePhoneAndEmailDetail({
                          emailType2: event.target.value,
                        });
                        setIsEditLead(true);
                      }}
                    >
                      <option value="">Choose...</option>
                      {displayEmailTypes(emailTypess)}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>
                      Email
                      <Btns
                        disabled={
                          !email2 || !validator.isEmail(email2) || showEmail3
                        }
                        type="add"
                        onClick={() => showAdditionalField(showEmail3Name)}
                      >
                        Add +
                      </Btns>
                      <Btns
                        type="remove"
                        onClick={() =>
                          removeAdditionalField(
                            showEmail2Name,
                            emailName2,
                            emailTypeName2
                          )
                        }
                      >
                        Remove -
                      </Btns>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      disabled={!emailType2}
                      value={email2}
                      //   isInvalid={errAt === emailName2}
                      onChange={(event) => {
                        changePhoneAndEmailDetail({
                          email2: event.target.value,
                        });
                        setIsEditLead(true);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            {showEmail3 && (
              <Row>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Email Type</Form.Label>
                    <Form.Control
                      as="select"
                      value={emailType3}
                      onChange={(event) => {
                        changePhoneAndEmailDetail({
                          emailType3: event.target.value,
                        });
                        setIsEditLead(true);
                      }}
                    >
                      <option value="">Choose...</option>
                      {displayEmailTypes(emailTypess)}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md="6" sm="12">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>
                      Email
                      <Btns
                        disabled={
                          !email3 || !validator.isEmail(email3) || showEmail2
                        }
                        type="add"
                        onClick={() => showAdditionalField(showEmail2Name)}
                      >
                        Add +
                      </Btns>
                      <Btns
                        type="remove"
                        onClick={() =>
                          removeAdditionalField(
                            showEmail3Name,
                            emailName3,
                            emailTypeName3
                          )
                        }
                      >
                        Remove -
                      </Btns>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      disabled={!emailType3}
                      value={email3}
                      //isInvalid={errAt === emailName3}
                      onChange={(event) => {
                        changePhoneAndEmailDetail({
                          email3: event.target.value,
                        });
                        setIsEditLead(true);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Col>
        )}
      </Row>
    </Col>
  );
}
