import React from "react";
import "../css/pm.scss";
import InitialLoader from "components/InitilalLoader";
import "react-datepicker/dist/react-datepicker.css";

import { DragDropContainer } from "react-drag-drop-container";

import { mapNavURL, roofImgURL } from "lib/constants/const";
import { Card } from "react-bootstrap";

const CalendarEventsList = ({
  loading,
  error,
  handleDrop,
  onDragStart,
  requests,
  searchTerm,
  setLeadReqId,
  onReload,
  unscheduledProject,
  setProjectId,
  projectId,
  setRequestId,
  setIsScheduled,
  setIsCheck,
  setType,
  setProposalId,
  paymentStatus,
}) => {
  if (loading || error) {
    return <InitialLoader error={error} onReload={onReload} />;
  }
  const listData =
    searchTerm.trim() !== ""
      ? unscheduledProject.filter((project) => {
          let {
            type,
            leadRequestData: [
              {
                createdAt,
                propertyList: [
                  {
                    leadAddress: [
                      { companyName, primaryFirstName, primaryLastName },
                    ],
                    propertyAddress: [
                      {
                        propertyAddress1,
                        propertyAddress2,
                        propertyCity,
                        propertyState,
                        propertyZip,
                        propertyType: [{ roleType }],
                      },
                    ],
                  },
                ],
              },
            ],
          } = project;
          return (
            primaryFirstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            primaryLastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            roleType.toLowerCase().includes(searchTerm.toLowerCase()) ||
            type.toLowerCase().includes(searchTerm.toLowerCase()) ||
            propertyAddress1.toLowerCase().includes(searchTerm.toLowerCase()) ||
            propertyAddress2.toLowerCase().includes(searchTerm.toLowerCase()) ||
            propertyCity.toLowerCase().includes(searchTerm.toLowerCase()) ||
            propertyState.toLowerCase().includes(searchTerm.toLowerCase())
          );
        })
      : unscheduledProject;
  if (listData && listData.length === 0) {
    return (
      <div className="empty-message">
        <div>No request found.</div>
      </div>
    );
  }

  return (
    listData &&
    listData.length > 0 &&
    listData
      .filter((list) => list.type === requests)
      .map((request) => {
        let {
          _id,
          type,
          isScheduled,
          proposalId,
          ledRequestId,
          leadRequestData: [
            {
              createdAt,
              propertyList: [
                {
                  leadAddress: [
                    { companyName, primaryFirstName, primaryLastName },
                  ],
                  propertyAddress: [
                    {
                      propertyAddress1,
                      propertyAddress2,
                      propertyCity,
                      propertyState,
                      propertyZip,
                      propertyType: [{ roleType }],
                    },
                  ],
                },
              ],
            },
          ],
          metrics,
        } = request;

        let totalShingles = 0;
        let totalFlats = 0;
        let leaders = 0;
        let gutters = 0;
        let screens = 0;

        metrics
          .filter((metric) => metric.metricType === "roof")
          .forEach((metric) => {
            let { metricData } = metric;

            metricData.forEach((data) => {
              let {
                metaData: { hasFlat, hasShingles },
                totalFlat,
              } = data;
              if (hasFlat && hasShingles === false) {
                totalFlats = totalFlats + totalFlat;
              }
              if (hasShingles && hasFlat === false) {
                totalShingles = totalShingles + totalFlat;
              }
            });
          });

        metrics
          .filter((metric) => metric.metricType === "gutter")
          .forEach((metric) => {
            let { metricData } = metric;

            metricData.forEach((data) => {
              let { guttersTotal, leadersTotal, screensTotal } = data;
              if (guttersTotal !== "") {
                gutters = gutters + guttersTotal;
              }
              if (leadersTotal !== "") {
                leaders = leaders + leadersTotal;
              }
              if (screensTotal !== "") {
                screens = screens + screensTotal;
              }
            });
          });

        let onClickIds = () => {
          setProjectId(request._id);
          setRequestId(request?.leadRequestId);
          setLeadReqId(request?.leadRequestId);
          setIsScheduled(isScheduled);
          setIsCheck(isScheduled);
          setType(type);
          setProposalId(proposalId);
        };

        function capitalizeFirstLetter(str) {
          return str.charAt(0).toUpperCase() + str.slice(1);
        }

        return (
          <DragDropContainer
            fluid="true"
            targetKey="calendar"
            key={request.requestId}
            onDragStart={(e) => onDragStart(e, request)}
            onDrop={(e) => handleDrop(e, request)}
          >
            <Card
              draggable
              onClick={onClickIds}
              style={
                request._id === projectId
                  ? {
                      padding: 10,
                      borderLeftColor: "#0000e6",
                      borderLeftWidth: "7px",
                      borderRightColor: "transparent",
                      borderTopColor: "transparent",
                      borderBottomColor: "transparent",
                      textAlign: "left",
                      backgroundColor: "transparent",
                      marginBottom: "0.5rem",
                    }
                  : {
                      padding: 10,
                      backgroundColor: "transparent",
                      textAlign: "left",
                      marginBottom: "0.5rem"
                    }
              }
              disabled={paymentStatus == "Frozen 1" ? true : false}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <div
                  style={
                    {
                      //  display: "flex",
                      //  flexDirection: "column",
                    }
                  }
                >
                  <p
                    style={{
                      fontSize: "1.2rem",
                      margin: 0,
                      padding: 0,
                      fontWeight: 700,
                    }}
                  >
                    {companyName || "Ajit Inc"}
                  </p>

                  <p
                    style={{
                      fontSize: "1rem",
                      margin: 0,
                      padding: 0,
                      fontWeight: 700,
                    }}
                  >
                    {`${primaryFirstName} ${primaryLastName}`}
                  </p>

                  <div style={{ marginTop: "2%" }}>
                    <a
                      href={`${mapNavURL}${propertyAddress1},
                                ${propertyAddress2 + ","}
                                ${propertyCity + ","}
                                ${propertyState + ","}
                                ${propertyZip} + ','`}
                      className="address"
                      target="_blank"
                      style={{
                        fontWeight: 500,
                      }}
                    >
                      {`${propertyAddress1 + ","}
                        ${propertyAddress2 + ","}
                        ${propertyCity + ","}
                        ${propertyState + ","}
                        ${propertyZip + ","}`}
                    </a>
                  </div>
                  <div
                    className="text-nowrap"
                    style={{ color: "black", marginTop: "2%" }}
                  >
                    {roleType} {capitalizeFirstLetter(type)}
                    <br></br>
                    <p className="text-nowrap">
                      {new Date(createdAt).toDateString()}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    alignSelf: "center",
                    width: "150px",
                  }}
                >
                  {metrics[0]?.coverImage.length === 0 ? (
                    <img
                      src={roofImgURL}
                      alt="Image"
                      width="130"
                      height="130"
                      style={{ objectFit: "contain" }}
                    />
                  ) : (
                    <img
                      src={metrics[0]?.coverImage[0]?.imageSrc}
                      alt="Image"
                      width="100"
                      height="110"
                    />
                  )}
                  {type === "roof" ? (
                    <p className="nowrap" style={{ fontSize: "medium" }}>
                      Shingles-{totalShingles}
                      <br></br>
                      Flat-{totalFlats}
                    </p>
                  ) : (
                    <p className="nowrap" style={{ fontSize: "medium" }}>
                      Gutters-{gutters}
                      <br></br>
                      Leaders-{leaders}
                      <br></br>
                      Screens-{screens}
                    </p>
                  )}
                </div>
              </div>
            </Card>
          </DragDropContainer>
        );
      })
  );
};

export default CalendarEventsList;
