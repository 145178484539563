import React from "react";
import "./tabs.scss";

export default function RepairsTabs(props) {
  const { active, count, tabs, setTab } = props;

  const displayTabs = (active) => {
    return tabs?.map((tab, i) => (
      <button
        key={`${tab.repairStageId}`}
        onClick={() => setTab(tab.repairStageId, i)}
        className={`_tabItem ${active === tab.repairStageId && "active"}`}
      >
        {tab.repairStageText}({count[i]?.count}){/* ({count[i] || 0}){" "} */}
      </button>
    ));
  };
  return (
    <div
      className="_tab"
      style={{
        background: "rgb(245 243 239)",
      }}
    >
      {displayTabs(active)}
    </div>
  );
}
