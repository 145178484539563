import { getEndPoints } from "api/api";
import { ApiEndpoints } from "lib/config/baseSettings";
import { axiosInstance as axios } from "services";
// import {
//   UPDATE_SSCHEDULING_MODAL_GAPTIME_REPAIR,
//   UPDATE_SSCHEDULING_MODAL_GAPTIME_SALES,
//   UPDATE_SSCHEDULING_MODAL_PROJECT,
//   UPDATE_SSCHEDULING_MODAL_REPAIR,
//   UPDATE_SSCHEDULING_MODAL_SALES,
// } from "utils/utils";

const url = getEndPoints(process.env.REACT_APP_MODE);
const {
  UPDATE_SSCHEDULING_MODAL_GAPTIME_REPAIR,
  UPDATE_SSCHEDULING_MODAL_GAPTIME_SALES,
  UPDATE_SSCHEDULING_MODAL_PROJECT,
  UPDATE_SSCHEDULING_MODAL_REPAIR,
  UPDATE_SSCHEDULING_MODAL_SALES,
} = ApiEndpoints.SCHEDULE_MODAL;

/**
 * change scheduling modal data for project management
 */
export const changeSchedulingTimeProjectManagement = (
  companyId,
  additionalProjectmanagementTime,
  setShowProjectSave,
  setIsLoading
) => {
  setIsLoading(true);

  setShowProjectSave(false);
  const body = {
    project_management_time: additionalProjectmanagementTime,
  };
  axios
    .patch(`${url}${UPDATE_SSCHEDULING_MODAL_PROJECT}${companyId}`, body)
    .then((response) => {
      setIsLoading(false);

      return { error: null, response };
    })
    .catch((error) => {
      setIsLoading(false);

      if (error.response) {
        return { error: error.response };
      }
    });
};
/**
 * change scheduling modal data for sales
 */
export const changeSchedulingTimeSales = (
  companyId,
  additionalSalesTime,
  setShowSalesSave,
  setIsLoading
) => {
  setIsLoading(true);

  setShowSalesSave(false);
  const body = {
    sales_time: additionalSalesTime,
  };
  axios
    .patch(`${url}${UPDATE_SSCHEDULING_MODAL_SALES}${companyId}`, body)
    .then((response) => {
      setIsLoading(false);

      return { error: null, response };
    })
    .catch((error) => {
      setIsLoading(false);

      if (error.response) {
        return { error: error.response };
      }
    });
};
/**
 * change scheduling modal data for repair
 */
export const changeSchedulingTimeRepair = (
  companyId,
  additionalRepairTime,
  setShowRepairSave,
  setIsLoading
) => {
  setShowRepairSave(false);
  setIsLoading(true);
  const body = {
    repair_time: additionalRepairTime,
  };
  axios
    .patch(`${url}${UPDATE_SSCHEDULING_MODAL_REPAIR}${companyId}`, body)
    .then((response) => {
      setIsLoading(false);

      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        setIsLoading(false);

        return { error: error.response };
      }
    });
};
/**
 * change scheduling modal data for sales gap
 */
export const changeSchedulingTimeSalesGap = (
  companyId,
  additionalSalesGapTime,
  setShowSalesGapSave,
  setIsLoading
) => {
  setIsLoading(true);

  setShowSalesGapSave(false);
  const body = {
    sales_gap_time: additionalSalesGapTime,
  };
  axios
    .patch(`${url}${UPDATE_SSCHEDULING_MODAL_GAPTIME_SALES}${companyId}`, body)
    .then((response) => {
      setIsLoading(false);

      return { error: null, response };
    })
    .catch((error) => {
      setIsLoading(false);

      if (error.response) {
        return { error: error.response };
      }
    });
};
/**
 * change scheduling modal data for repair gap
 */
export const changeSchedulingTimeRepairGap = (
  companyId,
  additionalRepairGapTime,
  setShowRepairGapSave,
  setIsLoading
) => {
  setShowRepairGapSave(false);
  setIsLoading(true);
  const body = {
    repair_gap_time: additionalRepairGapTime,
  };
  axios
    .patch(`${url}${UPDATE_SSCHEDULING_MODAL_GAPTIME_REPAIR}${companyId}`, body)
    .then((response) => {
      setIsLoading(false);

      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        setIsLoading(false);

        return { error: error.response };
      }
    });
};
