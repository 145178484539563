import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

export default function PublicRoutes(props) {
  const { component: Component, ...rest } = props;
  const { user } = useSelector((state) => state.login);
  const location = useLocation();

  const prevRoute = location.state ? location.state.referrer : undefined;
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          // <Redirect to={{ pathname: prevRoute ?? "dashboard" }} />
          <Redirect to={{ pathname: "dashboard/production" }} />
        ) : (
          <Component {...rest} {...props} />
        )
      }
    />
  );
}
