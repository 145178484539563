import { calculateTotal } from "services/offlineComputing/lib/utils";
import quoteGuttersModel from "services/offlineComputing/models/quoteGutters";
import { checkProposalData } from "services/offlineComputing/services/generateProposal";
import { guttersSectionAllData } from "services/offlineComputing/services/quoteGutters";

export const guttersSection = async (props) => {
  const { supplierId, metricId, proposalId, companyId, _id } = props;

  const proposalData = await checkProposalData({
    _id: proposalId,
    requestId: metricId,
  });
  const { excludedBuildings, excludedSections } = proposalData;
  const responseDataDb = await quoteGuttersModel.find({
    requestId: metricId,
    supplierId,
    companyId,
    proposalId,
  });

  if (responseDataDb && responseDataDb.length > 0) {
    const { guttersSubTotal, sectionData, _id } = responseDataDb[0];
    return {
      sectionData,
      dataId: _id,
      guttersSubTotal: Number(guttersSubTotal).toFixed(2),
    };
  }
  const responseData = await guttersSectionAllData(
    metricId,
    supplierId,
    companyId,
    excludedBuildings,
    excludedSections
  );

  let sectionData = [];

  if (responseData && responseData.length > 0) {
    sectionData = responseData;
  }

  const newData = {
    requestId: metricId,
    sectionData,
    supplierId,
    companyId,
    proposalId,
    userId: _id,
  };
  newData.guttersSubTotal = calculateTotal(responseData);
  newData._id = await quoteGuttersModel.save(newData);

  return {
    sectionData: newData.sectionData,
    dataId: newData._id,
    guttersSubTotal: Number(newData.guttersSubTotal).toFixed(2),
  };
};

export const editGuttersSection = async (props) => {
  const { dataId, sectionData, companyId, _id } = props;

  let data = {
    sectionData,
  };

  data.guttersSubTotal = calculateTotal(sectionData);

  const resData = await quoteGuttersModel.updateOne(
    {
      _id: dataId,
      companyId,
    },
    data
  );
  return {
    ...data,
    dataId,
  };
};
