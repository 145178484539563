import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import "../../components/common.scss";
const newStyle = {
  formInput: {
    marginBottom: "15px",
  },
  pricingCol: {
    display: "flex",
    justifyContent: "left",
  },
};

const StateTax = ({ currency, ...props }) => (
  <>
    <label className="monthly_sub_label">State Tax</label>
    <Row>
      <Col style={newStyle.pricingCol}>
        <Form.Group style={newStyle.formInput}>
          <Form.Control style={{ textAlign: "center" }} {...props} />
        </Form.Group>
      </Col>
    </Row>
  </>
);

export default StateTax;
