import React, { Component } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";

import { connect } from "react-redux";
import {
  getGuttersData,
  addMetric,
  updateBuilding,
} from "lib/redux/metrics/metrics.actions";
import { uuid } from "uuidv4";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import numeral from "numeral";
import PopupModal from "components/popup-modal/popup-modal";
import Building from "./components/Building";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import Numpad from "./components/Numpad";
import Images from "./Images";

import { DeleteSection, getBuildingName, storeMetricDataFinal } from "./utils";

import {
  G_LEVEL,
  G_ELEVATIONS,
  G_ELEVATION_DETAILS,
  G_SCREENS,
  G_SCREENSSIZE,
  G_SCREENSCOLOR,
  G_SCREENSOTHER,
  G_LEADERHEADSOTHER,
  G_LEADERS,
  G_LEADERSCOLOR,
  G_LEADERSOTHER,
  G_GUTTERS,
  G_CAPPING,
  G_FASCIADATA,
  G_FASCIA,
  G_columns,
  G_TABS,
} from "lib/constants/const";
import GenerateQuote from "./components/GenerateQuoteButton";
import { debounce, getGuttersSectionData } from "utils/utils";
import InitialLoader from "../../components/InitilalLoader";
import MetricHeader, { Header } from "./components/MetricHeader";
import DeleteModal from "components/DeleteModal";
import { removeUnwantedSections } from "./utils";
import DisplayTable from "components/DisplayTable";
import BasicTable from "components/BasicTable";

import "./metric.scss";

class Metric extends Component {
  constructor(props) {
    super(props);

    this.state = {
      section: 1,
      building: "",
      address: "",
      elevation: [],
      elevation_details: "",
      showCoopModal: false,
      coopUnits: [],
      newBuildingName: "",
      tempNewBuildingName: "",
      elevation_other: [],
      edOther: "",
      tableData: [],
      selectDimen: "",

      editDimen: "",
      viewTally: false,
      notes: "",
      selectData: "",

      label: "",

      page_num: 1,
      max_item: 5,
      linearfeet: "",
      faceSize: "",

      length: "",
      height: "",
      width: "",
      material: "",
      inches: "",
      shape: "",
      chimneycaps: "",
      dataKeys: [],
      activeWood: 0,
      otherColor: "",

      showDeleteModal: false,
      deleteData: null,

      level: "",
      imagesList: [],
      showEntryModal: false,
      showEntry: false,
      guttersData: [],
      guttersSelection: {},
      guttersTotal: "",
      gutters: "",
      style: "",
      type: "",
      size: "",
      color: "",
      leaders: "",
      leadersTotal: "",
      leadersData: [],
      leadersSelection: {},
      screens: "",
      screensData: [],
      screensTotal: "",
      screensSelection: {},
      leaderheads: "",
      leaderheadsTotal: "",
      leaderheadsData: [],
      leaderheadsSelection: {},
      fasciaTotal: "",
      fascia: "",
      fasciaData: [],
      fasciaSelection: {},
      capping: "",
      cappingData: [],
      cappingTotal: "",
      cappingSelection: {},
      activateSave: false,
      sectionData: [],
      editSection: false,
      msg: "",
      msgType: "danger",
      loadingReq: false,
      editBuilding: "",
      deleteBuilding: "",
      sectionI: "",
      buildingErr: "",
      images: false,
      coverImage: [],
      currentSection: "",
      sectionId: uuid(),
      isDataChanged: false,
      isQuoteLock: false,
      isLocked: false,
    };
    this.saveDataIntoDb = debounce(this.saveDataIntoDb, 2000);
  }

  disableButton = () => {
    const {
      elevation_details,
      guttersData,
      leadersData,
      screensData,
      leaderheadsData,
      fasciaData,
      cappingData,
      guttersTotal,
      leadersTotal,
      screensTotal,
      fasciaTotal,
      cappingTotal,
      leaderheadsTotal,
      elevation,
    } = this.state;

    if (
      elevation.length > 0 &&
      elevation_details &&
      (guttersData.length > 0 ||
        leadersData.length > 0 ||
        screensData.length > 0 ||
        leaderheadsData.length > 0 ||
        fasciaData.length > 0 ||
        cappingData.length > 0) &&
      (guttersTotal > 0 ||
        leadersTotal > 0 ||
        screensTotal > 0 ||
        leaderheadsTotal > 0 ||
        fasciaTotal > 0 ||
        cappingTotal > 0)
    ) {
      return false;
    }

    return true;
  };

  clearMsg = () => {
    setTimeout(() => {
      this.setState({
        msg: "",
        msgType: "danger",
      });
    }, 5000);
  };

  addMsg = (msg, msgType) => {
    this.setState({
      msg,
      msgType,
    });
    this.clearMsg();
  };

  loadData = () => {
    const {
      getGuttersData,
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      getGuttersData(id);
    }
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      level,
      elevation,
      elevation_details,
      difficulty,
      work_type,

      selectData,
      selectDimen,
      type,
      style,
    } = this.state;

    const { data, metricErrors, buildingErrors } = this.props;
    const { building } = this.state;

    if (metricErrors !== prevProps.metricErrors) {
      if (metricErrors) {
        this.addMsg(metricErrors, "danger");
        this.setState({ loadingReq: false });
      }
    }

    if (buildingErrors !== prevProps.buildingErrors) {
      if (buildingErrors) {
        this.setState({
          buildingErr: buildingErrors,
        });
        this.setState({ loadingReq: false });
        setTimeout(() => {
          this.setState({
            buildingErr: "",
          });
        }, 5000);
      }
    }
    if (!data) return;
    if (
      JSON.stringify(data) !== JSON.stringify(prevProps.data) ||
      this.state.address != data.address
    ) {
      if (data && JSON.stringify(data) !== "{}") {
        let dataList = {};
        let sectionData = [...data.metricData];
        let copyDataToDb = false;

        if (!data.hasGutters) {
          this.props.history.push("/dashboard/activity/sales");
        }

        if (data.buildings && data.buildings.length > 0) {
          sectionData = removeUnwantedSections(sectionData, data.buildings);
          if (sectionData.length !== data.metricData.length) {
            copyDataToDb = true;
          }
          let buildingName = localStorage.getItem("BuildingName");
          if (buildingName === null) {
            this.setState(
              {
                coopUnits: data.buildings,
              },
              () => {
                this.selectBuilding(data.buildings[0].bid);
              }
            );
          } else {
            this.setState(
              {
                coopUnits: data.buildings,
              },
              () => {
                this.selectBuilding(buildingName);
              }
            );
          }
        } else {
          dataList = { ...dataList, tableData: data.metricData };
        }
        this.setState(
          () => ({
            address: data.address,
            ...dataList,
            sectionData,
            loadingReq: false,
            coverImage: data.coverImage ? data.coverImage : [],
            isLocked: data.isLocked,
          }),
          () => {
            this.setState({ section: this.getNewSectionNumber(0) });
            if (copyDataToDb) {
              this.saveMetricData(sectionData);
            }
          }
        );
      }
    }

    if (type !== prevState.type || style !== prevState.style) {
      if ((type && prevState.type) || (style && prevState.style)) {
        this.setState({ color: "", otherColor: "", size: "" });
      }
    }
    if (selectData && prevState.selectData) {
      if (this.state[selectData].length !== prevState[selectData].length) {
        this.setState({ activateSave: true });
      }
    }
    if (
      JSON.stringify(this.state[`${selectDimen}Selection`]) !==
      JSON.stringify(prevState[`${selectDimen}Selection`])
    ) {
      this.setState({ activateSave: true });
    }

    this.clearData(level, prevState.level, "elevation");
    this.clearData(elevation, prevState.elevation, "elevation_details");
    this.clearData(
      elevation_details,
      prevState.elevation_details,
      "difficulty"
    );
    this.clearData(difficulty, prevState.difficulty, "work_type");
    this.clearData(work_type, prevState.work_type, "install_type");
  }

  getAllData = () => {
    const {
      section,
      elevation,
      elevation_details,
      elevation_other,
      edOther,

      notes,

      level,

      guttersData,
      guttersSelection,
      guttersTotal,
      building,
      leadersTotal,
      leadersData,
      leadersSelection,
      screensData,
      screensTotal,
      screensSelection,
      leaderheadsTotal,
      leaderheadsData,
      leaderheadsSelection,
      fasciaTotal,
      fasciaData,
      fasciaSelection,
      cappingData,
      cappingTotal,
      cappingSelection,
      sectionId,
      imagesList,
    } = this.state;

    return {
      section,
      elevation,
      elevation_details,
      elevation_other,
      edOther,
      building,
      notes,

      level,

      guttersData,
      guttersSelection,
      guttersTotal,

      leadersTotal,
      leadersData,
      leadersSelection,
      screensData,
      screensTotal,
      screensSelection,
      leaderheadsTotal,
      leaderheadsData,
      leaderheadsSelection,
      fasciaTotal,
      fasciaData,
      fasciaSelection,
      cappingData,
      cappingTotal,
      cappingSelection,
      sectionId,
      imagesList:
        imagesList.length > 0
          ? imagesList
          : [undefined, undefined, undefined, undefined, undefined],
    };
  };
  selectBuilding = (unit, empty = false) => {
    if (empty) {
      return;
    }
    if (unit) {
      localStorage.setItem("BuildingName", unit);

      this.setState({ building: unit }, () => {
        this.getBuildingSectionData();
      });
    } else {
      this.getBuildingSectionData();
    }
  };

  getBuildingSectionData = () => {
    const { sectionData, building } = this.state;
    const tableData = sectionData.filter((data) => data.building === building);
    this.setState({ tableData });
  };

  getBuildingName = (id) => {
    const match = this.state.coopUnits.find((unit) => unit.bid === id);
    if (match) {
      return match.bname;
    }
    return;
  };

  clearAllSectionData = () =>
    this.setState({
      section: this.state.sectionData.length + 1,
      elevation: [],
      elevation_details: "",
      showCoopModal: false,
      newBuildingName: "",
      tempNewBuildingName: "",
      elevation_other: [],
      edOther: "",

      selectDimen: "",

      editDimen: "",
      viewTally: false,
      notes: "",
      selectData: "",

      label: "",

      page_num: 1,
      max_item: 5,
      linearfeet: "",
      faceSize: "",

      length: "",
      height: "",
      width: "",
      material: "",
      inches: "",
      shape: "",
      chimneycaps: "",
      dataKeys: [],
      activeWood: 0,
      otherColor: "",

      showDeleteModal: false,
      deleteData: null,

      level: "",

      showEntryModal: false,
      showEntry: false,
      guttersData: [],
      guttersSelection: {},
      guttersTotal: "",
      gutters: "",
      style: "",
      type: "",
      size: "",
      color: "",
      leaders: "",
      leadersTotal: "",
      leadersData: [],
      leadersSelection: {},
      screens: "",
      screensData: [],
      screensTotal: "",
      screensSelection: {},
      leaderheads: "",
      leaderheadsTotal: "",
      leaderheadsData: [],
      leaderheadsSelection: {},
      fasciaTotal: "",
      fascia: "",
      fasciaData: [],
      fasciaSelection: {},
      capping: "",
      cappingData: [],
      cappingTotal: "",
      cappingSelection: {},
      activateSave: false,
      editSection: false,
      sectionI: "",
      imagesList: [],
      currentSection: "",
      sectionId: "",
    });

  onSuccess = (sectionData, del, empty) => {
    this.setState((state) => ({
      loadingReq: false,
      editSection: true,
      sectionI: state.section,
      currentSection: state.sectionId,
      sectionData: [...sectionData],
      tableData: empty ? [] : state.tableData,
      isDataChanged: true,
    }));
    if (del) {
      this.addAnotherSection();
    }
    this.hideDeleteModal();
    this.selectBuilding(this.state.building, empty);
  };

  saveMetricData = (sectionData, empty = false, del = false) => {
    const { coverImage, editSection } = this.state;
    const reqId = this.props.match.params.id || this.props.matricId;

    if (editSection && this.disableButton()) return;

    storeMetricDataFinal(
      this.props.addMetric,
      "gutter",
      reqId,
      coverImage,
      sectionData,
      () => this.onSuccess(sectionData, del, empty)
    );
  };

  addSection = () => {
    const data = this.getAllData();

    const { sectionData, currentSection, editSection } = this.state;
    if (currentSection && editSection) {
      const { imagesList, ...data } = this.getAllData();
      let temp = sectionData;
      const Idx = sectionData.findIndex(
        (data) => data.sectionId === currentSection
      );
      temp.splice(Idx, 1, { ...temp[Idx], ...data });
      this.setState(
        () => ({
          loadingReq: true,
        }),
        () => {
          this.saveMetricData(temp);
        }
      );
    } else {
      this.setState(
        (state) => ({
          loadingReq: true,
        }),
        () => {
          this.saveMetricData([...this.state.sectionData, { ...data }]);
        }
      );
    }
  };
  formatNumber = (num) => numeral(num).format("0,0");

  editSectionData = (data, sectionId, idx) => {
    const { isLocked, isQuoteLock } = this.state;
    this.setState({
      ...data,
      editSection: true,
      sectionI: idx - 1,
      section: idx,
      showDeleteModal: false,
      showEntryModal: false,
      showCoopModal: false,
      currentSection: sectionId,
    });
    if (isLocked) {
      this.setState({
        isQuoteLock: true,
      });
    } else {
      this.setState({
        isQuoteLock: false,
      });
    }
  };

  deleteAllSectionData = (sectionId) => {
    const { sectionData } = this.state;
    let temp = sectionData;
    const Idx = sectionData.findIndex((data) => data.sectionId === sectionId);
    temp.splice(Idx, 1);
    this.setState(
      () => ({
        loadingReq: true,
      }),
      () => {
        this.saveMetricData(temp, false, true);
      }
    );
  };

  deleteAllSectionDataByBuilding = (id) => {
    const { sectionData, deleteBuilding, building } = this.state;

    const newData = sectionData.filter(
      (data) => data.building !== deleteBuilding
    );

    this.setState(
      () => ({
        loadingReq: true,
        deleteBuilding: "",
      }),
      () => {
        if (newData.length !== sectionData.length) {
          this.saveMetricData(newData, id === building);
        } else {
          this.setState({ loadingReq: false });
        }
      }
    );
  };

  editAllSectionData = () => {
    const { sectionData, sectionI } = this.state;
    const data = this.getAllData();
    let temp = sectionData;
    const Idx = sectionData.findIndex((data) => data.section === sectionI);
    temp.splice(Idx, 1, { ...data });

    this.setState(
      () => ({
        loadingReq: true,
      }),
      () => {
        this.saveMetricData(temp);
      }
    );
  };

  getTableData = () => {
    let tableData = [];
    this.state.tableData.map((data, i) =>
      tableData.push({
        edit: (
          <Button
            onClick={() => this.editSectionData(data, data.sectionId, i + 1)}
            disabled={
              this.state.editSection &&
              this.state.currentSection === data.sectionId
            }
          >
            {this.getIcon(faSearch)} View
          </Button>
        ),
        ...getGuttersSectionData(data, i),
        delete: (
          <Button
            variant="danger"
            className="tblDel"
            onClick={() => this.onDeleteSection(data.sectionId)}
            disabled={
              !(
                this.state.currentSection === data.sectionId &&
                this.state.editSection
              )
            }
          >
            Delete
          </Button>
        ),
      })
    );
    return tableData;
  };

  onDeleteSection = (sectionId) => {
    this.setState({
      dltHead: DeleteSection.heading,
      dltSubHead: DeleteSection.subHeading,
    });
    this.setOnDelete(() => {
      this.deleteAllSectionData(sectionId);
    }, false);
  };

  getElevationLetters = (array, data) => {
    let match = "";
    array.map((elem) =>
      data.includes(elem.id)
        ? (match = `${match ? `${match} ` : ""}${elem.name[0].toUpperCase()}`)
        : match
    );
    return match;
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  clearData = (data, prevData, changeData) => {
    if (data !== prevData) {
      if (data === "") {
        this.setState({ [changeData]: "" });
      }
    }
  };

  addAnotherSection = () => {
    this.clearAllSectionData();
    this.setState((state) => ({
      section: this.getNewSectionNumber(0),
      editSection: false,
      currentSection: "",
      sectionI: "",
      loadingReq: false,
      sectionId: uuid(),
      isQuoteLock: false,
    }));
  };

  getNewSectionNumber = (add) => {
    const { sectionData } = this.state;

    const num = sectionData.length + 1 + add;
    const match = sectionData.some((data) => data.section === num);
    return match ? this.getNewSectionNumber(add + 1) : num;
  };

  saveDataIntoDb = () => {
    if (this.state.editSection) {
      this.addSection();
    }
  };

  getIcon = (name) => <FontAwesomeIcon icon={name} />;

  addFloor = (name, id) =>
    this.setState(
      (state) => ({ [name]: state[name] === id ? "" : id }),
      () => {
        if (
          (name.toLowerCase() === "level" ||
            name.toLowerCase() === "elevation_details") &&
          this.state.editSection
        ) {
          this.addSection();
        }
      }
    );

  selectMultipleMetric = (
    label,
    data,
    array,
    arrName,
    disableBy,
    isQuoteLock = false
  ) => {
    return data.map((item) => (
      <button
        disabled={disableBy}
        key={item.id}
        style={
          isQuoteLock && array?.includes(item.id)
            ? {
                backgroundColor: "#60abfb",
                borderColor: "#60abfb",
              }
            : {}
        }
        className={`ClickableBtn ${
          array?.includes(item.id) ? "selected" : ""
        } `}
        onClick={() => this.addAllData(arrName, item.id, true)}
      >
        {item.name}
      </button>
    ));
  };

  metricButton = (
    label,
    array,
    data,
    prevMetric,
    icon,
    width,
    styles,
    isQuoteLock = false
  ) => {
    return data.map((item) => (
      <button
        disabled={typeof prevMetric !== "undefined" ? !prevMetric : false}
        key={item.id}
        // style={typeof width !== "undefined" ? { width } : {}}
        style={
          icon && array === item.id
            ? typeof width !== "undefined"
              ? {
                  backgroundColor: "#60abfb",
                  borderColor: "#60abfb",
                  width,
                }
              : {
                  maxWidth: "20%",
                  backgroundColor: "#60abfb",
                  borderColor: "#60abfb",
                }
            : typeof width !== "undefined"
            ? { width }
            : { maxWidth: "20%" }
        }
        className={`ClickableBtn ${array === item.id ? "selected" : ""} `}
        onClick={() =>
          this.addFloor(label.replace(" ", "_").toLowerCase(), item.id)
        }
      >
        {item.name} {icon ? icon : ""}
      </button>
    ));
  };

  displayMetric = (
    label,
    array,
    data,
    prevMetric,
    icon,
    width,
    styles,
    showLabel = true,
    optional = false,
    displayLabel,
    isQuoteLock = false,
    type
  ) => {
    return (
      <>
        {showLabel && (
          <label
            style={
              typeof styles !== "undefined"
                ? { width: "100%", textAlign: "center" }
                : { marginBottom: "0.3em" }
            }
            className={"btnheading" + (type === 1 ? " gutterheading" : "")}
          >
            {typeof displayLabel !== "undefined" ? displayLabel : label}
            {optional && "(Optional)"}
          </label>
        )}
        <Row
          className="guttertype"
          style={
            typeof styles !== "undefined"
              ? {
                  justifyContent: "space-around",
                  display: "flex",
                  flexWrap: "nowrap",
                  alignContent: "flex-end",
                }
              : {
                  justifyContent: "flex-start",
                  display: "flex",
                  flexWrap: "nowrap",
                  alignContent: "flex-end",
                }
          }
        >
          {data.map((item) => (
            <button
              disabled={typeof prevMetric !== "undefined" ? !prevMetric : false}
              key={item.id}
              // style={
              //   typeof width !== "undefined"
              //     ? {
              //         width,
              //         // marginRight: "2em"
              //       }
              //     : { maxWidth: "20%" }
              // }
              style={
                icon && array === item.id
                  ? typeof width !== "undefined"
                    ? {
                        backgroundColor: "#60abfb",
                        borderColor: "#60abfb",
                        width,
                      }
                    : {
                        maxWidth: "20%",
                        backgroundColor: "#60abfb",
                        borderColor: "#60abfb",
                      }
                  : typeof width !== "undefined"
                  ? { width }
                  : { maxWidth: "20%" }
              }
              className={`ClickableBtn ${array === item.id ? "selected" : ""} `}
              onClick={() =>
                this.addFloor(label.replace(" ", "_").toLowerCase(), item.id)
              }
            >
              {item.name} {icon ? icon : ""}
            </button>
          ))}
        </Row>
      </>
    );
  };
  onCoopModalHide = () => this.setState({ showCoopModal: false });

  addBuldings = (value) => {
    this.setState({ loadingReq: true }, () => {
      this.saveBuilding(
        [
          ...this.state.coopUnits,
          {
            bid: uuid(),
            bname: this.state.newBuildingName,
          },
        ],
        value
      );
    });
  };

  saveBuilding = (coopUnits, remove) => {
    const {
      match: {
        params: { id },
      },
      updateBuilding,
    } = this.props;
    updateBuilding({
      requestId: id,
      propertyBuildings: coopUnits,
    })
      .then((res) => res.payload)
      .then((data) => {
        if (data.success) {
          this.setState(
            (state) => ({
              loadingReq: false,
              showCoopModal: true,
              coopUnits: coopUnits,
              newBuildingName: "",
              building: remove === state.building ? "" : state.building,
              editBuilding: "",
            }),
            () => {
              if (this.state.deleteBuilding) {
                this.deleteAllSectionDataByBuilding(remove);
              }
            }
          );
        }
      });
  };

  deleteBuilding = (id) => {
    this.setState({ loadingReq: true, showCoopModal: true }, () => {
      let building = this.state.coopUnits;
      const idx = building.findIndex((unit) => unit.bid === id);
      building.splice(idx, 1);
      this.saveBuilding(building, id);
    });
  };
  onEditBuilding = (unit, value = true) => {
    return this.setState((state) => {
      if (value) {
        return {
          editBuilding: unit,
          tempNewBuildingName: getBuildingName(this.state.coopUnits, unit),
        };
      } else {
        return {
          editBuilding: "",
        };
      }
    });
  };

  saveEditBuilding = (data) => {
    let building = this.state.coopUnits;
    building.splice(building.indexOf(data), 1, {
      bid: data.bid,
      bname: this.state.tempNewBuildingName,
    });
    this.saveBuilding(building);
  };

  onDeleteBuilding = (id, value = true) => {
    return this.setState(() => {
      if (value) {
        return {
          deleteBuilding: id,
          showCoopModal: false,
        };
      } else {
        return {
          deleteBuilding: "",
          showCoopModal: true,
        };
      }
    });
  };

  hideModal = () => this.setState({ otherModal: false });

  addAllData = (name, value, addIntoDb = false) => {
    let data = this.state[name];
    this.setState(
      {
        [name]: data.includes(value)
          ? this.removeFromAllData(name, value)
          : [...data, value],
      },
      () => {
        if (addIntoDb) {
          if (this.state.editSection) {
            this.addSection();
          }
        }
      }
    );
  };

  removeFromAllData = (name, value) => {
    let temp = this.state[name];
    temp.splice(temp.indexOf(value), 1);
    return temp;
  };

  displayModal = (show, label, hide, data, array, arrName, other) => {
    return (
      <PopupModal
        styles={{ padding: "1em 1em 0 1em", border: "none" }}
        show={show}
        heading={label}
        onHide={() => hide()}
        bold
        dialogClassName="modal-90w"
        size="lg"
      >
        <Row style={{ margin: "0 1em 1em 1em", alignItems: "center" }}>
          {data.map((item) => (
            <button
              key={item.id}
              className={`ClickableBtnModal ${
                array.includes(item.id) ? "selected" : ""
              } `}
              onClick={() => this.addAllData(arrName, item.id)}
              style={{ maxWidth: "12%", marginBottom: "8px", padding: "0" }}
            >
              {item.name}
            </button>
          ))}
          {this.checkOtherArrayValue(data, array) && (
            <div style={{ width: "150px", margin: "1em" }}>
              <Form.Control
                type="text"
                name={other.name}
                value={other.value}
                onChange={this.handleChange}
              />
            </div>
          )}
        </Row>

        <Modal.Footer
          style={{
            justifyContent: "flex-end",
            border: "none",
            padding: ".75rem",
          }}
        >
          <Button
            className="modified_btn"
            variant="danger"
            onClick={() => hide()}
          >
            Cancel
          </Button>
          <Button
            className="modified_btn"
            onClick={() => {
              hide();
              if (this.state.editSection) {
                this.addSection();
              }
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </PopupModal>
    );
  };

  addIntoDimen = (name, value) => {
    if (typeof value === "undefined") {
      return this.setState((state) => ({ [name]: "" }));
    }
    return this.setState((state) => ({
      [name]: state[name] ? Number(`${state[name]}${value}`) : value,
    }));
  };

  getTotalFromData = () => {
    const { selectData } = this.state;
    return selectData !== ""
      ? this.state[selectData].reduce((acc, data) => (acc += data), 0)
      : 0;
  };

  toggleWoodEntry = () =>
    this.setState((state) => ({
      showWoodEntry: !state.showWoodEntry,
      showMiscEntry: !state.showMiscEntry,
      label: `${
        !state.showWoodEntry
          ? state.viewTally
            ? `${state.activeLabel} Tally`
            : `Enter ${state.activeLabel}`
          : `Enter ${state.activeLabel} - ${state.dataKeys
              .slice(1)
              .toString()
              .replace(",", " & ")
              .replace(",", " & ")}`
      }`,
    }));

  checkOtherValue = (array, data) => {
    if (data) {
      return array.some((key) => key.id === data && key.other);
    }

    return false;
  };

  checkOtherArrayValue = (array, data) => {
    if (data.length > 0) {
      return array.some((key) => data.includes(key.id) && key.other);
    }

    return false;
  };

  getTabTopData = (section, selectData, total) => (
    <Row>
      <Col>
        <div className="_lbl">Section</div>
        <p className="_txt">{section}</p>
      </Col>

      <Col>
        <div className="_lbl">
          Total{" "}
          {this.state.totalLabel !== "" ? this.state.totalLabel : "Linear Feet"}
        </div>
        <p className="_txt">{total}</p>
      </Col>
      <Col>
        <div className="_lbl">Entries</div>
        <p className="_txt">
          {selectData !== "" ? this.state[selectData].length : 0}
        </p>
      </Col>
    </Row>
  );

  showPagination = () => (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      <button
        aria-label="Prev"
        className="pagination_btns"
        onClick={this.prevPage}
        disabled={this.state.page_num === 1}
      >
        {this.getIcon(faChevronLeft)}
      </button>
      <p className="page_num">{this.state.page_num}</p>
      <button
        aria-label="Next"
        className="pagination_btns"
        disabled={
          this.state.page_num * this.state.max_item >=
          this.state[this.state.selectData].length
        }
        onClick={this.nextPage}
      >
        {this.getIcon(faChevronRight)}
      </button>
    </div>
  );

  prevPage = () => this.setState((state) => ({ page_num: state.page_num - 1 }));

  nextPage = () => this.setState((state) => ({ page_num: state.page_num + 1 }));

  toggleKeypad = () =>
    this.setState((state) => ({
      viewTally: !state.viewTally,
      label: `${
        !state.viewTally
          ? `${state.activeLabel} Tally`
          : `Enter ${state.activeLabel}`
      }`,
    }));

  getDataLabel = (data, id) => {
    if (data === undefined) {
      return id;
    }
    const match = data.find((item) => item.id === id);
    if (match) {
      return match.name;
    }
    return "";
  };

  displayTabModal = (label, data, name, totalLabel) => {
    this.setState({
      showTabModal: true,
    });
    this.setModalOtherState(label, data, name, totalLabel);
  };

  displayWoodModal = (label, data, name, totalLabel, dataKeys, index) => {
    this.setState({
      showWoodModal: true,
      dataKeys,
      activeWood: index,
    });
    this.setModalOtherState(label, data, name, totalLabel);
  };

  displayMiscModal = (label, data, name, totalLabel, dataKeys, index) => {
    this.setState({
      showMiscModal: true,
      dataKeys,
      activeWood: index,
    });
    this.setModalOtherState(label, data, name, totalLabel);
  };

  setModalOtherState = (label, data, name, totalLabel) => {
    let totalLbl = typeof totalLabel === "undefined" ? "" : totalLabel;

    this.setState({
      label: `Enter ${label}`,
      selectData: data,
      selectDimen: name.toLowerCase().replace(" ", "_"),
      activeLabel: label,
      totalLabel: totalLbl.toLowerCase(),
    });
  };

  displayFlashingTabModal = (label, data) =>
    this.setState({
      showFlashingTabModal: true,
      label: `Enter ${label}`,
      selectData: data,
      activeLabel: label,
    });

  displaySkylightModal = (label, data, name, totalLabel) => {
    let totalLbl = typeof totalLabel === "undefined" ? "" : totalLabel;
    this.setState({
      showSkylightModal: true,
      label: `Enter ${label}`,
      selectData: data,
      selectDimen: name.toLowerCase().replace(" ", "_"),
      activeLabel: label,
      totalLabel: totalLbl,
    });
  };

  displayTally = () => {
    this.displaySkylightModal(
      "Skylight Dimensions & Details",
      "skylightData",
      "width",
      "count"
    );
    this.toggleKeypad();
  };

  renderTab = () => {
    return G_TABS.map(({ id, name }) => (
      <Tab key={id} eventKey={id} title={name}>
        {this.renderTabDimenData(id)}
      </Tab>
    ));
  };

  renderTabDimenData = (active) => {
    const {
      guttersTotal,
      leadersTotal,
      leadersSelection,
      guttersSelection,
      screensTotal,
      screensSelection,
      leaderheadsSelection,
      leaderheadsTotal,
      fasciaTotal,
      fasciaSelection,
      cappingTotal,
      cappingSelection,
      isQuoteLock,
    } = this.state;
    switch (active) {
      case G_TABS[0].id:
        return (
          <>
            {this.displayBtnsTab(
              [
                {
                  name: "Gutters",
                  total: guttersTotal,
                  disable: isQuoteLock,
                  onClick: () =>
                    this.displayGuttersModal(
                      "Gutters",
                      "guttersData",
                      "Gutters",
                      "Linear feet",
                      0
                    ),
                  selected: guttersSelection,
                  array: G_GUTTERS,
                },

                {
                  name: "Leaders",
                  total: leadersTotal,
                  disable: isQuoteLock,
                  onClick: () =>
                    this.displayGuttersModal(
                      "Leaders",
                      "leadersData",
                      "leaders",
                      "Linear feet",
                      1
                    ),
                  selected: leadersSelection,
                  array:
                    JSON.stringify(this.state.leadersSelection) !== "{}"
                      ? [
                          [...G_LEADERS[0]],
                          [...G_LEADERS[1]],
                          ...G_LEADERSOTHER[this.state.leadersSelection.type][
                            this.state.leadersSelection.style
                          ],
                          [...G_LEADERSCOLOR[this.state.leadersSelection.type]],
                        ]
                      : [],
                },
                {
                  name: "Screens",
                  total: screensTotal,
                  disable: isQuoteLock,
                  onClick: () =>
                    this.displayGuttersModal(
                      "Screens",
                      "screensData",
                      "Screens",
                      "Linear feet",
                      2
                    ),
                  selected: screensSelection,
                  array:
                    JSON.stringify(this.state.screensSelection) !== "{}"
                      ? [
                          G_SCREENS,
                          G_SCREENSOTHER[this.state.screensSelection.type][0],
                          G_SCREENSOTHER[this.state.screensSelection.type][1],
                        ]
                      : [],
                },
                {
                  name: "Leader Heads",
                  total: leaderheadsTotal,
                  disable: isQuoteLock,
                  onClick: () =>
                    this.displayGuttersModal(
                      "Leader Head",
                      "leaderheadsData",
                      "leaderheads",
                      "Count",
                      3
                    ),
                  selected: leaderheadsSelection,
                  array:
                    JSON.stringify(this.state.leaderheadsSelection) !== "{}"
                      ? [
                          G_SCREENS,
                          G_LEADERHEADSOTHER[
                            this.state.leaderheadsSelection.type
                          ][0],
                        ]
                      : [],
                },
              ],
              true,
              "gutter"
            )}
          </>
        );

      case G_TABS[1].id:
        return (
          <>
            {this.displayBtnsTab(
              [
                {
                  name: "Fascia",
                  total: fasciaTotal,
                  disable: isQuoteLock,
                  onClick: () =>
                    this.displayGuttersModal(
                      "Fascia",
                      "fasciaData",
                      "Fascia",
                      "Linear feet",
                      4
                    ),
                  selected: fasciaSelection,
                  array:
                    JSON.stringify(this.state.fasciaSelection) !== "{}"
                      ? [
                          G_FASCIADATA,
                          G_FASCIA[this.state.fasciaSelection.type][0],
                          G_FASCIA[this.state.fasciaSelection.type][1],
                        ]
                      : [],
                },
                {
                  name: "Capping",
                  total: cappingTotal,
                  disable: isQuoteLock,
                  onClick: () =>
                    this.displayGuttersModal(
                      "Capping",
                      "cappingData",
                      "Capping",
                      "Linear feet",
                      5
                    ),
                  selected: cappingSelection,
                  array:
                    JSON.stringify(this.state.cappingSelection) !== "{}"
                      ? G_CAPPING
                      : [],
                },
              ],
              true,
              "fascia"
            )}
          </>
        );

      default:
        return null;
    }
  };

  displayBtnsTab = (btns, width = false, name) => {
    return (
      <Row
        style={{
          margin: "1em 0",
          width: name === "fascia" ? "50%" : "100%",
          justifyContent: "space-between",
        }}
      >
        {btns.map((data) => {
          const keys = Object.keys(data.selected);
          const hasTotal = !!data.total;
          return (
            <Col
              key={data.name}
              style={{
                margin: "0px 1em 1em 0px",
                maxWidth: "170px",
                minWidth: "150px",
              }}
            >
              <Button
                style={{
                  margin: "0 0 1em 0",
                  width: "100%",
                  fontWeight: "700",
                  height: "45px",
                }}
                onClick={data.onClick}
                disabled={data.disable}
              >
                {data.name}
              </Button>
              <div
                style={
                  name === "fascia" ? { height: "120px" } : { height: "190px" }
                }
              >
                {hasTotal &&
                  this.showSelection(data).map((selData, i) => (
                    <label
                      key={keys[i]}
                      style={{
                        margin: "0 0 1em 0",
                        width: "100%",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {selData}
                    </label>
                  ))}
              </div>
              {typeof data.total !== "undefined" && (
                <Form.Control
                  type="text"
                  disabled={true}
                  value={data.total ? numeral(data.total).format("0,0") : ""}
                  placeholder="Total"
                  style={{ textAlign: "center", fontWeight: "600" }}
                />
              )}
            </Col>
          );
        })}
      </Row>
    );
  };

  showSelection = (data) => {
    let list = [];
    Object.keys(data.selected).map((key, i) => {
      return data.selected[key]
        ? list.push(this.getDataLabel(data.array[i], data.selected[key]))
        : list;
    });
    return list;
  };
  hideDeleteModal = () => {
    this.setState({ showDeleteModal: false, dltHead: "", dltSubHead: "" });
    const modal = document.querySelector(".modal");
    if (modal) {
      modal.classList.add("show");
    }
  };

  setOnDelete = (deleteFun) => {
    this.setState({
      showDeleteModal: true,
      deleteData: () => {
        deleteFun();
        this.setState({ showDeleteModal: false });
      },
    });
    const modal = document.querySelector(".modal");
    if (modal) {
      modal.classList.remove("show");
    }
  };

  hideEntryModal = () =>
    this.setState((state) => ({
      showEntryModal: !state.showEntryModal,
      showTally: false,
      showEntry: false,
      style: "",
      type: "",
      color: "",
      otherColor: "",
      size: "",
    }));

  displayEntryModal = () => {
    const { showEntryModal, viewTally, showEntry, label, active, totalLbl } =
      this.state;
    const hideModal = this.hideEntryModal;
    return (
      <PopupModal
        show={showEntryModal}
        heading={label}
        onHide={() => hideModal()}
        bold
        scrollable={false}
        styles={{ padding: "1em 1em 0 1em", border: "none" }}
      >
        <div className="_carsl">
          {!showEntry ? (
            <div className="slide-left view">
              {this.switchTabSelections(active)}
            </div>
          ) : !viewTally ? (
            this.displayDataEntry()
          ) : (
            this.renderTallyUI(hideModal, totalLbl)
          )}
        </div>
      </PopupModal>
    );
  };

  toggleDataEntry = (displayed = this.state.showEntry) => {
    this.setState(
      (state) => ({
        label: `${
          displayed
            ? `${state.activeLabel} Selections`
            : `Enter ${state.activeLabel}`
        } `,
        showEntry: !state.showEntry,
        viewTally: false,
      }),
      this.addSelectedToState
    );
  };

  addSelectedToState = () => {
    if (
      JSON.stringify(this.state[`${this.state.selectDimen}Selection`]) !== "{}"
    ) {
      this.setState({
        ...this.state[`${this.state.selectDimen}Selection`],
      });
    }
  };

  displayDataEntry = () => {
    const { section, selectData, selectDimen, editDimen } = this.state;
    const dimenValue = this.state[selectDimen];
    const dataValue = this.state[selectData];
    const hideModal = this.hideEntryModal;

    return (
      <div className="slide-right">
        {this.getTabTopData(
          section,
          selectData,
          numeral(this.getTotalFromData()).format("0,0")
        )}

        {this.WoodModalEntry(selectDimen, dimenValue)}

        {Numpad(
          selectDimen,
          editDimen,
          this.handleAddFromTab,
          this.onHandleEditEntry,
          this.state[selectDimen] === "" || this.state[selectDimen] === 0,
          this.addIntoDimen
        )}

        <Modal.Footer style={{ border: "none" }}>
          <div style={{ flex: "1" }}>
            <Button
              className="modified_btn"
              style={{ float: "left" }}
              onClick={this.toggleDataEntry}
            >
              View Selections
            </Button>
          </div>
          <Button
            className="modified_btn"
            variant="danger"
            onClick={() => hideModal()}
          >
            Close
          </Button>
          <Button
            className="modified_btn"
            onClick={this.toggleKeypad}
            disabled={selectData !== "" ? dataValue.length === 0 : false}
          >
            View Tally
          </Button>
        </Modal.Footer>
      </div>
    );
  };
  clearTabData = () => {
    const { selectDimen } = this.state;
    this.setState({ [selectDimen]: "", editDimen: "" });
  };

  WoodModalEntry = (selectDimen, dimenValue, disable = false) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "380px",
          paddingTop: "0.2em",
          fontSize: "2.1em",
        }}
        onClick={() => this.setState({ selectDimen: selectDimen })}
        className={`_block ${
          disable ? "unselected" : selectDimen ? "selected" : "unselected"
        }`}
      >
        <p className="_block-txt">{dimenValue}</p>
      </div>
    </div>
  );
  allowSelection = (data, value) => {
    if (data === value) {
      return true;
    }
    return false;
  };

  switchTabSelections = (active) => {
    const hideModal = this.hideEntryModal;
    switch (active) {
      case 0:
        return (
          <>
            <p className="btnheading gutterheading">Gutters Type & Style</p>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Row style={{ margin: "1em auto", width: "39%" }}>
                <Col>
                  {this.displayMetric(
                    "Type",
                    this.state.type,
                    G_GUTTERS[0],
                    [],
                    "",
                    "120px",
                    "",
                    false,
                    false,
                    ""
                  )}
                </Col>
              </Row>
              <Row style={{ margin: "1em auto", width: "39%" }}>
                <Col>
                  {this.displayMetric(
                    "style",
                    this.state.style,
                    G_GUTTERS[1],
                    this.state.type.length,
                    "",
                    "120px",
                    "",
                    false,
                    1
                  )}
                </Col>
              </Row>
            </Row>

            <Row style={{ margin: "1em auto", width: "94%" }}>
              <p className="btnheading gutterheading">Gutters Size</p>

              <Col>
                {this.displayMetric(
                  "size",
                  this.state.size,
                  G_GUTTERS[2],
                  this.state.style.length,
                  "",
                  "120px",
                  "",
                  false,
                  false,
                  "",
                  1
                )}
              </Col>
            </Row>

            {this.allowSelection(this.state.type, "GUTTERS001") && (
              <>
                <Row
                  style={{
                    margin: "1em auto",
                    width: "94%",
                    alignItems: "flex-end",
                  }}
                >
                  <p className="btnheading gutterheading">Gutters Color</p>
                  <Col>
                    {this.displayMetric(
                      "color",
                      this.state.color,
                      G_GUTTERS[3],
                      this.state.size.length,
                      "",
                      "120px",
                      "",
                      false,
                      false,
                      "Gutters Color",
                      1
                    )}
                  </Col>
                  {/* <Col lg="4"> */}
                  {/* {this.checkOtherValue(G_GUTTERS[3], this.state.color) && (
                    <div style={{ marginBottom: "7px" }}>
                      <Form.Control
                        type="text"
                        name="otherColor"
                        value={this.state.otherColor}
                        onChange={this.handleChange}
                      />
                    </div>
                  )} */}
                  {/* </Col> */}
                </Row>
                {this.checkOtherValue(G_GUTTERS[3], this.state.color) && (
                  <div
                    style={{
                      marginBottom: "7px",
                      marginTop: "7px",
                      width: "50%",
                      margin: "1.6em",
                    }}
                  >
                    <Form.Control
                      type="text"
                      name="otherColor"
                      value={this.state.otherColor}
                      onChange={this.handleChange}
                    />
                  </div>
                )}
              </>
            )}
            {this.displaySelectionFooter(
              hideModal,
              () => {
                const others = this.checkOtherValue(
                  G_GUTTERS[3],
                  this.state.color
                )
                  ? {
                      otherColor: this.state.otherColor,
                    }
                  : {
                      otherColor: "",
                    };
                this.saveSelection({
                  guttersSelection: {
                    type: this.state.type,
                    style: this.state.style,
                    size: this.state.size,
                    color: this.state.color,
                    ...others,
                  },
                });
              },
              !this.state.type ||
                !this.state.size ||
                !this.state.style ||
                (this.allowSelection(this.state.type, "GUTTERS001")
                  ? !this.state.color
                  : false)
            )}
          </>
        );

      case 1:
        return (
          <>
            <p className="btnheading gutterheading">Leader Type & Style</p>
            <Row style={{ alignItems: "flex-end" }}>
              <Row style={{ margin: "1em auto", width: "43%" }}>
                <Col>
                  {this.displayMetric(
                    "Type",
                    this.state.type,
                    G_LEADERS[0],
                    [],
                    "",
                    "120px",
                    "",
                    false,
                    false
                  )}
                </Col>
              </Row>
              <Row style={{ margin: "1em auto", width: "39%" }}>
                <Col>
                  {this.displayMetric(
                    "style",
                    this.state.style,
                    G_LEADERS[1],
                    this.state.type.length,
                    "",
                    "120px",
                    "",
                    false
                  )}
                </Col>
              </Row>
            </Row>

            <Row style={{ margin: "1em auto", width: "94%" }}>
              <p className="btnheading gutterheading">Leader Size</p>

              <Col>
                {this.displayMetric(
                  "size",
                  this.state.size,
                  G_LEADERSOTHER[this.state.type || "LEADERS001"][
                    this.state.style || "LEADERS003"
                  ][0],
                  this.state.style.length,
                  "",
                  "120px",
                  "",
                  false,
                  false,
                  "Leader Size"
                )}
              </Col>
            </Row>
            {this.state.type && G_LEADERSCOLOR[this.state.type].length > 0 && (
              <>
                <Row
                  style={{
                    margin: "1em auto",
                    width: "94%",
                    alignItems: "flex-end",
                  }}
                >
                  <p className="btnheading gutterheading">Gutters Color</p>

                  <Col>
                    {this.displayMetric(
                      "color",
                      this.state.color,
                      G_LEADERSCOLOR[this.state.type],
                      this.state.size.length,
                      "",
                      "120px",
                      "",
                      true,
                      false,
                      "Gutters Color"
                    )}
                  </Col>
                  {/* <Col lg="4">
                  {this.checkOtherValue(
                    G_LEADERSCOLOR[this.state.type],
                    this.state.color
                  ) && (
                    <div style={{ marginBottom: "7px" }}>
                      <Form.Control
                        type="text"
                        name="otherColor"
                        value={this.state.otherColor}
                        onChange={this.handleChange}
                      />
                    </div>
                  )}
                </Col> */}
                </Row>
                {this.checkOtherValue(
                  G_LEADERSCOLOR[this.state.type],
                  this.state.color
                ) && (
                  <div
                    style={{
                      marginBottom: "7px",
                      marginTop: "7px",
                      width: "50%",
                      margin: "1.6em",
                    }}
                  >
                    <Form.Control
                      type="text"
                      name="otherColor"
                      value={this.state.otherColor}
                      onChange={this.handleChange}
                    />
                  </div>
                )}
              </>
            )}
            {this.displaySelectionFooter(
              hideModal,
              () => {
                const others = this.allowSelection(
                  this.state.type,
                  "LEADERS001"
                )
                  ? {
                      color: this.state.color,
                      otherColor: this.state.otherColor,
                    }
                  : {
                      color: this.state.color,
                      otherColor: "",
                    };
                this.saveSelection({
                  leadersSelection: {
                    type: this.state.type,
                    style: this.state.style,
                    size: this.state.size,
                    ...others,
                  },
                });
              },
              !this.state.type ||
                !this.state.size ||
                !this.state.style ||
                (this.allowSelection(this.state.type, "LEADERS001")
                  ? !this.state.color
                  : false)
            )}
          </>
        );

      case 2:
        return (
          <>
            <Row style={{ margin: "1em auto", width: "94%" }}>
              <p className="btnheading gutterheading">Screen Type</p>

              <Col>
                {this.displayMetric(
                  "Type",
                  this.state.type,
                  G_SCREENS,
                  [],
                  "",
                  "120px",
                  "",
                  false,
                  false,
                  "Screen Type"
                )}
              </Col>
            </Row>

            <Row style={{ margin: "1em auto", width: "94%" }}>
              <p className="btnheading gutterheading">Screen Size</p>
              <Col>
                {this.displayMetric(
                  "size",
                  this.state.size,
                  G_SCREENSOTHER[this.state.type || "SCREENS001"][0],
                  this.state.type.length,
                  "",
                  "120px",
                  "",
                  false,
                  false,
                  "Screen Size"
                )}
              </Col>
            </Row>

            {this.state.type &&
              G_SCREENSOTHER[this.state.type][1].length > 0 && (
                <>
                  <Row
                    style={{
                      margin: "1em auto",
                      width: "94%",
                      alignItems: "flex-end",
                    }}
                  >
                    <p className="btnheading gutterheading">Screen Color</p>

                    <Col>
                      {this.displayMetric(
                        "color",
                        this.state.color,
                        G_SCREENSOTHER[this.state.type][1],
                        this.state.size.length,
                        "",
                        "120px",
                        "",
                        false,
                        false,
                        "Screen Color"
                      )}
                    </Col>
                    {/* <Col lg="4">
                  {this.checkOtherValue(
                    G_SCREENSOTHER[this.state.type][1],
                    this.state.color
                  ) && (
                    <div style={{ marginBottom: "7px" }}>
                      <Form.Control
                        type="text"
                        name="otherColor"
                        value={this.state.otherColor}
                        onChange={this.handleChange}
                      />
                    </div>
                  )}
                </Col> */}
                  </Row>
                  {this.checkOtherValue(
                    G_SCREENSOTHER[this.state.type][1],
                    this.state.color
                  ) && (
                    <div
                      style={{
                        marginBottom: "7px",
                        marginTop: "7px",
                        width: "50%",
                        margin: "1.6em",
                      }}
                    >
                      <Form.Control
                        type="text"
                        name="otherColor"
                        value={this.state.otherColor}
                        onChange={this.handleChange}
                      />
                    </div>
                  )}
                </>
              )}
            {this.displaySelectionFooter(
              hideModal,
              () => {
                const others = !this.allowSelection(
                  this.state.type,
                  "SCREENS002"
                )
                  ? {
                      color: this.state.color,
                      otherColor: this.state.otherColor,
                    }
                  : {
                      color: this.state.color,
                      otherColor: "",
                    };
                this.saveSelection({
                  screensSelection: {
                    type: this.state.type,
                    size: this.state.size,
                    ...others,
                  },
                });
              },
              !this.state.type ||
                !this.state.size ||
                (!this.allowSelection(this.state.type, "SCREENS002")
                  ? !this.state.color
                  : false)
            )}
          </>
        );
      case 3:
        return (
          <>
            <Row style={{ margin: "1em auto", width: "94%" }}>
              <p className="btnheading gutterheading">Leader Heads Type</p>
              <Col>
                {this.displayMetric(
                  "Type",
                  this.state.type,
                  G_SCREENS,
                  [],
                  "",
                  "120px",
                  "",
                  false,
                  false,
                  "Leader Heads Type"
                )}
              </Col>
            </Row>

            {this.state.type &&
              G_LEADERHEADSOTHER[this.state.type][0].length > 0 && (
                <>
                  <Row
                    style={{
                      margin: "1em auto",
                      width: "94%",
                      alignItems: "flex-end",
                    }}
                  >
                    <p className="btnheading gutterheading">
                      Leader Heads Color
                    </p>
                    <Col>
                      {this.displayMetric(
                        "color",
                        this.state.color,
                        G_SCREENSOTHER[this.state.type][1],
                        this.state.type.length,
                        "",
                        "120px",
                        "",
                        false,
                        false,
                        "Leader Heads Color"
                      )}
                    </Col>
                  </Row>
                  {this.checkOtherValue(
                    G_LEADERHEADSOTHER[this.state.type][0],
                    this.state.color
                  ) && (
                    <div
                      style={{
                        marginBottom: "7px",
                        marginTop: "7px",
                        width: "50%",
                        margin: "1.6em",
                      }}
                    >
                      <Form.Control
                        type="text"
                        name="otherColor"
                        value={this.state.otherColor}
                        onChange={this.handleChange}
                      />
                    </div>
                  )}
                </>
              )}
            {this.displaySelectionFooter(
              hideModal,
              () => {
                const others = !this.allowSelection(
                  this.state.type,
                  "SCREENS002"
                )
                  ? {
                      color: this.state.color,
                      otherColor: this.state.otherColor,
                    }
                  : {};
                this.saveSelection({
                  leaderheadsSelection: {
                    type: this.state.type,
                    ...others,
                  },
                });
              },
              !this.state.type ||
                (!this.allowSelection(this.state.type, "SCREENS002")
                  ? !this.state.color
                  : false)
            )}
          </>
        );

      case 4:
        return (
          <>
            <Row style={{ margin: "1em auto", width: "94%" }}>
              <p className="btnheading gutterheading">Fascia Type</p>

              <Col>
                {this.displayMetric(
                  "Type",
                  this.state.type,
                  G_FASCIADATA,
                  [],
                  "",
                  "120px",
                  "",
                  false,
                  false,
                  "Fascia Type"
                )}
              </Col>
            </Row>

            <Row style={{ margin: "1em auto", width: "94%" }}>
              <p className="btnheading gutterheading">Fascia Size</p>

              <Col>
                {this.displayMetric(
                  "size",
                  this.state.size,
                  G_FASCIA[this.state.type || "FASCIA009"][0],
                  this.state.type.length,
                  "",
                  "120px",
                  "",
                  false,
                  false,
                  "Fascia Size"
                )}
              </Col>
            </Row>

            <Row style={{ margin: "1em auto", width: "94%" }}>
              <p className="btnheading gutterheading">Fascia Length</p>

              <Col>
                {this.displayMetric(
                  "length",
                  this.state.length,
                  G_FASCIA[this.state.type || "FASCIA009"][1],
                  this.state.size.length,
                  "",
                  "120px",
                  "",
                  false,
                  false,
                  "Fascia Length"
                )}
              </Col>
            </Row>
            {this.displaySelectionFooter(
              hideModal,
              () => {
                this.saveSelection({
                  fasciaSelection: {
                    type: this.state.type,
                    size: this.state.size,
                    length: this.state.length,
                  },
                });
              },
              !this.state.type || !this.state.size || !this.state.length
            )}
          </>
        );

      case 5:
        return (
          <>
            <Row style={{ margin: "1em auto", width: "94%" }}>
              <p className="btnheading gutterheading">Capping Type</p>

              <Col>
                {this.displayMetric(
                  "Type",
                  this.state.type,
                  G_CAPPING[0],
                  [],
                  "",
                  "120px",
                  "",
                  false,
                  false,
                  "Capping Type"
                )}
              </Col>
            </Row>

            <Row style={{ margin: "1em auto", width: "94%" }}>
              <Col>
                {this.displayMetric(
                  "color",
                  this.state.color,
                  G_CAPPING[1],
                  this.state.type.length,
                  "",
                  "120px",
                  "",
                  true,
                  true,
                  "Capping Color"
                )}
              </Col>
            </Row>

            {this.checkOtherValue(G_CAPPING[1], this.state.color) && (
              <Row style={{ margin: "1em auto", width: "97%" }}>
                <Col style={{ maxWidth: "50%" }}>
                  <div style={{ marginBottom: "7px" }}>
                    <Form.Control
                      type="text"
                      name="otherColor"
                      value={this.state.otherColor}
                      onChange={this.handleChange}
                    />
                  </div>
                </Col>
              </Row>
            )}

            {this.displaySelectionFooter(
              hideModal,
              () => {
                this.saveSelection({
                  cappingSelection: {
                    type: this.state.type,
                    color: this.state.color,
                    otherColor: this.state.otherColor,
                  },
                });
              },
              !this.state.type
            )}
          </>
        );
      default:
        return null;
    }
  };

  saveSelection = (data) => {
    this.setState({ ...data }, () => {
      this.saveEntryData();
    });
  };

  displaySelectionFooter = (hideModal, onNext, disableBy = false) => (
    <Modal.Footer style={{ border: "none" }}>
      <Button
        className="modified_btn"
        variant="danger"
        onClick={() => hideModal()}
      >
        Close
      </Button>
      <Button
        className="modified_btn"
        disabled={disableBy}
        onClick={() => {
          onNext();
          this.toggleDataEntry();
        }}
      >
        Next
      </Button>
    </Modal.Footer>
  );

  switchTableHead = () => {
    const {
      active,
      fasciaSelection,
      cappingSelection,
      leadersSelection,
      leaderheadsSelection,
      guttersSelection,
      screensSelection,
    } = this.state;
    switch (active) {
      case 0:
        return (
          <tr style={{ justifyContent: "space-evenly" }}>
            {this.showSelection({
              selected: guttersSelection,
              array: G_GUTTERS,
            }).map((data) => (
              <th style={{ flex: "unset" }}>{data}</th>
            ))}
          </tr>
        );
      case 1:
        return (
          <tr style={{ justifyContent: "space-evenly" }}>
            {this.showSelection({
              selected: leadersSelection,
              array:
                JSON.stringify(leadersSelection) !== "{}"
                  ? [
                      [...G_LEADERS[0]],
                      [...G_LEADERS[1]],
                      ...G_LEADERSOTHER[leadersSelection.type][
                        leadersSelection.style
                      ],
                      [...G_LEADERSCOLOR[leadersSelection.type]],
                    ]
                  : [],
            }).map((data, i) => (
              <th key={`${i}-${data}`} style={{ flex: "unset" }}>
                {data}
              </th>
            ))}
          </tr>
        );

      case 2:
        return (
          <tr style={{ justifyContent: "space-evenly" }}>
            {this.showSelection({
              selected: screensSelection,
              array:
                JSON.stringify(screensSelection) !== "{}"
                  ? [
                      G_SCREENS,
                      G_SCREENSOTHER[screensSelection.type][0],
                      G_SCREENSOTHER[screensSelection.type][1],
                    ]
                  : [],
            }).map((data) => (
              <th key={data} style={{ flex: "unset" }}>
                {data}
              </th>
            ))}
          </tr>
        );

      case 3:
        return (
          <tr style={{ justifyContent: "space-evenly" }}>
            {this.showSelection({
              selected: leaderheadsSelection,
              array:
                JSON.stringify(leaderheadsSelection) !== "{}"
                  ? [
                      G_SCREENS,
                      G_LEADERHEADSOTHER[leaderheadsSelection.type][0],
                    ]
                  : [],
            }).map((data) => (
              <th style={{ flex: "unset" }}>{data}</th>
            ))}
          </tr>
        );

      case 4:
        return (
          <tr style={{ justifyContent: "space-evenly" }}>
            {this.showSelection({
              selected: fasciaSelection,
              array:
                JSON.stringify(fasciaSelection) !== "{}"
                  ? [
                      G_FASCIADATA,
                      G_FASCIA[fasciaSelection.type][0],
                      G_FASCIA[fasciaSelection.type][1],
                    ]
                  : [],
            }).map((data) => (
              <th key={data} style={{ flex: "unset" }}>
                {data}
              </th>
            ))}
          </tr>
        );

      case 5:
        return (
          <tr style={{ justifyContent: "space-evenly" }}>
            {this.showSelection({
              selected: cappingSelection,
              array: JSON.stringify(cappingSelection) !== "{}" ? G_CAPPING : [],
            }).map((data) => (
              <th style={{ flex: "unset" }}>{data}</th>
            ))}
          </tr>
        );

      default:
        return null;
    }
  };

  renderTallyUI = (hide, totalLbl) => {
    const { section, selectData } = this.state;

    return (
      <div className="slide-left view">
        {this.getTabTopData(
          section,
          selectData,
          numeral(this.getTotalFromData()).format("0,0")
        )}

        {selectData !== "" && this.state[selectData].length > 0 && (
          <>
            <table className="dimen_table">
              <thead>{this.switchTableHead()}</thead>
              <tbody>
                {this.TabDataDisplay(this.state[this.state.selectData])}
              </tbody>
            </table>
          </>
        )}
        <Modal.Footer
          style={{ justifyContent: "space-between", border: "none" }}
        >
          <div>
            <Button
              className="modified_btn"
              style={{ float: "left" }}
              onClick={this.toggleKeypad}
            >
              Keypad
            </Button>
          </div>
          {this.showPagination()}
          <div>
            <Button
              className="modified_btn"
              variant="danger"
              onClick={() => hide()}
              style={{ margin: "0 1em" }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </div>
    );
  };

  saveEntryData = () => {
    this.setState(
      (state) => ({
        activateSave: false,
        [`${state.selectDimen}Total`]: this.getTotalFromData(),
      }),
      () => {
        this.addSection();
      }
    );
  };
  TabDataDisplay = (data) => {
    const { page_num, max_item } = this.state;
    const lastI = page_num * max_item;
    const startI = lastI - max_item;
    return data.slice(startI, lastI).map((data, i) => (
      <tr key={i}>
        <td>
          <Button onClick={() => this.onEditEntry(data, i)}>Edit</Button>
        </td>
        <td>{data}</td>
        <td>
          <Button
            variant="danger"
            onClick={() => this.setOnDelete(() => this.deleteGuttersEntry(i))}
          >
            Delete
          </Button>
        </td>
      </tr>
    ));
  };

  deleteGuttersEntry = (index) => {
    this.setState(
      (state) => {
        let temp = state[state.selectData];
        temp.splice(index, 1);
        return {
          [state.selectData]: temp,
        };
      },
      () => {
        this.setState(
          (state) => ({
            [`${state.selectDimen}Total`]: this.getTotalFromData(),
            [`${state.selectDimen}Selection`]:
              state[state.selectData].length > 0
                ? state[`${state.selectDimen}Selection`]
                : {},
          }),
          () => {
            this.toggleWoodEntry();
            this.hideDeleteModal();
            this.clearInputs();
            this.addSection();
          }
        );
      }
    );
  };

  clearInputs = () => {
    this.setState({
      linearfeet: "",
      faceSize: "",
      length: "",
      height: "",
      width: "",
      material: "",
      inches: "",
      shape: "",
      style: "",
      type: "",
      size: "",
      color: "",
    });
  };

  renderTabs = () => (
    <Tabs
      defaultActiveKey="gutter"
      id="uncontrolled-tab"
      style={{ marginBottom: "3%" }}
    >
      {this.renderTab()}
    </Tabs>
  );

  displayGuttersModal = (label, data, name, totalLabel, index) => {
    this.setState({
      showEntryModal: true,
      active: index,
    });
    this.setModalOtherState(label, data, name, totalLabel);
  };

  setModalOtherState = (label, data, name, totalLabel) => {
    let totalLbl = typeof totalLabel === "undefined" ? "" : totalLabel;

    this.setState(
      {
        label: `${label} Selections`,
        selectData: data,
        selectDimen: name.toLowerCase().replace(" ", ""),
        activeLabel: label,
        totalLabel: totalLbl,
        totalLbl: totalLbl.toLowerCase().replace(" ", ""),
      },
      this.addSelectedToState
    );
  };

  onEditEntry = (data, i) => {
    let obj = { editDimen: i };
    this.setState({
      ...obj,
      [this.state.selectDimen]: data,
    });
    this.toggleKeypad();
    setTimeout(() => {
      this.toggleDataEntry();
    }, 500);
  };

  handleAddFromTab = () => {
    const { selectDimen, selectData } = this.state;
    this.setState(
      (state) => ({
        [selectData]: [...state[selectData], Number(state[selectDimen])],
      }),
      () => {
        this.saveEntryData();
        this.clearTabData();
      }
    );
  };

  onHandleEditEntry = () => {
    this.setState(
      (state) => {
        let temp = state[state.selectData];
        temp.splice(state.editDimen, 1, this.state[state.selectDimen]);
        return { [state.selectData]: temp, activateSave: true };
      },
      () => {
        this.toggleKeypad();
        this.saveEntryData();
        this.clearTabData();
      }
    );
  };

  buldingTotal = (list, selectTotal) => {
    return list.reduce(
      (acc, item) => (acc = Number(acc) + Number(item[selectTotal])),
      0
    );
  };

  openImages = () => {
    const { isLocked } = this.state;
    if (isLocked) {
      this.setState({
        isQuoteLock: true,
      });
    } else {
      this.setState({
        isQuoteLock: false,
      });
    }
    this.setState({
      images: true,
    });
  };

  closeImages = () =>
    this.setState({
      images: false,
      isQuoteLock: false,
    });

  getFloor = (data) => this.getDataLabel(G_LEVEL, data);
  getLabel = (data) => this.getDataLabel(G_ELEVATION_DETAILS, data);
  getElevation = (data) => this.getElevationLetters(G_ELEVATIONS, data);

  deleteImageFromSection = (section, col, img = undefined) => {
    const { sectionData, tableData } = this.state;
    let temp = [...sectionData];
    let temp2 = [...tableData];

    const idx = temp.findIndex((data) => data.sectionId === section);
    const idx2 = temp2.findIndex((data) => data.sectionId === section);
    temp[idx].imagesList[col] = img;
    temp2[idx2].imagesList[col] = img;

    let newImageList = [];
    temp[idx].imagesList.map((image) => {
      if (image) {
        newImageList.push(image);
      }
    });
    if (newImageList.length !== 5) {
      let remainingImage = new Array(5 - newImageList.length).fill(undefined);
      newImageList = [...newImageList, ...remainingImage];
    }

    temp[idx].imagesList = newImageList;
    temp2[idx2].imagesList = newImageList;

    this.setState(
      {
        sectionData: temp,
        tableData: temp2,
        editSection: false,
      },
      () => {
        this.saveImagesToDB();
      }
    );
  };

  addImageIntoSection = (section, col, img = undefined) => {
    const { sectionData, tableData } = this.state;
    let temp = [...sectionData];
    let temp2 = [...tableData];

    const idx = temp.findIndex((data) => data.sectionId === section);
    const idx2 = temp2.findIndex((data) => data.sectionId === section);
    temp[idx].imagesList[col] = img ? { checked: false, ...img } : img;
    temp2[idx2].imagesList[col] = img ? { checked: false, ...img } : img;
    this.setState(
      {
        sectionData: temp,
        tableData: temp2,
        editSection: false,
      },
      () => {
        this.saveImagesToDB();
      }
    );
  };

  uncheckAllImages = (imagesList) => {
    return imagesList.map((img) =>
      img ? (img = { ...img, checked: false }) : (img = undefined)
    );
  };

  addCheckToImage = (checked, image) => {
    const { building, coverImage } = this.state;
    let newCoverImage = [...coverImage];
    if (building) {
      if (coverImage.length > 0) {
        const idx = coverImage.findIndex((img) => img.bid === building);

        if (idx > -1) {
          if (!checked) {
            newCoverImage.splice(idx, 1);
          } else {
            newCoverImage[idx] = {
              bid: building,
              imageSrc: image,
            };
          }
        } else {
          newCoverImage.push({
            bid: building,
            imageSrc: image,
          });
        }
      } else {
        newCoverImage = [
          {
            bid: building,
            imageSrc: image,
          },
        ];
      }
    } else {
      newCoverImage = [
        checked
          ? {
              imageSrc: image,
            }
          : { imageSrc: "" },
      ];
    }

    this.setState(
      {
        coverImage: newCoverImage,
      },
      () => {
        this.saveImagesToDB();
      }
    );
    return true;
  };

  saveImagesToDB = () => {
    this.saveMetricData(this.state.sectionData);
  };

  reload = () => this.loadData();

  checkCoverImageProperty = () => {
    const { coverImage, building } = this.state;
    if (building) {
      if (coverImage.length > 0) {
        const match = coverImage.find((img) => img.bid === building);
        return match ? match.imageSrc : "";
      } else {
        return "";
      }
    } else {
      return coverImage.length > 0 ? coverImage[0].imageSrc : "";
    }
  };

  showBuildingList = () => this.setState({ showCoopModal: true });

  render() {
    let {
      isDataChanged,
      notes,
      elevation,
      elevation_details,
      address,
      showCoopModal,
      coopUnits,
      newBuildingName,
      tempNewBuildingName,
      otherModal,
      elevation_other,
      edOther,
      deleteData,
      showDeleteModal,
      building,
      level,
      msg,
      msgType,
      editBuilding,
      deleteBuilding,
      buildingErr,
      images,
      sectionData,
      tableData,
      dltSubHead,
      dltHead,
      editSection,
      isQuoteLock,
      isLocked,
    } = this.state;
    const { data, isLoading, errors, match } = this.props;
    const metricId = match?.params?.id || this.props.matricId;

    const HeaderComponent = Header.bind(this, {
      address,
      metricType: "Gutters",
      buildingName: building
        ? getBuildingName(this.state.coopUnits, building)
        : "",
    });

    const DeleteModalComponent = DeleteModal.bind(this, {
      showDeleteModal: showDeleteModal,
      deleteData: () => deleteData(),
      processing: false,
      heading: dltHead ? dltHead : "Delete Confirmation",
      hideDeleteModal: this.hideDeleteModal,
      dltSubHead: dltSubHead,
    });

    if (errors || isLoading)
      return <InitialLoader error={errors} onReload={this.reload} />;

    if (this.props.image_type === "PM_IMAGE") {
      images = true;
    }

    if (images) {
      return (
        <Images
          Header={HeaderComponent}
          closeImages={this.closeImages}
          sectionData={tableData}
          getLabel={this.getFloor}
          getElevationLetters={this.getElevation}
          addImageIntoSection={this.addImageIntoSection}
          getDataLabel={this.getLabel}
          deleteImageFromSection={this.deleteImageFromSection}
          addCheckToImage={this.addCheckToImage}
          coverImage={this.checkCoverImageProperty}
          label="Level"
          isQuoteLock={isQuoteLock}
          metricId={metricId}
        />
      );
    }

    return (
      <MetricHeader
        onClickBuilding={this.showBuildingList}
        openImages={this.openImages}
        addAnotherSection={this.addAnotherSection}
        disabledAddSection={this.disableButton()}
        disabledImageBtn={tableData.length === 0}
        metricId={metricId}
        hasOtherMetric={data?.hasRoof ?? false}
        isBuilding={data?.isBuilding ?? "false"}
        address={address}
        metricType="Gutters"
        msg={msg}
        msgType={msgType}
        buildingName={
          building ? getBuildingName(this.state.coopUnits, building) : ""
        }
        isQuoteLock={isLocked}
        type_page={this.props.type_page}
      >
        {DeleteModalComponent()}

        <div style={{ margin: "1em 0" }}>
          <Row
            style={{
              margin: "1em 0",
              justifyContent: "flex-end",
              width: "70%",
            }}
          >
            <Col>
              {this.displayMetric(
                "Level",
                level,
                G_LEVEL,
                (!(data && data.isBuilding) ||
                  (data && data.isBuilding && this.state.building)) &&
                  !isQuoteLock,
                isQuoteLock,
                "24%",
                undefined
              )}
            </Col>
            <Col>
              <label className="btnheading">Elevation</label>
              <div className="ele_grid">
                {this.selectMultipleMetric(
                  "Elevation",
                  G_ELEVATIONS,
                  elevation,
                  "elevation",
                  !level || isQuoteLock,
                  isQuoteLock
                )}
              </div>
            </Col>
            <Col>
              <label className="btnheading">Elevation Details</label>
              <div className="eledetail_grid">
                {this.metricButton(
                  "Elevation Details",
                  elevation_details,
                  G_ELEVATION_DETAILS,
                  elevation.length && !isQuoteLock,
                  isQuoteLock
                )}

                <button
                  disabled={
                    !elevation.length || !elevation_details || isQuoteLock
                  }
                  className={`ClickableBtn ${
                    this.state.elevation_other.length > 0 ? "selected" : ""
                  }`}
                  onClick={() => this.setState({ otherModal: true })}
                >
                  More...
                </button>
              </div>
            </Col>
          </Row>
        </div>
        {elevation_details && (
          <>
            <Row>
              <Col style={{ margin: "1em 0" }}>{this.renderTabs()}</Col>
            </Row>
            <Row
              // style={{ padding: "0 1.8em" }}
              style={{ marginBottom: "14px" }}
            >
              <Col lg="1.5">
                <label>Section Notes:</label>
              </Col>
              <Col
              //  style={{ padding: "0 0 0 1em" }}
              >
                <Form.Group style={styles.formInput}>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    name="notes"
                    value={notes}
                    style={{ resize: "none" }}
                    onChange={(e) => {
                      this.handleChange(e);
                      this.saveDataIntoDb();
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            {this.displayEntryModal()}
          </>
        )}
        <Building
          onCoopModalHide={this.onCoopModalHide}
          handleChange={this.handleChange}
          newBuildingName={newBuildingName}
          coopUnits={coopUnits}
          onEditBuilding={this.onEditBuilding}
          saveEditBuilding={this.saveEditBuilding}
          tempNewBuildingName={tempNewBuildingName}
          onDeleteBuilding={this.onDeleteBuilding}
          deleteBuilding={this.deleteBuilding}
          addBuldings={this.addBuldings}
          showCoopModal={showCoopModal}
          onSelected={(unit) => {
            if (unit === building) return this.onCoopModalHide();
            this.selectBuilding(unit);
            this.addAnotherSection();
            this.onCoopModalHide();
          }}
          selected={building}
          getBuildingName={this.getBuildingName}
          editBuilding={editBuilding}
          deleteBuildingId={deleteBuilding}
          error={buildingErr}
        />
        {this.displayModal(
          otherModal,
          "Elevation Details",
          this.hideModal,
          [
            { id: "001", name: "Apron" },
            { id: "002", name: "Rake" },
            { id: "003", name: "Dormer" },
            { id: "004", name: "Extension" },
            { id: "005", name: "BRZ-Way" },
            { id: "006", name: "Gable" },
            { id: "007", name: "REV Gable" },
            { id: "008", name: "Overhang" },
            { id: "009", name: "Flat Roof" },
            { id: "010", name: "Portico" },
            { id: "011", name: "Mansard" },
            { id: "012", name: "Turret" },
            { id: "100", name: "Other", other: true },
          ],
          elevation_other,
          "elevation_other",
          {
            name: "edOther",
            value: edOther,
            display: elevation_other.includes("004"),
          }
        )}
        {this.state.tableData.length > 0 && (
          <>
            {/* <div style={{ marginTop: "2em" }}>
              {coopUnits.length > 0 && (
                <Row>
                  <Col>
                    <table className="_tbl_building">
                      <thead>
                        <tr>
                          <th></th>
                          <th colSpan={2}>All Buildings Total</th>
                          <th
                            style={{
                              paddingRight: "calc(2px * 1.5vw",
                              width: editSection ? "75px" : "175px",
                            }}
                          >
                            <span className="totalData">
                              {this.formatNumber(
                                this.buldingTotal(sectionData, "guttersTotal")
                              ) !== "0"
                                ? `${this.formatNumber(
                                    this.buldingTotal(
                                      sectionData,
                                      "guttersTotal"
                                    )
                                  )}(G)`
                                : ""}
                            </span>
                          </th>
                          <th
                            style={{
                              paddingRight: "calc(2px * 1.5vw",
                              width: editSection ? "100px" : "120px",
                            }}
                          >
                            <span className="totalData">
                              {this.formatNumber(
                                this.buldingTotal(sectionData, "leadersTotal")
                              ) !== "0"
                                ? `${this.formatNumber(
                                    this.buldingTotal(
                                      sectionData,
                                      "leadersTotal"
                                    )
                                  )}(L)`
                                : ""}
                            </span>
                          </th>

                          <th
                            style={{
                              paddingRight: "calc(2px * 1.5vw",
                              width: editSection ? "100px" : "118px",
                            }}
                          >
                            <span className="totalData">
                              {this.formatNumber(
                                this.buldingTotal(sectionData, "screensTotal")
                              ) !== "0"
                                ? `${this.formatNumber(
                                    this.buldingTotal(
                                      sectionData,
                                      "screensTotal"
                                    )
                                  )}(S)`
                                : ""}
                            </span>
                          </th>
                          <th
                            style={{
                              paddingRight: "calc(2px * 1.5vw",
                              width: editSection ? "100px" : "90px",
                            }}
                          >
                            <span className="totalData">
                              {this.formatNumber(
                                this.buldingTotal(sectionData, "fasciaTotal")
                              ) !== "0"
                                ? `${this.formatNumber(
                                    this.buldingTotal(
                                      sectionData,
                                      "fasciaTotal"
                                    )
                                  )}(F)`
                                : ""}
                            </span>
                          </th>
                          <th
                            style={{
                              paddingRight: "calc(2px * 1.5vw",
                              width: editSection ? "100px" : "90px",
                            }}
                          >
                            <span className="totalData">
                              {this.formatNumber(
                                this.buldingTotal(sectionData, "cappingTotal")
                              ) !== "0"
                                ? `${this.formatNumber(
                                    this.buldingTotal(
                                      sectionData,
                                      "cappingTotal"
                                    )
                                  )}(C)`
                                : ""}
                            </span>
                          </th>
                          <th
                            style={{
                              paddingRight: "calc(2px * 1.5vw",
                              width: editSection ? "100px" : "40px",
                            }}
                          >
                            <span className="totalData">
                              {this.formatNumber(
                                this.buldingTotal(
                                  sectionData,
                                  "leaderheadsTotal"
                                )
                              ) !== "0"
                                ? `${this.formatNumber(
                                    this.buldingTotal(
                                      sectionData,
                                      "leaderheadsTotal"
                                    )
                                  )}(H)`
                                : ""}
                            </span>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                    </table>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <table className="_tbl_building">
                    <thead>
                      <tr>
                        <th></th>

                        <th colSpan={2}>Current Building Total</th>
                        <th
                          style={{
                            paddingRight: "calc(2px * 1.5vw",
                            width: editSection ? "75px" : "175px",
                          }}
                        >
                          <span className="totalData">
                            {this.formatNumber(
                              this.buldingTotal(tableData, "guttersTotal")
                            ) !== "0"
                              ? `${this.formatNumber(
                                  this.buldingTotal(tableData, "guttersTotal")
                                )}(G)`
                              : ""}
                          </span>
                        </th>
                        <th
                          style={{
                            paddingRight: "calc(2px * 1.5vw",
                            width: editSection ? "100px" : "120px",
                          }}
                        >
                          <span className="totalData">
                            {this.formatNumber(
                              this.buldingTotal(tableData, "leadersTotal")
                            ) !== "0"
                              ? `${this.formatNumber(
                                  this.buldingTotal(tableData, "leadersTotal")
                                )}(L)`
                              : ""}
                          </span>
                        </th>

                        <th
                          style={{
                            paddingRight: "calc(2px * 1.5vw",
                            width: editSection ? "100px" : "118px",
                          }}
                        >
                          <span className="totalData">
                            {this.formatNumber(
                              this.buldingTotal(tableData, "screensTotal")
                            ) !== "0"
                              ? `${this.formatNumber(
                                  this.buldingTotal(tableData, "screensTotal")
                                )}(S)`
                              : ""}
                          </span>
                        </th>
                        <th
                          style={{
                            paddingRight: "calc(2px * 1.5vw",
                            width: editSection ? "100px" : "90px",
                          }}
                        >
                          <span className="totalData">
                            {this.formatNumber(
                              this.buldingTotal(tableData, "fasciaTotal")
                            ) !== "0"
                              ? `${this.formatNumber(
                                  this.buldingTotal(tableData, "fasciaTotal")
                                )}(F)`
                              : ""}
                          </span>
                        </th>
                        <th
                          style={{
                            paddingRight: "calc(2px * 1.5vw",
                            width: editSection ? "100px" : "90px",
                          }}
                        >
                          <span className="totalData">
                            {this.formatNumber(
                              this.buldingTotal(tableData, "cappingTotal")
                            ) !== "0"
                              ? `${this.formatNumber(
                                  this.buldingTotal(tableData, "cappingTotal")
                                )}(C)`
                              : ""}
                          </span>
                        </th>
                        <th
                          style={{
                            paddingRight: "calc(2px * 1.5vw",
                            width: editSection ? "100px" : "40px",
                          }}
                        >
                          <span className="totalData">
                            {this.formatNumber(
                              this.buldingTotal(tableData, "leaderheadsTotal")
                            ) !== "0"
                              ? `${this.formatNumber(
                                  this.buldingTotal(
                                    tableData,
                                    "leaderheadsTotal"
                                  )
                                )}(H)`
                              : ""}
                          </span>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                  </table>
                </Col>
              </Row>
            </div> */}
            <Row>
              <Col>
                {BasicTable({
                  data: this.getTableData(),
                  columns: G_columns,
                  type: "gutter",
                  tableData: tableData,
                  editSection: editSection,
                  coopUnits: coopUnits,
                  sectionData: sectionData,
                  isQuoteLock: this.state.isQuoteLock,
                })}
              </Col>
            </Row>
            <Row>
              <Col>
                <GenerateQuote
                  disableBtn={editSection && this.disableButton()}
                  isDataChanged={isDataChanged}
                  metricId={metricId}
                />
              </Col>
            </Row>
          </>
        )}
      </MetricHeader>
    );
  }
}

const styles = {
  col: {
    margin: 0,
    padding: "1px",
  },
};

function mapStateToProps({
  metricsData: { isLoading, data, errors },
  addBuilding: { addingBuilding, buildingErrors, buildingSuccess },
  addMetric: { addingMetric, metricErrors, metricSuccess },
}) {
  return {
    isLoading,
    data,
    errors,
    addingBuilding,
    buildingErrors,
    buildingSuccess,
    addingMetric,
    metricErrors,
    metricSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getGuttersData: (id) => dispatch(getGuttersData(id)),
    updateBuilding: (data) => dispatch(updateBuilding({ ...data })),
    addMetric: (data) => dispatch(addMetric({ ...data })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Metric);
