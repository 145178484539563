import { SuppliersText } from './const';

export const selectPreferredSupplier = (
  supplierData,
  changeSupplier,
  setSelectedSupplier,
) => {
  if (supplierData && supplierData.length > 0) {
    const match = supplierData.find((supplier) => supplier.isPreferred);

    if (match) {
      changeSupplier(match._id, setSelectedSupplier);
    }
  }
};

export const changeSupplier = (supplier, setSelectedSupplier) => {
  setSelectedSupplier(supplier);
};

export const supplierEmptyText = (listLen) => (listLen > 0 ? SuppliersText.selectSuppliers : SuppliersText.noDataAvailable);


