import React, { useEffect, useState } from "react";
import { getWeekDate } from "components/calendar/utils";
import RoofWeekView from "./RoofWeekView";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-date-picker";
import { sortTimeWise } from "./helpers";
import { Tooltip } from "@material-ui/core";
import { LoadingLoader } from "components";

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ProductionScheduleRoof = (props) => {
  const {
    onPrevWeek,
    onNextWeek,
    dateObject,
    setToday,
    certainDate,
    roof,
    isLoading,
  } = props;

  const [weekdaysShort, setWeekdaysShort] = useState(moment.weekdaysShort());
  const [date, setDate] = useState([]);
  const [eventss, setEvents] = useState({});
  const [eventsArrayId, setEventsArrayId] = useState();

  useEffect(() => {
    if (roof) {
      let eventsObj = {};

      roof.sort(sortTimeWise).forEach((event) => {
        const date = event.eventDetails.date;
        const currentDayEvents = eventsObj[date];
        if (currentDayEvents) {
          eventsObj[date] = [...currentDayEvents, event];
        } else {
          eventsObj[date] = [event];
        }
      });
      setEvents(eventsObj);
    }
  }, [roof]);

  const getEventsByDate = (date) => {
    let dateArray = date.split("/");
    dateArray = dateArray.map((value) => {
      if (value.length > 1) return value;
      return `0${value}`;
    });

    const newDateFormat = `${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`;

    const currentDateEvents = eventss[newDateFormat];
    return currentDateEvents ?? [];
  };

  const tooltip_data = (eventData) => {
    let roof = eventData.metrics.filter(
      (metric) => metric.metricType === "roof"
    );
    let shingles = 0;
    let flats = 0;

    if (roof.length) {
      roof[0]?.metricData?.forEach((val) => {
        let {
          metaData: { hasFlat, hasShingles },
          totalFlat,
        } = val;
        if (hasFlat && hasShingles === false) {
          flats = flats + totalFlat;
        }
        if (hasShingles && hasFlat === false) {
          shingles = shingles + totalFlat;
        }
      });
    }
    if (isLoading) {
      return <LoadingLoader />;
    }
    return (
      <div>
        <div className="flex">
          <h4 className="p-4">
            Start: {eventData?.eventDetails?.startTime}
          </h4>
          <h4 className="p-4">Roof Crew: {eventData?.persons}</h4>
        </div>
        <div className="flex">
          <div>
            <div>
              <a
                href={`https://www.google.com/maps/place?q=${eventData?.requestObj?.address}`}
                className="address"
                target="_blank"
              >
                <h3 className="p-4">{`${eventData?.requestObj?.property_name ? `${eventData?.requestObj?.property_name},` : ""} ${eventData?.requestObj?.address}`}</h3>
              </a>
            </div>
            <div>
              <h4 className="pl-4 pb-4">
                Shingles - {shingles} Flat - {flats}
              </h4>
            </div>
            <div className="pb-4">
              <h4 className="pl-4">
                Company: {eventData?.requestObj?.property_name}
              </h4>
              <h4 className="pl-4">
                Primary Name: {eventData?.requestObj?.leadName}
              </h4>
              <h4 className="pl-4">
                Phone: {eventData?.requestObj?.primary_phone}
              </h4>
            </div>
            <div>
              <h4 className="pl-4">
                Secondary Name: {eventData?.requestObj?.secondary_name}
              </h4>
              <h4 className="pl-4">
                Phone: {eventData?.requestObj?.secondary_phone}
              </h4>
            </div>
          </div>
          {roof[0]?.coverImage.length > 0 || roof[0]?.metricData[0]?.imagesList[0] != undefined ? (
            <img
              src={
                roof[0]?.coverImage.length > 0
                  ? roof[0]?.coverImage[0].imageSrc
                  : roof[0]?.metricData[0]?.imagesList[0]
                    ? roof[0]?.metricData[0]?.imagesList[0]?.uri
                    : ""
              }
              alt=""
              className="mt-20 h-60 w-60 border-none"
            />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  };

  const daysInWeekView = () => {
    const { dateObject } = props;

    const hover = (eventData) => {
      setEventsArrayId(eventData?.requestObj?.requestId);
    };

    if (isLoading) {
      return weekdaysShort.map((e, i) => (
        <td className="_week_view_td" key={i}>
          {i === 3 && <LoadingLoader />}
        </td>
      ));
    }

    return weekdaysShort.map((day, i) => {
      const dayDate = getWeekDate(i, dateObject);
      const events = getEventsByDate(dayDate);

      const dayBlock = new Date(moment().subtract(1, "day")) <= new Date(dayDate) ? (
        <td className="_week_view_td" key={`${day}-${i}`}>
          {events.length > 0 &&
            events.map((eventData) => (
              <div onMouseOver={() => hover(eventData)}>
                {eventData?.requestObj?.requestId === eventsArrayId ? (
                  <Tooltip
                    title={tooltip_data(eventData)}
                    arrow
                    placement="right"
                    interactive
                  >
                    <div
                      key={eventData.requestObj.requestId}
                      className="week-event text-center"
                      style={{
                        background: eventData.badgeColor
                          ? eventData.badgeColor
                          : "#0051ff",
                      }}
                    >
                      {eventData.displayText1}
                      <br />
                      {eventData.displayText2}
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    key={eventData.requestObj.requestId}
                    className="week-event text-center"
                    style={{
                      background: eventData.badgeColor
                        ? eventData.badgeColor
                        : "#0051ff",
                    }}
                  >
                    {eventData.displayText1}
                    <br />
                    {eventData.displayText2}
                  </div>
                )}
              </div>
            ))}
        </td>
      ) : (
        <td className="_week_view_td" key={`${day}-${i}`}>
          {events.length > 0 &&
            events.map((eventData) => (
              <div
                onMouseOver={() => hover(eventData)}
                onMouseEnter={() => hover(eventData)}
              >
                {eventData?.requestObj?.requestId === eventsArrayId ? (
                  <Tooltip
                    title={tooltip_data(eventData)}
                    arrow
                    placement="right"
                    leaveDelay={200}
                    interactive
                  >
                    <div
                      key={eventData.requestObj.requestId}
                      className="week-event text-center"
                      style={{
                        background: eventData.badgeColor
                          ? eventData.badgeColor
                          : "#0051ff",
                      }}
                    >
                      {eventData.displayText1}
                      <br />
                      {eventData.displayText2}
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    key={eventData.requestObj.requestId}
                    className="week-event text-center"
                    style={{
                      background: eventData.badgeColor
                        ? eventData.badgeColor
                        : "#0051ff",
                    }}
                  >
                    {eventData.displayText1}
                    <br />
                    {eventData.displayText2}
                  </div>
                )}
              </div>
            ))}
        </td>
      );
      return dayBlock;
    });
  };

  const changeDate = (i, date) => {
    const temp = [date];
    temp.splice(i, 1, date);
    setDate(temp && temp.length && temp[0] != null && temp);
  };

const weekText = () => {
    const currentYear = moment(dateObject || new Date()).year();
    const currentMonth = moment(dateObject || new Date()).month();
    const currentWeek = moment(dateObject || new Date()).week();
    return `Week ${currentWeek} ${month[currentMonth]} ${currentYear}`;
  };


  useEffect(() => {
    certainDate(date);
  }, [date]);

  const dateChange = (date) => changeDate(0, date);

  return (
    <>
      <div style={{
        display: 'flex',
        padding: '1rem',
        justifyContent: 'space-between'
      }}>
        <div className="btns-group">
          <button className="today-btn text-sm" onClick={setToday}>
            Today
          </button>
        </div>
        <h3 className="text-lg w-100">Production Schedule</h3>
        <div style={{ width: "20rem" }}>
          <DatePicker
            showLeadingZeros={true}
            onChange={dateChange}
            value={date.length != 0 ? date[0] : new Date()}
            className="__datePicker"
          />
        </div>
      </div>
      <div className="p-4">
        <RoofWeekView
          weekdaysShort={weekdaysShort}
          onPrevWeek={onPrevWeek}
          onNextWeek={onNextWeek}
          dateObject={dateObject}
          weekText={weekText()}
          daysInWeekView={daysInWeekView}
        />
      </div>
    </>
  );
};

export default ProductionScheduleRoof;
