import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import RequiredText from 'components/required-text/required-text'
import SelectState from 'components/select-state/select-state'

export default function CommonAddressFields({
	required,
	allowDisabling,
	handleChange,
	address1Name,
	address1,
	address2Name,
	address2,
	cityName,
	city,
	stateName,
	state,
	zipName,
	zip,
	setAddress1,
	setAddress2,
	setCity,
	setState,
	setZip,
}) {
	return (
		<Col md='12' sm='12'>
			<Row>
				<Col md='6' lg='4'>
					<Form.Group>
						<Form.Label>
							Address 1 {required && <RequiredText>*</RequiredText>}
						</Form.Label>
						<Form.Control
							as='textarea'
							rows='1'
							name={address1Name}
							value={address1}
							disabled={allowDisabling}
							onChange={event => handleChange(event, setAddress1)}
						/>
					</Form.Group>
				</Col>
				<Col md='6' lg='4'>
					<Form.Group>
						<Form.Label>Address 2</Form.Label>
						<Form.Control
							as='textarea'
							rows='1'
							name={address2Name}
							value={address2}
							disabled={allowDisabling}
							onChange={event => handleChange(event, setAddress2)}
						/>
					</Form.Group>
				</Col>
			</Row>

			<Row className='mt-2'>
				<Col md='6' lg='4'>
					<Form.Group>
						<Form.Label>
							City {required && <RequiredText>*</RequiredText>}
						</Form.Label>
						<Form.Control
							type='text'
							name={cityName}
							value={city}
							disabled={allowDisabling}
							onChange={event => handleChange(event, setCity)}
						/>
					</Form.Group>
				</Col>

				<Col md='6' lg='4'>
					<SelectState
						label='State'
						required={required}
						name={stateName}
						value={state}
						onChange={value => handleChange(value, setState)}
						disabled={allowDisabling}
					/>
				</Col>

				<Col md='6' lg='4'>
					<Form.Group>
						<Form.Label>
							Zip Code {required && <RequiredText>*</RequiredText>}
						</Form.Label>
						<Form.Control
							type='text'
							name={zipName}
							value={zip}
							maxLength={5}
							disabled={allowDisabling}
							onChange={event => handleChange(event, setZip)}
						/>
					</Form.Group>
				</Col>
			</Row>
		</Col>
	)
}
