import React from "react";

const newStyle = {
  basePlanTextBox: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(16, 189, 214)",
    padding: "10px 20px",
    fontWeight: "800",
    marginTop: "10px",
    borderRadius: ".25rem",
  },
};

const BasePlanTextBox = ({
  baseplanPriceAdmin,
  currency,
  basePlanUsers,
  align = "left",
}) => (
  <div style={{ textAlign: align, ...newStyle.basePlanTextBox }}>
    Base Plan Includes ({basePlanUsers}) Users at{" "}
    {`${currency ? (currency === "USD" ? "$" : currency) : ""}${
      baseplanPriceAdmin
      // .toFixed(2)
    } `}
    Per Month
  </div>
);

export default BasePlanTextBox;
