import React, { useState, useEffect } from "react";
import validator from "validator";

import { Row, Col, Button, Form } from "react-bootstrap";

import {
  addMPricingCustom,
  deleteMPricingCustom,
} from "lib/redux/suppliers/suppliers.action";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PriceInput from "components/PriceIput/PriceInput";

export function NewListData({
  lgSize,
  showUOM,
  customItemDefaultValue,
  setDataList,
  dataList,
  setAddItem,
  newData,
  suppliersId,
  showPreferred,
}) {
  const [uomPrice, setUomPrice] = useState(parseFloat(0.0).toFixed(2));
  const [series, setSeries] = useState("");
  const [uom, setUOM] = useState("");
  const [uomValue, setUomValue] = useState("");
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(customItemDefaultValue).includes("series")) {
      setSeries(customItemDefaultValue.series);
    }
    if (Object.keys(customItemDefaultValue).includes("uom")) {
      setUOM(customItemDefaultValue.uom);
    }
    if (Object.keys(customItemDefaultValue).includes("uomValue")) {
      setUomValue(customItemDefaultValue.uomValue);
    }
  }, []);

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const handlePriceChange = (e, callback) => {
    callback(e.target.value.replace("$", ""));
  };

  const handleSave = async () => {
    if (!validator.isNumeric(uomPrice)) {
      return toast.error("Please provide a valid price.");
    }
    if (showUOM && !validator.isNumeric(`${uomValue}`)) {
      return toast.error("Please provide a valid UOM value.");
    }
    const addData = showUOM ? { uomValue } : {};
    const toastId = toast.info("Adding Data. Please wait!", {
      autoClose: false,
    });
    try {
      setProcessing(true);
      const res = await dispatch(
        addMPricingCustom(
          {
            ...newData,
            uomPrice: parseFloat(uomPrice).toFixed(2),
            uom,
            series,
            ...addData,
          },
          suppliersId
        )
      );
      if (res.data.success) {
        toast.dismiss(toastId);
        dataList.push(res.data.result.custom);
        setDataList(dataList);
        setAddItem(false);
        toast.success("Data Added successfully.");
      } else {
        toast.dismiss(toastId);
        toast.error(res.data.error);
      }
    } catch (err) {
      toast.dismiss(toastId);
      toast.error(err.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Row>
      {showPreferred && <Col md="1" />}
      {/* series */}
      <Col lg={lgSize}>
        <Form.Group>
          <Form.Control
            type="text"
            disabled={
              customItemDefaultValue
                ? Object.keys(customItemDefaultValue).includes("series")
                : true
            }
            value={series}
            onChange={(event) => handleChange(event, setSeries)}
          />
        </Form.Group>
      </Col>
      {/* UOM */}
      <Col>
        <Form.Group>
          <Form.Control
            type="text"
            style={{ textAlign: "center" }}
            disabled={
              customItemDefaultValue
                ? Object.keys(customItemDefaultValue).includes("uom")
                : true
            }
            value={uom}
            onChange={(event) => handleChange(event, setUOM)}
          />
        </Form.Group>
      </Col>
      {/*Ft Per UOM */}
      {showUOM && (
        <Col>
          <Form.Group>
            <Form.Control
              type="text"
              style={{ textAlign: "center" }}
              disabled={
                customItemDefaultValue
                  ? Object.keys(customItemDefaultValue).includes("uomValue")
                  : true
              }
              value={uomValue}
              onChange={(event) => handleChange(event, setUomValue)}
            />
          </Form.Group>
        </Col>
      )}
      {/* Price Per UOM */}
      <Col lg="2">
        <Form.Group>
          <Form.Control
            type="text"
            style={{ textAlign: "right" }}
            name="uomPrice"
            value={`$${uomPrice}`}
            onChange={(event) => handlePriceChange(event, setUomPrice)}
          />
        </Form.Group>
      </Col>
      <Col>
        <Button
          onClick={() => handleSave()}
          disabled={
            processing || (showUOM && !uomValue) || !series || !uom || !uomPrice
          }
          variant="success"
          style={{ marginRight: "1em" }}
        >
          {processing ? "Saving..." : "Save"}
        </Button>
        <Button variant="danger" onClick={() => setAddItem(false)}>
          Cancel
        </Button>
      </Col>
    </Row>
  );
}

export function MiscListData({
  changePrice,
  customItemDefaultValue,
  setDataList,
  dataList,
  setAddItem,
  newData,
  uomOptions,
  idx,
  _id,
  priceData,
  delData,
  setDisable,
}) {
  const [uomPrice, setUomPrice] = useState(
    priceData.uomPrice
      ? parseFloat(priceData.uomPrice).toFixed(2)
      : parseFloat("0").toFixed(2)
  );
  const [series, setSeries] = useState("" || priceData.series);
  const [uom, setUOM] = useState("" || priceData.uom);
  const [manufacturer, setManufacturer] = useState(
    "" || priceData.manufacturer
  );
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const handleInputChange = (e, callback) => {
    callback(e.target.value.replace("$", ""));
  };
  const handleChange = (e, callback) => {
    setDisable(false);
    callback(e.target.value);
  };
  useEffect(() => {
    let value = uomPrice;
    if (value && validator.isNumeric(value.toString())) {
      value = parseFloat(uomPrice).toFixed(2);
    }
    changePrice(idx, { uomPrice: value });
  }, [uomPrice]);

  const showOptions = (list) =>
    list.map((data) => (
      <option style={{ textTransform: "capitalize" }} value={data} key={data}>
        {data}
      </option>
    ));

  const handleDel = async () => {
    setProcessing(true);
    const toastId = toast.info("Deleting data. Please wait!", {
      autoClose: false,
    });
    try {
      const res = await dispatch(deleteMPricingCustom(_id));
      if (res.data.success) {
        toast.dismiss(toastId);
        if (delData(_id)) {
          setAddItem(false);
          toast.success("Data deleted successfully.");
        }
      } else {
        toast.dismiss(toastId);
        toast.error("An Error occured deleting data.Please try again later!");
      }
    } catch (err) {
      toast.dismiss(toastId);
      toast.error(err.message);
    } finally {
      setProcessing(false);
    }
  };

  const handlePriceChange = (price) => {
    setUomPrice(price);
  };

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Control
            type="text"
            disabled
            value={manufacturer}
            name={manufacturer}
            style={{ textAlign: "center" }}
            onChange={(event) => handleChange(event, setManufacturer)}
          />
        </Form.Group>
      </Col>
      <Col lg="3">
        <Form.Group>
          <Form.Control
            type="text"
            disabled
            value={series}
            name={series}
            onChange={(event) => handleChange(event, setSeries)}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Control
            as="select"
            value={uom}
            disabled
            name={uom}
            style={{ textAlign: "center" }}
            onChange={(event) => handleChange(event, setUOM)}
          >
            <option value="">Choose...</option>
            {showOptions(uomOptions)}
          </Form.Control>
        </Form.Group>
      </Col>

      <Col lg="2">
        <PriceInput
          style={{ textAlign: "right" }}
          price={uomPrice}
          onChange={handlePriceChange}
        />
      </Col>

      <Col>
        <Button
          variant="danger"
          onClick={() => handleDel()}
          disabled={processing}
        >
          {processing ? "Deleting..." : "Delete"}
        </Button>
      </Col>
    </Row>
  );
}

export function NewMiscListData({
  customItemDefaultValue,
  setDataList,
  dataList,
  setAddItem,
  newData,
  uomOptions,
  priceData,
  suppliersId,
}) {
  const [uomPrice, setUomPrice] = useState(Number(0).toFixed(2));
  const [series, setSeries] = useState("");
  const [uom, setUOM] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const handlePriceChange = (e, callback) => {
    callback(e.target.value.replace("$", ""));
  };

  const showOptions = (list) =>
    list.map((data) => (
      <option style={{ textTransform: "capitalize" }} value={data} key={data}>
        {data}
      </option>
    ));

  const handleSave = async () => {
    if (!validator.isNumeric(uomPrice)) {
      return toast.error("Please provide a valid price.");
    }

    const toastId = toast.info("Adding Data. Please wait!", {
      autoClose: false,
    });
    try {
      setProcessing(true);
      const res = await dispatch(
        addMPricingCustom(
          {
            ...newData,
            uomPrice: parseFloat(uomPrice).toFixed(2),
            series,
            manufacturer,
            uom,
          },
          suppliersId
        )
      );
      if (res.data.success) {
        toast.dismiss(toastId);
        dataList.push(res.data.result.custom);
        setDataList(dataList);
        setAddItem(false);
        toast.success("Data Added successfully.");
      } else {
        toast.dismiss(toastId);
        toast.error(res.data.error);
      }
    } catch (err) {
      toast.dismiss(toastId);
      toast.error(err.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Control
            type="text"
            value={manufacturer}
            name={manufacturer}
            style={{ textAlign: "center" }}
            onChange={(event) => handleChange(event, setManufacturer)}
          />
        </Form.Group>
      </Col>
      <Col lg="3">
        <Form.Group>
          <Form.Control
            type="text"
            value={series}
            name={series}
            onChange={(event) => handleChange(event, setSeries)}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Control
            as="select"
            value={uom}
            name={uom}
            style={{ textAlign: "center" }}
            onChange={(event) => handleChange(event, setUOM)}
          >
            <option value="">Choose...</option>
            {showOptions(uomOptions)}
          </Form.Control>
        </Form.Group>
      </Col>

      <Col lg="2">
        <Form.Group>
          <Form.Control
            style={{ textAlign: "right" }}
            type="text"
            name="uomPrice"
            value={`$${uomPrice}`}
            onChange={(event) => handlePriceChange(event, setUomPrice)}
          />
        </Form.Group>
      </Col>
      <Col>
        <Button
          onClick={() => handleSave()}
          disabled={processing || !uom || !uomPrice}
          variant="success"
          style={{ marginRight: "1em" }}
        >
          {processing ? "Saving..." : "Save"}
        </Button>
        <Button variant="danger" onClick={() => setAddItem(false)}>
          Cancel
        </Button>
      </Col>
    </Row>
  );
}

export default function ListData({
  lgSize,
  priceData,
  setPreferred,
  price,
  series,
  uom,
  uomPerSq,
  changePrice,
  id,
  showUOM,
  preferredData,
  _id,
  hasPreferedOption,
  multiPreferedOption,
  customItemDefaultValue,
  isCustom,
  setDataList,
  dataList,
  setAddItem,
  delData,
  showPreferred,
  setProcessingg,
  setDisable,
  category,
  categoryType,
}) {
  const [newPrice, setNewPrice] = useState(
    price ? parseFloat(price).toFixed(2) : parseFloat("0").toFixed(2)
  );
  const [newSeries, setNewSeries] = useState(series);
  const [newUom, setNewUom] = useState(uom);
  const [uomValue, setUomValue] = useState(uomPerSq);

  const [deleting, setDel] = useState(false);
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e, callback) => {
    setDisable(false);
    callback(e.target.value);
  };

  const handleChecked = (e) => {
    setDisable(false);
    if (multiPreferedOption) {
      if (checkPreferred(preferredData, _id)) {
        const temp = [...preferredData];
        const idx = temp.findIndex((pricing) => pricing._id === _id);
        if (idx > -1) {
          temp.splice(idx, 1);
        }
        setPreferred([...temp]);
        changePrice(id, { isPreferred: false });
      } else {
        setPreferred([...preferredData, priceData]);
        changePrice(id, { isPreferred: true });
      }
      return;
    }
    if (checkPreferred(preferredData, _id)) {
      setPreferred([]);
      changePrice(id, { isPreferred: false });
    } else {
      setPreferred([priceData]);
      changePrice(id, { isPreferred: true });
    }
  };

  const handleInputChange = (price) => {
    setProcessingg(false);
    setNewPrice(price);
  };

  useEffect(() => {
    let value = newPrice;
    if (value.trim() && validator.isNumeric(value)) {
      value = parseFloat(newPrice).toFixed(2);
    }
    changePrice(id, { uomPrice: value });
  }, [newPrice]);

  useEffect(() => {
    changePrice(id, { series: newSeries });
  }, [newSeries]);
  useEffect(() => {
    changePrice(id, { uom: newUom });
  }, [newUom]);
  useEffect(() => {
    changePrice(id, { uomValue });
  }, [uomValue]);

  const handleDel = async () => {
    setDel(true);
    const toastId = toast.info("Deleting data. Please wait!", {
      autoClose: false,
    });
    try {
      const res = await dispatch(deleteMPricingCustom(_id));
      if (res.data.success) {
        toast.dismiss(toastId);

        if (delData(_id)) {
          setAddItem(false);
          toast.success("Data deleted successfully.");
        }
      } else {
        toast.dismiss(toastId);
        toast.error("An Error occured deleting data.Please try again later!");
      }
    } catch (err) {
      toast.dismiss(toastId);
      toast.error(err.message);
    } finally {
      setDel(false);
    }
  };

  const handleEdit = async () => {
    setEditing((prev) => !prev);
  };

  const checkPreferredSelectedType = () => {
    let isAllGaf =
      preferredData.filter((e) => e.manufacturer === "GAF").length === 2;
    let isAllBroan =
      preferredData.filter((e) => e.manufacturer === "Broan").length === 2;
    return isAllGaf || isAllBroan ? false : true;
  };

  const checkPreferred = (list, id) => list.some((data) => data._id === id);

  const showCheckBox =
    preferredData.length === 0 ||
    (preferredData.length > 0 && checkPreferred(preferredData, _id)) ||
    (category == "Ventilation" &&
      categoryType == "Hood/Goose Neck" &&
      checkPreferredSelectedType());

  // const checkPreferred = (list, id) => list.some((data) => data._id === id);

  // const showCheckBox =
  //   preferredData.length === 0 ||
  //   (preferredData.length > 0 && checkPreferred(preferredData, _id)) ||
  //   multiPreferedOption;

  return (
    <Row style={{ paddingBottom: "15px" }}>
      {showPreferred && (
        <Col md="1" style={{ textAlign: "center" }}>
          {showCheckBox && (
            <Form.Group>
              <Form.Check
                className="materialListFeild"
                name="pref"
                checked={checkPreferred(preferredData, _id)}
                onChange={(e) => handleChecked(e)}
              />
            </Form.Group>
          )}
        </Col>
      )}
      <Col lg={lgSize}>
        <Form.Group>
          <Form.Control
            className="materialListFeild"
            type="text"
            disabled={!editing}
            value={newSeries}
            onChange={(event) => handleChange(event, setNewSeries)}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Control
            className="materialListFeild"
            type="text"
            disabled
            value={newUom}
            style={{ textAlign: "center" }}
            onChange={(event) => handleChange(event, setNewUom)}
          />
        </Form.Group>
      </Col>
      {showUOM && (
        <Col>
          <Form.Group>
            <Form.Control
              className="materialListFeild"
              type="text"
              disabled
              value={uomValue}
              style={{ textAlign: "center" }}
              onChange={(event) => handleChange(event, setUomValue)}
            />
          </Form.Group>
        </Col>
      )}
      <Col lg="2">
        <PriceInput
          style={{ textAlign: "right" }}
          price={newPrice}
          onChange={handleInputChange}
        />
      </Col>
      {/* button control item */}
      <Col>
        {isCustom && (
          <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
            <Button
              variant={editing ? "success" : "warning"}
              onClick={() => handleEdit()}
            >
              {editing ? "Done" : "Edit"}
            </Button>

            <Button
              variant="danger"
              onClick={() => handleDel()}
              disabled={deleting}
            >
              {deleting ? "Deleting..." : "Delete"}
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
}
