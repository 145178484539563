import { axiosInstance as axios, axiosInstance } from "services";
import { getEndPoints } from "api/api";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ApiEndpoints } from "lib/config/baseSettings";

const url = getEndPoints(process.env.REACT_APP_MODE);

const useWeather= () => {
  const [weatherData, setWeatherData] = useState([]);
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetch_weather_data = (zip) => {
    setIsLoading(true);
    return axiosInstance
      .get(ApiEndpoints.PRODUCTION_DASH.getWeather, {
        params: {
          zipCode: zip,
        },
      })
      .then((res) => {
        setWeatherData(res.data.result);
        return res.data.result;
      })
      .catch((err) => {
        setIsLoading(false);
        console.log({ error: err });
        setIsError(err);
        return [];
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isError,
    isLoading,
    weatherData,
    fetch_weather_data,
  };
};

export default useWeather;
