import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { DraggagleList } from "../DraggableList";
import { NonDraggableList } from "../NonDraggableList";
import PercentageModal from "../PercentageModal";
import { faCircleArrowUp, faHandRock } from "@fortawesome/free-solid-svg-icons";
import Color from "lib/constants/color";
import { Icon } from "components";

export function StartOverSection({
  HeadingText,
  switchModalData,
  setOtherTexts,
  setFixedText,
  otherTexts,
  fixedStandardStatements,
  updateFixedStandardStatementsPercentage,
  quoteMaterialSectionData,
  updateFixedStandardStatementsisSelected,
  updateFixedStandardStatementdisplayText,
  isLockQuoteData,
  totalWithMarkUp,
  modalShowv2,
  proposalModal,
  firstTime,
}) {
  const [per, setPer] = useState(0);
  const [percent, setPercent] = useState(per);
  const [percentageStatementIdx, setPercentageStatementIdx] = useState(-1);
  const [stepperModal, setStepperModal] = useState(false);
  const [displayData, setDisplayText] = useState("");
  const [otherTextsState, setOtherTextState] = useState(otherTexts);

  const [isDown, setIsDown] = useState(true);
  console.log("firstTime ", firstTime, {modalShowv2, proposalModal});
  useEffect(() => {
    setOtherTextState((prev) => [...otherTexts]);
  }, [otherTexts]);

  useLayoutEffect(() => {
    if (fixedStandardStatements && fixedStandardStatements.length > 0) {
      const percentageStatement = fixedStandardStatements.find(
        (statement) => {
          if (statement.percentage !== undefined) {
            return statement;
          }
        }
      );
      if (percentageStatement) {
        setPer(percentageStatement.percentage);
      }
    }
  }, [fixedStandardStatements]);
  const changePer = (value) => setPer(value);

  const changePerData = (increase = true) => {
    let newPer = percent;
    if (increase) {
      newPer += 1;
    } else {
      newPer -= 1;
    }
    setPercent(newPer < 0 ? 0 : newPer);
  };
  const applyChanges = () => {
    changePer(percent);
    if (percentageStatementIdx > -1) {
      updateFixedStandardStatementsPercentage(percentageStatementIdx, percent);
    }
    hidePercentageStepperModal();
  };

  const showPercentageStepperModal = (displaytext, idx) => {
    setPercent(displaytext.percentage);
    setDisplayText(displaytext.displayText);
    setPercentageStatementIdx(idx);

    setStepperModal(true);
  };

  const hidePercentageStepperModal = () => {
    setStepperModal(false);
  };

  const cancelChanges = () => {
    setPercent(per);
    hidePercentageStepperModal();
  };

  return (
    <>
      <Row style={{ margin: "2em 0" }}>
        <Col>
          <p className="__msg">{HeadingText}</p>
        </Col>
      </Row>

      <DraggagleList
        list={otherTextsState}
        setList={setOtherTexts}
        quoteMaterialSectionData={quoteMaterialSectionData}
        updateFixedStandardStatementsisSelected={
          updateFixedStandardStatementsisSelected
        }
        updateFixedStandardStatementdisplayText={
          updateFixedStandardStatementdisplayText
        }
        isLockQuoteData={isLockQuoteData}
        isDown={isDown}
        setIsDown={setIsDown}
      />
      <NonDraggableList
        list={fixedStandardStatements}
        setList={setFixedText}
        showPercentageStepperModal={showPercentageStepperModal}
        isLockQuoteData={isLockQuoteData}
        totalWithMarkUp={totalWithMarkUp}
      />

      <ThirtyDayZone
        otherTextsState={otherTextsState}
        isLockQuoteData={isLockQuoteData}
        setList={setOtherTexts}
        isDown={isDown}
        setIsDown={setIsDown}
      />

      <PercentageModal
        onHide={hidePercentageStepperModal}
        show={stepperModal}
        percent={percent}
        onCancel={cancelChanges}
        onSubmit={applyChanges}
        changePerData={changePerData}
        submitText="Apply"
        heading={displayData.replace(":", " Percentage")}
      />

      {firstTime
        ? switchModalData()
        : modalShowv2 === "Start Over" && proposalModal === "Start"
          ? switchModalData()
          : null}
    </>
  );
}

const ThirtyDayZone = ({
  otherTextsState,
  isLockQuoteData,
  setList,
  isDown,
  setIsDown,
}) => {
  const [thirtyDay, setThirtyDay] = useState(null);
  const [inpDisabled, setInpDisabled] = useState(true);
  const [displayText, setDisplayText] = useState(thirtyDay?.displayText);

  const inpRef = useRef(null);

  useEffect(() => {
    let thirtyDays = otherTextsState.filter((value) => {
      return value.dataId === "STD016";
    });

    setThirtyDay(() => thirtyDays[0]);
    if (!thirtyDays[0].isDown) {
      if (thirtyDays[0].isDown === false) {
        setIsDown(false);
      } else {
        setIsDown(true);
      }
    } else {
      setIsDown(true);
    }
  }, [otherTextsState]);

  useEffect(() => {
    setDisplayText(thirtyDay?.displayText);
  }, [thirtyDay]);

  const handleIsSelect = (isSelected) => {
    let newList = otherTextsState.slice();
    let index = 0;
    newList.map((value, id) => {
      if (value.dataId === "STD016") index = id;
    });
    newList[index].isSelected = isSelected;
    setList([...newList]);
  };

  const handelOnChangeEdit = (e) => {
    setDisplayText(e.target.value);
    //updateFixedStandardStatementdisplayText(idx, e.target.value);
  };

  const handleSave = (isDown = null) => {
    setInpDisabled(true);
    let newList = otherTextsState.slice();
    let index = 0;
    newList.map((value, id) => {
      if (value.dataId === "STD016") index = id;
    });
    newList[index].displayText = displayText;
    if (isDown !== null) {
      newList[index].isDown = isDown;
    }

    setList([...newList]);
  };

  const hanldeClick = () => {
    if (!inpDisabled) return;
    setInpDisabled(false);
    if (inpRef.current) {
      inpRef.current.focus();
    }
  };

  const styleCondition = (isSelected, isInpDisabled) => {
    const cross = {
      textDecoration: "line-through",
      color: "gray",
    };
    const pointerEvent = {
      pointerEvents: "none",
    };

    let result = {};
    if (!isSelected) {
      result = { ...result, ...cross };
    }
    if (isInpDisabled) {
      result = { ...result, ...pointerEvent };
    }

    return result;
  };
  if (!isDown) return;
  return (
    <div>
      {thirtyDay && (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <div>
            {thirtyDay.isSelected ? (
              <Button
                variant="danger"
                onClick={() => {
                  handleIsSelect(false);
                }}
                disabled={isLockQuoteData?.isLocked}
              >
                Remove
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => handleIsSelect(true)}
                style={{ width: "83px" }}
                disabled={isLockQuoteData?.isLocked}
              >
                Add
              </Button>
            )}
          </div>
          <div
            className="inp"
            onClick={() => {
              hanldeClick();
            }}
            role="button"
            // title={title}
            tabIndex="-1"
            style={{ flex: 1 }}
          >
            <Form.Control
              onChange={handelOnChangeEdit}
              value={displayText}
              type="text"
              disabled={inpDisabled || isLockQuoteData?.isLocked}
              ref={inpRef}
              style={styleCondition(thirtyDay.isSelected, inpDisabled)}
            />
          </div>
          {inpDisabled || isLockQuoteData?.isLocked ? null : (
            <div>
              <Button
                onClick={() => {
                  handleSave();
                }}
                style={{ marginRight: "0.5rem" }}
              >
                Save
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  setDisplayText((prev) => text);
                  setInpDisabled(true);
                }}
              >
                Cancel
              </Button>
            </div>
          )}
          <div
            onClick={() => {
              setIsDown(false);
              handleSave(false);
            }}
            style={{ cursor: "pointer" }}
          >
            <Icon size="2x" color={Color.primaryColor} name={faCircleArrowUp} />
          </div>
          <div title="Tap and hold">
            <span className="handle">
              <Icon size="2x" color={Color.primaryColor} name={faHandRock} />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
