import { calendarActionTypes } from "./calendar.types";

const INITIAL_STATE = {
  isLoading: false,
  data: null,
  errors: null,
};

export const CalendarReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case calendarActionTypes.CALENDAR_START:
      return {
        ...state,
        isLoading: true,
        data: null,
        errors: null,
      };

    case calendarActionTypes.CALENDAR_SUCCESS:{
      return {
        ...state,
        isLoading: false,
        data: action.payload.result,
        errors: null,
      };
    }

    case calendarActionTypes.CALENDAR_FAILURE:
      {
        return {
          ...state,
          isLoading: false,
          data: null,
          errors: action.payload,
        };
      }

    default:
      return state;
  }
};

const INITIAL_STATE_AV_PERSONS = {
  isLoadingAvPersons: false,
  personsData: [],
  personsErrors: null,
};

export const AvPersonsReducer = (state = INITIAL_STATE_AV_PERSONS, action) => {
  switch (action.type) {
    case calendarActionTypes.AVAILABLE_PERSON_START:
      return {
        ...state,
        isLoadingAvPersons: true,
        personsData: [],
        personsErrors: null,
      };
    case calendarActionTypes.AVAILABLE_PERSON_CLEAR:
      return {
        ...state,
        isLoadingAvPersons: false,
        personsData: [],
        personsErrors: null,
      };

    case calendarActionTypes.AVAILABLE_PERSON_SUCCESS:
      return {
        ...state,
        isLoadingAvPersons: false,
        personsData: action.payload.result,
        personsErrors: null,
      };

    case calendarActionTypes.AVAILABLE_PERSON_FAILURE:
      return {
        ...state,
        isLoadingAvPersons: false,
        personsData: [],
        personsErrors: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_SCHEDULE = {
  isSubmitting: false,
  submitSuccess: null,
  submitFail: null,
};

export const CalendarScheduleReducer = (
  state = INITIAL_STATE_SCHEDULE,
  action
) => {
  switch (action.type) {
    case calendarActionTypes.ADD_TO_CALENDAR_START:
      return {
        ...state,
        isSubmitting: true,
        submitSuccess: null,
        submitFail: null,
      };

    case calendarActionTypes.ADD_TO_CALENDAR_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        submitSuccess: action.payload.result.message,
        submitFail: null,
      };

    case calendarActionTypes.ADD_TO_CALENDAR_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        submitSuccess: null,
        submitFail: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_EVENTS = {
  isFetching: false,
  events: [],
  eventsError: null,
};

export const CalendarEventsReducer = (state = INITIAL_STATE_EVENTS, action) => {
  console.log({actionEvents: action})
  switch (action.type) {
    case calendarActionTypes.FETCH_EVENTS_START:
      return {
        ...state,
        isFetching: true,
        events: [],
        eventsError: null,
      };

    case calendarActionTypes.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        events: action.payload.result ? action.payload.result : [],
        eventsError: null,
      };

    case calendarActionTypes.FETCH_EVENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        events: [],
        eventsError: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_UNSCHEDULE = {
  isunScheduling: false,
  unscheduleSuccess: null,
  unscheduleFail: null,
};

export const CalendarUnScheduleReducer = (
  state = INITIAL_STATE_UNSCHEDULE,
  action
) => {
  switch (action.type) {
    case calendarActionTypes.DELETE_EVENTS_START:
      return {
        ...state,
        isunScheduling: true,
        unscheduleSuccess: null,
        unscheduleFail: null,
      };

    case calendarActionTypes.DELETE_EVENTS_SUCCESS:
      return {
        ...state,
        isunScheduling: false,
        unscheduleSuccess: action.payload.result.message,
        unscheduleFail: null,
      };

    case calendarActionTypes.DELETE_EVENTS_FAILURE:
      return {
        ...state,
        isunScheduling: false,
        unscheduleSuccess: null,
        unscheduleFail: action.payload,
      };

    default:
      return state;
  }
};
