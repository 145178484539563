import React, { useEffect, useState } from "react";
import "../../components/select-state/select-state.scss";
import { Form } from "react-bootstrap";
import RequiredText from "components/required-text/required-text";
import useStateAndCountTax from "hooks/useStateAndCountyTax";

export default function SelectState({
  errors = {},
  errAt,
  name,
  label,
  required,
  ...props
}) {
  const [dropDown, setDropDown] = useState()
  const { activeData} = useStateAndCountTax();
  
  useEffect(() => {
    let arr = [];
    if (activeData && activeData.length) {
      arr = activeData.map((val) => {
        // return `${val.state} - ${val._id}`;
        return {name: val.state, id: val._id};
      });

      setDropDown(arr)
    }
  }, [activeData]);

  // const STATES = dropDown && dropDown.length && [...dropDown];
  const showOptions = (list) => {
    return (list && list.length) ? list.map((data) => {
      return (
        <option style={{ textTransform: "capitalize" }} value={data.id} key={data}>
          {data.name}
        </option>
      )
    }
  ):   (
    <option>
      Not available
  </option>
  );
  };

  return (
    <Form.Group>
      <Form.Label>
        {label} {required && <RequiredText>*</RequiredText>}
      </Form.Label>

      <Form.Control
        className={`form-control select-state ${
          (errAt === name || errors[name]) && "error"
        }`}
        as="select"
        name={name}
        value={props.value}
        {...props}
      >
        <option value="">Choose...</option>
        {showOptions(dropDown)}
      </Form.Control>
    </Form.Group>
  );
}