import laborPricingCompany from "services/offlineComputing/models/laborPricingCompany";
import metricSelection from "./metricSelection";
import { parseMetricData } from "./parseMetricData";
import { getData } from "services/storageManager";
import {
  LABOR_METRIC_ITEMS,
  CATEGORYDATA_LABOR,
  LABORDATA_UOM,
  getDifficulty,
  getWorkType,
  getInstallType,
  getOtherRemovalType,
  getRemovalType,
} from "lib/constants/data";
import { getRoofMetrics } from "services/offlineComputing/models/metricsModel";

const getSpecificSelectedMetric = async (parsedMetricData) => {
  let materialSectionData = [];

  const { totals } = parsedMetricData;
  LABOR_METRIC_ITEMS.forEach((category) => {
    const { totalKey, series, order, types, dataKey, key } = category;
    const hasMetricData = dataKey ? parsedMetricData[dataKey] : null;

    const totalValue = totalKey ? totals[totalKey] : false;

    if (series && totalValue) {
      const data = metricSelection(category, hasMetricData, key);

      if (data.length > 0) {
        if (series === "Skylight") {
          // types.map((val) => {
          // .map((pricing) =>{
          data.map((val) => {
            for (let i = 0; i < val.qty; i++) {
              let original_list = {
                qty: 1,
              };
              materialSectionData.push({
                // data: pricing,
                types: "",
                types_array: types,
                series,
                seriesType: val.series,
                qty: 1,
                order,
                total: val.total,
                original_list: original_list,
              });
            }
          });
          // );
          // });
        } else {
          data.map((pricing) => {
            let original_list = {
              qty: qty,
            };
            materialSectionData.push({
              // data: pricing,
              types,
              series,
              seriesType: pricing.series,
              qty: pricing.qty,
              order,
              total: pricing.total,
              original_list: original_list,
            });
          });
        }
      }
    } else if (hasMetricData) {
      const data = metricSelection(category, hasMetricData, key);

      if (data.length > 0) {
        data.map((pricing) => {
          let original_list = {
            qty: pricing.qty,
          };
          materialSectionData.push({
            order: category.order,
            type: pricing.series,
            field: key,
            qty: pricing.qty,
            uom: pricing.uom,
            uomPrice: pricing.uomPrice,
            total: pricing.total,
            original_list: original_list,
          });
        });
      }
    }
  });
  return materialSectionData;
};

const getMetricSelectionData = (parsedMetricData, laborInitialData) => {
  let laborSectionData = {};
  laborInitialData.forEach((pricing) => {
    const { _id, difficulty, installType, workType, otherRems, removalTypes } =
      pricing;
    const key = difficulty + installType + workType + otherRems + removalTypes;

    if (laborSectionData[key]) {
      laborSectionData[key].qty = laborSectionData[key].qty + 0;
    } else {
      let additionalDetail = "";
      if (otherRems) {
        additionalDetail = getOtherRemovalType[otherRems];
      } else if (removalTypes) {
        additionalDetail = getRemovalType[removalTypes];
      }

      let qty_value = (num) => {
        let sq_num = 0;
        let num_arr = String(Number(num / 100)).split(".");
        if (Number(num_arr[1]) < 0.5) {
          sq_num = Number(num_arr[0]) + 0.5;
        } else {
          sq_num = Number(num_arr[0]) + 1;
        }
        return sq_num;
      };
      let qty;

      if (workType !== "003") {
        qty =
          getInstallType[installType] === "Architechtural" &&
          getDifficulty[difficulty] === "Non-Walkie"
            ? parsedMetricData.hideData["architechtural_area_nw"] / 100
            : getInstallType[installType] === "3-tab" &&
              getDifficulty[difficulty] === "Non-Walkie"
            ? parsedMetricData.hideData["tab_area_nw"] / 100
            : getInstallType[installType] === "Designer" &&
              getDifficulty[difficulty] === "Non-Walkie"
            ? parsedMetricData.hideData["designer_area_nw"] / 100
            : getInstallType[installType] === "Two Ply SA" &&
              getDifficulty[difficulty] === "Non-Walkie"
            ? parsedMetricData.hideData["two_ply_area_nw"] / 100
            : getInstallType[installType] === "Single Ply TD" &&
              getDifficulty[difficulty] === "Non-Walkie"
            ? parsedMetricData.hideData["single_ply_area_nw"] / 100
            : getInstallType[installType] === "Architechtural" &&
              getDifficulty[difficulty] === "Walkie"
            ? parsedMetricData.hideData["architechtural_area_w"] / 100
            : getInstallType[installType] === "3-tab" &&
              getDifficulty[difficulty] === "Walkie"
            ? parsedMetricData.hideData["tab_area_w"] / 100
            : getInstallType[installType] === "Designer" &&
              getDifficulty[difficulty] === "Walkie"
            ? parsedMetricData.hideData["designer_area_w"] / 100
            : getInstallType[installType] === "Two Ply SA" &&
              getDifficulty[difficulty] === "Walkie"
            ? parsedMetricData.hideData["two_ply_area_w"] / 100
            : getInstallType[installType] === "Single Ply TD" &&
              getDifficulty[difficulty] === "Walkie"
            ? parsedMetricData.hideData["single_ply_area_w"] / 100
            : getInstallType[installType] === "Architechtural" &&
              getDifficulty[difficulty] === "Flat"
            ? parsedMetricData.hideData["architechtural_area_flat"] / 100
            : getInstallType[installType] === "3-tab" &&
              getDifficulty[difficulty] === "Flat"
            ? parsedMetricData.hideData["tab_area_flat"] / 100
            : getInstallType[installType] === "Designer" &&
              getDifficulty[difficulty] === "Flat"
            ? parsedMetricData.hideData["designer_area_flat"] / 100
            : getInstallType[installType] === "Two Ply SA" &&
              getDifficulty[difficulty] === "Flat"
            ? parsedMetricData.hideData["two_ply_area_flat"] / 100
            : getInstallType[installType] === "Single Ply TD" &&
              getDifficulty[difficulty] === "Flat"
            ? parsedMetricData.hideData["single_ply_area_flat"] / 100
            : 0;
      } else {
        qty =
          additionalDetail === "Plywood Rip"
            ? Math.ceil(parsedMetricData.hideData["plywood_rip_area"] / 100)
            : additionalDetail === "Cedar Rip"
            ? Math.ceil(parsedMetricData.hideData["cedar_rip_area"] / 100)
            : installType === "001" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["tab_flat_shingle_slr"] / 100
            : installType === "001" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["tab_flat_shingle_tlr"] / 100
            : installType === "001" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["tab_flat_shingle_thlr"] / 100
            : installType === "001" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["tab_flat_flat_slr"] / 100
            : installType === "001" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["tab_flat_flat_tlr"] / 100
            : installType === "001" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["tab_flat_flat_thlr"] / 100
            : installType === "001" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["tab_nw_shingle_slr"] / 100
            : installType === "001" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["tab_nw_shingle_tlr"] / 100
            : installType === "001" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["tab_nw_shingle_thlr"] / 100
            : installType === "001" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["tab_nw_flat_slr"] / 100
            : installType === "001" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["tab_nw_flat_tlr"] / 100
            : installType === "001" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["tab_nw_flat_thlr"] / 100
            : installType === "001" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["tab_w_shingle_slr"] / 100
            : installType === "001" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["tab_w_shingle_tlr"] / 100
            : installType === "001" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["tab_w_shingle_thlr"] / 100
            : installType === "001" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["tab_w_flat_slr"] / 100
            : installType === "001" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["tab_w_flat_tlr"] / 100
            : installType === "001" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["tab_w_flat_thlr"] / 100
            : installType === "002" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["arch_flat_shingle_slr"] / 100
            : installType === "002" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["arch_flat_shingle_tlr"] / 100
            : installType === "002" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["arch_flat_shingle_thlr"] / 100
            : installType === "002" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["arch_flat_flat_slr"] / 100
            : installType === "002" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["arch_flat_flat_tlr"] / 100
            : installType === "002" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["arch_flat_flat_thlr"] / 100
            : installType === "002" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["arch_nw_shingle_slr"] / 100
            : installType === "002" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["arch_nw_shingle_tlr"] / 100
            : installType === "002" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["arch_nw_shingle_thlr"] / 100
            : installType === "002" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["arch_nw_flat_slr"] / 100
            : installType === "002" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["arch_nw_flat_tlr"] / 100
            : installType === "002" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["arch_nw_flat_thlr"] / 100
            : installType === "002" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["arch_w_shingle_slr"] / 100
            : installType === "002" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["arch_w_shingle_tlr"] / 100
            : installType === "002" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["arch_w_shingle_thlr"] / 100
            : installType === "002" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["arch_w_flat_slr"] / 100
            : installType === "002" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["arch_w_flat_tlr"] / 100
            : installType === "002" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["arch_w_flat_thlr"] / 100
            : installType === "003" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["designer_flat_shingle_slr"] / 100
            : installType === "003" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["designer_flat_shingle_tlr"] / 100
            : installType === "003" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["designer_flat_shingle_thlr"] / 100
            : installType === "003" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["designer_flat_flat_slr"] / 100
            : installType === "003" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["designer_flat_flat_tlr"] / 100
            : installType === "003" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["designer_flat_flat_thlr"] / 100
            : installType === "003" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["designer_nw_shingle_slr"] / 100
            : installType === "003" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["designer_nw_shingle_tlr"] / 100
            : installType === "003" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["designer_nw_shingle_thlr"] / 100
            : installType === "003" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["designer_nw_flat_slr"] / 100
            : installType === "003" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["designer_nw_flat_tlr"] / 100
            : installType === "003" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["designer_nw_flat_thlr"] / 100
            : installType === "003" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["designer_w_shingle_slr"] / 100
            : installType === "003" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["designer_w_shingle_tlr"] / 100
            : installType === "003" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["designer_w_shingle_thlr"] / 100
            : installType === "003" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["designer_w_flat_slr"] / 100
            : installType === "003" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["designer_w_flat_tlr"] / 100
            : installType === "003" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["designer_w_flat_thlr"] / 100 //
            : installType === "006" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["sp_flat_shingle_slr"] / 100
            : installType === "006" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["sp_flat_shingle_tlr"] / 100
            : installType === "006" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["sp_flat_shingle_thlr"] / 100
            : installType === "006" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["sp_flat_flat_slr"] / 100
            : installType === "006" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["sp_flat_flat_tlr"] / 100
            : installType === "006" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["sp_flat_flat_thlr"] / 100
            : installType === "006" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["sp_nw_shingle_slr"] / 100
            : installType === "006" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["sp_nw_shingle_tlr"] / 100
            : installType === "006" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["sp_nw_shingle_thlr"] / 100
            : installType === "006" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["sp_nw_flat_slr"] / 100
            : installType === "006" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["sp_nw_flat_tlr"] / 100
            : installType === "006" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["sp_nw_flat_thlr"] / 100
            : installType === "006" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["sp_w_shingle_slr"] / 100
            : installType === "006" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["sp_w_shingle_tlr"] / 100
            : installType === "006" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["sp_w_shingle_thlr"] / 100
            : installType === "006" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["sp_w_flat_slr"] / 100
            : installType === "006" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["sp_w_flat_tlr"] / 100
            : installType === "006" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["sp_w_flat_thlr"] / 100 ////////////////////
            : installType === "007" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["tp_flat_shingle_slr"] / 100
            : installType === "007" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["tp_flat_shingle_tlr"] / 100
            : installType === "007" &&
              difficulty === "001" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["tp_flat_shingle_thlr"] / 100
            : installType === "007" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["tp_flat_flat_slr"] / 100
            : installType === "007" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["tp_flat_flat_tlr"] / 100
            : installType === "007" &&
              difficulty === "001" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["tp_flat_flat_thlr"] / 100
            : installType === "007" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["tp_nw_shingle_slr"] / 100
            : installType === "007" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["tp_nw_shingle_tlr"] / 100
            : installType === "007" &&
              difficulty === "002" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["tp_nw_shingle_thlr"] / 100
            : installType === "007" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["tp_nw_flat_slr"] / 100
            : installType === "007" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["tp_nw_flat_tlr"] / 100
            : installType === "007" &&
              difficulty === "002" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["tp_nw_flat_thlr"] / 100
            : installType === "007" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "001"
            ? parsedMetricData.hideData["tp_w_shingle_slr"] / 100
            : installType === "007" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "002"
            ? parsedMetricData.hideData["tp_w_shingle_tlr"] / 100
            : installType === "007" &&
              difficulty === "003" &&
              removalTypes === "001" &&
              otherRems === "003"
            ? parsedMetricData.hideData["tp_w_shingle_thlr"] / 100
            : installType === "007" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "001"
            ? parsedMetricData.hideData["tp_w_flat_slr"] / 100
            : installType === "007" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "002"
            ? parsedMetricData.hideData["tp_w_flat_tlr"] / 100
            : installType === "007" &&
              difficulty === "003" &&
              removalTypes === "002" &&
              otherRems === "003"
            ? parsedMetricData.hideData["tp_w_flat_thlr"] / 100
            : 0;
      }

      let original_list = {
        qty: qty,
      };

      laborSectionData[key] = {
        _id,
        field: getDifficulty[difficulty],
        type:
          getWorkType[workType] === "New"
            ? "New Construction"
            : getWorkType[workType],
        subType: getInstallType[installType],
        additionalDetail,
        qty: qty,
        uom: LABORDATA_UOM,
        uomPrice: Number(0).toFixed(2),
        total: Number(0).toFixed(2),
        original_list: original_list,
      };
    }
  });
  return Object.values(laborSectionData);
};

const checkData = async (parsedMetricData, supplierId, companyId) => {
  let allPromises = [];

  const { totals } = parsedMetricData;
  const allTotalData = totals;

  CATEGORYDATA_LABOR.forEach(async (category) => {
    const { fields, totalKey, fieldValue, series, order, types } = category;
    const totalValue = totalKey ? allTotalData[totalKey] : false;

    if (totalValue) {
      let query = { supplierId, companyId };
      query = {
        [fields[0]]: fieldValue[0],
      };
      query = fields[1]
        ? {
            ...query,
            [fields[1]]: fieldValue[1],
          }
        : query;
      query = fields[2]
        ? {
            ...query,
            [fields[2]]: fieldValue[2],
          }
        : query;

      const laborPricing = await laborPricingCompany.find(query);
      const pricing = laborPricing[0];
      allPromises.push({
        data: pricing,
        field: fields[0],
        qtyValue: totalValue,
        order: order,
      });
    }
  });

  return Promise.all(allPromises);
};

const get_metric_selected_data_with_pricing = async (metricSelectedData) => {
  const newList = await metricSelectedData.map(async (val) => {
    let obj;
    if (val.field !== "Flat" && val.additionalDetail === "") {
      obj = {
        roofPitch: val.field,
        workType: val.type === "New" ? "New Construction" : val.type,
        subType:
          val.subType === "Architechtural" ? "Architectural" : val.subType,
      };
    }
    if (val.field === "Flat" && val.additionalDetail === "") {
      obj = {
        roofPitch: val.field,
        workType: val.type,
      };
    }
    if (val.field !== "Flat" && val.additionalDetail !== "") {
      obj = {
        roofPitch: val.field,
        workType:
          val.additionalDetail === "Two Layers Rip"
            ? "2 Layer Rip"
            : val.additionalDetail === "Single Layers Rip"
            ? "1 Layer Rip"
            : val.additionalDetail === "Three Layers Rip"
            ? "3 Layer Rip"
            : val.additionalDetail,
        subType:
          val.subType === "Architechtural" ? "Architectural" : val.subType,
      };
    }
    if (val.field === "Flat" && val.additionalDetail !== "") {
      obj = {
        roofPitch: val.field,
        workType:
          val.additionalDetail === "Two Layers Rip"
            ? "2 Layer Rip"
            : val.additionalDetail === "Single Layers Rip"
            ? "1 Layer Rip"
            : val.additionalDetail === "Three Layers Rip"
            ? "3 Layer Rip"
            : val.additionalDetail,
      };
    }

    const laborPricing = await laborPricingCompany.find(obj);
    const uom_price = laborPricing[0];

    if (uom_price) {
      val.uomPrice = Number(uom_price.uomPrice).toFixed(2);
      val.total = Number(Number(uom_price.uomPrice) * Number(val.qty)).toFixed(
        2
      );
    }

    return val;
  });
  return Promise.all(newList);
};

const getPricingData = async (data, field, qtyValue, order) => {
  let allPricings = [];
  const total = Number(data.uomPrice * qtyValue).toFixed(2);
  let original_list = {
    qty: qtyValue,
  };
  const requiredData = {
    order,
    _id: data._id,
    uomPrice: Number(data.uomPrice).toFixed(2),
    subType: "",
    type: "",
    qty: qtyValue,
    uom: data.uom,
    total,
    original_list: original_list,
  };

  let newPricing = data.series
    ? {
        ...requiredData,
        series: data.series,
        types: data.types,
        type: "",
      }
    : { ...requiredData, field: data[field] };
  allPricings.push(newPricing);
  return allPricings;
};

const getParsedPricings = async (parsedMetricData, supplierId, companyId) => {
  let data = [];
  const pricings = await checkData(parsedMetricData, supplierId, companyId);

  const allData = await pricings.map(async (pricing) => {
    if (pricing) {
      // const { data, field, qtyValue, order } = pricing;
      const pricingsData = await pricing.data;
      const reqPricing = await getPricingData(
        pricingsData,
        pricing.field,
        pricing.qtyValue,
        pricing.order
      );

      data = [...data, ...reqPricing];
      // return reqPricing;
    }
  });
  await Promise.all(allData);
  return data;
};
export const laborSectionAllData = async (
  metricId,
  supplierId,
  companyId,
  excludedBuildings,
  excludedSections
) => {
  const metricDataTemp = getRoofMetrics(metricId); //getData(`metrics${metricId}`);
  let metricData = [];
  if (metricDataTemp !== null) {
    metricData = [metricDataTemp.data];
  }


  if (metricData.length === 0) return [];

  const parsedMetricData = await parseMetricData(
    metricData[0],
    excludedBuildings,
    excludedSections
  );

  const specificSelectedMetric = await getSpecificSelectedMetric(
    parsedMetricData
  );

  const metricSelectedData = await getMetricSelectionData(
    parsedMetricData,
    parsedMetricData.laborInitialData
  );

  const metric_selected_data_with_pricing =
    await get_metric_selected_data_with_pricing(metricSelectedData);

  let data = await getParsedPricings(parsedMetricData, supplierId, companyId);
  data = data.sort((a, b) => a.order - b.order);
  return [
    ...metric_selected_data_with_pricing,
    ...data,
    ...specificSelectedMetric,
  ];
};
