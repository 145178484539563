import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import MaskedInput from "react-maskedinput";

import { uuid } from "uuidv4";

export default function PersonnelFields({
  idV = "",
  firstV = "",
  lastV = "",
  emailV = "",
  phoneV = "",
  posV = "",
  saveData,
  deleteData,
  posOptions,

  editData = false,
  disabled = false,
  editValue,
  setView,
  emailValue,
  add,
}) {
  const [first, setFirst] = useState(firstV);
  const [last, setLast] = useState(lastV);
  const [email, setEmail] = useState(emailV);
  const [phone, setPhone] = useState(phoneV);
  const [pos, setPos] = useState(posV);
  const [edit, setEdit] = useState(editData);
  const [id, setId] = useState(idV || uuid());

  const handleChange = (e, callback) => {
    // setEdit(false);

    callback(e.target.value);
  };

  const displayPos = (types) => {
    const filterData = types?.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return filterData?.map((item) => (
      <option key={item?._id} value={item?._id}>
        {item?.personelPosition}
      </option>
    ));
  };

  const toggleEdit = (value) => {
    setEdit(value);
  };

  const handleSave = () => {
    saveData({
      first,
      last,
      email,
      phone,
      pos,
      id,
    });
    toggleEdit(false);
  };
  return (
    <tr>
      <td style={add ? { width: "5%" } : {}}>
        {!edit && (
          <Button
            variant="secondary"
            onClick={() => toggleEdit(true)}
            disabled={disabled}
            style={{ marginRight: "3px" }}
          >
            Edit
          </Button>
        )}
      </td>
      <td>
        <div style={{ marginRight: "5%", marginBottom: "5%" }}>
          <Form.Group>
            <Form.Control
              as="select"
              name="pos"
              value={pos}
              disabled={!edit || disabled}
              onChange={(event) => handleChange(event, setPos)}
            >
              <option value="">Choose...</option>
              {displayPos(posOptions)}
            </Form.Control>
          </Form.Group>
        </div>
      </td>

      <td>
        <div style={{ marginRight: "5%" }}>
          <Form.Group>
            <Form.Control
              type="text"
              name="first"
              disabled={!edit || disabled}
              value={first}
              onChange={(event) => handleChange(event, setFirst)}
            />
          </Form.Group>
        </div>
      </td>
      <td>
        <div style={{ marginRight: "5%" }}>
          <Form.Group>
            <Form.Control
              type="text"
              name="last"
              disabled={!edit || disabled}
              value={last}
              onChange={(event) => handleChange(event, setLast)}
            />
          </Form.Group>
        </div>
      </td>
      <td>
        <div style={{ marginRight: "5%" }}>
          <Form.Group>
            <InputGroup>
              <MaskedInput
                className="form-control"
                mask="(111) 111-1111"
                type="text"
                name="phone"
                disabled={!edit || disabled}
                value={phone}
                placeholder=""
                required="required"
                maxLength={10}
                onChange={(event) => handleChange(event, setPhone)}
              />
            </InputGroup>
          </Form.Group>
        </div>
      </td>
      <td>
        <div style={{ marginRight: "5%" }}>
          <Form.Group>
            <Form.Control
              type="text"
              name="email"
              disabled={!edit || disabled}
              value={email}
              onChange={(event) => handleChange(event, setEmail)}
            />
          </Form.Group>
        </div>
      </td>
      {edit && (
        <td>
          <Button
            style={{ marginRight: "3px", marginLeft: "3px" }}
            onClick={() => handleSave()}
            variant="success"
            disabled={!pos || !first || !last || disabled}
          >
            Done
          </Button>
          <Button variant="danger" onClick={() => deleteData(id)}>
            Delete
          </Button>
        </td>
      )}
    </tr>
  );
}
