import React, { useState } from "react";
import "./ImageCarasoul.scss";
import styles from "./image.module.css";
import Next from "../../assets/right.svg";
import Prev from "../../assets/left.svg";
import { Button } from "react-bootstrap";
import Switch from "react-switch";
import LazyLoad from "react-lazyload";
import { ImCross } from "react-icons/im";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";

export default function ImageCarasoul({
  activeIdx,
  handleChange,
  images,
  openDeleteModal,
  setRowAndCol,
  coverImage,
  isQuoteLock = false,
}) {
  const [active, setActive] = useState(activeIdx ? activeIdx : 0);
  const [click, setClick] = useState(false);

  const setFlag = () => {
    setClick(true);
  };

  const unsetFlag = () => {
    setClick(false);
  };

  const moveRight = () => {
    setActive(active + 1);
  };
  const moveLeft = () => {
    setActive(active - 1);
  };

  const showImages = () => {
    return images.map((img, i) => {
      if (i === active) {
        return (
          <div key={`${img.row}/${img.col}`}>
            <div className="ref-pic">
              <div style={{ margin: "0 1em 1em 0", lineHeight: ".9em" }}>
                <div>Cover Photo</div>
                <div style={{ fontSize: "12px", fontWeight: "300" }}>
                  Use for project management.
                </div>
              </div>
              <Switch
                onChange={(checked) => handleChange(checked, img.uri)}
                checked={img.uri === coverImage()}
                className="react-switch"
              />
            </div>
            <LazyLoad height={200} once>
              {click ? (
                <div className={`${styles.lightbox} ${styles.show} relative`}>
                  <div className="slides-btns" style={{ maxWidth: "100%" }}>
                    <button
                      className="move"
                      aria-label="Prev"
                      disabled={active === 0}
                      onClick={() => moveLeft()}
                    >
                      {/* <img src={Prev} alt="Prev" /> */}
                      <AiOutlineLeft size={60} style={{ color: "white" }} />
                    </button>

                    <img
                      src={img.uri}
                      alt=""
                      className={`${styles.show_image}`}
                    />
                    <span style={{ position: "relative", bottom: "270px" }}>
                      <ImCross
                        size={30}
                        style={{ color: "white", cursor: "pointer" }}
                        onClick={unsetFlag}
                      />
                    </span>
                    <button
                      className="move"
                      aria-label="Next"
                      disabled={active === images.length - 1}
                      onClick={() => moveRight()}
                      style={{ color: "white" }}
                    >
                      <AiOutlineRight size={60} style={{ color: "white" }} />
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <img
                    onClick={setFlag}
                    src={`${img.uri}`}
                    alt=""
                  ></img>
                </>
              )}
            </LazyLoad>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const openModal = () => {
    const img = images[activeIdx];
    setRowAndCol(img.section, img.idx);
    openDeleteModal();
  };

  return (
    <div className="slides">
      <div className="img">{showImages(images)}</div>
      <div className="slides-btns">
        <button
          className="move"
          aria-label="Prev"
          disabled={active === 0}
          onClick={() => moveLeft()}
        >
          <img src={Prev} alt="Prev" />
        </button>
        <Button
          variant="danger"
          onClick={() => openModal()}
          disabled={isQuoteLock}
        >
          Delete Image
        </Button>
        <button
          className="move"
          aria-label="Next"
          disabled={active === images.length - 1}
          onClick={() => moveRight()}
        >
          <img src={Next} alt="Next" />
        </button>
      </div>
    </div>
  );
}
