import { getRoofMetrics } from "services/offlineComputing/models/metricsModel";
import quoteModel from "services/offlineComputing/models/quote";
import quoteDebrisModel from "services/offlineComputing/models/quoteDebris";
import quoteGuttersModel from "services/offlineComputing/models/quoteGutters";
import quoteLaborModel from "services/offlineComputing/models/quoteLabor";
import quoteMaterialModel from "services/offlineComputing/models/quoteMaterial";
import { getProposalTextData } from "services/offlineComputing/services/generateProposal";

export const createNewProposal = async ({
  metricId,
  companyId,
  userId,
  excludedSections = [],
  excludedBuildings = [],
  quoteName,
}) => {
  const requestId = metricId;
  const metricData = getRoofMetrics(metricId); //await getData(`metrics${metricId}`);

  if (metricData === undefined) {
    throw new Error("Metric Data is not available.");
  }
  //here
  const proposalTexts = await getProposalTextData({
    requestId,
    excludedBuildings,
    excludedSections,
    companyId,
  });

  //   let original_quote_proposal_tax = await get_original_quote_proposal_tax(
  //     metricId,
  //     companyId,
  //     userId
  //   );

  let newData;
  //   if (original_quote_proposal_tax.length == 0) {
  newData = {
    requestId,
    companyId,
    proposalsNotSent: true,
    proposalsSent: false,
    makeContract: false,
    userId,
    excludedBuildings,
    excludedSections,
    ...proposalTexts,
    total: 0,
    //stateTax: 0,
  };
  //   } else {
  //     newData = {
  //       requestId,
  //       companyId,
  //       userId,
  //       excludedBuildings,
  //       excludedSections,
  //       ...proposalTexts,
  //       stateTax: original_quote_proposal_tax[0].stateTax,
  //       total: original_quote_proposal_tax[0].total,
  //     };
  //   }

  newData = quoteName
    ? {
        ...newData,
        quoteName,
      }
    : {
        ...newData,
        quoteName: "As-Collected",
      };

  //const markUpData = await getQuoteMarkupByCompanyIdAsync(companyId);
  const markUpData = { standard: 33 };
  newData.markUp = markUpData.standard;

  newData._id = await quoteModel.save(newData);

  const { markUp } = newData;

  //calculate id and save

  return [
    {
      quoteName: newData.quoteName,
      markUp,
      proposalsNotSent: true,
      proposalsSent: false,
      makeContract: false,
      _id: newData._id, //this id will be used for later synchronizing
      excludedBuildings,
      excludedSections,
      ...proposalTexts,
    },
  ];
};

export const deleteQuote = async (props) => {
  const { quoteId, companyId, _id } = props;

  const queryForQuote = {
    _id: quoteId,
  };
  const quoteData = await quoteModel.find(queryForQuote);

  const hasNoQuote = (quoteData && quoteData.length === 0) || !quoteData;

  if (hasNoQuote) {
    throw new Error("No quote data available.");
  }

  const queryForSection = {
    proposalId: quoteId,
    companyId,
  };

  await quoteLaborModel.deleteMany(queryForSection);
  await quoteMaterialModel.deleteMany(queryForSection);
  await quoteGuttersModel.deleteMany(queryForSection);
  await quoteDebrisModel.deleteMany(queryForSection);
  const resData = await quoteModel.deleteMany(queryForQuote);

  const deleteUnsuccessful = !resData;
  if (deleteUnsuccessful) {
    throw new Error("Error while deleting the quote");
  }

  return {
    message: "Quote has been successfully deleted",
  };
};

export const checkProposal = async (props) => {
  const { metricId, companyId, _id } = props;

  const responseDataDb = await quoteModel.find({
    requestId: metricId,
  });

  return responseDataDb;
};
export const addNewProposal = async (props) => {
  const {
    quoteName,
    excludedBuildings,
    excludedSections,
    metricId,
    companyId,
    _id,
  } = props;

  const quoteData = await createNewProposal({
    metricId,
    companyId,
    userId: _id,
    excludedSections,
    excludedBuildings,
    quoteName,
  });

  return quoteData;
};

export const editProposal = async (props) => {
  const {
    _id,
    quoteName,
    fixedStandardStatements,
    markUp,
    excludedSections,
    excludedBuildings,
    otherTexts,
    inserted_data,
    companyId,
    userId,
  } = props;

  let data = {
    quoteName,
    markUp,
    fixedStandardStatements,
    excludedSections,
    excludedBuildings,
    otherTexts,
    inserted_data,
  };

  const resData = await quoteModel.updateOne(
    {
      _id,
    },
    data
  );

  return {
    quoteName,
    markUp,
    _id,
    excludedSections,
    excludedBuildings,
  };
};
export const recalculate_unlocked_quotes = async (props) => {
  const { metricId } = props;

  const responseDataDb = await quoteModel.find({
    requestId: metricId,
  });

  let updating_ids = [];
  if (responseDataDb && responseDataDb.length > 0) {
    updating_ids = responseDataDb.filter((value) => {
      return !value.isLocked;
    });
    updating_ids = updating_ids.map((value) => {
      return value._id;
    });
  }

  const newQuoteData = await calculate_quote({
    metricId,
    companyId,
    userId: _id,
  });

  const { fixedStandardStatements, otherTexts, markUp } = newQuoteData;
  const all_quote = await quoteModel.find({
    isLocked: false,
    requestId: metricId,
  });
  all_quote.map((value) => {
    quoteModel.updateOne(
      { _id: value._id },
      {
        fixedStandardStatements,
        otherTexts,
        markUp,
        metricChangeTime: new Date(),
      }
    );
  });

  await quoteModel.updateMany(
    { isLocked: false },
    {
      fixedStandardStatements,
      otherTexts,
      markUp,
      metricChangeTime: new Date(),
    }
  );

  return helpers.resSuccess(res, newQuoteData);
};

const calculate_quote = async ({
  metricId,
  companyId,
  userId,
  excludedSections = [],
  excludedBuildings = [],
  quoteName,
}) => {
  const requestId = metricId;
  const metricData = getRoofMetrics(metricId);

  if (metricData.length === 0) {
    throw new Error("Metric Data is not available.");
  }
  //here
  const proposalTexts = await getProposalTextData({
    requestId,
    excludedBuildings,
    excludedSections,
    companyId,
  });

  // let original_quote_proposal_tax = await get_original_quote_proposal_tax(
  //   metricId,
  //   companyId,
  //   userId
  // );

  let newData;
  // if (original_quote_proposal_tax.length == 0) {
  newData = {
    requestId,
    companyId,
    userId,
    excludedBuildings,
    excludedSections,
    ...proposalTexts,
  };
  // }
  //  else {
  //   newData = {
  //     requestId,
  //     companyId,
  //     userId,
  //     excludedBuildings,
  //     excludedSections,
  //     ...proposalTexts,
  //     stateTax: original_quote_proposal_tax[0].stateTax,
  //     total: original_quote_proposal_tax[0].total,
  //   };
  // }

  newData = quoteName
    ? {
        ...newData,
        quoteName,
      }
    : {
        ...newData,
      };

  // const markUpData = await getQuoteMarkupByCompanyIdAsync(companyId);
  newData.markUp = 33;

  return newData;
};
