import baseSettings from "lib/config/baseSettings";
import { axiosInstance as axios } from "services";

export const appURL = "https://organizedroofer.net";

export function getEndPoints(mode = "DEV") {
  let endPoint = "";
  if (mode === "UAT") {
    endPoint = "http://xxx.xxx.XXX.XXX";
  } else if (mode === "PROD") {
    endPoint = "http://xxx.xxx.XXX.XXX";
  } else {
    endPoint = baseSettings.baseUrl;
  }
  return endPoint;
}




export function setAuthHeaders(auth) {
  axios.defaults.headers.common.Authorization = auth;
}
export function getAllMaterialPersonnel(endPoint) {
  return axios.get(`${endPoint}`);
}
export function getAllSupplierPersonnel(endPoint) {
  return axios.get(`${endPoint}`);
}
export function getAllCartingPersonnel(endPoint) {
  return axios.get(`${endPoint}`);
}
export function getAllScreen(endPoint) {
  return axios.get(`${endPoint}`);
}
