import { axiosInstance as axios } from "services";
import { useState } from "react";
import { getData } from "services/storageManager";
import { getEndPoints } from "api/api";
import { ApiEndpoints } from "lib/config/baseSettings";
// import { GET_STAGE_BEFORE_DATA } from "utils/utils";
const url = getEndPoints(process.env.REACT_APP_MODE);
const { GET_STAGE_BEFORE_DATA } = ApiEndpoints.SCHEDULE_MODAL;

const useActiveSales = () => {
  const [stageBeforeData, setStageBeforeData] = useState([]);
  const getStageBeforeData = (body) => {
    if (!navigator.onLine) {
      let stored_data = getData("past" + body.stageId);
      if (stored_data === null) {
        setStageBeforeData([]);
      } else {
        setStageBeforeData(stored_data.data.stageData.data);
      }

      return { error: null };
    } else {
      return axios
        .post(`${url}${GET_STAGE_BEFORE_DATA}`, body)

        .then((response) => {
          if (response.data.success) {
            setStageBeforeData(response?.data?.result?.stageData?.data);
            return { error: null, response };
          }
        })
        .catch((error) => {
          if (error.response) {
            return { error: error.response };
          }
        });
    }
  };
  return {
    getStageBeforeData,
    stageBeforeData,
  };
};
export default useActiveSales;
