const styles = {
  newCol: {
    flex: "0 0 12%",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  reduceWidthCol: {
    paddingLeft: "2px",
    paddingRight: "2px",
    marginTop: "1%",
  },
};

export default styles;
