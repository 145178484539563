"use strict";
/**
 *@description constant data
 *@author Tilak
 *@since Mar 12, 2020
 */


const ELEVATION = [
  { id: "001", name: "Front" },
  { id: "002", name: "Back" },
  { id: "003", name: "Left" },
  { id: "004", name: "Right" },
];
const ELEVATIONDETAILS = [
  { id: "001", name: "Main House" },
  { id: "002", name: "Garage" },
  { id: "003", name: "Barn" },
  { id: "004", name: "GST/HSE" },
  { id: "005", name: "Cabana" },
];

const DIFFICULTY = [
  { id: "001", name: "Flat" },
  { id: "002", name: "Non-Walkie" },
  { id: "003", name: "Walkie" },
];

const getDifficulty = {};
getDifficulty[`${DIFFICULTY[0].id}`] = `${DIFFICULTY[0].name}`;
getDifficulty[`${DIFFICULTY[1].id}`] = `${DIFFICULTY[1].name}`;
getDifficulty[`${DIFFICULTY[2].id}`] = `${DIFFICULTY[2].name}`;

const WORKTYPE = [
  { id: "001", name: "New" },
  { id: "002", name: "Overlay" },
  { id: "003", name: "Remove" },
];

const getWorkType = {};
getWorkType[`${WORKTYPE[0].id}`] = `${WORKTYPE[0].name}`;
getWorkType[`${WORKTYPE[1].id}`] = `${WORKTYPE[1].name}`;
getWorkType[`${WORKTYPE[2].id}`] = `${WORKTYPE[2].name}`;


const METALSHEET = [
  {
    name: "Aluminum",
    id: "METALSHEET010",
  },
  {
    name: "Copper",
    id: "METALSHEET011",
  },
];

const METALSHEETDATA = {
  METALSHEET010: [
    [
      {
        name: ".032 (4x8)",
        id: "METALSHEET000",
      },
      {
        name: ".032 (4x10)",
        id: "METALSHEET001",
      },
      {
        name: ".040 (4x8)",
        id: "METALSHEET002",
      },
      {
        name: ".040 (4x10)",
        id: "METALSHEET003",
      },
    ],
  ],
  METALSHEET011: [
    [
      {
        name: "3 x 8 16oz",
        id: "METALSHEET004",
      },
      {
        name: "3 x 10 16oz",
        id: "METALSHEET005",
      },
      {
        name: "3 x 8 32oz",
        id: "METALSHEET006",
      },
      {
        name: "3 x 10 32oz",
        id: "METALSHEET007",
      },
    ],
  ],
};

const getMetalSheet = {};
getMetalSheet[`${METALSHEET[0].id}`] = `${METALSHEET[0].name}`;
getMetalSheet[`${METALSHEET[1].id}`] = `${METALSHEET[1].name}`;

// getMetalSheet[`${DIMENSIONNAME[0].id}`] = `${DIMENSIONNAME[0].name}`;
// getMetalSheet[`${DIMENSIONNAME[1].id}`] = `${DIMENSIONNAME[1].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET010"][0][0].id}`
] = `${METALSHEETDATA["METALSHEET010"][0][0].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET010"][0][1].id}`
] = `${METALSHEETDATA["METALSHEET010"][0][1].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET010"][0][2].id}`
] = `${METALSHEETDATA["METALSHEET010"][0][2].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET010"][0][3].id}`
] = `${METALSHEETDATA["METALSHEET010"][0][3].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET011"][0][0].id}`
] = `${METALSHEETDATA["METALSHEET011"][0][0].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET011"][0][1].id}`
] = `${METALSHEETDATA["METALSHEET011"][0][1].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET011"][0][2].id}`
] = `${METALSHEETDATA["METALSHEET011"][0][2].name}`;

getMetalSheet[
  `${METALSHEETDATA["METALSHEET011"][0][3].id}`
] = `${METALSHEETDATA["METALSHEET011"][0][3].name}`;

const COMMONNAILS = [
  [
    {
      name: "Steel",
      id: "COMMONNAILS001",
    },
    {
      name: "Galvanized",
      id: "COMMONNAILS002",
    },
  ],
  [
    {
      name: `6D 2"`,
      id: "CN003",
    },
    {
      name: `8D 2.5"`,
      id: "CN004",
    },
    {
      name: `10D 3"`,
      id: "CN005",
    },
    {
      name: `16D 3.5"`,
      id: "CN006",
    },
  ],
];

const getCommonNails = {};
getCommonNails[`${COMMONNAILS[0][0].id}`] = `${COMMONNAILS[0][0].name}`;
getCommonNails[`${COMMONNAILS[0][1].id}`] = `${COMMONNAILS[0][1].name}`;
getCommonNails[`${COMMONNAILS[1][0].id}`] = `${COMMONNAILS[1][0].name}`;
getCommonNails[`${COMMONNAILS[1][1].id}`] = `${COMMONNAILS[1][1].name}`;
getCommonNails[`${COMMONNAILS[1][2].id}`] = `${COMMONNAILS[1][2].name}`;
getCommonNails[`${COMMONNAILS[1][3].id}`] = `${COMMONNAILS[1][3].name}`;

const HANDNAILS = [
  [
    {
      name: "Copper",
      id: "HANDNAILS001",
    },
    {
      name: "Galvanized",
      id: "HANDNAILS002",
    },
    {
      name: "Stainless",
      id: "HANDNAILS003",
    },
  ],
  [
    {
      name: `1"`,
      id: "HANDNAILS004",
    },
    {
      name: `1.25"`,
      id: "HANDNAILS005",
    },
    {
      name: `1.5"`,
      id: "HANDNAILS006",
    },
    {
      name: `1.75"`,
      id: "HANDNAILS007",
    },
    {
      name: `2"`,
      id: "HANDNAILS008",
    },
  ],
];

const getHandsNails = {};
getHandsNails[`${HANDNAILS[0][0].id}`] = `${HANDNAILS[0][0].name}`;
getHandsNails[`${HANDNAILS[0][1].id}`] = `${HANDNAILS[0][1].name}`;
getHandsNails[`${HANDNAILS[0][2].id}`] = `${HANDNAILS[0][2].name}`;

getHandsNails[`${HANDNAILS[1][0].id}`] = `${HANDNAILS[1][0].name}`;
getHandsNails[`${HANDNAILS[1][1].id}`] = `${HANDNAILS[1][1].name}`;
getHandsNails[`${HANDNAILS[1][2].id}`] = `${HANDNAILS[1][2].name}`;
getHandsNails[`${HANDNAILS[1][3].id}`] = `${HANDNAILS[1][3].name}`;
getHandsNails[`${HANDNAILS[1][4].id}`] = `${HANDNAILS[1][4].name}`;

const CHIMENYCAPS = [
  [
    {
      name: "Round",
      id: "CHIMENYCAPS001",
      other: "inches",
    },
    {
      name: "Square",
      id: "CHIMENYCAPS002",
      other: "!inches",
    },
    {
      name: "Rectangle",
      id: "CHIMENYCAPS003",
      other: "!inches",
    },
  ],
  [
    {
      name: "Aluminum",
      id: "CHIMENYCAPS004",
    },
    {
      name: "Copper",
      id: "CHIMENYCAPS005",
    },
    {
      name: "Galvanized",
      id: "CHIMENYCAPS006",
    },
    {
      name: "Stainless",
      id: "CHIMENYCAPS007",
    },
  ],
];

const getChimneyCaps = {};
getChimneyCaps[`${CHIMENYCAPS[0][0].id}`] = `${CHIMENYCAPS[0][0].name}`;
getChimneyCaps[`${CHIMENYCAPS[0][1].id}`] = `${CHIMENYCAPS[0][1].name}`;
getChimneyCaps[`${CHIMENYCAPS[0][2].id}`] = `${CHIMENYCAPS[0][2].name}`;
getChimneyCaps[`${CHIMENYCAPS[1][0].id}`] = `${CHIMENYCAPS[1][0].name}`;
getChimneyCaps[`${CHIMENYCAPS[1][1].id}`] = `${CHIMENYCAPS[1][1].name}`;
getChimneyCaps[`${CHIMENYCAPS[1][2].id}`] = `${CHIMENYCAPS[1][2].name}`;
getChimneyCaps[`${CHIMENYCAPS[1][3].id}`] = `${CHIMENYCAPS[1][3].name}`;

const METAL = [
  [
    {
      name: "Apron",
      id: "METAL001",
    },
    {
      name: "J-Bend",
      id: "METAL002",
    },
    {
      name: "Gravel Stop",
      id: "METAL003",
    },
  ],
];

const getMetalTypes = {};
getMetalTypes[`${METAL[0][0].id}`] = `${METAL[0][0].name}`;
getMetalTypes[`${METAL[0][1].id}`] = `${METAL[0][1].name}`;
getMetalTypes[`${METAL[0][2].id}`] = `${METAL[0][2].name}`;

const RAFTER = [
  [
    {
      name: "2 x 4",
      id: "RAFTERSIZE001",
    },
    {
      name: "2 x 6",
      id: "RAFTERSIZE005",
    },
    {
      name: "2 x 8",
      id: "RAFTERSIZE002",
    },
    {
      name: "2 x 10",
      id: "RAFTERSIZE003",
    },
    {
      name: "2 x 12",
      id: "RAFTERSIZE004",
    },
  ],
  [
    {
      name: `8'`,
      id: "RAFTERTYPE001",
    },
    {
      name: `10'`,
      id: "RAFTERTYPE002",
    },
    {
      name: `12'`,
      id: "RAFTERTYPE003",
    },
  ],
];

const getRafter = {};
getRafter[`${RAFTER[0][0].id}`] = `${RAFTER[0][0].name}`;
getRafter[`${RAFTER[0][1].id}`] = `${RAFTER[0][1].name}`;
getRafter[`${RAFTER[0][2].id}`] = `${RAFTER[0][2].name}`;
getRafter[`${RAFTER[0][3].id}`] = `${RAFTER[0][3].name}`;
getRafter[`${RAFTER[0][4].id}`] = `${RAFTER[0][4].name}`;
getRafter[`${RAFTER[0][4].id}`] = `${RAFTER[0][4].name}`;
getRafter[`${RAFTER[1][0].id}`] = `${RAFTER[1][0].name}`;
getRafter[`${RAFTER[1][1].id}`] = `${RAFTER[1][1].name}`;
getRafter[`${RAFTER[1][2].id}`] = `${RAFTER[1][2].name}`;

const FASCIADATA = [
  {
    name: "Wood",
    id: "FASCIA009",
  },
  {
    name: "Composite",
    id: "FASCIA010",
  },
];

const FASCIA = {
  FASCIA009: [
    [
      {
        name: "1 x 4",
        id: "FASCIA001",
      },
      {
        name: "1 x 6",
        id: "FASCIA002",
      },
      {
        name: "1 x 8",
        id: "FASCIA003",
      },
    ],
    [
      {
        name: `8'`,
        id: "FASCIA004",
      },
      {
        name: `10'`,
        id: "FASCIA005",
      },
      {
        name: `12'`,
        id: "FASCIA006",
      },
    ],
  ],
  FASCIA010: [
    [
      {
        name: "1 x 4",
        id: "FASCIA007",
      },
      {
        name: "1 x 6",
        id: "FASCIA008",
      },
      {
        name: "1 x 8",
        id: "FASCIA011",
      },
      {
        name: "1 x 12",
        id: "FASCIA012",
      },
    ],
    [
      {
        name: `12'`,
        id: "FASCIA013",
      },
      {
        name: `18'`,
        id: "FASCIA014",
      },
      {
        name: `20'`,
        id: "FASCIA015",
      },
    ],
  ],
};

const getFascia = {};
getFascia[`${FASCIADATA[0].id}`] = `${FASCIADATA[0].name}`;
getFascia[`${FASCIADATA[1].id}`] = `${FASCIADATA[1].name}`;
getFascia[
  `${FASCIA["FASCIA009"][0][0].id}`
] = `${FASCIA["FASCIA009"][0][0].name}`;
getFascia[
  `${FASCIA["FASCIA009"][0][1].id}`
] = `${FASCIA["FASCIA009"][0][1].name}`;
getFascia[
  `${FASCIA["FASCIA009"][0][2].id}`
] = `${FASCIA["FASCIA009"][0][2].name}`;
getFascia[
  `${FASCIA["FASCIA009"][1][0].id}`
] = `${FASCIA["FASCIA009"][1][0].name}`;
getFascia[
  `${FASCIA["FASCIA009"][1][1].id}`
] = `${FASCIA["FASCIA009"][1][1].name}`;
getFascia[
  `${FASCIA["FASCIA009"][1][2].id}`
] = `${FASCIA["FASCIA009"][1][2].name}`;

getFascia[
  `${FASCIA["FASCIA010"][0][0].id}`
] = `${FASCIA["FASCIA010"][0][0].name}`;
getFascia[
  `${FASCIA["FASCIA010"][0][1].id}`
] = `${FASCIA["FASCIA010"][0][1].name}`;
getFascia[
  `${FASCIA["FASCIA010"][0][2].id}`
] = `${FASCIA["FASCIA010"][0][2].name}`;
getFascia[
  `${FASCIA["FASCIA010"][0][3].id}`
] = `${FASCIA["FASCIA010"][0][3].name}`;
getFascia[
  `${FASCIA["FASCIA010"][1][0].id}`
] = `${FASCIA["FASCIA010"][1][0].name}`;
getFascia[
  `${FASCIA["FASCIA010"][1][1].id}`
] = `${FASCIA["FASCIA010"][1][1].name}`;
getFascia[
  `${FASCIA["FASCIA010"][1][2].id}`
] = `${FASCIA["FASCIA010"][1][2].name}`;

const FURRING = [
  [
    {
      name: "1 x 2 x 8",
      id: "FURRING001",
    },
    {
      name: "1 x 3 x 8",
      id: "FURRING002",
    },
    {
      name: "1 x 4 x 8",
      id: "FURRING003",
    },
  ],
];

const getFurring = {};
getFurring[`${FURRING[0][0].id}`] = `${FURRING[0][0].name}`;
getFurring[`${FURRING[0][1].id}`] = `${FURRING[0][1].name}`;
getFurring[`${FURRING[0][2].id}`] = `${FURRING[0][2].name}`;

const PLYWOODSIZE = [
  {
    name: '3/8"',
    id: "PLYWOODSIZE001",
  },
  {
    name: '1/2"',
    id: "PLYWOODSIZE002",
  },
  {
    name: '5/8"',
    id: "PLYWOODSIZE003",
  },
  {
    name: '3/4"',
    id: "PLYWOODSIZE004",
  },
];

const getPlywoodSize = {};
getPlywoodSize[`${PLYWOODSIZE[1].id}`] = `${PLYWOODSIZE[1].name}`;
getPlywoodSize[`${PLYWOODSIZE[0].id}`] = `${PLYWOODSIZE[0].name}`;
getPlywoodSize[`${PLYWOODSIZE[2].id}`] = `${PLYWOODSIZE[2].name}`;
getPlywoodSize[`${PLYWOODSIZE[3].id}`] = `${PLYWOODSIZE[3].name}`;

const PLYWOODTYPE = [
  {
    name: "CDX",
    id: "PLYWOODTYPE001",
  },
  {
    name: "OSB",
    id: "PLYWOODTYPE002",
  },
];

const getPlywoodType = {};
getPlywoodType[`${PLYWOODTYPE[0].id}`] = `${PLYWOODTYPE[0].name}`;
getPlywoodType[`${PLYWOODTYPE[1].id}`] = `${PLYWOODTYPE[1].name}`;


const RemoveTypes = [
  { id: "001", name: "Shingles" },
  { id: "002", name: "Flat" },
  { id: "003", name: "Plywood Rip" },
  { id: "004", name: "Cedar Rip" },
  { id: "005", name: "Slate Rip" },
];

const ShiTypes = [
  { id: "001", name: "Single Layers Rip" },
  { id: "002", name: "Two Layers Rip" },
  { id: "003", name: "Three Layers Rip" },
];

const getRemovalType = {};
getRemovalType[`${RemoveTypes[0].id}`] = `${RemoveTypes[0].name}`;
getRemovalType[`${RemoveTypes[1].id}`] = `${RemoveTypes[1].name}`;
getRemovalType[`${RemoveTypes[2].id}`] = `${RemoveTypes[2].name}`;
getRemovalType[`${RemoveTypes[3].id}`] = `${RemoveTypes[3].name}`;
getRemovalType[`${RemoveTypes[4].id}`] = `${RemoveTypes[4].name}`;

const getOtherRemovalType = {};
getOtherRemovalType[`${ShiTypes[0].id}`] = `${ShiTypes[0].name}`;
getOtherRemovalType[`${ShiTypes[1].id}`] = `${ShiTypes[1].name}`;
getOtherRemovalType[`${ShiTypes[2].id}`] = `${ShiTypes[2].name}`;

const SLOPED_TYPES = [
  { id: "001", name: "3-tab" },
  { id: "002", name: "Architechtural" },
  { id: "003", name: "Designer" },
];

const CHECK_ROOFING = [
  SLOPED_TYPES[0].name,
  SLOPED_TYPES[1].name,
  SLOPED_TYPES[2].name,
];

const FLATTYPES = [
  { id: "006", name: "Single Ply TD" },
  { id: "007", name: "Two Ply SA" },
  { id: "008", name: "Three Ply SA" },
];

const CHECK_FLAT = [FLATTYPES[0].name, FLATTYPES[1].name, FLATTYPES[2].name];

const getInstallType = {};
getInstallType[`${SLOPED_TYPES[0].id}`] = `${SLOPED_TYPES[0].name}`;
getInstallType[`${SLOPED_TYPES[1].id}`] = `${SLOPED_TYPES[1].name}`;
getInstallType[`${SLOPED_TYPES[2].id}`] = `${SLOPED_TYPES[2].name}`;

getInstallType[`${FLATTYPES[0].id}`] = `${FLATTYPES[0].name}`;
getInstallType[`${FLATTYPES[1].id}`] = `${FLATTYPES[1].name}`;
getInstallType[`${FLATTYPES[2].id}`] = `${FLATTYPES[2].name}`;

const FLANGE = [
  { id: "flange001", name: "Copper" },
  { id: "flange002", name: "Aluminum" },
];

const CURB = [
  { id: "001", name: "Low" },
  { id: "002", name: "High" },
];

const VENTING = [
  { id: "001", name: "Fixed" },
  { id: "002", name: "Manual" },
  { id: "003", name: "Electric" },
];

const HOODTYPE = [
  { id: "001", name: "Glass" },
  { id: "002", name: "Glass Tinted" },
  { id: "003", name: "Acrylic" },
  { id: "004", name: "Acrylic Tinted" },
];

const TABS = [
  {
    id: "accessories",
    name: "Accessories",
  },
  {
    id: "flashing",
    name: "Flashing",
  },
  {
    id: "ventalition",
    name: "Ventalition",
  },
  {
    id: "skylight",
    name: "Skylight",
  },
  {
    id: "wood",
    name: "Wood",
  },
  {
    id: "fasciacapping",
    name: "Fascia and Capping",
  },
  {
    id: "miscItems",
    name: "Misc Items",
  },
];

const G_LEVEL = [
  { id: "floor001", name: "First" },
  { id: "floor002", name: "Second" },
  { id: "floor003", name: "Third" },
];
const G_ELEVATIONS = [
  { id: "001", name: "Front" },
  { id: "002", name: "Back" },
  { id: "003", name: "Left" },
  { id: "004", name: "Right" },
];

const G_ELEVATION_DETAILS = [
  { id: "001", name: "Main House" },
  { id: "002", name: "Garage" },
  { id: "003", name: "Barn" },
  { id: "004", name: "GST/HSE" },
  { id: "005", name: "Cabana" },
];

const G_SCREENS = [
  {
    name: "Aluminum",
    id: "SCREENS001",
  },
  {
    name: "Copper",
    id: "SCREENS002",
  },
  {
    name: "Vinyl",
    id: "SCREENS003",
  },
];

const G_SCREENSSIZE = [
  {
    name: '4"',
    id: "SCREENS-0040",
  },
  {
    name: '5"',
    id: "SCREENS-0050",
  },
  {
    name: '6"',
    id: "SCREENS-0060",
  },
  {
    name: '7"',
    id: "SCREENS-0070",
  },
];

const G_SCREENSCOLOR = [
  {
    name: "White",
    id: "SCREENS-011",
  },
  {
    name: "Brown",
    id: "SCREENS-012",
  },
  {
    name: "Other",
    other: true,
    id: "SCREENS-013",
  },
];

const G_SCREENSOTHER = {
  SCREENS001: [G_SCREENSSIZE, G_SCREENSCOLOR],
  SCREENS002: [G_SCREENSSIZE, []],
  SCREENS003: [G_SCREENSSIZE, G_SCREENSCOLOR],
};

const G_LEADERHEADSOTHER = {
  SCREENS001: [G_SCREENSCOLOR],
  SCREENS002: [[]],
  SCREENS003: [G_SCREENSCOLOR],
};

const G_LEADERS = [
  [
    {
      name: "Aluminum",
      id: "LEADERS001",
    },
    {
      name: "Copper",
      id: "LEADERS002",
    },
  ],
  [
    {
      name: "Box",
      id: "LEADERS003",
    },
    {
      name: "Round",
      id: "LEADERS004",
    },
  ],
];

const G_LEADERSCOLOR = {
  LEADERS001: [
    {
      name: "White",
      id: "LEADERS-011",
    },
    {
      name: "Brown",
      id: "LEADERS-012",
    },
    {
      name: "Other",
      other: true,
      id: "LEADERS-013",
    },
  ],
  LEADERS002: [],
};

const G_LEADERSOTHER = {
  LEADERS001: {
    LEADERS003: [
      [
        {
          name: '2" x 3"',
          id: "LEADERS-0004",
        },
        {
          name: '3" x 4"',
          id: "LEADERS-0005",
        },
        {
          name: '4" x 5"',
          id: "LEADERS-0006",
        },
      ],
    ],
    LEADERS004: [
      [
        {
          name: "3 Inch",
          id: "LEADERS-0010",
        },
        {
          name: "4 Inch",
          id: "LEADERS-0040",
        },
        {
          name: "5 Inch",
          id: "LEADERS-0050",
        },
        {
          name: "6 Inch",
          id: "LEADERS-0060",
        },
      ],
    ],
  },
  LEADERS002: {
    LEADERS003: [
      [
        {
          name: '2" x 4"',
          id: "LEADERS0031",
        },
        {
          name: '3" x 4"',
          id: "LEADERS0032",
        },
        {
          name: '4" x 5"',
          id: "LEADERS0033",
        },
      ],
    ],
    LEADERS004: [
      [
        {
          name: "3 Inch",
          id: "LEADERS0041",
        },
        {
          name: "4 Inch",
          id: "LEADERS0042",
        },
        {
          name: "5 Inch",
          id: "LEADERS0043",
        },
        {
          name: "6 Inch",
          id: "LEADERS0044",
        },
      ],
    ],
  },
};

const G_GUTTERS = [
  [
    {
      name: "Aluminum",
      id: "GUTTERS001",
    },
    {
      name: "Copper",
      id: "GUTTERS002",
    },
  ],
  [
    {
      name: "K Type",
      id: "GUTTERS002",
    },
    {
      name: "Half Round",
      id: "GUTTERS003",
    },
  ],
  [
    {
      name: '4"',
      id: "GUTTERS004",
    },
    {
      name: '5"',
      id: "GUTTERS005",
    },
    {
      name: '6"',
      id: "GUTTERS006",
    },
    {
      name: '7"',
      id: "GUTTERS010",
    },
  ],
  [
    {
      name: "White",
      id: "GUTTERS011",
    },
    {
      name: "Brown",
      id: "GUTTERS012",
    },
    {
      name: "Other",
      other: true,
      id: "GUTTERS013",
    },
  ],
];

const G_CAPPING = [
  [
    {
      name: "Flat",
      id: "CAPPING001",
    },
    {
      name: "PVC",
      id: "CAPPING002",
    },
    {
      name: "Mar-Free",
      id: "CAPPING003",
    },
  ],
  [
    {
      name: "White",
      id: "CAPPING004",
      other: false,
    },
    {
      name: "Brown",
      id: "CAPPING005",
      other: false,
    },
    {
      name: "Other",
      id: "CAPPING006",
      other: true,
    },
  ],
];

const getCapping = {};
getCapping[`${G_CAPPING[0][0].id}`] = `${G_CAPPING[0][0].name}`;
getCapping[`${G_CAPPING[0][1].id}`] = `${G_CAPPING[0][1].name}`;
getCapping[`${G_CAPPING[0][2].id}`] = `${G_CAPPING[0][2].name}`;
getCapping[`${G_CAPPING[1][0].id}`] = `${G_CAPPING[1][0].name}`;
getCapping[`${G_CAPPING[1][1].id}`] = `${G_CAPPING[1][1].name}`;
getCapping[`${G_CAPPING[1][2].id}`] = `${G_CAPPING[1][2].name}`;

const G_FASCIADATA = [
  {
    name: "Wood",
    id: "FASCIA009",
  },
  {
    name: "Composite",
    id: "FASCIA010",
  },
];

const G_FASCIA = {
  FASCIA009: [
    [
      {
        name: "1 x 4",
        id: "FASCIA001",
      },
      {
        name: "1 x 6",
        id: "FASCIA002",
      },
      {
        name: "1 x 8",
        id: "FASCIA003",
      },
    ],
    [
      {
        name: `8'`,
        id: "FASCIA004",
      },
      {
        name: `10'`,
        id: "FASCIA005",
      },
      {
        name: `12'`,
        id: "FASCIA006",
      },
    ],
  ],
  FASCIA010: [
    [
      {
        name: "1 x 4",
        id: "FASCIA007",
      },
      {
        name: "1 x 6",
        id: "FASCIA008",
      },
      {
        name: "1 x 8",
        id: "FASCIA011",
      },
      {
        name: "1 x 12",
        id: "FASCIA012",
      },
    ],
    [
      {
        name: `12'`,
        id: "FASCIA013",
      },
      {
        name: `18'`,
        id: "FASCIA014",
      },
      {
        name: `20'`,
        id: "FASCIA015",
      },
    ],
  ],
};

const G_columns = [
  {
    dataField: "edit",
    text: "",
  },
  {
    dataField: "id",
    text: "Sec",
  },
  {
    dataField: "level",
    text: "Level",
  },
  {
    dataField: "elevation",
    text: "Elevation",
  },
  {
    dataField: "gutters",
    text: "Gutters",
  },
  {
    dataField: "leaders",
    text: "Leaders",
  },
  {
    dataField: "screens",
    text: "Screens",
  },
  {
    dataField: "fascia",
    text: "Fascia",
  },
  {
    dataField: "cap",
    text: "Capping",
  },
  {
    dataField: "heads",
    text: "Heads",
  },
  {
    dataField: "delete",
    text: "",
  },
];

const G_TABS = [
  {
    id: "gutter",
    name: "Gutter & Leaders",
  },
  {
    id: "fascia",
    name: "Fascia & Capping",
  },
];

const CATEGORYDATA_MATERIAL = [
  {
    order: 0,
    key: "Roofing",
    typeKey: "categoryType",
  },
  {
    order: 1,
    key: "Flat Roofing",
    typeKey: "category",
  },
  {
    order: 2,
    key: "Starter",
    typeKey: "categoryType",
    totalKey: "T0",
    dataKey: "StarterData",
  },
  {
    order: 3,
    key: "Hip & Ridge",
    typeKey: "categoryType",
    totalKey: "T4",
    dataKey: "HipRidgeData",
    additionalData: {
      categoryType: "Hip & Ridge",
      category: "Shingles",
    },
  },
  {
    order: 4,
    key: "Underlayments",
    dataKey: "",
    additionalData: {
      category: "Underlayments",
      categoryType: {
        $not: {
          $regex: "Leak Barrier",
        },
      },
    },
  },
  {
    order: 5,
    key: "Leak Barrier",
    typeKey: "categoryType",
    totalKey: "T1",
    dataKey: "LeakBarrierData",
  },
  {
    order: 6,
    key: "Ridge Vent",
    typeKey: "categoryType",
    totalKey: "T5",
    dataKey: "RidgeVentData",
  },
  {
    order: 7,
    key: "Hood Vent Small",
    totalKey: "T13",
    dataKey: "hoodventsmallData",
    additionalData: {
      categoryType: "Hood/Goose Neck",
      $or: [
        {
          series: { $regex: "Small" },
        },
        {
          series: { $regex: "Aluminum" },
        },
      ],
    },
  },
  {
    order: 8,
    key: "Hood Vent Large",
    totalKey: "T14",
    dataKey: "hoodventlargeData",
    additionalData: {
      categoryType: "Hood/Goose Neck",
      $or: [
        {
          series: { $regex: "Large" },
        },
        {
          series: { $regex: "Galvanized" },
        },
      ],
    },
  },
  {
    order: 9,
    key: "Eve",
    typeKey: "categoryType",
    totalKey: "T2",
    dataKey: "EveDripData",
  },
  {
    order: 10,
    key: "Rake",
    typeKey: "categoryType",
    totalKey: "T3",
    dataKey: "RakeDripData",
  },
  {
    order: 11,
    key: "Louvers",
    typeKey: "categoryType",
    totalKey: "T11",
    dataKey: "louversData",
  },
  {
    order: 12,
    key: "Fans",
    typeKey: "categoryType",
    totalKey: "T12",
    dataKey: "fansData",
    additionalData: {
      categoryType: "Power Fan",
    },
  },
  {
    order: 13,
    key: "Vent Pipe",
    typeKey: "categoryType",
    totalKey: "T6",
    dataKey: "VentPipeData",
  },
  {
    order: 14,
    key: "Valley",
    typeKey: "categoryType",
    totalKey: "T7",
    dataKey: "ValleyData",
  },
  {
    order: 15,
    key: "Step",
    typeKey: "categoryType",
    totalKey: "T8",
    dataKey: "StepsData",
  },
  {
    order: 16,
    key: "Chimney",
    totalKey: "T9",
    dataKey: "ChimneyData",
    additionalData: {
      category: "Metal Sheets",
      categoryType: "Metal Sheets",
      subType: "Copper",
    },
  },
  {
    order: 17,
    key: "Wall",
    totalKey: "T10",
    dataKey: "WallData",
    additionalData: {
      category: "Metal Sheets",
      categoryType: "Metal Sheets",
      subType: "Copper",
    },
  },
  {
    order: 18,
    key: "Plywood",
    typeKey: "categoryType",
    totalKey: "T16",
    dataKey: "plywoodData",
    getSeries: (pricing) =>
      `${getPlywoodSize[pricing.size]} (4x8) ${getPlywoodType[pricing.type]}`,
    getQty: (pricing) => pricing.pieces,
    uom: "Pieces",
  },
  {
    order: 19,
    key: "Rafter & Studs",
    typeKey: "categoryType",
    totalKey: "T17",
    dataKey: "rafterstudsData",
    getSeries: (pricing) =>
      `${getRafter[pricing.size]} x ${getRafter[pricing.length]
        .toString()
        .replace("'", "")}`,
    getQty: (pricing) =>
      Math.ceil(
        pricing.feet /
          Number(`${getRafter[pricing.length].toString().replace("'", "")}`)
      ),
    uom: "Feet",
    additionalData: {
      category: "Wood",
      categoryType: "Rafter/Stud",
    },
  },
  {
    order: 20,
    key: "Furring Strips",
    typeKey: "categoryType",
    totalKey: "T18",
    dataKey: "furringstripsData",
    getSeries: (pricing) => `${getFurring[pricing.size]}`,
    getQty: (pricing) =>
      Math.ceil(
        pricing.feet /
          Number(
            `${
              getFurring[pricing.size].split("")[
                getFurring[pricing.size].split("").length - 1
              ]
            }`
          )
      ),
    uom: "Feet",
  },
  {
    order: 21,
    key: "Fascia",
    typeKey: "category",
    totalKey: "T19",
    dataKey: "fasciaData",
    getSeries: (pricing) =>
      `${getFascia[pricing.size]} x ${getFascia[pricing.length]
        .toString()
        .replace("'", "")}${
        getFascia[pricing.material] === "Composite" ? "" : ""
      }`,
    getCategoryType: (pricing) => getFascia[pricing.material],
    getQty: (pricing) =>
      Math.ceil(
        pricing.feet /
          Number(`${getFascia[pricing.length].toString().replace("'", "")}`)
      ),
    uom: "Feet",
  },
  {
    order: 22,
    key: "Capping",
    // key: 'Coil Rolls',
    typeKey: "categoryType",
    totalKey: "T20",
    dataKey: "cappingData",
    additionalData: {
      category: "Coil Rolls",
      categoryType: "Coil Rolls",
    },

    getSeries: (pricing) =>
      `${getCapping[pricing.type]}${
        pricing.color ? " " + getCapping[pricing.color] : ""
      }`,
    getQty: (pricing) => Math.ceil(pricing.feet / 100),
    uom: "Box",
  },
  {
    order: 23,
    key: "Skylight",
    typeKey: "categoryType",
    totalKey: "skylightTotal",
    dataKey: "skylightData",
    getSeries: (pricing) => `${pricing.width} x ${pricing.height}`,
    getQty: (pricing) => pricing.count,
    uom: "Per",
    noChanges: true,
  },
  {
    order: 24,
    key: "Trim Metal",
    typeKey: "categoryType",
    totalKey: "M0",
    dataKey: "trimmetalData",
  },
  {
    order: 25,
    key: "Chimney Cap",
    typeKey: "categoryType",
    totalKey: "M1",
    dataKey: "chimenycapsData",
    getSeries: (pricing) =>
      `${getChimneyCaps[pricing.shape]} ${getChimneyCaps[pricing.material]}`,
    getQty: (pricing) => pricing.count,
    uom: "Per",
  },
  {
    order: 26,
    key: "Common Nails",
    typeKey: "categoryType",
    totalKey: "M3",
    dataKey: "commonnailsData",
    getSeries: (pricing) =>
      `${getCommonNails[pricing.type]} ${getCommonNails[pricing.size]}`,
    getQty: (pricing) => pricing.pound,
    uom: "Pound",
    additionalData: {
      category: "Nails",
      categoryType: "Common",
    },
  },
  {
    order: 27,
    key: "Hand Nails",
    typeKey: "categoryType",
    totalKey: "M2",
    dataKey: "handnailsData",
    getSeries: (pricing) =>
      `${getHandsNails[pricing.type]} ${getHandsNails[pricing.size]}`,
    getQty: (pricing) => pricing.pound,
    uom: "Pound",
    additionalData: {
      category: "Nails",
      categoryType: "Hand",
    },
  },
  {
    order: 28,
    key: "Metal Sheets",
    typeKey: "categoryType",
    totalKey: "M4",
    dataKey: "metalsheetData",
    getSeries: (pricing) =>
      `${getMetalSheet[pricing.material]} ${getMetalSheet[pricing.dimension]}`,
    getQty: (pricing) => pricing.sheet,
    uom: "Sheet",
    getSubType: (pricing) => getMetalSheet[pricing.material],
  },
  {
    order: 29,
    key: "Soprema",
    typeKey: "",
    manufacturer: ["Soprema"],
    totalKey: "",
    dataKey: "",
    shingles: false,
  },
  {
    order: 30,
    key: "Gravel Stop",
    totalKey: "gravelStop",
  },
  {
    order: 31,
    key: "Apron",
    totalKey: "apron",
  },
  {
    order: 32,
    key: "J-Bend",
    totalKey: "jBend",
  },
];

const LABORDATA_UOM = "Square";

const CATEGORYDATA_LABOR = [
  {
    order: 0,
    fields: ["accessory"],
    fieldValue: ["Leak Barrier"],
    totalKey: "T1",
    dataKey: "LeakBarrierData",
  },
  {
    order: 1,
    fields: ["accessory"],
    fieldValue: ["Eve Drip Edge"],
    totalKey: "T2",
    dataKey: "EveDripData",
  },

  {
    order: 2,
    fields: ["accessory"],
    fieldValue: ["Rake Drip Edge"],
    totalKey: "T3",
    dataKey: "RakeDripData",
  },
  {
    order: 3,
    fields: ["accessory"],
    fieldValue: ["Ridge Vent"],
    totalKey: "T5",
    dataKey: "RidgeVentData",
  },
  {
    order: 4,
    fields: ["accessory"],
    fieldValue: ["Louvers"],
    totalKey: "T11",
    dataKey: "louversData",
  },
  {
    order: 5,
    fields: ["accessory"],
    fieldValue: ["Fan"],
    totalKey: "T12",
    dataKey: "fansData",
  },
  {
    order: 6,
    fields: ["accessory"],
    fieldValue: ["Step Flashing"],
    totalKey: "T8",
    dataKey: "StepsData",
  },
  {
    order: 7,
    fields: ["accessory"],
    fieldValue: ["Chimney Flashing"],
    totalKey: "T9",
    dataKey: "ChimneyData",
  },
  {
    order: 8,
    fields: ["accessory"],
    fieldValue: ["Wall Flashing"],
    totalKey: "T10",
    dataKey: "WallData",
  },
  {
    order: 9,
    fields: ["accessory"],
    fieldValue: ["Plywood"],
    totalKey: "T16",
    dataKey: "plywoodData",
  },
  {
    order: 10,
    fields: ["accessory"],
    fieldValue: ["Rafter/Stud"],
    totalKey: "T17",
    dataKey: "rafterstudsData",
  },
  {
    order: 11,
    fields: ["accessory"],
    fieldValue: ["Furring Strips"],
    totalKey: "T18",
    dataKey: "furringstripsData",
  },
  {
    order: 12,
    fields: ["accessory"],
    fieldValue: ["Gravel Stop"],
    totalKey: "gravelStop",
    dataKey: "",
  },
  {
    order: 13,
    fields: ["accessory"],
    fieldValue: ["Chimney Caps"],
    totalKey: "M1",
    dataKey: "chimenycapsData",
  },
  {
    order: 15,
    fields: ["accessory"],
    fieldValue: ["Starter"],
    totalKey: "T0",
    dataKey: "StarterData",
  },
  {
    order: 16,
    fields: ["accessory"],
    fieldValue: ["Vent Pipe Boot"],
    totalKey: "T6",
    dataKey: "VentPipeData",
  },
  {
    order: 17,
    fields: ["accessory"],
    fieldValue: ["Valley"],
    totalKey: "T7",
    dataKey: "ValleyData",
  },
  {
    order: 18,
    fields: ["accessory"],
    fieldValue: ["Hood Vent Small"],
    totalKey: "T13",
    dataKey: "hoodventsmallData",
  },
  {
    order: 19,
    fields: ["accessory"],
    fieldValue: ["Hood Vent Large"],
    totalKey: "T14",
    dataKey: "hoodventlargeData",
  },
  {
    order: 20,
    fields: ["accessory"],
    fieldValue: ["Trim Metal J-Bend"],
    totalKey: "jBend",
    dataKey: "",
  },
  {
    order: 21,
    fields: ["accessory"],
    fieldValue: ["Trim Metal Apron"],
    totalKey: "apron",
    dataKey: "",
  },
  {
    order: 22,
    fields: ["accessory"],
    fieldValue: ["Hip & Ridge"],
    totalKey: "T4",
    dataKey: "",
  },
];

const LABOR_METRIC_ITEMS = [
  {
    order: 14,
    fields: ["accessory"],
    types: ["Replace", "New Install", "Close Off"],
    series: "Skylight",
    totalKey: "skylightTotal",
    dataKey: "skylightData",
    getSeries: (pricing) => `${pricing.width} x ${pricing.height}`,
    getQty: (pricing) => pricing.count,
    uom: "Per",
  },
  {
    order: 21,
    key: "Fascia",
    typeKey: "categoryType",
    totalKey: "T19",
    dataKey: "fasciaData",
    getSeries: (pricing) =>
      `${getFascia[pricing.material]} - ${getFascia[pricing.size]}`,
    getQty: (pricing) => pricing.feet,
    uom: "Feet",
  },
  {
    order: 22,
    key: "Capping",
    typeKey: "categoryType",
    totalKey: "T20",
    dataKey: "cappingData",
    getSeries: (pricing) =>
      `${getCapping[pricing.type]}${
        pricing.color ? " - " + getCapping[pricing.color] : ""
      }`,
    getQty: (pricing) => pricing.feet,
    uom: "Feet",
  },
];

const MANUFACTURERS = {
  Roofing: ["GAF", "CertainTeed", "IKO", "TAMKO", "Owens Corning"],
  "Flat Roofing": ["GAF", "CertainTeed", "IKO", "TAMKO"],
  Torch: ["GAF", "CertainTeed"],
  "Flat Roofing Adhesive": ["CertainTeed", "Soprema"],
  Starter: ["GAF", "CertainTeed", "IKO", "TAMKO", "Owens Corning"],
  "Hip & Ridge": ["GAF", "CertainTeed", "IKO", "TAMKO", "Owens Corning"],
  Louvers: ["GAF", "Lomanco"],
  "Ridge Vent": ["GAF", "CertainTeed", "TAMKO", "Owens Corning", "Lomanco"],
  "Cap Sheet": ["GAF", "CertainTeed", "TAMKO", "IKO"],
  "Base Sheet": ["GAF", "CertainTeed", "TAMKO", "IKO"],
  Underlayments: ["IKO", "Owens Corning", "TAMKO", "CertainTeed", "GAF"],
  "Leak Barrier": ["IKO", "Owens Corning", "TAMKO", "CertainTeed", "GAF"],
  Synthetic: ["IKO", "Owens Corning", "CertainTeed", "TAMKO", "GAF"],
  "Felt Paper": ["GAF", "CertainTeed", "IKO", "TAMKO", "Owens Corning"],
  Fans: ["GAF", "Lomanco"],
  "Hood Vent Large": ["GAF", "CertainTeed", "IKO", "TAMKO", "Owens Corning"],
  "Hood Vent Small": ["GAF", "CertainTeed", "IKO", "TAMKO", "Owens Corning"],
};

const GUTTERSTYPES = [
  {
    selection: "guttersSelection",
    total: "guttersTotal",
    category: "Gutters/Leaders",
    categoryType: "Gutters",
  },
  {
    selection: "leadersSelection",
    total: "leadersTotal",
    category: "Gutters/Leaders",

    categoryType: "Leaders",
  },
  {
    selection: "screensSelection",
    total: "screensTotal",
    category: "Gutters/Leaders",

    categoryType: "Screens",
  },
  {
    selection: "leaderheadsSelection",
    total: "leaderheadsTotal",
    category: "Gutters/Leaders",

    categoryType: "Leader Heads",
  },
  {
    selection: "fasciaSelection",
    total: "fasciaTotal",
    category: "Fascia",
    categoryType: "Fascia",
  },
  {
    selection: "cappingSelection",
    total: "cappingTotal",
    category: "Capping",
    categoryType: "Capping",
  },
];

const QUOTE_LABOR_UOM = ["Piece", "Per", "Foot", "Square"];

const QUOTE_GUTTERS_UOM = ["Per", "Foot"];

const STANDARD_STATEMENTS_INITIAL_DATA_IDS = {
  Plywood: "STD002",
  "Debris Removal": "STD003",
  Labor: "STD007",
  Disclosure: "STD008",
  Cancellation: "STD009",
  [CHECK_ROOFING[0]]: "STD004",
  [CHECK_ROOFING[1]]: "STD005",
  [CHECK_ROOFING[2]]: "STD005",
};

const SHINGLES_IDS = [
  STANDARD_STATEMENTS_INITIAL_DATA_IDS["Plywood"],
  STANDARD_STATEMENTS_INITIAL_DATA_IDS["Debris Removal"],
  STANDARD_STATEMENTS_INITIAL_DATA_IDS["Labor"],
  STANDARD_STATEMENTS_INITIAL_DATA_IDS["Disclosure"],
];

const STANDARD_STATEMENTS_DATA_IDS = {
  ...STANDARD_STATEMENTS_INITIAL_DATA_IDS,
  hasShingles: SHINGLES_IDS,
};

const roles = {
  Admin: "admin",
  "Sales Manager": "sales-manager",
  "Project Manager": "project-manager",
  "Office Manager": "office-manager",
  Reception: "reception",
  Foreman: "foreman",
  "Owner/Exec": "owner/exec",
  "Sales Person": "sales-person",
  "Repair Person": "repair-person",
};

const newInstallationType = [
  {
    newInstallTypeId: "sales01",
    newInstallTypeName: "Roof",
    other: false,
  },
  {
    newInstallTypeId: "sales02",
    newInstallTypeName: "Gutters",
    other: false,
  },
];

const repairType = [
  {
    repairTypeId: "repair01",
    repairTypeName: "Roof",
    other: false,
  },
  {
    repairTypeId: "repair02",
    repairTypeName: "Gutters",
    other: false,
  },
  {
    repairTypeId: "repair03",
    repairTypeName: "Siding",
    other: false,
  },
  {
    repairTypeId: "repair04",
    repairTypeName: "Windows",
    other: false,
  },
  {
    repairTypeId: "repair05",
    repairTypeName: "Doors",
    other: false,
  },
  {
    repairTypeId: "repair06",
    repairTypeName: "Other",
    other: true,
  },
];

const propertyType = [
  {
    propertyTypeId: "property01",
    propertyTypeName: "Residential",
    coop: false,
  },
  {
    propertyTypeId: "property02",
    propertyTypeName: "Coop/Condo",
    coop: true,
  },
  {
    propertyTypeId: "property03",
    propertyTypeName: "Commerical",
    coop: false,
  },
];

const phoneType = [
  {
    phoneTypeId: "phone01",
    phoneTypeName: "Home",
  },
  {
    phoneTypeId: "phone02",
    phoneTypeName: "Work",
  },
  {
    phoneTypeId: "phone03",
    phoneTypeName: "Mobile",
  },
];

const emailType = [
  {
    emailTypeId: "email01",
    emailTypeName: "Home",
  },
  {
    emailTypeId: "email02",
    emailTypeName: "Work",
  },
  {
    emailTypeId: "email03",
    emailTypeName: "Other",
  },
];

const leadSourceType = [
  {
    _id: "leadSource99",
    leadSource: "Referral",
    leadSourceText: true,
  },
];

const relationshipType = [
  {
    relationshipId: "relation01",
    relationshipName: "Wife",
  },
  {
    relationshipId: "relation02",
    relationshipName: "Husband",
  },
  {
    relationshipId: "relation03",
    relationshipName: "Son",
  },
  {
    relationshipId: "relation04",
    relationshipName: "Daughter",
  },
  {
    relationshipId: "relation05",
    relationshipName: "Mother",
  },
  {
    relationshipId: "relation06",
    relationshipName: "Father",
  },
  {
    relationshipId: "relation07",
    relationshipName: "Tenant",
  },
  {
    relationshipId: "relation08",
    relationshipName: "Partner",
  },
  {
    relationshipId: "relation09",
    relationshipName: "Neighbor",
  },
  {
    relationshipId: "relation10",
    relationshipName: "Super",
  },
  {
    relationshipId: "relation11",
    relationshipName: "Prop. Manager",
  },
  {
    relationshipId: "relation99",
    relationshipName: "Other",
  },
];

const taxRate = [
  {
    taxId: "tax0",
    taxText: "No Tax",
    taxNum: 0,
  },
  {
    taxId: "tax1",
    taxText: "4%",
    taxNum: 4,
  },
  {
    taxId: "tax2",
    taxText: "5%",
    taxNum: 5,
  },
  {
    taxId: "tax3",
    taxText: "6%",
    taxNum: 6,
  },
  {
    taxId: "tax4",
    taxText: "7%",
    taxNum: 7,
  },
];

const salesStage = [
  {
    salesStageId: "activityStatus00",
    salesStageText: "New",
    salesStageOrder: 0,
    showInTable: false,
    showInChange: false,
  },
  {
    salesStageId: "activityStatus01",
    salesStageText: "Unscheduled", //Unscheduled
    salesStageOrder: 1,
    showInTable: false,
    showInChange: true,
  },
  {
    salesStageId: "salesStage102",
    salesStageText: "Scheduled",
    salesStageOrder: 2,
    showInTable: true,
    showInChange: true,
  },
  {
    salesStageId: "salesStage103",
    salesStageText: "Proposals Due",
    salesStageOrder: 3,
    showInTable: true,
    showInChange: true,
  },
  {
    salesStageId: "salesStage104",
    salesStageText: "Sent/Follow-Up",
    salesStageOrder: 4,
    showInTable: true,
    showInChange: true,
  },
  {
    salesStageId: "salesStage105",
    salesStageText: "Won",
    salesStageOrder: 5,
    showInTable: true,
    showInChange: true,
  },
  {
    salesStageId: "salesStage106",
    salesStageText: "Future Follow-up",
    salesStageOrder: 6,
    showInTable: true,
    showInChange: true,
  },
  {
    salesStageId: "salesStage107",
    salesStageText: "Lost",
    salesStageOrder: 7,
    showInTable: true,
    showInChange: true,
  },
  {
    salesStageId: "salesStage108",
    salesStageText: "Canceled",
    salesStageOrder: 8,
    showInTable: true,
    showInChange: true,
  },
];
const repairStage = [
  {
    repairStageId: "activityStatus00",
    repairStageText: "New",
    repairStageOrder: 0,
    showInTable: false,
    showInChange: false,
  },
  {
    repairStageId: "activityStatus01",
    repairStageText: "Unscheduled", //Unscheduled
    repairStageOrder: 1,
    showInTable: false,
    showInChange: true,
  },
  {
    repairStageId: "repairStage102",
    repairStageText: "Scheduled",
    repairStageOrder: 2,
    showInTable: true,
    showInChange: true,
  },
  {
    repairStageId: "repairStage103",
    repairStageText: "Complete Unpaid",
    repairStageOrder: 3,
    showInTable: true,
    showInChange: true,
  },
  {
    repairStageId: "repairStage104",
    repairStageText: "Paid/Closed",
    repairStageOrder: 4,
    showInTable: true,
    showInChange: true,
  },
  {
    repairStageId: "repairStage105",
    repairStageText: "Canceled",
    repairStageOrder: 5,
    showInTable: true,
    showInChange: true,
  },
];
const dropDownMenu = [
  {
    dropId: "ddm0",
    dropName: "Marketing",
  },
  // {
  // 	dropId: 'ddm1',
  // 	dropName: 'Material & Carting Suppliers',
  // },
  // {
  // 	dropId: 'ddm2',
  // 	dropName: 'Subcontractor Personel',
  // },
  {
    dropId: "ddm3",
    dropName: "State & County Taxes",
  },
  {
    dropId: "ddm4",
    dropName: "Quote Mark-Up Percentage & Range",
  },
  {
    dropId: "ddm5",
    dropName: "Production Calendar Day Lost",
  },
  {
    dropId: "ddm6",
    dropName: "Scheduling Modal",
  },
  {
    dropId: "ddm7",
    dropName: "Weather Zipcode",
  },
];
const userRole = [
  {
    text: "Admin",
    value: "admin",
    visible: false,
  },
  {
    text: "Owner/Exec",
    value: "executive",
    visible: true,
  },
  {
    text: "Office Manager",
    value: "office_manager",
    visible: true,
  },
  {
    text: "Project Manager",
    value: "project_manager",
    visible: true,
  },
  {
    text: "Sales Manager",
    value: "sales_manager",
    visible: true,
  },
  {
    text: "Sales Person",
    value: "sales",
    visible: true,
  },
  {
    text: "Repair Person",
    value: "repair",
    visible: true,
  },
  {
    text: "Reception",
    value: "receptionist",
    visible: true,
  },
  {
    text: "Foreman",
    value: "foreman",
    visible: true,
  },
  {
    text: "Marketer",
    value: "marketer",
    visible: false,
  },
];
const basePlanUser = [{ basePlanUsersCount: 5 }];
const personnelPos = [
  {
    text: "Owner",
    value: "owner",
    visible: true,
  },
  {
    text: "General Manager",
    value: "general_manager",
    visible: true,
  },
  {
    text: "Assistant Manager",
    value: "assistant_manager",
    visible: true,
  },
  {
    text: "Outside Sales Rep",
    value: "outside_sales_rep",
    visible: true,
  },
  {
    text: "Inside Sales Rep",
    value: "inside_sales_rep",
    visible: true,
  },
  {
    text: "Dispatcher",
    value: "dispatcher",
    visible: true,
  },
  {
    text: "Receptionist",
    value: "receptionist",
    visible: true,
  },
];
const personnelSubsPos = [
  {
    text: "Owner",
    value: "owner",
    visible: true,
  },
  {
    text: "Co/Owner",
    value: "co/owner",
    visible: true,
  },
  {
    text: "Foreman",
    value: "foreman",
    visible: true,
  },
  {
    text: "Receptionist",
    value: "receptionist",
    visible: true,
  },
];
const personnelCartingPos = [
  {
    text: "Owner",
    value: "owner",
    visible: true,
  },
  {
    text: "General Manager",
    value: "general_manager",
    visible: true,
  },
  {
    text: "Assistant Manager",
    value: "assistant_manager",
    visible: true,
  },
  {
    text: "Dispatcher",
    value: "dispatcher",
    visible: true,
  },
  {
    text: "Receptionist",
    value: "receptionist",
    visible: true,
  },
];
const services = [
  { value: "roofing", label: "Roofing" },
  { value: "gutters", label: "Gutters" },
  { value: "repairs", label: "Repairs" },
];
const cartingTypes = [
  { value: "trucks", label: "Trucks" },
  { value: "dumpsters", label: "Dumpsters" },
];
const laborPricingOptions = [
  {
    isMisc: false,
    category: "Shingle Roofing",
    categoryType: [
      {
        typeName: "Shingle Roofing",
        style: [],
        roofPitch: ["Walkie", "Non-Walkie"],
        subType: ["3-tab", "Architectural", "Designer"],
        subTypeName: "Install Type",
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Flat Roofing",
    categoryType: [
      {
        typeName: "Flat Roofing",
        style: [],
        //roofPitch: ['Flat'],
        subType: ["Torch", "2 Ply"],
        subTypeName: "Install Type",
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Skylights",
    categoryType: [
      {
        typeName: "Skylights",
        style: [],
        roofPitch: [],
        subType: [],
        subTypeName: "",
        customItem: true,
        customItemDefaultValue: {},
        uomOptions: QUOTE_LABOR_UOM,
      },
    ],
  },
  {
    isMisc: false,
    category: "Roofing Accessories",
    categoryType: [
      {
        typeName: "Roofing Accessories",
        style: [],
        roofPitch: [],
        subType: [],
        subTypeName: "",
        customItem: true,
        customItemDefaultValue: {},
        uomOptions: QUOTE_LABOR_UOM,
      },
    ],
  },
  {
    isMisc: false,
    category: "Fascia",
    categoryType: [
      {
        typeName: "Fascia",
        style: [],
        roofPitch: [],

        subType: ["Wood", "Composite"],
        subTypeName: "Material Type",
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Capping",
    categoryType: [
      {
        typeName: "Capping",
        style: [],
        roofPitch: [],

        subType: ["Flat", "PVC", "Mar-Free"],
        subTypeName: "Material Type",
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Gutters/Leaders",
    categoryType: [
      {
        typeName: "Gutters",
        style: ["K Type", "Half Round"],
        roofPitch: [],
        subType: ["Aluminum", "Copper"],
        subTypeName: "Material Type",
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Leaders",
        style: ["Box", "Round", "Round Fluted"],
        roofPitch: [],
        subType: ["Aluminum", "Copper"],
        subTypeName: "Material Type",
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Screens",
        style: [],
        roofPitch: [],
        subType: ["Aluminum", "Copper", "Vinyl"],
        subTypeName: "Material Type",
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Leader Heads",
        style: [],
        roofPitch: [],
        subType: ["Aluminum", "Copper", "Vinyl"],
        subTypeName: "Material Type",
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: true,
    category: "Miscellaneous",
    categoryType: [],
    customItem: true,
    customItemDefaultValue: {},
    uomOptions: [],
  },
];

const materialPricingOptions = [
  {
    isMisc: false,
    category: "Shingles",
    categoryType: [
      {
        typeName: "Roofing",
        manufacturer: MANUFACTURERS["Roofing"],
        subType: ["3-tab", "Architechtural", "Designer"],
        subTypeName: "Series Type",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Starter",
        manufacturer: MANUFACTURERS["Starter"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Hip & Ridge",
        manufacturer: MANUFACTURERS["Hip & Ridge"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Underlayments",
    categoryType: [
      {
        typeName: "Felt Paper",
        manufacturer: MANUFACTURERS["Felt Paper"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Synthetic",
        manufacturer: MANUFACTURERS["Synthetic"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Leak Barrier",
        manufacturer: MANUFACTURERS["Leak Barrier"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Flat Roofing",
    categoryType: [
      {
        typeName: "Base Sheet",
        manufacturer: MANUFACTURERS["Base Sheet"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Cap Sheet",
        manufacturer: MANUFACTURERS["Cap Sheet"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Torch",
        manufacturer: MANUFACTURERS["Torch"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Flashing Cement",
    categoryType: [
      {
        typeName: "Flashing Cement",
        manufacturer: ["Karnak", "Bulldog"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: false,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Flat Roofing Coating",
    categoryType: [
      {
        typeName: "Flat Roof Coating",
        manufacturer: ["Karnak", "Bulldog"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Flat Roofing Primer",
    categoryType: [
      {
        typeName: "Flat Roof Primer",
        manufacturer: ["Bulldog"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: false,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Flat Roofing Adhesive",
    categoryType: [
      {
        typeName: "Flat Roof Adhesive",
        manufacturer: MANUFACTURERS["Flat Roofing Adhesive"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Drip Edging",
    categoryType: [
      {
        typeName: "Eve",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Rake",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Flashings",
    categoryType: [
      {
        typeName: "Valley",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Vent Pipe",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Step",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Ventilation",
    categoryType: [
      {
        typeName: "Ridge Vent",
        manufacturer: MANUFACTURERS["Ridge Vent"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Louvers",
        manufacturer: MANUFACTURERS["Louvers"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Power Fan",
        manufacturer: MANUFACTURERS["Fans"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Hood/Goose Neck",
        manufacturer: ["Broan"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: true,
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  // {
  // 	isMisc: false,
  // 	category: 'Skylights',
  // 	categoryType: [
  // 		{
  // 			typeName: 'Skylight',
  // 			manufacturer: [],
  // 			subType: [],
  // 			subTypeName: '',
  // 			hasPreferedOption: true,
  // 			multiPreferedOption: false,
  // 			customItem: true,
  // 			customItemDefaultValue: {
  // 				uom: 'Unit',
  // 			},
  // 		},
  // 	],
  // },
  {
    isMisc: false,
    category: "Wood",
    categoryType: [
      {
        typeName: "Plywood",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: true,
        customItemDefaultValue: {
          uom: "Piece",
          uomValue: 32,
        },
      },
      {
        typeName: "Rafter/Stud",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: false,
        multiPreferedOption: false,
        customItem: true,
        customItemDefaultValue: {
          uom: "Lengths",
        },
      },
      {
        typeName: "Furring Strips",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: false,
        multiPreferedOption: false,
        customItem: true,
        customItemDefaultValue: {
          uom: "Lengths",
        },
      },
    ],
  },
  {
    isMisc: false,
    category: "Fascia",
    categoryType: [
      {
        typeName: "Wood",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: false,
        multiPreferedOption: false,
        customItem: true,
        customItemDefaultValue: {
          uom: "Piece",
        },
      },
      {
        typeName: "Composite",
        manufacturer: ["Azek"],
        subType: [],
        subTypeName: "",
        hasPreferedOption: false,
        multiPreferedOption: false,
        customItem: true,
        customItemDefaultValue: {
          uom: "Piece",
        },
      },
    ],
  },
  {
    isMisc: false,
    category: "Coil Rolls",
    categoryType: [
      {
        typeName: "Coil Rolls",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: true,
        customItemDefaultValue: {
          uom: "Box",
        },
      },
    ],
  },
  {
    isMisc: false,
    category: "Metal Sheets",
    categoryType: [
      {
        typeName: "Metal Sheets",
        manufacturer: [],
        subType: ["Aluminum", "Copper"],
        subTypeName: "subType",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Chimney Cap",
    categoryType: [
      {
        typeName: "Chimney Cap",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: false,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: false,
    category: "Nails",
    categoryType: [
      {
        typeName: "Coil",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: true,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Hand",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: false,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Common",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: false,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
      {
        typeName: "Trim",
        manufacturer: [],
        subType: [],
        subTypeName: "",
        hasPreferedOption: false,
        multiPreferedOption: false,
        customItem: false,
        customItemDefaultValue: {},
      },
    ],
  },
  {
    isMisc: true,
    category: "Miscellaneous",
    categoryType: [],
    customItem: true,
    customItemDefaultValue: {},
    uomOptions: [
      "BDL",
      "Roll",
      "Pail",
      "Bucket",
      "Can",
      "Piece",
      "Count",
      "Box",
      "Per",
      "Foot",
      "Sheet",
      "Pound",
    ],
  },
];

const autoTextDropDown = [
  { dropId: "at01", dropName: "Standard Statements" },
  { dropId: "at02", dropName: "Removal/Rip" },
  { dropId: "at03", dropName: "Rafter & Studs" },
  { dropId: "at04", dropName: "Plywood" },
  { dropId: "at05", dropName: "Furring Strips" },
  { dropId: "at06", dropName: "Accessories" },
  { dropId: "at07", dropName: "Flashing" },
  { dropId: "at08", dropName: "Ventilation" },
  { dropId: "at09", dropName: "Skylights" },
  { dropId: "at10", dropName: "Installation Shingle/Flat" },
  { dropId: "at11", dropName: "Fascia/Capping" },
  { dropId: "at12", dropName: "Misc. Roofing Items" },
  { dropId: "at13", dropName: "Gutters K Style" },
  { dropId: "at14", dropName: "Gutters Half Round" },
  { dropId: "at15", dropName: "Leaders Box" },
  { dropId: "at16", dropName: "Leaders Round" },
  { dropId: "at17", dropName: "Leader Heads" },
  { dropId: "at18", dropName: "Gutter Screens" },
];
const GUTTERS_DATATYPE = ["color", "size", "style", "subType"];

const STANDARD_STATEMENTS_OPENING_PROPOSAL = "STD001";
const STANDARD_STATEMENTS_DEFAULTS_DATA_ID = [
  "STD010",
  "STD011",
  "STD012",
  "STD013",
];

const AUTO_TEXTS_MODAL_SUBCATEGORIES = [
  {
    key: "Underlayment",
    totalKey: "",
    exists: (meta) => meta && meta.hasShingles,
  },
  {
    key: "Leak Barrier",
    totalKey: "T1",
    dataKey: "LeakBarrierData",
  },
  {
    key: "Roof Louvers",
    totalKey: "T11",
    dataKey: "louversData",
  },
  {
    key: "Fans",
    totalKey: "T12",
    dataKey: "fansData",
  },
  {
    key: "Vent Pipe Flashing",
    totalKey: "T6",
    dataKey: "VentPipeData",
  },
  {
    key: "Valley Flashing",
    totalKey: "T7",
    dataKey: "ValleyData",
  },
  {
    key: "Steps Flashing",
    totalKey: "T8",
    dataKey: "StepsData",
  },
  {
    key: "Chimney Flashing",
    totalKey: "T9",
    dataKey: "ChimneyData",
  },
  {
    key: "Wall Flashing",
    totalKey: "T10",
    dataKey: "WallData",
  },
  {
    key: "Skylights",
    totalKey: "skylightTotal",
    dataKey: "skylightData",
  },
];
export {
  SLOPED_TYPES,
  FLATTYPES,
  MANUFACTURERS,
  roles,
  CATEGORYDATA_MATERIAL,
  newInstallationType,
  repairType,
  propertyType,
  phoneType,
  emailType,
  leadSourceType,
  relationshipType,
  taxRate,
  salesStage,
  repairStage,
  dropDownMenu,
  userRole,
  basePlanUser,
  personnelPos,
  personnelSubsPos,
  personnelCartingPos,
  services,
  cartingTypes,
  laborPricingOptions,
  materialPricingOptions,
  autoTextDropDown,

  //   manufacturers:
  //   MANUFACTURERS,
  getDifficulty,
  getOtherRemovalType,
  getRemovalType,
  getInstallType,
  CATEGORYDATA_LABOR,
  LABORDATA_UOM,
  GUTTERSTYPES,
  GUTTERS_DATATYPE,
  G_GUTTERS,
  G_LEADERSCOLOR,
  G_LEADERSOTHER,
  G_LEADERS,
  G_SCREENSOTHER,
  G_SCREENS,
  G_FASCIA,
  G_FASCIADATA,
  G_CAPPING,
  G_LEADERHEADSOTHER,
  CHECK_ROOFING,
  METAL,
  WORKTYPE,
  getWorkType,
  LABOR_METRIC_ITEMS,
  // SUPPLIERSTYPE: ['material', 'subsorcrew', 'carting', 'labor', 'crew'],
  RemoveTypes,
  QUOTE_LABOR_UOM,
  QUOTE_GUTTERS_UOM,
  AUTO_TEXTS_MODAL_SUBCATEGORIES,
  // AUTO_TEXT_HOOD_VENT: [
  // 	{
  // 		subCategory: 'Hood Vent Small',
  // 		dataKey: 'hoodventsmallData',
  // 	},
  // 	{
  // 		subCategory: 'Hood Vent Large',
  // 		dataKey: 'hoodventlargeData',
  // 	},
  // ],
  // AUTO_TEXT_ROOFING_SELECTION_DATA_ID: {
  // 	Plywood: (metric) => `${metric.size}${metric.type}`,
  // 	Rafter: (metric) => metric.size,
  // 	'Furring Strip': (metric) => metric.size,
  // 	Fascia: (metric) => `${metric.size}${metric.material}`,
  // 	Capping: (metric) => metric.type,
  // 	'Trim metals': (metric) => metric.type,
  // 	'Chimney Cap': (metric) => `${metric.shape}${metric.material}`,
  // 	'Hood Vent Small': () => 'small',
  // 	'Hood Vent Large': () => 'large',
  // },
  STANDARD_STATEMENTS_DATA_IDS,
  STANDARD_STATEMENTS_OPENING_PROPOSAL,
  STANDARD_STATEMENTS_DEFAULTS_DATA_ID,
  // AUTO_TEXT_ROOFING_METRIC: [
  // 	{
  // 		subCategory: 'Eve Drip Edging',
  // 		dataKey: 'EveDripData',
  // 	},
  // 	{
  // 		subCategory: 'Rake Drip Edging',
  // 		dataKey: 'RakeDripData',
  // 	},
  // 	{
  // 		subCategory: 'Starter Shingles',
  // 		dataKey: 'StarterData',
  // 		exists: (meta) => meta && meta.hasShingles,
  // 	},
  // 	{
  // 		subCategory: 'Hip & Ridge',
  // 		dataKey: 'HipRidgeData',
  // 	},
  // 	{
  // 		subCategory: 'Ridge Vent',
  // 		dataKey: 'RidgeVentData',
  // 	},
  // ],
  // AUTO_TEXT_ROOFING_SELECTION: [
  // 	{
  // 		subCategory: 'Plywood',
  // 		dataKey: 'plywoodData',
  // 	},
  // 	{
  // 		subCategory: 'Rafter',
  // 		dataKey: 'rafterstudsData',
  // 	},
  // 	{
  // 		subCategory: 'Furring Strip',
  // 		dataKey: 'furringstripsData',
  // 	},
  // 	{
  // 		subCategory: 'Fascia',
  // 		dataKey: 'fasciaData',
  // 	},
  // 	{
  // 		subCategory: 'Capping',
  // 		dataKey: 'cappingData',
  // 	},
  // 	{
  // 		subCategory: 'Trim metals',
  // 		dataKey: 'trimmetalData',
  // 	},
  // 	{
  // 		subCategory: 'Chimney Cap',
  // 		dataKey: 'chimenycapsData',
  // 	},
  // ],
  // AUTO_TEXT_GUTTERS_DATA_ID: {
  // 	Gutters: (metric) =>
  // 		`${metric.style}${metric.type}${metric.color}${metric.size}`,
  // 	Leaders: (metric) =>
  // 		`${metric.style}${metric.type}${metric.color}${metric.size}`,
  // 	'Leader Heads': (metric) => `${metric.type}${metric.color}`,
  // 	Screens: (metric) => `${metric.type}${metric.color}${metric.size}`,
  // },
  // AUTO_TEXT_GUTTERS_SELECTION: [
  // 	{
  // 		subCategory: 'Gutters',
  // 		dataKey: 'guttersSelection',
  // 		totalKey: 'guttersTotal',
  // 	},
  // 	{
  // 		subCategory: 'Leaders',
  // 		dataKey: 'leadersSelection',
  // 		totalKey: 'leadersTotal',
  // 	},
  // 	{
  // 		subCategory: 'Leader Heads',
  // 		dataKey: 'leaderheadsSelection',
  // 		totalKey: 'leaderheadsTotal',
  // 	},
  // 	{
  // 		subCategory: 'Screens',
  // 		dataKey: 'screensSelection',
  // 		totalKey: 'screensTotal',
  // 	},
  // ],
  // AUTO_TEXT_OTHERS: [
  // 	{
  // 		key: 'Removal',
  // 		exists: (metric) => metric.removal_types,
  // 		getData: (metric) => metric.removal_types,
  // 		appendData: true,
  // 	},
  // 	{
  // 		key: 'Flat',
  // 		exists: (metric) =>
  // 			metric.pitch === DIFFICULTY[0].id &&
  // 			CHECK_FLAT.includes(getInstallType[metric.install_types]),
  // 		getData: (metric) =>
  // 			metric.pitch + metric.work_type + metric.install_types,
  // 		appendData: true,
  // 	},
  // 	{
  // 		key: 'Shingles',
  // 		exists: (metric) =>
  // 			metric.work_type !== WORKTYPE[1].id &&
  // 			CHECK_ROOFING.includes(getInstallType[metric.install_types]),
  // 		getData: (metric) => metric.work_type + metric.install_types,
  // 		appendData: true,
  // 	},
  // ],
  ELEVATION,
  ELEVATIONDETAILS,
};

// Removal
// exists: removal_types
// removal_types
// appendData:true

// Flat
// exists: pitch === DIFFICULTY[0].id && CHECK_FLAT.includes(install_types),
// work_type
// install_types
// appendData:true

// Shingles
// exists: CHECK_ROOFING.includes(install_types),
// work_type
// install_types
// appendData:true
