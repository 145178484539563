import React from 'react'
import {
    Form,
} from 'react-bootstrap'

export default function NumberInput(props) {
    return (<Form.Control
        type='number'
        onKeyDown={e =>
            ['e', 'E', '+', '.', '/', '-'].includes(e.key) &&
            e.preventDefault()
        }
        {...props}
    />)
}
