import React, { useState, useEffect } from "react";
import BasePlanTextBox from "components/base-plan-textbox/base-plan-text-box";
import { Row, Col, Button, Form } from "react-bootstrap";
import AdditionalUserInput from "components/additional-user-input/additional-user-input";
import MonthlySubscriptionTotal from "components/monthly-subscription-total/monthly-subscription-total";
import RequiredText from "components/required-text/required-text";
import MaskedInput from "react-maskedinput";

import validator from "validator";
import { checkPhone, checkPassword } from "utils/utils";
import PopupModal from "components/popup-modal/popup-modal";
import { useSelector, useDispatch } from "react-redux";
import { fetchStaticAll } from "lib/redux/static-all/static-all.actions";
import {
  getUsers,
  updateUser,
  addUser,
  deleteUser,
  toggleLock,
  getUserRoles,
  getBasePlan,
  basePlanUserUpdate,
  getAllNotification,
  updateNotification,
} from "lib/redux/user/user.actions";
import { toast } from "react-toastify";
import DeleteModal from "components/DeleteModal";
import InitialLoader from "components/InitilalLoader";
import { subscription } from "lib/constants/const";
import { Link, useHistory } from "react-router-dom";
import SignupAsEdit from "screens/Signup/Signup";
import Box from "@material-ui/core/Box";

import Modal from "@material-ui/core/Modal";
import useCountCheck from "hooks/useCountCheck";
import useSubscription from "hooks/useSubscription";
import PaymentHistory from "./PaymentHistory";

// const basePrice = 15;
const DeleteUserTitle =
  "The selected user will be deleted and no longer have access to the system. However, any data entered will still be available. Proceed with delete?";

const displayText = (text) => (
  <div style={{ textAlign: "center", padding: "0em" }}>
    <p style={{ margin: "0", fontWeight: "bold" }}>{text}</p>
  </div>
);

const getSubscriptionText = (userCount) => {
  return `Organized Roofer is a monthly subscription, the base plan includes (${userCount}) users. Users can be added, edited or changed at anytime.Note: Added user(s) can only be removed after the next billing cycle`;
};

const getSubscriptionSubText = (currency, basePlanUserPrice) => {
  return `Each Additional user ${
    currency ? (currency === "USD" ? "$" : currency) : ""
  }${basePlanUserPrice} per user/ per month (billed after clicking apply).
  Note: Between billing cycles, added user(s) will be prorated and fully billed at next billing cycle`;
};

const LoadingLoader = () => (
  <div className="loading">
    <div className="icon" />
  </div>
);
const styyle = {
  "@media (max-width: 600px)": { width: 500 },
  "@media (min-width: 600px)": { width: 500 },
  "@media (min-width: 821px)": { width: 500 },

  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 300,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  // p: 2,
  overflow: "auto",
  borderRadius: "10px",
};

function RemainingUser({
  USER_ROLES,
  data,
  addNew,
  updateAddUser,
  idx,
  deleteAddUser,
  showDeleteBtn,
}) {
  const [edit, setEdit] = useState(false);
  const [_id, setId] = useState("");
  const [newData, setNewData] = useState(addNew);
  const [lock, setLock] = useState(false);
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({});
  const [processing, setProcessing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && !addNew) {
      setId(data._id);
      setFirst(data.firstName);
      setLast(data.lastName);
      setRole(data?.role[0]?._id);
      setEmail(data.email);
      setLock(data.isLocked);
    }
  }, []);

  const hideDeleteModal = () => setShowDeleteModal(false);
  const deleteData = () => {
    onDeleteUser(_id);
    // deleteAddUser(dltIndex)
    setShowDeleteModal(false);
  };

  const deleteModal = DeleteModal.bind(this, {
    showDeleteModal,
    deleteData: () => deleteData(),
    processing: false,
    heading: "Delete User Confirmation",
    hideDeleteModal,
    dltSubHead: DeleteUserTitle,
  });

  const validate = () => {
    const newErr = {};
    const msg = "Please fill this field.";

    if (!role) {
      newErr.role = msg;
    }
    if (!first.trim()) {
      newErr.first = msg;
    }
    if (!last.trim()) {
      newErr.last = msg;
    }
    if (!email.trim()) {
      newErr.email = msg;
    }

    if (!validator.isEmail(email)) {
      newErr.email = "Please provide valid email.";
    }

    if (newData && !password) {
      newErr.password =
        "Password must be at least 6 characters and must contain one letter and one number.";
    }

    if (password && (!password.match(checkPassword) || password.length < 6)) {
      newErr.password =
        "Password must be at least 6 characters and must contain one letter and one number.";
    }

    if (password && confirmPassword !== password) {
      newErr.confirm = "Password didn't match.";
    }

    if (JSON.stringify(newErr) === "{}") {
      return false;
    }
    setError(newErr);
    return true;
  };

  const onEdit = () => {
    setProcessing(true);
    dispatch(
      updateUser(data._id, {
        role: [role],
        firstName: first,
        lastName: last,
        email,
        password,
      })
    )
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          setEdit(false);
          toast.success("Updated Successfully!");
          setProcessing(false);
          setPassword("");
          setConfirmPassword("");
        } else {
          toast.error(res.error);
          setProcessing(false);
        }
      })
      .catch((err) => {
        toast.error(err.meesage);
        setProcessing(false);
      });
  };

  const saveData = () => {
    if (!validate()) {
      if (newData) {
        setProcessing(true);
        dispatch(
          addUser({
            role: [role],
            firstName: first,
            lastName: last,
            email,
            password,
            isPaid: true,
          })
        )
          .then((res) => res.data)
          .then((res) => {
            if (res.success) {
              setRole("");
              setFirst("");
              setLast("");
              setEmail("");
              setEdit(false);
              toast.success("User Added Successfully!");
              setProcessing(false);
              updateAddUser(idx, {
                _id: res.result.userid,
                role: [role],
                firstName: first,
                lastName: last,
                email,
                password: "",
                confirmPassword: "",
                addNew: false,
              });
              setPassword("");
              setConfirmPassword("");
            } else {
              toast.error(res.error);
              setProcessing(false);
            }
          })
          .catch((err) => {
            toast.error(err.meesage);
            setProcessing(false);
          });
      } else {
        setProcessing(true);
        dispatch(
          updateUser(_id, {
            role: [role],
            firstName: first,
            lastName: last,
            email,
            password,
          })
        )
          .then((res) => res.data)
          .then((res) => {
            if (res.success) {
              setPassword("");
              setConfirmPassword("");
              setEdit(false);
              toast.success("Updated Successfully!");
              setProcessing(false);
            } else {
              toast.error(res.error);
              setProcessing(false);
            }
          })
          .catch((err) => {
            toast.error(err.meesage);
            setProcessing(false);
          });
      }
    }
  };

  const handelChange = (e, callback) => {
    if (error[e.target.name]) {
      setError({
        ...error,
        [e.target.name]: "",
      });
    }
    callback(e.target.value);
  };

  const displayRoleTypes = (types) => {
    const filterData = types.filter((type) => {
      return type.isDeleted !== true && type.roleName !== "Admin";
    });

    return filterData.map(
      (type) => (
        //  type.visible ? (
        <option key={type?._id} value={type?._id}>
          {type?.roleName}
        </option>
      )
      //  ) : null
    );
  };
  const lockOrUnlock = () => {
    setProcessing(true);
    dispatch(toggleLock(_id))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          setLock(res.result.isLocked);
          toast.success(
            `User ${res.result.isLocked ? "locked" : "unlocked"} Successfully!`
          );
          setProcessing(false);
        } else {
          toast.error(res.error);
          setProcessing(false);
        }
      })
      .catch((err) => {
        toast.error(err.meesage);
        setProcessing(false);
      });
  };

  const onDeleteUser = (id) => {
    setProcessing(true);
    dispatch(deleteUser(id))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          toast.success("User deleted Successfully!");
          setProcessing(false);
          deleteAddUser(id);
        } else {
          toast.error(res.error);
          setProcessing(false);
        }
      })
      .catch((err) => {
        toast.error(err.meesage);
        setProcessing(false);
      });
  };

  return (
    <>
      {!newData && (
        <Col lg="1">
          {lock ? (
            <Button
              variant="success"
              disabled={processing}
              onClick={() => lockOrUnlock()}
            >
              Unlock
            </Button>
          ) : (
            <Button
              variant="danger"
              disabled={processing}
              onClick={() => lockOrUnlock()}
              className="buttn_widthh"
            >
              Lock
            </Button>
          )}
        </Col>
      )}
      <Col className="_newCol">
        <Form.Group>
          <Form.Control
            disabled={!edit}
            as="select"
            value={role}
            name="role"
            isInvalid={error && error.role}
            onChange={(event) => handelChange(event, setRole)}
          >
            <option value="">Choose...</option>
            {displayRoleTypes(USER_ROLES)}
          </Form.Control>
        </Form.Group>
        {error && error.role && <p className="err">{error.role}</p>}
      </Col>
      <Col className="_newCol">
        <Form.Group>
          <Form.Control
            disabled={!edit}
            type="text"
            name="first"
            value={first}
            placeholder="Enter first name"
            onChange={(event) => handelChange(event, setFirst)}
            isInvalid={error && error.first}
          />
        </Form.Group>
        {error && error.first && <p className="err">{error.first}</p>}
      </Col>
      <Col className="_newCol">
        <Form.Group>
          <Form.Control
            disabled={!edit}
            type="text"
            name="last"
            value={last}
            placeholder="Enter last name"
            onChange={(event) => handelChange(event, setLast)}
            isInvalid={error && error.last}
          />
        </Form.Group>
        {error && error.last && <p className="err">{error.last}</p>}
      </Col>
      <Col className="_newCol">
        <Form.Group>
          <Form.Control
            disabled={!edit}
            type="text"
            name="email"
            value={email}
            placeholder="Enter Email or Username"
            onChange={(event) => handelChange(event, setEmail)}
            isInvalid={error && error.email}
          />
          {error && error.email && <p className="err">{error.email}</p>}
        </Form.Group>
      </Col>
      <Col
        className="_newCol"
        style={error && error.password ? { marginBottom: "63px" } : {}}
      >
        <Form.Group>
          <Form.Control
            disabled={!edit}
            type="password"
            name="password"
            value={password}
            placeholder="Enter Password"
            onChange={(event) => handelChange(event, setPassword)}
            isInvalid={error && error.password}
          />
          {error && error.password && <p className="err">{error.password}</p>}
        </Form.Group>
      </Col>
      <Col className="_newCol">
        <Form.Group>
          <Form.Control
            disabled={!edit}
            type="password"
            name="confirm"
            value={confirmPassword}
            placeholder="Confirm password"
            onChange={(event) => handelChange(event, setConfirmPassword)}
            isInvalid={error && error.confirm}
          />
        </Form.Group>
        {error && error.confirm && <p className="err">{error.confirm}</p>}
      </Col>
      <Col style={{ maxWidth: "7%" }}>
        {edit ? (
          <Button
            disabled={
              processing ||
              !role ||
              !first ||
              !last ||
              !email ||
              !confirmPassword ||
              !password
            }
            onClick={() => saveData()}
            variant="success"
            className="buttn_widthh"
          >
            Save
          </Button>
        ) : (
          <Button onClick={() => setEdit(true)} className="buttn_widthh">
            Edit
          </Button>
        )}
      </Col>
      {!newData && showDeleteBtn && !edit ? (
        <Col style={{ maxWidth: "6.333333%" }}>
          <Button
            disabled={processing}
            variant="danger"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </Button>
        </Col>
      ) : (
        edit && (
          <Col style={{ maxWidth: "6.333333%" }}>
            <Button
              disabled={!edit}
              variant="danger"
              onClick={() => {
                setPassword("");
                setConfirmPassword("");
                setEdit(false);
                setProcessing(false);
              }}
            >
              Cancel
            </Button>
          </Col>
        )
      )}
      {deleteModal()}
    </>
  );
}

function MainUser({ data, basePlanUsers }) {
  const [edit, setEdit] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [first, setFirst] = useState("" || data.firstName);
  const [last, setLast] = useState("" || data.lastName);
  const [phone, setPhone] = useState("" || data.phone);
  const [email, setEmail] = useState("" || data.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const validate = () => {
    const newErr = {};
    const msg = "Please fill this field.";
    if (!first.trim()) {
      newErr.first = msg;
    }
    if (!last.trim()) {
      newErr.last = msg;
    }
    if (!phone) {
      newErr.phone = msg;
    }
    if (phone && checkPhone(phone)) {
      newErr.phone = "Please provide valid phone.";
    }
    if (!email.trim()) {
      newErr.email = msg;
    }
    if (!validator.isEmail(email)) {
      newErr.email = "Please provide valid email.";
    }

    if (password && (!password.match(checkPassword) || password.length < 6)) {
      newErr.password =
        "Password must be at least 6 characters and must contain one letter and one number.";
    }

    if (password && confirmPassword !== password) {
      newErr.confirm = "Password didn't match.";
    }

    if (JSON.stringify(newErr) === "{}") {
      return false;
    }
    setError(newErr);
    return true;
  };

  const saveData = () => {
    if (!validate()) {
      setProcessing(true);
      dispatch(
        updateUser(data._id, {
          role: data.role,
          firstName: first,
          lastName: last,
          email,
          password,
          phone,
        })
      )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            setEdit(false);
            toast.success("Updated Successfully!");
            setProcessing(false);
          } else {
            toast.error(res.error);
            setProcessing(false);
          }
        })
        .catch((err) => {
          toast.error(err.meesage);
          setProcessing(false);
        });
    }
  };
  const handelChange = (e, callback) => {
    if (error[e.target.name]) {
      setError({
        ...error,
        [e.target.name]: "",
      });
    }
    callback(e.target.value);
  };

  const onEdit = () => {
    setEdit(true);
  };

  return (
    <>
      <Row style={{ fontWeight: "bold", color: "rgb(82, 82, 82)" }}>
        <Col>
          {displayText(
            `Subscription Administrator / One of the ${basePlanUsers} Users`
          )}
        </Col>
      </Row>
      <Row className="_totals_div">
        <Col className="_newCol">
          <Form.Group style={{ textAlign: "center" }}>
            <Form.Label>
              First
              <RequiredText>*</RequiredText>{" "}
            </Form.Label>
            <Form.Control
              disabled={!edit}
              type="text"
              name="first"
              value={first}
              placeholder="Enter first name"
              onChange={(event) => handelChange(event, setFirst)}
              isInvalid={error && error.first}
            />
          </Form.Group>
          {error && error.first && <p className="err">{error.first}</p>}
        </Col>
        <Col className="_newCol">
          <Form.Group style={{ textAlign: "center" }}>
            <Form.Label>
              Last
              <RequiredText>*</RequiredText>
            </Form.Label>
            <Form.Control
              disabled={!edit}
              type="text"
              name="last"
              value={last}
              placeholder="Enter last name"
              onChange={(event) => handelChange(event, setLast)}
              isInvalid={error && error.last}
            />
          </Form.Group>
          {error && error.last && <p className="err">{error.last}</p>}
        </Col>
        <Col className="_newCol">
          <Form.Group style={{ textAlign: "center" }}>
            <Form.Label>
              Phone
              <RequiredText>*</RequiredText>{" "}
            </Form.Label>
            <MaskedInput
              className="form-control"
              disabled={!edit}
              type="text"
              name="phone"
              value={phone}
              mask="(111) 111-1111"
              placeholder="Enter Phone"
              required="required"
              maxLength={10}
              onChange={(event) => handelChange(event, setPhone)}
            />
          </Form.Group>
          {error && error.phone && <p className="err">{error.phone}</p>}
        </Col>

        <Col className="_newCol">
          <Form.Group style={{ textAlign: "center" }}>
            <Form.Label>
              User Name/Email
              <RequiredText>*</RequiredText>
            </Form.Label>
            <Form.Control
              disabled={!edit}
              type="text"
              name="email"
              value={email}
              placeholder="Enter Email or Username"
              onChange={(event) => handelChange(event, setEmail)}
              isInvalid={error && error.email}
            />
          </Form.Group>
          {error && error.email && <p className="err">{error.email}</p>}
        </Col>
        <Col className="_newCol">
          <Form.Group style={{ textAlign: "center" }}>
            <Form.Label>
              Password
              <RequiredText>*</RequiredText>
            </Form.Label>
            <Form.Control
              disabled={!edit}
              type="password"
              name="password"
              value={password}
              placeholder="Enter Password"
              onChange={(event) => handelChange(event, setPassword)}
              isInvalid={error && error.password}
            />
            {error && error.password && <p className="err">{error.password}</p>}
          </Form.Group>
        </Col>
        <Col className="_newCol">
          <Form.Group style={{ textAlign: "center" }}>
            <Form.Label>
              Confirm Password
              <RequiredText>*</RequiredText>
            </Form.Label>
            <Form.Control
              disabled={!edit}
              type="password"
              name="confirm"
              value={confirmPassword}
              placeholder="Confirm password"
              onChange={(event) => handelChange(event, setConfirmPassword)}
              isInvalid={error && error.confirm}
            />
          </Form.Group>
          {error && error.confirm && <p className="err">{error.confirm}</p>}
        </Col>

        <Col
          className="_newCol"
          style={{ maxWidth: "6.333333%", marginTop: "2em" }}
        >
          <div style={{ height: "15px" }} />
          {edit ? (
            <Button disabled={processing} onClick={saveData} variant="success">
              Save
            </Button>
          ) : (
            <Button onClick={onEdit} className="buttn_widthh">
              Edit
            </Button>
          )}
        </Col>
        {edit && (
          <Col
            className="_newCol"
            style={{ maxWidth: "6.333333%", marginTop: "4em" }}
          >
            <Button
              disabled={!edit}
              variant="danger"
              onClick={() => {
                setPassword("");
                setConfirmPassword("");
                setEdit(false);
                setProcessing(false);
              }}
            >
              Cancel
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
}

export default function Subscription() {
  const [openModal, setOpenModal] = useState(false);
  const [tab, setTab] = useState(1);
  const [currency, setCurrency] = useState("USD");
  const [monthlySubs, setMonthlySubs] = useState("");
  const [baseUser, setBaseUser] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [basePrice, setBasePrice] = useState("");

  const [baseplanPriceAdmin, setBaseplanPriceAdmin] = useState("");
  const [basePlanUsers, setBasePlanUsers] = useState("");
  const [applydisable, setApplyDisable] = useState(true);
  const [remainingUsr, setRemainingUsr] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [basePlanRatio, setBasePlanRatio] = useState("");
  const [additionalUser, setAdditionalUser] = useState("");
  const [addUsers, setAddUsers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dltIndex, setDltIndex] = useState("");
  const [permission, setPermission] = useState({});
  const [userRoles, setUserRoles] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [baseUserCount, setBaseUserCount] = useState([]);
  const [admin, setAdmin] = useState(null);
  const [CompanYID, setCompanYID] = useState("");
  const [notification, setNotification] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationId, setNotificationId] = useState("");
  const [previosNumberOfUser, setPreviousNumberOfUser] = useState();
  const [modal, setModal] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const { data, errors, isLoading } = useSelector((state) => state.staticAll);
  const { gettingBasrPlan, baseList, BaselistErr } = useSelector(
    (state) => state.allBasePlan
  );
  const {
    notificationUpdate,
    notificationUpdateList,
    notificationUpdatelistErr,
  } = useSelector((state) => state.notificationupdate);
  
  const { gettingNotification, notificationList, NotificationlistErr } =
    useSelector((state) => state.allnotification);

  const { user } = useSelector((state) => state.login);
  const { userRoleList, UserRoleslistErr, BasePlanlistErr } = useSelector(
    (state) => state.userRoles
  );
  const { basePlanRole, basePlanList, gettingUsersRole } = useSelector(
    (state) => state.basePlan
  );

  const { loading, isError, historyData } = useSubscription(
    user?.userData?.companyId
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { userList, listErr, gettingUsers } = useSelector(
    (state) => state.userList
  );
  const {} = useCountCheck();
  const loadData = () => {
    dispatch(fetchStaticAll());
    dispatch(getUsers());
    dispatch(getUserRoles());
    dispatch(getBasePlan());
    dispatch(getAllNotification());
  };
  useEffect(() => {
    dispatch(getBasePlan());
  }, [tab]);
  useEffect(() => {
    setMonthlySubs(basePrice);
  }, [basePrice]);

  useEffect(() => {
    categorizeUsers();
  }, [allUsers, baseUserCount, baseList]);

  useEffect(() => {
    if (
      baseList?.baseplanUsers !== null &&
      baseList?.baseplanUsers !== undefined
    ) {
      setBasePrice(baseList?.totalPrice);
      setBasePlanUsers(baseList?.baseplanUsers);
      setAdditionalUser(baseList?.additionalUser);
      setBasePlanRatio(baseList?.pricePerAdditionalUser);
      setBaseplanPriceAdmin(baseList?.baseplanPrice);

      const num = Number(baseList?.additionalUser) - Number(addUsers?.length);

      if (num > 0) {
        const users = new Array(num)?.fill({
          newUser: true,
        });
        setNewUsers([...users]);
      }
   
    }
  }, [baseList]);
  useEffect(() => {
    if (basePlanList?.success === true) {
      toast.success("Number of users updated succesfully.");
    }
  }, [basePlanList]);

  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    const filterNotificationData = notificationList?.filter((elem) => {
      return elem.checked !== true && elem.type === "SubScription Admin";
    });
    setNotificationData(filterNotificationData);
  }, [notificationList]);

  useEffect(() => {
    if (
      notificationData !== undefined &&
      notificationData !== null &&
      notificationData?.length > 0
    ) {
      if (notificationData[notificationData?.length - 1]?.checked !== true) {
        setOpenModal(true);
        setNotificationMessage(
          notificationData[notificationData.length - 1]?.message
        );
        setNotificationId(notificationData[notificationData.length - 1]?._id);
      }
    }
  }, [notificationData]);
  useEffect(() => {
    if (notificationUpdateList?.success === true) {
      setOpenModal(false);

      dispatch(getAllNotification());
    }
  }, [notificationUpdateList]);

  useEffect(() => {
    if (userList && userList.length > 0) {
      setAllUsers(userList);
    }
  }, [userList]);

  useEffect(() => {
    if (listErr) {
      if (permission?.write) {
        toast.error("Users list could not be fetched. Please try again later.");
      }
    }
  }, [listErr]);

  useEffect(() => {
    if (baseList) {
      // setUserRoles(data.userRole);
      setBaseUserCount(baseList?.baseplanUsers - 1);
    }
  }, [baseList]);
  useEffect(() => {
    if (userRoleList) {
      setUserRoles(userRoleList);
    }
  }, [userRoleList]);
  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);

  const categorizeUsers = () => {
    if (allUsers?.length > 0 && baseList) {
      const aUsers = [];
      const remainUsers = [];
      allUsers?.map((user, i) => {
        if (user?.role[0]?.roleName?.toLowerCase()?.includes("admin")) {
          return setAdmin(user);
        }
        if (remainUsers.length < baseUserCount) {
          return remainUsers?.push(user);
        }
        return aUsers?.push(user);
      });

      if (
        remainUsers?.length > 0 &&
        baseList?.baseplanUsers !== null &&
        baseList?.baseplanUsers !== undefined
      ) {
        let baseUser = baseList?.baseplanUsers - 1;
        if (baseUser - remainUsers?.length < 0) return;
        setRemainingUsr(remainUsers);
        setRemainingUsr([
          ...remainUsers,
          ...new Array(baseUser - remainUsers?.length)?.fill({
            newUser: true,
          }),
        ]);
      } else {
        let baseUser = baseList?.baseplanUsers - 1;
        setRemainingUsr(
          new Array(baseUser)?.fill({
            newUser: true,
          })
        );
      }

      if (aUsers?.length > 0) {
        setAddUsers(aUsers);
        setBaseUser(aUsers?.length + newUsers?.length);
        setAdditionalUser(aUsers?.length + newUsers?.length);
      } else {
        setAddUsers([]);
        setBaseUser(newUsers?.length);
        setAdditionalUser(newUsers?.length);
      }
    }
  };

  const increaseUser = (increase = true) => {
    setApplyDisable(false);
    let user = additionalUser;
    if (increase) {
      user += 1;
    } else {
      user -= 1;
    }
    setAdditionalUser(user);
  };
  useEffect(() => {
    if (user) {
      setCompanYID(user.userData.companyId);
    }
  }, [user]);
  useEffect(() => {
    setMonthlySubs(baseplanPriceAdmin + additionalUser * basePlanRatio);
  }, [additionalUser]);

  const applyChanges = () => {
    // let basePlanUser = basePlanUsers + additionalUser;
    if (
      !baseList.userChangeTime ||
      Date.now() - baseList.userChangeTime < 300000
    ) {
      if (
        baseList.baseplanUsers + baseList.additionalUser <
        baseList.baseplanUsers + additionalUser
      ) {
        dispatch(
          basePlanUserUpdate({
            companyId: CompanYID,
            baseplanUsers: basePlanUsers,
            baseplanPrice: baseplanPriceAdmin,
            totalPrice: monthlySubs,
            additionalUser: additionalUser,
            userChangeTime: Date.now(),
          })
        ).then((res) => {
          setUpdateLoading(false);
        });

        setBaseUser(additionalUser);
        const num = Number(additionalUser) - Number(addUsers.length);

        if (num > 0) {
          const users = new Array(num)?.fill({
            newUser: true,
          });
          setNewUsers([...users]);
        }

      } else {
        toast.error(
          "You can not decrease the added additional user before 30 days."
        );
        setUpdateLoading(false);
      }
    } else {
      dispatch(
        basePlanUserUpdate({
          companyId: CompanYID,
          baseplanUsers: basePlanUsers,
          baseplanPrice: baseplanPriceAdmin,
          totalPrice: monthlySubs,
          additionalUser: additionalUser,
          userChangeTime: Date.now(),
        })
      ).then((res) => {
        setUpdateLoading(false);
      });

      setBaseUser(additionalUser);
      const num = Number(additionalUser) - Number(addUsers.length);

      const users = new Array(num)?.fill({
        newUser: true,
      });
      setNewUsers([...users]);
    }
  };

  // useEffect(() => {
  //   const num = Number(additionalUser);
  //   - Number(addUsers.length);

  //   const users = new Array(num).fill({
  //     newUser: true,
  //   });
  //   setNewUsers([...users]);
  // }, [additionalUser]);

  const updateAddUser = (index, data) => {
    newUsers.splice(index, 1);
    // setNewUsers(newUsers);
    allUsers.push(data);
    setAllUsers(allUsers);
    categorizeUsers();
  };

  const deleteAddUser = (id) => {
    const temp = [...allUsers];
    const idx = allUsers.findIndex((user) => user._id === id);
    temp.splice(idx, 1);
    setAllUsers([...temp]);
  };

  const getCommonHeader = (hideLast = false) => (
    <Row
      className="remain_user_label"
      style={{
        textAlign: "center",
        maxHeight: "56px",
      }}
    >
      {!hideLast && <Col style={{ maxWidth: "8.333333%" }} />}
      <Col>
        <Form.Label>
          User Role
          <RequiredText>*</RequiredText>
        </Form.Label>
      </Col>
      <Col>
        <Form.Label>
          First
          <RequiredText>*</RequiredText>{" "}
        </Form.Label>
      </Col>
      <Col>
        <Form.Label>
          Last
          <RequiredText>*</RequiredText>
        </Form.Label>
      </Col>
      <Col>
        <Form.Label>
          User Name/Email
          <RequiredText>*</RequiredText>{" "}
        </Form.Label>
      </Col>
      <Col>
        <Form.Label>
          Password
          <RequiredText>*</RequiredText>{" "}
        </Form.Label>
      </Col>
      <Col style={{ position: "relative", right: "10px" }}>
        <Form.Label>
          Confirm Password
          <RequiredText>*</RequiredText>
        </Form.Label>
      </Col>
      <Col style={{ maxWidth: "6.333333%" }} />
      {!hideLast && <Col style={{ maxWidth: "6.333333%" }} />}
    </Row>
  );

  const displayUserDetails = () => (
    <>
      {remainingUsr.length > 0 && getCommonHeader()}
      {remainingUsr.map((user, i) => (
        <Row
          className="_totals_div"
          key={user._id}
          style={{ alignItems: "baseline" }}
        >
          <RemainingUser
            USER_ROLES={userRoles}
            data={user}
            key={user._id}
            updateAddUser={updateAddUser}
            idx={i}
            deleteAddUser={deleteAddUser}
            showDeleteBtn
            addNew={user.newUser}
          />
        </Row>
      ))}
    </>
  );

  const AdditionalUsers = () => (
    <>
      {(addUsers.length > 0 ||
        newUsers.length > 0 ||
        additionalUser.length > 0) && (
        <>
          <Row style={{ marginTop: "2em" }}>
            <Col>{displayText("Additional Users")}</Col>
          </Row>
          {getCommonHeader(true)}
        </>
      )}

      {addUsers.map((user, i) => (
        <Row
          className="_totals_div"
          key={user._id ? user._id : i}
          style={{ alignItems: "baseline" }}
        >
          <RemainingUser
            USER_ROLES={userRoles}
            data={user}
            addNew={user.newUser}
            updateAddUser={updateAddUser}
            idx={i}
            deleteAddUser={deleteAddUser}
            showDeleteBtn
          />
        </Row>
      ))}
      {newUsers.map((user, i) => (
        <Row
          style={{ alignItems: "baseline" }}
          className="_totals_div"
          key={user._id ? user._id : i}
        >
          <RemainingUser
            USER_ROLES={userRoles}
            data={user}
            addNew={user.newUser}
            updateAddUser={updateAddUser}
            idx={i}
            deleteAddUser={deleteAddUser}
            showDeleteBtn
          />
        </Row>
      ))}
    </>
  );

  if (gettingUsers || errors || listErr)
    return (
      <div className="">
        <InitialLoader error={listErr} onReload={loadData} />
      </div>
    );
  const hideModal = () => {
    dispatch(updateNotification(notificationId));
  };

  const onHide = () => {
    setModal(false);
  };

  if (updateLoading) {
    return <InitialLoader />;
  }

  return (
    <>
      <PopupModal
        heading="Payment Portal For Organized Roofer"
        show={modal}
        onHide={onHide}
      >
        <p>
          Additional user(s) will be prorated for the current month and cannot
          be removed until after the next billing cycle.
        </p>
        <div style={{ display: "flex", float: "right" }}>
          <div className="_alignBtn" style={{ margin: "5px" }}>
            <Button
              variant="danger"
              onClick={() => {
                // setApplyDisable(true);
                setModal(false);
                // applyChanges();
              }}
            >
              Cancel
            </Button>
          </div>

          <div className="_alignBtn" style={{ margin: "5px" }}>
            <Button
              onClick={() => {
                // setApplyDisable(true);
                setUpdateLoading(true);
                applyChanges();
                setModal(false);
              }}
            >
              Accept
            </Button>
          </div>
        </div>
      </PopupModal>
      <ul
        className="nav nav-tabs"
        style={{
          marginLeft: "calc(1vw + 0.5em)",
        }}
      >
        <li className="nav-item" onClick={() => setTab(1)}>
          <a
            className={tab == 1 ? "nav-link active" : "nav-link"}
            aria-current="page"
          >
            Subscription
          </a>
        </li>
        <li className="nav-item" onClick={() => setTab(2)}>
          <a className={tab == 2 ? "nav-link active" : "nav-link"}>
            Subscription Information
          </a>
        </li>
        <li className="nav-item" onClick={() => setTab(3)}>
          <a className={tab == 3 ? "nav-link active" : "nav-link"}>
            Company Payment History
          </a>
        </li>
      </ul>

      <div
        className="_container_right _user_page"
        style={{ height: "calc(94vh - 130px)" }}
      >
        <Row className="_scrollable_users">
          {tab == 2 ? (
            <SignupAsEdit
              type="edit"
              company_id_edit={user.userData.companyId}
              from={"client_admin"}
              user={user.userData}
            />
          ) : tab == 1 ? (
            <Col>
              <div style={{ textAlign: "center", padding: "1em" }}>
                <p
                  style={{
                    marginBottom: ".4em",
                    fontWeight: "bold",
                    color: "rgb(82, 82, 82)",
                  }}
                  className="text-xs"
                >
                  <Link
                    to={{
                      pathname: "/dashboard/help",
                      search: "Admin Subscription User",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    style={{ cursor: "pointer" }}
                  >
                    <span style={{ color: "red", fontWeight: "bolder" }}>
                      ?
                    </span>
                  </Link>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  {getSubscriptionText(basePlanUsers)}
                </p>
                <p
                  style={{
                    margin: "0",
                    fontWeight: "bold",
                    color: "rgb(82, 82, 82)",
                  }}
                  className="text-xs"
                >
                  {getSubscriptionSubText(
                    currency,
                    baseList?.pricePerAdditionalUser
                  )}
                </p>
              </div>

              <Row className="_totals_div">
                <Col lg="5">
                  <BasePlanTextBox
                    align="center"
                    baseplanPriceAdmin={baseplanPriceAdmin}
                    currency={currency}
                    basePlanUsers={basePlanUsers}
                  />
                </Col>
                <Col>
                  <AdditionalUserInput
                    label="Additional User"
                    currency={currency}
                    additionalPricePerUser={5}
                    type="number"
                    name="additionalUser"
                    readOnly
                    value={additionalUser}
                  >
                    <Button
                      disabled={
                        additionalUser === 0 ||
                        Date.now() - baseList?.userChangeTime < 2592000000
                      }
                      onClick={() => {
                        increaseUser(false);
                        setApplyDisable(false);
                      }}
                      style={{ margin: "0 10px" }}
                      variant="outline-dark"
                    >
                      -
                    </Button>

                    <Button
                      onClick={() => increaseUser()}
                      variant="outline-dark"
                    >
                      +
                    </Button>
                  </AdditionalUserInput>
                </Col>
                <Col>
                  <MonthlySubscriptionTotal
                    currency={currency}
                    type="text"
                    name="monthlySubscription"
                    readOnly
                    value={`${
                      currency ? (currency === "USD" ? "$" : currency) : ""
                    }${monthlySubs}`}
                  />
                </Col>
                <Col>
                  <div className="_alignBtn">
                    <Button
                      onClick={() => {
                        if (baseList.additionalUser <= additionalUser) {
                          setModal(true);
                        } else {
                          applyChanges();
                        }
                      }}
                      disabled={applydisable}
                    >
                      Apply
                    </Button>
                  </div>
                </Col>
                {/* <Col lg="2">
                  <div className="_alignBtn">
                    <Button>
                      <Link
                        to={{
                          pathname: `/subscription-information`,
                          state: { type: "edit" },
                        }}
                      >
                        Edit Subscription Informations
                      </Link>
                    </Button>
                  </div>
                </Col> */}
              </Row>
              {admin && <MainUser data={admin} basePlanUsers={basePlanUsers} />}

              {remainingUsr.length > 0 && (
                <Row
                  style={{
                    marginTop: "4em",
                    fontWeight: "bold",
                    color: "rgb(82, 82, 82)",
                  }}
                >
                  <Col>
                    {displayText("Remaining Users Included with subscription")}
                  </Col>
                </Row>
              )}
              {displayUserDetails()}

              {AdditionalUsers()}
            </Col>
          ) : (
            <div>
              <PaymentHistory
                historyData={historyData}
                loading={loading}
                baseList={baseList}
              />
            </div>
          )}
        </Row>
      </div>
      <Modal
        keepMounted
        open={openModal}
        // onClose={hideModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styyle}>
          <div
            style={{
              backgroundColor: "yellow",
              textAlign: "center",
              padding: "2%",
              borderRadius: "0 0 15px 15px",
            }}
          >
            <h3>Organized Roofer Notification</h3>
          </div>
          <div style={{ padding: "20px", height: "53%", overflow: "auto" }}>
            <h4>{notificationMessage}</h4>
          </div>
          <div style={{ width: "72%", float: "right", paddingRight: "2%" }}>
            <button className="btn btn-primary" onClick={hideModal}>
              I, the Organized Roofer subscription administrator acknowledges
              this notification
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
