import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import PickerDate from "react-datepicker";

export default function SalesCard({
  id,
  name,
  fullAdd,
  tel,
  time,
  visit,
  created,
  project,
  sales,
  roof,
  gutters,
  changeStage,
  allowStageChange,
  isChanging,
  proposalSent,
  followUp,
  updatedVisible,
  updatedOn,
  updatedText,

  handleDateChange,
  RedirectTo,
  data,
  paymentStatus,
}) {
  return (
    <>
      {name?.length > 0 && (
        <div className="_sales-card">
          <div className="p-details" style={{ maxWidth: "300px" }}>
            {data?.companyName && <div className="names"> {data?.companyName}</div>}
            <div className="names">
              {name?.length > 0 &&
                name?.map((data, i) => (
                  <h5 key={i}>
                    {data?.name}
                    {/* <span className="sub">({data.type})</span> */}
                  </h5>
                ))}
            </div>
            {tel?.length > 0 && (
              <div className="tels">
                {tel?.map((data, i) => (
                  <div key={i} className="tel">
                    {data?.phone}
                    {/* <span className="sub">({data.type})</span> */}
                  </div>
                ))}
              </div>
            )}
            <a
              // href=  `` "https://www.google.com/maps/place/"
              href={`https://www.google.com/maps/place?q=${fullAdd}`}
              className="address"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              {fullAdd}
            </a>
          </div>
          <div className="o-details" style={{ margin: "0 1em" }}>
            <div className="bold">
              Time: <span className="bold">{time}</span>
            </div>
            <div className="bold">
              Visit: <span className="bold">{moment(visit).format("LL")}</span>
            </div>
            {proposalSent && (
              <div>
                <label>Proposal Sent:</label> <span>{proposalSent}</span>
              </div>
            )}
            {followUp && (
              <div>
                <label className="_label">Follow Up:</label>
                <PickerDate
                  value={followUp}
                  onChange={(date) => handleDateChange(id, date)}
                  className="_date_picker2"
                />
              </div>
            )}
            {updatedVisible && (
              <div>
                <label>{updatedText}:</label> {moment(updatedOn).format("LL")}
              </div>
            )}

            <div>
              <label>Project:</label> <span>{project}</span>
            </div>
            {sales && (
              <div>
                <label>Sales:</label> <span>{sales}</span>
              </div>
            )}
            <div>
              <label>Created:</label>{" "}
              <span>{moment(created).format("LL")}</span>
            </div>
          </div>
          <div className="_btns">
            <Row style={{ flexDirection: "column" }}>
              <Col style={{ marginBottom: "1em" }}>
                <Button
                  className="_btn_modified"
                  onClick={changeStage}
                  disabled={
                    false
                    // !allowStageChange || isChanging || paymentStatus === "Frozen 1"
                  }
                >
                  Change Stage
                </Button>
              </Col>
              <Col style={{ marginTop: "1em" }}>
                <Button className="_btn_modified">
                  <a
                    // href=  `` "https://www.google.com/maps/place/"
                    href={`https://www.google.com/maps/place?q=${fullAdd}`}
                    className="address"
                    // style={{ textDecoration: "underline" }}
                    target="_blank"
                  >
                    Directions
                  </a>
                </Button>
              </Col>
            </Row>
          </div>
          <div className="_btns">
            <Row style={{ flexDirection: "column" }}>
              <Col style={{ marginBottom: "1em" }}>
                <Button
                  onClick={() => RedirectTo("roof", data)}
                  className="_btn_modified"
                  disabled={!roof || paymentStatus == "Frozen 1"}
                >
                  Roof
                </Button>
              </Col>
              <Col style={{ marginTop: "1em" }}>
                <Button
                  onClick={() => RedirectTo("gutters", data)}
                  className="_btn_modified"
                  disabled={!gutters || paymentStatus == "Frozen 1"}
                >
                  Gutters
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      )}

      {/* <h5 style={{ margin: "7% 2% 3%" }}>Appointments Not Acted Upon</h5>
      {stageBeforeData?.length > 0 ? (
        stageBeforeData?.map((res) => {
          return (
            <div className="_sales-card">
              <div className="p-details" style={{ maxWidth: "300px" }}>
                <a className="address">{res?.address}</a>
                <div className="names">
                  {res?.name?.length > 0 &&
                    res?.name.map((data, i) => <h5 key={i}>{data.name}</h5>)}
                </div>
                {res?.phone?.length > 0 && (
                  <div className="tels">
                    {res?.phone?.map((data, i) => (
                      <div key={i} className="tel">
                        {data.phone}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="o-details" style={{ margin: "0 1em" }}>
                <div className="bold">
                  Time: <span className="bold">{res?.time}</span>
                </div>
                <div className="bold">
                  Visit:{" "}
                  <span className="bold">
                    {moment(res?.visit).format("LL")}
                  </span>
                </div>
              
                {res?.updatedVisible && (
                  <div>
                    <label>{res?.updatedText}:</label>{" "}
                    {moment(res?.updatedOn).format("LL")}
                  </div>
                )}

                <div>
                  <label>Project:</label> <span>{res?.project}</span>
                </div>
                {res?.sales && (
                  <div>
                    <label>Sales:</label> <span>{res?.sales}</span>
                  </div>
                )}
                <div>
                  <label>Created:</label>{" "}
                  <span>{moment(res?.created).format("LL")}</span>
                </div>
              </div>
              <div className="_btns">
                <Row style={{ flexDirection: "column" }}>
                  <Col style={{ marginBottom: "1em" }}>
                    <Button
                      className="_btn_modified"
                      onClick={() => changeStageBefore(res.requestId)}
                      disabled={!allowStageChange || isChanging}
                    >
                      Change Stage
                    </Button>
                  </Col>
                  <Col style={{ marginTop: "1em" }}>
                    <Button className="_btn_modified">
                      <a
                        href={`https://www.google.com/maps/place?q=${res?.address}`}
                        className="address"
                        target="_blank"
                      >
                        Directions
                      </a>
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="_btns">
                <Row style={{ flexDirection: "column" }}>
                  <Col style={{ marginBottom: "1em" }}>
                    <Button
                      onClick={() => RedirectTo("roof", res)}
                      className="_btn_modified"
                      disabled={!res?.hasRoof}
                    >
                      Roof
                    </Button>
                  </Col>
                  <Col style={{ marginTop: "1em" }}>
                    <Button
                      onClick={() => RedirectTo("gutters", res)}
                      className="_btn_modified"
                      disabled={!res?.hasGutters}
                    >
                      Gutters
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })
      ) : (
        <h6 style={{ textAlign: "center", marginBottom: "2%" }}>
          No Previous Appointments
        </h6>
      )} */}
    </>
  );
}
