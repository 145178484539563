const metricSelection = (category, metricData, key) => {
  let data = [];

  if (metricData.length === 0) return data;

  metricData.forEach((pricing) => {
    const { getSubType, getCategoryType } = category;

    const manufacturer = "";
    const series = category.getSeries(pricing);
    const qty = category.getQty(pricing);
    const subType = getSubType ? getSubType(pricing) : "";
    const categoryType = getCategoryType ? getCategoryType(pricing) : "";

    const uom = category.uom;
    const uomPrice = Number(0).toFixed(2);
    const total = Number(0).toFixed(2);

    let original_list = {
      manufacturer: manufacturer,
      series: series,
      qty: qty,
    };

    const newData = {
      _id: "123456789",
      manufacturer,
      series,
      qty: qty,
      uom,
      category: key === "Fascia" ? `Fascia - ${categoryType}` : key,
      fromMetric: true,
      uomPrice,
      total,
      subType,
      categoryType,
      original_list: original_list,
    };

    data.push(newData);
  });

  return data;
};

export default metricSelection;
