"use strict";
/**
 *@description constant data
 *@author Ajit
 *@since Nov 9, 2022
 */

const data = {
  add_to_options: {
    CATEGORY: "category",
    MANUFACTURER: "manufacturer",
    SUBTYPE: "subType",
    CATEGORYTYPE: "categoryType",
    ROOFING: "Roofing",
    STARTER: "Starter",
    HIP_AND_RIDGE: "Hip & Ridge",
  },

  material_categories: {
    CATEGORIES: "categories",
    MANUFACTURERS: "manufacturers",
    SUBTYPES: "subTypes",
    CATEGORYTYPES: "categoryTypes",
    UOMS: "uoms",
  },

  get_uom_category_options: {
    SHINGLES: "Shingles",
    UNDERLAYMENTS: "Underlayments",
    FLAT_ROOFING: "Flat Roofing",
    CHIMNEY_CAP: "Chimney Cap",
    FLASHINGS: "Flashings",
    METALSHEETS: "Metal Sheets",
    COIL_ROLLS: "Coil Rolls",
    FASCIA: "Fascia",
    WOOD: "Wood",
    NAILS: "Nails",
    DRIP_EDGING: "Drip Edging",
    FLAT_ROOFING_COATING: "Flat Roofing Coating",
    FLAT_ROOFING_ADHESIVE: "Flat Roofing Adhesive",
    VENTILATION: "Ventilation",
    SHINGLE_ROOFING: "Shingle Roofing",
    SKYLIGHTS: "Skylights",
    ROOFING_ACCESSORIES: "Roofing Accessories",
    CAPPING: "Capping",
    GUTTER_OR_LEADER: "Gutters/Leaders",
  },

  get_uom_categoryType_options: {
    VENT_PIPE: "Vent Pipe",
    PLYWOOD: "Plywood",
    RAFTERORSTUD: "Rafter/Stud",
    FURRING_STRIPS: "Furring Strips",
    COIL: "Coil",
    TRIM: "Trim",
    HAND: "Hand",
    COMMON: "Common",
    VALLEY: "Valley",
    VENT: "Vent",
    FELT_PAPER: "Felt Paper",
    SYNTHETIC: "Synthetic",
    LEAK_BARRIER: "Leak Barrier",
    SCREENS: "Screens",
    GUTTERS: "Gutters",
    LEADERS: "Leaders",
    RIDGE_VENT: "Ridge Vent",
    EVE: "Eve",
    RAKE: "Rake",
    STEP: "Step",
    LOUVERS: "Louvers",
    POWER_FAN: "Power Fan",
    FLAT_ROOF_ADHESIVE: "Flat Roof Adhesive",
  },

  subTypes: {
    ALUMINIUM: "Aluminum",
    VINYL: "Vinyl",
    COPPER: "Copper",
    THREE_TAB: "3-tab",
    ARCHITECHTURAL: "Architechtural",
    DESIGNER: "Designer",
  },

  create_notifications_options: {
    SUBSCRIPTION_ADMIN: "SubScription Admin",
  },

  clear_notification_options: {
    BROADCAST: "Broadcast",
  },

  get_all_subCrews_service_options: {
    OTHER: "Other",
  },

  delete_subType_options: {
    VIDEO: "video",
  },

  value: {
    CHIMNEY: "Chimney",
    WALL: "wall",
  },

  messages: {
    SUCCESS_MESSAGE: "Successfully created account",
    USER_ALREADY_EXISTS: "User already exists",
    EMAIL_PASSWORD_WRONG_ERROR_MESSAGE: "Email and/or password is wrong.",
    USER_DONT_EXIST_MESSAGE: "User doesn't exists",
    UPDATE_ERROR_MESSAGE: "Error while updating. Please try again later",
    BASEPLAN_NOT_FOUND_MESSAGE: "Not found base plan",
    SOMETHING_WENT_WRONG_MESSAGE: "Something went wrong",
    UPDATE_SUCCESS_MESSAGE: "Task is successfully updated.",
    DELETE_TASK_MESSAGE: "Task is marked as deleted.",
    INCORRECT_DATA_MESSAGE: "Put correct data",
    NO_AUTHORIZES_PERMISSION_MESSAGE:
      "You are not authorized to perform this operation.",
    INVALID_ID_MESSAGE: "Invalid ID provided.",
    UPDATE_FOLLOWUP_SUCCESS_MESSAGE: "Follow up updated successfully.",
    SERVICE_REQUEST_UPDATE_SUCCESS_MESSAGE:
      "Service request is updated successfully.",
    PROCESS_REQUEST_FAILURE_MESSAGE:
      "Unable to process request. Please try again later.",
    SUPPLIER_PRICING_DATA_ERROR_MESSAGE:
      "Error while fetching the pricing of a supplier.",
    ACCOUNT_SUSPENDED_MESSAGE:
      "Account is suspended. Please contact your admin.",
    TOKEN_MISSING_MESSAGE: "Token is missing",
    REQUESTED_DATA_DOSENT_EXIST_MESSAGE: "The requested data doesn't exists.",
    EVENT_SCHEDULE_SUCCESS_MESSAGE: "Event has been scheduled successfully.",
    EVENT_SCHEDULE_FAIL_MESSAGE: "Event has been deleted successfully.s",
    SERVICE_TYPE_SELECTION_MESSAGE: "Please select a service type.",
    LEAD_ADDED_MESSAGE: "Lead has been added successfully.",
    LEAD_UPDATTED_MESSAGE: "Lead has been updated successfully.",
    PUT_CORRECT_DATA_MESSAGE: "Put correct data",
    SEND_PROPER_QUERY_MESSAGE: "send proper query",
    INVALID_ID_SUPPLIER_MESSAGE: "Invalid ID of a supplier.",
    VERIFY_EMAILID_MESSAGE: "Please verify your email id to login.",
    INACTIVE_ACCOUNT_MESSAGE:
      "Your account is not active. Please contact your admin.",
    LOCKED_ACCOUNT_MESSAGE:
      "This account has been locked. Please contact your subscription admin.",
    SUCCESS_MESSAGE: "Success",
    INVALID_REQUEST_MESSAGE: "Invalid request.",
    USER_ALREADY_VERIFIED_MESSAGE: "The user is already verified.",
    ERROR_WHILE_SENDING_EMAIL_MESSAGE:
      "Error while sending email. Please try again, later.",
    CHECK_EMAIL_TO_VERIFY_ACCOUNT_MESSAGE:
      "Please check your registered email to verify your account.",
    CHECK_EMAIL_TO_RESET_PASSWORD_MESSAGE:
      "Please check your registered email to reset your password.",
    PASSWORD_UPDATED_SUCCESS_MESSAGE: "Your password is updated successfully.",
    NO_FILE_UPLOADED_MESSAGE: "No file uploaded",
    NO_LEADID_UPLOADED_MESSAGE: "No leadId uploaded",
    FILES_UPLOAD_SUCCESS_MESSAGE: "File(s) are uploaded successfully.",
    PERMISSION_DENIED_MESSAGE: "Permission denied.",
    USER_UPDATED_SUCCESS_MESSAGE: "User updated successfully.",
    EMAIL_CANNOT_BE_ADDED_TO_THE_USER_MESSAGE:
      "Provided email address cannot be added to the user.",
  },

  carting_data: {
    TRUCKS: "trucks",
    PRICINGTYPE_TRUCKS: "Trucks",
    DUMPSTERS: "dumpsters",
    PRICINGTYPE_DUMPSTERS: "Dumpsters",
  },

  role: {
    ADMIN: "admin",
    SALES: "sales",
    REPAIR: "repair",
    EXECUTIVE: "executive",
    PROJECT_MANAGER: "project_manager",
    SALES_MANAGER: "sales_manager",
    RECEPTIONIST: "receptionist",
    OFFICE_MANAGER: "office_manager",
    FOREMAN: "foreman",
  },

  sales_stage: {
    ID0: "activityStatus00",
    ID1: "activityStatus01",
    ID2: "salesStage102",
    ID3: "salesStage103",
    ID4: "salesStage104",
    ID5: "salesStage105",
    ID6: "salesStage106",
    ID7: "salesStage107",
    ID8: "salesStage108",
  },

  repair_stage: {
    ID0: "activityStatus00",
    ID1: "activityStatus01",
    ID2: "repairStage102",
    ID3: "repairStage103",
    ID4: "repairStage104",
    ID5: "repairStage105",
  },
};

export default data;
