/* eslint-disable import/prefer-default-export */
import PriceInput from "components/PriceIput/PriceInput";
import React, { useState, useRef, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

const styles = {
  btnWrapper: {
    flex: "0 0 10%",
  },
  priceInp: {
    flex: "0 0 12%",
  },
};

function NonDraggagleBlock({ text, setList, list }) {
  const [inpDisabled, setInpDisabled] = useState(true);
  const inpRef = useRef(null);

  const hanldeClick = () => {
    setInpDisabled(false);
    if (inpRef.current) {
      inpRef.current.focus();
    }
  };

  const onBlur = () => {
    setInpDisabled(true);
  };

  const title = inpDisabled ? "Tap to Edit" : "";
  return (
    <div className="__proposal_tab_drag_data">
      <div
        className="inp"
        onClick={hanldeClick}
        role="button"
        title={title}
        tabIndex="-1"
      >
        <Form.Control
          //   onChange={(e) => setDisplayText(e.target.value)}
          value={text}
          type="text"
          disabled={inpDisabled}
          onBlur={onBlur}
          ref={inpRef}
        />
      </div>

      <div className="inp" style={styles.priceInp}>
        <Form.Group>
          <Form.Control
            // disabled={!edit}
            type="text"
          />
        </Form.Group>
      </div>
    </div>
  );
}

export function NonDraggableList({
  list,
  setList,
  showPercentageStepperModal,
  isLockQuoteData,
  totalWithMarkUp,
}) {
  const [totalInp, setTotalInp] = useState(false);
  const [totalText, setTotalText] = useState(null);
  const percentageBtnStyle = {
    cursor: "pointer",
    textAlign: "center",
    //wordBreak: "break-all",
  };

  useEffect(() => {
    list.map((value, idx) => {
      if (value.dataId === "STD011") {
        setTotalText(value.displayText);
      }
    });
  }, [list]);
  const handleSave = () => {
    setTotalInp(false);
    let newList = list.map((value) => {
      if (value.dataId === "STD011") {
        return { ...value, displayText: totalText };
      } else {
        return value;
      }
    });
    setList([...newList]);
  };

  return (
    <div>
      {list.map((item, idx) => {
        const hasPercentageProperty = item.percentage !== undefined;
        const text = hasPercentageProperty
          ? item.displayText.replace(":", ` ${item.percentage}%: `)
          : item.displayText;

        return (
          <Row key={idx}>
            <Col style={{ marginBottom: "15px" }}>
              <Form.Group>
                <div
                  onClick={() => {
                    if (!totalInp) setTotalInp(true);
                  }}
                >
                  <Form.Control
                    value={item.dataId === "STD011" ? totalText : text}
                    disabled={item.dataId === "STD011" ? !totalInp : true}
                    onChange={(e) => {
                      if (item.dataId === "STD011") {
                        setTotalText(e.target.value);
                      }
                    }}
                    type="text"
                    style={totalInp ? {} : { pointerEvents: "none" }}
                  />
                </div>

                {totalInp && item.dataId === "STD011" && (
                  <div>
                    <Button
                      onClick={() => {
                        handleSave();
                      }}
                      style={{ marginRight: "0.5rem" }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        setTotalInp((prev) => false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </Form.Group>
            </Col>
            {item.price !== undefined && item.price !== "" && (
              <Col style={styles.priceInp}>
                {hasPercentageProperty ? (
                  <button
                    onClick={() => showPercentageStepperModal(item, idx)}
                    style={percentageBtnStyle}
                    className="form-control"
                    disabled={isLockQuoteData?.isLocked}
                  >
                    $
                    {parseFloat(
                      (item.percentage / 100) * totalWithMarkUp
                    ).toFixed(2)}
                  </button>
                ) : //item.displayText === "Total:"
                item.dataId === "STD011" ? (
                  <button
                    style={percentageBtnStyle}
                    className="form-control"
                    disabled={isLockQuoteData?.isLocked}
                  >
                    ${parseFloat(totalWithMarkUp).toFixed(2)}
                  </button>
                ) : item.dataId === "STD013" ? (
                  <button
                    style={percentageBtnStyle}
                    className="form-control"
                    disabled={isLockQuoteData?.isLocked}
                  >
                    {parseFloat(
                      (1 -
                        list[2].percentage / 100 -
                        list[3].percentage / 100) *
                        totalWithMarkUp
                    ).toFixed(2)}
                  </button>
                ) : null}
              </Col>
            )}
          </Row>
        );
      })}
    </div>
  );
}
