import {
  YesNoOptionsForContact,
  serviceRequestsForContact,
} from "lib/constants/ui-const";

import React, { Component, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Form,
  Modal,
  InputGroup,
} from "react-bootstrap";

import "../contact.scss";
import { styles } from "../styles";

const validateServiceRequestModalFields = ({
  isRepair,
  saleSelect,
  repairSelect,
  repairDetail,
}) => {
  if (isRepair === null) {
    return true;
  }

  if (!isRepair && !saleSelect.sales01 && !saleSelect.sales02) {
    return true;
  }

  if (
    isRepair &&
    !repairSelect.repair01 &&
    !repairSelect.repair02 &&
    !repairSelect.repair03 &&
    !repairSelect.repair04 &&
    !repairSelect.repair05 &&
    !repairSelect.repair06
  ) {
    return true;
  }

  if (isRepair && repairSelect.repair06 && !repairDetail.requestForOther) {
    return true;
  }

  // if (repair && (!originalWorkPerformed || !repairDescription)) {
  //   return true;
  // }

  // if (
  //   repair &&
  //   (originalWorkPerformed === "true" || originalWorkPerformed === true) &&
  //   (!WorkPerformedYear || !isUnderWarrenty)
  // ) {
  //   return true;
  // }

  // if (
  //   repair &&
  //   (originalWorkPerformed === "false" ||
  //     originalWorkPerformed === false ||
  //     isUnderWarrenty === "false" ||
  //     isUnderWarrenty === false) &&
  //   (!nrTotal || !taxRate || !repairServicePrice || !serviceChargePolicy)
  // ) {
  //   return true;
  // }

  return false;
};

const validateServiceRequestFields = () => {
  if (
    repair &&
    (originalWorkPerformed === "true" || originalWorkPerformed === true) &&
    WorkPerformedYear < 0
  ) {
    setErrMsg("Year cannot be negative value.");
    setErrType("warning");
    setErrFor("sr-modal");
    return false;
  } else if (
    repair &&
    (originalWorkPerformed === "true" || originalWorkPerformed === true) &&
    !validator.isNumeric(WorkPerformedYear)
  ) {
    setErrMsg("A valid year is required.");
    setErrType("warning");
    setErrFor("sr-modal");
    setErrAt("WorkPerformedYear");
    return false;
  } else if (
    repair &&
    (originalWorkPerformed === "true" || originalWorkPerformed === true) &&
    validator.isNumeric(WorkPerformedYear) &&
    WorkPerformedYear === "0000"
  ) {
    setErrMsg("A valid year is required.");
    setErrType("warning");
    setErrFor("sr-modal");
    setErrAt("WorkPerformedYear");

    return false;
  } else if (
    repair &&
    (originalWorkPerformed === "true" || originalWorkPerformed === true) &&
    WorkPerformedYear.length < 4
  ) {
    setErrMsg("A valid year is required.");
    setErrType("warning");
    setErrFor("sr-modal");
    setErrAt("WorkPerformedYear");
    return false;
  } else if (repair && repairServicePrice !== "" && repairServicePrice < 0) {
    setErrMsg("Repair/Service Price cannot be negative value.");
    setErrType("warning");
    setErrFor("sr-modal");
    setErrAt("repairServicePrice");
    return false;
  } else {
    setErrMsg("");
    setErrType("");
    setErrFor("");
    setErrAt("");
    onHide();
    return true;
  }
};

const DisplayRequests = ({ requests, isRepair, setIsRepair }) => {
  return requests?.map((request) => (
    <button
      id={request.state}
      key={request.state}
      className={`serviceRequestBtn ${
        (isRepair && request.state === "repair") ||
        (isRepair === false && request.state === "newInstallation")
          ? "selected"
          : ""
      }`}
      onClick={() => {
        if (request.state === "repair") setIsRepair(true);
        else setIsRepair(false);
      }}
    >
      {request.name}
    </button>
  ));
};

export const DisplayServiceRequestOption = ({
  isRepair,
  setIsRepair,
  onServiceRequestModalCancel,
  setShowNext,
}) => {
  return (
    <div>
      <strong>
        Is this service request for a new installation and/or a repair?
      </strong>
      <div style={{ margin: "1em auto", width: "100%" }}>
        <DisplayRequests
          requests={serviceRequestsForContact}
          isRepair={isRepair}
          setIsRepair={setIsRepair}
        />
      </div>
      <Modal.Footer>
        <Button variant={"danger"} onClick={onServiceRequestModalCancel}>
          Cancel
        </Button>
        <Button
          id="nextBtn"
          a
          disabled={isRepair === null}
          onClick={() => setShowNext(true)}
        >
          Next
        </Button>
      </Modal.Footer>
    </div>
  );
};

export const DisplayFooter = ({
  isRepair,
  saleSelect,
  repairSelect,
  repairDetail,
  saveServiceRequest,
  onServiceRequestModalCancel,
  setShowNext,
  serviceRequestModalType = undefined,
}) => {
  const styles = {
    display: "flex",
  };
  const handleOnClickSchedule = () => {
    let newInstallationFor = [];
    let repairFor = [];

    if (sales01) {
      newInstallationFor.push("sales01");
    }

    if (sales02) {
      newInstallationFor.push("sales02");
    }
    AddIntoArray(repairFor, repair01, "repair01");
    AddIntoArray(repairFor, repair02, "repair02");
    AddIntoArray(repairFor, repair03, "repair03");
    AddIntoArray(repairFor, repair04, "repair04");
    AddIntoArray(repairFor, repair05, "repair05");
    AddIntoArray(repairFor, repair06, "repair06");
    const children = newInstallationFor.concat(repairFor);
    const isRepairRequest = [true, false];
    props.ContactManagementSchedule({
      propertyRequestId: propertyRequestId,
    }),
      setShowServiceRequestModal(false);
  };
  return (
    <Modal.Footer>
      <div style={{ ...styles, width: "100%" }}>
        <div style={{ flex: 1 }}>
          <Button onClick={() => setShowNext(false)}>Back</Button>
        </div>
        <div style={styles}>
          <div style={{ marginRight: "5px" }}>
            <Button
              variant="outline-danger"
              onClick={onServiceRequestModalCancel}
            >
              Cancel
            </Button>
          </div>
          <div style={{ marginLeft: "5px" }}>
            {serviceRequestModalType === "Schedule" ? (
              <Button
                variant={"success"}
                disabled={validateServiceRequestModalFields({
                  isRepair,
                  saleSelect,
                  repairSelect,
                  repairDetail,
                })}
                onClick={() => {
                  // props.ContactManagementSchedule({ ...getAllFields() });
                  // props.ContactManagementSchedule({
                  //   propertyRequestId:propertyRequestId,
                  // }),
                  setShowServiceRequestModal("false");
                  onServiceRequestModalCancel();
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                variant={"success"}
                disabled={validateServiceRequestModalFields({
                  isRepair,
                  saleSelect,
                  repairSelect,
                  repairDetail,
                })}
                onClick={() => {
                  saveServiceRequest();
                }}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal.Footer>
  );
};

const DisplayNewInstallationType = ({ requests, selection, setSaleSelect }) => {
  return requests?.map((request) => (
    <button
      id={request.newInstallTypeId}
      key={request.newInstallTypeId}
      className={`serviceRequestBtn ${
        (selection.sales01 && request.newInstallTypeId.includes("sales01")) ||
        (selection.sales02 && request.newInstallTypeId.includes("sales02"))
          ? "selected"
          : ""
      }`}
      onClick={() =>
        setSaleSelect((prev) => ({
          ...prev,
          [request.newInstallTypeId]: !prev[request.newInstallTypeId],
        }))
      }
    >
      {request.newInstallTypeName}
    </button>
  ));
};

export const DisplayNewInstallationOption = ({
  saleSelect,
  newInstallationType,
  setSaleSelect,
}) => {
  return (
    <div>
      <strong>New Installation for:</strong>
      <div style={{ margin: "1em auto", width: "100%" }}>
        <DisplayNewInstallationType
          requests={newInstallationType}
          selection={saleSelect}
          setSaleSelect={setSaleSelect}
        />
      </div>
    </div>
  );
};

const DisplayRepairType = ({ requests, repairSelect, setRepairSelect }) => {
  return requests?.map((request) => (
    <button
      id={request.repairTypeId}
      key={request.repairTypeId}
      className={`serviceRequestBtn ${
        (repairSelect.repair01 && request.repairTypeId.includes("repair01")) ||
        (repairSelect.repair02 && request.repairTypeId.includes("repair02")) ||
        (repairSelect.repair03 && request.repairTypeId.includes("repair03")) ||
        (repairSelect.repair04 && request.repairTypeId.includes("repair04")) ||
        (repairSelect.repair05 && request.repairTypeId.includes("repair05")) ||
        (repairSelect.repair06 && request.repairTypeId.includes("repair06"))
          ? "selected"
          : ""
      }`}
      onClick={() =>
        setRepairSelect((prev) => ({
          ...prev,
          [request.repairTypeId]: !prev[request.repairTypeId],
        }))
      }
    >
      {request.repairTypeName}
    </button>
  ));
};

const DisplayTaxRate = ({ data }) => {
  return data.map((item) => (
    <option key={item.taxId} value={item.taxNum}>
      {item.taxText}
    </option>
  ));
};

export const DisplayNewRepairOptions = ({
  repairType,
  repairSelect,
  setRepairSelect,
  repairDetails,
  setRepairDetails,
  taxRates,
}) => {
  let {
    requestForOther,
    originalWorkDone,
    originalWorkYear,
    underWarrenty,
    servicePrice,
    taxRate,
    totalPrice,
    repairDescreption,
  } = repairDetails;

  if (typeof servicePrice === "string") {
    servicePrice = +servicePrice?.replaceAll("$", "");
    totalPrice = +totalPrice?.replaceAll("$", "");
  }

  const [serviceChargePolicy, setServiceChargePolicy] = useState(false);

  return (
    <div>
      <strong>New Repair is for:</strong>
      <div style={{ margin: "1em auto", width: "100%" }}>
        <DisplayRepairType
          requests={repairType}
          repairSelect={repairSelect}
          setRepairSelect={setRepairSelect}
        />

        <Row>
          <Col md="12" sm="12">
            {repairSelect.repair06 && (
              <Row>
                <Col md="6" sm="6">
                  <Form.Group style={styles.formInput}>
                    <Form.Control
                      type="text"
                      name="repair06Name"
                      placeholder="Enter Other"
                      value={requestForOther}
                      onChange={(e) =>
                        setRepairDetails((prev) => ({
                          ...prev,
                          requestForOther: e.target.value,
                        }))
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm="8" md="6">
                <Form.Group style={styles.formInput}>
                  <Form.Label>Did we perform original work?</Form.Label>
                  <Form.Control
                    as="select"
                    name="originalWorkPerformed"
                    value={originalWorkDone}
                    onChange={(e) =>
                      setRepairDetails((prev) => ({
                        ...prev,
                        originalWorkDone: e.target.value,
                      }))
                    }
                  >
                    <option value="">Choose...</option>
                    {YesNoOptionsForContact.map((item) => (
                      <option key={item.name} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              {(originalWorkDone === "true" || originalWorkDone === true) && (
                <>
                  <Col sm="3" md="3">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Year</Form.Label>
                      <Form.Control
                        type="text"
                        name="WorkPerformedYear"
                        value={originalWorkYear}
                        onChange={(e) =>
                          setRepairDetails((prev) => ({
                            ...prev,
                            originalWorkYear: e.target.value,
                          }))
                        }
                        maxLength={4}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="6" md="3">
                    <Form.Group style={styles.formInput}>
                      <Form.Label>Is it under Warranty?</Form.Label>
                      <Form.Control
                        as="select"
                        name="isUnderWarrenty"
                        value={underWarrenty}
                        onChange={(e) =>
                          setRepairDetails((prev) => ({
                            ...prev,
                            underWarrenty: e.target.value,
                          }))
                        }
                      >
                        <option value="">Choose...</option>
                        {YesNoOptionsForContact.map((item) => (
                          <option key={item.name} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>

            {((originalWorkDone === "true" || originalWorkDone === true) &&
              (underWarrenty === true || underWarrenty === "true")) || (
              <Row>
                <Col sm="6" md="6">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Repair/Service Price</Form.Label>
                    <Form.Control
                      type="text"
                      name="repairServicePrice"
                      placeholder="Pre Tax $"
                      value={`$${servicePrice}`}
                      onChange={(e) =>
                        setRepairDetails((prev) => ({
                          ...prev,
                          servicePrice: +e.target.value.replaceAll("$", ""),
                          totalPrice:
                            +e.target.value.replaceAll("$", "") *
                            (1 + taxRate / 100),
                        }))
                      }
                    />
                  </Form.Group>
                </Col>
                <Col sm="6" md="3">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Tax Rate</Form.Label>
                    <Form.Control
                      as="select"
                      name="taxRate"
                      value={taxRate}
                      onChange={(e) =>
                        setRepairDetails((prev) => ({
                          ...prev,
                          taxRate: e.target.value,
                          totalPrice: servicePrice * (1 + e.target.value / 100),
                        }))
                      }
                    >
                      <option value="">Choose...</option>
                      {taxRates.taskratedata.length > 0 && (
                        <DisplayTaxRate data={taxRates.taskratedata} />
                      )}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col sm="6" md="3">
                  <Form.Group style={styles.formInput}>
                    <Form.Label>Total</Form.Label>
                    <Form.Control
                      type="text"
                      value={`$${(+totalPrice)?.toFixed(2)}`}
                      disabled={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <Form.Group style={styles.formInput}>
                  <Form.Label>Description of Repair</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="2"
                    name="repairDescription"
                    value={repairDescreption}
                    onChange={(e) =>
                      setRepairDetails((prev) => ({
                        ...prev,
                        repairDescreption: e.target.value,
                      }))
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            {((originalWorkDone === "true" || originalWorkDone === true) &&
              (underWarrenty === true || underWarrenty === "true")) || (
              <Row>
                <Col>
                  <strong>
                    <Form.Group>
                      <Form.Check
                        name="serviceChargePolicy"
                        checked={serviceChargePolicy}
                        onChange={(e) =>
                          setServiceChargePolicy(e.target.checked)
                        }
                        label="I have explained and customer agrees to the Service Charge Policy."
                      />
                    </Form.Group>
                  </strong>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};
