import { axiosInstance } from "services";
import { repairsActionTypes } from "./repairs.types";
import { getData, saveData } from "services/storageManager";
import { ApiEndpoints } from "lib/config/baseSettings";

export const fetchTask = () => (dispatch) => {
  dispatch({ type: repairsActionTypes.TASK_START });

  return axiosInstance
    .get(ApiEndpoints.TASK.FETCH_TASK)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return dispatch({
          type: repairsActionTypes.TASK_SUCCESS,
          payload: data,
        });
      }
      return dispatch({
        type: repairsActionTypes.TASK_FAILURE,
        payload: data.error,
      });
    })
    .catch((errors) =>
      dispatch({
        type: repairsActionTypes.TASK_FAILURE,
        payload: errors.message,
      })
    );
};

export const addTask = (taskData) => (dispatch) => {
  dispatch({ type: repairsActionTypes.ADD_TASK_START });

  return axiosInstance
    .post(ApiEndpoints.TASK.ADD_TASK, taskData)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return dispatch({
          type: repairsActionTypes.ADD_TASK_SUCCESS,
          payload: { _id: data.result._id, ...taskData },
          message: "Successfully added a task.",
        });
      }
      return dispatch({
        type: repairsActionTypes.ADD_TASK_FAILURE,
        payload: data.error,
      });
    })
    .catch((errors) =>
      dispatch({
        type: repairsActionTypes.ADD_TASK_FAILURE,
        payload: errors.message,
      })
    );
};

export const editTask = (data, dataId) => (dispatch) => {
  dispatch({ type: repairsActionTypes.EDIT_TASK_START });

  return axiosInstance
    .post(ApiEndpoints.TASK.EDIT_TASK, { ...data })
    .then((res) => res.data)
    .then((resData) => {
      if (resData.success) {
        fetchTask();
        return dispatch({
          type: repairsActionTypes.EDIT_TASK_SUCCESS,
          payload: data,
          message: resData.result.message,
          dataId,
        });
      }
      return dispatch({
        type: repairsActionTypes.EDIT_TASK_FAILURE,
        payload: resData.error,
      });
    })
    .catch((errors) =>
      dispatch({
        type: repairsActionTypes.EDIT_TASK_FAILURE,
        payload: errors.message,
      })
    );
};

export const completeTask = (data, dataId) => (dispatch) => {
  dispatch({ type: repairsActionTypes.DELETE_TASK_START });

  return axiosInstance
    .post(ApiEndpoints.TASK.COMPLETE_TASK, { ...data })
    .then((res) => res.data)
    .then((resData) => {
      if (resData.success) {
        return dispatch({
          type: repairsActionTypes.DELETE_TASK_SUCCESS,
          message: resData.result.message,
          dataId,
        });
      }
      return dispatch({
        type: repairsActionTypes.DELETE_TASK_FAILURE,
        payload: resData.error,
      });
    })
    .catch((errors) =>
      dispatch({
        type: repairsActionTypes.DELETE_TASK_FAILURE,
        payload: errors.message,
      })
    );
};

export const getRepairRequest = (data) => (dispatch) => {
  dispatch({ type: repairsActionTypes.GET_REPAIR_REQUEST_START });
  if (!navigator.onLine) {
    try {
      let stored_data = getData(`repairMetrics${data.requestId}`);

      const changesMetricsString = getData(`changesRepair${data.requestId}`);
      if (changesMetricsString !== null) {
        const changesMetric = changesMetricsString;
        stored_data.data = changesMetric;
      }

      return dispatch({
        type: repairsActionTypes.GET_REPAIR_REQUEST_SUCCESS,
        payload: { success: true, result: stored_data.data },
      });
    } catch (error) {
      console.log("storage error", error);
    }
  } else {
    return axiosInstance
      .post(ApiEndpoints.REPAIR_REQUEST.GET_REPAIR_REQUEST, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          return dispatch({
            type: repairsActionTypes.GET_REPAIR_REQUEST_SUCCESS,
            payload: data,
          });
        }
        return dispatch({
          type: repairsActionTypes.GET_REPAIR_REQUEST_FAILURE,
          payload: data.error,
        });
      })
      .catch((errors) =>
        dispatch({
          type: repairsActionTypes.GET_REPAIR_REQUEST_FAILURE,
          payload: errors.message,
        })
      );
  }
};
export const updateRepairRequest = (data) => (dispatch) => {
  dispatch({ type: repairsActionTypes.UPDATE_REPAIR_REQUEST_START });

  if (!navigator.onLine) {
    try {
      return new Promise((resolve, reject) => {
        const metricData = { ...data };
        saveData(`changesRepair${data.requestId}`, metricData);
        resolve({
          payload: {
            success: true,
            result: { message: "Service request is updated successfully." },
          },
        });
        return dispatch({
          type: repairsActionTypes.UPDATE_REPAIR_REQUEST_SUCCESS,
          payload: {
            success: true,
            result: { message: "Service request is updated successfully." },
          },
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  return axiosInstance
    .post(ApiEndpoints.REPAIR_REQUEST.UPDATE_REPAIR_REQUEST, { ...data })
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return dispatch({
          type: repairsActionTypes.UPDATE_REPAIR_REQUEST_SUCCESS,
          payload: data,
        });
      }
      return dispatch({
        type: repairsActionTypes.UPDATE_REPAIR_REQUEST_FAILURE,
        payload: data.error,
      });
    })
    .catch((errors) =>
      dispatch({
        type: repairsActionTypes.UPDATE_REPAIR_REQUEST_FAILURE,
        payload: errors.message,
      })
    );
};
