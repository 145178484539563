import numeral from "numeral";
import {
  SLOPED_TYPES,
  FLATTYPES,
  FLOOR,
  ELEVATION,
  ELEVATIONDETAILS,
  DIFFICULTY,
  WORKTYPE,
  G_LEVEL,
  G_ELEVATIONS,
  G_ELEVATION_DETAILS,
  UserRole,
  salespersion,
} from "../lib/constants/const";

export function clog(text) {}

export function checkPhone(number) {
  let res = number?.replace("-", "");
  res = res?.replace(" ", "");
  res = res?.replace("(", "");
  res = res?.replace(")", "");
  return res?.includes("_");
}

const parseDateZero = (value) => {
  if (+value < 10) {
    return `0${value}`;
  }
  return `${value}`;
};
export function convertDate(date) {
  const DateObj = new Date(date);
  return `${parseDateZero(DateObj.getMonth() + 1)}/${parseDateZero(
    DateObj.getDate()
  )}/${DateObj.getFullYear()}`;
}

export const checkPassword = /^(?=.*\d)(?=.*[A-Za-z]).{6,20}$/;

export const getDataLabel = (data, id) => {
  const match = data.find((item) => item.id === id);
  if (match) {
    return match.name;
  }
  return id;
};

export const getFlatOrShingles = (data) => {
  if (!data) {
    return "";
  }

  const index = SLOPED_TYPES.findIndex((item) => item.id === data);
  if (index > -1) {
    return "Shingles";
  }
  return "Flat";
};

export const getElevationLetters = (array, data) => {
  let match = "";
  array.map((elem) =>
    data.includes(elem.id)
      ? (match = `${match ? `${match} ` : ""}${elem.name[0].toUpperCase()}`)
      : match
  );
  return match;
};

export const formatNumber = (num) => numeral(num).format("0,0");

export const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    const context = this;

    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.call(context, args);
    }, delay);
  };
};

export const getRoofingSectionData = (section, i) => ({
  id: section.sectionId,
  sec: i + 1,
  floor: getDataLabel(FLOOR, section.floor),
  elevation:
    `${getElevationLetters(ELEVATION, section.elevation)}` +
    "\n" +
    `${getDataLabel(ELEVATIONDETAILS, section.elevation_details)}`,
  pitch: getDataLabel(DIFFICULTY, section.pitch),
  workType: getDataLabel(WORKTYPE, section.work_type),
  install: getDataLabel([...SLOPED_TYPES, ...FLATTYPES], section.install_types),
  l:
    section.lwfData.length && section.lwfData.length < 2
      ? section.lwfData[0].l
      : "*",
  w:
    section.lwfData.length && section.lwfData.length < 2
      ? section.lwfData[0].w
      : "*",
  f:
    section.lwfData.length && section.lwfData.length < 2
      ? section.lwfData[0].f
      : "*",
  sq:
    getFlatOrShingles(section.install_types) !== "Flat"
      ? section.lwfData.length && section.lwfData.length < 2
        ? formatNumber(section.lwfData[0].lwfTotal)
        : formatNumber(section.totalFlat)
      : "",
  flat:
    getFlatOrShingles(section.install_types) === "Flat"
      ? section.lwfData.length && section.lwfData.length < 2
        ? formatNumber(section.lwfData[0].lwfTotal)
        : formatNumber(section.totalFlat)
      : "",
});

export const getGuttersSectionData = (section, i) => ({
  id: section.sectionId,
  sec: i + 1,
  level: getDataLabel(G_LEVEL, section.level),
  elevation:
    `${getElevationLetters(G_ELEVATIONS, section.elevation)}` +
    "\n" +
    `${getDataLabel(G_ELEVATION_DETAILS, section.elevation_details)}`,
  gutters: section.guttersTotal ? section.guttersTotal : "",
  leaders: section.leadersTotal ? section.leadersTotal : "",
  screens: section.screensTotal ? section.screensTotal : "",
  fascia: section.fasciaTotal ? section.fasciaTotal : "",
  cap: section.cappingTotal ? section.cappingTotal : "",
  heads: section.leaderheadsTotal ? section.leaderheadsTotal : "",
});

export const getUserPermissions = (pageName, role, history) => {
  const filterData = UserRole[0][role[0]?.roleName?.toLowerCase()][pageName];
  if (filterData?.read === false && pageName !== "subscription") {
    history.goBack();
  }
  return filterData;
};

export const checkAsses = (access) => {
  let short = "no access";
  if (access !== short) {
    return true;
  } else {
    return false;
  }
};

export const displayPersons = (persons) => {
  let Persons = [];
  if (persons && persons.length > 0) {
    persons.map((person) => {
      return Persons.push({
        value: person.personId,
        label: person.personName,
      });
    });
  }
  return Persons;
};

export const displayPersonsPM = (persons, active) => {
  let Persons = [];
  if (persons && persons.length > 0) {
    persons.map((person) => {
      if (person.services.includes(active.toLowerCase()))
        return Persons.push({
          value: person._id,
          label: person.name,
        });
    });
  }
  return Persons;
};

export const sliceHoursAndMin = (start, inNum = false) => {
  const data = start.split(" ");
  const time = data[0];
  const meridiem = data[1];
  let hour = Number(time.split(":")[0]);
  let min = Number(time.split(":")[1]);
  if (meridiem === "PM" && hour !== 12) {
    hour = 12 + hour;
  }

  if (min < 10) {
    min = inNum ? min : `0${min}`;
  }
  return {
    hour,
    min,
  };
};

export const generateUId = () => {
  return (
    Math.random().toString(30).slice(2) + Math.random().toString(30).slice(2)
  );
};

export function timeAgo(timestamp) {
  const date = new Date(timestamp);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
      return interval + " year" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
      return interval + " month" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
      return interval + " day" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
      return interval + " hour" + (interval === 1 ? "" : "s") + " ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
      return interval + " minute" + (interval === 1 ? "" : "s") + " ago";
  }
  return "Just now";
}


// /**
//  * Scheduling modal
//  * All Api end point of Scheduling Modal
//  */
// export const GET_SCEDULING_TIME = "admin/getSchedulingTime";
// export const GET_SCEDULING_TIME_BY_ID = "admin/getSchedulingTime/";

// export const UPDATE_SSCHEDULING_MODAL_SALES =
//   "admin/updateSchedulingModalSaleTime/";
// export const UPDATE_SSCHEDULING_MODAL_REPAIR =
//   "admin/updateSchedulingModalRepairTime/";
// export const UPDATE_SSCHEDULING_MODAL_PROJECT =
//   "admin/updateSchedulingModalPMT/";
// export const UPDATE_SSCHEDULING_MODAL_GAPTIME_REPAIR =
//   "admin/updateSchedulingModalRepairGapTime/";
// export const UPDATE_SSCHEDULING_MODAL_GAPTIME_SALES =
//   "admin/updateSchedulingModalSalesGapTime/";
// export const GET_ALLVIDEO = "admin/videos/firstTimeSetup";
// export const GET_ALL_HELP_VIDEO = "admin/videos/getScreensIds";
// export const GET_ALL_COUNT = "admin/notifications/countAndCheck";

// export const GET_STAGE_BEFORE_DATA = "activity/getDataOfTodayAndBefore";
// export const GET_ALL_UPDATEON = "activity/updateWonOrFutureFollowUp";
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const splitToGetNameAndId = (value) => {
  const splitValue = value.split("-");
  return {
    name: splitValue[0].trim(),
    id: splitValue[1].trim(),
  }
}

export const getActivePaymentMethod = (methods) => {
  return methods.find((m) => m.active)
};
