import React from "react";
import { Form, Row } from "react-bootstrap";
import PopupModal, { ModalFooter } from "components/popup-modal/popup-modal";

export default function DisplayModal({
  handleChange,
  checkOtherArrayValue,
  addAllData,
  show,
  label,
  hide,
  data,
  array,
  arrName,
  other,
  onSubmit,
}) {
  return (
    <PopupModal
      styles={{ padding: "1em 1em 0 1em", border: "none" }}
      show={show}
      heading={label}
      onHide={hide}
      bold
    >
      <Row style={{ margin: "0 1em 1em 1em", alignItems: "center" }}>
        {data.map((item) => (
          <button
            key={item.id}
            className={`ClickableBtnModal ${
              array.includes(item.id) ? "selected" : ""
            } `}
            onClick={() => addAllData(arrName, item.id, false)}
            style={{ maxWidth: "12%", marginTop: "4px", padding: "0" }}
          >
            {item.name}
          </button>
        ))}
        {checkOtherArrayValue(data, array) && (
          <div style={{ width: "150px", margin: "1em" }}>
            <Form.Control
              type="text"
              name={other.name}
              value={other.value}
              onChange={handleChange}
            />
          </div>
        )}
      </Row>

      <ModalFooter onCancel={hide} onSubmit={onSubmit} />
    </PopupModal>
  );
}
