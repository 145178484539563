import React, { useState } from "react";
import { Row, Col, Form, Button, Modal, Alert } from "react-bootstrap";
import "./sales-activity.scss";
import Select from "react-select";
import SearchField from "components/search-field/search-field";
import moment from "moment";
import DatePicker from "react-date-picker";
import Clock from "components/clock/clock";
import Tabs from "components/tabs/tabs";
import InitialLoader from "components/InitilalLoader";

import PopupModal from "components/popup-modal/popup-modal";
import { fetchStaticAll } from "lib/redux/static-all/static-all.actions";
import {
  getStage,
  changeStage,
  updateFollowup,
  updateFollowupData,
} from "lib/redux/sales/sales.actions";
import { connect, useDispatch } from "react-redux";
import {
  salesTabs,
  salesStagesChange,
} from "lib/redux/static-all/static-all.selector";
import { SalesPersons } from "lib/redux/calendar/calendar.selector";
import { count, stageData } from "lib/redux/sales/sales.selector";
import { fetchForCalendar } from "lib/redux/calendar/calendar.actions";

import MonthPickerInput from "react-month-picker-input";
import "react-month-picker-input/dist/react-month-picker-input.css";
import { convertDate } from "utils/utils";
import SalesCard from "./SalesCard";
import SalesCardExtended from "./SalesCardExtended";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import useActiveSales from "hooks/useActiveSales";
import { salesActionTypes } from "lib/redux/sales/sales.types";

const SalesCardNotUpon = ({
  RedirectTo,
  stageBeforeData,
  changeStageBefore,
  paymentStatus,
}) => {
  return (
    <>
      <h5 style={{ margin: "7% 2% 3%" }}>Appointments Not Acted Upon</h5>
      {stageBeforeData?.length > 0 ? (
        stageBeforeData?.map((res) => {
          return (
            <div className="_sales-card">
              <div className="p-details" style={{ maxWidth: "300px" }}>
                {/* <a className="address">{res?.address}</a> */}
                <div className="names">
                  {res?.name?.length > 0 &&
                    res?.name.map((data, i) => <h5 key={i}>{data.name}</h5>)}
                </div>
                {res?.phone?.length > 0 && (
                  <div className="tels">
                    {res?.phone?.map((data, i) => (
                      <div key={i} className="tel">
                        {data.phone}
                      </div>
                    ))}
                  </div>
                )}
                <a
                  href={`https://www.google.com/maps/place?q=${res?.address}`}
                  className="address"
                  style={{ textDecoration: "underline" }}
                  target="_blank"
                >
                  {res?.address}
                </a>
              </div>
              <div className="o-details" style={{ margin: "0 1em" }}>
                <div className="bold">
                  Time: <span className="bold">{res?.time}</span>
                </div>
                <div className="bold">
                  Visit:{" "}
                  <span className="bold">
                    {moment(res?.visit).format("LL")}
                  </span>
                </div>

                {res?.updatedVisible && (
                  <div>
                    <label>{res?.updatedText}:</label>{" "}
                    {moment(res?.updatedOn).format("LL")}
                  </div>
                )}

                <div>
                  <label>Project:</label> <span>{res?.project}</span>
                </div>
                {res?.persons && (
                  <div>
                    <label>Sales:</label> <span>{res?.persons}</span>
                  </div>
                )}
                <div>
                  <label>Created:</label>{" "}
                  <span>{moment(res?.created).format("LL")}</span>
                </div>
              </div>
              <div className="_btns">
                <Row style={{ flexDirection: "column" }}>
                  <Col style={{ marginBottom: "1em" }}>
                    <Button
                      className="_btn_modified"
                      onClick={() => changeStageBefore(res.requestId)}
                      disabled={
                        false
                        // !allowStageChange ||
                        // isChanging ||
                        // paymentStatus == "Frozen 1"
                      }
                    >
                      Change Stage
                    </Button>
                  </Col>
                  <Col style={{ marginTop: "1em" }}>
                    <Button className="_btn_modified">
                      <a
                        href={`https://www.google.com/maps/place?q=${res?.address}`}
                        className="address"
                        target="_blank"
                      >
                        Directions
                      </a>
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="_btns">
                <Row style={{ flexDirection: "column" }}>
                  <Col style={{ marginBottom: "1em" }}>
                    <Button
                      onClick={() => {
                        if (paymentStatus !== "Unpaid") {
                          RedirectTo("roof", res);
                        }
                      }}
                      className="_btn_modified"
                      disabled={!res?.hasRoof || paymentStatus == "Frozen 1"}
                    >
                      Roof
                    </Button>
                  </Col>
                  <Col style={{ marginTop: "1em" }}>
                    <Button
                      onClick={() =>
                        paymentStatus !== "Unpaid" && RedirectTo("gutters", res)
                      }
                      className="_btn_modified"
                      disabled={!res?.hasGutters}
                    >
                      Gutters
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })
      ) : (
        <h6 style={{ textAlign: "center", marginBottom: "2%" }}>
          No Previous Appointments
        </h6>
      )}
    </>
  );
};

const SalesActivity = (props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [active, setActive] = useState("salesStage102");
  const [page, setPage] = useState(1);
  const [selectedDate, setSelectedDate] = useState([new Date()]);

  const [selectedScheduleDate, setSelectedScheduleDate] = useState(new Date());

  const [modal, setModal] = useState(false);
  const [countt, setCount] = useState([]);
  const [filterYear, setFilterYear] = useState(new Date().getFullYear());
  const [filterMonth, setFilterMonth] = useState(new Date().getMonth());
  const [requestId, setRequestId] = useState("");
  const [msgType, setMsgType] = useState("");
  const [msgFor, setMsgFor] = useState("");

  const [msg, setMsg] = useState("");
  const [searchString, setSearchString] = useState("");
  const [selectPerson, setSelectPerson] = useState([]);
  const [datePicked, setDatePicked] = useState("--/--/----");
  const [pickFirst, setPickFirst] = useState(true);
  const [updatedText, setUpdatedText] = useState("");
  const [updatedDateText, setUpdatedDateText] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [cancelModalId, setCancelModalId] = useState(false);
  const [hide, setHide] = useState(false);
  const [selectedStage, setSelectedStage] = useState();
  const [dateForMonthStages, setDateForMonthSatges] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear()
  });

  //#region online offline handle
  const [isOnline, setIsOnline] = useState(true);



  const {
    salesStages,
    isUpdating,
    updateSuccess,
    updateFail,
    changeFail,
    changeSuccess,
    updateSuccessData,
    errors,
    count,
    stageData,
    paymentStatus,
  } = props;


  console.log({ salesStages, active });


  const setOnline = () => {
    console.log("setting is online");
    setIsOnline(true);
  };

  const setOffline = () => {
    console.log("setting is offline");
    setIsOnline(false);
  };

  useEffect(() => {
    addEventListener("offline", setOffline);
    addEventListener("online", setOnline);

    return () => {
      removeEventListener("offline", setOffline);
      removeEventListener("online", setOnline);
    };
  }, []);
  const { getStageBeforeData, stageBeforeData } = useActiveSales();

  const dispatch = useDispatch();

  const loadData = () => {
    props.fetchForCalendar();
    props.fetchStaticAll();
  };

  useEffect(() => {
    setHide(false);
    loadData();
  }, []);

  const RedirectTo = (path, data) => {
    props.history.push({
      pathname: `/dashboard/metrics/${path}/${data.requestId}`,
      state: { stageId: data.stageId },
    });
  };

  const getPersons = (selectPerson) => {
    let data = [];
    if (selectPerson) {
      selectPerson.map((per) => data.push(per.value));
    }
    return data;
  };

  const getStagesData = (salesStages) => {
    console.log("salesStages ", salesStages);
    if (!salesStages) return;
    let data = [];
    // const { date } = state;
    console.log("salesStages ", salesStages);
    salesStages.map(({ salesStageId }, i) => {
      return data.push({
        stageId: salesStageId,
        date: i < 3 ? convertDate(selectedDate[i]) : converFilterDate(selectedDate[i]),
      });
    });
    return data;
  };

  const converFilterDate = (date) => {
    if (date) {
      const month = Number(date?.split("/")[0]) + 1;
      const year = date?.split("/")[1];
      return `${month}/${year}`;
    }
    return `${new Date().getMonth() + 1}/${new Date().getFullYear()}`;
  };

  const addIntoDate = (salesStages) => {
    let date = [];
    salesStages.map((data, i) => {
      const formattedDate =
        i < 3
          ? new Date()
          : `${new Date().getMonth()}/${new Date().getFullYear()}`;
      date.push(formattedDate);
    });
    setSelectedDate(date);
    console.log({ date });
  };

  const getFormattedDate = () => {
    const formattedDate =
      activeTabIndex < 3 ? convertDate(selectedDate[activeTabIndex]) :
        converFilterDate(selectedDate[activeTabIndex]);

    console.log({ activeTabIndex, selectedDate, dd: selectedDate[activeTabIndex] });
    return formattedDate;
  }

  function formatDate(date, typeOfFilter) {
    console.log({ tt: typeof date });
    if (typeOfFilter === "month") return date;
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero if necessary
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (+1 because months are zero-indexed) and pad with leading zero if necessary
    const year = date.getFullYear(); // Get full year

    return `${month}/${day}/${year}`; // Format as MM/DD/YYYY
  }

  const loadStageData = (date = "", typeOfFilter = "date") => {
    console.log("here 1 ld");

    const fDate = date ? formatDate(date, typeOfFilter) : getFormattedDate();
    const stages = getStagesData(salesStages);
    const persons = getPersons(selectPerson);

    console.log({ stages, persons, fDate, date });

    if (stages && stages.length) {
      addIntoDate(salesStages);
      const body = {
        isRepair: false,
        searchString: searchString,
        persons: persons,
        stageId: active,
        date: fDate,
        page: page,
        stages: stages,
      }

      props.getStage(body);

      getStageBeforeData(body)
    }

  };

  useEffect(() => {
    console.log({ salesStages, active, changeSuccess });
    const isSaleDataLoaded = salesStages && salesStages.length;
    if ((isSaleDataLoaded && active) || (isSaleDataLoaded && changeSuccess) || (selectPerson && active && isSaleDataLoaded))
      loadStageData();
  }, [salesStages, active, changeSuccess, selectPerson]);

  useEffect(() => {
    if (errors) {
      setMsg(errors);
      setMsgType("danger");
      // setState({ msg: errors, msgType: "danger" });
      clearMsg();
    }
  }, [errors]);


  useEffect(() => {
    if (updateFail) {
      setMsg(updateFail);
      setMsgType("danger");
      clearMsg();
    }
  }, [updateFail]);

  useEffect(() => {
    if (isUpdating) {
      setMsg("Please wait! While follow up is being updated!");
      setMsgType("info");
      clearMsg();
    }
  }, [isUpdating]);

  useEffect(() => {
    if (changeFail) {
      setMsg(changeFail);
      setMsgType("danger");
      clearMsg();
    }
  }, [changeFail]);


  useEffect(() => {
    if (count?.length > 0) {
      setCount([]);
      if (count.length > 0) {
        count.map((data) => {
          return setCount([...count, data.count]);
        });
      }
    }
  }, [count, hide]);

  const dateChange = (date) => {

    console.log({ date123: date });
    setSelectedScheduleDate(date);

    loadStageData(date)
  }

  const changeStage = (id) => {
    if (paymentStatus !== "Frozen 1") {
      setModal(true);
      setRequestId(id);
    }
  };


  const handleChangeTab = (requestId, stageId) => {
    console.log({ stageId });
    setSelectedStage(active);

    if (stageId === "salesStage108") {
      props.changeStage({
        requestId,
        stageId,
      });
      setActive(stageId);
      setAddModal(true);
      setCancelModalId(requestId);
    } else {
      props.changeStage({
        requestId,
        stageId,
      });
      setActive(stageId);
    }
  };

  const handleDateChange = (id, date) => {
    props.updateFollowup({
      requestId: id,
      followUp: convertDate(date),
    });
  };
  const handleOnUpdateOndata = (id, date) => {
    if (active === "salesStage105") {
      props.updateFollowupData({
        requestId: id,
        won: convertDate(date),
      });
    } else if (active === "salesStage106") {
      props.updateFollowupData({
        requestId: id,
        futureFollowUp: convertDate(date),
      });
    } else if (active === "salesStage107") {
      props.updateFollowupData({
        requestId: id,
        lost: convertDate(date),
      });
    } else if (active === "salesStage108") {
      props.updateFollowupData({
        requestId: id,
        cancel: convertDate(date),
      });
    }
  };

  const clearMsg = () =>
    setTimeout(
      () => {
        setMsg("");
        setMsgType("");
        setMsgFor("");
        dispatch({ type: salesActionTypes.CHANGE_STAGE_RESET });
      },
      // setState({ msg: "", msgType: "", msgFor: "" }),
      10000
    );

  const displayPersons = (persons) => {
    let Persons = [];
    persons.map(({ personId, personName }) => {
      return Persons.push({
        value: personId,
        label: personName,
      });
    });
    return Persons;
  };

  const renderFilter = () => {

    return (
      <Row>
        <Col
          style={{
            margin: "1em auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <div>
            <h6>Filter By Month</h6>
            <MonthPickerInput
              year={dateForMonthStages.year}
              month={dateForMonthStages.month}
              onChange={changeDateYear}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const changeDateYear = (event) => {
    const year = Number(event?.split("/")[1]);
    const month = Number(event?.split("/")[0]);

    setDateForMonthSatges({
      month,
      year
    })
    console.log({ nDate: new Date(event), event });
    loadStageData(event, 'month')
  };

  const displayStageData = (stageData, salesStages) => {
    if (stageData) {
      if (stageData.data.length > 0 || active === "salesStage102") {
        return stageData.data.length > 0
          ? stageData.data.map((data) => {
            return renderData(data, salesStages);
          })
          : renderData("", salesStages);
      }
      if (stageData.data.length === 0) {
        return renderEmptyTab();
      }
      return;
    }
  };

  const renderData = (data, salesStages) => {
    // const { active, datePicked } = state;
    const { isChanging } = props;
    switch (active) {
      case salesStages[0].salesStageId:
        return (
          <SalesCard
            key={data.requestId}
            id={data.requestId}
            name={data.name}
            address1={""}
            fullAdd={data.address}
            tel={data.phone}
            time={data.time}
            visit={data.visit}
            created={data.created}
            project={data.project}
            sales={data.persons}
            roof={data.hasRoof}
            gutters={data.hasGutters}
            isChanging={isChanging}
            updatedVisible={data.updatedVisible}
            updatedOn={data.updatedOn}
            updatedText={data.updatedText}
            changeStage={() => changeStage(data.requestId)}
            allowStageChange={salesStages[0].showInChange}
            datePicked={datePicked}
            handleDateChange={handleDateChange}
            RedirectTo={(path, data) => RedirectTo(path, data)}
            data={data}
            stageBeforeData={stageBeforeData}
            changeStageBefore={changeStage}
            paymentStatus={paymentStatus}
          />
        );

      // Proposal
      case salesStages[1].salesStageId:
        return (
          <SalesCardExtended
            key={data.requestId}
            id={data.requestId}
            name={data.name}
            address1={""}
            fullAdd={data.address}
            tel={data.phone}
            time={data.time}
            visit={data.visit}
            created={data.created}
            project={data.project}
            sales={data.persons}
            roof={data.hasRoof}
            gutters={data.hasGutters}
            isChanging={isChanging}
            showMetric
            updatedVisible={data.updatedVisible}
            updatedOn={data.updatedOn}
            updatedText={data.updatedText}
            changeStage={() => changeStage(data.requestId)}
            allowStageChange={salesStages[1].showInChange}
            datePicked={datePicked}
            handleDateChange={handleDateChange}
            RedirectTo={(path, data) => RedirectTo(path, data)}
            data={data}
            paymentStatus={paymentStatus}
          />
        );

      case salesStages[2].salesStageId:
        return (
          <SalesCardExtended
            key={data.requestId}
            id={data.requestId}
            name={data.name}
            address1={""}
            fullAdd={data.address}
            tel={data.phone}
            time={data.time}
            visit={data.visit}
            created={data.created}
            project={data.project}
            sales={data.persons}
            roof={data.hasRoof}
            followUp={data.followUp}
            showMetric
            displayExtraButton
            gutters={data.hasGutters}
            isChanging={isChanging}
            proposalSent={data.proposalSent}
            updatedVisible={false}
            updatedOn={data.updatedOn}
            updatedText={data.updatedText}
            changeStage={() => changeStage(data.requestId)}
            allowStageChange={salesStages[2].showInChange}
            datePicked={datePicked}
            handleDateChange={handleDateChange}
            RedirectTo={(path, data) => RedirectTo(path, data)}
            data={data}
            paymentStatus={paymentStatus}
          />
        );

      case salesStages[3].salesStageId:
        return (
          <SalesCardExtended
            key={data.requestId}
            id={data.requestId}
            name={data.name}
            address1={""}
            fullAdd={data.address}
            tel={data.phone}
            time={data.time}
            visit={data.visit}
            created={data.created}
            project={data.project}
            sales={data.persons}
            roof={data.hasRoof}
            gutters={data.hasGutters}
            isChanging={isChanging}
            proposalSent={data.proposalSent}
            updatedVisible={data.updatedVisible}
            updatedOn={data.updatedOn}
            updatedText={data.updatedText}
            displayExtraButton
            showMetric
            changeStage={() => changeStage(data.requestId)}
            allowStageChange={salesStages[3].showInChange}
            datePicked={datePicked}
            handleDateChange={handleDateChange}
            RedirectTo={(path, data) => RedirectTo(path, data)}
            data={data}
            handleOnUpdateOndata={handleOnUpdateOndata}
            firstCol={"3"}
            secondCol={"9"}
            paymentStatus={paymentStatus}
          />
        );

      case salesStages[4].salesStageId:
        return (
          <SalesCardExtended
            key={data.requestId}
            id={data.requestId}
            name={data.name}
            address1={""}
            fullAdd={data.address}
            tel={data.phone}
            time={data.time}
            visit={data.visit}
            created={data.created}
            project={data.project}
            sales={data.persons}
            roof={data.hasRoof}
            proposalSent={data.proposalSent}
            gutters={data.hasGutters}
            isChanging={isChanging}
            displayExtraButton
            showMetric
            updatedVisible={data.updatedVisible}
            updatedOn={data.updatedOn}
            updatedText={data.updatedText}
            changeStage={() => changeStage(data.requestId)}
            allowStageChange={salesStages[4].showInChange}
            datePicked={datePicked}
            handleDateChange={handleDateChange}
            RedirectTo={(path, data) => RedirectTo(path, data)}
            data={data}
            handleOnUpdateOndata={handleOnUpdateOndata}
            firstCol={"7"}
            secondCol={"5"}
            paymentStatus={paymentStatus}
          />
        );

      case salesStages[5].salesStageId:
        return (
          <SalesCardExtended
            key={data.requestId}
            id={data.requestId}
            name={data.name}
            address1={""}
            fullAdd={data.address}
            tel={data.phone}
            time={data.time}
            visit={data.visit}
            created={data.created}
            project={data.project}
            sales={data.persons}
            roof={data.hasRoof}
            proposalSent={data.proposalSent}
            gutters={data.hasGutters}
            isChanging={isChanging}
            displayExtraButton
            showMetric
            updatedVisible={data.updatedVisible}
            updatedOn={data.updatedOn}
            updatedText={data.updatedText}
            changeStage={() => changeStage(data.requestId)}
            allowStageChange={salesStages[5].showInChange}
            datePicked={datePicked}
            handleDateChange={handleDateChange}
            RedirectTo={(path, data) => RedirectTo(path, data)}
            data={data}
            handleOnUpdateOndata={handleOnUpdateOndata}
            firstCol={"2"}
            secondCol={"10"}
            paymentStatus={paymentStatus}
          />
        );

      case salesStages[6].salesStageId:
        return (
          <SalesCardExtended
            key={data.requestId}
            id={data.requestId}
            name={data.name}
            address1={""}
            fullAdd={data.address}
            tel={data.phone}
            time={data.time}
            visit={data.visit}
            created={data.created}
            project={data.project}
            sales={data.persons}
            roof={data.hasRoof}
            proposalSent={data.proposalSent}
            gutters={data.hasGutters}
            isChanging={isChanging}
            displayExtraButton
            showMetric
            updatedVisible={data.updatedVisible}
            updatedOn={data.updatedOn}
            updatedText={data.updatedText}
            changeStage={() => changeStage(data.requestId)}
            allowStageChange={salesStages[6].showInChange}
            datePicked={datePicked}
            handleDateChange={handleDateChange}
            RedirectTo={(path, data) => RedirectTo(path, data)}
            data={data}
            handleOnUpdateOndata={handleOnUpdateOndata}
            firstCol={"4"}
            secondCol={"8"}
            addModal={addModal}
            setAddModal={setAddModal}
            setCancelModalId={setCancelModalId}
            cancelModalId={cancelModalId}
            setActive={setActive}
            selectedStage={selectedStage}
            setHide={setHide}
            paymentStatus={paymentStatus}
          />
        );

      default:
        return null;
    }
  };

  console.log({ selectedDate });
  const displayTabItem = (active, salesStages) => {
    // const { date } = state;
    const { stageData, isLoading, errors } = props;
    if (isLoading || errors)
      return <InitialLoader error={errors} onReload={loadStageData} />;

    switch (active) {
      case salesStages[0].salesStageId:
        return (
          <>
            <div className="date_time">
              <div className="time">
                <Clock />
              </div>
              <div className="day_date">
                {selectedDate && (
                  <div className="day">{moment(selectedDate[0]).format("dddd")}</div>
                )}
                <div className="date-picker">
                  <DatePicker
                    value={selectedScheduleDate}
                    onChange={dateChange}
                    showLeadingZeros={true}
                  />
                </div>
              </div>
            </div>

            <div className="sales-cards">
              {displayStageData(stageData, salesStages)}
              <SalesCardNotUpon
                allowStageChange={salesStages[0].showInChange}
                RedirectTo={(path, data) => RedirectTo(path, data)}
                stageBeforeData={stageBeforeData}
                changeStageBefore={changeStage}
                // isChanging={isChanging}
                isChanging={""}
                paymentStatus={paymentStatus}
              />
            </div>
          </>
        );

      case salesStages[1].salesStageId:
        return (
          <>
            <div className="sales-cards">
              {displayStageData(stageData, salesStages)}
            </div>
          </>
        );

      case salesStages[2].salesStageId:
        return (
          <>
            <div className="sales-cards">
              {displayStageData(stageData, salesStages)}
            </div>
          </>
        );

      case salesStages[3].salesStageId:
        return (
          <>
            <Col>{renderFilter()}</Col>

            <div className="sales-cards">
              {displayStageData(stageData, salesStages)}
            </div>
          </>
        );

      case salesStages[4].salesStageId:
        return (
          <>
            <Col>{renderFilter()}</Col>

            <div className="sales-cards">
              {displayStageData(stageData, salesStages)}
            </div>
          </>
        );

      case salesStages[5].salesStageId:
        return (
          <>
            <Col>{renderFilter()}</Col>

            <div className="sales-cards">
              {displayStageData(stageData, salesStages)}
            </div>
          </>
        );

      case salesStages[6].salesStageId:
        return (
          <>
            <Col>{renderFilter()}</Col>
            <div className="sales-cards">
              {displayStageData(stageData, salesStages)}
            </div>
          </>
        );

      default:
        return (
          <div className="empty-_tab">
            <p>Please select one of the above tab.</p>
          </div>
        );
    }
  };

  const renderEmptyTab = () => (
    <div className="empty-_tab">
      <p>No new visits are scheduled for any time.</p>
    </div>
  );
  const onHide = () => {
    setModal(false);
    setRequestId("");
  };

  const changeTab = (active, i) => {
    setActive(active);
    setActiveTabIndex(i);
    setPickFirst(i < 3 ? true : false);
  };

  const { salesStagesChange, salesPersons, staticLoading, staticError } = props;

  if (staticLoading || staticError) {
    return <InitialLoader error={staticError} onReload={loadData} />;
  }

  return (
    <div
      style={{
        margin: "2em 1.5em",
        position: "relative",
      }}
    >
      {msg && <Alert variant={msgType}>{msg}</Alert>}
      <Row style={{ alignItems: "center" }}>
        <Col md="2">
          <h3 className="_title">
            Sales Activity &nbsp; &nbsp;
            <Link
              to={{
                pathname: "/dashboard/help",
                search: "Activity Sales",
              }}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
            >
              <span style={{ color: "red" }}>?</span>
            </Link>
          </h3>
        </Col>
        {isOnline ? (
          <Col md="6">
            <SearchField
              type="search"
              placeholder="Search for sale"
              value={searchString}
              name="searchString"
              onChange={(e) => setSearchString(e.target.value)}
              sm
              disabled={paymentStatus == "Frozen 1" ? true : false}
              paymentStatus={paymentStatus}
            />
          </Col>
        ) : (
          <p>
            You are working in offline mode. What you edit in Scheduled tasks
            will be upload to server when you are back online!
          </p>
        )}
        {isOnline ? (
          <Col md="4">
            <Form.Group style={{ margin: "0 4em" }}>
              <Select
                isMulti
                placeholder="View activity by sales persons"
                name="selectPerson"
                value={selectPerson}
                onChange={
                  (value) => setSelectPerson(value)
                  // setState(() => ({ selectPerson: value }))
                }
                options={displayPersons(salesPersons)}
                isDisabled={paymentStatus === "Frozen 1"}
              />
            </Form.Group>
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col>
          {salesStages && salesStages.length > 0 && (
            <>
              <Tabs
                height={""}
                tabs={salesStages}
                count={count}
                active={active}
                setTab={changeTab}
              />
              <div
                className="_tab-content"
              >
                {displayTabItem(active, salesStages)}
              </div>
            </>
          )}
        </Col>
      </Row>

      <PopupModal show={modal} onHide={onHide} heading="Select Sales Stage">
        <Row style={{ margin: "1em" }}>
          {salesStagesChange &&
            salesStagesChange.map((stage) => (
              <button
                disabled={active === stage.salesStageId}
                key={stage.salesStageId}
                onClick={() => handleChangeTab(requestId, stage.salesStageId)}
                className="stage"
              >
                {stage.salesStageText}
              </button>
            ))}
        </Row>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </PopupModal>
    </div>
  );
  // }
};

const mapStateToProps = (state) => {
  const {
    changeStage: { changeFail, changeSuccess, isChanging },
    updateFollowup: { isUpdating, updateFail, updateSuccess },
    updateFollowupData: { isUpdatingData, updateSuccessData, updateFailData },
    login,
  } = state;
  return {
    isUpdating,
    updateFail,
    updateSuccess,
    stageData: stageData(state),
    count: count(state),
    errors: getStage.errors,
    isLoading: getStage.isLoading,
    staticLoading: state.staticAll.isLoading,
    staticError: state.staticAll.errors,
    changeFail,
    changeSuccess,
    isChanging,
    salesStages: salesTabs(state),
    salesStagesChange: salesStagesChange(state),
    salesPersons: SalesPersons(state),
    userRole: login.user ? login.user.userData.role : null,
    paymentStatus: login?.user?.userData?.paymentStatus
      ? login.user.userData.paymentStatus
      : null,
    updateFailData,
    updateSuccessData,
    isUpdatingData,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchStaticAll: () => dispatch(fetchStaticAll()),
    getStage: (data) => dispatch(getStage({ ...data })),
    changeStage: (data) => dispatch(changeStage({ ...data })),
    updateFollowup: (data) => dispatch(updateFollowup({ ...data })),
    updateFollowupData: (data) => dispatch(updateFollowupData({ ...data })),

    fetchForCalendar: () => dispatch(fetchForCalendar()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesActivity);
