import { axiosInstance } from "services";
import { autoTextActionTypes } from "./auto-text.types";
import { ApiEndpoints } from "lib/config/baseSettings";

export const autoTextAPI = (category) =>
  axiosInstance.get(`${ApiEndpoints.AUTOTEXT.AUTO_TEXT}${category}`);

export const fetchAutoText = (category) => (dispatch) => {
  dispatch({ type: autoTextActionTypes.AUTO_TEXT_FETCH_START });
  return autoTextAPI(category)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        dispatch(
          dispatch({
            type: autoTextActionTypes.AUTO_TEXT_FETCH_SUCCESS,
            payload: data,
          })
        );
      } else {
        dispatch(
          dispatch({
            type: autoTextActionTypes.AUTO_TEXT_FETCH_FAILURE,
            payload: data.error,
          })
        );
      }
    })
    .catch((error) =>
      dispatch(
        dispatch({
          type: autoTextActionTypes.AUTO_TEXT_FETCH_FAILURE,
          payload: error.message,
        })
      )
    );
};

export const updateAutoText = (id, data, onSuccess, onError) => (dispatch) =>
  axiosInstance
    .patch(`${ApiEndpoints.AUTOTEXT.AUTO_TEXT}${id}`, data)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        onSuccess();
      } else {
        onError(data.error);
      }
    })
    .catch((error) => onError(error.message));

export const fetchStandardStatements = (category) => (dispatch) => {
  dispatch({ type: autoTextActionTypes.STANDARD_STATEMENTS_FETCH_START });

  axiosInstance
    .get(ApiEndpoints.AUTOTEXT.STANDARD_STATEMENT)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        dispatch(
          dispatch({
            type: autoTextActionTypes.STANDARD_STATEMENTS_FETCH_SUCCESS,
            payload: data,
          })
        );
      } else {
        dispatch(
          dispatch({
            type: autoTextActionTypes.STANDARD_STATEMENTS_FETCH_FAILURE,
            payload: data.error,
          })
        );
      }
    })
    .catch((error) =>
      dispatch(
        dispatch({
          type: autoTextActionTypes.STANDARD_STATEMENTS_FETCH_FAILURE,
          payload: error.message,
        })
      )
    );
};

export const updateStandardStatements =
  (id, data, onSuccess, onError) => (dispatch) =>
    axiosInstance
      .patch(`${ApiEndpoints.AUTOTEXT.STANDARD_STATEMENT}${id}`, data)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          onSuccess();
        } else {
          onError(data.error);
        }
      })
      .catch((error) => onError(error.message));
