import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Finalize from "./FinalizePdf/Finalize";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./ProposalTab/ProposalTab.scss";
import useContactData from "hooks/useEmailContract";
import DatePickerNew from "components/DatePickerWithRemove";
import { toast } from "react-toastify";
import use_quote from "hooks/useQuote";
import { PopupModal } from "components";
import { ModalFooter } from "components/popup-modal/popup-modal";
import { EmailModal } from "./ProposalTab/EmailModal";
import { StartOverSection } from "./StartOver/StartOver";

export function Sections({
  HeadingText,
  proposalData,
  showModal,
  onHideModal,
  switchModalData,
  currentSection,
  BtnsData,
  otherTexts,
  fixedStandardStatements,
  setFixedText,
  setOtherTexts,
  updateFixedStandardStatementsPercentage,
  quoteMaterialSectionData,
  updateFixedStandardStatementsisSelected,
  updateFixedStandardStatementdisplayText,
  isLockQuoteData,
  leadRequestId,
  SendEmailContract,
  isEmailSent,
  totalWithMarkUp,
  setOpenHomeWonSignature,
  setOpenAuthorizedSignature,
  AddDateToContract,
  signatureSeller,
  signatureClient,
  pdflineItem,
  addClientSignature,
  addSellerSignature,
  contractId,
  modalShow,
  modalShowv2,
  quoteProposalData,
  proposalModal,
  proposal_modal,
  type,
  changeProposalStatus,
  firstTime,
  signingDate,
  showEmailModal,
  setShowEmailModal,
}) {
  const [show, setShow] = useState(false);
  const [changedamount, setChangedAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("Cash");
  const [changedDate, setChangedDate] = useState("");
  const [file, setFile] = useState();
  const [url, setUrl] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [emailPdf, setEmailPdf] = useState();

  const [isUploading, setIsUploading] = useState(false);

  const { contractDate, update_signed_contract } = useContactData();
  const { letterHead } = use_quote();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    if (contractId !== undefined) {
      setPaymentMode(contractId.contract.paymentMethod);
      setChangedDate(contractId.contract.date);
      setChangedAmount(contractId.contract.depositAmount);
    }
  };
  
  useEffect(() => {
    handleClose();
  }, []);

  console.log("leadRequestId ", leadRequestId);

  const handleOnClickContractDeposit = (deposit) => {
    changeProposalStatus({
      proposalsNotSent: false,
      proposalsSent: true,
      makeContract: true,
    });
    AddDateToContract({
      proposalId: isLockQuoteData?._id,
      leadRequestId: leadRequestId,
      depositAmount: deposit,
      paymentMethod: paymentMode || "Cash",
      date: changedDate,
    });
    // onHideModal();
    setShow(false);
    toast.success(" Contract Successfully Deposited ");
  };

  useEffect(() => {
    proposal_modal(proposalModal);
  }, [proposal_modal]);

  let handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  let handleUpload = async (e) => {
    let form_data = new FormData();
    form_data.append("uploads", file);
    form_data.append("leadRequestId", contractId.contract.leadRequestId);

    setIsUploading(true);
    let { url } = await update_signed_contract(form_data);
    setUrl(url);
  };


  console.log({BtnsData, currentSection, type});

  useEffect(() => {
    setIsUploading(false);
  }, [url]);

  if (type) {
    switch (currentSection) {
      case BtnsData[0].name:
        return (
          <>
            <StartOverSection
              HeadingText={HeadingText}
              proposalData={otherTexts}
              setOtherTexts={setOtherTexts}
              setFixedText={setFixedText}
              otherTexts={otherTexts}
              fixedStandardStatements={fixedStandardStatements}
              showModal={showModal}
              onHideModal={onHideModal}
              switchModalData={switchModalData}
              updateFixedStandardStatementsPercentage={
                updateFixedStandardStatementsPercentage
              }
              quoteMaterialSectionData={quoteMaterialSectionData}
              updateFixedStandardStatementsisSelected={
                updateFixedStandardStatementsisSelected
              }
              updateFixedStandardStatementdisplayText={
                updateFixedStandardStatementdisplayText
              }
              isLockQuoteData={isLockQuoteData}
              totalWithMarkUp={totalWithMarkUp}
              modalShow={modalShow}
              modalShowv2={modalShowv2}
              quoteProposalData={quoteProposalData}
              proposalModal={proposalModal}
              buttonData={BtnsData[0].name}
              contractId={contractId}
              currentSection={currentSection}
              firstTime={firstTime}
            />
          </>
        );
      case BtnsData[1].name:
        return (
          <>
            <Finalize
              otherTexts={otherTexts}
              setOtherTexts={setOtherTexts}
              fixedStandardStatements={fixedStandardStatements}
              proposalData={proposalData}
              totalWithMarkUp={totalWithMarkUp}
              signatureSeller={signatureSeller}
              signatureClient={signatureClient}
              pdflineItem={pdflineItem}
              addClientSignature={addClientSignature}
              addSellerSignature={addSellerSignature}
              proposalModal={proposalModal}
              quoteProposalData={quoteProposalData}
              buttonData={BtnsData[1].name}
              contractId={contractId}
              currentSection={currentSection}
              letterHead={letterHead}
              signingDate={signingDate}
              isUploading={isUploading}
            />
          </>
        );

      case BtnsData[2].name:
        return (
          <>
            <Finalize
              otherTexts={otherTexts}
              setOtherTexts={setOtherTexts}
              fixedStandardStatements={fixedStandardStatements}
              proposalData={proposalData}
              totalWithMarkUp={totalWithMarkUp}
              signatureSeller={signatureSeller}
              signatureClient={signatureClient}
              pdflineItem={pdflineItem}
              addClientSignature={addClientSignature}
              addSellerSignature={addSellerSignature}
              proposalModal={proposalModal}
              quoteProposalData={quoteProposalData}
              buttonData={BtnsData[2].name}
              contractId={contractId}
              currentSection={currentSection}
              letterHead={letterHead}
              signingDate={signingDate}
              isUploading={isUploading}
              setEmailPdf={setEmailPdf}
            />
            <button
              type="button"
              class="btn btn-warning"
              style={{ float: "Right", marginBottom: "18px", padding: "10px" }}
              onClick={(selectedEmail) => {
                changeProposalStatus({
                  proposalsNotSent: false,
                  proposalsSent: true,
                });

                SendEmailContract({
                  leadRequestId: leadRequestId,
                  selectedEmail,
                  emailPdf,
                });
              }}
            >
              <strong>{isEmailSent ? "Resend Email" : "Send Email"} </strong>
            </button>
          </>
        );
      default:
        return null;
    }
  } else {
    switch (currentSection) {
      case BtnsData[0].name:
        return (
          <>
            <StartOverSection
              HeadingText={HeadingText}
              proposalData={otherTexts}
              setOtherTexts={setOtherTexts}
              setFixedText={setFixedText}
              otherTexts={otherTexts}
              fixedStandardStatements={fixedStandardStatements}
              showModal={showModal}
              onHideModal={onHideModal}
              switchModalData={switchModalData}
              updateFixedStandardStatementsPercentage={
                updateFixedStandardStatementsPercentage
              }
              quoteMaterialSectionData={quoteMaterialSectionData}
              updateFixedStandardStatementsisSelected={
                updateFixedStandardStatementsisSelected
              }
              updateFixedStandardStatementdisplayText={
                updateFixedStandardStatementdisplayText
              }
              isLockQuoteData={isLockQuoteData}
              totalWithMarkUp={totalWithMarkUp}
              modalShow={modalShow}
              modalShowv2={modalShowv2}
              quoteProposalData={quoteProposalData}
              proposalModal={proposalModal}
              buttonData={BtnsData[0].name}
              contractId={contractId}
              currentSection={currentSection}
              firstTime={firstTime}
            />
          </>
        );

      case BtnsData[1].name:
        return (
          <>
            <Finalize
              otherTexts={otherTexts}
              setOtherTexts={setOtherTexts}
              fixedStandardStatements={fixedStandardStatements}
              proposalData={proposalData}
              totalWithMarkUp={totalWithMarkUp}
              signatureSeller={signatureSeller}
              signatureClient={signatureClient}
              pdflineItem={pdflineItem}
              addClientSignature={addClientSignature}
              addSellerSignature={addSellerSignature}
              proposalModal={proposalModal}
              quoteProposalData={quoteProposalData}
              buttonData={BtnsData[1].name}
              contractId={contractId}
              currentSection={currentSection}
              letterHead={letterHead}
              signingDate={signingDate}
              isUploading={isUploading}
            />
          </>
        );

      case BtnsData[2].name:
        return (
          <>
            <Finalize
              otherTexts={otherTexts}
              setOtherTexts={setOtherTexts}
              fixedStandardStatements={fixedStandardStatements}
              proposalData={proposalData}
              totalWithMarkUp={totalWithMarkUp}
              signatureSeller={signatureSeller}
              signatureClient={signatureClient}
              pdflineItem={pdflineItem}
              addClientSignature={addClientSignature}
              addSellerSignature={addSellerSignature}
              proposalModal={proposalModal}
              quoteProposalData={quoteProposalData}
              buttonData={BtnsData[2].name}
              contractId={contractId}
              currentSection={currentSection}
              letterHead={letterHead}
              signingDate={signingDate}
              isUploading={isUploading}
              setEmailPdf={setEmailPdf}
            />

            <EmailModal
              isShow={showEmailModal}
              onHide={() => {
                setShowEmailModal(false);
              }}
              leadId={leadRequestId}
              sendEmails={(selectedEmail) => {
                //WARNING: NOT SENDING EMAIL
                changeProposalStatus({
                  proposalsNotSent: false,
                  proposalsSent: true,
                });
                
                let form_data = new FormData();
                form_data.append("emailPdf", emailPdf);
                form_data.append("leadRequestId", leadRequestId);
                form_data.append("selectedEmail", [...selectedEmail]);

                console.log("form_data ", {form_data, leadRequestId});
                SendEmailContract(form_data);

              }}
            />
          </>
        );
      case BtnsData[3].name:
        const deposit =
          totalWithMarkUp * (fixedStandardStatements[2].percentage / 100);
        return (
          <>
            <Finalize
              AddDateToContract={AddDateToContract}
              contractDate={contractDate}
              otherTexts={otherTexts}
              setOtherTexts={setOtherTexts}
              fixedStandardStatements={fixedStandardStatements}
              proposalData={proposalData}
              totalWithMarkUp={totalWithMarkUp}
              signatureSeller={signatureSeller}
              signatureClient={signatureClient}
              pdflineItem={pdflineItem}
              addClientSignature={addClientSignature}
              addSellerSignature={addSellerSignature}
              proposalModal={proposalModal}
              quoteProposalData={quoteProposalData}
              buttonData="make_contract"
              contractId={contractId}
              currentSection={currentSection}
              url={url}
              letterHead={letterHead}
              signingDate={signingDate}
              isUploading={isUploading}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="button"
                class="btn btn-primary"
                variant="outline-secondary"
                style={{ margin: "2%", padding: "0.7rem" }}
                onClick={() => setShowUploadModal(true)}
              >
                Upload Hard Copy
              </Button>
              <button
                type="button"
                class="btn btn-primary"
                style={{ margin: "2%", padding: "0.7rem" }}
                onClick={() => {
                  setOpenHomeWonSignature(true);
                  setOpenAuthorizedSignature(false);
                }}
                disabled={
                  (contractId && contractId.contract.signed_contract !== "") ||
                  url ||
                  signatureClient
                }
              >
                Add Home Owners Signature
              </button>
              <button
                type="button"
                class="btn btn-primary"
                style={{ margin: "2%", padding: "0.7rem" }}
                onClick={() => {
                  setOpenAuthorizedSignature(true);
                  setOpenHomeWonSignature(false);
                }}
                disabled={
                  (contractId && contractId.contract.signed_contract !== "") ||
                  url ||
                  signatureSeller
                }
              >
                Add Authorized Signature
              </button>
              
              {contractId?.contract.paymentMethod !== "" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <strong>{`Deposited $${Number.parseFloat(
                    contractId?.contract.depositAmount
                  ).toFixed(2)} at ${contractId?.contract.date}`}</strong>
                </div>
              ) : (
                <button
                  type="button"
                  class="btn btn-warning float-right"
                  style={{ margin: "2%", padding: "0.7rem 1.4rem" }}
                  onClick={handleShow}
                  disabled={
                    contractId
                      ? (!addClientSignature || !addSellerSignature) &&
                        contractId.contract.signed_contract == "" &&
                        url == undefined
                      : (!addClientSignature || !addSellerSignature) &&
                        url == undefined
                  }
                >
                  <strong>Deposit</strong>
                </button>
              )}
              {showUploadModal && (
                <PopupModal
                  show={showUploadModal}
                  heading={"Upload Hard Copy of Contract"}
                  onHide={() => setShowUploadModal(false)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                  >
                    <input
                      class="btn"
                      type="file"
                      name="file"
                      style={{ margin: "2%", padding: "0.7rem" }}
                      onChange={(e) => handleFile(e)}
                    />
                    <ModalFooter
                      onCancel={() => setShowUploadModal(false)}
                      onSubmit={(e) => {
                        handleUpload(e);
                        setShowUploadModal(false);
                      }}
                      submitText={"Upload"}
                    />
                  </div>
                </PopupModal>
              )}
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              className="modal-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Deposit</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <Row
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Col>
                      <div class="form-group">
                        <label for="">Amount</label>
                        <input
                          type="amount"
                          class="form-control"
                          value={deposit}
                          disabled
                          //onChange={(e) => setChangedAmount(e.target.value)}
                          placeholder="$"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div class="form-group">
                        <label for="exampleFormControlSelect1">
                          Payment Method
                        </label>
                        <select
                          class="form-control"
                          id="exampleFormControlSelect1"
                          onChange={(e) => setPaymentMode(e.target.value)}
                        >
                          <option>Cash</option>
                          <option>Check</option>
                          <option>Credit Card</option>
                          <option>Money Order</option>
                        </select>
                      </div>
                    </Col>
                    <Col>
                      <div class="form-group">
                        <label for="">Date</label>
                        {/* <DatePicker label="Select Date"
                        onChange={(e)=>setChangedDate(e.target.value)} /> */}
                        <DatePickerNew
                          value={changedDate}
                          setValue={setChangedDate}
                          disabled={false}
                        />
                      </div>
                    </Col>
                  </Row>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose} className="me-5">
                  No
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    handleOnClickContractDeposit(deposit);
                  }}
                >
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      default:
        return null;
    }
  }
}