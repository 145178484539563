export const SuppliersText = {
  selectSuppliers: 'Select supplier',
  noDataAvailable: 'No data available',
};

export const StaticTexts = {
  loadingAddress: 'Loading address...',
  taxesErr: 'Error fetching the state taxes data.',
  quoteMarkUpErr: 'Unable to fetch quote mark up data.',
};
