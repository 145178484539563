import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  View,
  Document,
  Page,
  Text,
  Font,
  Image,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { FOOTER_TEXT } from "lib/constants/ui-const";
import { useSelector } from "react-redux";
import useContactData from "hooks/useEmailContract";
import { LoadingLoader } from "components";

const Quixote = ({
  contractDate,
  proposalData,
  fixedStandardStatements,
  otherTexts,
  totalWithMarkUp,
  signatureSeller,
  signatureClient,
  pdflineItem,
  addClientSignature,
  addSellerSignature,
  letterHead,
}) => {
  const filterData = otherTexts.filter((res) => {
    if (res.dataId === "STD016") {
      if (res.isDown === false) {
        return true;
      } else {
        return false;
      }
    }
    return res.isSelected !== false;
  });

  const thirtyDay = otherTexts.filter((value) => {
    if (value.dataId === "STD016" && value.isSelected) {
      if (value.isDown === false) {
        return false;
      } else {
        return true;
      }
    }
  })[0];

  console.log({otherTexts});

  let base64 = letterHead?.letterHeadImageForClientSide;
  
  const getNumber = (dataId, percentage = null, fixedStandardStatements) => {
    const total = Number(totalWithMarkUp);

    const calculateBalance = (fixedStandardStatements) => {
      let percentage = 0;
      fixedStandardStatements.map((value) => {
        if (value.percentage) {
          percentage += value.percentage;
        }
      });

      return total * (1 - percentage / 100);
    };

    if (dataId === "STD011") {
      return `$${total.toFixed(2)}`;
    } else if (dataId === "STD012") {
      if (!percentage && percentage !== 0) {
        return 0;
      }
      const deposit = total * (percentage / 100);
      return `$${deposit.toFixed(2)}`;
    } else if (dataId === "STD013") {
      if (!percentage && percentage !== 0) {
        const balance = calculateBalance(fixedStandardStatements);
        return `$${balance.toFixed(2)}`;
      } else {
        const start = total * (percentage / 100);
        return `$${start.toFixed(2)}`;
      }
    }
    return null;
  };

  console.log({proposalData, base64, letterHead});

  return (
    <Document>
      <Page style={styles.body} wrap>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {base64 && base64.lenght > 10 ? <Text style={{}}>
            <Image src={base64} style={styles.letterHeadImage} />
          </Text> : null }
        </View>
        <View style={styles.page}>
          <View style={styles.content}>
            <View style={styles.section}>
              {proposalData.map((proposalText) => (
                <Text key={proposalText._id} style={styles.text}>
                  {proposalText.displayText}
                </Text>
              ))}
            </View>

            <View style={styles.section}>
              <View style={styles.table}>
                {filterData.map((proposalText, index) => (
                  <View style={styles.trr}>
                    <View key={proposalText._id}>
                      {(index === 0 ||
                        (filterData[index]?.category ===
                          "Warranty & Guarantee Statements" &&
                          filterData[index + 1]?.category !==
                          "Warranty & Guarantee Statements")) && (
                          <View style={{ margin: 4 }} />
                        )}
                      <Text style={styles.text}>
                        {proposalText.displayText}
                      </Text>
                      {(index === 0 ||
                        (filterData[index]?.category !==
                          "Warranty & Guarantee Statements" &&
                          filterData[index + 1]?.category ===
                          "Warranty & Guarantee Statements")) && (
                          <View style={{ margin: 4 }} />
                        )}
                    </View>
                  </View>
                ))}
                <Text style={styles.text}>{contractDate}</Text>
              </View>
            </View>
            {/* {pdflineItem && pdflineItem !== "" && (
              <View style={styles.section} break>
                <View
                // style={{ marginTop: "90%" }}
                >
                  <Text style={styles.text}>{pdflineItem}</Text>
                </View>
              </View>
            )} */}
            <View style={styles.section}>
              <View style={styles.totaltable}>
                {fixedStandardStatements.map((proposalText, index) => (
                  <View style={styles.totaltr}>
                    <View
                      key={proposalText._id}
                      style={
                        index === 0 ? styles.totaltext : styles.totaltextindex
                      }
                    >
                      <Text style={styles.text}>
                        {proposalText.displayText}
                      </Text>
                      {/* barrier */}
                      {/* {proposalText.dataId === "STD011" && ( */}
                      <Text style={{ ...styles.text, marginHorizontal: 8 }}>
                        {getNumber(
                          proposalText.dataId,
                          proposalText.percentage,
                          fixedStandardStatements
                        )}
                      </Text>
                      {/* )} */}
                    </View>
                  </View>
                ))}
              </View>
            </View>

            {thirtyDay && (
              <View>
                <Text style={styles.text}>{thirtyDay.displayText}</Text>
              </View>
            )}
            <View>
              <View style={styles.row}>
                {FOOTER_TEXT.map((texts) => (
                  <View style={styles.col}>
                    <Text style={styles.text}>{texts.person}</Text>
                    <Text style={{}}>
                      {(signatureClient || addClientSignature) &&
                        texts.type == 1 && (
                          <Image
                            src={signatureClient ?? addClientSignature}
                            style={styles.image}
                          />
                        )}
                      {(signatureSeller || addSellerSignature) &&
                        texts.type == 2 && (
                          <Image
                            src={signatureSeller ?? addSellerSignature}
                            style={styles.image}
                          />
                        )}
                    </Text>
                    <Text style={styles.sign}>{texts.signHolder}</Text>
                    <Text style={styles.text}>{texts.name}</Text>
                    <Text style={styles.text}>{texts.date}</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>

        {/* <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
            )} fixed /> */}
      </Page>
    </Document>
  );
};

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

export default function Finalize({
  proposalData,
  fixedStandardStatements,
  otherTexts,
  totalWithMarkUp,
  contractDate,
  signatureSeller,
  signatureClient,
  pdflineItem,
  addClientSignature,
  addSellerSignature,
  quoteProposalData,
  buttonData,
  contractId,
  currentSection,
  url,
  letterHead,
  signingDate,
  setOtherTexts,
  isUploading,
  setEmailPdf = null,
}) {
  const [contractOtherStatements, setContractStatement] = useState(otherTexts);
  const { proposalId, proposalIdx } = useSelector(
    (state) => state.activeProposal
  );

  const { edit_quote } = useContactData();

  console.log({otherTexts11: otherTexts, proposalData});
  useEffect(() => {
    const getPDFString = async () => {
      if (!setEmailPdf) return;
      try {
        let stringPDF = await pdf(
          <Quixote
            contractDate={contractDate}
            proposalData={proposalData}
            fixedStandardStatements={fixedStandardStatements}
            otherTexts={contractOtherStatements}
            totalWithMarkUp={totalWithMarkUp}
            signatureClient={signatureClient}
            signatureSeller={signatureSeller}
            pdflineItem={pdflineItem}
            addClientSignature={addClientSignature}
            addSellerSignature={addSellerSignature}
            letterHead={letterHead}
          />
        ).toBlob();
        setEmailPdf(stringPDF);
      } catch (err) {
        console.error("here eerrror", err);
      }
    };

    getPDFString();
  }, [otherTexts]);

  useEffect(() => {
    const proposalAndContractCondition = (value) =>
      currentSection === "Finalize"
        ? value.dataId !== "STD009"
        : currentSection === "Make Contract"
          ? value.dataId !== "STD016"
          : true;

    const contractStatements = otherTexts.filter(
      (value) => proposalAndContractCondition(value) //value.category !== "Good For Disclosure"
    );
    setContractStatement(contractStatements);
  }, [otherTexts]);

  useEffect(() => {
    if (currentSection === "Make Contract") {
      //dateSigned,
      const { dateSelected, dateSigned } = signingDate;

      if (dateSelected === "" || dateSigned === "") return;
      const parseCancellationStatement = async () => {
        let oldCancelStatement = otherTexts.filter((value) => {
          return value.dataId === "STD009";
        })[0];

        let cancelStatement = {
          ...oldCancelStatement,
          displayText: oldCancelStatement.displayText.replace(
            "MM/DD/YYYY",
            dateSigned
          ),
        };

        cancelStatement = {
          ...cancelStatement,
          displayText: cancelStatement.displayText.replace(
            "MM/DD/YYYY",
            dateSelected
          ),
        };

        if (cancelStatement.displayText === oldCancelStatement.displayText) {
          return;
        }

        const newOtherText = [
          ...otherTexts.map((value) => {
            if (value.dataId === "STD009") {
              return cancelStatement;
            }
            return value;
          }),
        ];
        if (otherTexts) setOtherTexts(newOtherText);
        await onEditStatement(newOtherText);
        //ancelStatement.replace("MM/DD/YYYY", cancelDate);
      };
      parseCancellationStatement();
    }
  }, [signingDate]);

  const onEditStatement = async (newOtherTexts) => {
    let {
      _id,
      _sid,
      excludedBuildings,
      excludedSections,
      fixedStandardStatements,
      isLocked,
      isLockedTime,
      isProposalCreated,
      otherTexts,
      leadRequestId,
      makeContract,
      markUp,
      outPut,
      pdf,
      proposalsNotSent,
      proposalsSent,
      quoteName,
      requestId,
      stateTax,
      total,
      inserted_data,
    } = quoteProposalData[proposalIdx];

    await edit_quote({
      _id,
      _sid,
      excludedBuildings,
      excludedSections,
      fixedStandardStatements,
      isLocked,
      isLockedTime,
      isProposalCreated,
      leadRequestId,
      makeContract,
      markUp,
      otherTexts: newOtherTexts,
      outPut,
      pdf,
      proposalsNotSent,
      proposalsSent,
      quoteName,
      requestId,
      stateTax,
      total,
      inserted_data,
    });
  };

  if (currentSection === "Make Contract") {
    if (isUploading) {
      return <LoadingLoader />;
    }

    if ((contractId && contractId.contract.signed_contract !== "") || url) {
      return (
        <div style={{ height: "80vh", marginTop: "1rem" }}>
          <object
            data={url ? url : contractId?.contract?.signed_contract}
            type="application/pdf"
            width="100%"
            height="100%"
          ></object>
        </div>
      );
    } else {
      return (
        <div style={{ margin: "2em auto" }}>
          <PDFViewer height={800} width={"100%"}>
            <Quixote
              contractDate={contractDate}
              proposalData={proposalData}
              fixedStandardStatements={fixedStandardStatements}
              otherTexts={contractOtherStatements}
              totalWithMarkUp={totalWithMarkUp}
              signatureClient={signatureClient}
              signatureSeller={signatureSeller}
              pdflineItem={pdflineItem}
              addClientSignature={addClientSignature}
              addSellerSignature={addSellerSignature}
              letterHead={letterHead}
            />
          </PDFViewer>
        </div>
      );
    }
  } else {
    return (
      <div style={{ margin: "2em auto" }}>
        <PDFViewer height={800} width={"100%"}>
          <Quixote
            contractDate={contractDate}
            proposalData={proposalData}
            fixedStandardStatements={fixedStandardStatements}
            otherTexts={contractOtherStatements}
            totalWithMarkUp={totalWithMarkUp}
            signatureSeller={signatureSeller}
            pdflineItem={pdflineItem}
            addClientSignature={addClientSignature}
            addSellerSignature={addSellerSignature}
            letterHead={letterHead}
          />
        </PDFViewer>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  body: {
    paddingLeft: 20,
    paddingRight: 20,
    // widthHorizontal: "100%",
  },
  page: {
    flex: 1,
    // margin: 35,
    // padding: 10
  },
  content: {
    flex: 1,
  },
  row: {
    flexDirection: "row",
    marginTop: "12px",
  },
  col: {
    flex: 1,
  },
  col2: {
    flex: 1,
    borderBottomWidth: 1,
  },
  tr: {
    flexDirection: "row",
    // borderLeftWidth: 1,
    // borderTopWidth: 1,
  },
  trr: {
    flexDirection: "row",

    marginBottom: 0,
    padding: 1,
  },
  td: {
    flex: 1,
    padding: 2,
  },
  table: {
    marginBottom: 8,
  },

  totaltable: {
    marginBottom: 8,
  },
  totaltr: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
    borderBottomWidth: 2,
  },
  text: {
    marginVertical: 0,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  totaltext: {
    // margin: 4,
    marginVertical: 2,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",

    marginBottom: 8,
  },
  totaltextindex: {
    display: "flex",
    flexDirection: "row",
    // margin: 4,
    marginVertical: 2,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",

    justifyContent: "space-between",
    width: "100%",
  },
  htext: {
    marginVertical: 2,
    fontSize: 15,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
    height: 70,
    width: 185,
    marginBottom: 20,
  },
  header: {
    fontSize: 1,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  sign: {
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    marginBottom: 13,
  },
  section: {
    marginVertical: 0,
    //marginTop: "-2px",
    //textAlign: "center",
  },
  headingsection: {
    marginVertical: 10,
    marginTop: "-8px",
    textAlign: "center",
  },
  headeritem: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  total: {
    marginRight: "10px",
  },
  letterHeadImage: {
    marginTop: 0,
    width: 554,
    height: 140,
  },
});
