import { getData } from "services/storageManager";

const getRoofMetrics = (metricId) => {
  const oriMetric = getData(`metrics${metricId}`);
  const changeMetric = getData(`changesMetric${metricId}`);

  if (oriMetric === null) {
    console.error("no such metrics data");
    return null;
  }
  if (changeMetric !== null) {
    return {
      data: {
        ...oriMetric.data,
        metricData: changeMetric.metricData,
      },
    };
  }

  return oriMetric;
};

const getGuttersMetrics = (metricId) => {
  const oriMetric = getData(`Gmetrics${metricId}`);
  const changeMetric = getData(`changesGMetric${metricId}`);

  if (oriMetric === null) {
    console.error("no such metrics data");
    return null;
  }
  if (changeMetric !== null) {
    return {
      data: {
        ...oriMetric.data,
        metricData: changeMetric.metricData,
      },
    };
  }

  return oriMetric;
};

export { getRoofMetrics, getGuttersMetrics };
