import { getStatements } from "services/offlineComputing/controllers/standardStatement";
import { getRoofMetrics } from "services/offlineComputing/models/metricsModel";
import quoteModel from "services/offlineComputing/models/quote";
import { getData } from "services/storageManager";

const STANDARD_STATEMENTS_DEFAULTS_DATA_ID = [
  "STD010",
  "STD011",
  "STD012",
  "STD013",
];

const STANDARD_STATEMENTS_OPENING_PROPOSAL = "STD001";

export const checkProposalData = async (q) => {
  const quoteProposal = await quoteModel.find(q);

  if (quoteProposal.length == 0) {
    throw new Error("Proposal doesnot exist.");
  }
  return quoteProposal[0];
};
export const getProposalTextData = async ({
  requestId,
  excludedBuildings,
  excludedSections,
  companyId,
}) => {
  const roofingMetricData = getRoofMetrics(requestId); //getData(`metrics${requestId}`);

  if (roofingMetricData === null) {
    console.log("no metrics found", requestId);
    return null;
  }

  let resMetricData = [],
    autoTextsRemaining = [],
    subCategories = [],
    hoodVents = [],
    autoTextAppendElevationDetails = {},
    hideData = {},
    hideDatas = [],
    elevation_and_elevation_details = [];
  const queryWithCompanyId = { companyId };

  const selection = {
    displayText: 1,
    order: 1,
    isSelected: 1,
  };

  // const autoTextsRemainingSelection = { ...selection, dataId: 1 };
  // let res_metric_data = [];
  // let res_gutters_data = [];
  // let res_gutters_rest_data;

  // if (roofingMetricData.length > 0) {
  //   //////here bug
  //   let autoTextDetailsForRoofing = await parseMetricDataForAutoTexts(
  //     roofingMetricData[0],
  //     excludedBuildings,
  //     excludedSections
  //   );

  //   hoodVents = autoTextDetailsForRoofing.hoodVents;
  //   hideData = autoTextDetailsForRoofing.hideData;
  //   hideDatas = autoTextDetailsForRoofing.hideDatas;
  //   autoTextAppendElevationDetails =
  //     autoTextDetailsForRoofing.autoTextAppendElevationDetails;
  //   elevation_and_elevation_details =
  //     autoTextDetailsForRoofing.elevation_and_elevation_details;

  //   const { roofingUserSelections, autoTextRemaining } =
  //     autoTextDetailsForRoofing;

  //   subCategories = autoTextDetailsForRoofing.singleTextMetric;

  //   resMetricData = removeDuplicatesFromObjectAndArray(
  //     roofingUserSelections,
  //     AUTO_TEXT_ROOFING_SELECTION_DATA_ID
  //   );

  //   for (let key in resMetricData) {
  //     resMetricData[key].map((val) => {
  //       res_metric_data.push(val);
  //     });
  //   }

  //   res_metric_data = [...res_metric_data, ...hoodVents, ...hideDatas];

  //   autoTextsRemaining = await getAutoTextsNonAggregate(
  //     queryWithCompanyId,
  //     {
  //       showInModal: false,
  //       dataId: {
  //         $in: autoTextRemaining,
  //       },
  //     },
  //     autoTextsRemainingSelection
  //   );
  // }

  const statementSelections = {
    ...selection,
    hasDate: 1,
  };

  const getStatementsReusable = (dataIdArr) =>
    getStatements(
      {
        companyId,
        isSelected: true,
        dataId: { $in: dataIdArr },
      },
      statementSelections
    );

  const fixedStandardStatements = await getStatementsReusable(
    STANDARD_STATEMENTS_DEFAULTS_DATA_ID
  );

  // let includedStatementsIds = [];

  // const hideMetaData = extractStandardStatementsIdKey(hideData);

  // Object.keys(hideMetaData).map((key) => {
  //   const id = STANDARD_STATEMENTS_DATA_IDS[key];
  //   if (id && !hideData[key]) {
  //     checkAndAddIncluded(includedStatementsIds, id);
  //   }
  // });

  // const otherStatements = await getStatementsReusable(includedStatementsIds);

  // const guttersMetricData = await getUnparsedGuttersMetricData({
  //   requestId,
  // });

  // let resGuttersData = [],
  //   guttersAutoTextAppendElevationDetails = {};

  // if (guttersMetricData.length > 0) {
  //   let { guttersUserSelections, ...rest } = await parseGuttersDataForAutoTexts(
  //     guttersMetricData[0],
  //     excludedBuildings,
  //     excludedSections
  //   );

  //   res_gutters_rest_data = rest;
  //   resGuttersData = removeDuplicatesFromObjectAndArray(
  //     guttersUserSelections,
  //     AUTO_TEXT_GUTTERS_DATA_ID
  //   );

  //   for (let key in resGuttersData) {
  //     resGuttersData[key].map((val) => {
  //       res_gutters_data.push(val);
  //     });
  //   }

  //   guttersAutoTextAppendElevationDetails =
  //     rest.guttersAutoTextAppendElevationDetails;
  // }

  // if (hoodVents.length != 0) {
  //   hoodVents = hoodVents;
  // }

  // const q = {
  //   showInModal: false,
  //   $or: [
  //     {
  //       subCategory: {
  //         $in: subCategories,
  //       },
  //     },
  //   ],
  // };

  // const autoTextsData = await getAutoTextsNonAggregate(
  //   queryWithCompanyId,
  //   q,
  //   selection
  // );

  // const getAppendData = (dataId, elevationDetailsData) => {
  //   if (!dataId) return "";

  //   if (dataId && typeof dataId === "string")
  //     return elevationDetailsData[dataId];
  //   else {
  //     let appendData = "";
  //     dataId.forEach((id) => {
  //       if (elevationDetailsData[id]) {
  //         appendData = elevationDetailsData[id];
  //       }
  //     });
  //     return appendData;
  //   }
  // };

  // autoTextsRemaining = autoTextsRemaining.map((textData) => {
  //   const { _id, displayText, dataId, order } = textData;
  //   const appendText = getAppendData(dataId, autoTextAppendElevationDetails);
  //   const updatedDisplayText = `${displayText} ${appendText ? appendText : ""}`;

  //   return {
  //     _id,
  //     displayText: updatedDisplayText,
  //     order,
  //   };
  // });

  // let auto_text_gutters_data = await get_auto_text_gutters_data(
  //   res_gutters_data,
  //   res_gutters_rest_data
  // );
  // let auto_text_data = await get_auto_text_data(
  //   res_metric_data,
  //   elevation_and_elevation_details
  // );
  // auto_text_data = [
  //   ...auto_text_data,
  //   ...auto_text_gutters_data,
  //   ...autoTextsData,
  // ];

  // auto_text_data = auto_text_data.sort((a, b) => a.order - b.order);

  const openingProposalData = await getStatements(
    {
      companyId,
      isSelected: true,
      dataId: STANDARD_STATEMENTS_OPENING_PROPOSAL,
    },
    selection
  );

  console.log("check otherTexts before", openingProposalData);

  const otherTexts = [
    openingProposalData,
    //...auto_text_data,
    //   ...autoTextsRemaining,
    //   ...otherStatements,
  ]
    .filter((val) => val !== null)
    .sort((prev, next) => prev - next);

  console.log("check otherTexts", otherTexts);

  const proposalTextData = {
    isProposalCreated: false,
    fixedStandardStatements,
    otherTexts,
  };

  return proposalTextData;
};

const getProposalForQuoteScreen = (metricId, companyId, userId) => {
  return getquoteProposalData(
    {
      requestId: metricId,
      companyId,
      userId,
    },
    {
      quoteName: 1,
      markUp: 1,
      excludedSections: 1,
      excludedBuildings: 1,
      isProposalCreated: 1,
      pdf: 1,
      fixedStandardStatements: 1,
      otherTexts: 1,
      proposalsNotSent: 1,
      proposalsSent: 1,
      makeContract: 1,
    }
  );
};
