import React from 'react';
import './search-field.scss';
import SearchIcon from './search.svg';


export default function SearchField({sm = false,...props}) {
    return (
        <div className="search-field">
               <img src={SearchIcon} width={40} height={25} alt="search-icon"/>
               <input {...props} style={sm ? {padding:'0.5em 0.8em 0.5em 3em'} : {}}  disabled={props.paymentStatus == "Frozen 1" ? true : false}/>
        </div>
    )
}