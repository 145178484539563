import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Welcome from "./screens/Welcome";
import Login from "./screens/Login/Login";
import Signup from "./screens/Signup/Signup";
import ForgotPass from "./screens/ForgotPassword/ForgotPass";
import PublicRoutes from "./lib/routes/PublicRoutes";
import ProtectedRoutes from "./lib/routes/ProtectedRoutes";
import { LoadingLoader } from "components";
import { validateToken } from "lib/redux/user/user.actions";
import Completion from "components/Stripe/Completeion";
import Fail from "components/Stripe/Fail";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import usePushData from "services/offlineDataPush";
// import useFetchData from "services/newOfflineFetch";
import { getAllPermissions } from "lib/redux/permission/permission.actions";
import { useSelector } from "react-redux";
import DashboardPanel from "screens/dashboard-panel/dashboard-panel";

const AppRoute = () => {
  const [loading, setLoading] = useState(true);
  // const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.login);
  // const { isPushing, offlineDataPush } = usePushData();
  // const { fetchOfflineData } = useFetchData();
  //here cache for offline and synchronize
  //when start the app or reload

  // useEffect(() => {
  //   const eventOnline = async () => {
  //     console.log("run event online");
  //     await offlineDataPush();
  //     await fetchOfflineData();
  //   };
  //   addEventListener("online", eventOnline);
  //   console.log("check pushingDone", isPushing);
  //   if (isPushing) return;
  //   offlineDataPush().then(() => {
  //     fetchOfflineData();
  //   });

  //   return () => {
  //     removeEventListener("online", eventOnline);
  //   };
  // }, []);

  useEffect(() => {
   if (user) dispatch(getAllPermissions());
  }, [user]);

  // when go back online
  const disableLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    dispatch(validateToken(disableLoading));
  }, [dispatch]);

  if (loading) {
    return <LoadingLoader />;
  }

  // if (isPushing) {
  //   return (
  //     <LoadingLoader
  //       message={"synchronizing data, please do not exit or refresh"}
  //     />
  //   );
  // }
  return (
    <div>
      <ToastContainer />
      <Router basename="/">
        <Route path="/" exact component={Welcome} />
        <PublicRoutes path="/login" component={Login} />
        <PublicRoutes path="/signup" component={Signup} />
        {/* <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} /> */}
        {/* <Route path="/subscription-information" component={Signup} /> */}
        <ProtectedRoutes path="/dashboard" component={DashboardPanel} />
        <Route path="/forgotpassword/:email/:otp" component={ForgotPass} />
        <Route path="/completion" component={Completion} />
        <Route path="/fail" component={Fail} />
      </Router>
    </div>
  );
};

export default AppRoute;