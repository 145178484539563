import { UserActionTypes } from "./user.types";

const LOGIN_INITIAL_STATE = {
  isLogging: false,
  errors: null,
  user: null,
  companyId: null,
  otpSubmittedStatus: false,
  resendOtpButtonStatus: false
};

export function LoginReducer(state = LOGIN_INITIAL_STATE, action) {
  console.log({action, type: action.type});
  switch (action.type) {
    case UserActionTypes.LOGIN_START:
      return {
        ...state,
        isLogging: true,
        isAuthenticated: false,
        user: null,
      };

    case UserActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLogging: false,
        errors: null,
        user: action.payload,
      };

    case UserActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isLogging: false,
        errors: action.payload.error,
        user: null,
        companyId: action.payload?.companyId
      };


    case UserActionTypes.UPDATE_OTP_SUBMITTED_STATUS:
      return {
        ...state,
        otpSubmittedStatus: action.payload
      };


    case UserActionTypes.RESNED_OTP_BUTTON_CLICKED:
      return {
        ...state,
        resendOtpButtonStatus: action.payload
      };

    case UserActionTypes.SIGN_OUT:
      return {
        ...state,
        isLogging: false,
        errors: null,
        user: null,
      };

    default:
      return state;
  }
}

const GET_COMPANY_INITIAL_STATE = {
  isLoading: false,
  errors: null,
  company: null,
};
export function GetCompnayReducer(state = GET_COMPANY_INITIAL_STATE, action) {
  switch (action.type) {
    case UserActionTypes.GET_COMPANY_START:
      return {
        ...state,
        isLoading: true,
        company: null,
      };

    case UserActionTypes.GET_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: null,
        company: action.payload,
      };

    case UserActionTypes.GET_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.error,
        company: null,
      };

    default:
      return state;
  }
}


const INITIAL_STATE_USERS = {
  gettingUsers: false,
  userList: null,
  listErr: null,
};

export const GetUsersReducer = (state = INITIAL_STATE_USERS, action) => {
  switch (action.type) {
    case UserActionTypes.GET_USERS_START:
      return {
        ...state,
        gettingUsers: true,
        userList: null,
        listErr: null,
      };

    case UserActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        gettingUsers: false,
        userList: action.payload.data,
        listErr: null,
      };

    case UserActionTypes.GET_USERS_FAILURE:
      return {
        ...state,
        gettingUsers: false,
        userList: null,
        listErr: action.payload,
      };

    default:
      return state;
  }
};

/**
 * UserRole REducer
 */

const INITIAL_STATE = {
  gettingUsersRole: false,
  userRoleList: null,
  UserRoleslistErr: null,
};

export const GetUsersRoleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.GET_USERSROLES_START:
      return {
        ...state,
        gettingUsersRole: true,
        userRoleList: null,
        UserRoleslistErr: null,
      };

    case UserActionTypes.GET_USERSROLES_SUCCESS:
      return {
        ...state,
        gettingUsersRole: false,
        userRoleList: action.payload,
        UserRoleslistErr: null,
      };

    case UserActionTypes.GET_USERSROLES_FAILURE:
      return {
        ...state,
        gettingUsersRole: false,
        userRoleList: null,
        UserRoleslistErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_BASEPLAN = {
  gettingBasrPlan: false,
  baseList: null,
  BaselistErr: null,
};

export const GetBaseReducer = (state = INITIAL_STATE_BASEPLAN, action) => {
  switch (action.type) {
    case UserActionTypes.GET_BASE_START:
      return {
        ...state,
        gettingBasrPlan: true,
        baseList: null,
        BaselistErr: null,
      };

    case UserActionTypes.GET_BASE_SUCCESS:
      return {
        ...state,
        gettingBasrPlan: false,
        baseList: action.payload,
        BaselistErr: null,
      };

    case UserActionTypes.GET_BASE_FAILURE:
      return {
        ...state,
        gettingBasrPlan: false,
        baseList: null,
        BaselistErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_PLAN_BASEPLAN = {
  gettingBasePlan: false,
  basePlanList: null,
  BasePlanlistErr: null,
};

export const GetBaseReducerSignup = (
  state = INITIAL_STATE_PLAN_BASEPLAN,
  action
) => {
  switch (action.type) {
    case UserActionTypes.GET_BASE_PLAN_START:
      return {
        ...state,
        gettingBasePlan: true,
        basePlanList: null,
        BasePlanlistErr: null,
      };

    case UserActionTypes.GET_BASE_PLAN_SUCCESS:
      return {
        ...state,
        gettingBasePlan: false,
        basePlanList: action.payload,
        BasePlanlistErr: null,
      };

    case UserActionTypes.GET_BASE_PLAN_FAILURE:
      return {
        ...state,
        gettingBasePlan: false,
        basePlanList: null,
        BasePlanlistErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_BASE = {
  basePlanRole: false,
  basePlanList: null,
  BasePlanlistErr: null,
};

export const BasePlanReducer = (state = INITIAL_STATE_BASE, action) => {
  switch (action.type) {
    case UserActionTypes.BASE_START:
      return {
        ...state,
        basePlanRole: true,
        basePlanList: null,
        BasePlanlistErr: null,
      };

    case UserActionTypes.BASE_SUCCESS:
      return {
        ...state,
        basePlanRole: false,
        basePlanList: action.payload,
        BasePlanlistErr: null,
      };

    case UserActionTypes.BASE_FAILURE:
      return {
        ...state,
        basePlanRole: false,
        basePlanList: null,
        BasePlanlistErr: action.payload,
      };

    default:
      return state;
  }
};

/**
 * Reducer of getAllNotification
 */
const INITIAL_STATE_NOTIFICATION = {
  gettingNotification: false,
  notificationList: null,
  NotificationlistErr: null,
};

export const GetNotificationReducer = (
  state = INITIAL_STATE_NOTIFICATION,
  action
) => {
  switch (action.type) {
    case UserActionTypes.GET_NOTIFICATION_START:
      return {
        ...state,
        gettingNotification: true,
        notificationList: null,
        NotificationlistErr: null,
      };

    case UserActionTypes.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        gettingNotification: false,
        notificationList: action.payload,
        NotificationlistErr: null,
      };

    case UserActionTypes.GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        gettingNotification: false,
        notificationList: null,
        NotificationlistErr: action.payload,
      };

    default:
      return state;
  }
};

/**
 * Reducer of the notificationUpdate
 */
const INITIAL_STATE_NOTIFICATION_UPDATE = {
  notificationUpdate: false,
  notificationUpdateList: null,
  notificationUpdatelistErr: null,
};

export const notificationUpdateReducer = (
  state = INITIAL_STATE_NOTIFICATION_UPDATE,
  action
) => {
  switch (action.type) {
    case UserActionTypes.NOTIFICATION_START:
      return {
        ...state,
        notificationUpdate: true,
        notificationUpdateList: null,
        notificationUpdatelistErr: null,
      };

    case UserActionTypes.NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationUpdate: false,
        notificationUpdateList: action.payload,
        notificationUpdatelistErr: null,
      };

    case UserActionTypes.NOTIFICATION_FAILURE:
      return {
        ...state,
        notificationUpdate: false,
        notificationUpdateList: null,
        notificationUpdatelistErr: action.payload,
      };

    default:
      return state;
  }
};
