export const GET_QUOTE_START = "GET_QUOTE_START";
export const GET_QUOTE_SUCCESS = "GET_QUOTE_SUCCESS";
export const GET_QUOTE_FAILURE = "GET_QUOTE_FAILURE";
export const UPDATE_STAGE_SUCCESS = 'UPDATE_STAGE_SUCCESS'

export const QUOTE_MSUPPLIERS_START = "QUOTE_MSUPPLIERS_START";
export const QUOTE_MSUPPLIERS_SUCCESS = "QUOTE_MSUPPLIERS_SUCCESS";
export const QUOTE_MSUPPLIERS_FAILURE = "QUOTE_MSUPPLIERS_FAILURE";
export const QUOTE_SUBSCREWSUPPLIERS_START = "QUOTE_SUBSCREWSUPPLIERS_START";
export const QUOTE_SUBSCREWSUPPLIERS_SUCCESS =
  "QUOTE_SUBSCREWSUPPLIERS_SUCCESS";
export const QUOTE_SUBSCREWSUPPLIERS_FAILURE =
  "QUOTE_SUBSCREWSUPPLIERS_FAILURE";
export const QUOTE_CARTINGSUPPLIERS_START = "QUOTE_CARTINGSUPPLIERS_START";
export const QUOTE_CARTINGSUPPLIERS_SUCCESS = "QUOTE_CARTINGSUPPLIERS_SUCCESS";
export const QUOTE_CARTINGSUPPLIERS_FAILURE = "QUOTE_CARTINGSUPPLIERS_FAILURE";

export const QUOTE_MATERAILSECTION_DATA_START =
  "QUOTE_MATERAILSECTION_DATA_START";
export const QUOTE_MATERAILSECTION_DATA_SUCCESS =
  "QUOTE_MATERAILSECTION_DATA_SUCCESS";
export const QUOTE_MATERAILSECTION_DATA_FAILURE =
  "QUOTE_MATERAILSECTION_DATA_FAILURE";
export const QUOTE_MATERAILSECTION_DATA_EDIT =
  "QUOTE_MATERAILSECTION_DATA_EDIT";
export const QUOTE_MATERAILSECTION_DATA_UPDATETOTAL =
  "QUOTE_MATERAILSECTION_DATA_UPDATETOTAL";
export const LOCK_QUOTE_DATA_START = "LOCK_QUOTE_DATA_START";
export const LOCK_QUOTE_DATA_SUCCESS = "LOCK_QUOTE_DATA_SUCCESS";
export const LOCK_QUOTE_DATA_FAILURE = "LOCK_QUOTE_DATA_FAILURE";
export const QUOTE_LABORSECTION_DATA_START = "QUOTE_LABORSECTION_DATA_START";
export const QUOTE_LABORSECTION_DATA_SUCCESS =
  "QUOTE_LABORSECTION_DATA_SUCCESS";
export const QUOTE_LABORSECTION_DATA_FAILURE =
  "QUOTE_LABORSECTION_DATA_FAILURE";
export const QUOTE_LABORSECTION_DATA_EDIT = "QUOTE_LABORSECTION_DATA_EDIT";
export const QUOTE_LABORSECTION_DATA_UPDATETOTAL =
  "QUOTE_LABORSECTION_DATA_UPDATETOTAL";

export const QUOTE_DEBRISSECTION_DATA_START = "QUOTE_DEBRISSECTION_DATA_START";
export const QUOTE_DEBRISSECTION_DATA_SUCCESS =
  "QUOTE_DEBRISSECTION_DATA_SUCCESS";
export const QUOTE_DEBRISSECTION_DATA_FAILURE =
  "QUOTE_DEBRISSECTION_DATA_FAILURE";
export const QUOTE_DEBRISSECTION_DATA_EDIT = "QUOTE_DEBRISSECTION_DATA_EDIT";
export const QUOTE_DEBRISSECTION_DATA_UPDATETOTAL =
  "QUOTE_DEBRISSECTION_DATA_UPDATETOTAL";

export const QUOTE_GUTTERSSECTION_DATA_START =
  "QUOTE_GUTTERSSECTION_DATA_START";
export const QUOTE_GUTTERSSECTION_DATA_SUCCESS =
  "QUOTE_GUTTERSSECTION_DATA_SUCCESS";
export const QUOTE_GUTTERSSECTION_DATA_FAILURE =
  "QUOTE_GUTTERSSECTION_DATA_FAILURE";
export const QUOTE_GUTTERSSECTION_DATA_EDIT = "QUOTE_GUTTERSSECTION_DATA_EDIT";
export const QUOTE_GUTTERSSECTION_DATA_UPDATETOTAL =
  "QUOTE_GUTTERSSECTION_DATA_UPDATETOTAL";

export const QUOTE_PROPOSAL_DATA_START = "QUOTE_PROPOSAL_DATA_START";
export const QUOTE_PROPOSAL_DATA_SUCCESS = "QUOTE_PROPOSAL_DATA_SUCCESS";
export const QUOTE_PROPOSAL_DATA_FAILURE = "QUOTE_PROPOSAL_DATA_FAILURE";
export const QUOTE_PROPOSAL_DATA_EDIT = "QUOTE_PROPOSAL_DATA_EDIT";
export const QUOTE_PROPOSAL_DATA_ADD = "QUOTE_PROPOSAL_DATA_ADD";
export const QUOTE_PROPOSAL_DATA_DELETE = "QUOTE_PROPOSAL_DATA_DELETE";
export const QUOTE_PROPOSAL_DATA_EDIT_ALL = "QUOTE_PROPOSAL_DATA_EDIT_ALL";

export const QUOTE_PROPOSAL_CHANGE_ACTIVE = "QUOTE_PROPOSAL_CHANGE_ACTIVE";

export const QUOTE_PROPOSAL_MODAL_START = "QUOTE_PROPOSAL_MODAL_START";
export const QUOTE_PROPOSAL_MODAL_SUCCESS = "QUOTE_PROPOSAL_MODAL_SUCCESS";
export const QUOTE_PROPOSAL_MODAL_FAILURE = "QUOTE_PROPOSAL_MODAL_FAILURE";
export const QUOTE_PROPOSAL_MODAL_RESET = "QUOTE_PROPOSAL_MODAL_RESET";
export const QUOTE_PROPOSAL_TAB_DISPLAY = "QUOTE_PROPOSAL_TAB_DISPLAY";
export const QUOTE_PROPOSAL_TAB_MODAL_INDEX = "QUOTE_PROPOSAL_TAB_MODAL_INDEX";
export const QUOTE_PROPOSAL_MODAL_DISPLAY = "QUOTE_PROPOSAL_MODAL_DISPLAY";
