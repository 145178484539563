import baseSettings from "lib/config/baseSettings";
import { addUserLocal, deleteUserLocal, getUserLocal } from "lib/constants/localbase";

class AuthService {
  token = "auth_token";
  tempUser = "temp_user";

  rememberMe = "remember_me";

  saveRememberMe(value) {
    localStorage.setItem(this.rememberMe, JSON.stringify(value));
  }

  getRememberMe() {
    return JSON.parse(localStorage.getItem(this.rememberMe));
  }

  saveTempUser(tempUser) {
    return sessionStorage.setItem(this.tempUser, JSON.stringify(tempUser));
  }

  getTempUser() {
    return JSON.parse(sessionStorage.getItem(this.tempUser));
  }

  removeTempUser() {
    return sessionStorage.removeItem(this.tempUser);
  }

  async saveToken(userData, rememberMe) {
    this.saveRememberMe(rememberMe);
    //save email for uid
    localStorage.setItem("user_email", userData.userData.email);

    if (!rememberMe) {
      this.saveTempUser(userData);
      return;
    }
    return addUserLocal(userData);
  }

  getToken() {
    const hasRememberMeChecked = this.getRememberMe();

    if (hasRememberMeChecked === null) return null;

    if (!hasRememberMeChecked) {
      return this.getTempUser().token;
    }

    return getUserLocal()
      .then((user) => {
        if (user) {
          return user.token;
        } else return null;
      })
      .catch(() => null);
  }

  clearUserStorage() {
    const CACHE_NAME = "roofer-organized-cache";
    //change here then remember to change in serviceWorker
    localStorage.removeItem("user_email");
    caches.open(CACHE_NAME).then((cache) => {
      cache
        .delete(`${baseSettings.baseUrl}admin/permission/getAllPermission`)
        .then((res) => {
          console.log("cache cleared", res);
        })
        .catch((err) => {
          console.log("cache error", err);
        });
    });
  }

  invalidateUser() {
    const hasRememberMeChecked = this.getRememberMe();

    this.clearUserStorage();
    if (!hasRememberMeChecked) {
      return this.removeTempUser();
    }
    deleteUserLocal();
  }

  isValid(token) {
    if (token) return true;
    return false;
  }

  isAuthenticated() {
    const token = this.getToken();

    return token && this.isValid(token) ? true : false;
  }
}

export default new AuthService();
