import React, { useEffect, useState } from "react";
import {
  activityrepair,
  activitysales,
  admindropdowns,
  adminproposal,
  adminsubscriptionusers,
  caldaylost,
  calendarrepair,
  calendarsales,
  dashboardnewLead,
  fullreadwrite,
  materiallabor,
  metric,
  noacces,
  onlyself,
  projectmanagementScreen,
  quotesproposals,
  viewcontactinfo,
  viewonly,
} from "lib/constants/const";

export default function userPermission(permission) {
  const [permissions, setPermissions] = useState({
    hasAccess: false,
    hasAccessForSale: false,
    hasAccessForRepairs: false,
    hasAccessForMaterial: false,
    hasAccessForProposal: false,
    hasAccessForDashboard: false,
    hasAccessForRepairsCalender: false,
    hasAccessForSaleCalender: false,
    hasAccesSubscription: false,
    hasAccessForDropDown: false,
    calenderDayLostForDropDown: false,
    hasAccessForPM: false,
    proposalPointerEvent: false,
    dropdownPointerEvent: false,
    materialPointerEvent: false,
    qProposalPointerEvent: false,
    matrixPointerEvent: false,
    salesPointerEvent: false,
    repairPointerEvent: false,
    activitySalesPointerEvent: false,
    activityRepairPointerEvent: false,
    newleadPointerEvent: false,
    materialPointerEventViewOnly: false,
  });

  useEffect(() => {
    if (!permission) return;

    const findPermission = (screenName) =>
      permission.find((perm) => perm.screen.screenNameModified === screenName)
        ?.permission[0]?.permissionNameModified;

    setPermissions({
      hasAccess: findPermission(dashboardnewLead) !== noacces,
      hasAccessForSale: findPermission(activitysales) !== noacces,
      hasAccessForRepairs: findPermission(activityrepair) !== noacces,
      hasAccessForMaterial: findPermission(materiallabor) !== noacces,
      hasAccessForProposal: findPermission(adminproposal) !== noacces,
      hasAccessForDashboard: findPermission(dashboardnewLead) !== noacces,
      hasAccessForRepairsCalender:
        findPermission(calendarrepair) !== noacces,
      hasAccessForSaleCalender: findPermission(calendarsales) !== noacces,
      hasAccesSubscription:
        findPermission(adminsubscriptionusers) === fullreadwrite,
      hasAccessForDropDown: findPermission(admindropdowns) !== noacces,
      calenderDayLostForDropDown: findPermission(admindropdowns) === caldaylost,
      hasAccessForPM:
        findPermission(projectmanagementScreen) !== noacces,
      proposalPointerEvent: findPermission(adminproposal) !== viewonly,
      dropdownPointerEvent: findPermission(admindropdowns) !== viewonly,
      materialPointerEvent: findPermission(materiallabor) !== viewcontactinfo,
      materialPointerEventViewOnly:
        findPermission(materiallabor) !== viewonly,
      qProposalPointerEvent: findPermission(quotesproposals) !== viewonly,
      matrixPointerEvent: findPermission(metric) !== viewonly,
      salesPointerEvent: findPermission(calendarsales) !== viewonly,
      repairPointerEvent: findPermission(calendarrepair) !== viewonly,
      activitySalesPointerEvent:
        findPermission(activitysales) !== viewonly,
      activityRepairPointerEvent:
        findPermission(activityrepair) !== viewonly,
      newleadPointerEvent: findPermission(dashboardnewLead) !== viewonly,
    });
  }, [permission]);

  return permissions;
}
