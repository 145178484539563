import React, { useState, useEffect } from "react";
import "./Suppliers.scss";
import RequiredText from "components/required-text/required-text";

import { uuid } from "uuidv4";
import Select from "react-select";

import { Row, Col, Button, Form } from "react-bootstrap";

import {
  getSubcontractorSuppliers,
  getCrewSuppliers,
  updateCrewSuppliers,
  addCrewSuppliers,
  deleteCrewSuppliers,
  toggleIsPreferred,
  resetPricingLabor,
  getPricingCrew,
  resetPricingCrew,
  patchCrewSuppliers,
  addCrewPricingCustom,
  deleteCrewPricingCustom,
} from "lib/redux/suppliers/suppliers.action";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import displayMsg from "components/displayMsg";
import { LoadingLoader } from "components";
import DeleteModal from "../../components/DeleteModal";
import { EmptyData } from "./commonComponents";
import { PriceListLabor } from "./Labor";
import { PriceListCarting } from "./Carting";
import { MiscPriceListLabor } from "./Labor";

export function CrewPricingList({ selectedSupp }) {
  const { data, errors, isLoading } = useSelector((state) => state.staticAll);
  const { gettingCrewMaterial, crewMaterialData, crewMaterialErr } =
    useSelector((state) => state.crewPricing);
  const dispatch = useDispatch();
  const [pricing, setPricing] = useState([]);
  const [category, setCategory] = useState("");
  const [addItem, setAddItem] = useState(false);
  const [isMisc, setIsMisc] = useState(false);
  const [type, setType] = useState("");
  const [subType, setSubType] = useState("");
  const [uomOptions, setUomOptions] = useState([]);

  const [matchData, setMatchData] = useState(null);
  const [matchType, setMatchType] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [typesList, setTypesList] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [subName, setSubName] = useState("");
  const [roofPitchTypes, setRoofPitchTypes] = useState([]);
  const [roofPitch, setRoofPitch] = useState("");
  const [styleTypes, setStyleTypes] = useState([]);
  const [style, setStyle] = useState("");

  const [customItem, setCustomItem] = useState(false);
  const [customItemDefaultValue, setCustomItemDefaultValue] = useState(null);

  useEffect(() => {
    if (data) {
      setPricing(data.laborPricingOptions);
    }
  }, [data]);

  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);

  useEffect(() => {
    setAddItem(false);
    setSubTypes([]);
    setSubName("");
    setType("");
    setSubType("");
    setMatchData(null);
    setMatchType(null);
    setStyleTypes([]);
    setStyle("");
    setRoofPitchTypes([]);
    setRoofPitch("");
    setCategory("");
    setCustomItem(false);
    dispatch(resetPricingLabor());
  }, [selectedSupp]);

  useEffect(() => {
    setAddItem(false);
    const list = [];
    if (pricing.length > 0) {
      pricing.map((priceData) => list.push(priceData.category));
    }
    setCategoryList(list);
  }, [pricing]);

  useEffect(() => {
    setAddItem(false);
    setSubTypes([]);
    setSubName("");
    setType("");
    setSubType("");
    setMatchData(null);
    setMatchType(null);
    setStyleTypes([]);
    setStyle("");
    setRoofPitchTypes([]);
    setRoofPitch("");
    dispatch(resetPricingLabor());

    if (category) {
      const list = [];
      const match = pricing.find(
        (priceData) => priceData.category === category
      );
      setMatchData(match);
      setIsMisc(match.isMisc);
      if (match.customItem) {
        setCustomItem(match.customItem);
      }
      match.categoryType.map((keys) => list.push(keys.typeName));
      setTypesList(list);
      if (list.length === 1) {
        setType(list[0]);
      }
    } else {
      setMatchData(null);
      setIsMisc(false);
      setTypesList([]);
    }
  }, [category]);

  useEffect(() => {
    setSubTypes([]);
    setSubName("");
    setStyleTypes([]);
    setStyle("");
    setRoofPitchTypes([]);
    setRoofPitch("");
    setSubType("");
    setMatchType(null);
    setAddItem(false);
    dispatch(resetPricingCrew());

    if (type) {
      const match = matchData.categoryType.find(
        (types) => types.typeName === type
      );
      setMatchType(match);
      setCustomItem(match.customItem);
      setCustomItemDefaultValue(match.customItemDefaultValue);
      if (match.uomOptions && match.uomOptions.length > 0) {
        setUomOptions(match.uomOptions);
      }
      if (match.subTypeName) {
        setSubName(match.subTypeName);
        setSubTypes(match.subType);
      } else {
        dispatch(
          getPricingCrew({
            category,
            categoryType: type,
          })
        );
      }
    }
  }, [type]);

  useEffect(() => {
    setStyleTypes([]);
    setStyle("");
    setRoofPitchTypes([]);
    setRoofPitch("");
    dispatch(resetPricingLabor());
    if (subType) {
      if (matchType.roofPitch && matchType.roofPitch.length > 0) {
        setRoofPitchTypes(matchType.roofPitch);
      } else if (matchType.style && matchType.style.length > 0) {
        setStyleTypes(matchType.style);
      } else if (
        matchType.style.length === 0 &&
        matchType.roofPitch.length === 0
      ) {
        dispatch(
          getPricingCrew({
            category,
            categoryType: type,
            subType,
          })
        );
      }
    }
  }, [subType]);

  useEffect(() => {
    dispatch(resetPricingLabor());
    setAddItem(false);
    if (style) {
      if (roofPitchTypes.length === 0) {
        dispatch(
          getPricingCrew({
            category,
            categoryType: type,
            subType,
            style,
          })
        );
      }
    }
  }, [style]);

  useEffect(() => {
    dispatch(resetPricingLabor());
    setAddItem(false);
    if (roofPitch) {
      if (styleTypes.length === 0) {
        dispatch(
          getPricingCrew({
            category,
            categoryType: type,
            subType,
            roofPitch,
          })
        );
      }
    }
  }, [roofPitch]);

  useEffect(() => {
    if (isMisc) {
      dispatch(
        getPricingCrew({
          category,
        })
      );
    }
  }, [isMisc]);

  const showOptions = (list) =>
    list.map((data) => (
      <option style={{ textTransform: "capitalize" }} value={data} key={data}>
        {data}
      </option>
    ));

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const checkMiscData = () => {
    if (isMisc) {
      return (
        <>
          <Row>
            <Col>
              <div className="_tab_msg">
                <p className="__msg">
                  This is where you enter those various unforseen items not
                  found in the labor categories. Click 'Add new Item' above.
                </p>
              </div>
            </Col>
          </Row>
          <MiscPriceListLabor
            customItemDefaultValue={customItemDefaultValue}
            data={crewMaterialData.crewPricing}
            addItem={addItem}
            setAddItem={setAddItem}
            newData={{
              category,
            }}
            uomOptions={uomOptions}
            suppliersId={selectedSupp}
            deletePricing={deleteCrewPricingCustom}
            patchPricing={patchCrewSuppliers}
            addPricing={addCrewPricingCustom}
          />
        </>
      );
    }
    if (!isMisc) {
      if (crewMaterialData.crewPricing.length === 0) {
        return <EmptyData />;
      }
      return (
        <PriceListLabor
          customItemDefaultValue={customItemDefaultValue}
          data={crewMaterialData.crewPricing}
          addItem={addItem}
          setAddItem={setAddItem}
          newData={{
            category,
            categoryType: type || "N/A",
            subType: subType || "N/A",
            style: style || "N/A",
            roofPitch: roofPitch || "N/A",
          }}
          subName={subName}
          uomOptions={uomOptions}
          suppliersId={selectedSupp}
          deletePricing={deleteCrewPricingCustom}
          patchPricing={patchCrewSuppliers}
          addPricing={addCrewPricingCustom}
        />
      );
    }
  };

  const getList = () => {
    if (gettingCrewMaterial) {
      return <LoadingLoader />;
    }
    if (crewMaterialErr) {
      return (
        <div className="_tab_msg">
          <p className="__msg">{crewMaterialErr}</p>
        </div>
      );
    }

    if (crewMaterialData && crewMaterialData.crewPricing) {
      return <>{checkMiscData()}</>;
    }
  };
  if (isLoading) {
    return <LoadingLoader />;
  }

  return (
    <div style={{ padding: "1em" }}>
      <Row
        style={{
          justifyContent: "space-between",
          paddingBottom: "2em",
          paddingTop: "1em",
          display: "flex",
        }}
      >
        <Row>
          <Col md="3">
            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="pos"
                value={category}
                onChange={(event) => handleChange(event, setCategory)}
              >
                <option value="">Choose...</option>
                {showOptions(categoryList)}
              </Form.Control>
            </Form.Group>
          </Col>
          {category && typesList.length > 0 && typesList.length !== 1 && (
            <Col md="3">
              <Form.Group>
                <Form.Label>{category} Type</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={type}
                  onChange={(event) => handleChange(event, setType)}
                >
                  <option value="">Choose...</option>
                  {showOptions(typesList)}
                </Form.Control>
              </Form.Group>
            </Col>
          )}

          {subName && subTypes && subTypes.length > 0 && (
            <Col md="3">
              <Form.Group>
                <Form.Label>{subName}</Form.Label>
                <Form.Control
                  as="select"
                  name="subType"
                  value={subType}
                  onChange={(event) => handleChange(event, setSubType)}
                >
                  <option value="">Choose...</option>
                  {showOptions(subTypes)}
                </Form.Control>
              </Form.Group>
            </Col>
          )}

          {roofPitchTypes.length > 0 && (
            <Col md="3">
              <Form.Group>
                <Form.Label>Roof Pitch</Form.Label>
                <Form.Control
                  as="select"
                  name="roofPitch"
                  value={roofPitch}
                  onChange={(event) => handleChange(event, setRoofPitch)}
                >
                  <option value="">Choose...</option>
                  {showOptions(roofPitchTypes)}
                </Form.Control>
              </Form.Group>
            </Col>
          )}
          {styleTypes.length > 0 && (
            <Col md="3">
              <Form.Group>
                <Form.Label>Style</Form.Label>
                <Form.Control
                  as="select"
                  name="style"
                  value={style}
                  onChange={(event) => handleChange(event, setStyle)}
                >
                  <option value="">Choose...</option>
                  {showOptions(styleTypes)}
                </Form.Control>
              </Form.Group>
            </Col>
          )}
          {customItem && (
            <Col>
              <div style={{ float: "right" }}>
                <Button disabled={addItem} onClick={() => setAddItem(true)}>
                  Add New Item
                </Button>
              </div>
            </Col>
          )}
        </Row>

        {/* {customItem && (
          <Row>
            <Col>
              <div style={{ float: "right" }}>
                <Button disabled={addItem} onClick={() => setAddItem(true)}>
                  Add New Item
                </Button>
              </div>
            </Col>
          </Row>
        )} */}
      </Row>
      {getList()}
    </div>
  );
}

export default function Crew({
  crews,
  setCrews,
  selectedSupp,
  setSelectedSupp,
  addNew,
  activeTab,
  permission,
}) {
  const [_id, setId] = useState(uuid());
  const [isActive, setIsActive] = useState(true);
  const [isPreferred, setIsPreferred] = useState(false);

  const [name, setName] = useState("");
  const [services, setServices] = useState([]);
  const [SERVICES, setSERVICES] = useState([]);

  const [processing, setProcessing] = useState(false);
  const [edit, setEdit] = useState(false);

  const dispatch = useDispatch();
  const { data, errors, isLoading } = useSelector((state) => state.staticAll);
  const [dltModal, setDltModal] = useState(false);

  const hideDeleteModal = () => setDltModal(false);
  const [prevData, setPrevData] = useState({});

  useEffect(() => {
    if (data) {
      setSERVICES(data.services);
    }
  }, [data, activeTab]);

  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);

  const getObjs = (servicesData) => {
    if (servicesData.length > 0 && data.services.length > 0) {
      const res = [];
      servicesData.map((item) =>
        res.push(data.services.find((service) => service.value === item))
      );
      return res;
    }
    return [];
  };

  const getValues = (data) => {
    if (data.length > 0) {
      const res = [];
      data.map((item) => res.push(item.value));
      return res;
    }
    return [];
  };

  const hasNotChangedFields = () =>
    JSON.stringify(prevData) ===
    JSON.stringify({
      _id,
      isActive,
      isPreferred,
      name,
      services: getValues(services),
    });

  const resetFields = () => {
    setId(uuid());
    setIsActive(true);
    setIsPreferred(false);
    setName("");
    setServices([]);
  };

  useEffect(() => {
    if (selectedSupp) {
      const data = crews.find((crew) => crew._id === selectedSupp);
      if (data) {
        setId(data._id);
        setIsActive(data.isActive);
        setIsPreferred(data.isPreferred);
        setName(data.name);
        setServices(getObjs(data.services));

        setPrevData({
          _id: data._id,
          isActive: data.isActive,
          isPreferred: data.isPreferred,
          name: data.name,
          services: data.services,
        });
      }
    } else {
      resetFields();
    }
  }, [crews, selectedSupp, activeTab]);

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const addCrews = () => {
    if (!selectedSupp) {
      setProcessing(true);
      return dispatch(
        addCrewSuppliers({
          isActive,
          name,
          services: getValues(services),
        })
      )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            const newData = res.result;
            dispatch(
              toggleIsPreferred(1, {
                supplierId: newData._id,
                isPreferred,
                isSubs: false,
              })
            )
              .then((resData) => resData.data)
              .then((resData) => {
                if (resData) {
                  setCrews([...crews, { ...newData, isPreferred }]);
                  setSelectedSupp(newData._id);
                  toast.success("Crew added successfully.");
                  dispatch(getCrewSuppliers());
                } else {
                  toast.error(res.error);
                }
              })
              .catch((err) => {
                toast.error(err);
              })
              .finally(() => setProcessing(false));
          } else {
            toast.error(res.error);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => setProcessing(false));
    }

    if (selectedSupp === _id) {
      const idx = crews.findIndex((crew) => crew._id === _id);

      setProcessing(true);
      return dispatch(
        updateCrewSuppliers(_id, {
          isActive,
          name,
          services: getValues(services),
        })
      )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            dispatch(
              toggleIsPreferred(1, {
                supplierId: _id,
                isPreferred,
                isSubs: false,
              })
            )
              .then((resData) => resData.data)
              .then((resData) => {
                if (resData) {
                  crews.splice(idx, 1, {
                    _id,
                    isActive,
                    name,
                    isPreferred,
                    services: getValues(services),
                  });
                  setCrews([...crews]);
                  toast.success("Crew updated successfully.");
                  dispatch(getCrewSuppliers());
                  dispatch(getSubcontractorSuppliers());
                } else {
                  toast.error(res.error);
                }
              })
              .catch((err) => {
                toast.error(err);
              })
              .finally(() => setProcessing(false));
          } else {
            toast.error(res.error);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => setProcessing(false));
    }
  };
  const handleMultiSelect = (value) => {
    if (!value) return setServices([]);
    setServices(value);
  };

  const addInternalCrew = () => {
    setSelectedSupp("");
    resetFields();
  };

  const dltData = () => {
    const idx = crews.findIndex((supp) => supp._id === _id);
    setProcessing(true);
    dispatch(deleteCrewSuppliers(_id))
      .then((res) => res.data)
      .then((res) => {
        if (res.success) {
          hideDeleteModal();
          crews.splice(idx, 1);
          setCrews([...crews]);
          toast.success("Crew deleted successfully.");
          addInternalCrew();
        } else {
          toast.error(res.error);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setProcessing(false));
  };

  return (
    <div
      style={
        permission
          ? { padding: "1em" }
          : { pointerEvents: "none", padding: "1em" }
      }
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Form.Group>
            <Form.Check
              name="isActive"
              defaultChecked={isActive}
              checked={isActive}
              onChange={(event) => setIsActive(event.target.checked)}
              label="Active"
            />
          </Form.Group>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              marginRight: "1em",
            }}
          >
            <RequiredText>*</RequiredText> <span>Required Fields</span>
          </div>
          <div>
            <Button disabled={!selectedSupp} onClick={() => addInternalCrew()}>
              Add Crew
            </Button>
          </div>
        </div>
      </div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Check
              name="isPrefered"
              disabled={!isActive}
              checked={isPreferred}
              onChange={(event) => setIsPreferred(event.target.checked)}
              label="Preferred Subcontractor/Crew"
            />
          </Form.Group>
        </Col>
      </Row>

      
      <Row className="mt-3">
        <Col md="6" lg="4">
          <Form.Group>
            <Form.Label>
              In-house Crew Name: <RequiredText>*</RequiredText>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              disabled={!isActive}
              value={name}
              onChange={(event) => handleChange(event, setName)}
            />
          </Form.Group>
        </Col>
        <Col md="6" lg="4">
          <Form.Group>
            <Form.Label>
              Services: <RequiredText>*</RequiredText>
            </Form.Label>
            <Select
              className={!isActive ? "_multiselect_disabled" : ""}
              onChange={!isActive ? {} : handleMultiSelect}
              options={SERVICES}
              value={services}
              isMulti
              name="services"
              styles={!isActive ? { background: "#e9ecef" } : {}}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row style={{ justifyContent: "flex-end", margin: "1em 0" }}>
        <div style={{ margin: "0 1em" }}>
          <Button
            disabled={
              processing || !isActive || hasNotChangedFields() || !permission
            }
            variant="danger"
            style={{ marginRight: "1em" }}
            onClick={() => resetFields()}
          >
            Cancel
          </Button>

          <Button
            disabled={
              !name ||
              services.length === 0 ||
              processing ||
              hasNotChangedFields()
            }
            onClick={() => addCrews()}
          >
            {/* Save */}
          </Button>
        </div>
      </Row>
      <DeleteModal
        showDeleteModal={dltModal}
        deleteData={dltData}
        hideDeleteModal={hideDeleteModal}
        processing={processing}
      />
    </div>
  );
}
