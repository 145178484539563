export const TABS_SUPPLIERS = [
  {
    name: 'Material',
    id: 'material',
    id2: 'materialinfo',
    id3: 'materiallist',
  },
  {
    name: 'Subs/Crews',
    id: 'subcontractor',
    id2: 'crew',
    id3: 'subs/crews',
  },
  {
    name: 'Carting',
    id: 'carting',
    id3: 'cartinglist',
  },
];

export const MAIN_TABS = [
  {
    name: 'Supplier Info',
    id: '001',
  },
  {
    name: 'Pricing List',
    id: '002',
  },
];

export const MODAL_OPTIONS = [
  {
    name: 'Material Suppliers',
    id: TABS_SUPPLIERS[0].id,
  },
  {
    name: 'Sub/Crew',
    id: TABS_SUPPLIERS[1].id,
  },
  {
    name: 'Carting Suppliers',
    id: TABS_SUPPLIERS[2].id,
  },
];

export const SUBS_CREWS_OPTIONS = [
  {
    name: 'Subcontractor',
    id: TABS_SUPPLIERS[1].id,
  },
  {
    name: 'In-house Crew',
    id: TABS_SUPPLIERS[1].id2,
  },
];

export const POS_CARTING = [
  {
    name: 'Owner',
    id: '001',
  },
  {
    name: 'General Manager',
    id: '002',
  },
  {
    name: 'Assistant Manager',
    id: '003',
  },
  {
    name: 'Dispatcher',
    id: '006',
  },
  {
    name: 'Receptionist',
    id: '007',
  },
];

export const TYPES = [
  { value: '001', label: 'Trucks' },
  { value: '002', label: 'Dumpsters' },
];
