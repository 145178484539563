import React from "react";

import {
  Row,
  Col
} from "react-bootstrap";
export const SideContactList = (props) => {
  const {
    contact,
    selectedContact,
    selectedLeadId,
    addNewSvc,
    getContactDetails,
  } = props;

  const {
    companyName,
    primaryFirstName,
    primaryLastName,
    primaryAddress1,
    primaryAddress2,
    primaryCity,
    primaryState,
    primaryZip,
  } = contact.primary_data[0];

  return (
    <div
      className="card"
      style={
        selectedContact !== undefined && selectedContact._id === contact?._id
          ? {
              backgroundColor: "#fff",
              padding: "3px",
              borderColor: "rgb(87, 165, 201)",
              borderWidth: "0.3rem",
            }
          : { backgroundColor: "#fff", padding: "3px" }
      }
    >
      <li draggable>
        <Row>
          <Col className="ms-1 mt-1">
            <h5>{companyName && companyName}</h5>

            {companyName ? (
              <h6>{primaryFirstName + " " + primaryLastName}</h6>
            ) : (
              <h5>{primaryFirstName + " " + primaryLastName}</h5>
            )}

            <span className="text-muted">
              {primaryAddress1}, {primaryAddress2},{primaryCity}, {primaryState}
              , {primaryZip}
            </span>
            <p style={{ color: "rgb(0, 123, 255)" }}>
              Property Count-
              {selectedContact !== undefined &&
              selectedContact._id === contact?._id
                ? selectedContact.property_list.length
                : contact.property_list.length}
            </p>
          </Col>
          {/* <Col sm="2" md="2">
                  <div className="arrow">
                    <img src={Arrow} height={20} width={20} alt="arrow" />
                  </div>
                </Col> */}
        </Row>
      </li>
    </div>
  );
};
