import { createSelector } from 'reselect'


const selectStageData = ({getStage}) =>  getStage.data

export const stageData = createSelector(
    [selectStageData],
    (data) => data ? data.stageData : null
)

export const count = createSelector(
    [selectStageData],
    (data) => data ? data.count : []
)