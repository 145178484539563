import { SuppliersActionTypes } from "./suppliers.action.types";

const INITIAL_STATE = {
  gettingMSuppliers: false,
  mSuppliersData: null,
  mSuppliersErr: null,
};

export const GetMSuppliersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SuppliersActionTypes.GET_MSUPPLIERS_START:
      return {
        ...state,
        gettingMSuppliers: true,
        mSuppliersData: null,
        mSuppliersErr: null,
      };

    case SuppliersActionTypes.GET_MSUPPLIERS_SUCCESS:
      return {
        ...state,
        gettingMSuppliers: false,
        mSuppliersData: action.payload,
        mSuppliersErr: null,
      };

    case SuppliersActionTypes.GET_MSUPPLIERS_FAILURE:
      return {
        ...state,
        gettingMSuppliers: false,
        mSuppliersData: null,
        mSuppliersErr: action.payload,
      };

    default:
      return state;
  }
};

/**
 *
 */
const INITIAL_STATE_OPTION = {
  gettingMSuppliersoPTIONS: false,
  mSuppliersOptionsData: null,
  mSuppliersOptionsErr: null,
};

export const GetMSupplierOptionsReducer = (
  state = INITIAL_STATE_OPTION,
  action
) => {
  switch (action.type) {
    case SuppliersActionTypes.GET_MSUPPLIERSOPTIONS_START:
      return {
        ...state,
        gettingMSuppliersoPTIONS: true,
        mSuppliersOptionsData: null,
        mSuppliersOptionsErr: null,
      };

    case SuppliersActionTypes.GET_MSUPPLIERSOPTIONS_SUCCESS:
      return {
        ...state,
        gettingMSuppliersoPTIONS: false,
        mSuppliersOptionsData: action.payload,
        mSuppliersOptionsErr: null,
      };

    case SuppliersActionTypes.GET_MSUPPLIERSOPTIONS_FAILURE:
      return {
        ...state,
        gettingMSuppliersoPTIONS: false,
        mSuppliersOptionsData: null,
        mSuppliersOptionsErr: action.payload,
      };

    default:
      return state;
  }
};
/**
 *
 */
const INITIAL_STATE_PRICING = {
  gettingPMaterial: false,
  pMaterialData: null,
  pMaterialErr: null,
};

export const PricingMaterialReducer = (
  state = INITIAL_STATE_PRICING,
  action
) => {
  switch (action.type) {
    case SuppliersActionTypes.PRICING_MATERIAL_START:
      return {
        ...state,
        gettingPMaterial: true,
        pMaterialData: null,
        pMaterialErr: null,
      };

    case SuppliersActionTypes.PRICING_MATERIAL_SUCCESS:
      return {
        ...state,
        gettingPMaterial: false,
        pMaterialData: action.payload,
        pMaterialErr: null,
      };

    case SuppliersActionTypes.PRICING_MATERIAL_FAILURE:
      return {
        ...state,
        gettingPMaterial: false,
        pMaterialData: null,
        pMaterialErr: action.payload,
      };

    case SuppliersActionTypes.PRICING_MATERIAL_RESET:
      return {
        ...state,
        gettingPMaterial: false,
        pMaterialData: null,
        pMaterialErr: null,
      };

    default:
      return state;
  }
};
/**
 * Spplier for preferd materials
 */
const INITIAL_STATE_PREFERD = {
  gettingPreMaterial: false,
  preMaterialData: null,
  preMaterialErr: null,
};

export const PreferdMaterialReducer = (
  state = INITIAL_STATE_PREFERD,
  action
) => {
  switch (action.type) {
    case SuppliersActionTypes.PREFERD_MATERIAL_START:
      return {
        ...state,
        gettingPreMaterial: true,
        preMaterialData: null,
        preMaterialErr: null,
      };

    case SuppliersActionTypes.PREFERD_MATERIAL_SUCCESS:
      return {
        ...state,
        gettingPreMaterial: false,
        preMaterialData: action.payload,
        preMaterialErr: null,
      };

    case SuppliersActionTypes.PREFERD_MATERIAL_FAILURE:
      return {
        ...state,
        gettingPreMaterial: false,
        preMaterialData: null,
        preMaterialErr: action.payload,
      };

    case SuppliersActionTypes.PREFERD_MATERIAL_RESET:
      return {
        ...state,
        gettingPreMaterial: false,
        preMaterialData: null,
        preMaterialErr: null,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_SUBS = {
  gettingSubsSuppliers: false,
  subsSuppliersData: null,
  subsSuppliersErr: null,
};

export const GetSubsSuppliersReducer = (state = INITIAL_STATE_SUBS, action) => {
  switch (action.type) {
    case SuppliersActionTypes.GET_SUBCONTRACTORSUPPLIERS_START:
      return {
        ...state,
        gettingSubsSuppliers: true,
        subsSuppliersData: null,
        subsSuppliersErr: null,
      };

    case SuppliersActionTypes.GET_SUBCONTRACTORSUPPLIERS_SUCCESS:
      return {
        ...state,
        gettingSubsSuppliers: false,
        subsSuppliersData: action.payload,
        subsSuppliersErr: null,
      };

    case SuppliersActionTypes.GET_SUBCONTRACTORSUPPLIERS_FAILURE:
      return {
        ...state,
        gettingSubsSuppliers: false,
        subsSuppliersData: null,
        subsSuppliersErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_CREW = {
  gettingCrewSuppliers: false,
  crewSuppliersData: null,
  crewSuppliersErr: null,
};

export const GetCrewSuppliersReducer = (state = INITIAL_STATE_CREW, action) => {
  switch (action.type) {
    case SuppliersActionTypes.GET_CREWSUPPLIERS_START:
      return {
        ...state,
        gettingCrewSuppliers: true,
        crewSuppliersData: null,
        crewSuppliersErr: null,
      };

    case SuppliersActionTypes.GET_CREWSUPPLIERS_SUCCESS:
      return {
        ...state,
        gettingCrewSuppliers: false,
        crewSuppliersData: action.payload,
        crewSuppliersErr: null,
      };

    case SuppliersActionTypes.GET_CREWSUPPLIERS_FAILURE:
      return {
        ...state,
        gettingCrewSuppliers: false,
        crewSuppliersData: null,
        crewSuppliersErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_CARTING = {
  gettingCartingSuppliers: false,
  cartingSuppliersData: null,
  cartingSuppliersErr: null,
};

export const GetCartingSuppliersReducer = (
  state = INITIAL_STATE_CARTING,
  action
) => {
  switch (action.type) {
    case SuppliersActionTypes.GET_CARTINGSUPPLIERS_START:
      return {
        ...state,
        gettingCartingSuppliers: true,
        cartingSuppliersData: null,
        cartingSuppliersErr: null,
      };

    case SuppliersActionTypes.GET_CARTINGSUPPLIERS_SUCCESS:
      return {
        ...state,
        gettingCartingSuppliers: false,
        cartingSuppliersData: action.payload,
        cartingSuppliersErr: null,
      };

    case SuppliersActionTypes.GET_CREWSUPPLIERS_FAILURE:
      return {
        ...state,
        gettingCartingSuppliers: false,
        cartingSuppliersData: null,
        cartingSuppliersErr: action.payload,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_PRICING_LABOR = {
  gettingPLabor: false,
  pLaborData: null,
  pLaborErr: null,
};

export const PricingLaborReducer = (
  state = INITIAL_STATE_PRICING_LABOR,
  action
) => {
  switch (action.type) {
    case SuppliersActionTypes.PRICING_LABOR_START:
      return {
        ...state,
        gettingPLabor: true,
        pLaborData: null,
        pLaborErr: null,
      };

    case SuppliersActionTypes.PRICING_LABOR_SUCCESS:
      return {
        ...state,
        gettingPLabor: false,
        pLaborData: action.payload,
        pLaborErr: null,
      };

    case SuppliersActionTypes.PRICING_LABOR_FAILURE:
      return {
        ...state,
        gettingPLabor: false,
        pLaborData: null,
        pLaborErr: action.payload,
      };

    case SuppliersActionTypes.PRICING_LABOR_RESET:
      return {
        ...state,
        gettingPLabor: false,
        pLaborData: null,
        pLaborErr: null,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_PRICING_CARTING = {
  gettingCartMaterial: false,
  cartMaterialData: null,
  cartMaterialErr: null,
};

export const PricingCartingReducer = (
  state = INITIAL_STATE_PRICING_CARTING,
  action
) => {
  switch (action.type) {
    case SuppliersActionTypes.PRICING_CARTING_START:
      return {
        ...state,
        gettingCartMaterial: true,
        cartMaterialData: null,
        cartMaterialErr: null,
      };

    case SuppliersActionTypes.PRICING_CARTING_SUCCESS:
      return {
        ...state,
        gettingCartMaterial: false,
        cartMaterialData: action.payload,
        cartMaterialErr: null,
      };

    case SuppliersActionTypes.PRICING_CARTING_FAILURE:
      return {
        ...state,
        gettingCartMaterial: false,
        cartMaterialData: null,
        cartMaterialErr: action.payload,
      };

    case SuppliersActionTypes.PRICING_CARTING_RESET:
      return {
        ...state,
        gettingCartMaterial: false,
        cartMaterialData: null,
        cartMaterialErr: null,
      };

    default:
      return state;
  }
};

const INITIAL_STATE_PRICING_CREW = {
  gettingCrewMaterial: false,
  crewMaterialData: null,
  crewMaterialErr: null,
};

export const PricingCrewReducer = (
  state = INITIAL_STATE_PRICING_CREW,
  action
) => {
  switch (action.type) {
    case SuppliersActionTypes.PRICING_CREW_START:
      return {
        ...state,
        gettingCrewMaterial: true,
        crewMaterialData: null,
        crewMaterialErr: null,
      };

    case SuppliersActionTypes.PRICING_CREW_SUCCESS:
      return {
        ...state,
        gettingCrewMaterial: false,
        crewMaterialData: action.payload,
        crewMaterialErr: null,
      };

    case SuppliersActionTypes.PRICING_CREW_FAILURE:
      return {
        ...state,
        gettingCrewMaterial: false,
        crewMaterialData: null,
        crewMaterialErr: action.payload,
      };

    case SuppliersActionTypes.PRICING_CREW_RESET:
      return {
        ...state,
        gettingCrewMaterial: false,
        crewMaterialData: null,
        crewMaterialErr: null,
      };

    default:
      return state;
  }
};
