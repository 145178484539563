
import React, { useState, useEffect, useRef } from "react";
import "../css/date.scss";
import { Col, Form } from "react-bootstrap";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

function NotesMap(props) {
  const [notesEdit, setNotesEdit] = useState(false);
  const [notesValue, setNotesValue] = useState("");

  const {
    notes,
    addNotes,
    setAddNotes,
    styles,
    getNotesEdit,
    note,
    deleteProjectNotes,
    setDelNotes,
    delNotes
  } = props;

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {" "}
      <Col md="9" sm="12">
        <Form.Group style={styles.formInput}>
          <Form.Label></Form.Label>
          <Form.Control
            as="textarea"
            disabled={!notesEdit}
            // value={notesValue === "" ? "" : notesValue || note.notes}
            value={notesValue ? notesValue : `${new Date(note.createdAt).toLocaleDateString()} ${new Date(note.createdAt).toLocaleTimeString()} ${note.notes}`}
            onChange={(e) => setNotesValue(e.target.value)}
          ></Form.Control>
        </Form.Group>
      </Col>
      <Col md="1"></Col>
      <Col md="1">
        {notesEdit ? (
          ""
        ) : (
          <button
            style={{ marginTop: "2rem", marginLeft: "-2rem" }}
            type="button"
            className="btn btn-danger"
            onClick={() => {
              deleteProjectNotes({
                notesId: note._id,
              });
              setAddNotes(false);
              setDelNotes(!delNotes)
            }}
          >
            Delete
          </button>
        )}
      </Col>
      <Col md="1">
        {!notesEdit ? (
          <button
            style={{ marginTop: "2rem" }}
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setNotesEdit(true);
            }}
          >
            Edit
          </button>
        ) : (
          <button
            style={{ marginTop: "2rem", marginLeft: "-8rem" }}
            type="button"
            className="btn btn-primary"
            onClick={() => {
              {
                getNotesEdit({
                  notesId: note._id,
                  notes: notesValue,
                });
              }
              setNotesEdit(false);
            }}
          >
            Save
          </button>
        )}
      </Col>
    </div>
  );
}

export default NotesMap;
