import { SYNC_TYPES } from "./sync.action.type";

const INITIAL_STATE = {
  isFetching: false,
};

export const Fetch = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SYNC_TYPES.FETCH_START:
      return {
        isFetching: true,
      };

    case SYNC_TYPES.FETCH_END:
      return {
        isFetching: false,
      };

    case SYNC_TYPES.FETCH_SET:
      return {
        isFetching: action.payload,
      };
    default:
      return state;
  }
};
