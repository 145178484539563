import { axiosInstance as axios } from "services";
import { getEndPoints } from "api/api";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { upadateContractDetail } from "lib/redux/proposalTab/proposalTab.actions";
import { FINISH_FIRST_TIME } from "lib/redux/proposalTab/proposalTab.reducer";
import {
  getQuoteProposalData,
  updateProposalStage,
} from "lib/redux/quote-proposals/quotes-proposals.actions";
import { ApiEndpoints } from "lib/config/baseSettings";

const url = getEndPoints(process.env.REACT_APP_MODE);

console.log("url ", `${url}contactManagement`);
const useContactData = () => {
  const dispatch = useDispatch();
  const [contractId, setContractId] = useState();
  const [contractDate, setContractDate] = useState("");
  const [sendEmail, setSendEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [contractDateGet, setContractDateGet] = useState("");
  const [signatureSeller, setSignatureSeller] = useState("");
  const [signatureClient, setSignatureClient] = useState("");
  const [pdflineItem, setPdflineItem] = useState("");
  const [updateContractResponse, setUpdateContractResponse] = useState(false);

  const [createContract, setCreateContract] = useState("");
  const [error, setError] = useState();
  const contractDetails = useSelector(
    (state) => state.contractDetail.contractDetail
  );
  const { firstTime } = useSelector((state) => state.contractDetail);
  const setContractDetails = (data) => dispatch(upadateContractDetail(data));
  const setFirstTime = (data, proposalId) => {
    axios.put(ApiEndpoints.EMAIL_CONTRACT.FirstTime, {
      _id: proposalId,
      firstTime: data,
    });
    return dispatch({ type: FINISH_FIRST_TIME, payload: data });
  };

  const fetchFirstTime = (data) => {
    return dispatch({ type: FINISH_FIRST_TIME, payload: data });
  };
  const SendEmailContract = (body) => {
    return axios
      .post(ApiEndpoints.EMAIL_CONTRACT.SendEmailContract, body)

      .then((response) => {
        if (response.data.success) {
          setSendEmail(response.data.success);
          toast.success(response.data.result.message);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const MakeContract = (body) => {
    return axios
      .post(ApiEndpoints.EMAIL_CONTRACT.MakeContract, body)

      .then((response) => {
        if (response.data.success) {
          // toast.success("New Contract Created");
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const AddDateToContract = (body) => {
    return axios
      .post(ApiEndpoints.EMAIL_CONTRACT.AddDateToContract, body)

      .then((response) => {
        if (response.data.success) {
          setContractDate(response.data.result.line_item);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const GetContractById = (body) => {
    return axios
      .post(ApiEndpoints.EMAIL_CONTRACT.GetContractById, body)

      .then((response) => {
        if (response.data.success) {
          setContractId(response.data.result);
          setContractDateGet(response.data.result.contract.date);
          setIsEmailSent(response.data.result.contract.isEmailSent);
          setSignatureSeller(response.data.result.contract.sellerSignature);
          setSignatureClient(response.data.result.contract.clientSignature);
          setPdflineItem(response.data.result.contract.lineItem);
          return { response: response.data.result };
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const updateContractState = (body) => {
    return axios
      .post(ApiEndpoints.EMAIL_CONTRACT.updatedContract, body)

      .then((response) => {
        if (response.data.success) {
          setUpdateContractResponse(response.data.success);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const createContractDetails = (body) => {
    return axios
      .post(ApiEndpoints.EMAIL_CONTRACT.createContractDetails, body)

      .then((response) => {
        if (response.data.success) {
          setContractDetails(response.data.result.contract);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const updatedContractDetails = (body) => {
    return axios
      .post(ApiEndpoints.EMAIL_CONTRACT.updatedContractDetails, body)

      .then((response) => {
        if (response.data.success) {
          setCreateContract(response.data.success);
        }
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const edit_quote = (body, metricId) => {
    dispatch({ type: "STARTING" });
    return axios
      .put(ApiEndpoints.EMAIL_CONTRACT.edit_quote, body)
      .then(async (response) => {
        if (response.data.success) {
          dispatch(updateProposalStage(metricId));
          //toast.success("Quote Updated");
        }

      });
  };

  const update_signed_contract = (body) => {
    return axios
      .post(ApiEndpoints.EMAIL_CONTRACT.update_signed_contract, body)
      .then((response) => {
        if (response.data.success) {
          toast.success("file uploaded");
          return { url: response.data.result };
        }
      })
      .catch(() => {
        toast.error("Something Went Wrong");
      });
  };

  return {
    AddDateToContract,
    MakeContract,
    SendEmailContract,
    setSendEmail,
    sendEmail,
    contractDate,
    setContractDate,
    GetContractById,
    setContractId,
    contractId,
    isEmailSent,
    contractDateGet,
    signatureSeller,
    signatureClient,
    pdflineItem,
    updateContractResponse,
    updateContractState,
    updateContractResponse,
    contractDetails,
    updatedContractDetails,
    createContractDetails,
    setCreateContract,
    createContract,
    edit_quote,
    update_signed_contract,
    setContractDetails,
    firstTime,
    setFirstTime,
    fetchFirstTime,
  };
};
export default useContactData;
