import { getData, saveData } from "services/storageManager";

const solveInSyntax = (key, inLogic, document) => {
  const arrayValue = inLogic["$in"];
  if (arrayValue.includes(document[key])) {
    return true;
  }
  return false;
};

/**
 * resolve mongoDB 'and' queries
 * @param {*} query
 * @param {*} document
 * @returns true if the document satisfy the query, else false
 */
const checkDocument = (query, document) => {
  for (const [key, value] of Object.entries(query)) {

    //check if the value of query is an object which means it has special meanings
    if (typeof value === "object" && !Array.isArray(value) && value !== null) {
      //solve in
      if ("$in" in value) {
        let check = solveInSyntax(key, value, document);
        if (!check) return false;
      }
    } else {
      //here solve simple equality

      if (document[key] !== value) return false;
    }
  }
  return true;
};
export const find = async (modelName, query) => {
  const data = await getData(modelName);

  if (data === null) return [];

  const result = data.filter((document) => {
    return checkDocument(query, document);
  });

  return result;
};

export const save = async (modelName, data) => {
  const storedData = getData(modelName);
  let newData = [];
  if (storedData !== null) {
    newData = [...storedData];
  }
  if (!data._id) {
    data._id = await getId(modelName);
  }

  //check if it is offline change or online change
  data.isOfflineChange = !navigator.onLine;
  //check if it is offline created to delete on Push
  data.isOfflineCreated = !navigator.onLine;

  newData.push(data);
  await saveData(modelName, newData);
  return data._id;
};

export const findOne = async (modelName, query) => {
  const resultArray = await find(modelName, query);
  return resultArray[0];
};

export const updateOne = async (modelName, query, data) => {
  const storedData = await getData(modelName);

  if (storedData === null) return false;

  if (typeof data !== "object" || Array.isArray(data) || data === null) {
    return false;
  }

  let updated = false;
  const newData = storedData.map((document) => {
    let newDoc = { ...document };
    if (checkDocument(query, document) && !updated) {
      for (const [key, value] of Object.entries(data)) {
        newDoc[key] = value;
      }
      newDoc.isOfflineChange = !navigator.onLine;
      updated = true;
    }
    return newDoc;
  });

  saveData(modelName, newData);
  return true;
};

export const getId = async (modelName) => {
  const baseID = 1;
  let idList = [];
  const storedData = getData(modelName);

  if (storedData === null) {
    return baseID;
  }
  for (const document of storedData) {
    idList.push(document._id);
  }

  idList.sort();

  //find free id

  if (idList.length === 0) {
    return baseID;
  }

  for (let i = 1; i < idList.length; i++) {
    if (idList[i] > idList[i - 1] + 1) {
      return idList[i - 1] + 1;
    }
  }
  return idList[idList.length - 1] + 1;
};

export const deleteAll = async (modelName) => {
  const data = await getData(modelName);

  if (data === null) return "no data";

  saveData(modelName, []);
  return true;
};

export const deleteMany = async (modelName, query) => {
  try {
    const data = await getData(modelName);

    if (data === null) return [];

    const result = data.filter((document) => {
      return !checkDocument(query, document);
    });

    await saveData(modelName, result);
    return true;
  } catch {
    console.log("delete error", modelName, query);
    return false;
  }
};
