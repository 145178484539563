import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./tabs.scss";

export default function Tabs(props) {
  const { height, active, count, tabs, setTab } = props;

  const displayTabs = (active) => {
    return tabs?.map((tab, i) => (
      <button
        style={{ height }}
        key={`${tab.salesStageId}`}
        onClick={() => setTab(tab.salesStageId, i)}
        className={`_tabItem ${active === tab.salesStageId && "active"}`}
      >
        {tab.salesStageText} ({count[i]?.count})
      </button>
    ));
  };
  return (
    <div
      className="_tab"
      style={{
        background: "rgb(245 243 239)",
      }}
    >
      {displayTabs(active)}
    </div>
  );
}
