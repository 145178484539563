import React, { useState, useRef, useEffect, useMemo } from "react";
import { Button, Form } from "react-bootstrap";
import {
  faCircleArrowDown,
  faHandRock,
} from "@fortawesome/free-solid-svg-icons";
import { Icon, LoadingLoader, ReactSortable } from "components";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Color from "lib/constants/color";
import useContactData from "hooks/useEmailContract";

function DraggagleBlock({
  index,
  text,
  setList,
  list,
  isMultiLine,
  quoteMaterialSectionData,
  updateFixedStandardStatementsisSelected,
  updateFixedStandardStatementdisplayText,
  isSelected,
  idx,
  isLockQuoteData,
  isDown,
  setIsDown,
  dataId,
}) {
  const [inpDisabled, setInpDisabled] = useState(true);
  const [remDisabled, setRemDisabled] = useState(true);
  const [displayText, setDisplayText] = useState(text);
  const inpRef = useRef(null);

  useEffect(() => {
    setDisplayText(text);
  }, [text]);

  useEffect(() => {
    setInpDisabled(true);
  }, [isLockQuoteData]);
  const hanldeClick = () => {
    if (!inpDisabled) return;
    setInpDisabled(false);
    if (inpRef.current) {
      inpRef.current.focus();
    }
  };

  const onBlur = () => {
    //setInpDisabled(true);
  };
  const handleIsSelect = (idx, isSelected) => {
    let newList = list.slice();
    newList[idx].isSelected = isSelected;
    setList([...newList]);
  };
  const handelOnClickRemove = () => {
    setRemDisabled(false);
    handleIsSelect(idx, false);
  };
  const handelOnClickRemoveAdd = () => {
    setRemDisabled(true);
    handleIsSelect(idx, true);
  };

  const handelOnChangeEdit = (e) => {
    setDisplayText(e.target.value);
    //updateFixedStandardStatementdisplayText(idx, e.target.value);
  };

  const handleSave = (isDown = null) => {
    setInpDisabled(true);
    let newList = list.slice();
    newList[idx].displayText = displayText;
    if (isDown !== null) {
      newList[idx].isDown = isDown;
    }

    setList([...newList]);
  };
  const title = inpDisabled ? "Tap to Edit" : "";

  const styleCondition = (isSelected, isInpDisabled) => {
    const cross = {
      textDecoration: "line-through",
      color: "gray",
    };
    const pointerEvent = {
      pointerEvents: "none",
    };

    let result = {};
    if (!isSelected) {
      result = { ...result, ...cross };
    }
    if (isInpDisabled) {
      result = { ...result, ...pointerEvent };
    }

    return result;
  };
  if (isDown && dataId === "STD016") return null;
  return (
    <Draggable draggableId={index} index={idx} key={index}>
      {(provided, snapshot) => {
        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div
              style={
                dataId === "STD009"
                  ? { display: "none" }
                  : {
                      display: "flex",
                      flex: 1,
                      gap: "1rem",
                      marginBottom: "1rem",
                    }
              }
            >
              <div>
                {isSelected ? (
                  <Button
                    variant="danger"
                    onClick={handelOnClickRemove}
                    disabled={isLockQuoteData?.isLocked}
                  >
                    Remove
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={handelOnClickRemoveAdd}
                    style={{ width: "83px" }}
                    disabled={isLockQuoteData?.isLocked}
                  >
                    Add
                  </Button>
                )}
              </div>
              <div
                className="inp"
                onClick={() => {
                  hanldeClick();
                }}
                role="button"
                title={title}
                tabIndex="-1"
                style={{ flex: 1 }}
              >
                {isMultiLine ? (
                  <Form.Control
                    as="textarea"
                    rows="3"
                    value={text}
                    disabled={inpDisabled || isLockQuoteData?.isLocked}
                    onBlur={onBlur}
                    ref={inpRef}
                    style={styleCondition(isSelected, inpDisabled)}
                  />
                ) : (
                  <Form.Control
                    onChange={handelOnChangeEdit}
                    value={displayText}
                    type="text"
                    disabled={inpDisabled || isLockQuoteData?.isLocked}
                    onBlur={onBlur}
                    ref={inpRef}
                    style={styleCondition(isSelected, inpDisabled)}
                  />
                )}
              </div>
              {inpDisabled || isLockQuoteData?.isLocked ? null : (
                <div>
                  <Button
                    onClick={() => {
                      handleSave();
                    }}
                    style={{ marginRight: "0.5rem" }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      setDisplayText((prev) => text);
                      setInpDisabled(true);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              )}
              {dataId === "STD016" && (
                <div
                  onClick={() => {
                    setIsDown(true);
                    handleSave(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Icon
                    size="2x"
                    color={Color.primaryColor}
                    name={faCircleArrowDown}
                  />
                </div>
              )}
              <div title="Tap and hold">
                <span className="handle">
                  <Icon
                    size="2x"
                    color={Color.primaryColor}
                    name={faHandRock}
                  />
                </span>
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
}

export function DraggagleList({
  list,
  setList,
  updateFixedStandardStatementsisSelected,
  updateFixedStandardStatementdisplayText,
  quoteMaterialSectionData,
  isLockQuoteData,
  isDown,
  setIsDown,
}) {
  const [listState, setListState] = useState(list);

  useEffect(() => {
    if (list != listState) {
      setListState((prev) => [...list]);
    }
  }, [list]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination || !source || !draggableId) return;
    const newList = listState.slice();
    const movingItem = newList[source.index];
    newList.splice(source.index, 1);
    newList.splice(destination.index, 0, movingItem);
    setList([...newList]);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={"123456789"}>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {listState.map((item, idx) => (
              <DraggagleBlock
                index={item._id}
                key={item._id}
                text={item?.displayText}
                setList={setList}
                list={list}
                quoteMaterialSectionData={quoteMaterialSectionData}
                isMultiLine={item?.isMultiLine}
                updateFixedStandardStatementsisSelected={
                  updateFixedStandardStatementsisSelected
                }
                updateFixedStandardStatementdisplayText={
                  updateFixedStandardStatementdisplayText
                }
                isSelected={item?.isSelected}
                idx={idx}
                dataId={item.dataId}
                isLockQuoteData={isLockQuoteData}
                isDown={isDown}
                setIsDown={setIsDown}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
