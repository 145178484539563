import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './weather.css';

import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Form,
  Modal,
  InputGroup,
} from "react-bootstrap";
import RequiredText from "components/required-text/required-text";
import { manipulated_hourly_data, weather_manipulation } from "./helpers";
import { Calendar } from "iconsax-react";
import useWeatherZipCode from "hooks/useWeatherZipCode";
import { LoadingLoader } from "components";
import useWeather from "hooks/useWeather";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const styles = {
  container: {
    boxShadow: "0 0 10px #99999996",
    background: "#fff",
    padding: "2em 1em",
  },
  heading: {
    flex: 1,
  },
  header: {
    padding: "25px 0",
  },
  formInput: {
    marginBottom: "15px",
    display: "flex",
  },
  formStyle: {
    // marginTop: "5%"
  },
};

const Weather = (props) => {

  let { paymentStatus } = props;
  const [zip, setZip] = useState();
  const [weather, setWeather] = useState([]);
  const [isLoadingWeather, setIsLoadingWeather] = useState(false);
  const [currentWeather, setCurrentWeather] = useState(null);
  const [point, setPoint] = useState({ lat: null, long: null });
  const { zipCode, update_weather_zip_code } = useWeatherZipCode();

  let manipulated_result;
  let { fetch_weather_data, isError, isLoading } = useWeather();

  let get_weather_data_for_zip_code = async (event) => {
    setIsLoadingWeather(true);
    let result = await fetch_weather_data(event);
    setWeather(result?.week);
    setCurrentWeather(result?.current);
    setPoint(result?.point);
    setIsLoadingWeather(false);
  };


  let data_func = async () => {
    setIsLoadingWeather(true);
    let result = await fetch_weather_data(zipCode && zipCode.zip);
    manipulated_result = weather_manipulation(result);
    setWeather(result?.week);
    setCurrentWeather(result?.current);
    setPoint(result?.point);
    setIsLoadingWeather(false);
  };

  useEffect(() => {
    if (zipCode) data_func();
  }, [zipCode]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Row>
        <Col xs="12" md="6">
          <Form.Group style={styles.formInput}>
            <Form.Control
              type="text"
              name="pcFname"
              value={zip || zip == "" ? zip : zipCode ? zipCode.zip : ""}
              placeholder="Enter Zip Code"
              onChange={(event) => {
                if (event.target.value.length == 5) {
                  get_weather_data_for_zip_code(
                    event.target.value || zipCode.zip
                  );
                  setZip(event.target.value);
                } else {
                  setZip(event.target.value);
                }
              }}
              // isInvalid={errAt === "pcFname"}
              style={{ width: "10rem" }}
              disabled={paymentStatus === "Frozen 1"}
            />
            <Form.Label>
              <RequiredText>*</RequiredText>
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>

      <Row
        style={{
          marginBottom: "3rem",
          height: "15rem",
          borderRadius: "2rem",
        }}
      >
        {isLoadingWeather ? (
          <LoadingLoader />
        ) : weather && weather.length > 0 ? (
          <Carousel responsive={responsive}>
            {weather && weather.length > 0
              ? weather.map((value, index) => {
                const {
                  name,
                  shortForecast,
                  temperature,
                  temperatureUnit,
                  windDirection,
                  windSpeed,
                  startTime,
                  icon,
                  isDaytime,
                } = value;
                if (!isDaytime) return null;
                return (
                  <div
                    className="weather-card"
                  >
                    <a
                      href={`https://forecast.weather.gov/MapClick.php?lat=${point.lat}&lon=${point.long}`}
                      target="_blank"
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flex: 1,
                          }}
                        >
                          <div>
                            <Calendar
                              size="24"
                              color="#697689"
                              margin="0"
                              padding="0"
                            />
                            <strong style={{ marginLeft: "5px" }}>
                              {startTime.slice(0, 10) ===
                                new Date().toISOString().split("T")[0]
                                ? `${name} (Today)`
                                : `${name} ${startTime.slice(8, 10)}`}
                            </strong>
                          </div>

                          {startTime.slice(0, 10) ===
                            new Date().toISOString().split("T")[0] && (
                              <strong>{`${currentWeather?.temperature}°F`}</strong>
                            )}
                        </div>
                      </div>

                      <div
                        style={{
                          borderRadius: "20%",
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "0.7rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "0.5rem",
                          }}
                        >
                          <strong
                            style={{ margin: 0, marginBottom: "0.5rem", fontSize: '0.8rem' }}
                          >
                            {shortForecast}
                          </strong>
                          <p style={{ margin: 0, fontSize: '0.8rem' }}>
                            <strong>Temperature:</strong>
                            {` ${weather[index + 1]?.temperature
                              }°${temperatureUnit} - ${temperature}°${temperatureUnit}`}
                          </p>
                          <p style={{ margin: 0, fontSize: '0.8rem' }}>
                            <strong>Wind:</strong>
                            {` ${windSpeed} ${windDirection}`}
                          </p>
                        </div>
                        <div>
                          <img
                            src={`${icon}`}
                            alt=""
                            style={{ borderRadius: "20%", height: "auto" }}
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })
              : []}
          </Carousel>
        ) : (
          <div style={{ width: "100%", display: 'flex', justifyContent: "center" }}>
            <p>Please enter zip code to check the weather</p>
          </div>
        )}
      </Row>
    </div>
  );
};

export default Weather;
