import BootstrapTable from "react-bootstrap-table-next";
import "./success.scss";
function Completion(props) {
  setTimeout(function() {
    localStorage.removeItem("paymentIntentId")
    window.location.replace('http://organizedroofer.net/login');
  }, 10000);
  return (
    <>
      <div className="body_stripe">
        <div class="card_stripe">
          <div
            style={{
              borderRadius: "200px",
              height: "200px",
              width: "200px",
              background: "#F8FAF5",
              margin: "0 auto",
            }}
          >
            <i className="checkmark">✓</i>
          </div>
          <h1 className="stripe_h1">Success</h1>
          <p className="stripe_p1">
            We received your purchase request;
            <br /> we'll be in touch shortly!
          </p>
          {/* <a href="http://localhost:4001/login">Now return to login</a> */}
          <div style={{paddingTop: "2rem"}}>
          <div style={{display: "flex", flexDirection: "column", textAlign:'start', paddingTop: "1rem"}}>
            <span style={{fontWeight: "1000", fontSize: "1.5rem", opacity: "0.5"}}>Payment Reference Id:</span>
            <span style={{fontWeight: "1000", fontSize: "1rem"}}>{localStorage.getItem("paymentIntentId")}</span>
          </div>
          <div style={{display: "flex", flexDirection: "column",textAlign:'start', paddingTop: "2rem"}}>
            <span style={{fontWeight: "1000", fontSize: "1.5rem",  opacity: "0.5"}}>Date of Transaction:</span>
            <span style={{fontWeight: "1000", fontSize: "1rem"}}>{new Date().toDateString()}</span>
          </div>
        </div>
        </div>

        
      </div>
    </>
  );
}

export default Completion;
