import React, { useState, useEffect } from "react";

import { Row, Col, Button, Form } from "react-bootstrap";

import {
  getPricingLabor,
  resetPricingLabor,
  patchLSuppliers,
  addLPricingCustom,
  deleteLPricingCustom,
} from "lib/redux/suppliers/suppliers.action";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { LoadingLoader } from "components";
import { MiscPriceListLabor } from "./Labor";
import { EmptyData } from "./commonComponents";
import { PriceListLabor } from "./Labor";
import RequiredText from "components/required-text/required-text";

export default function SubsPricingList({
  selectedSupp,
  materialPointerEventViewOnly,
}) {
  const { data, errors, isLoading } = useSelector((state) => state.staticAll);
  const { gettingPLabor, pLaborData, pLaborErr } = useSelector(
    (state) => state.laborPricing
  );
  const dispatch = useDispatch();

  const [pricing, setPricing] = useState([]);
  const [category, setCategory] = useState("");
  const [addItem, setAddItem] = useState(false);
  const [isMisc, setIsMisc] = useState(false);
  const [type, setType] = useState("");
  const [subType, setSubType] = useState("");
  const [uomOptions, setUomOptions] = useState([]);

  const [matchData, setMatchData] = useState(null);
  const [matchType, setMatchType] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [typesList, setTypesList] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [subName, setSubName] = useState("");
  const [roofPitchTypes, setRoofPitchTypes] = useState([]);
  const [roofPitch, setRoofPitch] = useState("");
  const [styleTypes, setStyleTypes] = useState([]);
  const [style, setStyle] = useState("");

  const [customItem, setCustomItem] = useState(false);
  const [customItemDefaultValue, setCustomItemDefaultValue] = useState(null);

  useEffect(() => {
    if (data) {
      setPricing(data.laborPricingOptions);
    }
  }, [data]);
  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);
  const resetPricingLaborData = () => {
    if (pLaborData) {
      dispatch(resetPricingLabor());
    }
  };

  useEffect(() => {
    setAddItem(false);
    setSubTypes([]);
    setSubName("");
    setType("");
    setSubType("");
    setMatchData(null);
    setMatchType(null);
    setStyleTypes([]);
    setStyle("");
    setRoofPitchTypes([]);
    setRoofPitch("");
    setCategory("");
    setCustomItem(false);
    resetPricingLaborData();
  }, [selectedSupp]);

  useEffect(() => {
    setAddItem(false);
    const list = [];
    if (pricing.length > 0) {
      pricing.map((priceData) => list.push(priceData.category));
    }
    setCategoryList(list);
  }, [pricing]);

  useEffect(() => {
    setAddItem(false);
    setSubTypes([]);
    setSubName("");
    setType("");
    setSubType("");
    setMatchData(null);
    setMatchType(null);
    setStyleTypes([]);
    setStyle("");
    setRoofPitchTypes([]);
    setRoofPitch("");
    resetPricingLaborData();

    if (category) {
      const list = [];
      const match = pricing.find(
        (priceData) => priceData.category === category
      );
      setMatchData(match);
      setIsMisc(match.isMisc);
      if (match.customItem) {
        setCustomItem(match.customItem);
      }
      match.categoryType.map((keys) => list.push(keys.typeName));
      setTypesList(list);
      if (list.length === 1) {
        setType(list[0]);
      }
    } else {
      setMatchData(null);
      setIsMisc(false);
      setTypesList([]);
    }
  }, [category]);

  useEffect(() => {
    setSubTypes([]);
    setSubName("");
    setStyleTypes([]);
    setStyle("");
    setRoofPitchTypes([]);
    setRoofPitch("");
    setSubType("");
    setMatchType(null);
    setAddItem(false);
    resetPricingLaborData();

    if (type) {
      const match = matchData.categoryType.find(
        (types) => types.typeName === type
      );
      setMatchType(match);
      setCustomItem(match.customItem);
      setCustomItemDefaultValue(match.customItemDefaultValue);
      if (match.uomOptions && match.uomOptions.length > 0) {
        setUomOptions(match.uomOptions);
      }
      if (match.subTypeName) {
        setSubName(match.subTypeName);
        setSubTypes(match.subType);
      } else {
        dispatch(
          getPricingLabor(
            {
              category,
              categoryType: type,
            },
            selectedSupp
          )
        );
      }
    }
  }, [type]);

  useEffect(() => {
    setStyleTypes([]);
    setStyle("");
    setRoofPitchTypes([]);
    setRoofPitch("");
    resetPricingLaborData();
    if (subType) {
      if (matchType.roofPitch && matchType.roofPitch.length > 0) {
        setRoofPitchTypes(matchType.roofPitch);
      } else if (matchType.style && matchType.style.length > 0) {
        setStyleTypes(matchType.style);
      } else if (
        (matchType?.style?.length === 0 &&
          matchType?.roofPitch?.length === 0) ||
        matchType.typeName === "Flat Roofing"
      ) {
        dispatch(
          getPricingLabor(
            {
              category,
              categoryType: type,
              subType,
            },
            selectedSupp
          )
        );
      }
    }
  }, [subType]);

  useEffect(() => {
    resetPricingLaborData();
    setAddItem(false);
    if (style) {
      if (roofPitchTypes.length === 0) {
        dispatch(
          getPricingLabor(
            {
              category,
              categoryType: type,
              subType,
              style,
            },
            selectedSupp
          )
        );
      }
    }
  }, [style]);

  useEffect(() => {
    resetPricingLaborData();
    setAddItem(false);
    if (roofPitch) {
      if (styleTypes.length === 0) {
        dispatch(
          getPricingLabor(
            {
              category,
              categoryType: type,
              subType,
              roofPitch,
            },
            selectedSupp
          )
        );
      }
    }
  }, [roofPitch]);

  useEffect(() => {
    if (isMisc) {
      dispatch(
        getPricingLabor(
          {
            category,
          },
          selectedSupp
        )
      );
    }
  }, [isMisc]);

  const showOptions = (list) =>
    list.map((data) => (
      <option style={{ textTransform: "capitalize" }} value={data} key={data}>
        {data}
      </option>
    ));

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const checkMiscData = () => {
    if (isMisc) {
      return (
        <>
          <Row>
            <Col>
              <div className="_tab_msg">
                <p className="__msg text-sm">
                  This is where you enter those various unforseen items not
                  found in the labor categories. Click 'Add new Item' above.
                </p>
              </div>
            </Col>
          </Row>
          <MiscPriceListLabor
            customItemDefaultValue={customItemDefaultValue}
            data={pLaborData.laborPricing}
            addItem={addItem}
            setAddItem={setAddItem}
            newData={{
              category,
            }}
            uomOptions={uomOptions}
            suppliersId={selectedSupp}
            deletePricing={deleteLPricingCustom}
            patchPricing={patchLSuppliers}
            addPricing={addLPricingCustom}
            customItem={customItem}
            materialPointerEventViewOnly={materialPointerEventViewOnly}
          />
        </>
      );
    }
    if (!isMisc) {
      if (pLaborData.laborPricing.length === 0) {
        return <EmptyData />;
      }

      return (
        <PriceListLabor
          customItemDefaultValue={customItemDefaultValue}
          data={pLaborData.laborPricing}
          addItem={addItem}
          setAddItem={setAddItem}
          newData={{
            category,
            categoryType: type || "N/A",
            subType: subType || "N/A",
            style: style || "N/A",
            roofPitch: roofPitch || "N/A",
          }}
          subName={subName}
          uomOptions={uomOptions}
          suppliersId={selectedSupp}
          deletePricing={deleteLPricingCustom}
          patchPricing={patchLSuppliers}
          addPricing={addLPricingCustom}
          customItem={customItem}
          materialPointerEventViewOnly={materialPointerEventViewOnly}
        />
      );
    }
  };

  const getList = () => {
    if (gettingPLabor) {
      return <LoadingLoader />;
    }
    if (pLaborErr) {
      return (
        <div className="_tab_msg">
          <p className="__msg">{pLaborErr}</p>
        </div>
      );
    }

    if (pLaborData && pLaborData.laborPricing) {
      return <>{checkMiscData()}</>;
    }
  };
  if (isLoading) {
    return <LoadingLoader />;
  }

  return (
    <div style={{ padding: "1em" }}>
      <Row
        style={{
          justifyContent: "space-between",
          // , padding: "1em"
        }}
      >
        <Row>
          <Col md="3">
            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="pos"
                value={category}
                onChange={(event) => handleChange(event, setCategory)}
              >
                <option value="">Choose...</option>
                {showOptions(categoryList)}
              </Form.Control>
            </Form.Group>
          </Col>
          {category && typesList.length > 0 && typesList.length !== 1 && (
            <Col md="3">
              <Form.Group>
                <Form.Label>{category} Type</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={type}
                  onChange={(event) => handleChange(event, setType)}
                >
                  <option value="">Choose...</option>
                  {showOptions(typesList)}
                </Form.Control>
              </Form.Group>
            </Col>
          )}

          {subName && subTypes && subTypes.length > 0 && (
            <Col md="3">
              <Form.Group>
                <Form.Label>{subName}</Form.Label>
                <Form.Control
                  as="select"
                  name="subType"
                  value={subType}
                  onChange={(event) => handleChange(event, setSubType)}
                >
                  <option value="">Choose...</option>
                  {showOptions(subTypes)}
                </Form.Control>
              </Form.Group>
            </Col>
          )}

          {roofPitchTypes.length > 0 && (
            <Col md="3">
              <Form.Group>
                <Form.Label>Roof Pitch</Form.Label>
                <Form.Control
                  as="select"
                  name="roofPitch"
                  value={roofPitch}
                  onChange={(event) => handleChange(event, setRoofPitch)}
                >
                  <option value="">Choose...</option>
                  {showOptions(roofPitchTypes)}
                </Form.Control>
              </Form.Group>
            </Col>
          )}
          {styleTypes.length > 0 && (
            <Col md="3">
              <Form.Group>
                <Form.Label>Style</Form.Label>
                <Form.Control
                  as="select"
                  name="style"
                  value={style}
                  onChange={(event) => handleChange(event, setStyle)}
                >
                  <option value="">Choose...</option>
                  {showOptions(styleTypes)}
                </Form.Control>
              </Form.Group>
            </Col>
          )}

          {customItem && (
            <Col>
              <div style={{ float: "right" }}>
                <Button disabled={addItem} onClick={() => setAddItem(true)}>
                  Add New Item
                </Button>
                <div
                  style={{
                    width: "99%",
                    textAlign: "right",
                    marginTop: "10%",
                    marginRight: "5%",
                  }}
                >
                  <RequiredText>* Required</RequiredText>
                </div>
              </div>
            </Col>
          )}
        </Row>

        {/* {customItem && (
          <Row>
            <Col>
              <div style={{ float: "right" }}>
                <Button disabled={addItem} onClick={() => setAddItem(true)}>
                  Add New Item
                </Button>
              </div>
            </Col>
          </Row>
        )} */}
      </Row>
      {getList()}
    </div>
  );
}
