import React, { useEffect, useState } from 'react'
import moment from 'moment'


export default function Clock() {
    const [time,setTime] = useState()
    useEffect(()=>{
        let id;
        id = setInterval(()=>setTime(moment().format('LTS')),1000)
        return () => clearInterval(id)
    },[time])

    return (
        <>{time}</>
    )
}