import React, { useState, useEffect } from "react";

import { Row, Col, Button, Form, Modal, Alert } from "react-bootstrap";
import "../css/pm.scss";
import PopupModal from "components/popup-modal/popup-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { convertDate, displayPersons, sliceHoursAndMin } from "utils/utils";

import {
  CalendarPersons,
  SalesPersons,
  RepairPersons,
  CalendarRequests,
  CalendarRequestsRepair,
  CalendarRequestsSales,
  salesEvents,
  repairEvents,
  CalendarEvents,
} from "lib/redux/calendar/calendar.selector";
import { connect } from "react-redux";
import {
  deleteEvent,
  clearAvailablePersons,
  addToCalendar,
  fetchForCalendar,
  fetchAvailabelPersons,
  fetchEvents,
} from "lib/redux/calendar/calendar.actions";
import useProjectData from "hooks/useProjectManagement";
import { toast } from "react-toastify";

let url = {
  card_data_url: "projects/getAllUnScheduledEvents",
  get_supplier_url: "projects/getSuppliers",
};

export const ScheduleProject = ({ ...props }) => {
  const {
    addModal,
    edit,
    date,
    msg,
    msgFor,
    start,
    isAllDay,
    sRep,
    isunScheduling,
    end,
    isSubmitting,
    personsData,
    displayBtns,
    active,
    salesPointerEvent,
    timing,
    setEnd,
    setSRep,
    request,
    clearAllFields,
    setAddModal,
    setEdit,
    repairPointerEvent,
    supplier_data,
    onReload,
    scheduledProject,
    additionalProjectmanagementTime,
    displaySupplier,
  } = props;

  let { labor_supplier } = supplier_data;

  const [laborSupplier, setLaborSupplier] = useState("");
  const [cartingSupplier, setCartingSupplier] = useState("");
  const [modal, setModal] = useState(false);
  const [laborTypes, setLaborTypes] = useState([]);
  const [multiSubCrew, setMultiSubCrew] = useState([]);
  const [laborId, setLaborId] = useState([]);
  const [gutterId, setGutterId] = useState([]);
  const [multiGutterCrew, setMultiGutterCrew] = useState([]);
  const [disableEdit, setDisableEdit] = useState(true);

  const {
    isLoading,
    useScheduleProject,
    useReScheduleProject,
    useUpdateProject,
    setIsLoading,
  } = useProjectData();

  let {
    _id,
    leadRequestId,
    proposalId,
    Quote_Labor,
    Quote_Gutter,
    leadRequestData: [
      {
        propertyList: [
          {
            leadAddress: [{ companyName, primaryFirstName, primaryLastName }],
            propertyAddress: [
              {
                propertyAddress1,
                propertyAddress2,
                propertyCity,
                propertyState,
                propertyZip,
                propertyType: [{ roleType }],
              },
            ],
          },
        ],
      },
    ],
  } = request;

  const handleSaveAppointment = async () => {
    const { requestId } = request;
    let endTime = "";
    const eTime = sliceHoursAndMin(end);
    endTime = `${eTime.hour}:${eTime.min}`;

    let newEvent;
    if (active === "Roofing") {
      newEvent = {
        type: "roof",
        proposalId: proposalId,
        projectId: _id,
        scheduleStartTime: endTime,
        scheduleStartDate: convertDate(date),
        crewSupplierId: laborId, //WARNING SUBCONTRACTOR
      };
    } else {
      newEvent = {
        type: "gutter",
        proposalId: proposalId,
        projectId: _id,
        scheduleStartTime: endTime,
        scheduleStartDate: convertDate(date),
        gutterSupplierId: gutterId,
      };
    }

    let data = await useScheduleProject(newEvent);
    if (data) {
      onHide();
      onReload();
      setIsLoading(false);
    }
  };

  const displayEndTimingOptions = () => {
    let index = -1;
    if (start) {
      index = timing.indexOf(start);
    }

    let array = date?.toLocaleDateString("en-US").split("/");

    array = array?.map((value) => {
      if (value.length < 2) {
        return `0${value}`;
      }
      return value;
    });

    const currentDate = `${array[0]}/${array[1]}/${array[2]}`;
    const todaySchedule = scheduledProject.filter((value) => {
      return value.schedules[0].scheduleStartDate === currentDate;
    });

    const roofOrGutter = active === "Roofing" ? "roof" : "gutter";

    let startHours = todaySchedule.map((value) => {
      if (
        (laborId.includes(value.schedules[0].crews[0].crewSupplierId[0]?._id) ||
          laborId.includes(
            value.schedules[0].crews[0].subSupplierId[0]?._id
          )) &&
        value.type === roofOrGutter
      ) {
        return value.schedules[0]?.scheduleStartTime;
      }
      return null;
    });

    startHours = startHours.filter((value) => {
      return value !== null;
    });

    // const

    return timing.map((time, i) => {
      const nowHour = new Date().getHours();
      const nowMin = new Date().getMinutes();
      let currentTime = sliceHoursAndMin(time, true);
      const isBefore =
        new Date(date).toDateString() === new Date().toDateString() &&
        (nowHour > currentTime.hour ||
          (nowHour === currentTime.hour && nowMin > currentTime.min));

      let isTaken = false;
      startHours.forEach((value) => {
        const startTime = sliceHoursAndMin(value, true);
        const endTime = {
          ...startTime,
          hour: startTime.hour + additionalProjectmanagementTime,
        }; //change later
        const isAfterEqualStart =
          currentTime.hour > startTime.hour ||
          (currentTime.hour == startTime.hour &&
            currentTime.min >= startTime.min);

        const isBeforeEnd =
          currentTime.hour < endTime.hour ||
          (currentTime.hour == endTime.hour && currentTime.min < endTime.min);

        if (isAfterEqualStart && isBeforeEnd) {
          isTaken = true;
        }
      });

      return (
        <option
          key={time}
          value={time}
          disabled={isBefore || isTaken || index > i}
          style={isBefore || isTaken ? {} : { fontWeight: "bold" }}
        >
          {time}
        </option>
      );
    });
  };

  let display_supplier = (e) => {
    setDisableEdit(false);
    let { labor_supplier, carting_supplier } = supplier_data;
    let arr_id = [];
    let arr_value = [];
    e.forEach((element) => {
      arr_id.push(
        labor_supplier.filter((labor) => labor.name === element.value)[0]._id
      );
      arr_value.push({
        value: element.value,
        label: element.value.toUpperCase(),
      });
    });

    setLaborId(arr_id);
    setMultiSubCrew(arr_value);
  };

  let display_gutter_supplier = (e) => {
    let { labor_supplier, carting_supplier } = supplier_data;
    setDisableEdit(false);
    let arr_id = [];
    let arr_value = [];
    e.forEach((element) => {
      arr_id.push(
        labor_supplier.filter((labor) => labor.name === element.value)[0]._id
      );
      arr_value.push({
        value: element.value,
        label: element.value.toUpperCase(),
      });
    });

    setLaborId(arr_id);
    setMultiGutterCrew(arr_value);
  };

  const handleEditAppointment = () => {
    let {
      type,
      schedules: [{ _id, crews }],
    } = request;

    let newEvent;
    if (active === "Roofing") {
      newEvent = {
        id: _id,
        type: type,
        scheduleId: crews[0]._id,
        scheduleStartTime: end,
        crewSupplierId: laborId, //WARNING SUBCONTRACTOR
      };
    } else {
      newEvent = {
        id: _id,
        type: type,
        scheduleId: crews[0]._id,
        scheduleStartTime: end,
        gutterSupplierId: laborId,
      };
    }

    let data = useUpdateProject(newEvent);

    if (data) {
      onHide();
      onReload();
      setIsLoading(false);
    } else {
      alert("Something went wrong");
    }
  };
  const handleDateChange = (date) => {
    const dateString = new Date(date).toDateString().split(" ");

    setDate(`${dateString[1]} ${Number(dateString[2])}, ${dateString[3]}`);
  };
  const removeEvent = async () => {
    const { _id, schedules } = request;

    let data = await useReScheduleProject({
      projectId: _id,
      id: schedules[0]?._id,
      crewDetailsId: schedules[0]?.crews[0]?._id,
    });

    if (data) {
      onHide();
      onReload();
      setIsLoading(false);
    } else {
      alert("Something went wrong");
    }
  };

  const onHide = () => {
    clearAllFields();
    setAddModal(false);
    setEdit(false);
    setModal(true);
  };

  useEffect(() => {
    let labor_arr = [];
    labor_supplier?.map((labor, i) => {
      let { name } = labor;
      if (labor.services.includes(active.toLowerCase())) {
        return labor_arr.push({ label: name.toUpperCase(), value: name });
      }
    });

    let data_labor = [];
    let data_gutter = [];

    if (
      request.schedules &&
      request?.schedules[0]?.crews[0]?.crewSupplierId.length > 0
    ) {
      request.schedules[0].crews[0].crewSupplierId.forEach((crew) => {
        data_labor.push({ value: crew.name, label: crew.name.toUpperCase() });
      });
    } else if (
      request.schedules &&
      request?.schedules[0]?.crews[0]?.subSupplierId.length > 0
    ) {
      request.schedules[0].crews[0].subSupplierId.forEach((crew) => {
        data_labor.push({ value: crew.name, label: crew.name.toUpperCase() });
      });
    } else {
      labor_supplier.forEach((labor) => {
        if (labor._id == Quote_Labor[0].supplierId) {
          data_labor.push({
            value: labor.name,
            label: labor.name.toUpperCase(),
          });
        }
      });
    }

    if (
      request.schedules &&
      (request?.schedules[0]?.crews[0]?.gutterSupplierId.length > 0 ||
        request?.schedules[0]?.crews[0]?.gutterCrewSupplierId.length > 0)
    ) {
      request.schedules[0].crews[0].gutterSupplierId.forEach((crew) => {
        data_gutter.push({ value: crew.name, label: crew.name.toUpperCase() });
      });

      request.schedules[0].crews[0].gutterCrewSupplierId.forEach((crew) => {
        data_gutter.push({ value: crew.name, label: crew.name.toUpperCase() });
      });
    } else {
      labor_supplier.forEach((labor) => {
        if (labor._id == Quote_Gutter[0]?.supplierId) {
          data_gutter.push({
            value: labor.name,
            label: labor.name.toUpperCase(),
          });
        }
      });
    }
    setLaborId([Quote_Labor[0].supplierId]);
    setGutterId([Quote_Gutter[0]?.supplierId]);
    setMultiGutterCrew(data_gutter);
    setMultiSubCrew(data_labor);
    setLaborTypes(labor_arr);
  }, [active]);

  return (
    <PopupModal
      show={addModal}
      onHide={onHide}
      heading={`Schedule Property - ${date}`}
      subHeading={`${propertyAddress1} ${propertyAddress2} ${propertyCity} ${propertyState} ${propertyZip}`}
      bold={true}
      styles={{ background: "#000099", color: "#fff" }}
    >
      {active === "Roofing" ? (
        <Col style={salesPointerEvent ? {} : { pointerEvents: "none" }}>
          {msg && msgFor === "modal" && (
            <Col>
              <Alert variant="danger">{msg}</Alert>
            </Col>
          )}
          <Form>
            {edit && (
              <Row>
                <Col md="5" style={{ marginLeft: "2.5rem" }} sm="12">
                  <DatePicker
                    minDate={new Date()}
                    selected={new Date(date)}
                    onChange={handleDateChange}
                    className="_date_picker"
                    dateFormat="E M/d/y"
                    calendarContainer={() => <></>}
                  />
                </Col>
              </Row>
            )}
            <Row style={{ marginTop: "2rem" }}>
              <Col md="5" sm="12" style={{ marginLeft: "5%" }}>
                <Form.Group style={styles.formInput}>
                  <Form.Label>Roofing Sub/Crew</Form.Label>
                  <Select
                    options={laborTypes}
                    value={multiSubCrew}
                    isMulti
                    name="services"
                    onChange={display_supplier}
                  />
                </Form.Group>
              </Col>

              <Col md="4" sm="12" style={{ marginLeft: "15%" }}>
                <Form.Group style={styles.formInput}>
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control
                    as="select"
                    name="end"
                    disabled={isAllDay || laborId.length < 1}
                    onChange={(e) => {
                      setEnd(e.target.value);
                      setDisableEdit(false);
                    }}
                    value={end}
                  >
                    <option value="">Choose...</option>
                    {displayEndTimingOptions()}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            {personsData.length > 0 ? (
              <Row>
                <Col md="12" sm="12" style={{ padding: "1em" }}>
                  <label>Select Sales Rep:</label>
                  <Select
                    isMulti
                    name="sRep"
                    value={sRep}
                    onChange={(value) => setSRep(value)}
                    options={displayPersons(personsData)}
                  />
                </Col>
              </Row>
            ) : (
              displayBtns && (
                <div className="_message"> No person is available.</div>
              )
            )}
          </Form>
        </Col>
      ) : (
        <Col style={repairPointerEvent ? {} : { pointerEvents: "none" }}>
          {msg && msgFor === "modal" && (
            <Col>
              <Alert variant="danger">{msg}</Alert>
            </Col>
          )}
          <Form>
            {edit && (
              <Row>
                <Col style={{ margin: "1em 0" }} sm="12">
                  <DatePicker
                    minDate={new Date()}
                    selected={new Date(date)}
                    onChange={handleDateChange}
                    className="_date_picker"
                    dateFormat="E M/d/y"
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md="5" sm="12" style={{ marginLeft: "5%" }}>
                <Form.Group style={styles.formInput}>
                  <Form.Label>Gutter Sub/Crew</Form.Label>
                  {/* <Form.Control
                    as="select"
                    name="start"
                    onChange={change_labor}
                    value={laborSupplier ? laborSupplier : labor_supplier_name}
                    disabled={isAllDay}
                  >
                    <option value="">Choose...</option>
                    {display_supplier({ type: "labor" })}
                  </Form.Control> */}
                  <Select
                    options={laborTypes}
                    value={multiGutterCrew}
                    isMulti
                    name="services"
                    onChange={display_gutter_supplier}
                  />
                </Form.Group>
              </Col>
              <Col md="4" sm="12" style={{ marginLeft: "15%" }}>
                <Form.Group style={styles.formInput}>
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control
                    as="select"
                    name="end"
                    disabled={isAllDay}
                    onChange={(e) => {
                      setEnd(e.target.value);
                      setDisableEdit(false);
                    }}
                    value={end}
                  >
                    <option value="">Choose...</option>
                    {displayEndTimingOptions()}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      )}
      {active === "Roofing" ? (
        <Modal.Footer
          style={
            salesPointerEvent
              ? {}
              : {
                  pointerEvents: "none",
                  display: "flex",
                  justifyContent: "space-between",
                }
          }
        >
          {edit && (
            <div>
              <Button
                variant="danger"
                onClick={removeEvent}
                disabled={isunScheduling}
                className="me-3"
              >
                {isLoading
                  ? "Removing From Calender..."
                  : "Remove From Calender"}
              </Button>
            </div>
          )}
          <div>
            <Button className="me-4" variant="outline-danger" onClick={onHide}>
              Cancel
            </Button>
            {edit ? (
              <Button
                onClick={() => handleEditAppointment()}
                disabled={disableEdit}
              >
                {isLoading ? "Saving..." : "Save Changes"}
              </Button>
            ) : (
              <Button onClick={() => handleSaveAppointment()} disabled={!end}>
                {isLoading ? "Saving..." : "Save"}
              </Button>
            )}
          </div>
        </Modal.Footer>
      ) : (
        <Modal.Footer
          style={repairPointerEvent ? {} : { pointerEvents: "none" }}
        >
          {edit && (
            <Button
              variant="danger"
              onClick={removeEvent}
              disabled={isunScheduling}
              className="me-3"
            >
              {/* {isunScheduling ? "Deleting..." : "Delete"} */}
              {isunScheduling
                ? "Removing From Calender..."
                : "Remove From Calender"}
            </Button>
          )}
          <Button className="me-4" variant="outline-danger" onClick={onHide}>
            Cancel
          </Button>
          {displayBtns ? (
            edit ? (
              <Button
                onClick={() => handleEditAppointment()}
                disabled={disableEdit}
              >
                {isLoading ? "Saving..." : "Save Changes"}
              </Button>
            ) : (
              <Button
                onClick={() => handleSaveAppointment()}
                disabled={!end || isSubmitting}
              >
                {isLoading ? "Saving..." : "Save"}
              </Button>
            )
          ) : edit ? (
            <Button
              onClick={() => {
                handleEditAppointment();
              }}
              disabled={disableEdit}
            >
              {isLoading ? "Saving..." : "Save Changes"}
            </Button>
          ) : (
            <Button
              onClick={() => handleSaveAppointment()}
              disabled={!end || isSubmitting}
            >
              {isLoading ? "Saving..." : "Save"}
            </Button>
          )}
        </Modal.Footer>
      )}
    </PopupModal>
  );
};

const styles = {
  container: {
    background: "#fff",
    padding: "2em .5em",
  },
  heading: {
    flex: 1,
  },
  header: {
    padding: "25px 20px",
  },
  formInput: {
    marginBottom: "15px",
  },
  formStyle: {
    // marginTop: "5%"
  },
  tabItem: { padding: "1em" },
};

const mapStateToProps = (state) => {
  const {
    calendarInputs: { isLoading, errors },
    availablePersons: { isLoadingAvPersons, personsData, personsErrors },
    scheduleCalendar: { isSubmitting, submitSuccess, submitFail },
    calendarEvents: { eventsError, isFetching },
    unscheduleEvent: { isunScheduling, unscheduleSuccess, unscheduleFail },
    login,
  } = state;
  return {
    isunScheduling,
    unscheduleSuccess,
    unscheduleFail,
    eventsError,
    isFetching,
    isSubmitting,
    submitSuccess,
    submitFail,
    isLoading,
    errors,
    isLoadingAvPersons,
    personsData,
    personsErrors,
    evnts: CalendarEvents(state),
    reqs: CalendarRequests(state),
    Persons: CalendarPersons(state),
    sales: CalendarRequestsSales(state),
    repairs: CalendarRequestsRepair(state),
    salesPersons: SalesPersons(state),
    repairPersons: RepairPersons(state),
    salesEvents: salesEvents(state),
    repairEvents: repairEvents(state),
    userRole: login.user ? login.user.userData.role : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchForCalendar: () => dispatch(fetchForCalendar()),
  fetchAvailabelPersons: (data) => dispatch(fetchAvailabelPersons({ ...data })),
  addToCalendar: (data) => dispatch(addToCalendar({ ...data })),
  fetchEvents: () => dispatch(fetchEvents()),
  clearAvailablePersons: () => dispatch(clearAvailablePersons()),
  deleteEvent: (data) => dispatch(deleteEvent({ ...data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleProject);
