import cartingPricingCompany from "services/offlineComputing/models/cartingPricingCompany";

export const getCartingPricing = async (o, q) => {
  const query = { ...q, ...o };
  // let newO = { _id: o.supplierId };
  // let data = await cartingSupplier.find(newO);

  const cartingPricing = await cartingPricingCompany.find(query);

  return cartingPricing;
  // let masterCartingPricings;
  // let arr = data[0].cartingTypes;
  // arr.length === 1 && arr.includes(carting_data.TRUCKS)
  //   ? (masterCartingPricings = await cartingPricingMaster.find({
  //       ...q,
  //       pricingType: carting_data.PRICINGTYPE_TRUCKS,
  //     }))
  //   : arr.length === 1 && arr.includes(carting_data.DUMPSTERS)
  //   ? (masterCartingPricings = await cartingPricingMaster.find({
  //       ...q,
  //       pricingType: carting_data.PRICINGTYPE_DUMPSTERS,
  //     }))
  //   : (masterCartingPricings = await cartingPricingMaster.find(q));
  // if (cartingPricing.length) {
  //   if (cartingPricing.length === masterCartingPricings.length) {
  //     return cartingPricing;
  //   } else {
  //     await cartingPricingCompany.deleteMany({ ...o });
  //     let masterCartingPricing;
  //     arr.length === 1 && arr.includes(carting_data.TRUCKS)
  //       ? (masterCartingPricing = await cartingPricingMaster.find({
  //           ...q,
  //           pricingType: carting_data.PRICINGTYPE_TRUCKS,
  //         }))
  //       : arr.length === 1 && arr.includes(carting_data.DUMPSTERS)
  //       ? (masterCartingPricing = await cartingPricingMaster.find({
  //           ...q,
  //           pricingType: carting_data.PRICINGTYPE_DUMPSTERS,
  //         }))
  //       : (masterCartingPricing = await cartingPricingMaster.find(q));

  //     for (let item of masterCartingPricing) {
  //       let { category, pricingType, size } = item;
  //       let newPricing = {
  //         ...o,
  //         category: category,
  //         pricingType: pricingType,
  //         size: size,
  //       };

  //       const newCartingPricingCompany = new cartingPricingCompany(newPricing);
  //       await newCartingPricingCompany.save();
  //     }
  //     const updatedCartingPricing = await cartingPricingCompany.find(query);
  //     return updatedCartingPricing;
  //   }
  // } else {
  //   let masterCartingPricing;
  //   arr.length === 1 && arr.includes(carting_data.TRUCKS)
  //     ? (masterCartingPricing = await cartingPricingMaster.find({
  //         ...q,
  //         pricingType: carting_data.PRICINGTYPE_TRUCKS,
  //       }))
  //     : arr.length === 1 && arr.includes(carting_data.DUMPSTERS)
  //     ? (masterCartingPricing = await cartingPricingMaster.find({
  //         ...q,
  //         pricingType: carting_data.PRICINGTYPE_DUMPSTERS,
  //       }))
  //     : (masterCartingPricing = await cartingPricingMaster.find(q));

  //   for (let item of masterCartingPricing) {
  //     let { category, pricingType, size } = item;
  //     let newPricing = {
  //       ...o,
  //       category: category,
  //       pricingType: pricingType,
  //       size: size,
  //     };

  //     const newCartingPricingCompany = new cartingPricingCompany(newPricing);
  //     await newCartingPricingCompany.save();
  //   }
  //   const updatedCartingPricing = await cartingPricingCompany.find(query);
  //   return updatedCartingPricing;
  // }
};
