import React from "react";

export default function SectionDataList({
  loadingData,
  sectionData,
  dataError,
  selectedSupplier,
  metricId,
  saveSectionDataToDB,
  deleteMiscItem,
  editMiscItem,
  MiscItemComponent,
  LoadingSkeleton,
  IndividualDataItem,
  displayMsg,
  quoteMaterialSectionData,
  isLockQuoteData,
  isLoading,
}) {
  if (dataError) {
    displayMsg({ msg: "No data available." });
  }

  if (sectionData && sectionData.length > 0) {
    return (
      <div>
        {sectionData.map((sectionPricing, idx) =>
          sectionPricing.isCustom ? (
            <MiscItemComponent
              key={sectionPricing._id + sectionPricing.category}
              data={sectionPricing}
              deleteMiscItem={() => deleteMiscItem(idx)}
              editMiscItem={(dataItem) => editMiscItem(idx, dataItem, true)}
              quoteMaterialSectionData={quoteMaterialSectionData}
              isLockQuoteData={isLockQuoteData}
              isLoading={isLoading}
            />
          ) : (
            <IndividualDataItem
              editItem={(dataItem) => editMiscItem(idx, dataItem)}
              key={sectionPricing._id + sectionPricing.category}
              idx={idx}
              pricing={sectionPricing}
              selectedSupplier={selectedSupplier}
              metricId={metricId}
              sectionData={sectionData}
              saveSectionDataToDB={saveSectionDataToDB}
              quoteMaterialSectionData={quoteMaterialSectionData}
              isLockQuoteData={isLockQuoteData}
              isLoading={isLoading}
            />
          )
        )}
      </div>
    );
  }

  if (sectionData && sectionData.length === 0) {
    return displayMsg({ msg: "No data available." });
  }

  if (loadingData) {
    return new Array(10).fill(1).map((_, i) => <LoadingSkeleton key={i} />);
  }

  return null;
}
