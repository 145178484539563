import { getData } from "services/storageManager";
import { PermissionActionTypes } from "./permission.type";
import { axios, axiosInstance } from "services";
import { ApiEndpoints } from "lib/config/baseSettings";

export const getAllPermissions = () => (dispatch) => {
  dispatch({ type: PermissionActionTypes.GET_PERMISSION_START });
  if (!navigator.onLine) {
    ///
    const allPermission = getData("allPermission");
    if (allPermission !== null) {
      return dispatch({
        type: PermissionActionTypes.GET_PERMISSION_SUCCESS,
        payload: allPermission,
      });
    }
    dispatch({
      type: PermissionActionTypes.GET_PERMISSION_FAILURE,
      payload: "getPermission error",
    });
  }

  return axiosInstance
    .get(ApiEndpoints.PERMISSION.GET_ALL_PERMISSIONS)
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return dispatch({
          type: PermissionActionTypes.GET_PERMISSION_SUCCESS,
          payload: data.result,
        });
      }
      // return dispatch({
      //   type: PermissionActionTypes.GET_PERMISSION_FAILURE,
      //   payload: data.error,
      // });
    })
    .catch((errors) =>
      dispatch({
        type: PermissionActionTypes.GET_PERMISSION_FAILURE,
        payload: errors.message,
      })
    );
};
