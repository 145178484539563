import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Modal,
} from "react-bootstrap";
import { connect } from "react-redux";
import PopupModal from "components/popup-modal/popup-modal";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { fetchStaticAll } from "lib/redux/static-all/static-all.actions";

import {
  SaveNewLead,
  FetchLeads,
  DeleteLead,
  EditLead,
  ScheduleLead,
  GetEmailType,
  GetPhoneType,
  GetPropertyType,
  GetRelationType,
  GetTasksRate,
  GetEditLeadData,
} from "lib/redux/new-lead/new-lead.actions";
import Building from "components/add-building/add-building";
import ContactTable from "./components/ContactTable";
import { getAllPermissions } from "../../lib/redux/permission/permission.actions";
import {} from "../../";
import { styles } from "./styles";
import { useRef } from "react";
import CompanyFields from "./components/CompanyFields";
import PrimaryFields from "./components/PrimaryFields";
import SecondaryFields from "./components/SecondaryFields";
import SiteContact_Property from "./components/SiteContact_Property";
import { LoadingLoader } from "components";

const RightTab = (props) => {
  //#region props const
  const { selectedContact, setRequestsDeatails, requestsDeatails } = props;
  const {
    staticAll,
    saveLead,
    leads,
    deletingLead: { deleteSuccess, deleteErrors },
    editLead,
    schedule,
    emailTypess,
    phoneTypess,
    propertyTypess,
    relationTypess,
    allPermissions,
    tasksRate,
  } = props;

  const {
    saveLead: { saveLeadLoading },
    editLead: { editLeadLoading },
    leads: { leadsData },
    schedule: { schedulling },
    ScheduleLead,
  } = props;

  const { editContactManagementProperty } = props;

  //#endregion
  const [contactTypes, setContactTypes] = useState({
    phoneTypess: "",
    emailTypess: "",
  });

  const [currentAction, setCurrentAction] = useState("");
  const [showNext, setShowNext] = useState(false);

  const [propertyList, setPropertyList] = useState([]);
  const [propertyListId, setPropertyListId] = useState("");
  const [isPropertySelected, setIsPropertySelected] = useState(false);
  const [propertyId, setPropertyId] = useState("");
  const [chosenProperty, setChosenProperty] = useState({
    property: "",
    request: "",
  });
  const [staticData, setStaticData] = useState({
    phoneType: "",
    emailType: "",
    propertyType: "",
    newInstallationType: "",
    repairType: "",
    leadSourceType: "",
    relationshipType: "",
  });
  const [requestDataForSchedule, setRequestDataForSchedule] = useState({});
  const [showServiceRequestModal, setShowServiceRequestModal] = useState(false);
  const [serviceRequestModalHeading, setServiceRequestModalHeading] =
    useState("");

  const [newBuildingName, setNewBuildingName] = useState("");
  const [coopUnits, setCoopUnits] = useState({});
  const [tempNewBuildingName, setTempNewBuildingName] = useState();
  const [isCancel, setIsCancel] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [showCoopModal, setShowCoopModal] = useState(false);
  const [addProperty, setAddProperty] = useState(false);
  const [addService, setAddService] = useState(false);
  const propertyDiv = useRef();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (staticAll?.data) {
      const { data } = staticAll;
      setStaticData({
        phoneType: data["phoneType"],
        emailType: data["emailType"],
        propertyType: data["propertyType"],
        newInstallationType: data["newInstallationType"],
        repairType: data["repairType"],
        leadSourceType: data["leadSourceType"],
        relationshipType: data["relationshipType"],
      });
    }
  }, [staticAll]);

  useEffect(() => {
    if (
      propertyList[chosenProperty.property]?.property_address[0]
        .propertyBuildings
    ) {
      const buildings =
        propertyList[chosenProperty.property]?.property_address[0]
          .propertyBuildings;

      let coopBuildingObj = {};

      buildings.map((value) => {
        coopBuildingObj = {
          ...coopBuildingObj,
          [value.bname.trim()]: {
            editBtn: false,
            deleteModal: false,
          },
        };
      });
      setCoopUnits(coopBuildingObj);
    }
  }, [chosenProperty]);

  useEffect(() => {
    // setcontactList(selectedContact);

    if (selectedContact) {
      let new_property_list = selectedContact?.property_list.map((doc) => {
        setPropertyListId(doc._id);
        let requests = doc.requests.map((req) => {
          return { ...req, isCheckScheduleCbox: false };
        });
        return { ...doc, pageView: false, requests, isCheck: false };
      });

      setPropertyList(new_property_list);
      setChosenProperty({ property: "", request: "" });
    }
  }, [selectedContact]);

  useEffect(() => {
    setContactTypes({ phoneTypess, emailTypess });
  }, [phoneTypess, emailTypess]);

  // #region functions

  const loadData = () => {
    const {
      FetchLeads,
      GetEmailType,
      GetPhoneType,
      GetPropertyType,
      GetRelationType,
      getAllPermissions,
      ScheduleLead,
      fetchStaticAll,
      GetTasksRate,
    } = props;
    FetchLeads();
    GetEmailType();
    GetPhoneType();
    fetchStaticAll();
    GetPropertyType();
    GetRelationType();
    getAllPermissions();
    ScheduleLead();
    GetTasksRate();
  };
  const onHide = () => {
    clearMessage();
    setShowServiceRequestModal(false);
    setShowNext(false);
  };

  const addBuldings = (value) => {
    setCoopUnits({
      ...coopUnits,
      [value.trim()]: {
        editBtn: false,
        deleteModal: false,
      },
    });
    setNewBuildingName("");
    // setEditBtn(false);
    setDeleteModal(false);
  };

  const DeleteLeadModal = () => {
    const {
      DeleteLead,
      deletingLead: { deleting },
    } = props;
    return (
      <PopupModal
        show={deleteModal}
        heading="Proceed with the deletion of this property?"
        onHide={onDeleteModalHide}
        bold={true}
      >
        <p style={{ fontSize: "13px" }}>
          <i>Note:</i>{" "}
          <b>
            Pressing 'YES' will permanently delete this property, but it can be
            reentered.
          </b>
        </p>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={onDeleteModalHide}>
            No
          </Button>
          <Button
            variant="outline-success"
            disabled={deleting}
            onClick={() => DeleteLead({ lead_id })}
          >
            {deleting ? "Deleting..." : "Yes"}
          </Button>
        </Modal.Footer>
      </PopupModal>
    );
  };

  const deleteBuilding = (unit) => {
    let CoopUnits = coopUnits;
    CoopUnits[unit] = undefined;
    delete CoopUnits[unit];
    setCoopUnits(CoopUnits);
    setShowCoopModal(true);
  };

  const onEditBuilding = (unit, value = true) => {
    disableOtherBuildingsEditBtn();
    let CoopUnits = coopUnits;
    CoopUnits[unit].editBtn = value;
    setCoopUnits(CoopUnits);
    setTempNewBuildingName(unit);
    setIsCancel(value);
  };

  const saveEditBuilding = (unit) => {
    deleteBuilding(unit);
    return addBuldings(tempNewBuildingName);
  };

  const onDeleteBuilding = (unit, value = true) => {
    let CoopUnits = coopUnits;
    CoopUnits[unit].deleteModal = value;
    setCoopUnits(CoopUnits);
    setShowCoopModal(!value);
  };

  const disableOtherBuildingsEditBtn = () => {
    Object.keys(coopUnits)?.map((unit) => {
      let CoopUnits = coopUnits;
      CoopUnits[unit].editBtn = false;
      setCoopUnits(CoopUnits);
    });
  };

  const scrollToBottom = () => {
    return window.scrollTo({
      top: document.body.offsetHeight,
      behavior: "smooth",
    });
  };
  const scrollToTop = () => {
    return window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // #endregion

  if (
    !selectedContact ||
    !selectedContact.primary_data ||
    emailTypess.isLoadingEmailtype ||
    !emailTypess.emaildata ||
    phoneTypess.isLoadingPhonetype ||
    !phoneTypess.phonedata
  )
    return <LoadingLoader />;

  return (
    <Container
      fluid="true"
      className="p-3"
      style={styles.container}
      block="true"
      ref={propertyDiv}
    >
      <ContactTable
        staticData={staticData}
        selectedContact={selectedContact}
        propertyList={propertyList}
        setIsPropertySelected={setIsPropertySelected}
        setPropertyId={setPropertyId}
        propertyTypess={propertyTypess}
        setRequestDataForSchedule={setRequestDataForSchedule}
        setRequestsDeatails={setRequestsDeatails}
        setPropertyList={setPropertyList}
        requestsDeatails={requestsDeatails}
        schedulling={schedulling}
        setChosenProperty={setChosenProperty}
        ContactManagementSchedule={props.ContactManagementSchedule}
        scrollToBottom={scrollToBottom}
        setAddProperty={setAddProperty}
        setAddService={setAddService}
      />
      <CompanyFields
        selectedContact={selectedContact}
        editContactManagementProperty={editContactManagementProperty}
      />
      <PrimaryFields
        selectedContact={selectedContact}
        editContactManagementProperty={editContactManagementProperty}
        contactTypes={contactTypes}
      />

      <SecondaryFields
        selectedContact={selectedContact}
        editContactManagementProperty={editContactManagementProperty}
        contactTypes={contactTypes}
        relationTypess={relationTypess}
        leadSourcesType={staticData.leadSourceType}
      />

      <SiteContact_Property
        selectedContact={selectedContact}
        editContactManagementProperty={editContactManagementProperty}
        contactTypes={{ ...contactTypes, propertyTypess }}
        relationTypess={relationTypess}
        leadSourcesType={staticData.leadSourceType}
        newInstallationType={staticData.newInstallationType}
        repairType={staticData.repairType}
        chosenProperty={chosenProperty}
        setChosenProperty={setChosenProperty}
        tasksRate={tasksRate}
        coopUnits={coopUnits}
        editLeadLoading={editLeadLoading}
        setShowCoopModal={setShowCoopModal}
        EditLead={props.EditLead}
        setCurrentAction={setCurrentAction}
        addingProperty={addProperty}
        setAddProperty={setAddProperty}
        AddContactManagementProperty={props.AddContactManagementProperty}
        scrollToTop={scrollToTop}
        selectedLeadId={props.selectedLeadId}
        AddNewSvcRequestToProperty={props.AddNewSvcRequestToProperty}
        addingService={addService}
      />

      <Building
        onCoopModalHide={() => setShowCoopModal(false)}
        newBuildingName={newBuildingName}
        setNewBuildingName={setNewBuildingName}
        coopUnits={coopUnits}
        onEditBuilding={onEditBuilding}
        saveEditBuilding={saveEditBuilding}
        tempNewBuildingName={tempNewBuildingName}
        setTempNewBuildingName={setTempNewBuildingName}
        onDeleteBuilding={onDeleteBuilding}
        deleteBuilding={deleteBuilding}
        addBuldings={addBuldings}
        showCoopModal={showCoopModal}
        isCancel={isCancel}
      />
      {deleteModal && <DeleteLeadModal />}
    </Container>
  );
};

const handleChange = (event) => {
  const { name, value } = event.target.value;
  setState({ ...state, [name]: value });

  clearErrorMsg(name, value);
};

function mapStateToProps({
  emailTypess: { isLoadingEmailtype, emaildata, emailerrors },
  phoneTypess: { isLoadingPhonetype, phonedata, phoneerrors },
  propertyTypess: { isLoadingPropertytype, propertydata, propertyerrors },
  relationTypess: { isLoadingRelationtype, relationdata, relationerrors },
  allPermissions: { gettingPermission, permission, permissionErr },
  tasksRate: { isLoadingTasksRate, taskratedata, tasksrateerror },

  staticAll: { isLoading, data, errors },
  saveLead,
  fetchLeads,
  deleteLead: { isLoadingDelete, deleteSuccess, deleteErrors },
  editLead,
  scheduleLead,
  login,
}) {
  return {
    emailTypess: { isLoadingEmailtype, emaildata, emailerrors },
    phoneTypess: { isLoadingPhonetype, phonedata, phoneerrors },
    propertyTypess: { isLoadingPropertytype, propertydata, propertyerrors },
    relationTypess: { isLoadingRelationtype, relationdata, relationerrors },
    allPermissions: { gettingPermission, permission, permissionErr },
    tasksRate: { isLoadingTasksRate, taskratedata, tasksrateerror },

    staticAll: {
      isLoading,
      data,
      errors,
    },
    saveLead: {
      saveLeadLoading: saveLead.isLoading,
      saveLeadsuccess: saveLead.success,
      saveLeaderrors: saveLead.errors,
    },
    leads: {
      leadsLoading: fetchLeads.isLoadingFetchLeads,
      leadsData: fetchLeads.data,
      leadsErrors: fetchLeads.errors,
    },
    deletingLead: {
      deleting: isLoadingDelete,
      deleteSuccess,
      deleteErrors,
    },
    editLead: {
      editLeadLoading: editLead.isLoadingEdit,
      editLeadsuccess: editLead.successEdit,
      editLeaderrors: editLead.errorsEdit,
    },
    schedule: {
      schedulling: scheduleLead.schedulling,
      successSchedule: scheduleLead.successSchedule,
      errorsSchedule: scheduleLead.errorsSchedule,
    },
    userRole: login.user ? login.user.userData.role : null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchStaticAll: () => dispatch(fetchStaticAll()),
    SaveNewLead: (data) => dispatch(SaveNewLead({ ...data })),
    FetchLeads: () => dispatch(FetchLeads()),
    GetTasksRate: () => dispatch(GetTasksRate()),
    DeleteLead: (data) => dispatch(DeleteLead({ ...data })),
    EditLead: (data) => dispatch(EditLead({ ...data })),
    GetEditLeadData: (data) => dispatch(GetEditLeadData({ ...data })),
    ScheduleLead: () => dispatch(ScheduleLead()),
    GetEmailType: () => dispatch(GetEmailType()),
    GetPhoneType: () => dispatch(GetPhoneType()),
    GetPropertyType: () => dispatch(GetPropertyType()),
    GetRelationType: () => dispatch(GetRelationType()),
    getAllPermissions: () => dispatch(getAllPermissions()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RightTab);
