import { axios as axiosNoAuth } from "services";
import { useState, useEffect } from "react";
import { ApiEndpoints } from "lib/config/baseSettings";

const useStateAndCountTax = () => {
  const [loading, setLoading] = useState(false);
  const [activeData, setActiveData] = useState();
  const [currentCounty, setCurrentCounty] = useState({});

  useEffect(() => {
    get_all_active_data();
  }, []);

  const get_all_active_data = () => {
    setLoading(true);
    return axiosNoAuth
      .get(ApiEndpoints.STATE_COUNTY_TAX)
      .then((res) => {
        if (res.data.success) {
          setActiveData(res.data.result);
        } else {
          setActiveData([]);
        }
      })
      .catch((err) => {
        setActiveData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const get_county_tax_by_state_zip = (stateId, zip) => {
    setLoading(true);
    return axiosNoAuth
      .get(ApiEndpoints.getCountyByStateAndZip(stateId, zip))
      .then((res) => {
        if (res.data.success) {
          setCurrentCounty(res.data.result);
        } else {
          setCurrentCounty({});
          setLoading(true);
        }
      })
      .catch((err) => {
        setCurrentCounty({});
        setLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return {
    activeData,
    get_all_active_data,
    get_county_tax_by_state_zip
  };
};

export default useStateAndCountTax;
