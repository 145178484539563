const { CHECKOUT_TYPES } = require("./checkout.action.type");

const showCheckoutModal = () => (dispatch) => {
  dispatch({ type: CHECKOUT_TYPES.SHOW_CHECKOUT_MODAL });
};

const hideCheckoutModal = () => (dispatch) => {
  dispatch({ type: CHECKOUT_TYPES.HIDE_CHECKOUT_MODAL });
};


export { showCheckoutModal, hideCheckoutModal };
