import React, { useState, useEffect } from "react";

import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import "./calendar.scss";
import SearchField from "components/search-field/search-field";
import Calendar from "components/calendar/Calendar";
import InitialLoader from "components/InitilalLoader";

import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {  displayPersons, sliceHoursAndMin } from "utils/utils";
import moment from "moment";
import { DragDropContainer } from "react-drag-drop-container";
import { FilterCircleOutline, FilterCircle } from "react-ionicons";

import {
  CalendarPersons,
  SalesPersons,
  RepairPersons,
  CalendarRequests,
  CalendarRequestsRepair,
  CalendarRequestsSales,
  salesEvents,
  repairEvents,
  CalendarEvents,
} from "lib/redux/calendar/calendar.selector";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  deleteEvent,
  clearAvailablePersons,
  addToCalendar,
  fetchForCalendar,
  fetchAvailabelPersons,
  fetchEvents,
} from "lib/redux/calendar/calendar.actions";
import { TIMINGS } from "lib/constants/const";
import userPermission from "hooks/usePermission";
import useSchedulingModal from "hooks/SchedulingModal/useSchedulingModal";
import { Link } from "react-router-dom";
import { memo } from "react";
import AddModal from "./AddModal";

const links = ["Sales", "Repair"];

const CalendarEventsList = ({
  loading,
  error,
  handleDrop,
  onDragStart,
  requests,
  searchResult,
  searchTerm,
  onReload,
}) => {
  let hasTouchScreen = false;

  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  }

  const [isDragging, setIsDragging] = useState(false);

  if (loading || error) {
    return <InitialLoader error={error} onReload={onReload} />;
  }
  const listData = searchTerm.trim() ? searchResult : requests;
  if (!listData || listData.length === 0) {
    return (
      <div className="empty-message">
        <div>No request found.</div>
      </div>
    );
  }

  return (
    listData &&
    listData.length > 0 &&
    listData.map((request) => {
      let reqs = "";
      request.requestFor.map((req) =>
        reqs ? (reqs = `${reqs} & ${req}`) : (reqs = `${req}`)
      );
      return (
        <div>
          <DragDropContainer
            noDragging={!isDragging && hasTouchScreen}
            targetKey="calendar"
            key={request.requestId}
            onDragStart={(e) => {
              onDragStart(e, request);
            }}
            onDrop={(e) => {
              handleDrop(e, request);
              setIsDragging(false);
            }}
          >
            <li draggable={isDragging || !hasTouchScreen}>
              <Row>
                <Col sm="10" md="10">
                  {request.companyNameEntered.length > 0 && (
                    <h5>{request.companyNameEntered}</h5>
                  )}
                  {request.companyNameEntered.length > 0 ? (
                    <h6 className="title">{request.leadName}</h6>
                  ) : (
                    <h5>{request.leadName}</h5>
                  )}
                  <a
                    href={`https://www.google.com/maps/place?q=${request.address}`}
                    className="address"
                    style={{
                      textDecoration: "underline",
                    }}
                    target="_blank"
                  >
                    {request.propertyName + " " + request.address}
                  </a>
                  <p className="type">
                    {request.propertyType} - {reqs}
                  </p>
                  <p className="date">
                    {new Date(request.createdOn).toDateString()}
                  </p>
                </Col>
                {hasTouchScreen && (
                  <Col
                    sm="2"
                    md="2"
                    style={{
                      paddingRight: "5px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "right",
                    }}
                  >
                    {isDragging ? (
                      <FilterCircle
                        onClick={() => {
                          setIsDragging(false);
                        }}
                        color={"#00000"}
                        height="30px"
                        width="30px"
                      />
                    ) : (
                      <FilterCircleOutline
                        onClick={() => {
                          setIsDragging(true);
                        }}
                        color={"#00000"}
                        height="30px"
                        width="30px"
                      />
                    )}
                  </Col>
                )}
                {/* <Col sm="2" md="2">
              <div className="arrow">
                <img src={Arrow} height={20} width={20} alt="arrow" />
              </div>
            </Col> */}
              </Row>
            </li>
          </DragDropContainer>
        </div>
      );
    })
  );
};

const CalendarUI = (props) => {
  const {
    evnts,
    reqs,
    salesEvents,
    repairEvents,
    unscheduleSuccess,
    unscheduleFail,
    eventsError,
    personsErrors,
    errors,
    salesPersons,
    repairPersons,
    repairs,
    submitFail,
    submitSuccess,
    sales,
    paymentStatus,
  } = props;

  //#region states
  let timeout = null;
  const [timing, setTiming] = useState(TIMINGS);
  const [events, setEvent] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [active, setActive] = useState("");
  const [indexE, setIndexE] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [date, setDate] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [isAllDay, setIsAllDay] = useState(false);
  const [sRep, setSRep] = useState(null);
  const [id, setId] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState("");
  const [edit, setEdit] = useState(false);
  const [request, setRequest] = useState({});
  const [requestFor, setRequestFor] = useState([]);
  const [persons, setPersons] = useState([]);
  const [requests, setRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectPerson, setSelectPerson] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [displayBtns, setDisplayBtns] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [msgFor, setMsgFor] = useState("");
  const [view, setView] = useState("month");
  const [dateObject, setDateObject] = useState(new Date());

  const [sale, setSales] = useState("");
  //#endregion

  const { gettingPermission, permission, permissionErr } = useSelector(
    (state) => state.allPermissions
  );
  const { user } = useSelector((state) => state.login);

  const { additionalRepairTime, additionalSalesTime } =
    useSchedulingModal(user);

  const dispatch = useDispatch();

  const { salesPointerEvent, repairPointerEvent, 
    hasAccessForRepairsCalender, hasAccessForSaleCalender } = userPermission(permission);

  const setToday = () => {
    setDateObject(new Date());
  };
  const loadData = () => {
    const { fetchForCalendar, fetchEvents } = props;
    fetchForCalendar();
    fetchEvents();
  };

  useEffect(() => {
    if (hasAccessForSaleCalender) {
      setActive("Repair");
    } else {
      setActive("Sales");
    }
    if (
      hasAccessForSaleCalender ||
      hasAccessForRepairsCalender
    ) {
      loadData();
    }
  }, [hasAccessForSaleCalender]);

  useEffect(() => {
    getThingsReady();
  }, []);

  useEffect(() => {
    setAddModal(false);
    setMsg(unscheduleSuccess);
    setMsgType("success");
    setMsgFor("!modal");

    clearAllFields();
    if (unscheduleSuccess || unscheduleFail) {
      props.clearAvailablePersons();
      props.fetchForCalendar();
      props.fetchEvents();
    }

    clearMsg();
  }, [unscheduleSuccess]);

  useEffect(() => {
    setAddModal(false);
    setMsg(submitSuccess);
    setMsgType("success");
    setMsgFor("!modal");
    clearAllFields();
    if (submitSuccess || submitFail) {
      props.clearAvailablePersons();
      props.fetchForCalendar();
      props.fetchEvents();
    }
    clearMsg();
  }, [submitSuccess]);

  useEffect(() => {
    if (selectPerson && selectPerson.length > 0) {
      const filtered = events.filter((event) =>
        event.persons.some((person) =>
          selectPerson.some((data) => person.personId === data.value)
        )
      );
      setFiltered(filtered);
    } else {
      setEvent(events);
    }
  }, [selectPerson]);

  useEffect(() => {
    if (active === "Sales") {
      setRequests(sales);
    } else {
      setRequests(repairs);
    }
  }, [reqs]);
  useEffect(() => {
    if (active === "Sales") {
      setEvent(salesEvents);
    } else {
      setEvent(repairEvents);
    }
  }, [evnts]);

  useEffect(() => {
    setMsg(unscheduleFail);
    setMsgType("danger");
    setMsgFor("modal");
    clearMsg();
  }, [unscheduleFail]);

  useEffect(() => {
    setMsg(eventsError);
    setMsgType("danger");
    setMsgFor("!modal");
    clearMsg();
  }, [eventsError]);

  useEffect(() => {
    setMsg(unscheduleFail);
    setMsgType("danger");
    setMsgFor("modal");
    setDisplayBtns(false);
    clearMsg();
  }, [personsErrors]);

  useEffect(() => {
    setMsg(errors);
    setMsgType("danger");
    setMsgFor("!modal");
    clearMsg();
  }, [errors]);

  useEffect(() => {
    setMsg(submitFail);
    setMsgType("danger");
    setMsgFor("modal");
    setDisplayBtns(false);
    props.clearAvailablePersons();
    clearMsg();
  }, [submitFail]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      if (active === "Sales") {
        setRequests(sales);
      } else {
        setRequests(repairs);
      }
      return;
    }
    if (searchTerm !== "") {
      const filteredRequests =
        requests &&
        requests.filter(
          (request) =>
            request.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
            request.leadName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      setSearchResult(filteredRequests);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (active === "Sales") {
      if (start) {
        const time = sliceHoursAndMin(start);
        let hour = time.hour + additionalSalesTime;
        const min = time.min;
        const newTime = convert24to12Hours(hour, min);
        if (timing.includes(newTime)) {
          setEnd(newTime);
        } else {
          setEnd("");
        }
      }
    } else {
      if (start) {
        const time = sliceHoursAndMin(start);
        let hour = time.hour + additionalRepairTime;
        const min = time.min;
        const newTime = convert24to12Hours(hour, min);
        if (timing.includes(newTime)) {
          setEnd(newTime);
        } else {
          setEnd("");
        }
      }
    }
  }, [start]);

  useEffect(() => {
    if (active === "Sales") {
      setPersons(salesPersons);
    }
  }, [salesPersons]);

  useEffect(() => {
    if (active === "Repairs") {
      setPersons(repairPersons);
    }
  }, [repairPersons]);

  useEffect(() => {
    if (active === "Sales") {
      setEvent(salesEvents);
      setPersons(salesPersons);
      setRequests(sales);
    } else {
      setEvent(repairEvents);
      setPersons(repairPersons);
      setRequests(repairs);
    }
  }, [active]);

  useEffect(() => {
    if (active === "Sales") {
      setRequests(sales);
    }
  }, [sales]);

  useEffect(() => {
    if (active === "Repairs") {
      setRequests(repairs);
    }
  }, [repairs]);

  useEffect(() => {
    if (isAllDay) {
      setStart("");
      setEnd("");
    }
  }, [isAllDay]);

  useEffect(() => {
    props.clearAvailablePersons();
    setDisplayBtns(false);
  }, [start, end, date]);

  const getThingsReady = () => {
    if (isAllDay || start || end || date) {
      props.clearAvailablePersons();
      setDisplayBtns(false);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const changeView = (view) => {
    setView(view);
  };

  const clearMsg = () =>
    (timeout = setTimeout(() => {
      setMsg("");
      setMsgType("");
      setMsgFor("");
    }, 10000));

  const convert24to12Hours = (hour, min) => {
    const meridiem = hour > 11 ? "PM" : "AM";
    const newHour = hour > 12 ? hour - 12 : hour;
    return `${newHour}:${
      Number(min) < 10 ? `0${Number(min)}` : Number(min)
    } ${meridiem}`;
  };

  const editEvent = (id, event) => {
    let newEvents = [...events];
    newEvents.splice(id, 1, event);
    setEvent(newEvents);
  };

  const onEventDrop = ({ event, start, end, allDay }) => {
    const idx = events.indexOf(event);
    const newEvent = {
      id: event.id,
      start,
      end,
      allDay,
      title: `(${convert24to12Hours(
        start.getHours(),
        start.getMinutes()
      )} - ${convert24to12Hours(end.getHours(), end.getMinutes())}) ${
        events[idx].title.split(")")[1]
      }`,
    };
    editEvent(idx, newEvent);
  };

  const onDragStart = (e, request) => {
    setRequest(request);
  };

  const handleDrop = (e, request) => {
    if (paymentStatus !== "Frozen 1") {
      const date = e.dropData.date;
      const dateString = new Date(date).toDateString().split(" ");
      if (request) {
        const { address, propertyType, requestFor } = request;

        setAddModal(true);
        setDate(`${dateString[1]} ${Number(dateString[2])}, ${dateString[3]}`);
        setAddress(address);
        setType(propertyType);
        setRequestFor(requestFor);
      }
    }

    return;
  };

  const clearAllFields = () => {
    setIndexE("");
    setDate("");
    setEnd("");
    setStart("");
    setIsAllDay(false);
    setSRep([]);
    setDisplayBtns(false);
    setRequest(null);
    setEdit(false);
    setSales(null);
  };
  const onMultipleSelectChange = (array, value) => {
    let temp = array;
    const idx = temp.indexOf(value);
    temp.includes(value) ? temp.splice(idx, 1) : temp.push(value);

    return setSRep(temp);
  };

  const eventPressed = ({ data }) => {
    const { address, propertyType } = data.requestObj;
    const { date, startTime, endTime, allDay } = data.eventDetails;
    const dateString = new Date(date).toDateString().split(" ");
    let newStart = "",
      newEnd = "";
    if (!allDay) {
      const sTime = sliceHoursAndMin(startTime);
      const eTime = sliceHoursAndMin(endTime);
      newStart = convert24to12Hours(sTime.hour, sTime.min);
      newEnd = convert24to12Hours(eTime.hour, eTime.min);
    }

    let personsData = [];
    setDate(
      `${dateString[0]} ${dateString[1]} ${Number(dateString[2])}, ${
        dateString[3]
      }`
    );
    setStart(newStart);
    setIsAllDay(allDay);
    setSRep(personsData);
    setAddress(address);
    setType(propertyType);
    setStart(newStart);
    setRequest(data);
    setAddModal(true);
    setEnd(newEnd);
    setEdit(true);
  };

  const changeTab = (tabName) => {
    setActive(tabName);
    setSelectPerson("");
  };

  const onNext = () => {
    setDateObject(moment(dateObject).add(1, "M"));
  };

  const onPrev = () => {
    setDateObject(moment(dateObject).subtract(1, "M"));
  };
  const onNextDay = () => {
    setDateObject(moment(dateObject).add(1, "day"));
  };

  const onPrevDay = () => {
    setDateObject(moment(dateObject).subtract(1, "day"));
  };

  const onNextWeek = () => {
    setDateObject(moment(dateObject).add(1, "weeks"));
  };

  const onPrevWeek = () => {
    setDateObject(moment(dateObject).subtract(1, "weeks"));
  };

  const {
    isunScheduling,
    isLoading,
    isLoadingAvPersons,
    personsData,
    isSubmitting,
    // errors,
    userRole,
  } = props;

  return (
    <>
      <div className="calendar-ui">
        <Container
          fluid="true"
          className="p-3"
          style={styles.container}
          block="true"
        >
          {msg && msgFor === "!modal" && (
            <Col>
              <Alert variant={msgType}>{msg}</Alert>
            </Col>
          )}
          <Row>
            {/* left column */}
            <Col lg="4" md="5" sm="12" style={{ width: "27.333333%" }}>
              {/* tabs and search field */}
              <Col>
                <div className="_tab">
                  {!hasAccessForSaleCalender ? (
                    <button
                      className={`_tabItem ${active === links[1]}`}
                      style={{ color: "gray", pointerEvents: "none" }}
                    >
                      Sales &nbsp; &nbsp; &nbsp;
                      <Link
                        to={{
                          pathname: "/dashboard/help",
                          search: "Calendar Sales",
                        }}
                        target="_blank"
                        rel="noreferrer"
                        style={{ cursor: "pointer" }}
                      >
                        <span style={{ color: "red" }}>?</span>
                      </Link>
                    </button>
                  ) : (
                    <button
                      style={styles.tabItem}
                      onClick={() => changeTab(links[0])}
                      className={`_tabItem ${active === links[0] && "active"}`}
                    >
                      Sales &nbsp; &nbsp; &nbsp;
                      <Link
                        to={{
                          pathname: "/dashboard/help",
                          search: "Calendar Sales",
                        }}
                        target="_blank"
                        rel="noreferrer"
                        style={{ cursor: "pointer" }}
                      >
                        <span style={{ color: "red" }}>?</span>
                      </Link>
                    </button>
                  )}
                  {!hasAccessForRepairsCalender ? (
                    <button
                      className={`_tabItem ${active === links[1]}`}
                      style={{ color: "gray", pointerEvents: "none" }}
                    >
                      Repairs &nbsp; &nbsp; &nbsp;
                      <Link
                        to={{
                          pathname: "/dashboard/help",
                          search: "Calendar Repair",
                        }}
                        target="_blank"
                        rel="noreferrer"
                        style={{ cursor: "pointer" }}
                      >
                        <span style={{ color: "red" }}>?</span>
                      </Link>
                    </button>
                  ) : (
                    <button
                      style={styles.tabItem}
                      onClick={() => changeTab(links[1])}
                      className={`_tabItem ${active === links[1] && "active"}`}
                    >
                      Repairs &nbsp; &nbsp; &nbsp;
                      <Link
                        to={{
                          pathname: "/dashboard/help",
                          search: "Calendar Repair",
                        }}
                        target="_blank"
                        rel="noreferrer"
                        style={{ cursor: "pointer" }}
                      >
                        <span style={{ color: "red" }}>?</span>
                      </Link>
                    </button>
                  )}
                </div>
                <SearchField
                  type="search"
                  placeholder={`Search for ${active.toLowerCase()}`}
                  value={searchTerm}
                  onChange={
                    ({ target: { value } }) => setSearchTerm(value)
                    // this.setState({ searchTerm: value })
                  }
                  paymentStatus={paymentStatus}
                />
              </Col>
              {/* event list */}
              {active === "Sales" ? (
                <Col className="cal__left__tab">
                  <ol
                    style={salesPointerEvent ? {} : { pointerEvents: "none" }}
                  >
                    <CalendarEventsList
                      loading={isLoading}
                      error={errors}
                      onDragStart={onDragStart}
                      handleDrop={handleDrop}
                      requests={requests}
                      searchResult={searchResult}
                      searchTerm={searchTerm}
                      onReload={loadData}
                      paymentStatus={paymentStatus}
                    />
                  </ol>
                </Col>
              ) : (
                <Col className="cal__left__tab">
                  <ol
                    style={repairPointerEvent ? {} : { pointerEvents: "none" }}
                  >
                    <CalendarEventsList
                      loading={isLoading}
                      error={errors}
                      onDragStart={onDragStart}
                      handleDrop={handleDrop}
                      requests={requests}
                      searchResult={searchResult}
                      searchTerm={searchTerm}
                      onReload={loadData}
                    />
                  </ol>
                </Col>
              )}
            </Col>

            {/* right column */}
            <Col>
              {/* top control */}
              <Row style={{ alignItems: "center" }}>
                <Col lg="8">
                  <div className="btns-group">
                    <button className="today-btn" onClick={setToday} style={{}}>
                      Today
                    </button>
                    <div>
                      <button
                        className={`today-btn inverted${
                          view === "month" ? " selected" : ""
                        }`}
                        onClick={() => changeView("month")}
                      >
                        Month
                      </button>
                      <button
                        className={`today-btn inverted${
                          view === "week" ? " selected" : ""
                        }`}
                        onClick={() => changeView("week")}
                      >
                        Week
                      </button>
                      <button
                        className={`today-btn inverted${
                          view === "day" ? " selected" : ""
                        }`}
                        onClick={() => changeView("day")}
                      >
                        Day
                      </button>
                    </div>
                  </div>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>View Calendar By {active} Person</Form.Label>
                    {false ? (
                      <Select
                        isMulti
                        name="selectPerson"
                        value={selectPerson}
                        onChange={
                          (value) => setSelectPerson(value)
                        }
                        options={displayPersons(persons)}
                      />
                    ) : (
                      <Select
                        isMulti
                        name="selectPerson"
                        value={selectPerson}
                        onChange={
                          (value) => setSelectPerson(value)
                        }
                        options={displayPersons(persons)}
                        isDisabled={paymentStatus === "Frozen 1"}
                      />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {/* calendar */}
              <Col>
                <Calendar
                  events={
                    selectPerson && selectPerson.length > 0 ? filtered : events
                  }
                  dropFromOutside={handleDrop}
                  dayviewstart="6:00"
                  dayviewend="20:59"
                  eventClicked={(event) => eventPressed(event)}
                  view={view}
                  dateObject={dateObject}
                  setToday={setToday}
                  onNext={onNext}
                  onPrev={onPrev}
                  onNextDay={onNextDay}
                  onPrevDay={onPrevDay}
                  onNextWeek={onNextWeek}
                  onPrevWeek={onPrevWeek}
                />
              </Col>
            </Col>
          </Row>
        </Container>
        {paymentStatus !== "Frozen 1" && addModal && (
          <AddModal
            addModal={addModal}
            edit={edit}
            date={date}
            address={address}
            type={type}
            requestFor={requestFor}
            msg={msg}
            msgFor={msgFor}
            start={start}
            isAllDay={isAllDay}
            sRep={sRep}
            isunScheduling={isunScheduling}
            end={end}
            isSubmitting={isSubmitting}
            isLoadingAvPersons={isLoadingAvPersons}
            personsData={personsData}
            displayBtns={displayBtns}
            permission={salesPointerEvent}
            active={active}
            salesPointerEvent={permission}
            timing={timing}
            setIsAllDay={setIsAllDay}
            setEnd={setEnd}
            setStart={setStart}
            displayPersons={displayPersons}
            setDisplayBtns={setDisplayBtns}
            setSRep={setSRep}
            request={request}
            clearAllFields={clearAllFields}
            setAddModal={setAddModal}
            setEdit={setEdit}
            repairPointerEvent={repairPointerEvent}
          />
        )}
      </div>
    </>
  );
};

const styles = {
  container: {
    background: "#fff",
    padding: "2em .5em",
    position: "relative",
  },
  heading: {
    flex: 1,
  },
  header: {
    padding: "25px 20px",
  },
  formInput: {
    marginBottom: "15px",
  },
  formStyle: {
    // marginTop: "5%"
  },
  tabItem: { padding: "1em" },
};

const mapStateToProps = (state) => {
  const {
    calendarInputs: { isLoading, errors },
    availablePersons: { isLoadingAvPersons, personsData, personsErrors },
    scheduleCalendar: { isSubmitting, submitSuccess, submitFail },
    calendarEvents: { eventsError, isFetching },
    unscheduleEvent: { isunScheduling, unscheduleSuccess, unscheduleFail },
    login,
  } = state;
  return {
    isunScheduling,
    unscheduleSuccess,
    unscheduleFail,
    eventsError,
    isFetching,
    isSubmitting,
    submitSuccess,
    submitFail,
    isLoading,
    errors,
    isLoadingAvPersons,
    personsData,
    personsErrors,
    evnts: CalendarEvents(state),
    reqs: CalendarRequests(state),
    Persons: CalendarPersons(state),
    sales: CalendarRequestsSales(state),
    repairs: CalendarRequestsRepair(state),
    salesPersons: SalesPersons(state),
    repairPersons: RepairPersons(state),
    salesEvents: salesEvents(state),
    repairEvents: repairEvents(state),
    userRole: login.user ? login.user.userData.role : null,
    paymentStatus:
      login.user.userData.paymentStatus && login.user.userData.paymentStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchForCalendar: () => dispatch(fetchForCalendar()),
  fetchAvailabelPersons: (data) => dispatch(fetchAvailabelPersons({ ...data })),
  addToCalendar: (data) => dispatch(addToCalendar({ ...data })),
  fetchEvents: () => dispatch(fetchEvents()),
  clearAvailablePersons: () => dispatch(clearAvailablePersons()),
  deleteEvent: (data) => dispatch(deleteEvent({ ...data })),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(CalendarUI));
