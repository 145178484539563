import { axiosInstance as axios } from "services";
import { getEndPoints } from "api/api";
import { useState, useEffect } from "react";
import { LoadingLoader } from "components";
import { toast } from "react-toastify";
import { ApiEndpoints } from "lib/config/baseSettings";

const url = getEndPoints(process.env.REACT_APP_MODE);

const use_quote = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [letterHead, setLetterHead] = useState();

  useEffect(() => {
    get_quote_data_for_letter_head();
  }, []);

  const save_cancelleation_reason = (body) => {
    setIsLoading(true);
    return axios
      .post(ApiEndpoints.QUOTE.cancellationReason, { ...body })
      .then((response) => {
        if (response.data.success) {
          setIsLoading(false);
          toast.success("reason added successfully...!");
          return { error: null, response: response };
        }
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);
          setError(error.response.message | "Something went wrong...");
          return { error: error.response, response: null };
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const update_followup = (data) => {
    setIsLoading(true);
    return axios
      .post(ApiEndpoints.QUOTE.changeStage, { ...data })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          setIsLoading(false);
          toast.success("stage updated successfully!");
          return { error: false, response: response };
        } else {
          setIsLoading(false);
          toast.error("stage couldn't be updated!");
          return { error: true, response: null };
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error?.response?.message | "Something went wrong...");
        return { error: error.response, response: null };
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  let get_quote_data_for_letter_head = () => {
    setIsLoading(true);
    return axios
      .get(ApiEndpoints.QUOTE.getLetterHead)
      .then((response) => {
        if (response.data.success) {
          console.log({ response });
          setLetterHead(response.data.result);
          return response.data.result;
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error?.response?.message | "Something went wrong...");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    save_cancelleation_reason,
    update_followup,
    get_quote_data_for_letter_head,
    letterHead,
  };
};

export default use_quote;
