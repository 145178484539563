import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import {
  getQuoteProposalData,
} from "lib/redux/quote-proposals/quotes-proposals.actions";
import { useDispatch, useSelector } from "react-redux";
import { LoaderModal } from "components";
import { Redirect } from "react-router-dom";
import APIHook from "../hooks/APIHook";
import { toast } from "react-toastify";
import usePushData from "services/offlineDataPush";
import { FINISH_FIRST_TIME } from "lib/redux/proposalTab/proposalTab.reducer";
import data from "lib/constants/constDataForCodeCorrection";
import { axiosInstance } from "services";
import { reCalculate } from "services/offlineComputing/routes/generateQuote";

const styles = { float: "right" };

export default function GenerateQuote({
  metricId,
  isDataChanged,
  disableBtn,
  type_page,
}) {
  const dispatch = useDispatch();

  const { loading, err, resData, onReload } = APIHook({ metricId });
  const [redirect, setRedirect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [allowRedrect, setAllowRedirect] = useState(false);
  const [saveQuoteRes, setSaveQuoteRes] = useState({
    success: null,
    err: null,
    loading: false,
  });

  const { gettingQuoteProposalData, quoteProposalData, quoteProposalErr } =
    useSelector((state) => state.quoteProposal);
  const { user } = useSelector((state) => state.login);

  const changeShowModal = (value) => setShowModal(value);
  const showGenerateModal = () => changeShowModal(true);
  const hideGenerateModal = () => changeShowModal(false);
  const userDataRole = user.userData.role;

  useEffect(() => {
    if (quoteProposalErr) {
      hideGenerateModal();
      toast.error(quoteProposalErr);
    }
  }, [quoteProposalErr]);

  useEffect(() => {
    if (quoteProposalData && quoteProposalData.length > 0 && allowRedrect) {
      redirectTo();
    }
  }, [quoteProposalData, allowRedrect]);
  
  const { isPushing } = usePushData();
  const generateQuote = () => {
    dispatch({ type: FINISH_FIRST_TIME, type: true });
    setAllowRedirect(true);
    localStorage.removeItem("BuildingName");
    showGenerateModal();
    dispatch(getQuoteProposalData(metricId, isPushing));
  };

  const changeRedirect = (value) => setRedirect(value);
  
  const redirectTo = () => changeRedirect(true);

  console.log({quoteProposalData});
  const changeSaveQuoteRes = (newData) =>
    setSaveQuoteRes({ ...saveQuoteRes, ...newData });

  const reCalculateUnlockedQuotes = () =>
    new Promise((resolve, reject) => {
      if (!navigator.onLine) {
        reCalculate({ metricId });
      }
      axiosInstance
        .post("/quote/reCalculate", { metricId })
        .then((res) => {
          if (res.data.success) {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  const saveQuote = (metricId) => {
    changeSaveQuoteRes({ loading: true });

    try {
      reCalculateUnlockedQuotes().then((res) => {
        if (res) {
          changeSaveQuoteRes({ loading: false, success: true, err: null });
          onSaveSuccess();
          setAllowRedirect(true);
          localStorage.removeItem("BuildingName");
        } else {
          changeSaveQuoteRes({ loading: false, success: false, err: message });
        }
      });
    } catch (errr) {
      changeSaveQuoteRes({ loading: false, success: false, err: errr });
    }
  };

  const onSaveSuccess = (res) => {
    // if (res.success) {
    dispatch(getQuoteProposalData(metricId, isPushing));
    // }
    // if (res.result && res.result.length > 0) {
    //   changeSaveQuoteRes({ success: res.result });
    // } else {
    //   changeSaveQuoteRes({ success: res.success });
    // }
  };

  const onSaveFailure = (msg) => {
    changeSaveQuoteRes({ err: msg });
  };
  const onSaveFinally = () => {
    changeSaveQuoteRes({ loading: false });
  };

  const viewQuote = () => {
    localStorage.removeItem("BuildingName");
    redirectTo();
  };

  const reload = () => {
    onReload();
  };

  const QuoteBtn = ({ children, ...rest }) => <Btn {...rest}>{children}</Btn>;

  if (redirect) {
    return <Redirect to={`/dashboard/proposal-quotes/${metricId}`} />;
  }

  if (loading) {
    return <Btn disabled>Loading Quote Data...</Btn>;
  }

  if (err) {
    return (
      <Btn onClick={reload} variant="danger">
        Reload Quote Data
      </Btn>
    );
  }

  const hasQuote = resData && resData.length > 0;

  const SaveOrViewQuote =
    saveQuoteRes.success || !isDataChanged ? (
      <Btn onClick={viewQuote}>View Quote</Btn>
    ) : (
      <QuoteBtn disabled={disableBtn} onClick={() => saveQuote(metricId)}>
        {saveQuoteRes.loading
          ? "Saving Changes..."
          : saveQuoteRes.err
          ? "Try again"
          : "Save Quote"}
      </QuoteBtn>
    );

  return (
    <>
      {userDataRole != "repair" && !type_page ? (
        hasQuote ? (
          SaveOrViewQuote
        ) : (
          <QuoteBtn disabled={disableBtn} onClick={generateQuote}>
            Generate Quote
          </QuoteBtn>
        )
      ) : (
        ""
      )}
      {showModal && <LoaderModal show={showModal} />}
    </>
  );
}

function Btn({ onClick, disabled, children, variant }) {
  return (
    <Button
      style={styles}
      variant={variant || "primary"}
      onClick={onClick}
      disabled={disabled || false}
    >
      {children}
    </Button>
  );
}
