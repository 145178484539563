import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useHelp from "hooks/useHelp";
import { useEffect } from "react";
import useHelpAll from "hooks/useHelpAll";
import useContactData from "hooks/useContactManagement";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Help = (props) => {
  const {
    selectCategory,
    setSelectCategory,

    displaypTypeOptionsScreen,
    setSelectSetup,
    selectSetup,
    allVideo,
  } = useHelp();
  const {
    companyData
  } = useContactData()
  const { setAllHelp, allHelp } = useHelpAll();

  const help = props.location.search.split("%20").join(" ").replace("?", "");
  useEffect(() => {
    if (props.location.search) {
      setSelectCategory(allHelp[help]?.screenId);
    }
  }, [props?.location?.search, allHelp]);
  return (
    <>
      <div className="_container_right _user_page">
        <div className="__proposal" style={{ marginLeft: "5%" }}>
          <Row style={{ marginBottom: "5%", position: "relative" }}>
            <Col md="3">
              <Form.Group>
                <Form.Label>Select Screen</Form.Label>
                <Row>
                  <Col md="12">
                    <Form.Control
                      as="select"
                      name="pType"
                      value={selectCategory}
                      onChange={(e) => setSelectCategory(e.target.value)}
                      disabled={companyData && companyData.length && companyData[0]?.paymentStatus == "Frozen 1" ? true : false}
                    >
                      <option value="">Choose...</option>
                      <option value="First Time Setup">First Time Setup</option>
                      {displaypTypeOptionsScreen()}
                    </Form.Control>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            {selectCategory === "First Time Setup" && (
              <Col md="6">
                <Form.Group>
                  <Form.Label>Select First Time Setup</Form.Label>
                  <Row>
                    <Col md="6">
                      <Form.Control
                        as="select"
                        name="pType"
                        value={selectSetup}
                        onChange={(e) => setSelectSetup(e.target.value)}
                      >
                        <option value="">Choose...</option>
                        <option value="Application Settings">
                          Application Settings
                        </option>
                        <option value="Supplier Material & Labor">
                          Supplier Material & Labor
                        </option>
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            )}
          </Row>

          {allVideo?.length > 0
            ? allVideo?.map((data) => {
              return (
                <Row>
                  <Col md="3">
                    {" "}
                    <h4>{data?.subScreens}</h4>{" "}
                  </Col>

                  <Row
                    style={
                      data?.videoInfo?.length > 0
                        ? {}
                        : { height: "230px", width: "100%" }
                    }
                  >
                    <Carousel
                      swipeable={false}
                      draggable={false}
                      // showDots={true}
                      responsive={responsive}
                      ssr={true} // means to render carousel on server-side.
                      // infinite={true}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container"
                      // removeArrowOnDeviceType={["tablet", "mobile"]}
                      deviceType="desktop "
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-10-px"
                    >
                      {data?.videoInfo?.length > 0 ? (
                        data?.videoInfo?.map((res) => {
                          return (
                            <Col md="3" style={{ marginBottom: "5%" }}>
                              <div class="owl-carousel owl-theme">
                                <div class="item">
                                  <p style={{ marginTop: "3%" }}>
                                    {res?.descriptions}
                                  </p>

                                  <video width="290" height="170" controls>
                                    <source src={res?.url} type="video/mp4" />
                                    <source src={res?.url} type="video/ogg" />
                                  </video>
                                  {/* <div class="owl-carousel owl-theme">
                                      <div class="item">
                                        <iframe
                                          // src={`${res?.url}`}
                                          src="https://drive.google.com/file/d/13jbPnb1Y3jbFfwqiaDas_grSYyq8MVsU/preview"
                                          width="100%"
                                          height="170"
                                        ></iframe>
                                      </div>
                                    </div> */}
                                  {/* <div style={{ textAlign: "center" }}>
                                        <button
                                          className="btn btn-danger"
                                          onClick={() => {
                                            setIsDelete(!isDelete);
                                            toggle();
                                            geiId(res?.videoId);
                                          }}
                                        >
                                          delete
                                        </button>
                                      </div> */}
                                </div>
                              </div>
                            </Col>
                          );
                        })
                      ) : (
                        <h5 style={{ textAlign: "center" }}>
                          No video found
                        </h5>
                      )}
                    </Carousel>
                  </Row>
                </Row>
              );
            })
            : selectCategory && (
              <h5 style={{ textAlign: "center" }}>No data Found</h5>
            )}
        </div>
      </div>
    </>
  );
};

export default Help;
