import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./modal.scss";
import { ShowChartOutlined } from "@material-ui/icons";

export const ModalFooter = ({
  onCancel,
  onSubmit,
  submitDisabled,
  submitText,
  cancelText = "",
  styleFoot = { border: "none" },
}) => (
  <Modal.Footer
    style={
      styleFoot
        ? styleFoot
        : {
          marginTop: "30px",
          justifyContent: "flex-end",
          border: "none",
          padding: ".75rem",
        }
    }
  >
    <Button className="modified_btn" variant="danger" onClick={onCancel}>
      {cancelText === "No" ? "No" : "Cancel"}
    </Button>
    <Button
      disabled={submitDisabled}
      className="modified_btn"
      onClick={onSubmit}
    >
      {submitText || "Ok"}
    </Button>
  </Modal.Footer>
);

const PopupModal = ({
  show,
  children,
  styles,
  subHeading,
  heading,
  onHide,
  bold = false,
  size = "lg",
  closeButton = true,
  modalStyles,
  fromProposal,
  payment,
  ...rest
}) => {
  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={onHide}
      size={size}
      fromProposal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={modalStyles || {}}
      {...rest}
    >
      <Modal.Header closeButton={closeButton} style={styles || null}>
        <Modal.Title id="contained-modal-title-vcenter">
          {bold ? <b>{heading}</b> : heading}
          {subHeading && (
            <p style={{ margin: 0, fontSize: ".6em" }}>{subHeading}</p>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={fromProposal ? { height: "56vh", overflow: "auto" } : {}}
      >
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default PopupModal;
